import React, { useState, useEffect } from "react";
import { Box, Text, Heading } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { Add, Alert, Edit } from "grommet-icons";
import { split } from "lodash";
import { getPaymentMethodsGroup } from "../../../../store/patients/actions";
import PaymentMethodGroup from "../../../common/PaymentMethodGroup";

const MethodsGroup = () => {
  const [addCardShow, setAddCardShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [existingMethods, setExisttingMethods] = useState(null);
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];

  const showPaymentMethods = (data) => {
    setExisttingMethods(data);
    setLoading(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodsGroup(groupID, showPaymentMethods));
  }, [loading]);

  if (loading)
    return (
      <Box className="card" height="100%">
        <Heading level={6} color="#395E9D">
          Payment Method
        </Heading>
      </Box>
    );

  return (
    <>
      {addCardShow && (
        <PaymentMethodGroup
          display={addCardShow}
          setDisplayOut={(value) => setAddCardShow(value)}
          showPaymentMethods={showPaymentMethods}
        />
      )}
      <Box className="card" height="100%">
        <Box direction="row" gap="xsmall" align="start">
          <Heading level={6} color="#395E9D">
            Payment Method
          </Heading>
          {existingMethods &&
          new Date(
            existingMethods.card.exp_year,
            existingMethods.card.exp_month - 1,
            1
          ) <
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 2,
              new Date().getDate()
            ) ? (
            <Box
              direction="row"
              gap="1px"
              background="#e86661"
              pad="2px"
              round="4px"
            >
              <Alert color="white" size="small" />
              <Text size="9px" color="white">
                Expiring Soon
              </Text>
            </Box>
          ) : null}
        </Box>
        {existingMethods ? (
          <Box gap="medium">
            <Box gap="medium" direction="row">
              <Text style={{ textTransform: "capitalize" }} size="xsmall">
                {existingMethods.card.brand} **** {existingMethods.card.last4} -
                Expires {existingMethods.card.exp_month}/
                {existingMethods.card.exp_year}
              </Text>
              <Box
                direction="row"
                justify="center"
                className="noOutline"
                style={{
                  fontSize: "small",
                  color: "white",
                  borderRadius: "8px",
                }}
                onClick={() => setAddCardShow(true)}
              >
                <Edit color="#395E9D" />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box gap="small" justify="center">
            <Box
              direction="row"
              justify="center"
              gap="small"
              pad="xsmall"
              background="#395E9D"
              className="noOutline"
              style={{
                fontSize: "small",
                color: "white",
                borderRadius: "8px",
              }}
              onClick={() => setAddCardShow(true)}
            >
              <Add /> Add Payment Method
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MethodsGroup;
