import {
  SET_CLINIC_ADMIN_BY_ID,
  SET_PENDING_ASSESSMENTS,
  SET_THIS_CARE_GIVER,
} from "./actions";

export default function careGiverReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_THIS_CARE_GIVER: {
      const { data } = payload;
      return { ...state, user: data };
    }
    case SET_PENDING_ASSESSMENTS: {
      const { data } = payload;
      return { ...state, pending_assessments: data };
    }
    case SET_CLINIC_ADMIN_BY_ID: {
      const { data } = payload;
      return { ...state, child_admin: data };
    }
    default:
      return state;
  }
}
