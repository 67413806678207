import React, { useEffect, useState } from "react";
import { Avatar, Box, Select, Text } from "grommet";
import { useSelector } from "react-redux";
import { Image } from "grommet-icons";
import moment from "moment";

const MessageRow = ({
  convData,
  changePeer,
  patients,
  staff,
  unreadCounts,
}) => {
  const id = useSelector((state) => state.app.user.user.id);

  const getSenderName = (senderID) => {
    if (staff.find((item) => item.id === senderID))
      return `${staff.find((item) => item.id === senderID).first_name} ${
        staff.find((item) => item.id === senderID).last_name
      }`;

    if (patients.find((item) => item.id === senderID))
      return `${patients.find((item) => item.id === senderID).first_name} ${
        patients.find((item) => item.id === senderID).last_name
      }`;

    return id;
  };

  const getNameInitials = (peer) => {
    const thisPatient = patients.find(
      (item) => item.id.toString() === peer.toString()
    );

    if (thisPatient)
      return thisPatient.first_name[0] + thisPatient.last_name[0];

    return peer;
  };

  if (patients.find((item) => item.id === convData.to))
    return (
      <Box
        key={convData.id}
        className="noOutline"
        style={{ minHeight: "60px", marginTop: "2%" }}
        onClick={() =>
          changePeer(convData.to.toString(), getSenderName(convData.to))
        }
      >
        <Box
          direction="row"
          background="#395E9D07"
          round="8px"
          gap="small"
          align="center"
          justify="start"
          pad="small"
        >
          <Avatar
            background="#ffffff"
            style={{ border: "2px solid #395E9D50" }}
          >
            <Box align="center" justify="center">
              <Text
                color="#28539e"
                style={{ textTransform: "uppercase" }}
                width="20px"
                placeholder="reciever"
              >
                {getNameInitials(convData.to)}
              </Text>
            </Box>
          </Avatar>
          <Box width="80%">
            <Box direction="row" justify="between">
              <Text color="#395E9D" weight="bold" size="small">
                {getSenderName(convData.to)}
              </Text>
              {unreadCounts[convData.to] > 0 ? (
                <Box
                  background="#2BC2F7"
                  margin={{ right: "small" }}
                  round="full"
                  pad="3px"
                  justify="center"
                  align="center"
                  width="20px"
                  height="20px"
                >
                  <Text size="xsmall" color="white">
                    {unreadCounts[convData.to]}
                  </Text>
                </Box>
              ) : null}
            </Box>
            <Box direction="row" align="end" justify="between">
              <Box
                width="60%"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  color: "#828282",
                }}
              >
                {convData.message_type === "text" ? (
                  <Text size="small">
                    {convData.sender === id
                      ? convData.message
                      : `${getSenderName(convData.sender)}: ${
                          convData.message
                        }`}
                  </Text>
                ) : (
                  <>
                    {convData.sender === id ? (
                      <Image size="small" />
                    ) : (
                      <Text size="small">
                        {getSenderName(convData.sender)}: <Image size="small" />
                      </Text>
                    )}
                  </>
                )}
              </Box>

              <Box width="30%">
                <Text size="xsmall" color="#2BC2F7">
                  {moment().format("MM/DD/YY") ===
                  moment(convData.created).format("MM/DD/YY")
                    ? moment(convData.created).format("hh:mm a")
                    : moment(convData.created).format("MM/DD/YY")}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );

  return null;
};

const Sidebar = (props) => {
  const { onSelect, peerID } = props;
  const [options, setOptions] = useState([]);
  const [searchOcurring, setSearchOcurring] = useState(false);

  const alloptions = [];

  const processedMessages = [];
  const unreadCounts = [];

  const patients = useSelector((state) =>
    state.patients.chat_patients ? state.patients.chat_patients.patients : []
  );
  const staff = useSelector((state) => state.patients.staff);

  const allMessages = useSelector(
    (state) => state.patients.all_messages.results
  );

  useEffect(() => {
    if (!searchOcurring)
      patients.forEach((data, index) => {
        options[index] = {
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        };
      });

    patients.forEach((data, index) => {
      alloptions[index] = {
        value: `${data.first_name} ${data.last_name}`,
        key: data.id,
      };
    });
  }, [options]);

  if (allMessages) {
    allMessages.forEach((data) => {
      if (!unreadCounts[data.to]) unreadCounts[data.to] = 0;

      if (unreadCounts[data.to] >= 0) {
        if (data.read_status === false) unreadCounts[data.to] += 1;
      }

      if (!processedMessages[data.to]) processedMessages[data.to] = data;
    });

    processedMessages.sort((a, b) => new Date(b.created) - new Date(a.created));
  }

  const processSearch = (recieved) => {
    if (recieved === "") {
      setSearchOcurring(false);
      setOptions(alloptions);
    }

    if (recieved !== "") {
      setSearchOcurring(true);
      setOptions(
        alloptions.filter((s) =>
          s.value.toLowerCase().includes(recieved.toLowerCase())
        )
      );
    }
  };

  return (
    <Box
      width="40%"
      gap="small"
      pad="medium"
      justify="start"
      height="100%"
      style={{ overflowY: "hidden", maxHeight: "700px" }}
    >
      <Text color="#1B3664" weight="bold" size="36px">
        Chat
      </Text>
      <Box background="#395E9D10" round="8px">
        <Select
          value={peerID}
          plain
          placeholder="Start new chat"
          labelKey="value"
          valueKey={{ key: "key", reduce: true }}
          options={options}
          className="smalltext"
          emptySearchMessage="No Matches Found"
          searchPlaceholder="Search"
          onSearch={(recieved) => processSearch(recieved)}
          // eslint-disable-next-line no-unused-vars
          onChange={({ value, option }) => {
            onSelect(option.key.toString(), option.value);
            setOptions(alloptions);
          }}
          dropProps={{
            style: { borderRadius: "10px", overflowX: "hidden" },
            className: "abcde",
          }}
          style={{ fontSize: "small" }}
          required
          dropHeight="small"
          size="small"
        />
      </Box>
      <Box height="90%" style={{ overflowY: "auto" }}>
        {processedMessages.map((item) => (
          <MessageRow
            convData={item}
            key={item.id}
            unreadCounts={unreadCounts}
            changePeer={onSelect}
            patients={patients}
            staff={staff}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;
