import React, { useState } from "react";
import { Box, Button, Form, TextInput, Text, Image } from "grommet";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { FormView, Hide } from "grommet-icons";
import { push } from "connected-react-router";
import { isMobileHook } from "../../common/Responsive";
import { createNotification, loginManual } from "../../../store/app/actions";
import logo from "../../../assets/dark-logo.png";
import theme from "../../../theme";

const SoftButton = styled(Button)`
  border-radius: 10px;
  background-color: #2bc2f7;
  border: none;
`;

const LoadingSoftButton = ({ label, submitting }) => (
  <Box height="xxsmall">
    <SoftButton
      fill
      primary
      type="submit"
      disabled={submitting}
      label={
        submitting ? (
          <Loader
            type="TailSpin"
            height={30}
            color={theme.global.colors.brand}
          />
        ) : (
          <Text color="#FFFFFF">{label}</Text>
        )
      }
    />
  </Box>
);

const ManualLogin = () => {
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const isMobile = isMobileHook();

  const dispatch = useDispatch();

  const loginError = () => {
    setSubmitting(false);
    dispatch(createNotification("Incorrect Username/Password", false));
  };

  const loginEmail = (form) => {
    setSubmitting(true);
    dispatch(loginManual(form, loginError));
  };

  const onChangeForm = () => {
    if (errors.detail) setErrors({});
  };

  return (
    <Box
      className="wrap-login"
      width={isMobile ? "320px" : "400px"}
      round="10px"
      justify="center"
    >
      <Box alignSelf="center" margin={{ bottom: "medium", top: "xsmall" }}>
        <Image alignSelf="center" src={logo} height="45px" width="146px" />
      </Box>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="large"
        weight="bold"
        color="#1B3664"
      >
        Sign In
      </Text>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="small"
        weight="normal"
        color="black"
      >
        Enter your details to access your dashboard
      </Text>

      <Box
        align="center"
        justify="center"
        direction="row"
        margin={{ top: "xxsmall", bottom: "xxsmall" }}
      />
      <Form
        onChangeCapture={() => onChangeForm()}
        onSubmit={loginEmail}
        errors={errors}
      >
        <Text size="xsmall" weight="bold" color="#1B3664">
          Username / Email Address
        </Text>
        <Box
          border={{ side: "all", color: "#C4C4C4" }}
          round="small"
          margin={{ top: "6px" }}
        >
          <TextInput
            size="small"
            name="username"
            plain
            type="text"
            placeholder="Your Username or Email Address"
            className="loginInput"
            required
          />
        </Box>
        <Box margin={{ top: "xsmall", bottom: "xsmall" }} />
        <Text size="xsmall" weight="bold" color="#1B3664">
          Password
        </Text>
        <Box
          border={{ side: "all", color: "#C4C4C4" }}
          round="small"
          margin={{ top: "6px" }}
          align="center"
          direction="row"
        >
          <TextInput
            name="password"
            plain
            type={showPass ? "text" : "password"}
            className="passwordInput"
            placeholder="Your Password"
            required
          />
          <Box margin={{ right: "small" }}>
            {showPass ? (
              <FormView
                color="#92929D"
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <Hide color="#92929D" onClick={() => setShowPass(!showPass)} />
            )}
          </Box>
        </Box>
        <Box
          align="center"
          justify="end"
          direction="row"
          margin={{ bottom: "xsmall", top: "xsmall" }}
        >
          <Text
            onClick={() => dispatch(push(`/resetPassword`))}
            style={{ cursor: "pointer" }}
            margin="xsmall"
            size="small"
            color="#4F4F4F"
          >
            Forgot Password
          </Text>
        </Box>

        <Box align="center" justify="center" direction="row" margin={{}}>
          {errors.detail ? (
            <Text
              margin={{ top: "xxxsmall", bottom: "xsmall" }}
              size="small"
              color="red"
            >
              {errors.detail}
            </Text>
          ) : null}
        </Box>

        <LoadingSoftButton submitting={submitting} label="Sign In" />
      </Form>

      <Box align="center" justify="center" margin={{ top: "" }}>
        <Text margin="medium" size="small" color="#4F4F4F" textAlign="center">
          Don&apos;t have a Rose account yet? <br />
          <a
            href="https://rosehealth.com/schedule.html"
            className="schedule-demo"
          >
            Schedule a Demo&nbsp;
          </a>
          to get started
        </Text>
      </Box>
    </Box>
  );
};

export default ManualLogin;
