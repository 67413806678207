import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckBox,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";
import InPageLoader from "./InPageLoader";
import {
  getAllClinics,
  getGroups,
  getPatientsDataSales,
  getTestGroups,
} from "../../store/sales/actions";
import { Document, Search, Unsorted } from "grommet-icons";
import { cloneDeep } from "lodash";

const AllClinicsList = () => {
  const [loading, setLoading] = useState(false);
  const [exportMode, setExportMode] = useState(false);

  const clinics = useSelector((state) => state.sales.all_clinics);
  const groups = useSelector((state) => state.sales.clinic_groups);
  const testGroups = useSelector((state) => state.sales.clinic_groups_test);
  const salesPatients = useSelector((state) => state.sales.sales_patients);
  const [sortParam, setSortParam] = useState();
  const [reverse, setReverse] = useState(false);
  const [removeDemo, setRemoveDemo] = useState(false);

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  const getGroupName = (id) => {
    let thisGroup;
    if (groups) {
      thisGroup = groups.find((x) => x.id === id);
      if (thisGroup) return thisGroup.name;
    }

    if (testGroups) {
      thisGroup = testGroups.find((x) => x.id === id);
      if (thisGroup) return thisGroup.name;
    }

    return id;
  };

  const getStatusToDisplay = (status, id) => {
    let thisGroup;
    thisGroup = groups.find((x) => x.id === id);

    if (!thisGroup) return "Demo";
    if (status) return "Active";
    else return "Inactive";
  };

  const getNoOfPatients = (clinicID) => {
    let count = 0;
    if (salesPatients)
      salesPatients.results.forEach((patient) => {
        if (patient.clinic === clinicID) count++;
      });

    return count;
  };

  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);

  const paginatedResults = [];

  let counter = 1;

  const pageSie = exportMode ? 999999999999 : 10;

  let toPage = 0;

  function getFiltered() {
    const toPlay = clinics.filter((clinic) =>
      groups.find((x) => x.id === clinic.clinic_group)
    );

    return toPlay;
  }

  // if (clinics && groups) {
  //   const toPlay = clinics;

  //   for (let i = 0; i < toPlay.length; i += 1) {
  //     if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

  //     paginatedResults[toPage].push(toPlay[i]);
  //     counter += 1;

  //     if (counter > pageSie) {
  //       toPage += 1;
  //       counter = 1;
  //     }
  //   }
  // }

  const pages = [];

  const dispatch = useDispatch();

  const success = () => {
    setLoading(false);
  };
  const successInitial = () => {
    dispatch(getAllClinics(success, ""));
  };

  if (clinics) {
    const clinicsSortCopy = cloneDeep(clinics);
    if (sortParam === "Name")
      clinicsSortCopy.sort((a, b) => a.name.localeCompare(b.name));

    if (sortParam === "Created")
      clinicsSortCopy.sort((a, b) => new Date(a.created) - new Date(b.created));

    if (sortParam === "Type")
      clinicsSortCopy.sort((a, b) =>
        a.type && b.type
          ? a.type.localeCompare(b.type)
          : a.type
          ? 1
          : b.type
          ? -1
          : 0
      );

    if (sortParam === "Group")
      clinicsSortCopy.sort((a, b) =>
        getGroupName(a.clinic_group) && getGroupName(b.clinic_group)
          ? getGroupName(a.clinic_group).localeCompare(
              getGroupName(b.clinic_group)
            )
          : getGroupName(a.clinic_group)
          ? 1
          : getGroupName(b.clinic_group)
          ? -1
          : 0
      );

    if (sortParam === "No of Patients")
      clinicsSortCopy.sort(
        (a, b) => getNoOfPatients(b.id) - getNoOfPatients(a.id)
      );

    if (sortParam === "No of Providers")
      clinicsSortCopy.sort(
        (a, b) =>
          (b.invoicing_metadata
            ? b.invoicing_metadata.number_of_providers_to_charge_monthly_fees
            : 0) -
          (a.invoicing_metadata
            ? a.invoicing_metadata.number_of_providers_to_charge_monthly_fees
            : 0)
      );

    if (sortParam === "Contract Termination")
      clinicsSortCopy.sort(
        (a, b) =>
          (b.invoicing_metadata
            ? new Date(b.invoicing_metadata.contract_termination_date)
            : new Date("1945-03-25")) -
          (a.invoicing_metadata
            ? new Date(a.invoicing_metadata.contract_termination_date)
            : new Date("1945-03-25"))
      );

    if (reverse) clinicsSortCopy.reverse();

    for (let i = 0; i < clinicsSortCopy.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      if (!removeDemo) {
        paginatedResults[toPage].push(clinicsSortCopy[i]);
        counter += 1;
      }

      if (removeDemo) {
        if (
          !clinicsSortCopy[i].invoicing_metadata ||
          getStatusToDisplay(
            clinicsSortCopy[i].invoicing_metadata.status,
            clinicsSortCopy[i].clinic_group
          ) !== "Inactive"
        ) {
          paginatedResults[toPage].push(clinicsSortCopy[i]);
          counter += 1;
        }
      }

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }
  }
  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getGroups(successInitial, ""));
    dispatch(getTestGroups(success, ""));
    dispatch(getPatientsDataSales(success));
  }, []);

  useEffect(() => {
    dispatch(getAllClinics(success, searchTerm));
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllClinics(success, ""));
  }, [sortParam, reverse]);

  if (loading || !clinics || !groups || !testGroups || !salesPatients)
    return (
      <Box pad="medium" gap="small" className="large-card">
        <InPageLoader />
      </Box>
    );

  return (
    <>
      <Box
        direction="row"
        align="center"
        justify="between"
        margin={{ bottom: "small" }}
      >
        <Box
          round="small"
          align="center"
          justify="between"
          direction="row"
          // height="38px"
          width="320px"
          className="whiteBack"
        >
          <TextInput
            name="search"
            plain
            type="text"
            className="searchInput"
            placeholder="Search"
            value={searchTerm}
            onChangeCapture={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            required
          />

          <Box margin={{ right: "small" }}>
            <Search color="#395E9D" />
          </Box>
        </Box>
        <Box direction="row" gap="small" align="start" justify="start">
          <CheckBox
            size="small"
            toggle
            checked={removeDemo}
            onClick={() => {
              setPage(0);
              setRemoveDemo(!removeDemo);
            }}
          ></CheckBox>
          <Text size="small">Remove Inactive Accounts</Text>
        </Box>
        <Box direction="row" gap="small" align="start" justify="start">
          <CheckBox
            size="small"
            toggle
            checked={exportMode}
            onClick={() => setExportMode(!exportMode)}
          ></CheckBox>
          <Text size="small">Export Mode</Text>
        </Box>
        <Box direction="row" gap="small" align="center">
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            className="customInput"
          >
            <Select
              plain
              clear
              options={[
                "Name",
                "Created",
                "Group",
                "No of Patients",
                "No of Providers",
                "Contract Termination",
              ]}
              placeholder="Sort by"
              dropProps={{
                style: { borderRadius: "10px" },
              }}
              dropHeight="small"
              size="small"
              name="sort"
              value={sortParam}
              onChange={({ option }) => {
                setSortParam(option);
              }}
            />
          </Box>
          {sortParam ? <Unsorted onClick={() => setReverse(!reverse)} /> : null}
        </Box>
        <Box
          onClick={() => htmlToCSV("data.csv")}
          pad="small"
          style={{ maxHeight: "42px" }}
          alignSelf="end"
          align="center"
          className="selectReports"
          direction="row"
          gap="small"
        >
          <Text weight="bold" size="small" color="#395E9D">
            Download as CSV
          </Text>
          <Document color="#ADB5BD" />
        </Box>
      </Box>
      <Box pad="medium" gap="small" className="large-card">
        <Box style={{ overflowX: "auto" }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    NAME
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    CREATED
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    CLINIC GROUP
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    CONTRACT STATUS
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    CONTRACT TERMINATION
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    ADDRESS
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    PROVIDERS
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    # PATIENTS
                  </Text>
                </TableCell>
                <TableCell scope="col" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {clinics.length > 0 &&
                paginatedResults[page].map((clinic) => (
                  <TableRow key={clinic.id}>
                    <TableCell scope="row">
                      <Text size="small">{clinic.name}</Text>
                    </TableCell>

                    <TableCell align="start">
                      <Text size="small">
                        {moment(clinic.created).format("MM/DD/YYYY")}
                      </Text>
                    </TableCell>
                    <TableCell scope="row">
                      <Text size="small">
                        {getGroupName(clinic.clinic_group)}
                      </Text>
                    </TableCell>
                    <TableCell scope="row">
                      <Text size="small">
                        {clinic.invoicing_metadata
                          ? getStatusToDisplay(
                              clinic.invoicing_metadata.status,
                              clinic.clinic_group
                            )
                          : "Not Setup"}
                      </Text>
                    </TableCell>
                    <TableCell scope="row">
                      <Text size="small">
                        {clinic.invoicing_metadata
                          ? moment(
                              clinic.invoicing_metadata
                                .contract_termination_date
                            ).format("MM/DD/YYYY")
                          : "Not Setup"}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size="small">
                        {clinic.street_address_1} {clinic.street_address_2}{" "}
                        {clinic.city} {clinic.state}
                      </Text>
                    </TableCell>
                    <TableCell scope="row">
                      <Text size="small">
                        {clinic.invoicing_metadata
                          ? clinic.invoicing_metadata
                              .number_of_providers_to_charge_monthly_fees
                          : "Not Setup"}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">{getNoOfPatients(clinic.id)}</Text>
                    </TableCell>
                    <TableCell>
                      <Box direction="row" justify="center" align="center">
                        <Button
                          className="btn-primary"
                          color="#2BC2F7"
                          onClick={() =>
                            dispatch(push(`/dashboard/clinic/?${clinic.id}`))
                          }
                          margin={{ horizontal: "small" }}
                          size="small"
                          label="View"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box width="100%">
            <Box height="1px" background="#395E9D" />
            <Box
              direction="row"
              width="100%"
              justify="between"
              align="center"
              pad={{ horizontal: "small" }}
            >
              <Box>
                <Text size="small" color="#395E9D">
                  Showing{" "}
                  {clinics.length === 0
                    ? 0
                    : pageSie * (page + 1) - (pageSie - 1)}{" "}
                  to{" "}
                  {pages[page + 1]
                    ? pageSie * (page + 1)
                    : getFiltered().length}{" "}
                  of {getFiltered().length} entries
                </Text>
              </Box>
              <Box direction="row" align="center">
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page - 1] ? setPage(page - 1) : null
                  }
                  disabled={!paginatedResults[page - 1]}
                >
                  <Text size="small">
                    {clinics.length === 0 ? null : "Previous"}
                  </Text>
                </Button>
                {pages.map((vals) => (
                  <Text
                    key={vals}
                    className={vals === page ? "activePage" : "inactivePage"}
                    textAlign="center"
                    size="small"
                    margin="xsmall"
                    color="#82D4E7"
                    onClick={() => setPage(vals)}
                  >
                    {vals + 1}
                  </Text>
                ))}
                {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page + 1] ? setPage(page + 1) : null
                  }
                  disabled={!paginatedResults[page + 1]}
                >
                  <Text size="small">
                    {clinics.length === 0 ? null : "Next"}
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AllClinicsList;
