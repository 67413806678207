import React, { useEffect, useState } from "react";
import { Box, Grid } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import BarChart from "./BarChart";
import LikertScale from "./LikertScale";
import DailyCheckIns from "./DailyCheckIns";
import JounralEntries from "./JounralEntries";
// import StickyNotes from "./StickyNotes";

import { isMobileHook, iTabletHook } from "./Responsive";
import ToFromRow from "./ToFromRow";
import PainScale from "./PainScale";
import SleepScale from "./SleepScale";
import Loading from "./InPageLoader";
import {
  fetchAssessmentResults,
  fetchAssessmentResultsRecent,
  getAssessments,
} from "../../store/patients/actions";
import LatestAssessmentBox from "./LatestAssessmentBox";
import Histogram from "./Histogram";
import PieChartYesNo from "./PieChartYesNo";
import YOQChart from "./YOQChart";
import ViewPastAssessments from "./ViewPastAssessments";

const MentalHealth = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const patientId = split(search, "?", 2)[1];
  const results = useSelector((state) => state.patients.assessment_results);
  const resultsRecent = useSelector(
    (state) => state.patients.assessment_results_recent
  );
  const assessmentTypes = useSelector(
    (state) => state.patients.assessment_types
  );
  let resultArray;
  const schedulableAssessments = [];
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()
  );
  var Difference_In_Time =
    new Date(endDate).getTime() - new Date(startDate).getTime();

  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  if (resultsRecent) resultArray = Object.entries(resultsRecent);

  const onSuccess = () => {};

  if (assessmentTypes)
    assessmentTypes.forEach((x, index) => {
      schedulableAssessments[index] = x.name;
    });

  useEffect(() => {
    dispatch(fetchAssessmentResults(patientId, onSuccess, startDate, endDate));
    dispatch(fetchAssessmentResultsRecent(patientId, onSuccess));
    dispatch(getAssessments(onSuccess));
  }, []);

  if (results && assessmentTypes && resultsRecent)
    return (
      <>
        <Box width="100%" height="100%">
          <Box pad="small" id="pdf2">
            <Grid
              columns={isMobileorTablet ? "large" : { count: 2, size: "auto" }}
              gap="medium"
              margin={{ bottom: "large" }}
            >
              {resultArray.map((result) => {
                if (
                  result[1].length !== 0 &&
                  schedulableAssessments.find((key) => key === result[0])
                )
                  return (
                    <LatestAssessmentBox
                      tests={result[1]}
                      key={result[0]}
                      name={result[0]}
                      assessmentTypes={assessmentTypes}
                    />
                  );
                return null;
              })}
            </Grid>

            <Box
              justify="between"
              align="center"
              wrapProp={"true"}
              direction="row"
            >
              <ViewPastAssessments />
              <ToFromRow
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                start={startDate}
                end={endDate}
              />
            </Box>

            {results.hasOwnProperty("PHQ-8") &&
            results.hasOwnProperty("GAD-7") &&
            results.hasOwnProperty("PHQ-9") ? (
              <BarChart
                PHQ8={results["PHQ-8"]}
                GAD7={results["GAD-7"]}
                PHQ9={results["PHQ-9"]}
              />
            ) : null}
          </Box>
          <Box pad="small" id="pdf3">
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            >
              {results.hasOwnProperty("Journal-Mood") &&
              results.hasOwnProperty("Journal-Anxiety") &&
              (results["Journal-Mood"].length > 0 ||
                results["Journal-Anxiety"].length > 0) ? (
                <LikertScale
                  mood={results["Journal-Mood"]}
                  anxiety={results["Journal-Anxiety"]}
                />
              ) : null}
              {results.hasOwnProperty("journal") &&
              results["journal"].length > 0 ? (
                <DailyCheckIns results={results.journal} />
              ) : null}
            </Box>
          </Box>
          <Box pad="small" id="pdf3">
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            >
              {results.hasOwnProperty("SCARED-1") &&
              results["SCARED-1"].length > 0 ? (
                <Histogram
                  data={results["SCARED-1"]}
                  yAxisMax={100}
                  nameToPut="SCARED-1"
                  yAxisLabel="SCARED Score"
                  heading="Youth SCARED Assessments"
                  barColor="#395E9D"
                />
              ) : null}
              {results.hasOwnProperty("Columbia-1") &&
              results["Columbia-1"].length > 0 ? (
                <Histogram
                  data={results["Columbia-1"]}
                  yAxisMax={22}
                  nameToPut="Columbia-1"
                  yAxisLabel="CDS Score"
                  heading="Columbia Depression Scale"
                  barColor="#45CAF8"
                />
              ) : null}
            </Box>
          </Box>
          <Box pad="small" id="pdf4">
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            >
              {results.hasOwnProperty("Pain") && results["Pain"].length > 0 ? (
                <PainScale results={results.Pain} />
              ) : null}
              {results.hasOwnProperty("Journal-Sleep") &&
              results["Journal-Sleep"].length > 0 ? (
                <SleepScale sleep={results["Journal-Sleep"]} />
              ) : null}
            </Box>
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            ></Box>
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            >
              {results.hasOwnProperty("Daily-DBT") &&
              results["Daily-DBT"].length > 0 ? (
                <PieChartYesNo
                  data={results["Daily-DBT"]}
                  days={Difference_In_Days}
                  nameToPut="Daily-DBT"
                  heading="Daily-DBT"
                />
              ) : null}
              {results.hasOwnProperty("Daily-Med") &&
              results["Daily-Med"].length > 0 ? (
                <PieChartYesNo
                  data={results["Daily-Med"]}
                  days={Difference_In_Days}
                  nameToPut="Daily-Med"
                  heading="Daily-Med"
                />
              ) : null}
            </Box>
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              justify="evenly"
              gap="small"
            >
              {results.hasOwnProperty("YOQ-1") &&
              results["YOQ-1"].length > 0 ? (
                <YOQChart
                  data={results["YOQ-1"]}
                  heading="Youth Outcome Questionaire (YOQ)"
                />
              ) : null}
            </Box>
          </Box>
          <Box id="pdf5" pad="small">
            <Box direction="row" justify="evenly" gap="small">
              <JounralEntries results={results.journal} />
            </Box>
          </Box>
        </Box>
      </>
    );
  return <Loading />;
};
export default MentalHealth;
