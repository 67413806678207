import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";

const BarChart = ({ PHQ8, GAD7, Pain }) => {
  let xAxisData = ["No Data Found"];
  const PHQAxisData = [];
  const GADAxisData = [];
  const PainAxisData = [];

  const PHQSeriesData = [];
  const GAD7SeriesData = [];
  const PainSeriesData = [];

  const SortedPHQ = [];
  const SortedGAD = [];
  const SortedPain = [];

  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const getOption = () => {
    PHQ8.forEach((element, index) => {
      PHQAxisData[index] = element.created;
    });
    GAD7.forEach((element, index) => {
      GADAxisData[index] = element.created;
    });
    Pain.forEach((element, index) => {
      PainAxisData[index] = element.created;
    });

    const union = [
      ...new Set([...PHQAxisData, ...GADAxisData, ...PainAxisData]),
    ];
    union.sort();
    if (union.length !== 0) {
      xAxisData = union;
      xAxisData.forEach((item, index) => {
        xAxisData[index] = moment(item).format("DD MMM");
      });
      xAxisData = [...new Set(xAxisData)];
    }

    PHQ8.forEach((element, index) => {
      PHQSeriesData[index] = [element.created, element.score];
    });
    GAD7.forEach((element, index) => {
      GAD7SeriesData[index] = [element.created, element.score];
    });
    Pain.forEach((element, index) => {
      PainSeriesData[index] = [element.created, element.score];
    });

    PHQSeriesData.sort();

    PHQSeriesData.forEach((element, index) => {
      SortedPHQ[index] = [moment(element[0]).format("DD MMM"), element[1]];
    });

    GAD7SeriesData.sort();
    GAD7SeriesData.forEach((element, index) => {
      SortedGAD[index] = [moment(element[0]).format("DD MMM"), element[1]];
    });

    PainSeriesData.sort();
    PainSeriesData.forEach((element, index) => {
      SortedPain[index] = [moment(element[0]).format("DD MMM"), element[1]];
    });

    return {
      animation: false,

      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${params.seriesName}: ${params.data[1]}`;
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },
          magicType: {
            type: ["bar"],
            title: {
              bar: "Show Bar Graph",
            },
          },
          restore: {
            title: "Restore",
          },
          saveAsImage: {
            title: "Save As Image",
            name: `PHQ8 and GAD7 ${moment(new Date()).format(
              "MM DD YYYY"
            )} ${firstName} ${lastName}`,
          },
        },
      },

      legend: {
        show: true,
        top: 270,
        data: ["GAD-7", "PHQ-8", "Pain"],
      },

      xAxis: [
        {
          offset: 5,
          type: "category",
          data: xAxisData,
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          max: 24.0,
          min: 0,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: "value",
          show: false,
          name: "scores",
          max: 28,
          min: 0,
        },
      ],
      series: [
        {
          name: "PHQ-8",
          type: "line",
          color: "#FDA004",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedPHQ,
        },
        {
          name: "GAD-7",
          type: "line",
          color: "#395E9D",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedGAD,
        },
        {
          name: "Pain",
          type: "line",
          color: "#ffabab",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedPain,
        },
      ],
    };
  };

  return (
    <Box>
      <Text alignSelf="start" weight="bold" color="#395E9D">
        Patient Engaged
      </Text>

      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default BarChart;
