import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const PieChartYesNo = ({ data, days, nameToPut, heading }) => {
  let YesCount = 0;
  let NoCount = 0;
  let NoResponseCount = days;

  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const getOption = () => {
    data.forEach((element, index) => {
      if (element.score === 1) YesCount = YesCount + 1;

      if (element.score === 0) NoCount = NoCount + 1;
    });

    NoResponseCount = NoResponseCount - YesCount - NoCount;
    YesCount = (YesCount / days) * 100;
    NoCount = (NoCount / days) * 100;
    NoResponseCount = (NoResponseCount / days) * 100;

    return {
      legend: {
        show: true,
        top: 280,
        orient: "horizontal",
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            title: "Save As Image",
            name:
              nameToPut +
              ` ${moment(new Date()).format(
                "MM DD YYYY"
              )} ${firstName} ${lastName}`,
          },
        },
      },

      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${params.name}  <br/> ${params.seriesName}:  ${Math.round(
            params.value
          )}%`;
        },
      },

      series: [
        {
          color: ["#395E9D", "#FA8581", "#82D4E7"],
          name: nameToPut,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          data: [
            { value: YesCount, name: "Yes" },
            { value: NoCount, name: "No" },
            { value: NoResponseCount, name: "Not Responded" },
          ],
          type: "pie",
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          {heading}
        </Text>
        <CustomToolTip
          outText="i"
          content="Single-item questions to assess the patient's daily mental well-being."
          subText="Data shown for the selected date range."
        />
      </Box>
      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default PieChartYesNo;
