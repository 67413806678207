import React, { useEffect, useState } from "react";
import { Box, Heading, Image, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBillingPatients,
  fetchPatients,
  getStaff,
} from "../../../../store/patients/actions";
import InfoCard from "../../../common/InfoCard";
import InPageLoader from "../../../common/InPageLoader";
import { RowCardContainer } from "../../../common/RowCardContainer";
import HistogramCategories from "../../../common/HistogramCategories";
import moment from "moment";
import LineGraphCategories from "../../../common/LineGraphCategories";
import RedAlert from "../../../../assets/icons/red-alert-flag.svg";
import YellowAlert from "../../../../assets/icons/yellow-alert-flag.svg";
import GreenAlert from "../../../../assets/icons/green-alert-flag.svg";
import { Emoji, Group, LineChart, UserNew } from "grommet-icons";
import {
  getAssesmentCount,
  getAssessmentImprovement,
  getDepressionRemission,
  getFlagCount,
  getLastActiveCount,
  getMessageCount,
  processAssessmentImprovement,
} from "../../../../store/aco/actions";
import {
  getAssessmentValues,
  getColorsArrayOfLength,
  getFlagValues,
  getWeekDayValues,
} from "../../../../util/globalFunctions";
import ToFromRowGeneric from "../../../common/ToFromRowGeneric";

const SingleFlagRow = ({ name, count, img }) => (
  <Box>
    <Heading level={2} color="black">
      {count}
    </Heading>
    <Box direction="row" gap="small" align="center">
      <Image src={img} alt={name} width="15px" />
      <Text size="small">{name}</Text>
    </Box>
  </Box>
);

const HorizontalDivider = () => (
  <Box
    width="2px"
    height="100%"
    background="#C4C4C430"
    margin={{ horizontal: "small" }}
  ></Box>
);
const ACOExecutiveHome = () => {
  const dispatch = useDispatch();

  const success = () => true;

  const staff = useSelector((state) => state.patients.staff);
  const user = useSelector((state) => state.app.user.user);
  const messagescount = useSelector((state) => state.aco.message_count);
  const flagcount = useSelector((state) => state.aco.flag_count);
  const assessmentscount = useSelector((state) => state.aco.assessment_count);
  const lastactivecount = useSelector((state) => state.aco.last_active_count);
  const assessmentImprovement = useSelector(
    (state) => state.aco.assessment_improvement
  );
  const depressionremissionrate = useSelector(
    (state) => state.aco.depression_remission_rate
  );

  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()
  );
  const patients = useSelector((state) => state.patients.patients);
  const billing_patients = useSelector((state) => state.patients.patientsdata);

  const assessmentCallback = () => {
    dispatch(
      getAssessmentImprovement(
        2,
        "PHQ8",
        moment(startDate).format("YYYY-MM-01"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getAssessmentImprovement(
        10,
        "PHQ9",
        moment(startDate).format("YYYY-MM-01"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getAssessmentImprovement(
        1,
        "GAD7",
        moment(startDate).format("YYYY-MM-01"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
  };

  useEffect(() => {
    dispatch(getStaff(success));
    dispatch(
      fetchPatients(success, "999", "", "", "", "", 1, "", "", "All", "")
    );
    dispatch(
      fetchBillingPatients(
        success,
        "10",
        "",
        "",
        "",
        "",
        1,
        "",
        "false",
        "HomeData",
        moment(startDate).format("YYYY-MM-DD"),
        moment(startDate).format("YYYY-MM-DD")
      )
    );
    dispatch(
      getMessageCount(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getAssesmentCount(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getFlagCount(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getLastActiveCount(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );
    dispatch(
      getDepressionRemission(
        moment(startDate).format("YYYY-MM-01"),
        moment(endDate).format("YYYY-MM-DD"),
        user.clinic.id,
        success
      )
    );

    dispatch(processAssessmentImprovement(assessmentCallback));
  }, [startDate, endDate]);

  const getActivePatientsCount = (patients) => {
    let count = 0;

    patients.forEach((x) => {
      const d = new Date();
      d.setDate(d.getDate() - 30);
      if (new Date(x.last_active) > d) count = count + 1;
    });
    return count;
  };

  const getJoinedPatientsCount = (patients) => {
    let count = 0;

    patients.forEach((x) => {
      if (
        new Date(x.date_joined) >= new Date(startDate) &&
        new Date(x.date_joined) <= new Date(endDate)
      ) {
        count = count + 1;
      }
    });
    return count;
  };

  const getBHIEligibleCount = (patients) => {
    let count = 0;

    patients.forEach((x) => {
      if (x.bhi_eligible && !x.bhi_enabled) count = count + 1;
    });
    return count;
  };

  const getBHIEnrolledCount = (patients) => {
    let count = 0;

    patients.forEach((x) => {
      if (x.bhi_enabled) count = count + 1;
    });
    return count;
  };

  const getPHQImprovement = () => {
    if (
      assessmentImprovement.length > 0 &&
      assessmentImprovement.find((x) => x.assessment === "PHQ8") &&
      assessmentImprovement.find((x) => x.assessment === "PHQ9")
    ) {
      const phq8 = assessmentImprovement.find((x) => x.assessment === "PHQ8");
      const phq9 = assessmentImprovement.find((x) => x.assessment === "PHQ9");

      var union = [...new Set([...phq8.patients_list, ...phq9.patients_list])];
      return union.length;
    } else return <span style={{ fontSize: "small" }}>Loading...</span>;
  };
  const getGADImprovement = () => {
    if (
      assessmentImprovement.length > 0 &&
      assessmentImprovement.find((x) => x.assessment === "GAD7")
    ) {
      const gad7 = assessmentImprovement.find((x) => x.assessment === "GAD7");
      return gad7.patients_list.length;
    } else return <span style={{ fontSize: "small" }}>Loading...</span>;
  };
  const getTotalImprovement = () => {
    if (
      assessmentImprovement.length > 0 &&
      assessmentImprovement.find((x) => x.assessment === "GAD7") &&
      assessmentImprovement.find((x) => x.assessment === "PHQ8") &&
      assessmentImprovement.find((x) => x.assessment === "PHQ9")
    ) {
      const phq8 = assessmentImprovement.find((x) => x.assessment === "PHQ8");
      const phq9 = assessmentImprovement.find((x) => x.assessment === "PHQ9");
      const gad7 = assessmentImprovement.find((x) => x.assessment === "GAD7");
      var union = [
        ...new Set([
          ...phq8.patients_list,
          ...phq9.patients_list,
          ...gad7.patients_list,
        ]),
      ];
      return union.length;
    } else return <span style={{ fontSize: "small" }}>Loading...</span>;
  };

  if (
    !staff ||
    !user ||
    !patients ||
    !billing_patients ||
    !messagescount ||
    !flagcount ||
    !assessmentscount ||
    !depressionremissionrate ||
    !assessmentImprovement
  )
    return <InPageLoader />;

  return (
    <Box pad="medium" gap="medium">
      <Heading level={3} color="brand">
        {user.clinic.name}
      </Heading>
      <RowCardContainer>
        <InfoCard
          link="/dashboard/aco/measure?careMembers"
          title="Care Team"
          subTitle="Team Members"
          stat={staff.length}
        />
        <InfoCard
          link="/dashboard/aco/measure?totalPatients"
          title="Total Patients"
          subTitle="Patients till Date"
          stat={patients.length}
        />
        <InfoCard
          link="/dashboard/aco/measure?activePatients"
          title="Active Patients"
          subTitle="Active Patients on Rose"
          stat={getActivePatientsCount(patients)}
        />
      </RowCardContainer>
      <Heading level={3} color="brand">
        Billing Report
      </Heading>
      <HistogramCategories
        barColor=""
        yAxisLabel="Count"
        heading="Billing Stats"
        data={[
          getBHIEligibleCount(patients),
          getBHIEnrolledCount(patients),
          billing_patients.count,
        ]}
        nameToPut="Patients"
        horizontalDatas={[
          "ELIGIBLE FOR BHI",
          "ENROLLED IN BHI",
          "ELLIGIBLE FOR BHI BILLING",
        ]}
        colors={["#395E9D", "#82D4E7", "#2BC2F7"]}
        yAxisMax={patients.length}
      />
      <RowCardContainer alignIn="center" justifyIn="between">
        <Heading level={3} color="brand">
          Patient Engagement Metrics
        </Heading>
        <ToFromRowGeneric
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          start={startDate}
          end={endDate}
        />
      </RowCardContainer>

      <RowCardContainer>
        <LineGraphCategories
          barColor=""
          yAxisLabel=""
          heading=""
          data={getWeekDayValues(messagescount)}
          nameToPut="Total Number Of Messages Engaged"
          horizontalDatas={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
          colors={["#395E9D", "#82D4E7", "#2BC2F7"]}
          yAxisMax={Math.max(...getWeekDayValues(messagescount))}
        />
        <Box gap="small" width="100%">
          <Box className="card" margin={{ top: "small" }}>
            <Heading level={6} color="brand">
              Active Patients Count
            </Heading>
            <Heading level={2} color="black">
              <Group color="black" />{" "}
              {lastactivecount.last_active_patients_count}
            </Heading>
            <Text size="small">
              Patients who used the app in the given time period
            </Text>
          </Box>
          <Box className="card" margin={{ top: "small" }}>
            <Heading level={6} color="brand">
              Patients Joined
            </Heading>
            <Heading level={2} color="black">
              <UserNew color="black" /> {getJoinedPatientsCount(patients)}
            </Heading>
            <Text size="small">
              New patients that signed up in the given time period
            </Text>
          </Box>
        </Box>
      </RowCardContainer>

      <Heading level={3} color="brand">
        Behavioral Health Metrics
      </Heading>
      <RowCardContainer>
        <HistogramCategories
          barColor=""
          yAxisLabel="Count"
          heading=""
          data={Object.values(getAssessmentValues(assessmentscount))}
          nameToPut="Assessments being administered"
          horizontalDatas={Object.keys(getAssessmentValues(assessmentscount))}
          colors={getColorsArrayOfLength(
            Object.values(getAssessmentValues(assessmentscount)),
            "#395E9D"
          )}
          yAxisMax={Math.max(
            ...Object.values(getAssessmentValues(assessmentscount))
          )}
        />
        <Box pad="small" height="100%" width="100%" gap="small">
          <Box className="card">
            <Heading level={6} color="brand">
              Depression Remission (
              {moment(moment(startDate).format("YYYY-MM-01")).format("MMMM")})
            </Heading>
            <Heading level={2} color="black">
              {Math.round(depressionremissionrate.remission_rate * 100)}%
            </Heading>
            <Text size="small">
              Percent of patients who reached depression remission (PHQ-8 ≤ 4)
            </Text>
          </Box>
          <Box className="card">
            <Heading level={6} color="brand">
              Flag Count
            </Heading>
            <RowCardContainer notMakeResponsive={true}>
              <SingleFlagRow
                name="Red Flag"
                count={getFlagValues(flagcount).Red}
                img={RedAlert}
              />
              <HorizontalDivider />
              <SingleFlagRow
                name="Yellow Flag"
                count={getFlagValues(flagcount).Yellow}
                img={YellowAlert}
              />
              <HorizontalDivider />
              <SingleFlagRow
                name="Green Flag"
                count={getFlagValues(flagcount).Green}
                img={GreenAlert}
              />
            </RowCardContainer>
          </Box>

          <Box className="card">
            <Heading level={6} color="brand">
              Patient Satisfaction Score //static
            </Heading>
            <Box align="center" justify="between" direction="row">
              <Box direction="row" align="center" gap="small">
                <Box direction="row" align="baseline">
                  <Text weight="bold" size="28px">
                    12
                  </Text>
                  <Text size="18px">/18</Text>
                </Box>
                <Emoji />
              </Box>
              <Box
                pad="small"
                style={{ cursor: "pointer" }}
                className="btn-primary-dark"
              >
                View Survey
              </Box>
            </Box>
          </Box>
        </Box>
      </RowCardContainer>
      <RowCardContainer>
        <Box className="card">
          <Heading level={6} color="brand">
            PHQ-8 or PHQ-9 Improvement
          </Heading>
          <Heading level={2} color="black">
            <LineChart color="black" /> {getPHQImprovement()}
          </Heading>
          <Text size="small">
            Number of patients who increase by 6 points in the PHQ-8 or PHQ-9
            assessment in the date range
          </Text>
        </Box>
        <Box className="card">
          <Heading level={6} color="brand">
            GAD-7 Improvement
          </Heading>
          <Heading level={2} color="black">
            <LineChart color="black" /> {getGADImprovement()}
          </Heading>
          <Text size="small">
            Number of patients who increase by 6 points in the GAD-7 assessment
            in the date range
          </Text>
        </Box>
        <Box className="card">
          <Heading level={6} color="brand">
            Total Patients Improved
          </Heading>
          <Heading level={2} color="black">
            <LineChart color="black" /> {getTotalImprovement()}
          </Heading>
          <Text size="small">
            Number of patients (excluding duplicates) who increase by 6 points
            in the PHQ-8, PHQ-9 or GAD-7 assessment in the date range
          </Text>
        </Box>
      </RowCardContainer>
    </Box>
  );
};
export default ACOExecutiveHome;
