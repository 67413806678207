/* eslint-disable no-prototype-builtins */
import React from "react";
import { Box, Text, Layer, TextInput, Button, Form, Select } from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { editGroup, getGroup } from "../../store/sales/actions";

const EditModalClinicGroup = ({ display, setDisplayOut }) => {
  const dispatch = useDispatch();

  const thisGroup = useSelector((state) => state.sales.single_group);

  function setGroupValues(group) {
    const obj = {
      name: group.name ? group.name : "",
      type: group.type ? group.type : "",
      street_address_1: thisGroup.street_address_1
        ? thisGroup.street_address_1
        : "",
      street_address_2: thisGroup.street_address_2
        ? thisGroup.street_address_2
        : "",
      city: thisGroup.city ? thisGroup.city : "",
      state: thisGroup.state ? thisGroup.state : "",
      country: thisGroup.country ? thisGroup.country : "",
      zip_code: thisGroup.zip_code ? thisGroup.zip_code : "",
      contact_name: thisGroup.contact_name ? thisGroup.contact_name : "",
      email: thisGroup.email ? thisGroup.email : "",
      contact_number: thisGroup.contact_number ? thisGroup.contact_number : "",
      rose_sales_team_member: thisGroup.rose_sales_team_member
        ? thisGroup.rose_sales_team_member
        : "",
      payment_type: thisGroup.payment_type ? thisGroup.payment_type : "",
    };
    return obj;
  }
  const [value, setValue] = React.useState(
    thisGroup ? setGroupValues(thisGroup) : ""
  );

  const onSuccessFinal = () => {
    setDisplayOut(false);
  };

  const onSuccess = () => {
    dispatch(getGroup(thisGroup.id, onSuccessFinal));
  };

  const groupSubmit = (form) => {
    dispatch(editGroup(thisGroup.id, form, onSuccess));
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-sales"
          position="center"
        >
          <Form
            value={value}
            onSubmit={groupSubmit}
            onChange={(nextValue) => setValue(nextValue)}
          >
            <Box width="100%" gap="medium">
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Name of Healthcare Group
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      dropHeight="small"
                      size="small"
                      name="name"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Type
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={[
                        { key: "PROVIDER", value: "PROVIDER" },
                        { key: "OTHER", value: "OTHER" },
                        {
                          key: "ACCOUNTABLE_CARE_ORGANIZATION_(ACO)",
                          value: "ACCOUNTABLE CARE ORGANIZATION (ACO)",
                        },
                        {
                          key: "EMPLOYER_GROUP",
                          value: "EMPLOYER GROUP",
                        },
                      ]}
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      dropHeight="small"
                      size="small"
                      name="type"
                    />
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Street Address 1
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="street_address_1"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Street Address 2
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="street_address_2"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    City
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="city"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    State
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="state"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Country
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="country"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Zip Code
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="zip_code"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Primary Contact
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="contact_name"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Primary Contact Email Address
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      type="email"
                      name="email"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Primary Contact Number
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="contact_number"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Rose Sales Team Member
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="rose_sales_team_member"
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="50%">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  Payment Type
                </Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                >
                  <Select
                    plain
                    labelKey="value"
                    valueKey={{ key: "key", reduce: true }}
                    options={[
                      { key: "Card", value: "Card" },
                      { key: "Invoice", value: "Invoice" },
                      {
                        key: "ACH",
                        value: "ACH",
                      },
                    ]}
                    dropProps={{
                      style: { borderRadius: "10px" },
                    }}
                    dropHeight="small"
                    size="small"
                    name="payment_type"
                  />
                </Box>
              </Box>
              <Box
                justify="end"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default EditModalClinicGroup;
