import React, { useState } from "react";
import { Box, Text, DateInput, Button } from "grommet";
import { View } from "grommet-icons";

const ToFromRowGeneric = ({ setStartDate, setEndDate, start, end }) => {
  const [internalStart, setInternalStart] = useState(start);
  const [internalEnd, setInternalEnd] = useState(end);

  const processNewDates = () => {
    setStartDate(internalStart);
    setEndDate(internalEnd);
  };

  return (
    <Box direction="row" pad="small" align="center" justify="end" gap="xsmall">
      <Text weight="bold" color="#4F4F4F80">
        From
      </Text>
      <DateInput
        format="mm/dd/yyyy"
        size="small"
        plain
        required
        name="start_date"
        calendarProps={{
          size: "medium",
          daysOfWeek: true,
          fill: true,
          animate: false,
          className: "padCal",
        }}
        value={internalStart}
        onChange={({ value }) => {
          setInternalStart(value);
        }}
        className="myDate"
        icon={<View size="xsmall" style={{ visibility: "hidden" }} />}
      />
      <Text weight="bold" color="#4F4F4F80">
        To
      </Text>
      <DateInput
        format="mm/dd/yyyy"
        size="small"
        plain
        required
        name="end_date"
        calendarProps={{
          size: "medium",
          daysOfWeek: true,
          fill: true,
          animate: false,
          className: "padCal",
        }}
        value={internalEnd}
        onChange={({ value }) => {
          setInternalEnd(value);
        }}
        className="myDate"
        icon={<View size="xsmall" style={{ visibility: "hidden" }} />}
      />
      <Button
        color="transparent"
        className="btn-trans"
        plain
        label="Apply"
        onClick={() => processNewDates()}
      />
    </Box>
  );
};
export default ToFromRowGeneric;
