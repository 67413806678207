import React, { useState, useEffect } from "react";
import { Box, Text, Image } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { Add, Edit } from "grommet-icons";
import PaymentMethod from "../../../common/PaymentMethod";
import { getPaymentMethods } from "../../../../store/patients/actions";
import InPageLoader from "../../../common/InPageLoader";
import noCard from "../../../../assets/noCard.svg";

const Methods = () => {
  const [addCardShow, setAddCardShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [existingMethods, setExisttingMethods] = useState(null);
  const [billingCustomer, setBillingCustomer] = useState(null);

  const user = useSelector((state) => state.app.user.user);
  const showPaymentMethods = (data, customer) => {
    setExisttingMethods(data);
    setBillingCustomer(customer);
    setLoading(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethods(showPaymentMethods));
  }, [loading]);

  if (loading)
    return (
      <Box className="large-card" pad="medium">
        <Text color="#395E9D" weight="bold">
          Billing Information
        </Text>
        <InPageLoader />
      </Box>
    );

  return (
    <>
      {addCardShow && (
        <PaymentMethod
          display={addCardShow}
          setDisplayOut={(value) => setAddCardShow(value)}
          showPaymentMethods={showPaymentMethods}
        />
      )}
      <Box className="large-card" gap="small" pad="medium">
        <Text color="#395E9D" weight="bold">
          Billing Information
        </Text>
        {existingMethods ? (
          <Box gap="medium" direction="row">
            <Box gap="medium">
              <Text style={{ textTransform: "capitalize" }} size="small">
                Credit Card Details
              </Text>
              <Text size="small">Company/Organization Name</Text>
              <Text size="small">Address</Text>
              <Text size="small">Email for billing</Text>
              <Box
                direction="row"
                justify="center"
                gap="small"
                pad="xsmall"
                background="#395E9D"
                className="noOutline"
                style={{
                  fontSize: "small",
                  color: "white",
                  borderRadius: "8px",
                }}
                onClick={() => setAddCardShow(true)}
              >
                <Edit /> Edit Payment Method
              </Box>
            </Box>
            <Box gap="medium">
              <Text style={{ textTransform: "capitalize" }} size="small">
                {existingMethods.card.brand} Card Ending in{" "}
                {existingMethods.card.last4} - Expires{" "}
                {existingMethods.card.exp_month}/{existingMethods.card.exp_year}
              </Text>
              <Text size="small">{user.clinic.clinic_group.name}</Text>
              <Text size="small">
                {user.clinic.address === ""
                  ? "No Address Specified"
                  : user.clinic.address}
              </Text>
              <Text size="small">
                {billingCustomer ? billingCustomer.email : ""}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box pad="small" gap="small" justify="center" align="center">
            <Image src={noCard} width="200px" />
            <Text size="small" color="#395E9D">
              There are no cards added yet.
            </Text>
            <Box
              direction="row"
              justify="center"
              gap="small"
              pad="xsmall"
              background="#395E9D"
              className="noOutline"
              style={{
                fontSize: "small",
                color: "white",
                borderRadius: "8px",
                width: "30%",
              }}
              onClick={() => setAddCardShow(true)}
            >
              <Add /> Add Payment Method
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Methods;
