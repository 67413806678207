import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CheckBox,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import { push } from "connected-react-router";
import { split } from "lodash";
import moment from "moment";
import Loading from "../../../common/InPageLoader";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import { getBulkFiles, getStaff } from "../../../../store/patients/actions";

const BulkUploads = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const [completeChecked, setCompletedChecked] = useState(true);

  const dispatch = useDispatch();

  const files = useSelector((state) => state.patients.files) || [];

  const success = () => {
    setLoading(false);
  };

  const onStaffSuccess = () => true;

  useEffect(() => {
    setLoading(true);
    dispatch(getStaff(onStaffSuccess));
    dispatch(getBulkFiles(success));
  }, []);

  files.sort((a, b) => new Date(b.created) - new Date(a.created));

  return (
    <>
      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box direction="row" width="100%" justify="between" align="center">
            <Heading level={3} color="#1B3664" alignSelf="center">
              Multiple Patient Upload Files
            </Heading>
            <CheckBox
              checked={completeChecked}
              label={<Text color="#1B3664">Show Completed</Text>}
              onChange={() => setCompletedChecked(!completeChecked)}
            />
          </Box>
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <>
            <Box
              width="100%"
              justify="start"
              align="center"
              margin={{ top: "small" }}
            >
              <Box
                direction={isMobileorTablet ? "column" : "row"}
                width="100%"
                justify="evenly"
                gap="medium"
                align="center"
              >
                <Box
                  className="large-card"
                  align="center"
                  justify="center"
                  style={{ overflowX: "scroll" }}
                  gap="small"
                  pad="small"
                >
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <Table alignSelf="center">
                        <TableHeader>
                          <TableRow>
                            <TableCell scope="col">
                              <Text size="small" weight="bold">
                                FILE ID
                              </Text>
                            </TableCell>
                            <TableCell scope="col">
                              <Text size="small" weight="bold">
                                FILE NAME
                              </Text>
                            </TableCell>
                            <TableCell scope="col">
                              <Text size="small" weight="bold">
                                DATE UPLOADED
                              </Text>
                            </TableCell>
                            <TableCell scope="col" align="center">
                              <Text size="small" weight="bold">
                                CLINIC NAME
                              </Text>
                            </TableCell>
                            <TableCell scope="col" align="center">
                              <Text size="small" weight="bold">
                                CLINICIAN ID
                              </Text>
                            </TableCell>
                            <TableCell scope="col" align="center">
                              <Text size="small" weight="bold">
                                STATUS
                              </Text>
                            </TableCell>
                            <TableCell scope="col" align="center" />
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {files &&
                            files.map((file) =>
                              file.mark_as_done && !completeChecked ? null : (
                                <TableRow key={file.id}>
                                  <TableCell scope="row">
                                    <Text size="small" weight="bold">
                                      {file.id}
                                    </Text>
                                  </TableCell>
                                  <TableCell scope="row">
                                    <Text size="small" weight="bold">
                                      {split(file.file, "/", 5)[4]}
                                    </Text>
                                  </TableCell>
                                  <TableCell>
                                    <Text size="small" weight="bold">
                                      {moment(file.created).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </Text>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Text size="xsmall" weight="bold">
                                      {file.clinic_name}
                                    </Text>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Text size="xsmall" weight="bold">
                                      {file.clinician}{" "}
                                    </Text>
                                  </TableCell>
                                  <TableCell>
                                    {file.mark_as_done ? (
                                      <Box
                                        className="gFlag"
                                        pad={{
                                          horizontal: "small",
                                          vertical: "xxsmall",
                                        }}
                                      >
                                        <Text size="10px" textAlign="center">
                                          Completed
                                        </Text>
                                      </Box>
                                    ) : (
                                      <Box
                                        className="rFlag"
                                        pad={{
                                          horizontal: "small",
                                          vertical: "xxsmall",
                                        }}
                                      >
                                        <Text size="10px" textAlign="center">
                                          Pending
                                        </Text>
                                      </Box>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      direction="row"
                                      justify="center"
                                      align="center"
                                    >
                                      <Button
                                        className="btn-primary-dark"
                                        color="transparent"
                                        margin={{ horizontal: "small" }}
                                        size="small"
                                        label="View"
                                        onClick={() =>
                                          dispatch(
                                            push(
                                              `/dashboard/singleBulkUpload?${file.id}?${file.clinic}?${file.clinician}`
                                            )
                                          )
                                        }
                                      />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BulkUploads;
