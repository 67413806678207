import React from "react";
import Home from "./Home";
import Patients from "./Patients";
import Messages from "./RTM/Messages";
// import Sessions from "./Sessions";
import SinglePatientView from "./SinglePatientView";
import PinContent from "./PinContent";
import ScheduleAssessments from "./ScheduleAssessments";
import SingleBulkUploadFile from "../dashboard/BulkUploads/SingleBulkUploadFileNew";
import Resources from "./Resources";
import BulkUploads from "../dashboard/BulkUploads/BulkUploads";
import Settings from "./Settings";
import InactivePatients from "./InactivePatients";
import BillingHome from "./Billing/BillingHome";
import PatientReport from "./Billing/PatientReport";
import Invoicing from "./Billing/Invoicing";
import SalesHome from "./SalesPanel/SalesHome";
import AllClinics from "./SalesPanel/AllClincs";
import SingleClinic from "./SalesPanel/SingleClinic";
import SingleHealthcareGroup from "./SalesPanel/SingleHealthcareGroup";
import CareGiverHome from "./CareGiver/CareGiverHome";
import ACOExecutiveHome from "./ACOExecutive/ACOExecutiveHome";
import ACOExecutiveMeasures from "./ACOExecutive/ACOExecutiveMeasures";
import NoClinicPin from "./NoClinicPin";
import UtilizationReports from "./SalesPanel/UtilizationReports";
import RevenueReports from "./SalesPanel/RevenueReports";
import UtilizationDetail from "./SalesPanel/UtilizationDetail";
import RevenueDetail from "./SalesPanel/RevenueDetail";
import OverdueInvoices from "./SalesPanel/OverdueInvoices";
import AllTestAccounts from "../../common/AllTestAccounts";
import ClassEnrollments from "../../common/ClassEnrollments";
import PatientList from "./SalesPanel/PatientList";
import ClinicianList from "./SalesPanel/ClinicianList";
import RevenueBreakdown from "./SalesPanel/RevenueBreakdown";

const HomeStep = {
  id: "home",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "home",
    path: "/dashboard/home",
    label: "Home",
    Component: (props) => <Home {...props} />,
  },
};

const EmptyStep = {
  id: "empty",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "empty",
    path: "/dashboard/",
    label: "Home",
    Component: (props) => <Home {...props} />,
  },
};

const PatientsStep = {
  id: "patients",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "patients",
    path: "/dashboard/patients",
    label: "Patients",
    Component: () => <Patients invited={false} />,
  },
};

const InvitedPatientsStep = {
  id: "i-patients",
  role: ["CLINIC_ADMIN", "TWS_COORDINATOR"],
  step: {
    id: "i-patients",
    path: "/dashboard/invitedPatients",
    label: "Invited Patients",
    Component: () => <Patients invited />,
  },
};

const InactivePatientsStep = {
  id: "ia-patients",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "ia-patients",
    path: "/dashboard/inactivePatients",
    label: "Inactive Patients",
    Component: () => <InactivePatients />,
  },
};

const SinglePatientViewStep = {
  id: "singlepatient",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "singlepatient",
    path: "/dashboard/patient/?id",
    label: "singlepatient",
    Component: (props) => <SinglePatientView {...props} />,
  },
};

const SingleBillingPatientViewStep = {
  id: "SingleBillingPatientView",
  role: ["INVOICING_ADMIN"],
  step: {
    id: "SingleBillingPatientView",
    path: "/dashboard/patientReport/?id",
    label: "SingleBillingPatientView",
    Component: (props) => <PatientReport {...props} />,
  },
};

const PinContentStep = {
  id: "pincontent",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "pincontent",
    path: "/dashboard/pinContent/?id",
    label: "pinContent",
    Component: (props) => <PinContent {...props} />,
  },
};

const ScheduleAssessmentsStep = {
  id: "scheduleAssessments",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "scheduleAssessments",
    path: "/dashboard/scheduleAssessments/?id",
    label: "scheduleAssessments",
    Component: (props) => <ScheduleAssessments {...props} />,
  },
};

const ResourcesStep = {
  id: "resourcesStep",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
  ],
  step: {
    id: "resourcesStep",
    path: "/dashboard/resources",
    label: "resources",
    Component: (props) => <Resources {...props} />,
  },
};

const SingleBulkUploadStep = {
  id: "singleBulkUploadStep",
  role: ["CUSTOMER_SUCCESS"],
  step: {
    id: "singleBulkUploadStep",
    path: "/dashboard/singleBulkUpload",
    label: "Bulk File",
    Component: (props) => <SingleBulkUploadFile {...props} />,
  },
};

const BulkUploadsStep = {
  id: "bulkUploadsStep",
  role: ["CUSTOMER_SUCCESS"],
  step: {
    id: "singleBulkUploadsStep",
    path: "/dashboard/bulkUploads",
    label: "Bulk File",
    Component: (props) => <BulkUploads {...props} />,
  },
};

const SettingsStep = {
  id: "settingsStep",
  role: [
    "CLINIC_ADMIN",
    "CLINICAL_STAFF",
    "TWS_COORDINATOR",
    "TWS_CONCIERGE",
    "TWS_CASE_MANAGER",
    "INVOICING_ADMIN",
  ],
  step: {
    id: "settingsStep",
    path: "/dashboard/settings",
    label: "Settings",
    Component: (props) => <Settings {...props} />,
  },
};

const BillingHomeStep = {
  id: "BillingHomeStep",
  role: ["INVOICING_ADMIN"],
  step: {
    id: "BillingHomeStep",
    path: "/dashboard/billingHome",
    label: "Home",
    Component: (props) => <BillingHome {...props} />,
  },
};

const InvoicingStep = {
  id: "InvoicingStep",
  role: ["INVOICING_ADMIN"],
  step: {
    id: "InvoicingStep",
    path: "/dashboard/invoicing",
    label: "Invoicing",
    Component: (props) => <Invoicing {...props} />,
  },
};

const MessagesStep = {
  id: "messagesStep",
  role: ["CLINIC_ADMIN", "CLINICAL_STAFF", "TWS_CONCIERGE", "TWS_CASE_MANAGER"],
  step: {
    id: "messagesStep",
    path: "/dashboard/messages",
    label: "Messages",
    Component: (props) => <Messages {...props} />,
  },
};

const SalesHomeStep = {
  id: "salesHomeStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "messagesStep",
    path: "/dashboard/salesHome",
    label: "Sales Home",
    Component: (props) => <SalesHome {...props} />,
  },
};

const AllClinicsStep = {
  id: "allClinicsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "allClinicsStep",
    path: "/dashboard/allClinics",
    label: "All Clinics",
    Component: (props) => <AllClinics {...props} />,
  },
};

const SingleHealthcareGroupStep = {
  id: "singleHealthcareGroupStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "singleHealthcareGroupStep",
    path: "/dashboard/healthgroup/?id",
    label: "Healthcare Group",
    Component: (props) => <SingleHealthcareGroup {...props} />,
  },
};

const SingleClinicStep = {
  id: "singleClinicStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "singleClinicStep",
    path: "/dashboard/clinic/?id",
    label: "Clinic View",
    Component: (props) => <SingleClinic {...props} />,
  },
};

const UtilizationReportsStep = {
  id: "UtilizationReportsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "UtilizationReportsStep",
    path: "/dashboard/utilization-reports",
    label: "Clinic View",
    Component: (props) => <UtilizationReports {...props} />,
  },
};

const UtilizationDetailReportsStep = {
  id: "UtilizationDetailReportsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "UtilizationReportsStep",
    path: "/dashboard/utilization-detail",
    label: "Clinic View",
    Component: (props) => <UtilizationDetail {...props} />,
  },
};

const RevenueReportsStep = {
  id: "RevenueReportsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "RevenueReportsStep",
    path: "/dashboard/revenue-reports",
    label: "Clinic View",
    Component: (props) => <RevenueReports {...props} />,
  },
};

const RevenueBreakdownStep = {
  id: "RevenueBreakdownStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "RevenueBreakdownStep",
    path: "/dashboard/revenue-breakdown",
    label: "Clinic View",
    Component: (props) => <RevenueBreakdown {...props} />,
  },
};
const RevenueDetailReportsStep = {
  id: "RevenueDetailReportsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "RevenueDetailReportsStep",
    path: "/dashboard/revenue-detail",
    label: "Clinic View",
    Component: (props) => <RevenueDetail {...props} />,
  },
};

const OverdueInvoicesStep = {
  id: "OverdueInvoicesStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "OverdueInvoicesStep",
    path: "/dashboard/overdue-invoices",
    label: "Clinic View",
    Component: (props) => <OverdueInvoices {...props} />,
  },
};
const PatientListStep = {
  id: "PatientListStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "PatientListStep",
    path: "/dashboard/patient-list",
    label: "Clinic View",
    Component: (props) => <PatientList {...props} />,
  },
};

const ClinicianListStep = {
  id: "ClinicianListStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "ClinicianListStep",
    path: "/dashboard/clinician-list",
    label: "Clinic View",
    Component: (props) => <ClinicianList {...props} />,
  },
};

const TestAccountsStep = {
  id: "OverdueInvoicesStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "OverdueInvoicesStep",
    path: "/dashboard/test-accounts",
    label: "Clinic View",
    Component: (props) => <AllTestAccounts {...props} />,
  },
};

const ClassEnrollmentsStep = {
  id: "ClassEnrollmentsStep",
  role: ["SALES_MANAGER"],
  step: {
    id: "ClassEnrollmentsStep",
    path: "/dashboard/class-enrollments",
    label: "Class Enrollments",
    Component: (props) => <ClassEnrollments {...props} />,
  },
};

const CareGiverHomeStep = {
  id: "CareGiverHomeStep",
  role: ["CAREGIVER"],
  step: {
    id: "CareGiverHomeStep",
    path: "/dashboard/caregiverHome",
    label: "CareGiver Home",
    Component: (props) => <CareGiverHome {...props} />,
  },
};

const ACOExecutiveHomeStep = {
  id: "ACOExecutiveHomeStep",
  role: ["ACO_EXECUTIVE"],
  step: {
    id: "ACOExecutiveHomeStep",
    path: "/dashboard/acoexecutiveHome",
    label: "ACO Executive Home",
    Component: (props) => <ACOExecutiveHome {...props} />,
  },
};

const ACOExecutiveMeasureStep = {
  id: "ACOExecutiveMeasureStep",
  role: ["ACO_EXECUTIVE"],
  step: {
    id: "ACOExecutiveMeasureStep",
    path: "/dashboard/aco/measure",
    label: "ACO Executive Measure",
    Component: (props) => <ACOExecutiveMeasures {...props} />,
  },
};

const NoClinicPinStep = {
  id: "NoClinicPinStep",
  role: ["CLINIC_ADMIN", "CLINICAL_STAFF"],
  step: {
    id: "NoClinicPinStep",
    path: "/dashboard/selectClinicPin",
    label: "Select Clinic Pin",
    Component: (props) => <NoClinicPin {...props} />,
  },
};

const AllSteps = [
  HomeStep,
  NoClinicPinStep,
  PatientsStep,
  // SessionsStep,
  MessagesStep,
  EmptyStep,
  SinglePatientViewStep,
  PinContentStep,
  InvitedPatientsStep,
  ScheduleAssessmentsStep,
  ResourcesStep,
  SingleBulkUploadStep,
  BulkUploadsStep,
  SettingsStep,
  InactivePatientsStep,
  BillingHomeStep,
  SingleBillingPatientViewStep,
  InvoicingStep,
  SalesHomeStep,
  AllClinicsStep,
  SingleHealthcareGroupStep,
  SingleClinicStep,
  CareGiverHomeStep,
  ACOExecutiveHomeStep,
  ACOExecutiveMeasureStep,
  UtilizationReportsStep,
  RevenueReportsStep,
  UtilizationDetailReportsStep,
  RevenueDetailReportsStep,
  OverdueInvoicesStep,
  TestAccountsStep,
  ClassEnrollmentsStep,
  PatientListStep,
  ClinicianListStep,
  RevenueBreakdownStep,
];

export default AllSteps;
