import React, { useEffect, useState } from "react";
import { Box, CheckBox, Collapsible, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getGroups } from "../../store/sales/actions";
import { CircleAlert } from "grommet-icons";
import { push } from "connected-react-router";

const AlertsBox = () => {
  const [loading, setLoading] = useState(false);

  const groups = useSelector((state) => state.sales.clinic_groups);
  const dispatch = useDispatch();

  const [openExpire, setOpenExpire] = useState(false);
  const [openNoInfo, setOpenNoInfo] = useState(false);
  const [cardOnly, setCardOnly] = useState(true);

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
    }
  }, []);

  let expiringGroups = [];

  let noCardGroups = [];
  if (groups) {
    groups.forEach((x) => {
      if (x.invoicing && x.invoicing.card_expiry_alert) {
        if (cardOnly && x.payment_type === "Card") {
          expiringGroups.push({
            id: x.id,
            name: x.name,
            year: x.invoicing.card_expiration_year,
            month: x.invoicing.card_expiration_month,
            payment_type: x.payment_type,
          });
        } else if (!cardOnly) {
          expiringGroups.push({
            id: x.id,
            name: x.name,
            year: x.invoicing.card_expiration_year,
            month: x.invoicing.card_expiration_month,
            payment_type: x.payment_type,
          });
        }
      }
      if (x.invoicing && x.invoicing.is_card_info_missing) {
        if (cardOnly && x.payment_type === "Card") {
          noCardGroups.push({
            id: x.id,
            name: x.name,
            payment_type: x.payment_type,
          });
        } else if (!cardOnly) {
          noCardGroups.push({
            id: x.id,
            name: x.name,
            payment_type: x.payment_type,
          });
        }
      }
    });
  }

  if (loading || !groups)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium">
      <Box pad="medium" gap="small" className="large-card">
        <Box direction="row" gap="small" justify="between" align="center">
          <Text color="#1D3763" weight="bold">
            These Alerts Require Your Attention
          </Text>
          <Box direction="row" gap="small" align="start" justify="start">
            <CheckBox
              size="small"
              toggle
              checked={cardOnly}
              onClick={() => setCardOnly(!cardOnly)}
            ></CheckBox>
            <Text size="small">Consider Payment Method "Card" Only</Text>
          </Box>
        </Box>
        <Box
          onClick={() => setOpenExpire(!openExpire)}
          className="noOutline"
          background="#FA8581"
          pad="small"
          round="4px"
          direction="row"
          gap="small"
        >
          <CircleAlert color="white" />{" "}
          <Text color="white">Expiring Cards ({expiringGroups.length})</Text>
        </Box>
        <Collapsible open={openExpire}>
          <Box gap="small">
            {expiringGroups.map((x) => (
              <Box
                background="light-2"
                pad="small"
                round="4px"
                className="noOutline"
                onClick={() =>
                  dispatch(push(`/dashboard/healthgroup/?${x.id}`))
                }
              >
                <Text>
                  {x.name} (Expired {x.month} / {x.year})
                </Text>
              </Box>
            ))}
          </Box>
        </Collapsible>
        <Box
          onClick={() => setOpenNoInfo(!openNoInfo)}
          className="noOutline"
          background="#F8C998"
          pad="small"
          round="4px"
          direction="row"
          gap="small"
        >
          <CircleAlert color="white" />{" "}
          <Text color="white">
            {" "}
            Missing Card Information ({noCardGroups.length})
          </Text>
        </Box>
        <Collapsible open={openNoInfo}>
          <Box gap="small">
            {noCardGroups.map((x) => (
              <Box
                background="light-2"
                pad="small"
                round="4px"
                className="noOutline"
                onClick={() =>
                  dispatch(push(`/dashboard/healthgroup/?${x.id}`))
                }
              >
                <Text>{x.name}</Text>
              </Box>
            ))}
          </Box>
        </Collapsible>
      </Box>
    </Box>
  );
};
export default AlertsBox;
