/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/label-has-for */
import {
  Avatar,
  Box,
  Button,
  Image,
  Text,
  TextInput,
  Form,
  MaskedInput,
} from "grommet";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { createNotification, updateProfile } from "../../store/app/actions";

const LoadingSoftButton = ({ label, submitting, invalidToken }) => (
  <Box height="xxsmall">
    <Button
      className="menu"
      color="transparent"
      alignSelf="end"
      type="submit"
      disabled={submitting || invalidToken}
      label={
        submitting ? (
          <Loader type="TailSpin" height={20} color="grey" />
        ) : (
          <Text size="small" color="#FFFFFF">
            {label}
          </Text>
        )
      }
    />
  </Box>
);

const EditProfile = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user.user);

  const [fileName, setFileName] = useState("No File Uploaded");

  function setUserValues(userToSet) {
    const inviteDetails = {
      id: userToSet.id ? userToSet.id : "",
      first_name: userToSet.first_name ? userToSet.first_name : "",
      last_name: userToSet.last_name ? userToSet.last_name : "",
      cell_number: userToSet.cell_number ? userToSet.cell_number : "",
      address_line: userToSet.address_line ? userToSet.address_line : "",
      address_city: userToSet.address_city ? userToSet.address_city : "",
      address_state: userToSet.address_state ? userToSet.address_state : "",
    };
    return inviteDetails;
  }
  const [value, setValue] = React.useState(user ? setUserValues(user) : "");
  const [submitting, setSubmitting] = useState(false);

  const handleFile = (event) => {
    setFileName(event.target.files[0].name);
  };
  const getAvatar = () => {
    if (user.hasOwnProperty("profilePicture")) {
      return <Image src={user.profilePicture} fit="cover" />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="4xl">
          {user.first_name[0] + user.last_name[0]}
        </Text>
      </Box>
    );
  };

  const onSuccess = () => {
    dispatch(createNotification("Profile Updated", true));
    setSubmitting(false);
  };

  const loginError = () => {
    dispatch(createNotification("Something Went Wrong", false));
    setSubmitting(false);
  };

  const saveChanges = (form) => {
    setSubmitting(true);
    dispatch(updateProfile(form, loginError, onSuccess));
  };

  return (
    <Box className="large-card" pad="medium" gap="small">
      <Text color="#395E9D" weight="bold" size="large">
        Basic Info
      </Text>

      <Box direction="row" gap="small" align="center">
        <Avatar
          size="xlarge"
          background="#f1f1f5"
          style={{ border: "3px solid #28539e" }}
        >
          {user ? getAvatar() : null}
        </Avatar>
        <label className="custom-file-upload-2">
          <input
            type="file"
            name="file"
            disabled
            onChange={(event) => handleFile(event)}
          />
          <Text size="small">Change Picture</Text>
        </label>
        <Text
          size="small"
          color={fileName === "No File Uploaded" ? "white" : "green"}
          margin={{ left: "xsmall" }}
        >
          {fileName}
        </Text>
      </Box>
      <Form
        onSubmit={saveChanges}
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
      >
        <Box gap="small">
          <Box direction="row" gap="medium">
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                First Name
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <TextInput
                  size="small"
                  name="first_name"
                  plain
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Last Name
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <TextInput
                  size="small"
                  name="last_name"
                  plain
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
          </Box>

          <Box direction="row" gap="medium">
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Phone Number
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <MaskedInput
                  size="small"
                  mask={[
                    {
                      length: 3,
                      regexp: /^[0-9]{1,3}$/,
                      placeholder: "XXX",
                    },
                    { fixed: " " },
                    {
                      length: 3,
                      regexp: /^[0-9]{1,3}$/,
                      placeholder: "XXX",
                    },
                    { fixed: " " },
                    {
                      length: 4,
                      regexp: /^[0-9]{1,4}$/,
                      placeholder: "XXXX",
                    },
                  ]}
                  name="cell_number"
                  plain
                />
              </Box>
            </Box>
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Address
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <TextInput
                  size="small"
                  name="address_line"
                  plain
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
          </Box>

          <Box direction="row" gap="medium">
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                City
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <TextInput
                  size="small"
                  name="address_city"
                  plain
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                State
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <MaskedInput
                  size="small"
                  mask={[
                    {
                      length: 2,
                      regexp: /^[a-zA-Z]{1,2}$/,
                    },
                  ]}
                  style={{ textTransform: "uppercase" }}
                  name="address_state"
                  plain
                />
              </Box>
            </Box>
          </Box>

          <LoadingSoftButton submitting={submitting} label="Save" />
        </Box>
      </Form>
    </Box>
  );
};

export default EditProfile;
