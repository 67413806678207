/* eslint-disable camelcase */
export const FETCH_PATIENTS = "patients/FETCH_PATIENTS";
export const FETCH_SINGLE_PATIENT = "patients/FETCH_SINGLE_PATIENT";
export const SET_SINGLE_PATIENT = "patients/SET_SINGLE_PATIENT";
export const SET_PATIENTS = "patients/SET_PATIENTS";
export const SET_RED_PATIENTS = "patients/SET_RED_PATIENTS";
export const SET_YELLOW_PATIENTS = "patients/SET_YELLOW_PATIENTS";
export const SEND_INVITE = "patients/SEND_INVITE";
export const SET_INVITED_PATIENTS = "patient/SET_INVITED_PATIENTS";
export const FETCH_INVITED_PATIENTS = "patients/FETCH_INVITED_PATIENTS";
export const RESEND_INVITE = "patients/RESEND_INVITE";
export const GET_SINGLE_INVITE = "patients/GET_SINGLE_INVITE";
export const GET_SINGLE_ASSESSMENT = "patients/GET_SINGLE_ASSESSMENT";
export const SET_SINGLE_INVITE = "patients/SET_SINGLE_INVITE";
export const EDIT_SINGLE_INVITE = "patients/EDIT_SINGLE_INVITE";
export const ENROLL_BHI_PATIENT = "patients/ENROLL_BHI_PATIENT";
export const SET_SINGLE_PATIENT_BHI = "patients/SET_SINGLE_PATIENT_BHI";
export const UPDATE_BHI_PATIENT = "patients/UPDATE_BHI_PATIENT";
export const FETCH_ASSESSMENTS_SCHEDULE = "patients/FETCH_ASSESSMENTS_SCHEDULE";
export const SET_ASSESSMENTS_SCHEDULE = "patients/SET_ASSESSMENTS_SCHEDULE";
export const SET_SINGLE_ASSESSEMENT = "patients/SET_SINGLE_ASSESSEMENT";
export const GET_ASSESSMENTS = "app/GET_ASSESSMENTS";
export const SET_ASSESSMENT_TYPES = "app/SET_ASSESSMENT_TYPES";
export const SET_NEW_ASSESSMENT = "app/SET_NEW_ASSESSMENT";
export const EDIT_SINGLE_ASSESSMENT = "app/EDIT_SINGLE_ASSESSMENT";
export const PIN_POST = "app/PIN_POST";
export const GET_PINNED_CONTENT = "app/GET_PINNED_CONTENT";
export const SET_PINNED_CONTENT = "app/SET_PINNED_CONTENT";
export const UNPIN_POST = "app/UNPIN_POST";
export const RESET_FLAG = "app/RESET_FLAG";
export const DELETE_SCHEDULE = "app/DELETE_SCHEDULE";
export const FETCH_ASSESSMENT_RESULTS = "app/FETCH_ASSESSMENT_RESULTS";
export const SET_ASSESSMENT_RESULTS = "app/SET_ASSESSMENT_RESULTS";
export const BULK_UPLOAD_FILE = "app/BULK_UPLOAD_FILE";
export const GET_BULK_INVITES = "app/GET_BULK_INVITES";
export const SET_BULK_INVITES = "app/SET_BULK_INVITES";
export const SEND_SINGLE_BULK = "app/SEND_SINGLE_BULK";
export const GET_BULK_FILES = "app/GET_BULK_FILES";
export const SET_BULK_FILES = "app/SET_BULK_FILES";
export const MARK_FILE_COMPLETE = "app/MARK_FILE_COMPLETE";
export const FETCH_BHI_PATIENTS = "app/FETCH_BHI_PATIENTS";
export const SET_BHI_PATIENTS = "app/SET_BHI_PATIENTS";
export const SET_PATIENTS_DATA = "app/SET_PATIENTS_DATA";
export const FETCH_ALL_REPORT_DATA = "app/FETCH_ALL_REPORT_DATA";
export const GET_STAFF = "app/GET_STAFF";
export const SET_STAFF = "app/SET_STAFF";
export const SAVE_BHI_NOTE = "app/SAVE_BHI_NOTE";
export const GET_BHI_NOTES = "app/GET_BHI_NOTES";
export const SET_BHI_NOTES = "app/SET_BHI_NOTES";
export const FETCH_ASSESSMENT_RESULTS_RECENT =
  "app/FETCH_ASSESSMENT_RESULTS_RECENT";
export const SET_ASSESSMENT_RESULTS_RECENT =
  "app/SET_ASSESSMENT_RESULTS_RECENT";
export const GET_MESSAGE_HISTORY = "app/GET_MESSAGE_HISTORY";
export const SET_MESSAGE_HISTORY = "app/SET_MESSAGE_HISTORY";
export const SAVE_SINGLE_MESSAGE = "app/SAVE_SINGLE_MESSAGE";
export const GET_ALL_MESSAGE_HISTORY = "app/GET_ALL_MESSAGE_HISTORY";
export const SET_ALL_MESSAGE_HISTORY = "app/SET_ALL_MESSAGE_HISTORY";
export const TOGGLE_MESSAGE_STATUS = "app/TOGGLE_MESSAGE_STATUS";
export const FETCH_CHAT_PATIENTS = "app/FETCH_CHAT_PATIENTS";
export const SET_CHAT_PATIENTS = "app/SET_CHAT_PATIENTS";
export const MARK_MESSAGES_READ = "app/MARK_MESSAGES_READ";
export const GET_CHECKOUT_SESSION = "app/GET_CHECKOUT_SESSION";
export const GET_PAYMENT_METHODS = "app/GET_PAYMENT_METHODS";
export const RETURN_CHAT_PATIENTS = "app/RETURN_CHAT_PATIENTS";
export const GET_UNREAD_MESSAGES_COUNT = "app/GET_UNREAD_MESSAGES_COUNT";
export const FETCH_BILLING_PATIENTS = "app/FETCH_BILLING_PATIENTS";
export const OVERRIDE_ELIGIBILITY = "app/OVERRIDE_ELIGIBILITY";
export const GET_PAYMENT_METHODS_GROUP = "app/GET_PAYMENT_METHODS_GROUP";
export const ENROLL_TWS_BHI_PATIENT = "app/ENROLL_TWS_BHI_PATIENT";
export const UPDATE_BHI_PATIENT_TWS = "app/UPDATE_BHI_PATIENT_TWS";
export const GET_SINGLE_BULK_FILE = "app/GET_SINGLE_BULK_FILE";
export const SET_BULK_FILE = "app/SET_BULK_FILE";
export const PATCH_FILE_NOTES = "app/PATCH_FILE_NOTES";
export const DOWNLOAD_FILE = "app/DOWNLOAD_FILE";
export const REUPLOAD_FILE = "app/REUPLOAD_FILE";
export const SET_ALL_BILLING_PATIENTS = "app/SET_ALL_BILLING_PATIENTS";
export const CREATE_NEW_DEFAULT_SCHEDULE =
  "patients/CREATE_NEW_DEFAULT_SCHEDULE";
export const GET_DEFAULT_SCHEDULE_ASSESSMENTS =
  "patients/GET_DEFAULT_SCHEDULE_ASSESSMENTS";
export const SET_DEFAULT_SCHEDULE_ASSESSMENTS =
  "patients/SET_DEFAULT_SCHEDULE_ASSESSMENTS";
export const DELETE_DEFAULT_SCHEDULE_ASSESSMENT =
  "patients/DELETE_DEFAULT_SCHEDULE_ASSESSMENT";
export const UPDATE_SELF_HARM_JOURNAL_FLAG =
  "patients/UPDATE_SELF_HARM_JOURNAL_FLAG";
export const FETCH_FIRE_FLAG_PATIENTS = "patients/FETCH_FIRE_FLAG_PATIENTS";
export const SET_FIRE_FLAG_PATIENTS = "patients/SET_FIRE_FLAG_PATIENTS";
export const UPDATE_PATIENT_LAST_VIEWED = "patients/UPDATE_PATIENT_LAST_VIEWED";
export const FETCH_ASSESSMENT_RESULTS_OLD =
  "patients/FETCH_ASSESSMENT_RESULTS_OLD";
export const SET_ASSESSMENT_RESULTS_OLD = "patients/SET_ASSESSMENT_RESULTS_OLD";

export const fetchPatients = (
  success,
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  type,
  assessed
) => ({
  type: FETCH_PATIENTS,
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
  },
});

export const fetchBillingPatients = (
  success,
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  type,
  assessed,
  lastEnrollment
) => ({
  type: FETCH_BILLING_PATIENTS,
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
    lastEnrollment,
  },
});

export const fetchInvitedPatients = (
  success,
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  page
) => ({
  type: FETCH_INVITED_PATIENTS,
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    page,
  },
});

export const resendInvite = (id, resendSuccess) => ({
  type: RESEND_INVITE,
  payload: { id, resendSuccess },
});

export const fetchSinglePatient = (id, success) => ({
  type: FETCH_SINGLE_PATIENT,
  payload: { id, success },
});

export const setSinglePatient = (patients) => ({
  type: SET_SINGLE_PATIENT,
  payload: { patients },
});

export const setSinglePatientBHI = (patients) => ({
  type: SET_SINGLE_PATIENT_BHI,
  payload: { patients },
});

export const setPatients = (patients, count, next, previous) => ({
  type: SET_PATIENTS,
  payload: { patients, count, next, previous },
});

export const setPatientsData = (patients, count, next, previous) => ({
  type: SET_PATIENTS_DATA,
  payload: { patients, count, next, previous },
});

export const setInvitedPatients = (patients, count, next, previous) => ({
  type: SET_INVITED_PATIENTS,
  payload: { patients, count, next, previous },
});
export const sendInvite = (
  form,
  inviteError,
  NAD,
  clinic,
  clinician,
  user,
  physician
) => ({
  type: SEND_INVITE,
  payload: { form, inviteError, NAD, clinic, clinician, user, physician },
});

export const getSingleInvite = (id, onSuccess) => ({
  type: GET_SINGLE_INVITE,
  payload: { id, onSuccess },
});
export const setSingleInvite = (data) => ({
  type: SET_SINGLE_INVITE,
  payload: { data },
});

export const setSingleAssessment = (data) => ({
  type: SET_SINGLE_ASSESSEMENT,
  payload: { data },
});

export const editInvite = (form, invite, NAD) => ({
  type: EDIT_SINGLE_INVITE,
  payload: { form, invite, NAD },
});

export const setRedPatients = (patients, count) => ({
  type: SET_RED_PATIENTS,
  payload: { patients, count },
});

export const setYellowPatients = (patients, count) => ({
  type: SET_YELLOW_PATIENTS,
  payload: { patients, count },
});

export const enrollBHIPatient = (
  diagnosis,
  bhManager,
  consentInfo,
  patient,
  user,
  onSuccess
) => ({
  type: ENROLL_BHI_PATIENT,
  payload: { diagnosis, bhManager, consentInfo, patient, user, onSuccess },
});

export const enrollTWSBHIPatient = (
  diagnosis,
  bhManager,
  consentInfo,
  patient,
  user,
  onSuccess
) => ({
  type: ENROLL_TWS_BHI_PATIENT,
  payload: { diagnosis, bhManager, consentInfo, patient, user, onSuccess },
});

export const updatePatientBHI = (
  diagnosis,
  bhi_manager,
  id,
  patient,
  setShow
) => ({
  type: UPDATE_BHI_PATIENT,
  payload: { diagnosis, bhi_manager, id, patient, setShow },
});

export const updatePatientBHITWS = (
  diagnosis,
  bhi_manager,
  id,
  patient,
  setShow
) => ({
  type: UPDATE_BHI_PATIENT_TWS,
  payload: { diagnosis, bhi_manager, id, patient, setShow },
});

export const fetchAssessmentsSchedule = (onSuccess, patientId) => ({
  type: FETCH_ASSESSMENTS_SCHEDULE,
  payload: { onSuccess, patientId },
});

export const setAssessmentsSchedule = (data) => ({
  type: SET_ASSESSMENTS_SCHEDULE,
  payload: { data },
});

export const setAssessmentTypes = (data) => ({
  type: SET_ASSESSMENT_TYPES,
  payload: { data },
});

export const getSingleAssessment = (id, onSuccessEdit) => ({
  type: GET_SINGLE_ASSESSMENT,
  payload: { id, onSuccessEdit },
});

export const getAssessments = (onSuccessNew) => ({
  type: GET_ASSESSMENTS,
  payload: { onSuccessNew },
});

export const setNewAssessment = (value, patient, onSuccess) => ({
  type: SET_NEW_ASSESSMENT,
  payload: { value, patient, onSuccess },
});

export const editSingleAssessment = (value, scheduleID) => ({
  type: EDIT_SINGLE_ASSESSMENT,
  payload: { value, scheduleID },
});

export const pinPost = (type, identifier, patient, setInternalPin) => ({
  type: PIN_POST,
  payload: { type, identifier, patient, setInternalPin },
});
export const unpinPost = (id) => ({
  type: UNPIN_POST,
  payload: { id },
});

export const getPinnedContent = (patient, fetchMyPosts) => ({
  type: GET_PINNED_CONTENT,
  payload: { patient, fetchMyPosts },
});

export const setPinnedContent = (data) => ({
  type: SET_PINNED_CONTENT,
  payload: { data },
});

export const resetFlagAction = (id, flag) => ({
  type: RESET_FLAG,
  payload: { id, flag },
});

export const deleteAssessmentSchedule = (id, patientId) => ({
  type: DELETE_SCHEDULE,
  payload: { id, patientId },
});

export const fetchAssessmentResults = (
  id,
  onSuccess,
  start_date,
  end_date
) => ({
  type: FETCH_ASSESSMENT_RESULTS,
  payload: { id, onSuccess, start_date, end_date },
});

export const fetchAssessmentResultsOld = (
  id,
  onSuccess,
  start_date,
  end_date
) => ({
  type: FETCH_ASSESSMENT_RESULTS_OLD,
  payload: { id, onSuccess, start_date, end_date },
});

export const fetchAssessmentResultsRecent = (id, onSuccess) => ({
  type: FETCH_ASSESSMENT_RESULTS_RECENT,
  payload: { id, onSuccess },
});

export const setAssessmentResults = (data) => ({
  type: SET_ASSESSMENT_RESULTS,
  payload: { data },
});

export const setAssessmentResultsOld = (data) => ({
  type: SET_ASSESSMENT_RESULTS_OLD,
  payload: { data },
});

export const setAssessmentResultsRecent = (data) => ({
  type: SET_ASSESSMENT_RESULTS_RECENT,
  payload: { data },
});

export const bulkUploadFile = (report, clinic, onSuccess, clinician) => ({
  type: BULK_UPLOAD_FILE,
  payload: { report, clinic, onSuccess, clinician },
});

export const getBulkInvites = (success, fileID) => ({
  type: GET_BULK_INVITES,
  payload: { success, fileID },
});

export const setBulkInvites = (data) => ({
  type: SET_BULK_INVITES,
  payload: { data },
});

export const singleBulkInvite = (
  form,
  inviteError,
  id,
  fileID,
  inviteSuccess,
  NAD,
  clinic,
  clinician
) => ({
  type: SEND_SINGLE_BULK,
  payload: {
    form,
    inviteError,
    id,
    fileID,
    inviteSuccess,
    NAD,
    clinic,
    clinician,
  },
});

export const getBulkFiles = (success) => ({
  type: GET_BULK_FILES,
  payload: { success },
});

export const getSingleBulkFile = (id, onSuccess) => ({
  type: GET_SINGLE_BULK_FILE,
  payload: { id, onSuccess },
});
export const setBulkFiles = (data) => ({
  type: SET_BULK_FILES,
  payload: { data },
});

export const setBulkFile = (data) => ({
  type: SET_BULK_FILE,
  payload: { data },
});

export const markFileComplete = (fileID) => ({
  type: MARK_FILE_COMPLETE,
  payload: { fileID },
});

export const downloadFile = (fileID, success) => ({
  type: DOWNLOAD_FILE,
  payload: { fileID, success },
});

export const reUploadFile = (fileID, file, success) => ({
  type: REUPLOAD_FILE,
  payload: { fileID, file, success },
});

export const fetchBHIPatients = () => ({
  type: FETCH_BHI_PATIENTS,
});

export const setBHIPatients = (data) => ({
  type: SET_BHI_PATIENTS,
  payload: { data },
});

export const fetchAllReportData = (search, onSuccess, startDate, endDate) => ({
  type: FETCH_ALL_REPORT_DATA,
  payload: { search, onSuccess, startDate, endDate },
});

export const getStaff = (onSuccess) => ({
  type: GET_STAFF,
  payload: { onSuccess },
});

export const setStaff = (data) => ({
  type: SET_STAFF,
  payload: { data },
});

export const saveBHINote = (diagnosis, notes, patient, madeBy) => ({
  type: SAVE_BHI_NOTE,
  payload: { diagnosis, notes, patient, madeBy },
});

export const getBHINotes = (patient, onSuccess) => ({
  type: GET_BHI_NOTES,
  payload: { patient, onSuccess },
});

export const setBHINotes = (data) => ({
  type: SET_BHI_NOTES,
  payload: { data },
});

export const getMessageHistory = (peerid, onSuccess) => ({
  type: GET_MESSAGE_HISTORY,
  payload: { peerid, onSuccess },
});

export const setMessageHistory = (data) => ({
  type: SET_MESSAGE_HISTORY,
  payload: { data },
});

export const saveSingleMessage = (
  message_type,
  message,
  read_status,
  to,
  sender,
  callBack
) => ({
  type: SAVE_SINGLE_MESSAGE,
  payload: { message_type, message, read_status, to, sender, callBack },
});

export const getAllMessagesHistory = (onSuccess) => ({
  type: GET_ALL_MESSAGE_HISTORY,
  payload: { onSuccess },
});

export const setAllMessageHistory = (data) => ({
  type: SET_ALL_MESSAGE_HISTORY,
  payload: { data },
});

export const toggleMessageStatus = (patientID, currentStatus) => ({
  type: TOGGLE_MESSAGE_STATUS,
  payload: { patientID, currentStatus },
});

export const fetchChatPatients = (
  success,
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  type,
  assessed
) => ({
  type: FETCH_CHAT_PATIENTS,
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
  },
});

export const setChatPatients = (patients, count, next, previous) => ({
  type: SET_CHAT_PATIENTS,
  payload: { patients, count, next, previous },
});

export const setAllBillingPatients = (patients, count, next, previous) => ({
  type: SET_ALL_BILLING_PATIENTS,
  payload: { patients, count, next, previous },
});

export const markMessagesAsRead = (patientID, callBack) => ({
  type: MARK_MESSAGES_READ,
  payload: { patientID, callBack },
});

export const getCheckoutSessions = (callBack) => ({
  type: GET_CHECKOUT_SESSION,
  payload: { callBack },
});

export const getPaymentMethods = (callBack) => ({
  type: GET_PAYMENT_METHODS,
  payload: { callBack },
});
export const getPaymentMethodsGroup = (groupID, callBack) => ({
  type: GET_PAYMENT_METHODS_GROUP,
  payload: { groupID, callBack },
});

export const returnChatPatients = (
  success,
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  assessed
) => ({
  type: RETURN_CHAT_PATIENTS,
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    assessed,
  },
});

export const getUnreadMessagesCount = (callBack) => ({
  type: GET_UNREAD_MESSAGES_COUNT,
  payload: { callBack },
});

export const overrideEligibility = (patient) => ({
  type: OVERRIDE_ELIGIBILITY,
  payload: { patient },
});

export const patchFileNotes = (fileID, notes, callBack) => ({
  type: PATCH_FILE_NOTES,
  payload: { fileID, notes, callBack },
});

export const createNewDefaultSchedule = (
  pinID,
  assessmentID,
  gapDays,
  onSuccess
) => ({
  type: CREATE_NEW_DEFAULT_SCHEDULE,
  payload: { pinID, assessmentID, gapDays, onSuccess },
});

export const getDefaultScheduleAssessments = (pinID) => ({
  type: GET_DEFAULT_SCHEDULE_ASSESSMENTS,
  payload: { pinID },
});

export const setDefaultScheduleAssessments = (data) => ({
  type: SET_DEFAULT_SCHEDULE_ASSESSMENTS,
  payload: { data },
});

export const deleteDefaultScheduleAssessment = (id, onSuccess) => ({
  type: DELETE_DEFAULT_SCHEDULE_ASSESSMENT,
  payload: { id, onSuccess },
});

export const updateSelfHarmJournalFlag = (id, onSuccess) => ({
  type: UPDATE_SELF_HARM_JOURNAL_FLAG,
  payload: { id, onSuccess },
});

export const fetchFireFlagPatients = (page, onSuccess, page_size, search) => ({
  type: FETCH_FIRE_FLAG_PATIENTS,
  payload: { page, onSuccess, page_size, search },
});

export const setFireFlagPatients = (data) => ({
  type: SET_FIRE_FLAG_PATIENTS,
  payload: { data },
});

export const updateLastViewed = (id) => ({
  type: UPDATE_PATIENT_LAST_VIEWED,
  payload: { id },
});
