/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from "react";
import { Box, Text, Layer, Button, Image } from "grommet";
import { Attachment, Close } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import ExcelLogo from "../../assets/VectorExcel.svg";
import File from "../../assets/ReportTemplate.csv";
import { bulkUploadFile } from "../../store/patients/actions";

const BulkInviteModal = ({ display, setDisplayOut }) => {
  const clinician = useSelector((state) => state.app.user.user.id);
  const clinic = useSelector((state) => state.app.user.user.clinic.id);
  const [fileName, setFileName] = useState("No File Uploaded");
  const dispatch = useDispatch();

  const handleFile = (event) => {
    setFileName(event.target.files[0].name);
  };

  const onSuccess = () => {
    setDisplayOut(false);
  };

  const onSumbit = (event) => {
    event.preventDefault();
    dispatch(
      bulkUploadFile(event.target[0].files[0], clinic, onSuccess, clinician)
    );
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite-bulk"
          position="center"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="brand" weight="bold">
                Add Multiple Patients
              </Text>
              <Box>
                <Close
                  onClick={() => setDisplayOut(false)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <form
              onSubmit={onSumbit}
              encType="multipart/form-data"
              charSet="utf-8"
            >
              <Box gap="small">
                <Text size="small" color="#4F4F4F">
                  Kindly ensure the report to be attached is in the correct
                  format . The Report columns should follow this order:
                </Text>
                <Text
                  size="small"
                  weight="bold"
                  style={{ fontStyle: "italic" }}
                  color="#82D4E7"
                >
                  First Name, Last name, Insurance, Email, Mobile number,
                  Upcoming appointment date
                </Text>
                <Box direction="row" align="center">
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="file"
                      onChange={(event) => handleFile(event)}
                    />
                    <Attachment size="small" color="white" />
                    <Text size="small">Attach Report</Text>
                  </label>
                  <Text
                    size="small"
                    color={fileName === "No File Uploaded" ? "red" : "green"}
                    margin={{ left: "xsmall" }}
                  >
                    {fileName}
                  </Text>
                </Box>
                <Box justify="between" direction="row" align="center">
                  <a
                    href={File}
                    download="ReportTemplate.csv"
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "#395E9D",
                    }}
                  >
                    <Box direction="row">
                      <Image width="20px" height="20px" src={ExcelLogo} />
                      <Text
                        size="small"
                        color="#395E9D"
                        margin={{ left: "xsmall" }}
                      >
                        Download Report Format
                      </Text>
                    </Box>
                  </a>
                  <Box direction="row" gap="small">
                    <Button
                      className="btn-inverse"
                      color="#c4c4c410"
                      size="small"
                      label="Cancel"
                      onClick={() => setDisplayOut(false)}
                    />
                    <Button
                      className="btn"
                      color="#395e9d"
                      size="small"
                      disabled={fileName === "No File Uploaded"}
                      label="Submit"
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default BulkInviteModal;
