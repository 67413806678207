import { Box } from "grommet";
import React from "react";
import OverdueGroups from "../../../common/OverdueGroups";

const OverdueInvoices = () => {
  return (
    <>
      <Box width="100%" pad="medium">
        <OverdueGroups />
      </Box>
    </>
  );
};

export default OverdueInvoices;
