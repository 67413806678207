import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckBox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";
import InPageLoader from "./InPageLoader";
import {
  getGroups,
  getTestGroups,
  toggleTestStatusForTest,
} from "../../store/sales/actions";
import { Document, Search } from "grommet-icons";
import InvoicesPendingAction from "./InvoicesPendingAction";

const AllTestAccounts = () => {
  const [loading, setLoading] = useState(false);
  const groups = useSelector((state) => state.sales.clinic_groups_test);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const handleTestStatusChange = (groupID, currentVal) => {
    dispatch(toggleTestStatusForTest(groupID, currentVal));
  };

  const paginatedResults = [];

  let counter = 1;

  const pageSie = 10;

  let toPage = 0;

  const [searchTerm, setSearchTerm] = useState("");

  if (groups)
    for (let i = 0; i < groups.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      paginatedResults[toPage].push(groups[i]);
      counter += 1;

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }

  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
    }
  }, []);

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  useEffect(() => {
    dispatch(getTestGroups(success, searchTerm));
  }, [searchTerm]);

  if (loading || !groups)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Text color="#1D3763" weight="bold">
        Test Accounts
      </Text>

      <Box
        onClick={() => htmlToCSV("data.csv")}
        pad="small"
        style={{ maxHeight: "42px" }}
        alignSelf="end"
        align="center"
        className="selectReports"
        direction="row"
        gap="small"
      >
        <Text weight="bold" size="small" color="#395E9D">
          Download as CSV
        </Text>
        <Document color="#ADB5BD" />
      </Box>

      <Box direction="row" width="100%" justify="between">
        <Box
          round="small"
          align="center"
          justify="between"
          direction="row"
          height="38px"
          width={"320px"}
          className="whiteBack"
        >
          <TextInput
            name="search"
            plain
            type="text"
            className="searchInput"
            placeholder="Search"
            value={searchTerm}
            onChangeCapture={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            required
          />

          <Box margin={{ right: "small" }}>
            <Search color="#395E9D" />
          </Box>
        </Box>
      </Box>
      <Box className="large-card" pad="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  NAME
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  CREATED
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  TYPE
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  SALES REP
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  INVOICES PENDING ACTION
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  TOGGLE TEST STATUS
                </Text>
              </TableCell>
              <TableCell scope="col" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {groups.length > 0 &&
              paginatedResults[page] &&
              paginatedResults[page].map((group) => (
                <TableRow key={group.id}>
                  <TableCell scope="row" align="center">
                    <Text size="small">{group.name}</Text>
                  </TableCell>

                  <TableCell align="center">
                    <Text size="small">
                      {moment(group.created).format("MM/DD/YYYY")}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text size="small">
                      {group.type ? group.type : "Not Specified"}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text size="small">
                      {group.rose_sales_team_member
                        ? group.rose_sales_team_member
                        : "Not Specified"}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <InvoicesPendingAction groupID={group.id} />
                  </TableCell>
                  <TableCell align="center">
                    <Box direction="row" align="center" justify="center">
                      <CheckBox
                        onChange={() => {
                          handleTestStatusChange(
                            group.id,
                            group.is_test_account,
                            group.name
                          );
                        }}
                        toggle
                        checked={group ? group.is_test_account : false}
                      />
                    </Box>{" "}
                  </TableCell>
                  <TableCell>
                    <Box direction="row" justify="center" align="center">
                      <Button
                        className="btn-primary"
                        color="#2BC2F7"
                        onClick={() =>
                          dispatch(push(`/dashboard/healthgroup/?${group.id}`))
                        }
                        margin={{ horizontal: "small" }}
                        size="small"
                        label="View"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box width="100%">
          <Box height="1px" background="#395E9D" />
          <Box
            direction="row"
            width="100%"
            justify="between"
            align="center"
            pad={{ horizontal: "small" }}
          >
            <Box>
              <Text size="small" color="#395E9D">
                Showing{" "}
                {groups.length === 0 ? 0 : pageSie * (page + 1) - (pageSie - 1)}{" "}
                to {groups[page + 1] ? pageSie * (page + 1) : groups.length} of{" "}
                {groups.length} entries
              </Text>
            </Box>
            <Box direction="row" align="center">
              <Button
                size="small"
                color="#666666"
                onClick={() =>
                  paginatedResults[page - 1] ? setPage(page - 1) : null
                }
                disabled={!paginatedResults[page - 1]}
              >
                <Text size="small">
                  {groups.length === 0 ? null : "Previous"}
                </Text>
              </Button>
              {pages.map((vals) => (
                <Text
                  key={vals}
                  className={vals === page ? "activePage" : "inactivePage"}
                  textAlign="center"
                  size="small"
                  margin="xsmall"
                  color="#82D4E7"
                  onClick={() => setPage(vals)}
                >
                  {vals + 1}
                </Text>
              ))}
              {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
              <Button
                size="small"
                color="#666666"
                onClick={() =>
                  paginatedResults[page + 1] ? setPage(page + 1) : null
                }
                disabled={!paginatedResults[page + 1]}
              >
                <Text size="small">{groups.length === 0 ? null : "Next"}</Text>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AllTestAccounts;
