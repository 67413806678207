import React from "react";
import { Avatar, Box, Image, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import InPageLoaderNew from "./InPageLoaderNew";
import { getClinicAdminById } from "../../store/caregiver/actions";
import { useEffect } from "react";

const HeaderTitle = ({ heading }) => (
  <Box background="#E0E0E033" pad="xsmall" round="8px">
    <Text color="#1C3664" size="small" weight="bold">
      {heading}
    </Text>
  </Box>
);

const LabelText = ({ label, text }) => (
  <Box gap="small" direction="row" pad={{ horizontal: "small" }}>
    <Text color="#4F4F4F" size="small">
      {label}
    </Text>
    <Text color="#44444F" size="small" weight="bold">
      {text}
    </Text>
  </Box>
);

const ProfileBoxCareGiver = () => {
  const clinician = useSelector((state) => state.caregiver.child_admin);

  const user = useSelector((state) => state.caregiver.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClinicAdminById(user.child.clinician));
  }, []);

  const getAvatar = () => {
    if (user.hasOwnProperty("profilePicture")) {
      return <Image src={user.profilePicture} fit="cover" />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="4xl">
          {user ? user.first_name[0] + user.last_name[0] : ""}
        </Text>
      </Box>
    );
  };

  if (!clinician) return <InPageLoaderNew />;

  return (
    <Box
      pad="medium"
      direction="row"
      gap="small"
      align="start"
      className="large-card"
    >
      <Box width="20%" height="100%" align="center" justify="center">
        <Avatar
          size="xlarge"
          background="#f1f1f5"
          style={{ border: "3px solid #28539e" }}
        >
          {user ? getAvatar() : null}
        </Avatar>
      </Box>

      <Box gap="small" width="40%">
        <HeaderTitle heading="Caregiver Details" />
        <LabelText label="Last Name" text={user.last_name} />
        <LabelText label="First Name" text={user.first_name} />
        <LabelText label="Email Address" text={user.email} />
      </Box>

      <Box gap="small" width="40%">
        <HeaderTitle heading="Care Details" />
        <LabelText
          label="Name"
          text={`${user.child.first_name} ${user.child.last_name}`}
        />
        <LabelText
          label="D.O.B"
          text={
            user.child.date_of_birth
              ? `${moment(user.child.date_of_birth).format("MM/DD/YYYY")}`
              : "Not found"
          }
        />

        <HeaderTitle heading="Your child's care team" />
        <LabelText
          label="Provider Name"
          text={`${clinician.first_name} ${clinician.last_name}`}
        />
      </Box>
    </Box>
  );
};
export default ProfileBoxCareGiver;
