import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const Histogram = ({
  data,
  yAxisMax,
  nameToPut,
  yAxisLabel,
  heading,
  barColor,
}) => {
  let xAxisData = ["No Data Found"];

  const SeriesData = [];
  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const getOption = () => {
    data.forEach((element, index) => {
      xAxisData[index] = element.created;
    });

    data.forEach((element, index) => {
      SeriesData[index] = [element.created, element.score];
    });

    if (xAxisData[0] !== "No Data Found") {
      xAxisData.forEach((item, index) => {
        xAxisData[index] = moment(item).format("MMM DD");
      });
    }

    SeriesData.forEach((element, index) => {
      SeriesData[index] = element[1];
    });

    return {
      legend: {
        show: true,
        top: 270,
        data: [nameToPut],
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },
          magicType: {
            type: ["line"],
            title: {
              line: "Show Line Graph",
            },
          },
          restore: {
            title: "Restore",
          },
          saveAsImage: {
            title: "Save As Image",
            name:
              nameToPut +
              ` ${moment(new Date()).format(
                "MM DD YYYY"
              )} ${firstName} ${lastName}`,
          },
        },
      },

      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${params.name}  <br/> ${params.seriesName}:  ${params.value}`;
        },
      },

      xAxis: {
        offset: 5,
        type: "category",
        data: xAxisData,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        max: yAxisMax,
        min: 0,
        name: yAxisLabel,
        nameLocation: "middle",
        nameTextStyle: {
          padding: [0, 0, 10, 0],
          color: "#999999",
        },
        axisLabel: {
          color: "#999999",
        },

        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          name: nameToPut,

          color: barColor,
          data: SeriesData,
          type: "bar",
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          {heading}
        </Text>
        <CustomToolTip
          outText="i"
          content="Single-item questions to assess the patient's daily mental well-being."
          subText="The 5-point scale ranges from 0 (great data) to 4 (awful data)."
        />
      </Box>
      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default Histogram;
