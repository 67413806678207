import React from "react";
import { Box } from "grommet";
import moment from "moment";
import LineGraphCategories from "../../../../common/LineGraphCategories";
import GoBackTItle from "../../../../common/GoBackTItle";

const MeasurePatients = ({ data, countActive, heading }) => {
  let breakDownforGraph = [];
  let filteredData = [];
  let graphX = [];
  let graphY = [];
  let colors = [];
  const getActiveState = (x) => {
    const d = new Date();
    d.setDate(d.getDate() - 30);
    if (new Date(x.last_active) > d) return true;
    else return false;
  };

  const processGraphData = (initialArray) => {
    initialArray.forEach((x) => {
      if (!countActive) breakDownforGraph.push(x.date_joined);

      if (countActive && getActiveState(x))
        breakDownforGraph.push(x.date_joined);
    });

    breakDownforGraph.sort((a, b) => new Date(a) - new Date(b));

    breakDownforGraph.forEach(
      (x, index) => (breakDownforGraph[index] = moment(x).format("MMM YYYY"))
    );
    filteredData = [...new Set([...breakDownforGraph])];

    filteredData.forEach((x, index) => {
      filteredData[index] = { month: x, count: 0 };
    });

    breakDownforGraph.forEach((x) => {
      filteredData.find((ex) => {
        if (ex.month === x) {
          ex.count = ex.count + 1;
          return 1;
        }
        return 0;
      });
    });

    filteredData.forEach((x, index) => {
      graphX[index] = x.month;
      graphY[index] = x.count;
    });

    filteredData.forEach(() => {
      colors.push("#395E9D");
    });
  };

  processGraphData(data);

  return (
    <Box pad="medium">
      <GoBackTItle heading={heading} link="/dashboard/acoexecutiveHome" />
      <LineGraphCategories
        barColor=""
        yAxisLabel="Count"
        heading=""
        data={graphY}
        nameToPut="Patients"
        horizontalDatas={graphX}
        colors={colors}
        yAxisMax={Math.max(...graphY)}
      />
    </Box>
  );
};
export default MeasurePatients;
