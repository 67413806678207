/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Text,
  Button,
  // CheckBox,
  Tabs,
  // Tab,
  Layer,
  TextInput,
  // DateInput,
  // Select,
  Image,
  Menu,
  TextArea,
  CheckBox,
  // TextArea,
} from "grommet";
import Selected, { components } from "react-select";
import {
  // MailOption,
  Pin,
  // Plan,
  Clipboard,
  Flag,
  Close,
  Edit,
  Add,
  DocumentDownload,
  Chat,
  StatusUnknown,
  Alert,
} from "grommet-icons";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import jsPDF from "jspdf";
import moment from "moment";
import html2canvas from "html2canvas";

import { Multiselect } from "multiselect-react-dropdown";
import CustomTab from "./CustomTab";
import { groupedOptions } from "./BHIOptionsGroups";
// import moment from "moment";
import Loader from "./InPageLoader";
import { isMobileHook, iTabletHook } from "./Responsive";
// import Biomarkers from "./Biomarkers";
import MentalHealth from "./MentalHealth";
import NotesHistoryModal from "./NotesHistoryModal";
import {
  fetchSinglePatient,
  getStaff,
  saveBHINote,
  toggleMessageStatus,
  updatePatientBHI,
} from "../../store/patients/actions";
import EnrollModal from "./EnrollModal";
import ResetFlagModal from "./ResetFlagModal";
import ChatModal from "../pages/dashboard/InDashboardChat/ChatModal";
import OverideEligibilityModal from "./OverideEligibilityModal";
import SecurityQuestionModal from "./SecurityQuestionModal";
import EnrollModalTWS from "./EnrollModalTWS";
import TWSUpdateModal from "./TWSUpdateModal";
import FireFlagsModal from "./FireFlagsModal";
// import { borderRadius } from "polished";

const ProfileBox = () => {
  const [show, setShow] = useState(false);
  const [showUpdateTWS, setShowUpdateTWS] = useState(false);

  const [enrollShow, setEnrollShow] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);
  const [chatShow, setChatShow] = useState(false);
  const [overideEligibilityShow, setOverideEligibilityShow] = useState(false);
  const [securityShow, setSecurityShow] = useState(false);
  const [fireFlagModal, setFireFlagModal] = useState(false);

  const [resetFlagShow, setResetFlagShow] = useState(false);
  const [flagToSet, setFlagtoSet] = useState();
  const [notes, setNotes] = useState("");

  const results = useSelector((state) => state.patients.assessment_results);
  const user = useSelector((state) => state.app.user.user);

  const { search } = useSelector((state) => state.router.location);
  const patient = useSelector((state) => state.patients.patient) || null;
  const patientBHI = useSelector((state) => state.patients.patient_bhi);

  const [showValue1, setShowValue1] = useState("");
  const [showValue2, setShowValue2] = useState(
    patient ? [patient.clinician] : null
  );

  const [loading, setLoading] = useState();
  const [cuurentIndex, setCurrentIndex] = useState(0);
  const [showDownloadBar, setShowDownloadBar] = useState(false);
  // const [downloadProgress, setDownloadProgress] = useState("0%");
  const [change, setChange] = useState(false);

  const [getIndexCall, sendGetIndexCall] = useState(false);
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const staff = useSelector((state) => state.patients.staff);
  const dispatch = useDispatch();
  const isMobileorTablet = isMobile || isTablet;
  const [fireFlagsToShow, setFireFlagsToShow] = useState([]);

  // const [messageStatus, setMessageStatus] = useState(0);

  const handleFireFlagModal = (patient) => {
    setFireFlagsToShow(patient);
    setFireFlagModal(true);
  };

  const options = [];
  if (staff && patient) {
    staff.forEach((data) => {
      if (data.id === patient.clinician) {
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }

      if (data.role === "CLINICAL_STAFF")
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
    });
  }

  const handleDropDownSelect = (nextValue) => {
    const newArray = [];
    nextValue.forEach((element, index) => {
      newArray[index] = element.key;
    });
    setShowValue2(newArray);
  };

  const handleDropDownRemove = (nextValue, removedItem) => {
    const newArray = showValue2.filter((value) => value !== removedItem.key);
    setShowValue2(newArray);
  };

  const handleHeaderClick = (id) => {
    const node = document.querySelector(`#${id}`).parentElement
      .nextElementSibling;
    const classes = node.classList;
    if (classes.contains("collapsed")) {
      node.classList.remove("collapsed");
    } else {
      node.classList.add("collapsed");
    }
  };

  const CustomGroupHeading = (props) => (
    <div
      className="group-heading-wrapper"
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} />
    </div>
  );

  function getIndex() {
    // const index = JSON.parse(window.localStorage.getItem("currentTab")) || 0;
    setCurrentIndex(0);
  }

  if (!getIndexCall) {
    getIndex();
    sendGetIndexCall(true);
  }

  const onActive = (index) => {
    window.localStorage.setItem("currentTab", JSON.stringify(index));
    getIndex();
  };

  const onSuccess = () => {
    setLoading(false);
  };

  const preformLater = (toDo) => {
    setShow(toDo);
    setNotes("");
    setChange(false);
  };

  const updatePatient = () => {
    const filtered = showValue2.filter((value) => value !== patient.clinician);
    dispatch(
      updatePatientBHI(
        showValue1.value,
        filtered,
        patientBHI.id,
        patientBHI.patient,
        preformLater
      )
    );
    dispatch(saveBHINote(showValue1.value, notes, patient.id, user.id));
  };

  const getEligibility = () => {
    if (results && patient) {
      if (patient.bhi_eligible)
        return (
          <Text size="small" color="green">
            Eligible
          </Text>
        );
    }

    return (
      <Box
        className="noOutline"
        onClick={() => setOverideEligibilityShow(true)}
      >
        <Text size="small" color="red">
          Ineligible
        </Text>
      </Box>
    );
  };

  const getFlag = (flag) => {
    if (flag === "RED") {
      return "rFlag";
    }
    if (flag === "YELLOW") {
      return "yFlag";
    }
    return "gFlag";
  };

  const getAvatar = () => {
    if (patient.hasOwnProperty("profile_picture") && patient.profile_picture) {
      return <Image src={patient.profile_picture} fit="cover" fill />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="3xl">
          {patient ? patient.first_name[0] + patient.last_name[0] : ""}
        </Text>
      </Box>
    );
  };

  const onStaffSuccess = () => true;

  useEffect(() => {
    setLoading(true);
    dispatch(getStaff(onStaffSuccess));
    dispatch(fetchSinglePatient(search, onSuccess));
  }, []);

  const openUpdate = () => {
    const newArray = _.cloneDeep(patient.shared_with_clinical_staff);
    newArray.push(patient.clinician);
    setShowValue2(newArray);

    setShowValue1({ value: patientBHI.diagnosis, label: patientBHI.diagnosis });

    if (
      user.role !== "TWS_COORDINATOR" &&
      user.role !== "TWS_CONCIERGE" &&
      user.role !== "TWS_CASE_MANAGER"
    )
      setShow(true);
    else setShowUpdateTWS(true);
  };

  const openSecurity = () => {
    setSecurityShow(true);
  };

  const printDocument = () => {
    setShowDownloadBar(true);

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      unit: "px",
      hotfixes: ["px_scaling"],
    });

    // eslint-disable-next-line no-undef
    const input = document.getElementById("pdf");
    const imgWidth = 793;
    const pageHeight = 1115;
    let heightLeft = input.offsetHeight - (30 / 100) * input.offsetHeight;
    let position = 10;
    // const profileBox = document.getElementById("pdf1");
    // const LastestAssessments = document.getElementById("pdf2");
    // const SecondAssessments = document.getElementById("pdf3");
    // const ThirdAssessments = document.getElementById("pdf4");
    // const Journals = document.getElementById("pdf5");

    html2canvas(input).then((dataUrl) => {
      const imgData = dataUrl.toDataURL("image/png");

      pdf.addImage(
        imgData,
        "PNG",
        0,
        position,
        793,
        input.offsetHeight - (30 / 100) * input.offsetHeight,
        "patientSummary",
        "SLOW"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position +=
          heightLeft - (input.offsetHeight - (30 / 100) * input.offsetHeight);
        pdf.addPage();

        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          input.offsetHeight - (30 / 100) * input.offsetHeight,
          "patientSummary",
          "SLOW"
        );
        heightLeft -= pageHeight;
      }

      pdf.save(
        `Report Patient: ${moment(new Date()).format("MM/DD/YYYY")}.pdf`
      );
      setShowDownloadBar(false);
    });
  };

  const handleMessagingChange = (patientID, currentVal) => {
    dispatch(toggleMessageStatus(patientID, currentVal));
  };

  const getManagedBy = (managedArray) => {
    let thisStaff;

    if (staff) {
      if (user.role === "CLINIC_ADMIN")
        thisStaff = staff.find((item) => item.id === user.id);
      else thisStaff = staff.find((item) => item.id === patient.clinician);

      let finalString = "";

      if (thisStaff) {
        if (managedArray.length > 0)
          finalString = `${thisStaff.first_name} ${thisStaff.last_name}, `;
        else finalString = `${thisStaff.first_name} ${thisStaff.last_name}`;
      }

      managedArray.forEach((element, index) => {
        const sharedStaff = staff.find((item) => item.id === element);

        if (index !== managedArray.length - 1)
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}, `;
        else
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}`;
      });

      return finalString;
    }

    return "";
  };

  const getManagedByTWS = (managedArray) => {
    let finalString = "";

    managedArray.forEach((element, index) => {
      const sharedStaff = staff.find((item) => item.id === element);
      if (sharedStaff) {
        if (index !== managedArray.length - 1)
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}, `;
        else
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}`;
      }
    });

    return finalString === "" ? "No Manager Assigned" : finalString;
  };

  const getSelectedValues = () => {
    const newArray = [];
    staff.forEach((data) => {
      if (data.id === patient.clinician) {
        newArray.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }
    });

    if (user.role === "CLINICAL_STAFF") {
      staff.forEach((data) => {
        if (
          patient.shared_with_clinical_staff.find(
            (element) => element === data.id
          )
        ) {
          newArray.push({
            value: `${data.first_name} ${data.last_name}`,
            key: data.id,
          });
        }
      });
    }
    return newArray;
  };

  const getEligibilityButton = () => {
    if (results) {
      if (
        patient.bhi_eligible ||
        user.role === "TWS_COORDINATOR" ||
        user.role === "TWS_CONCIERGE"
      )
        return false;
    }

    return true;
  };

  const handleChange = () => {
    const newArray = [];
    newArray.push(patient.clinician);
    setShowValue2(newArray);

    setChange(true);
  };

  const getButton = () => {
    if (patientBHI && user.role !== "TWS_CASE_MANAGER")
      return (
        <Button
          size="small"
          label="Update"
          className="btn-profile-dark"
          color="#395E9D"
          onClick={() => openUpdate()}
          icon={<Edit size="small" color="white" />}
        />
      );

    if (
      user.role === "CLINIC_ADMIN" ||
      user.role === "CLINICAL_STAFF" ||
      user.role === "TWS_COORDINATOR"
    )
      return (
        <Button
          size="small"
          label="Enroll"
          className="btn-profile-dark"
          color="#395E9D"
          disabled={getEligibilityButton()}
          onClick={() => setEnrollShow(true)}
          icon={<Add size="small" color="white" />}
        />
      );

    return null;
  };

  if (loading) {
    return (
      <Box className="large-card-sharp">
        <Loader />
      </Box>
    );
  }
  return (
    <>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-update"
          position="right"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="brand" weight="bold">
                Update Patient Details
              </Text>
              <Box>
                <Close onClick={() => setShow(false)} />
              </Box>
            </Box>
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Name
              </Text>
              <Box round="xsmall" margin={{ top: "6px" }}>
                <TextInput
                  size="small"
                  name="username"
                  plain
                  disabled
                  value={`${patient.first_name} ${patient.last_name}`}
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
            {/* <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Date of Birth
              </Text>
              <Box
                round="xsmall"
                margin={{ top: "6px" }}
              >
                <DateInput
                  size="small"
                  plain
                  disabled
                  format="mm/dd/yyyy"
                  value={patient.date_of_birth}
                  className="semiInput"
                />
              </Box>
            </Box> */}
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Behavioral Health Diagnosis Update:
              </Text>
              <Selected
                value={showValue1}
                menuColor="white"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "14px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: "solid 1px #C4C4C4",
                    marginTop: "6px",
                    backgroundColor: "#FBFBFB",
                    borderRadius: "6px",
                    fontSize: "14px",
                    padding: "1%",
                  }),
                }}
                onChange={setShowValue1}
                options={groupedOptions}
                components={{ GroupHeading: CustomGroupHeading }}
              />
            </Box>
            <Box direction="row" align="center" justify="between">
              <Box direction="row">
                <Text
                  size="xsmall"
                  weight="bold"
                  color="#4F4F4F"
                  margin={{ right: "xsmall" }}
                >
                  Managed By:
                </Text>
                <Text size="xsmall" weight="bold" color="#A9A9A9">
                  {patient
                    ? user.role === "TWS_COORDINATOR"
                      ? getManagedByTWS(patient.shared_with_tws_concierge)
                      : getManagedBy(patient.shared_with_clinical_staff)
                    : ""}
                </Text>
              </Box>
              {user && user.role === "CLINIC_ADMIN" ? (
                <Box onClick={() => handleChange()} className="noOutline">
                  <Text size="small">{!change ? "Change?" : ""}</Text>
                </Box>
              ) : null}
            </Box>
            {change ? (
              <Box width="100%">
                <Box>
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Update Behavioural Health Manager
                  </Text>
                </Box>

                <Box
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                >
                  <Multiselect
                    options={options}
                    displayValue="value"
                    disable={
                      user.role === "CLINICAL_STAFF" ||
                      user.role === "TWS_CONCIERGE" ||
                      user.role === "TWS_CASE_MANAGER"
                    }
                    showCheckbox
                    placeholder={
                      user.role === "CLINICAL_STAFF" ||
                      user.role === "TWS_CONCIERGE" ||
                      user.role === "TWS_CASE_MANAGER"
                        ? "Disabled"
                        : "Select..."
                    }
                    closeOnSelect={false}
                    hidePlaceholder
                    selectedValues={getSelectedValues()}
                    avoidHighlightFirstOption
                    showArrow
                    onSelect={handleDropDownSelect} // Function will trigger on select event
                    onRemove={handleDropDownRemove} // Function will trigger on remove event
                    disablePreSelectedValues
                    closeIcon="cancel"
                  />
                </Box>
              </Box>
            ) : null}
            <Box>
              <Box direction="row" justify="between">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  BHI Notes:
                </Text>
                <Box
                  border="bottom"
                  className="noOutline"
                  onClick={() => setHistoryShow(true)}
                >
                  <Text size="xsmall">See Notes History</Text>
                </Box>
              </Box>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>
            <Box align="center">
              <Button
                className="btn-fullwidth"
                color="#395e9d"
                disabled={notes === "" || showValue1 === ""}
                onClick={() => updatePatient()}
                size="small"
                label="Submit"
              />
            </Box>
          </Box>
        </Layer>
      )}
      {enrollShow &&
        (user.role === "TWS_COORDINATOR" ? (
          <EnrollModalTWS
            display={enrollShow}
            setDisplayOut={(value) => setEnrollShow(value)}
          />
        ) : (
          <EnrollModal
            display={enrollShow}
            setDisplayOut={(value) => setEnrollShow(value)}
          />
        ))}
      {historyShow && (
        <NotesHistoryModal
          display={historyShow}
          setDisplayOut={(value) => setHistoryShow(value)}
        />
      )}
      {fireFlagModal && (
        <FireFlagsModal
          fireFlagsToShow={fireFlagsToShow}
          display={fireFlagModal}
          setDisplayOut={(value) => setFireFlagModal(value)}
        />
      )}
      {resetFlagShow && (
        <ResetFlagModal
          display={resetFlagShow}
          flag={flagToSet}
          setDisplayOut={(value) => setResetFlagShow(value)}
        />
      )}
      {chatShow && (
        <ChatModal
          display={chatShow}
          setDisplayOut={(value) => setChatShow(value)}
        />
      )}
      {overideEligibilityShow && (
        <OverideEligibilityModal
          display={overideEligibilityShow}
          setDisplayOut={(value) => setOverideEligibilityShow(value)}
        />
      )}

      {securityShow && (
        <SecurityQuestionModal
          display={securityShow}
          setDisplayOut={(value) => setSecurityShow(value)}
        />
      )}

      {showUpdateTWS && (
        <TWSUpdateModal
          display={showUpdateTWS}
          setDisplayOut={(value) => setShowUpdateTWS(value)}
        />
      )}
      <Box id="pdf">
        <Box id="pdf1">
          <Box
            className="large-card-sharp"
            // margin={{ top: "small", horizontal: "small", bottom: "small" }}
          >
            <Box>
              <Box
                direction={isMobileorTablet ? "column" : "row"}
                align="start"
                justify="center"
                margin={{ top: "large", bottom: "xsmall" }}
                gap="large"
              >
                <Box
                  justify="center"
                  align="center"
                  gap="xsmall"
                  width={isMobileorTablet ? "100%" : "60%"}
                >
                  <Avatar
                    size="xlarge"
                    background="#f1f1f5"
                    style={{ border: "3px solid #28539e" }}
                  >
                    {patient ? getAvatar() : null}
                  </Avatar>
                  <Box
                    className={patient ? getFlag(patient.flag) : null}
                    pad={{ horizontal: "small", vertical: "xxsmall" }}
                  >
                    {patient ? (
                      <Text size="10px">
                        {patient.flag ? `${patient.flag} FLAG` : "GREEN FLAG"}
                      </Text>
                    ) : null}
                  </Box>

                  <Box
                    // className={patient ? getFlag(patient.flag) : null}
                    pad={{ horizontal: "small", vertical: "xxsmall" }}
                  >
                    {patient &&
                    patient.fire_flag &&
                    patient.fire_flag.length > 0 ? (
                      <Box
                        className="noOutline"
                        onClick={() => handleFireFlagModal(patient)}
                        style={{ cursor: "pointer" }}
                        pad="xsmall"
                        background="#FABFBF"
                        gap="3px"
                        justify="center"
                        direction="row"
                        align="center"
                        round="4px"
                      >
                        <Alert size="10px" color="#EB5757" />
                        <Text size="8px" weight="bold" color="#EB5757">
                          URGENT RISK
                        </Text>
                      </Box>
                    ) : null}
                  </Box>
                  <Menu
                    size="small"
                    label={
                      <Box direction="row" align="center" gap="small">
                        <Flag size="small" color="#28539E" />
                        <Text size="small" color="#28539E">
                          Reset Flag
                        </Text>
                      </Box>
                    }
                    // className={
                    //   isMobileorTablet
                    //     ? "btn-profile-light-mobile"
                    //     : "btn-profile-light"
                    // }
                    justifyContent="center"
                    dropAlign={{ top: "bottom" }}
                    dropProps={{ className: "dropMenu" }}
                    items={[
                      {
                        label: "Remove Flag",
                        onClick: () => {
                          setResetFlagShow(!resetFlagShow);
                          setFlagtoSet(null);
                        },
                      },
                      {
                        label: "Set Red Flag",
                        onClick: () => {
                          setResetFlagShow(!resetFlagShow);
                          setFlagtoSet("RED");
                        },
                      },
                      {
                        label: "Set Yellow Flag",
                        onClick: () => {
                          setResetFlagShow(!resetFlagShow);
                          setFlagtoSet("YELLOW");
                        },
                      },
                    ]}
                    icon={false}
                  />
                </Box>
                <Box
                  justify="between"
                  width="66%"
                  margin={isMobileorTablet ? { left: "large" } : null}
                >
                  <Box align="start" gap="small">
                    <Box direction="row">
                      <Text size="small" margin={{ right: "xsmall" }}>
                        First Name:
                      </Text>
                      <Text size="small" color="#A9A9A9">
                        {patient ? patient.first_name : " "}
                      </Text>
                    </Box>
                    <Box direction="row">
                      <Text size="small" margin={{ right: "xsmall" }}>
                        Last Name:
                      </Text>
                      <Text size="small" color="#A9A9A9">
                        {patient ? patient.last_name : " "}
                      </Text>
                    </Box>
                    {/* <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>Date of Birth (Age):</Text>
                  <Text
                    size="small"
                    color="#A9A9A9"
                  >
                    {patient ? `${patient.date_of_birth} (${moment().diff(moment(patient.date_of_birth), "years")})` : " "}
                  </Text>
                </Box> */}
                    <Box direction="row">
                      <Text size="small" margin={{ right: "xsmall" }}>
                        Email:
                      </Text>
                      <Text size="small" color="#A9A9A9">
                        {patient ? patient.email : " "}
                      </Text>
                    </Box>
                    <Box
                      onClick={() => {
                        setHistoryShow(true);
                      }}
                      className="noOutline"
                    >
                      <Text
                        size="small"
                        color="#50B5FF"
                        style={{ textDecoration: "underline" }}
                      >
                        See patient notes
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  justify="center"
                  width="100%"
                  margin={
                    isMobileorTablet ? { left: "large", bottom: "large" } : null
                  }
                >
                  <Box align="start" gap="small">
                    {user.role === "TWS_COORDINATOR" ||
                    user.role === "TWS_CONCIERGE" ||
                    user.role === "TWS_CASE_MANAGER" ? (
                      <Box direction="row">
                        <Text size="small" margin={{ right: "xsmall" }}>
                          Cell Number:
                        </Text>

                        <Text size="small" color="#A9A9A9">
                          {patient
                            ? patient.cell_number
                            : "Patient not Enrolled"}
                        </Text>
                      </Box>
                    ) : (
                      <Box direction="row">
                        <Text size="small" margin={{ right: "xsmall" }}>
                          BH Diagnosis:
                        </Text>

                        <Text size="small" color="#A9A9A9">
                          {patientBHI
                            ? patientBHI.diagnosis
                            : "Patient not Enrolled"}
                        </Text>
                      </Box>
                    )}
                    {user.role === "TWS_COORDINATOR" ||
                    user.role === "TWS_CONCIERGE" ||
                    user.role === "TWS_CASE_MANAGER" ? null : (
                      <Box direction="row">
                        <Text size="small" margin={{ right: "xsmall" }}>
                          BHI Status:
                        </Text>
                        <Text size="small" color="#74C9C1">
                          {patientBHI ? "Enrolled" : getEligibility()}
                        </Text>
                      </Box>
                    )}
                    {patientBHI ? (
                      <Box direction="row">
                        <Text size="small" margin={{ right: "xsmall" }}>
                          Managed By:
                        </Text>
                        <Text size="small" color="#A9A9A9">
                          {patient
                            ? user.role === "TWS_COORDINATOR" ||
                              user.role === "TWS_CONCIERGE" ||
                              user.role === "TWS_CASE_MANAGER"
                              ? getManagedByTWS(
                                  patient.shared_with_tws_concierge
                                )
                              : getManagedBy(patient.shared_with_clinical_staff)
                            : ""}
                        </Text>
                      </Box>
                    ) : null}
                    {user.clinic.rose_chat &&
                    user.role !== "TWS_COORDINATOR" ? (
                      <Box direction="row">
                        <Box>
                          <Text size="small">Chat Status:</Text>
                        </Box>
                        <Box margin={{ left: "small" }} direction="row">
                          <CheckBox
                            onChange={() =>
                              handleMessagingChange(
                                patient.id,
                                patient.rose_chat
                              )
                            }
                            toggle
                            checked={patient ? patient.rose_chat : false}
                            className="noOutline"
                          />
                          <Text
                            size="small"
                            color="#395E9D"
                            margin={{ left: "xsmall" }}
                          >
                            {patient && patient.rose_chat
                              ? "ENABLED"
                              : "DISABLED"}
                          </Text>
                        </Box>
                      </Box>
                    ) : null}

                    {/* <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>Managed By:</Text>
                  <Text
                    size="small"
                    color="#A9A9A9"
                  >
                    {patientBHI ? patientBHI.bhi_manager : "Patient not Enrolled"}
                  </Text>
                </Box> */}
                  </Box>
                </Box>
              </Box>

              <Box
                direction={isMobileorTablet ? "column" : "row"}
                justify="center"
                margin={
                  isMobileorTablet
                    ? { horizontal: "medium" }
                    : { top: "medium", left: "large" }
                }
                gap="small"
                wrapProp="true"
              >
                {/* <Button
              size="small"
              label="Inbox"
              className="btn-profile-light"
              color="#F1F1F5"
              icon={<MailOption size="small" color="#28539E" />}
            /> */}
                {user.role === "TWS_COORDINATOR" ||
                user.role === "TWS_CONCIERGE" ||
                user.role === "TWS_CASE_MANAGER" ? (
                  <Button
                    size="small"
                    label="Security Question"
                    className="btn-profile"
                    color="#2BC2F7"
                    onClick={
                      () => openSecurity()
                      // dispatch(push(`/dashboard/pinContent/?${patient.id}`))
                    }
                    icon={<StatusUnknown size="small" color="white" />}
                  />
                ) : null}
                <Button
                  size="small"
                  label="Pin Content"
                  className={
                    isMobileorTablet
                      ? "btn-profile-light-mobile"
                      : "btn-profile-light"
                  }
                  color="#F1F1F5"
                  onClick={() =>
                    dispatch(push(`/dashboard/pinContent/?${patient.id}`))
                  }
                  icon={<Pin size="small" color="#28539E" />}
                />
                {/* <Button
              size="small"
              label="Schedule Appointments"
              className="btn-profile-light"
              color="#F1F1F5"
              icon={<Plan size="small" color="#28539E" />}
            /> */}
                <Button
                  size="small"
                  label="Assessments"
                  className={
                    isMobileorTablet
                      ? "btn-profile-light-mobile"
                      : "btn-profile-light"
                  }
                  onClick={() =>
                    dispatch(
                      push(`/dashboard/scheduleAssessments/?${patient.id}`)
                    )
                  }
                  color="#F1F1F5"
                  icon={<Clipboard size="small" color="#28539E" />}
                />

                {/* eslint-disable-next-line no-nested-ternary */}
                <Box>
                  <Button
                    size="small"
                    disabled={showDownloadBar}
                    label="Download"
                    className={
                      isMobileorTablet
                        ? "btn-profile-light-mobile"
                        : "btn-profile-light"
                    }
                    onClick={() => printDocument()}
                    color="#F1F1F5"
                    icon={<DocumentDownload size="small" color="#28539E" />}
                  />
                  {showDownloadBar ? (
                    <Box
                      margin={{ top: "xsmall", horizontal: "xxsmall" }}
                      background="#DCDCDC"
                      height="7px"
                      round="small"
                    >
                      <Box className="downloadBarInner" width={0} />
                    </Box>
                  ) : (
                    <Box
                      margin={{ top: "xsmall", horizontal: "xxsmall" }}
                      height="7px"
                    />
                  )}
                </Box>

                {user.clinic.rose_chat &&
                patient &&
                patient.rose_chat &&
                user.role !== "TWS_COORDINATOR" ? (
                  <Button
                    size="small"
                    label="Chat"
                    className={
                      isMobileorTablet
                        ? "btn-profile-light-mobile"
                        : "btn-profile-light"
                    }
                    onClick={() => setChatShow(true)}
                    color="#F1F1F5"
                    icon={<Chat size="small" color="#28539E" />}
                  />
                ) : null}

                {patient ? getButton() : null}
              </Box>
            </Box>
            <Box>
              <Box alignSelf="end" margin={{ top: "medium" }} width="100%">
                <Tabs
                  activeIndex={cuurentIndex}
                  onActive={onActive}
                  style={{ width: "100%" }}
                  // justifyProp={"evenly"}
                >
                  <CustomTab title="Mental Health" className="tab" />
                  {/* <CustomTab title="Biomarkers" className="tab" /> */}
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          margin={{
            top: "0",
            left: "small",
            right: "small",
            bottom: "large",
          }}
        >
          {cuurentIndex === 0 ? <MentalHealth /> : <MentalHealth />}
        </Box>
      </Box>
    </>
  );
};
export default ProfileBox;
