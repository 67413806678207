/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Text, Image, Button, Collapsible, Layer } from "grommet";
import {
  Menu,
  Close,
  Home,
  // CircleInformation, Catalog,
  Logout,
} from "grommet-icons";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Patients from "../../../assets/icons/patients.svg";
// import Messages from "../../../assets/icons/messages.svg";
import Settings from "../../../assets/icons/settings.svg";
import Help from "../../../assets/icons/help.svg";

import Logo from "../../../assets/light-logo.png";

import { logout } from "../../../store/app/actions";
import ClinicSelector from "../../common/ClinicSelector";

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.global.colors.light};
  width: 100%;
  text-decoration: none !important;
  cursor: context-menu;
  transition: 0.5s;

  &:hover {
    color: ${(props) => props.theme.global.colors.light};
    text-decoration: none !important;
    cursor: context-menu;
  }
  &.active {
    width: 100%;
    background-color: ${(props) => props.theme.global.colors.active};
    color: ${(props) => props.theme.global.colors.sideNav};
    text-decoration: none !important;
  }
`;

const MobileMenu = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  // const clinic = useSelector((state) => state.app.user.user.clinic);
  const user = useSelector((state) => state.app.user.user);

  return (
    <Box width="100%" background="brand" align="center" justify="center">
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
        >
          <Box align="center" className="popup-card-mobile">
            <Text size="medium" textAlign="center">
              {" "}
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box justify="center" margin="small" pad={{ top: "small" }}>
        <Image src={Logo} alt="logo" />
      </Box>

      {user.role === "CLINIC_ADMIN" || user.role === "CLINICAL_STAFF" ? (
        <Box
          margin={{ bottom: "xlarge", top: "small" }}
          align="center"
          gap="small"
        >
          <Text
            margin={{ top: "4px", bottom: "10px" }}
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              fontFamily: "Courier New",
              lineHeight: "28px",
              color: "#74C9C1",
            }}
          >
            {window.localStorage.getItem("selectedDropdownPin")}
          </Text>
          <ClinicSelector />
        </Box>
      ) : null}
      <Box
        pad="medium"
        direction="row"
        width="100%"
        align="center"
        justify="between"
        style={{ borderTop: "1px solid rgb(255,255,255,0.3)" }}
        onClick={() => setOpen(!open)}
        className="hamburger-row"
      >
        <Box direction="row">
          {open ? <Close /> : <Menu />}
          <Text size="small" style={{ paddingLeft: "32px" }}>
            Menu
          </Text>
        </Box>
      </Box>
      <Box className="collapsible-menu">
        <Collapsible open={open}>
          <StyledLink exact to="/dashboard/home">
            <Box pad="medium" direction="row">
              <Home />
              <Text size="small" margin={{ left: "medium" }}>
                Home
              </Text>
            </Box>
          </StyledLink>

          <StyledLink exact to="/dashboard/patients">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Patients} alt="patients" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                All Patients
              </Text>
            </Box>
          </StyledLink>

          <StyledLink exact to="/dashboard/settings">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Settings} alt="settings" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Settings
              </Text>
            </Box>
          </StyledLink>

          {/* <StyledLink exact to="/dashboard/messages">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Messages} alt="messages" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Messages
              </Text>
            </Box>
          </StyledLink> */}
          {/* <StyledLink exact to="/faq">
            <Box
              pad="medium"
              direction="row"
            >
              <CircleInformation />
              <Text size="small" margin={{left:"medium"}}>FAQs</Text>
            </Box>
          </StyledLink> */}
          <StyledLink exact to="/dashboard/resources">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Help} alt="help" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Resources
              </Text>
            </Box>
          </StyledLink>

          <Box
            direction="row"
            pad="medium"
            width="100%"
            align="center"
            height="small"
            margin={{ left: "xsmall" }}
          >
            <Logout color="secondaryRed" />
            <Button
              margin={{ left: "medium" }}
              onClick={() => {
                setShow(true);
              }}
              style={{
                fontSize: "small",
                fontWeight: "bold",
              }}
              plain
              color="secondaryRed"
              label="Logout"
            />
          </Box>
        </Collapsible>
      </Box>
    </Box>
  );
});

export default MobileMenu;
