import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";
import { split } from "lodash";
import InPageLoader from "./InPageLoader";
import { getGroupClinics } from "../../store/sales/actions";

const GroupClinics = () => {
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];
  const [loading, setLoading] = useState(false);
  const clinics = useSelector((state) => state.sales.single_group_clinics);
  const dispatch = useDispatch();

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getGroupClinics(groupID, success));
  }, []);

  if (loading || !clinics)
    return (
      <Box pad="medium" gap="small" className="large-card">
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small" className="large-card">
      <Text color="#1D3763" weight="bold">
        Clinics
      </Text>
      <Box style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                <Text size="small" weight="bold">
                  NAME
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom">
                <Text size="small" weight="bold">
                  CREATED
                </Text>
              </TableCell>
              <TableCell scope="col" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {clinics.length > 0 &&
              clinics.map((clinic) => (
                <TableRow key={clinic.id}>
                  <TableCell scope="row">
                    <Text size="small">{clinic.name}</Text>
                  </TableCell>

                  <TableCell align="start">
                    <Text size="small">
                      {moment(clinic.created).format("MM/DD/YYYY")}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Box direction="row" justify="center" align="center">
                      <Button
                        className="btn-primary"
                        color="#2BC2F7"
                        onClick={() =>
                          dispatch(push(`/dashboard/clinic/?${clinic.id}`))
                        }
                        margin={{ horizontal: "small" }}
                        size="small"
                        label="View"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default GroupClinics;
