/* eslint-disable no-console */
/* eslint-disable camelcase */
import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_CLINIC_ADMIN_BY_ID,
  GET_PENDING_ASSESSMENTS,
  GET_THIS_CARE_GIVER,
  setCareGiver,
  setClinicAdmin,
  setPendingAssessments,
  SUBMIT_ASSESSMENT,
} from "./actions";
import api from "../../services/api/ApiService";

function getCareGiverSagaCall(id) {
  const resp = api.get(`/api/caregiver/${id}`);

  return resp;
}

function* getCareGiverSaga({ payload: { id } }) {
  const { data } = yield call(getCareGiverSagaCall, id);
  yield put(setCareGiver(data));
}

function getPendingAssessmentsSagaCall() {
  const resp = api.get(`/api/patient-assessment/get-pending-assessments/`);

  return resp;
}
function* getPendingAssessmentsSaga() {
  const { data } = yield call(getPendingAssessmentsSagaCall);
  yield put(setPendingAssessments(data));
}

function submitAssessmentSagaCall(answers) {
  const resp = api.post(`/api/patient-assessment/collect-answers/`, answers);

  return resp;
}

function* submitAssessmentSaga({ payload: { answers, callback } }) {
  const data = yield call(submitAssessmentSagaCall, answers);
  if (data.status === 200) {
    yield call(callback);
  }
}

function getClinicAdminByIdSagaCall(id) {
  const resp = api.get(`/api/clinic-admin/${id}`);

  return resp;
}

function* getClinicAdminByIdSaga({ payload: { id } }) {
  const { data } = yield call(getClinicAdminByIdSagaCall, id);
  if (data) yield put(setClinicAdmin(data));
}

function* careGiverSagas() {
  yield takeEvery(GET_THIS_CARE_GIVER, getCareGiverSaga);
  yield takeEvery(GET_PENDING_ASSESSMENTS, getPendingAssessmentsSaga);
  yield takeEvery(SUBMIT_ASSESSMENT, submitAssessmentSaga);
  yield takeEvery(GET_CLINIC_ADMIN_BY_ID, getClinicAdminByIdSaga);
}

export default careGiverSagas;
