import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  Box,
  Heading,
  Text,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
  Select,
  Menu,
} from "grommet";
import { FormDown, Search } from "grommet-icons";
import { push } from "connected-react-router";
import { ceil } from "lodash";
import Loading from "../../common/InPageLoader";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import {
  fetchInvitedPatients,
  getSingleInvite,
  resendInvite,
  setInvitedPatients,
} from "../../../store/patients/actions";
import InviteModal from "../../common/InviteModal";
import { createNotification } from "../../../store/app/actions";
import EditModal from "../../common/EditModal";
import BulkInviteModal from "../../common/BulkInviteModal";

const InvitedPatients = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState();
  const [sortValue, setSortValue] = useState();
  const [orderBy, setOrderBy] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [isNew, setIsNew] = useState("");

  const [showValue, setShowValue] = useState("10");
  const [page, setPage] = useState(1);
  const [showBulk, setShowBulk] = useState(false);

  const patients = useSelector((state) => state.patients.patients) || [];
  const totalEntries = useSelector((state) => state.patients.count);
  const next = useSelector((state) => state.patients.next);
  const previous = useSelector((state) => state.patients.previous);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const totalPages = ceil(totalEntries / showValue);
  const pages = [];

  for (let i = 0; i < totalPages; i += 1) {
    pages[i] = i + 1;
  }
  const onSuccess = () => {
    setEditShow(!editShow);
  };

  const setToEdit = (id) => {
    dispatch(getSingleInvite(id, onSuccess));
  };

  const [searchTerm, setSearchTerm] = useState("");
  const success = () => {
    setLoading(false);
  };

  const resendSuccess = () => {
    dispatch(createNotification("Reinvite Sent Sucessfully", true));
  };

  const fetchPatientsData = () => {
    dispatch(
      fetchInvitedPatients(
        success,
        showValue,
        searchTerm,
        orderBy,
        filterBy,
        page
      )
    );
  };

  useEffect(() => {
    setInvitedPatients(null);
    setLoading(true);
    fetchPatientsData();
  }, [showValue, searchTerm, orderBy, filterBy, isNew, page]);

  const getFlag = (flag) => {
    if (flag === "INVITED") {
      return "rFlag";
    }
    if (flag === "COMPLETED") {
      return "gFlag";
    }
    return null;
  };

  return (
    <>
      {show && (
        <InviteModal display={show} setDisplayOut={(value) => setShow(value)} />
      )}
      {editShow && (
        <EditModal
          display={editShow}
          setDisplayOut={(value) => setEditShow(value)}
        />
      )}
      {showBulk && (
        <BulkInviteModal
          display={showBulk}
          setDisplayOut={(value) => setShowBulk(value)}
        />
      )}

      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box
            direction="row"
            width={isMobileorTablet ? "100%" : null}
            justify={isMobileorTablet ? "between" : null}
            align="center"
          >
            <Heading level={3} color="#1B3664" alignSelf="center">
              Invited Patients
            </Heading>
            <Box direction="row" align="center" justify="center" margin="small">
              <Text color="#c4c4c4">Show:</Text>
              <Box width="80px">
                <Select
                  options={["5", "10", "20", "30"]}
                  plain
                  value={showValue}
                  style={{ color: "black" }}
                  size="medium"
                  onChange={({ option }) => {
                    setShowValue(option);
                    setPage(1);
                  }}
                  dropHeight="small"
                  dropProps={{ style: { borderRadius: "10px" } }}
                />
              </Box>
            </Box>
          </Box>
          <Menu
            className="menu"
            size="small"
            icon={<FormDown color="white" />}
            color="#395e9d"
            style={{ marginTop: isMobileorTablet ? "16px" : null }}
            label="+ Invite Patients"
            dropAlign={{ top: "bottom" }}
            dropProps={{ className: "dropMenu" }}
            justifyContent="center"
            items={[
              {
                label: "Add a Patient",
                onClick: () => {
                  setShow(!show);
                },
              },
              // {
              //   label: "Invite Multiple Patients",
              //   onClick: () => {
              //     setShowBulk(!showBulk);
              //   },
              // },
              {
                label: "View Invited Patients",
                onClick: () => {
                  dispatch(push(`/dashboard/invitedPatients`));
                },
              },
            ]}
          />
        </Box>
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align="center"
          justify="between"
          margin={{ top: "small" }}
        >
          <Box
            round="small"
            align="center"
            justify="between"
            direction="row"
            height="38px"
            width={isMobileorTablet ? "100%" : "320px"}
            className="whiteBack"
            margin={isMobileorTablet ? { bottom: "small" } : null}
          >
            <TextInput
              name="search"
              plain
              type="text"
              className="searchInput"
              placeholder="Search"
              value={searchTerm}
              onChangeCapture={(e) => {
                setSearchTerm(e.target.value);
                setPage(1);
              }}
              required
            />

            <Box margin={{ right: "small" }}>
              <Search color="#395E9D" />
            </Box>
          </Box>
          <Box
            height={isMobileorTablet ? null : "38px"}
            align="center"
            width={isMobileorTablet ? "100%" : null}
            direction={isMobileorTablet ? "column" : "row"}
          >
            <Box
              round="small"
              className="whiteBack"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={["All Patients", "Yet to Accept Invite", "Completed"]}
                plain
                size="small"
                value={filterValue}
                placeholder="Filter:"
                style={{ color: "black" }}
                clear
                onChange={({ option }) => {
                  setFilterValue(option);
                  setPage(1);
                  if (option === "All Patients") setFilterBy("");
                  else if (option === "Yet to Accept Invite")
                    setFilterBy("INVITED");
                  else if (option === "Completed") {
                    setFilterBy("COMPLETED");
                  } else {
                    setFilterBy("");
                    setIsNew("");
                  }
                }}
                dropHeight="small"
                dropProps={{ style: { borderRadius: "10px" } }}
              />
            </Box>
            <Box
              className="whiteBack"
              round="small"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={["First Name", "Last Name", "Date Invited", "Status"]}
                plain
                clear
                size="small"
                dropHeight="small"
                dropProps={{
                  style: { fontSize: "2px !important", borderRadius: "10px" },
                }}
                value={sortValue}
                onChange={({ option }) => {
                  setSortValue(option);
                  setPage(1);
                  if (option === "First Name") setOrderBy("first_name");
                  else if (option === "Last Name") setOrderBy("last_name");
                  else if (option === "Date Invited")
                    setOrderBy("invite_datetime");
                  else if (option === "Status") setOrderBy("status");
                  else setOrderBy("");
                }}
                placeholder="Sort By:"
                style={{ color: "black" }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          width="100%"
          justify="start"
          align="center"
          margin={{ top: "small" }}
        >
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="evenly"
            gap="medium"
            align="center"
          >
            <Box
              className="large-card"
              align="center"
              justify="center"
              style={{ overflowX: "auto" }}
              gap="small"
              pad="small"
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Table alignSelf="center">
                    <TableHeader>
                      <TableRow>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            FIRST NAME
                          </Text>
                        </TableCell>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            LAST NAME
                          </Text>
                        </TableCell>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            DATE INVITED
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="start">
                          <Text size="small" weight="bold">
                            EMAIL
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            STATUS
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            PHYSICIAN
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {patients &&
                        patients.map((patient) => (
                          <TableRow key={patient.id}>
                            <TableCell scope="row">
                              <Text size="small" weight="bold">
                                {patient.first_name}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text size="small" weight="bold">
                                {patient.last_name}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text size="xsmall" weight="bold">
                                {patient.invite_datetime
                                  ? moment(patient.invite_datetime).format(
                                      "MM/DD/YYYY h:mm:ss a"
                                    )
                                  : null}
                              </Text>
                            </TableCell>
                            <TableCell align="start">
                              <Box
                                style={{
                                  overflowY: "hidden",
                                  maxWidth: "140px",
                                  scrollbarWidth: "none" /* Firefox */,
                                }}
                              >
                                <Text size="xsmall" weight="bold">
                                  {patient.email}
                                </Text>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Text size="xsmall" weight="bold">
                                <Box
                                  className={
                                    patient ? getFlag(patient.status) : null
                                  }
                                  pad={{
                                    horizontal: "small",
                                    vertical: "xxsmall",
                                  }}
                                >
                                  <Text size="xsmall">{patient.status}</Text>
                                </Box>
                              </Text>
                            </TableCell>
                            <TableCell align="center">
                              <Text size="xsmall" weight="bold">
                                <Box
                                  pad={{
                                    horizontal: "small",
                                    vertical: "xxsmall",
                                  }}
                                >
                                  <Text size="xsmall">{patient.physician}</Text>
                                </Box>
                              </Text>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                className="btn-primary"
                                color="#2BC2F7"
                                onClick={() => {
                                  setToEdit(patient.id);
                                }}
                                margin={{ horizontal: "small" }}
                                size="small"
                                label="Edit"
                              />
                              <Button
                                className="btn-primary-dark"
                                color="transparent"
                                onClick={() =>
                                  dispatch(
                                    resendInvite(patient.id, resendSuccess)
                                  )
                                }
                                margin={{ horizontal: "small" }}
                                size="small"
                                label="Resend"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Box width="100%">
                    <Box height="1px" background="#395E9D" />
                    <Box
                      direction="row"
                      width="100%"
                      justify="between"
                      align="center"
                      pad={{ horizontal: "small" }}
                    >
                      <Box>
                        <Text size="small" color="#395E9D">
                          Showing{" "}
                          {totalEntries === 0
                            ? 0
                            : showValue * page - (showValue - 1)}{" "}
                          to {next === null ? totalEntries : showValue * page}{" "}
                          of {totalEntries} entries
                        </Text>
                      </Box>
                      <Box direction="row" align="center">
                        <Button
                          size="small"
                          color="#666666"
                          onClick={() =>
                            previous === null ? null : setPage(page - 1)
                          }
                          disabled={previous === null}
                        >
                          <Text size="small">Previous</Text>
                        </Button>
                        {pages.map((vals) => (
                          <Text
                            key={vals}
                            className={
                              vals === page ? "activePage" : "inactivePage"
                            }
                            textAlign="center"
                            size="small"
                            margin="xsmall"
                            color="#82D4E7"
                            onClick={() => setPage(vals)}
                          >
                            {vals}
                          </Text>
                        ))}
                        {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
                        <Button
                          size="small"
                          color="#666666"
                          onClick={() =>
                            next === null ? null : setPage(page + 1)
                          }
                          disabled={next === null}
                        >
                          <Text size="small">Next</Text>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InvitedPatients;
