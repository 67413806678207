/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Box, Text, Tabs, Tab } from "grommet";
import axios from "axios";
import { Iteration, Pin, Volume, Youtube } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import ProfileBoxCommon from "../../common/ProfileBoxCommon";
import Loading from "../../common/InPageLoaderNew";
import RenderSingleCat from "../../common/RenderSingleCat";
import RenderSingleCatVideos from "../../common/RenderSingleCatVideos";
import RenderSingleCatAudios from "../../common/RenderSingleCatAudio";
// import RenderPaths from "../../common/RenderPaths";
import { getPinnedContent } from "../../../store/patients/actions";
import { Mixpanel } from "../../../services/mixpanel/Mixpanel";
import RenderPinnedContent from "../../common/RenderPinnedContent";

const PinContent = () => {
  const pinnedContent =
    useSelector((state) => state.patients.pinned_content) || null;

  const user = useSelector((state) => state.app.user);

  const [allCategories, setAllCategories] = useState([]);
  const [allCategoriesColors, setAllCategoriesColors] = useState([]);
  const [videoCategories, setVideoCategories] = useState([]);
  const [videoCategoriesColors, setVideoCategoriesColors] = useState([]);
  const [audioCategories, setAudioCategories] = useState([]);
  const [audioCategoriesColors, setAudioCategoriesColors] = useState([]);

  const [posts, setPost] = useState();
  const [videos, setVideos] = useState();
  const [viewmedica, setViewmedicaItems] = useState();

  const [audios, setAudios] = useState();
  // const [paths, setPaths] = useState();

  const [loading, setLoading] = useState(false);
  const [cuurentIndex, setCurrentIndex] = useState(0);
  const [getIndexCall, sendGetIndexCall] = useState(false);
  const { search } = useSelector((state) => state.router.location);
  const patient = split(search, "?", 2)[1];
  const dispatch = useDispatch();

  function getIndex() {
    const index = JSON.parse(window.localStorage.getItem("currentTab")) || 0;
    setCurrentIndex(index);
  }

  if (!getIndexCall) {
    getIndex();
    sendGetIndexCall(true);
  }

  const onActive = (index) => {
    window.localStorage.setItem("currentTab", JSON.stringify(index));
    getIndex();
  };

  const baseURL = process.env.REACT_APP_CMS_URL;

  useEffect(() => {
    setLoading(true);
    Mixpanel.time_event("Time Spent on Patient Dashboard");

    async function fetchMyPosts() {
      const query = `${baseURL}posts`;
      const videoQuery = `${baseURL}videos`;
      const viewMedicaQuery = `${baseURL}view-medicas`;

      const audioQuery = `${baseURL}audios`;

      const categoryQuery = `${baseURL}classifications`;
      // const pathsQuery = `${baseURL}paths`;

      const response = await axios.get(query);
      const { data } = response;

      const videoresponse = await axios.get(videoQuery);
      const videodata = videoresponse.data;

      const viewmedicaresponse = await axios.get(viewMedicaQuery);
      const viewmedicadata = viewmedicaresponse.data;

      const audioresponse = await axios.get(audioQuery);
      const audiodata = audioresponse.data;

      // const pathsresponse = await axios.get(pathsQuery);
      // const pathsdata = pathsresponse.data;

      const allTempCategories = await axios.get(categoryQuery);
      const categoriesData = allTempCategories.data;
      const categories = [];
      const categoriesColors = [];
      const videocategories = [];
      const videocategoriesColors = [];
      const audiocategories = [];
      const audiocategoriesColors = [];

      categoriesData.forEach((element, index) => {
        if (element.posts.length !== 0 && element.isPinable)
          categories[index] = element.Name;
        const color = JSON.parse(element.background_color);
        if (color !== null) categoriesColors[index] = color.hex;
        else categoriesColors[index] = "#395E9D";
      });

      categoriesData.forEach((element, index) => {
        if (
          (element.videos.length !== 0 || element.view_medicas.length !== 0) &&
          element.isPinable
        ) {
          videocategories[index] = element.Name;
          const color = JSON.parse(element.background_color);
          if (color !== null) videocategoriesColors[index] = color.hex;
          else videocategoriesColors[index] = "#395E9D";
        }
      });

      categoriesData.forEach((element, index) => {
        if (element.audios.length !== 0 && element.isPinable)
          audiocategories[index] = element.Name;
        const color = JSON.parse(element.background_color);
        if (color !== null) audiocategoriesColors[index] = color.hex;
        else audiocategoriesColors[index] = "#395E9D";
      });

      setPost(data);
      setVideos(videodata);
      setViewmedicaItems(viewmedicadata);
      setAudios(audiodata);
      // setPaths(pathsdata);
      setAllCategories(categories);
      setAllCategoriesColors(categoriesColors);
      setVideoCategoriesColors(videocategoriesColors);
      setVideoCategories(videocategories);
      setAudioCategoriesColors(audiocategoriesColors);
      setAudioCategories(audiocategories);
      setLoading(false);
    }

    dispatch(getPinnedContent(patient, fetchMyPosts));
    return () => {
      Mixpanel.track("Time Spent on Patient Dashboard", {
        Patient: split(search, "?", 2)[1],
        User: `${user.user.first_name} ${user.user.last_name}`,
        Clinic: user.user.clinic.name,
        "User Type": user.user.role,
      });
    };
  }, [cuurentIndex]);

  const getComponentToRender = () => {
    if (cuurentIndex === 0)
      return posts && allCategories ? (
        <>
          {allCategories.map((element, index) => (
            <RenderSingleCat
              posts={posts}
              key={element}
              pinnedContent={pinnedContent}
              element={element}
              color={allCategoriesColors[index]}
            />
          ))}
        </>
      ) : (
        <Text>No Posts Found</Text>
      );

    if (cuurentIndex === 1)
      return videos && viewmedica && allCategories ? (
        <>
          {videoCategories.map((element, index) => (
            <RenderSingleCatVideos
              key={element}
              videos={videos}
              pinnedContent={pinnedContent}
              viewmedicas={viewmedica}
              element={element}
              color={videoCategoriesColors[index]}
            />
          ))}
        </>
      ) : (
        <Text>No Posts Found</Text>
      );
    if (cuurentIndex === 2)
      return audios && allCategories ? (
        <>
          {audioCategories.map((element, index) => (
            <RenderSingleCatAudios
              key={element}
              posts={audios}
              pinnedContent={pinnedContent}
              element={element}
              color={audioCategoriesColors[index]}
            />
          ))}
        </>
      ) : (
        <Text>No Posts Found</Text>
      );

    // if (cuurentIndex === 3)
    //   return paths ? (
    //     <>
    //       <RenderPaths paths={paths} />
    //     </>
    //   ) : (
    //     <Text>No Paths Found</Text>
    //   );

    if (cuurentIndex === 4)
      return posts && videos && viewmedica && audios && pinnedContent ? (
        <>
          <RenderPinnedContent
            posts={posts}
            videos={videos}
            viewmedica={viewmedica}
            audios={audios}
            pinnedContent={pinnedContent}
          />
        </>
      ) : (
        <Text>No Pinned Content Found</Text>
      );

    return null;
  };

  return (
    <>
      <Box width="100%">
        <ProfileBoxCommon />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box
              margin={{ top: "xsmall" }}
              width="98%"
              className="large-card-bread"
              justify="center"
              alignSelf="center"
            >
              <Box alignSelf="center" margin={{ top: "small" }} width="100%">
                <Tabs
                  activeIndex={cuurentIndex}
                  onActive={onActive}
                  style={{ width: "100%" }}
                >
                  <Tab
                    title="Articles"
                    icon={<Iteration color="#395E9D" />}
                    className="tab2"
                  />
                  <Tab
                    title="Videos"
                    icon={<Youtube color="#395E9D" />}
                    className="tab2"
                  />
                  <Tab
                    title="Audio"
                    icon={<Volume color="#395E9D" />}
                    className="tab2"
                  />

                  <Tab
                    title="Pinned Content"
                    icon={<Pin color="#395E9D" />}
                    className="tab2"
                  />
                </Tabs>
              </Box>
            </Box>
            <Box pad={{ horizontal: "medium" }}>
              {/* <Text color="#44444F" margin={{ top: "medium", left: "10px" }}>
                SELECT TOPICS
              </Text> */}
              {getComponentToRender()}
            </Box>
            <Box margin={{ vertical: "small" }} />
          </>
        )}
      </Box>
    </>
  );
};

export default PinContent;
