import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  // DateInput,
  CheckBox,
  TextArea,
} from "grommet";

import Selected, { components } from "react-select";

import { Close } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "./InPageLoader";
import {
  enrollBHIPatient,
  getStaff,
  saveBHINote,
} from "../../store/patients/actions";
import { groupedOptions } from "./BHIOptionsGroups";

const EnrollModal = ({ display, setDisplayOut }) => {
  const patient = useSelector((state) => state.patients.patient) || null;
  const dispatch = useDispatch();
  const [showValue1, setShowValue1] = useState("");
  const [loading, setLoading] = useState();
  const [notes, setNotes] = useState("");

  const [agreed, setAgreed] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const staff = useSelector((state) => state.patients.staff);
  const user = useSelector((state) => state.app.user.user);
  const [showValue2, setShowValue2] = useState();

  const options = [];

  const onSuccess2 = (singlePatient) => {
    dispatch(
      saveBHINote(showValue1.value, notes, singlePatient.data.id, user.id)
    );
  };

  const enrollPatient = () => {
    let filtered = [];
    if (showValue2)
      filtered = showValue2.filter((value) => value !== patient.clinician);

    dispatch(
      enrollBHIPatient(
        showValue1.value,
        filtered,
        "Agreed",
        patient.id,
        user,
        onSuccess2
      )
    );
    setDisplayOut(false);
  };

  const handleHeaderClick = (id) => {
    const node = document.querySelector(`#${id}`).parentElement
      .nextElementSibling;
    const classes = node.classList;
    if (classes.contains("collapsed")) {
      node.classList.remove("collapsed");
    } else {
      node.classList.add("collapsed");
    }
  };

  const onSuccess = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getStaff(onSuccess));
  }, []);

  const CustomGroupHeading = (props) => (
    <div
      className="group-heading-wrapper"
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} />
    </div>
  );

  if (staff) {
    staff.forEach((data) => {
      if (data.id === user.id && user.role !== "CLINICAL_STAFF") {
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }

      if (data.role === "CLINICAL_STAFF")
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
    });
  }

  const handleDropDownSelect = (nextValue, selectedItem) => {
    const newArray = [];
    nextValue.forEach((element, index) => (newArray[index] = element.key));
    setShowValue2(newArray);
  };

  const handleDropDownRemove = (nextValue, removedItem) => {
    const newArray = showValue2.filter((value) => value !== removedItem.key);
    setShowValue2(newArray);
  };

  const getSelectedValues = () => {
    const newArray = [];
    staff.forEach((data) => {
      if (data.id === patient.clinician) {
        newArray.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }
    });
    return newArray;
  };

  if (loading) {
    return (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-update"
        position="right"
      >
        <Box width="100%" height="100%">
          <Loader />
        </Box>
      </Layer>
    );
  }

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-update"
          position="right"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="brand" weight="bold">
                Enroll Patient
              </Text>
              <Box>
                <Close onClick={() => setDisplayOut(false)} />
              </Box>
            </Box>
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Name
              </Text>

              <TextInput
                plain
                type="text"
                size="small"
                disabled
                className="semiInput"
                value={`${patient.first_name} ${patient.last_name}`}
              />
            </Box>
            {/* <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Date of Birth
              </Text>
             
              <DateInput
                size="small"
                plain
                format="mm/dd/yyyy"
                value={patient.date_of_birth?  patient.date_of_birth : null}
                className="semiInput"
                disabled
              />
            </Box> */}
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Behavioral Health Diagnosis:
              </Text>
              <Selected
                value={showValue1}
                menuColor="white"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    fontSize: "14px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: "solid 1px #C4C4C4",
                    marginTop: "6px",
                    backgroundColor: "#FBFBFB",
                    borderRadius: "6px",
                    fontSize: "14px",
                    padding: "1%",
                  }),
                }}
                onChange={setShowValue1}
                options={groupedOptions}
                components={{ GroupHeading: CustomGroupHeading }}
              />
            </Box>
            <Box width="100%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Behavioral Health Manager
              </Text>
              <Box
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <Multiselect
                  options={options}
                  displayValue="value"
                  showCheckbox
                  closeOnSelect={false}
                  selectedValues={getSelectedValues()}
                  placeholder="Select..."
                  hidePlaceholder
                  avoidHighlightFirstOption
                  showArrow
                  onSelect={handleDropDownSelect} // Function will trigger on select event
                  onRemove={handleDropDownRemove} // Function will trigger on remove event
                  disablePreSelectedValues
                />
              </Box>
            </Box>

            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                BHI Notes:
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>

            <Box direction="row" gap="small" align="start" justify="start">
              <CheckBox checked={agreed} onChange={() => setAgreed(!agreed)} />
              <Text size="10px">
                I confirm I have discussed {patient.first_name}
                ’s Behavioral Health Care Program with the patient, including
                the roles of the behavioral health care manager and psychiatric
                consultant
              </Text>
            </Box>
            <Box direction="row" gap="small" align="start" justify="start">
              <CheckBox
                checked={agreed2}
                onChange={() => setAgreed2(!agreed2)}
              />
              <Text size="10px">
                I have informed the patient that they will be responsible for
                potential cost sharing expenses for both in person and
                non-face-to-face services.
              </Text>
            </Box>
            <Box direction="row" gap="small" align="start" justify="start">
              <CheckBox
                checked={agreed3}
                onChange={() => setAgreed3(!agreed3)}
              />
              <Text size="10px">
                The patient has agreed to participate in the Behavioral Health
                Care Program and for consultations to be conducted with relevant
                specialists.
              </Text>
            </Box>
            <Box align="center">
              <Button
                className="btn-fullwidth"
                color="#395e9d"
                size="small"
                disabled={
                  !agreed ||
                  !agreed2 ||
                  !agreed3 ||
                  showValue1 === "" ||
                  showValue2 === "" ||
                  notes === ""
                }
                label="Submit"
                onClick={() => enrollPatient()}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default EnrollModal;
