import React, { useState } from "react";
import { Text, Layer, Box, TextInput, Button, TextArea } from "grommet";
import { Multiselect } from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "grommet-icons";
import NotesHistoryModal from "./NotesHistoryModal";
import { saveBHINote, updatePatientBHITWS } from "../../store/patients/actions";

const TWSUpdateModal = ({ display, setDisplayOut }) => {
  const user = useSelector((state) => state.app.user.user);
  const patient = useSelector((state) => state.patients.patient) || null;
  const staff = useSelector((state) => state.patients.staff);
  const [change, setChange] = useState(false);
  const [showValue2, setShowValue2] = useState();
  const [historyShow, setHistoryShow] = useState(false);
  const [notes, setNotes] = useState("");
  const patientBHI = useSelector((state) => state.patients.patient_bhi);
  const dispatch = useDispatch();

  const options = [];
  if (staff && patient) {
    staff.forEach((data) => {
      if (data.role === "TWS_CONCIERGE")
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
    });
  }

  const getSelectedValues = () => {
    const newArray = [];
    staff.forEach((data) => {
      if (data.id === patient.clinician) {
        newArray.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }
    });

    if (user.role === "TWS_CONCIERGE") {
      staff.forEach((data) => {
        if (
          patient.shared_with_tws_concierge.find(
            (element) => element === data.id
          )
        ) {
          newArray.push({
            value: `${data.first_name} ${data.last_name}`,
            key: data.id,
          });
        }
      });
    }
    return newArray;
  };

  const handleDropDownSelect = (nextValue) => {
    const newArray = [];
    nextValue.forEach((element, index) => {
      newArray[index] = element.key;
    });
    setShowValue2(newArray);
  };

  const handleDropDownRemove = (nextValue, removedItem) => {
    const newArray = showValue2.filter((value) => value !== removedItem.key);
    setShowValue2(newArray);
  };

  const handleChange = () => {
    setChange(true);
  };

  const getManagedByTWS = (managedArray) => {
    let finalString = "";

    managedArray.forEach((element, index) => {
      const sharedStaff = staff.find((item) => item.id === element);
      if (sharedStaff) {
        if (index !== managedArray.length - 1)
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}, `;
        else
          finalString = `${finalString}${sharedStaff.first_name} ${sharedStaff.last_name}`;
      }
    });

    return finalString === "" ? "No Manager Assigned" : finalString;
  };

  const preformLater = (toDo) => {
    setDisplayOut(toDo);
    setNotes("");
    setChange(false);
  };

  const updatePatient = () => {
    const filtered = showValue2;
    dispatch(
      updatePatientBHITWS(
        "TWS Patient",
        filtered,
        patientBHI.id,
        patientBHI.patient,
        preformLater
      )
    );
    dispatch(saveBHINote(null, notes, patient.id, user.id));
  };

  return (
    <>
      {historyShow && (
        <NotesHistoryModal
          display={historyShow}
          setDisplayOut={(value) => setHistoryShow(value)}
        />
      )}

      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-update"
          position="right"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="brand" weight="bold">
                Update Patient Details
              </Text>
              <Box>
                <Close onClick={() => setDisplayOut(false)} />
              </Box>
            </Box>
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Name
              </Text>
              <Box round="xsmall" margin={{ top: "6px" }}>
                <TextInput
                  size="small"
                  name="username"
                  plain
                  disabled
                  value={`${patient.first_name} ${patient.last_name}`}
                  type="text"
                  className="semiInput"
                  required
                />
              </Box>
            </Box>
            <Box direction="row" align="center" justify="between">
              <Box direction="row">
                <Text
                  size="xsmall"
                  weight="bold"
                  color="#4F4F4F"
                  margin={{ right: "xsmall" }}
                >
                  Care Manager:
                </Text>
                <Text size="xsmall" weight="bold" color="#A9A9A9">
                  {patient
                    ? getManagedByTWS(patient.shared_with_tws_concierge)
                    : ""}
                </Text>
              </Box>
              {user &&
              (user.role === "TWS_COORDINATOR" ||
                user.role === "TWS_CONCIERGE") ? (
                <Box onClick={() => handleChange()} className="noOutline">
                  <Text size="small">{!change ? "Change?" : ""}</Text>
                </Box>
              ) : null}
            </Box>
            {change ? (
              <Box width="100%">
                <Box>
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Update Care Manager
                  </Text>
                </Box>

                <Box
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                >
                  <Multiselect
                    options={options}
                    displayValue="value"
                    disable={
                      user.role === "CLINICAL_STAFF" ||
                      user.role === "TWS_CASE_MANAGER"
                    }
                    showCheckbox
                    placeholder={
                      user.role === "CLINICAL_STAFF" ||
                      user.role === "TWS_CASE_MANAGER"
                        ? "Disabled"
                        : "Select..."
                    }
                    closeOnSelect={false}
                    hidePlaceholder
                    selectedValues={getSelectedValues()}
                    avoidHighlightFirstOption
                    showArrow
                    onSelect={handleDropDownSelect} // Function will trigger on select event
                    onRemove={handleDropDownRemove} // Function will trigger on remove event
                    disablePreSelectedValues
                    closeIcon="cancel"
                  />
                </Box>
              </Box>
            ) : null}
            <Box>
              <Box direction="row" justify="between">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  Clinical Notes:
                </Text>
                <Box
                  border="bottom"
                  className="noOutline"
                  onClick={() => setHistoryShow(true)}
                >
                  <Text size="xsmall">See Notes History</Text>
                </Box>
              </Box>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>
            <Box align="center">
              <Button
                className="btn-fullwidth"
                color="#395e9d"
                disabled={notes === ""}
                onClick={() => updatePatient()}
                size="small"
                label="Submit"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default TWSUpdateModal;
