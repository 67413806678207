import React from "react";
import { Box, Image, Text } from "grommet";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import Background from "../../../assets/login-back.svg";

function PatientResetMessage() {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const user = useSelector((state) => state.app.user);

  if (user) {
    if (user.user) {
      return <Redirect to="/dashboard/home" />;
    }
  }

  return (
    <Box
      justify="center"
      align="center"
      background="brand"
      width="100%"
      height="100%"
    >
      <Box
        direction="row"
        align="center"
        justify="center"
        className={isMobileorTablet ? "wrapper-mobile" : "wrapper"}
      >
        <Box
          align="center"
          width="100%"
          justify="center"
          style={{ position: "relative" }}
        >
          <Image src={Background} style={{ maxHeight: "720px" }} />

          <Text
            style={{ position: "absolute" }}
            margin="medium"
            textAlign="center"
          >
            Go back to the app and sign in with you new password
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default PatientResetMessage;
