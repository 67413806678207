/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Button, Heading, Text } from "grommet";
import { FormNext } from "grommet-icons";
import { useSelector } from "react-redux";
import ChangePassword from "../../common/ChangePassword";
import EditProfile from "../../common/EditProfile";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import ChangeDefaultAssessments from "../../common/ChangeDefaultAssessments";

const Settings = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const user = useSelector((state) => state.app.user.user);

  const [cuurentIndex, setCurrentIndex] = useState(
    user.role === "CLINIC_ADMIN" ? 0 : 1
  );

  const isMobileorTablet = isMobile || isTablet;

  const getComponentToRender = () => {
    if (cuurentIndex === 0) return <EditProfile />;

    if (cuurentIndex === 1) return <ChangePassword />;

    return <ChangeDefaultAssessments />;
  };

  const isActive = (toCompare) => {
    if (toCompare === cuurentIndex) return true;

    return false;
  };

  return (
    <Box width="100%" pad="medium" gap="small">
      <Box>
        <Heading level={3} color="#1B3664">
          Settings
        </Heading>
      </Box>
      <Box
        margin={{ top: "small" }}
        direction={isMobileorTablet ? "column" : "row"}
        gap={isMobileorTablet ? "small" : null}
        width="100%"
      >
        <Box
          className="large-card-settings"
          pad="small"
          gap="small"
          align="start"
          width={isMobileorTablet ? "100%" : "25%"}
        >
          {user.role === "CLINIC_ADMIN" ? (
            <Button
              fill="horizontal"
              align="between"
              onClick={() => setCurrentIndex(0)}
              label={<Text size="small">Profile</Text>}
              className={isActive(0) ? "active-tab" : "tab-button"}
              reverse
              icon={<FormNext color={isActive(0) ? "#395E9D" : null} />}
              plain
            />
          ) : null}
          <Button
            fill="horizontal"
            align="between"
            onClick={() => setCurrentIndex(1)}
            label={<Text size="small">Change Password</Text>}
            className={isActive(1) ? "active-tab" : "tab-button"}
            reverse
            icon={<FormNext color={isActive(1) ? "#395E9D" : null} />}
            plain
          />
          {user.role === "CLINIC_ADMIN" ? (
            <Button
              fill="horizontal"
              align="between"
              onClick={() => setCurrentIndex(2)}
              label={<Text size="small">Default Assessments</Text>}
              className={isActive(2) ? "active-tab" : "tab-button"}
              reverse
              icon={<FormNext color={isActive(2) ? "#395E9D" : null} />}
              plain
            />
          ) : null}
        </Box>
        <Box
          margin={isMobileorTablet ? null : { left: "small" }}
          width={isMobileorTablet ? "100%" : "75%"}
        >
          {getComponentToRender()}
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
