import React, { useState } from "react";
import {
  Text,
  Layer,
  Tabs,
  Tab,
  Box,
  Form,
  TextInput,
  Select,
  Button,
} from "grommet";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  createClinicGroup,
  getGroups,
  getSetupIntentforGroup,
} from "../../store/sales/actions";
import InPageLoader from "./InPageLoader";
import CardSetupForm from "../pages/dashboard/SalesPanel/CardSetupForm";
import ClinicGroupSuccess from "./ClinicGroupSuccess";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CreateClinicGroup = ({ display, setDisplayOut }) => {
  const [value, setValue] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [groupID, setGroupID] = useState();

  const [index, setIndex] = useState(0);
  const [loadingIntent, setLoadingIntent] = useState(false);
  const [setupIntentSecret, setSetupIntentSecret] = useState();

  const dispatch = useDispatch();

  const onActive = (nextIndex) => setIndex(nextIndex);

  const onSuccess = () => true;

  const onIntentSuccess = (data) => {
    setSetupIntentSecret(data.client_secret);
    setLoadingIntent(false);
    dispatch(getGroups(onSuccess, ""));
  };

  const groupCallback = (data) => {
    onActive(1);
    setLoadingIntent(true);
    setGroupID(data.id);
    setValue();
    dispatch(getSetupIntentforGroup(data.id, onIntentSuccess));
  };

  const groupSubmit = (form) => {
    dispatch(createClinicGroup(form, groupCallback));
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-sales"
          position="center"
        >
          <Box align="start" gap="small">
            <Text color="#395E9D">Create Healthcare Group</Text>
            <Tabs
              activeIndex={index}
              onActive={onActive}
              justify="start"
              gap="small"
              style={{ width: "100%" }}
            >
              <Tab
                className="tabSale"
                title={
                  <Text color="#395E9D" size="small">
                    BASIC DETAILS
                  </Text>
                }
                disabled={index !== 0}
              >
                <Form
                  value={value}
                  onSubmit={groupSubmit}
                  onChange={(nextValue) => setValue(nextValue)}
                >
                  <Box width="100%" gap="medium">
                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Name of Healthcare Group
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          margin={{ top: "6px" }}
                          className="customInput"
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            dropHeight="small"
                            size="small"
                            name="name"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Type
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          margin={{ top: "6px" }}
                          className="customInput"
                        >
                          <Select
                            plain
                            labelKey="value"
                            valueKey={{ key: "key", reduce: true }}
                            options={[
                              { key: "PROVIDER", value: "PROVIDER" },
                              { key: "OTHER", value: "OTHER" },
                              {
                                key: "ACCOUNTABLE_CARE_ORGANIZATION_(ACO)",
                                value: "ACCOUNTABLE CARE ORGANIZATION (ACO)",
                              },
                              {
                                key: "EMPLOYER_GROUP",
                                value: "EMPLOYER GROUP",
                              },
                            ]}
                            dropProps={{
                              style: { borderRadius: "10px" },
                            }}
                            dropHeight="small"
                            size="small"
                            name="type"
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Street Address 1
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="street_address_1"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Street Address 2
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="street_address_2"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          City
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="city"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          State
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="state"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Country
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="country"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Zip Code
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            required
                            name="zip_code"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Primary Contact
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="contact_name"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Primary Contact Email Address
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            required
                            type="email"
                            name="email"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" gap="medium">
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Primary Contact Number
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="contact_number"
                          />
                        </Box>
                      </Box>
                      <Box width="50%">
                        <Text size="xsmall" weight="bold" color="#4F4F4F">
                          Rose Sales Team Member
                        </Text>
                        <Box
                          border={{ side: "all", color: "#C4C4C4" }}
                          round="xsmall"
                          background="#FBFBFB"
                          className="customInput"
                          margin={{ top: "6px" }}
                        >
                          <TextInput
                            plain
                            className="loginInput"
                            size="small"
                            name="rose_sales_team_member"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box width="50%">
                      <Text size="xsmall" weight="bold" color="#4F4F4F">
                        Payment Type
                      </Text>
                      <Box
                        border={{ side: "all", color: "#C4C4C4" }}
                        round="xsmall"
                        background="#FBFBFB"
                        margin={{ top: "6px" }}
                        className="customInput"
                      >
                        <Select
                          plain
                          labelKey="value"
                          valueKey={{ key: "key", reduce: true }}
                          options={[
                            { key: "Card", value: "Card" },
                            { key: "Invoice", value: "Invoice" },
                            {
                              key: "ACH",
                              value: "ACH",
                            },
                          ]}
                          dropProps={{
                            style: { borderRadius: "10px" },
                          }}
                          dropHeight="small"
                          size="small"
                          name="payment_type"
                        />
                      </Box>
                    </Box>
                    <Box
                      justify="end"
                      margin={{ top: "medium" }}
                      gap="medium"
                      direction="row"
                    >
                      <Button
                        className="btn-inverse"
                        color="#c4c4c410"
                        size="small"
                        label="Cancel"
                        onClick={() => setDisplayOut(false)}
                      />
                      <Button
                        className="btn"
                        color="#395e9d"
                        size="small"
                        label="Next"
                        type="submit"
                      />
                    </Box>
                  </Box>
                </Form>
              </Tab>
              <Tab
                disabled={index === 0}
                className="tabSale"
                title={
                  <Text color="#395E9D" size="small">
                    CARD PAYMENT
                  </Text>
                }
                style={{ padding: "none" }}
              >
                {loadingIntent || !setupIntentSecret ? (
                  <InPageLoader />
                ) : (
                  <Box pad="medium">
                    <Elements stripe={stripePromise}>
                      <CardSetupForm
                        intent={setupIntentSecret}
                        setMainOut={setDisplayOut}
                        setSuccessShow={(val) => setShowSuccess(val)}
                        changeIndex={(val) => setIndex(val)}
                      />
                    </Elements>
                  </Box>
                )}
              </Tab>
            </Tabs>
          </Box>
        </Layer>
      )}
      {
        <ClinicGroupSuccess
          display={showSuccess}
          setDisplayOut={(val) => setShowSuccess(val)}
          groupID={groupID}
        />
      }
    </>
  );
};
export default CreateClinicGroup;
