import React from "react";
import { Box, Image } from "grommet";
import Background from "../../../assets/login-photo.jpg";
import Icon from "../../../assets/logo.svg";

const backgroundImage = `url(${Background})`;
const LeftMessgeCard = () => (
  <>
    <Box
      align="center"
      justify="center"
      // style={{position: "absolute"}}
      width="100%"
      height="100%"
      background={backgroundImage}
    >
      {/* <Box direction="row">
        <Image className="eclipse-image" width="290px" height="279px" src={graphic2} />
        <Image className="rounded-image" width="382px" height="345px" src={graphic1} />
      </Box> */}

      {/* <Box>
        <Text margin={{top:"small"}} weight="bold" textAlign="center" size="xlarge">
          <Text color="#2BC2F7" size="xlarge">Smarter </Text>
          Mental Health
        </Text>
        <Text textAlign="center" size="small">Integrate feedback informed care into your primary </Text>
        <Text textAlign="center" size="small">care practice or mental health clinic.</Text>
      </Box> */}
      <Image src={Icon} opacity="20%" width="45%" draggable={false} />
    </Box>
  </>
);

export default LeftMessgeCard;
