import { Box, Button, Form, Text, TextInput } from "grommet";
import { FormView, Hide } from "grommet-icons";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  confirmPasswordSettings,
  createNotification,
} from "../../store/app/actions";

const LoadingSoftButton = ({ label, submitting, invalidToken, weakPass }) => (
  <Box height="xxsmall">
    <Button
      className="menu"
      color="transparent"
      alignSelf="end"
      type="submit"
      disabled={submitting || invalidToken || weakPass}
      label={
        submitting ? (
          <Loader type="TailSpin" height={20} color="grey" />
        ) : (
          <Text size="small" color="#FFFFFF">
            {label}
          </Text>
        )
      }
    />
  </Box>
);
const ChangePassword = () => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [showPassCurrent, setShowPassCurrent] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState();
  const [value, setValue] = useState("");

  const loginError = (e) => {
    if (e) dispatch(createNotification("Incorrect old password", false));
    setSubmitting(false);
  };

  const onSuccess = () => {
    dispatch(createNotification("Password Changed", true));
    setValue({
      ...value,
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    });

    setSubmitting(false);
  };

  const resetPassSubmit = (form) => {
    setSubmitting(true);
    const {
      value: { newpassword, confirmpassword, currentpassword },
    } = form;
    if (newpassword === confirmpassword) {
      setSubmitting(true);
      if (newpassword === currentpassword) {
        dispatch(
          createNotification(
            "New password must be different from current",
            false
          )
        );
        setSubmitting(false);
      } else dispatch(confirmPasswordSettings(form, loginError, onSuccess));
    } else {
      dispatch(createNotification("Passwords do not match"));
      setSubmitting(false);
    }
  };

  return (
    <Box className="large-card" pad="medium">
      <Text
        color="#395E9D"
        weight="bold"
        size="large"
        margin={{ bottom: "medium" }}
      >
        Change Password
      </Text>

      <Form
        onSubmit={resetPassSubmit}
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
      >
        <Box gap="small">
          <Box width="49%">
            <Text size="xsmall" weight="bold" color="#4F4F4F">
              Current Password
            </Text>

            <Box
              border={{ side: "all", color: "#C4C4C4" }}
              round="small"
              margin={{ top: "6px" }}
              align="center"
              direction="row"
            >
              <TextInput
                name="currentpassword"
                plain
                type={showPassCurrent ? "text" : "password"}
                className="passwordInput"
                placeholder="Your Password"
                required
              />

              <Box margin={{ right: "small" }}>
                {showPassCurrent ? (
                  <FormView
                    color="#92929D"
                    onClick={() => setShowPassCurrent(!showPassCurrent)}
                  />
                ) : (
                  <Hide
                    color="#92929D"
                    onClick={() => setShowPassCurrent(!showPassCurrent)}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box direction="row" gap="small">
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                New Password
              </Text>

              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="small"
                margin={{ top: "6px" }}
                align="center"
                direction="row"
              >
                <TextInput
                  name="newpassword"
                  plain
                  type={showPass ? "text" : "password"}
                  className="passwordInput"
                  placeholder="Your Password"
                  required
                />
                <Box margin={{ right: "small" }}>
                  {showPass ? (
                    <FormView
                      color="#92929D"
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <Hide
                      color="#92929D"
                      onClick={() => setShowPass(!showPass)}
                    />
                  )}
                </Box>
              </Box>
              <PasswordStrengthBar
                scoreWords={["too weak", "weak", "okay", "good", "strong"]}
                shortScoreWord={
                  value.hasOwnProperty("newpassword") &&
                  value.newpassword.length > 0
                    ? "too short"
                    : ""
                }
                minLength={6}
                password={
                  value.hasOwnProperty("newpassword") ? value.newpassword : " "
                }
                onChangeScore={(score) => setPasswordStrength(score)}
              />
            </Box>
            <Box width="50%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Confirm Password
              </Text>

              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="small"
                margin={{ top: "6px" }}
                align="center"
                direction="row"
              >
                <TextInput
                  name="confirmpassword"
                  plain
                  type={showPass ? "text" : "password"}
                  className="passwordInput"
                  placeholder="Your Password"
                  required
                />
                <Box margin={{ right: "small" }}>
                  {showPass ? (
                    <FormView
                      color="#92929D"
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <Hide
                      color="#92929D"
                      onClick={() => setShowPass(!showPass)}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box margin={{ top: "xsmall", bottom: "xsmall" }} />

          <LoadingSoftButton
            submitting={submitting}
            label="Change Password"
            weakPass={passwordStrength === 0}
          />
        </Box>
      </Form>
    </Box>
  );
};

export default ChangePassword;
