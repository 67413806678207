import React, { useState } from "react";
import { Text, Layer, Box, Button, TextInput } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import {
  finalizeInvoice,
  getGroupInvoices,
  markInvoicePaidManual,
} from "../../store/sales/actions";
import { FormCheckmark, FormClose } from "grommet-icons";
import moment from "moment";

const LabelText = ({ label, text }) => (
  <Box
    gap="small"
    direction="row"
    pad={{ horizontal: "small" }}
    className="minHeightFitContent"
  >
    <Text color="#4F4F4F" size="small">
      {label}
    </Text>
    <Text color="#44444F" size="small" weight="bold">
      {text}
    </Text>
  </Box>
);

const PDFModal = ({ display, setDisplayOut, invoice, setLoading }) => {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const [memo, setMemo] = useState(
    "Payment has been received outside of stripe"
  );
  const groupID = split(search, "?", 2)[1];

  const success = () => {
    setLoading(false);
  };
  const onSuccess = () => {
    setDisplayOut(false);
    setLoading(true);
    dispatch(getGroupInvoices(groupID, success));
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-medium"
          position="center"
        >
          <Box style={{ overflowY: "auto", maxHeight: "80%" }}>
            <LabelText label="Account" text={invoice.account_name} />

            <LabelText label="ID" text={invoice.id} />
            <LabelText label="Invoice Number" text={invoice.number} />
            <LabelText
              label="Collection Method"
              text={invoice.collection_method}
            />

            <LabelText
              label="Payment Attempted"
              text={invoice.attempted ? "true" : "false"}
            />
            <LabelText label="Attempt Count" text={invoice.attempt_count} />
            <LabelText
              label="Next Attempt"
              text={moment(
                new Date(invoice.next_payment_attempt * 1000)
              ).format("MM/DD/YYYY")}
            />

            <LabelText label="Customer" text={invoice.customer_name} />
            <LabelText label="Customer Email" text={invoice.customer_email} />

            <LabelText label="Amount Due" text={invoice.amount_due / 100} />
            <LabelText label="Amount Paid" text={invoice.amount_paid / 100} />
            <LabelText
              label="Amount Remaining"
              text={invoice.amount_remaining / 100}
            />
            <LabelText label="Currency" text={invoice.currency} />
            <LabelText
              label="Created"
              text={moment(new Date(invoice.created * 1000)).format(
                "MM/DD/YYYY"
              )}
            />
            <LabelText
              label="Due Date"
              text={moment(new Date(invoice.due_date * 1000)).format(
                "MM/DD/YYYY"
              )}
            />
            <LabelText label="Invoice PDF" text={invoice.invoice_pdf} />

            <LabelText
              label="Invoice Payment Link"
              text={invoice.hosted_invoice_url}
            />
            <Box pad="small">
              <Box
                background="#E0E0E0"
                pad="small"
                round="4px"
                gap="small"
                style={{ flexShrink: 0 }}
              >
                <LabelText label="Invoice Items: " text="" />

                {invoice.lines.data.map((item) => (
                  <Box direction="row" gap="small">
                    <LabelText label={item.description} />
                    <LabelText label={item.amount / 100} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {invoice.status === "open" ? (
            <Box
              style={{ border: "2px solid #395e9d" }}
              round="4px"
              margin="small"
              pad="small"
              align="center"
              direction="row"
              gap="small"
            >
              <Text color="#395e9d">Memo:</Text>
              <TextInput
                name="memo"
                label="Memo:"
                className="noOutline"
                type="text"
                placeholder="Start typing..."
                value={memo}
                onChangeCapture={(e) => {
                  setMemo(e.target.value);
                }}
                required
              />
            </Box>
          ) : null}
          <Box pad="small" direction="row" justify="end" gap="small">
            <Button
              fill={false}
              alignSelf="end"
              style={{
                width: "30%",
                padding: "5px 5px",
                backgroundColor: "#8dc779",
                color: "white",
                borderRadius: "8px",
              }}
              plain
              icon={<FormClose color="white" />}
              label="Cancel"
              onClick={() => setDisplayOut(false)}
              size="small"
            />
            <Button
              fill={false}
              alignSelf="end"
              style={{
                width: "30%",
                padding: "5px 5px",
                backgroundColor: "#395E9D",
                color: "white",
                borderRadius: "8px",
              }}
              plain
              icon={<FormCheckmark color="white" />}
              label={invoice.status === "open" ? "Mark As Paid" : "Finalize"}
              onClick={() =>
                invoice.status === "open"
                  ? dispatch(markInvoicePaidManual(invoice.id, onSuccess, memo))
                  : dispatch(finalizeInvoice(invoice.id, onSuccess))
              }
              size="small"
            />
          </Box>
        </Layer>
      )}
    </>
  );
};
export default PDFModal;
