import React from "react";
import { Layer } from "grommet";
import Messages from "./Messages";

const ChatModal = ({ display, setDisplayOut }) => (
  <>
    {display && (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-chat"
        position="right"
      >
        <Messages display={{ display }} setDisplayOut={setDisplayOut} />
      </Layer>
    )}
  </>
);
export default ChatModal;
