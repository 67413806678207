/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Button, Heading, Text } from "grommet";
import { FormNext } from "grommet-icons";
import FAQs from "../../common/FAQs";
import TutorialVideos from "../../common/TutorialVideos";
import HelpfulMaterial from "../../common/HelpfulMaterial";
import QuickHelp from "../../common/QuickHelp";
import { isMobileHook, iTabletHook } from "../../common/Responsive";

const Resources = () => {
  const [cuurentIndex, setCurrentIndex] = useState(0);
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;

  const getComponentToRender = () => {
    if (cuurentIndex === 0) return <FAQs />;

    if (cuurentIndex === 1) return <TutorialVideos />;

    if (cuurentIndex === 2) return <HelpfulMaterial />;

    return <QuickHelp />;
  };

  const isActive = (toCompare) => {
    if (toCompare === cuurentIndex) return true;

    return false;
  };

  return (
    <Box width="100%" pad="medium" gap="small">
      <Box>
        <Heading level={3} color="#1B3664">
          Resources
        </Heading>
        <Text size="small">
          For additional assistance, email us at&nbsp;
          <a className="customLink" href="mailto:help@rosehealth.com">
            help@rosehealth.com
          </a>
          &nbsp; or call at{" "}
          <a className="customLink" href="tel:8334007673">
            (833) 400 7673
          </a>
        </Text>
      </Box>
      <Box
        margin={{ top: "small" }}
        direction={isMobileorTablet ? "column" : "row"}
        gap={isMobileorTablet ? "small" : null}
        width="100%"
      >
        <Box
          className="large-card-resources"
          pad="small"
          gap="small"
          align="start"
          width={isMobileorTablet ? "100%" : "25%"}
        >
          <Button
            fill="horizontal"
            onClick={() => setCurrentIndex(0)}
            align="between"
            label={<Text size="small">FAQs</Text>}
            className={isActive(0) ? "active-tab" : "tab-button"}
            reverse
            icon={<FormNext color={isActive(0) ? "#395E9D" : null} />}
            plain
          />
          {/* <Button fill="horizontal" onClick={()=>setCurrentIndex(1)} align="between" label={<Text size="small">Tutorial Videos</Text>} className={isActive(1)?"active-tab":"tab-button"} reverse icon={<FormNext color={isActive(1)? "#395E9D":null} />} plain /> */}
          <Button
            fill="horizontal"
            onClick={() => setCurrentIndex(2)}
            align="between"
            label={<Text size="small">Helpful Materials</Text>}
            className={isActive(2) ? "active-tab" : "tab-button"}
            reverse
            icon={<FormNext color={isActive(2) ? "#395E9D" : null} />}
            plain
          />
          <Button
            fill="horizontal"
            onClick={() => setCurrentIndex(3)}
            align="between"
            label={<Text size="small">Quick Help</Text>}
            className={isActive(3) ? "active-tab" : "tab-button"}
            reverse
            icon={<FormNext color={isActive(3) ? "#395E9D" : null} />}
            plain
          />
        </Box>
        <Box
          margin={isMobileorTablet ? null : { left: "small" }}
          width={isMobileorTablet ? "100%" : "75%"}
        >
          {getComponentToRender()}
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
