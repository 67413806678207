/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import React, { useLayoutEffect, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { hot } from "react-hot-loader/root";
import { Box } from "grommet";
import { useSelector, useDispatch } from "react-redux";
import { split } from "lodash";
import Login from "./components/pages/login/Login";
import { history } from "./index";
import Notification from "./components/notification/Notification";
import Loading from "./components/common/Loading";
import Dashboard from "./components/pages/dashboard/Dashboard";
import PageNotFound from "./components/pages/page-not-found/PageNotFound";
import { initialize, removeNotification } from "./store/app/actions";
import { Mixpanel } from "./services/mixpanel/Mixpanel";
import ResetPassword from "./components/pages/login/ResetPassword";
import ProcessReset from "./components/pages/login/ProcessReset";
import PatientResetMessage from "./components/pages/login/PatientResetMessage";
import { Offline } from "react-detect-offline";
import { WifiNone } from "grommet-icons";
import AppLinker from "./components/common/AppLinker";

const App = () => {
  const isInitialized = useSelector((state) => state.app.isInitialized);
  const user = useSelector((state) => state.app.user);
  const location = useSelector((state) => state.router.location);
  const dispatch = useDispatch();
  const page = location.pathname;

  useEffect(() => {
    if (!isInitialized) {
      dispatch(initialize());
    }
  }, [isInitialized]);

  if (user) {
    if (page !== "/" && user.user) {
      if (user.user.clinic)
        Mixpanel.track(split(page, "/")[2], {
          User: `${user.user.first_name} ${user.user.last_name}`,
          Clinic: user.user.clinic.name,
          "User Type": user.user.role,
        });
      else
        Mixpanel.track(split(page, "/")[2], {
          User: `${user.user.first_name} ${user.user.last_name}`,
          Clinic: "None",
          "User Type": user.user.role,
        });
    }
  }

  const MainView = () => (
    <ConnectedRouter history={history}>
      <Layout />
    </ConnectedRouter>
  );

  const PrivateRoute = ({ component: Component, ...rest }) =>
    user ? (
      <Route
        location={location}
        render={(props) => <Component {...props} />}
        {...rest}
      />
    ) : (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );

  const Layout = () => {
    const { pathname } = location;

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    if (!user && pathname === "/") {
      return <Redirect to="/login" />;
    }

    if (user && pathname === "/") {
      if (
        user.user.role === "CLINIC_ADMIN" ||
        user.user.role === "CLINICAL_STAFF"
      )
        return <Redirect to="/dashboard/home" />;

      if (user.user.role === "CUSTOMER_SUCCESS")
        return <Redirect to="/dashboard/bulkUploads" />;

      if (user.user.role === "INVOICING_ADMIN")
        return <Redirect to="/dashboard/billingHome" />;

      if (user.user.role === "SALES_MANAGER")
        return <Redirect to="/dashboard/salesHome" />;

      if (user.user.role === "CAREGIVER")
        return <Redirect to="/dashboard/caregiverHome" />;

      if (
        user.user.role === "TWS_COORDINATOR" ||
        user.user.role === "TWS_CONCIERGE" ||
        user.user.role === "TWS_CASE_MANAGER"
      )
        return <Redirect to="/dashboard/home" />;

      if (user.user.role === "ACO_EXECUTIVE")
        return <Redirect to="/dashboard/acoexecutiveHome" />;
    }

    return (
      <>
        <Switch>
          <PrivateRoute
            user={user}
            exact
            path="/dashboard/*"
            component={(props) => <Dashboard {...props} />}
          />
          <Route
            exact
            path="/login"
            location={location}
            component={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/patientReset"
            location={location}
            component={(props) => <PatientResetMessage {...props} />}
          />
          <Route
            exact
            path="/appstorelink"
            location={location}
            component={(props) => <AppLinker {...props} />}
          />
          <Route
            exact
            path="/resetPassword"
            location={location}
            component={(props) => <ResetPassword {...props} />}
          />

          <Route
            exact
            path="/resetPassword/*"
            location={location}
            component={(props) => <ProcessReset {...props} />}
          />

          <Route component={() => <PageNotFound />} />
        </Switch>
      </>
    );
  };

  if (!isInitialized) {
    return (
      <Box height="100vh">
        <Loading />
      </Box>
    );
  }

  return (
    <Box width="100%" direction="column">
      <MainView />

      <Notification onClose={() => dispatch(removeNotification())} />
      <Offline>
        <div className="offline_error">
          <Box direction="row" gap="small" justify="center" align="center">
            <WifiNone color="#000000" />
            You are not connected to the internet
          </Box>
        </div>
      </Offline>
    </Box>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
