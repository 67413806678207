import React, { useState, useEffect } from "react";
import { Box, Text } from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { FormEdit } from "grommet-icons";
import InPageLoader from "./InPageLoader";
import { getMetadatas } from "../../store/sales/actions";
import EditModalMetaData from "./EditModalMetaData";

const InvoicingDetails = () => {
  const { search } = useSelector((state) => state.router.location);
  const clinicID = split(search, "?", 2)[1];
  const [loading, setLoading] = useState(false);
  const metaDatas = useSelector((state) => state.sales.clinic_metadatas);
  const dispatch = useDispatch();
  const [editShow, setEditShow] = useState(false);

  let thismetadata;

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!metaDatas) {
      setLoading(true);
      dispatch(getMetadatas(success));
    }
  }, []);

  if (metaDatas)
    thismetadata = metaDatas.find((x) => x.clinic.toString() === clinicID);

  if (loading || !metaDatas)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  if (!loading && metaDatas && !thismetadata)
    return (
      <>
        {editShow && (
          <EditModalMetaData
            display={editShow}
            setDisplayOut={(value) => setEditShow(value)}
          />
        )}
        <Box pad="medium" gap="small" className="large-card">
          <Box direction="row" justify="between">
            <Text color="#1D3763" weight="bold">
              No Invoicing Setup for this Clinic
            </Text>
            <FormEdit onClick={() => setEditShow(true)} />
          </Box>
        </Box>
      </>
    );

  return (
    <>
      {editShow && (
        <EditModalMetaData
          display={editShow}
          setDisplayOut={(value) => setEditShow(value)}
        />
      )}
      <Box pad="medium" gap="small" className="large-card">
        <Box direction="row" justify="between">
          <Text color="#1D3763" weight="bold">
            Invoicing Summary
          </Text>
          <FormEdit onClick={() => setEditShow(true)} />
        </Box>
        <Box gap="small">
          <Box>
            <Text color="#395E9D">Setup</Text>
            <Text size="small">Setup fees: ${thismetadata.setup_fees}</Text>
            <Text size="small">
              Providers: {thismetadata.number_of_providers_to_charge_setup_fees}
            </Text>
            <Text size="small" color="#395E9D">
              Total Setup: $
              {thismetadata.number_of_providers_to_charge_setup_fees *
                thismetadata.setup_fees}
            </Text>
          </Box>
          <Box>
            <Text color="#395E9D">Monthly</Text>
            <Text size="small">Monthly fees: ${thismetadata.monthly_fees}</Text>
            <Text size="small">
              Providers:{" "}
              {thismetadata.number_of_providers_to_charge_monthly_fees}
            </Text>
            <Text size="small">
              Monthly Total: $
              {thismetadata.monthly_fees *
                thismetadata.number_of_providers_to_charge_monthly_fees}
            </Text>
            <Text size="small">
              Payment Cycle:&nbsp;
              {thismetadata.number_of_months_to_be_paid} month(s)
            </Text>
            <Text size="small" color="#395E9D">
              Recurring Total Every {thismetadata.number_of_months_to_be_paid}{" "}
              month(s): $
              {thismetadata.monthly_fees *
                thismetadata.number_of_providers_to_charge_monthly_fees *
                thismetadata.number_of_months_to_be_paid}
            </Text>
          </Box>
          <Box>
            <Text color="#395E9D">Contract</Text>
            <Text size="small">Trial: {thismetadata.trial_period}</Text>
            <Text size="small">
              Onboarding Date: {thismetadata.onboarding_date}
            </Text>
            <Text size="small">
              Contract Effective: {thismetadata.contract_effective_date}
            </Text>
            <Text size="small">
              Billing Effective: {thismetadata.monthly_billing_effective_date}
            </Text>
            <Text size="small">
              Contract Termination: {thismetadata.contract_termination_date}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default InvoicingDetails;
