import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, Text, Box } from "grommet";
import { useDispatch } from "react-redux";
import { FormCheckmark } from "grommet-icons";
import CardSection from "./CardSection";
import { createNotification } from "../../../../store/app/actions";
import { getPaymentMethods } from "../../../../store/patients/actions";

export default function CardSetupForm({
  setDisplayOut,
  intent,
  showPaymentMethods,
}) {
  const dispatch = useDispatch();
  const stripe = useStripe();

  const elements = useElements();
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardSetup(intent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
      expand: ["payment_method"],
    });

    if (result.error) {
      dispatch(createNotification(result.error.message, false));
    } else {
      dispatch(createNotification("Card Saved", true));
      setDisplayOut(false);

      await delay(5000);

      dispatch(getPaymentMethods(showPaymentMethods));
    }
  };

  return (
    <>
      <Box gap="small" width="100%">
        <Text color="395E9D">New Card Information</Text>
        <CardSection />
        <Box direction="row" justify="end">
          <Button
            fill={false}
            alignSelf="end"
            style={{
              width: "30%",
              padding: "5px 5px",
              backgroundColor: "white",
              color: "#395E9D",
              borderRadius: "8px",
            }}
            plain
            icon={<FormCheckmark color="white" />}
            disabled={!stripe}
            label="Cancel"
            onClick={() => setDisplayOut(false)}
            size="small"
          />

          <Button
            fill={false}
            alignSelf="end"
            style={{
              width: "30%",
              padding: "5px 5px",
              backgroundColor: "#395E9D",
              color: "white",
              borderRadius: "8px",
            }}
            plain
            icon={<FormCheckmark color="white" />}
            disabled={!stripe}
            label="Save"
            onClick={() => handleSubmit()}
            size="small"
          />
        </Box>
      </Box>
    </>
  );
}
