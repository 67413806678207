import React, { useState } from "react";
import { Text, Layer, Box, CheckBox, TextArea, Button } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { overrideEligibility, saveBHINote } from "../../store/patients/actions";

const OverideEligibilityModal = ({ display, setDisplayOut }) => {
  const [agreed, setAgreed] = useState(false);
  const [notes, setNotes] = useState("");
  const { search } = useSelector((state) => state.router.location);
  const user = useSelector((state) => state.app.user.user);

  const patientId = split(search, "?", 2)[1];

  const dispatch = useDispatch();
  const confirmOverrideEligibility = () => {
    dispatch(overrideEligibility(patientId));

    dispatch(
      saveBHINote(
        `Eligibility Overriden`.toLowerCase(),
        notes,
        patientId,
        user.id
      )
    );

    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card-override"
          position="center"
        >
          <Box gap="small">
            <Text>Override Eligibility?</Text>
            <Text size="small">
              To qualify for Behavioural Health Integration (BHI) enrollment, a
              patient must have an eligible condition. Eligible conditions are
              classified as any mental, behavioral health, or psychiatric
              condition being treated by the billing practitioner, including
              substance use disorders, that, in the clinical judgment of the
              billing practitioner, warrants BHI services. The diagnosis or
              diagnoses could be either pre-existing or made by the billing
              practitioner and may be refined over time. Please confirm below
              that this patient has an eligible condition. Then, enter the
              justification for their eligibility to enroll them in BHI.
            </Text>
            <Box direction="row" gap="small" align="start" justify="start">
              <CheckBox checked={agreed} onChange={() => setAgreed(!agreed)} />
              <Text size="small">
                I confirm that this patient has an eligible condition
              </Text>
            </Box>

            <Box width="100%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Comments:
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>
            <Box direction="row" width="100%" justify="evenly">
              <Button
                className="btn-yes-small"
                color="transparent"
                label="Yes"
                disabled={notes === "" || !agreed}
                onClick={() => confirmOverrideEligibility()}
              />

              <Button
                className="btn-no-small"
                color="transparent"
                label="No"
                disabled={notes === ""}
                onClick={() => setDisplayOut(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default OverideEligibilityModal;
