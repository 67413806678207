/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import "react-datepicker/dist/react-datepicker.css";

import { Box, Heading, Button, Text } from "grommet";
import { Attachment, Checkmark, DownloadOption, Return } from "grommet-icons";
import { split } from "lodash";
// import MailBox from "../../../assets/icons/feather_mail.svg";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import {
  setPatients,
  markFileComplete,
  getSingleBulkFile,
  reUploadFile,
} from "../../../../store/patients/actions";
import InPageLoader from "../../../common/InPageLoader";
import FileBulkNotes from "../../../common/FileBulkNotes";

const LabelText = ({ label, text }) => (
  <Box
    gap="small"
    direction="row"
    pad={{ horizontal: "small" }}
    className="minHeightFitContent"
  >
    <Text color="#4F4F4F" size="small">
      {label}
    </Text>
    <Text color="#44444F" size="small" weight="bold">
      {text}
    </Text>
  </Box>
);

const SingleBulkUploadFile = () => {
  const { search } = useSelector((state) => state.router.location);
  const fileID = split(search, "?", 2)[1];
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileBack = useSelector((state) => state.patients.single_file);

  const reuploadSuccess = (data) => {
    return true;
  };

  const handleFile = (event) => {
    dispatch(reUploadFile(fileID, event.target.files[0], reuploadSuccess));
  };

  const onSuccess = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(getSingleBulkFile(fileID, onSuccess));
    setPatients(null);
  }, []);

  if (loading || !fileBack) return <InPageLoader />;

  return (
    <>
      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box
            direction="row"
            width={isMobileorTablet ? "100%" : null}
            justify={isMobileorTablet ? "between" : null}
            align="center"
          >
            <Heading level={3} color="#1B3664" alignSelf="center">
              Review File
            </Heading>
          </Box>
          <Box direction="row" gap="small">
            <Box direction="row" align="center" className="menu">
              <label className="labelText">
                <input
                  type="file"
                  name="file"
                  onChange={(event) => handleFile(event)}
                />
                <Text size="small">Reupload File</Text>
              </label>
              <Attachment style={{ marginLeft: "5px" }} color="white" />
            </Box>
            <Box
              pad={{ vertical: "small" }}
              gap="small"
              direction="row"
              className="menu"
            >
              <a
                href={fileBack.file}
                download
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
                rel="noopener noreferrer"
              >
                Download
              </a>
              <DownloadOption color="white" />
            </Box>

            <Button
              label="Mark Complete"
              reverse
              className="menu"
              disabled={fileBack.mark_as_done}
              icon={<Checkmark color="white" />}
              color="transparent"
              size="small"
              onClick={() => dispatch(markFileComplete(fileID))}
            />

            <Button
              label="Back to All Files"
              reverse
              className="menu"
              icon={<Return color="white" />}
              color="transparent"
              size="small"
              onClick={() => dispatch(push(`/dashboard/bulkUploads`))}
            />
          </Box>
        </Box>
        <Box
          className="large-card"
          width="100%"
          style={{ overflowX: "scroll" }}
          gap="small"
          pad="medium"
        >
          <Heading level={4} color="#1B3664">
            File Details
          </Heading>
          <LabelText label="ID" text={fileBack.id} />

          <LabelText label="Created" text={fileBack.created} />
          <LabelText label="Modified" text={fileBack.modified} />
          <LabelText label="File" text={fileBack.file} />
          <LabelText label="Clinic ID" text={fileBack.clinic} />
          <LabelText label="Clinician ID" text={fileBack.clinician} />
        </Box>
        <FileBulkNotes />

        <Box
          width="100%"
          justify="start"
          align="center"
          margin={{ top: "small" }}
        >
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="evenly"
            gap="medium"
            align="center"
          ></Box>
        </Box>
      </Box>
    </>
  );
};

export default SingleBulkUploadFile;
