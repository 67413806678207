import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import { useSelector } from "react-redux";
// import StickyNotes from "./StickyNotes";

import moment from "moment";
import { split } from "lodash";
import Loading from "./InPageLoader";
import PatientEngagedGraph from "./PatientEngagedGraph";

const SingleRow = ({ result, name, assessmentTypes }) => {
  const thisAssessment = assessmentTypes.find(
    (element) => element.name === name
  );

  let tags = [];

  if (thisAssessment.tag_group)
    // eslint-disable-next-line prefer-destructuring
    tags = thisAssessment.tag_group.tags;

  const getTag = (score) => {
    if (tags.length === 0) return "No Tag Group";

    let returnTag = "";

    tags.forEach((element) => {
      if (score >= element.low_bound && score <= element.high_bound)
        returnTag = element.label;
    });

    return returnTag;
  };
  return (
    <TableRow>
      <TableCell align="center">
        <Text size="xsmall" style={{ textTransform: "uppercase" }}>
          {moment(result.created).format("MM/DD/YYYY")}
        </Text>
      </TableCell>
      <TableCell align="center">
        <Text size="xsmall" style={{ textTransform: "uppercase" }}>
          {name}
        </Text>
      </TableCell>
      <TableCell align="center">
        <Text size="xsmall" style={{ textTransform: "uppercase" }}>
          {result.score}
        </Text>
      </TableCell>
      <TableCell align="center">
        <Text size="xsmall" style={{ textTransform: "uppercase" }}>
          {getTag(result.score)}
        </Text>
      </TableCell>
    </TableRow>
  );
};

const BehavioralHealthResourceAssessment = () => {
  const notes = useSelector((state) => state.patients.bhi_notes);
  const staff = useSelector((state) => state.patients.staff);
  const { search } = useSelector((state) => state.router.location);
  const month = new Date(split(search, "?")[2]);
  const endDate = new Date(
    month.getFullYear(),
    month.getMonth() + 1,
    0
  ).toISOString();
  const startDate = month.toISOString();

  const getClinicianComments = (element) => {
    const thisStaff = staff.find((item) => item.id === element.added_by);

    if (thisStaff.role === "CLINIC_ADMIN")
      return (
        <li>
          <Text size="xsmall">
            {element.notes}
            <br />
          </Text>
        </li>
      );
    return null;
  };
  const getBHComments = (element) => {
    const thisStaff = staff.find((item) => item.id === element.added_by);

    if (thisStaff.role === "CLINICAL_STAFF")
      return (
        <li>
          <Text size="xsmall">
            {element.notes}
            <br />
          </Text>
        </li>
      );

    return null;
  };
  return (
    <Box
      margin={{ top: "small" }}
      direction="row"
      width="100%"
      justify="around"
      pad="small"
    >
      <Box gap="medium">
        <Box
          pad="small"
          width="315px"
          height="152px"
          style={{ maxHeight: "152px", overflowY: "scroll" }}
          background="#F1F1F590"
        >
          <Text size="small" weight="bold">
            Treating Clinincian Comments
          </Text>
          <Text size="xsmall">
            <ul>
              {notes &&
                notes.map((element) => {
                  if (element.created > startDate && element.created < endDate)
                    return getClinicianComments(element);
                  return null;
                })}
            </ul>
          </Text>
        </Box>
      </Box>
      <Box gap="medium">
        <Box
          pad="small"
          width="315px"
          height="152px"
          style={{ maxHeight: "152px", overflowY: "scroll" }}
          background="#F1F1F590"
        >
          <Text size="small" weight="bold">
            Behavioral Health Manager Comments
          </Text>
          <Text size="xsmall">
            <ul>
              {notes &&
                notes.map((element) => {
                  if (element.created > startDate && element.created < endDate)
                    return getBHComments(element);
                  return null;
                })}
            </ul>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const BHSummary = () => {
  const results = useSelector((state) => state.patients.assessment_results);
  const assessmentTypes = useSelector(
    (state) => state.patients.assessment_types
  );
  const staff = useSelector((state) => state.patients.staff);
  const { search } = useSelector((state) => state.router.location);
  const month = new Date(split(search, "?")[2]);
  const endDate = new Date(
    month.getFullYear(),
    month.getMonth() + 1,
    0
  ).toISOString();
  const startDate = month.toISOString();

  const getManagedBy = (id) => {
    const thisStaff = staff.find((item) => item.id === id);

    if (thisStaff) return `${thisStaff.first_name} ${thisStaff.last_name}`;

    return "";
  };

  const notes = useSelector((state) => state.patients.bhi_notes);

  let resultArray;
  const sortedResults = [];
  const schedulableAssessments = [];

  if (assessmentTypes)
    assessmentTypes.forEach((x, index) => {
      schedulableAssessments[index] = x.name;
    });

  if (results) {
    resultArray = Object.entries(results);
    resultArray.map((result) => {
      if (
        result[1].length !== 0 &&
        schedulableAssessments.find((key) => key === result[0])
      )
        return result[1].map((resultOne) =>
          sortedResults.push([result[0], resultOne])
        );
      return null;
    });
    sortedResults.sort(
      (a, b) => new Date(a[1].created) - new Date(b[1].created)
    );
  }

  if (assessmentTypes)
    assessmentTypes.forEach((x, index) => {
      schedulableAssessments[index] = x.name;
    });

  const getDiagnosisType = (note) => {
    if (
      note.diagnosis === "flag change to null" ||
      note.diagnosis === "flag change to red" ||
      note.diagnosis === "flag change to yellow" ||
      note.diagnosis === "Self Added Note" ||
      note.diagnosis === "eligibility overriden"
    )
      return false;

    return true;
  };

  if (results && assessmentTypes)
    return (
      <>
        <Box width="100%" pad="medium" className="large-card-summary">
          <Text weight="bold" color="#395E9D">
            Assessments Taken
          </Text>
          <Box width="100%" pad="small">
            <Table alignSelf="center">
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      DATE COMPLETED
                    </Text>
                  </TableCell>
                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      ASSESSMENT
                    </Text>
                  </TableCell>

                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      SCORE
                    </Text>
                  </TableCell>
                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      SEVERITY
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedResults.length > 0 &&
                  sortedResults.map((result) => (
                    <SingleRow
                      result={result[1]}
                      name={result[0]}
                      assessmentTypes={assessmentTypes}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
          <PatientEngagedGraph
            PHQ8={results["PHQ-8"]}
            GAD7={results["GAD-7"]}
            Pain={results.Pain}
          />

          <Text weight="bold" color="#395E9D">
            Update Activity History
          </Text>
          <Box
            width="100%"
            pad="small"
            style={{ maxHeight: "200px", overflowY: "scroll" }}
          >
            <Table alignSelf="center">
              <TableHeader>
                <TableRow>
                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      BH Diagnosis
                    </Text>
                  </TableCell>
                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      Date Updated
                    </Text>
                  </TableCell>

                  <TableCell scope="col" align="center">
                    <Text size="small" weight="bold">
                      Updated By
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {notes && notes.reverse()
                  ? notes.map((element) => {
                      if (
                        element.created > startDate &&
                        element.created < endDate &&
                        getDiagnosisType(element)
                      )
                        return (
                          <TableRow>
                            <TableCell align="center">
                              <Text
                                size="xsmall"
                                style={{ textTransform: "uppercase" }}
                              >
                                {element.diagnosis}
                              </Text>
                            </TableCell>

                            <TableCell align="center">
                              <Text
                                size="xsmall"
                                style={{ textTransform: "uppercase" }}
                              >
                                {moment(element.created).format("MM/DD/YYYY")}
                              </Text>
                            </TableCell>

                            <TableCell align="center">
                              <Text
                                size="xsmall"
                                style={{ textTransform: "uppercase" }}
                              >
                                {getManagedBy(element.added_by)}
                              </Text>
                            </TableCell>
                          </TableRow>
                        );

                      return null;
                    })
                  : null}
              </TableBody>
            </Table>
          </Box>

          <BehavioralHealthResourceAssessment />
        </Box>
      </>
    );
  return <Loading />;
};
export default BHSummary;
