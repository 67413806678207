import React, { useEffect } from "react";
import { Box, Text, Heading } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { Add, DownloadOption, Edit } from "grommet-icons";
import { split } from "lodash";
import { getGroup, reUploadContract } from "../../../../store/sales/actions";
import { createNotification } from "../../../../store/app/actions";

const ContractAttachment = () => {
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];

  const thisGroup = useSelector((state) => state.sales.single_group);

  const dispatch = useDispatch();

  const onSuccess = () => true;

  useEffect(() => {
    if (!thisGroup) dispatch(getGroup(groupID, onSuccess));
  }, []);

  const reuploadSuccess = () => {
    dispatch(createNotification("File Changed Successfully", true));
  };

  const handleFile = (event) => {
    dispatch(reUploadContract(groupID, event.target.files[0], reuploadSuccess));
  };

  if (!thisGroup)
    return (
      <Box className="card-attachment" height="100%">
        <Heading level={6} color="#395E9D">
          Contract
        </Heading>
      </Box>
    );

  return (
    <>
      <Box className="card-attachment" width="25%" height="100%">
        <Box direction="row" gap="xsmall" align="start">
          <Heading level={6} color="#395E9D">
            Contract
          </Heading>
        </Box>
        {thisGroup.contract_file ? (
          <Box gap="medium" direction="row" height="100%" align="center">
            <Box
              pad={{ vertical: "small" }}
              gap="small"
              direction="row"
              className="menu"
            >
              <a
                href={thisGroup.contract_file}
                download
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
                rel="noopener noreferrer"
              >
                Download
              </a>
              <DownloadOption color="white" />
            </Box>
            <label className="labelText">
              <input
                type="file"
                name="file"
                style={{ marginTop: "2%" }}
                onChange={(event) => handleFile(event)}
              />
              <Edit color="#395E9D" />
            </label>
          </Box>
        ) : (
          <Box gap="small" justify="center">
            <Text>No Contract Attached</Text>
            <label className="labelText">
              <input
                type="file"
                name="file"
                style={{ marginTop: "2%" }}
                onChange={(event) => handleFile(event)}
              />
              <Box
                direction="row"
                justify="center"
                gap="small"
                pad="xsmall"
                background="#395E9D"
                className="noOutline"
                style={{
                  fontSize: "small",
                  color: "white",
                  borderRadius: "8px",
                }}
              >
                <Add /> Attach File
              </Box>
            </label>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ContractAttachment;
