/* eslint-disable no-console */
/* eslint-disable camelcase */
import { put, takeEvery, call } from "redux-saga/effects";
import { split } from "lodash";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import api from "../../services/api/ApiService";
import {
  FETCH_PATIENTS,
  FETCH_SINGLE_PATIENT,
  setPatients,
  setRedPatients,
  setYellowPatients,
  setSinglePatient,
  SEND_INVITE,
  setInvitedPatients,
  FETCH_INVITED_PATIENTS,
  RESEND_INVITE,
  GET_SINGLE_INVITE,
  setSingleInvite,
  EDIT_SINGLE_INVITE,
  ENROLL_BHI_PATIENT,
  setSinglePatientBHI,
  UPDATE_BHI_PATIENT,
  setAssessmentsSchedule,
  FETCH_ASSESSMENTS_SCHEDULE,
  GET_SINGLE_ASSESSMENT,
  setSingleAssessment,
  GET_ASSESSMENTS,
  setAssessmentTypes,
  SET_NEW_ASSESSMENT,
  EDIT_SINGLE_ASSESSMENT,
  PIN_POST,
  GET_PINNED_CONTENT,
  setPinnedContent,
  UNPIN_POST,
  RESET_FLAG,
  DELETE_SCHEDULE,
  FETCH_ASSESSMENT_RESULTS,
  setAssessmentResults,
  BULK_UPLOAD_FILE,
  GET_BULK_INVITES,
  setBulkInvites,
  SEND_SINGLE_BULK,
  GET_BULK_FILES,
  setBulkFiles,
  MARK_FILE_COMPLETE,
  setBHIPatients,
  FETCH_BHI_PATIENTS,
  setPatientsData,
  FETCH_ALL_REPORT_DATA,
  GET_STAFF,
  setStaff,
  SAVE_BHI_NOTE,
  GET_BHI_NOTES,
  setBHINotes,
  FETCH_ASSESSMENT_RESULTS_RECENT,
  setAssessmentResultsRecent,
  setMessageHistory,
  GET_MESSAGE_HISTORY,
  SAVE_SINGLE_MESSAGE,
  setAllMessageHistory,
  GET_ALL_MESSAGE_HISTORY,
  TOGGLE_MESSAGE_STATUS,
  FETCH_CHAT_PATIENTS,
  setChatPatients,
  MARK_MESSAGES_READ,
  GET_CHECKOUT_SESSION,
  GET_PAYMENT_METHODS,
  RETURN_CHAT_PATIENTS,
  GET_UNREAD_MESSAGES_COUNT,
  FETCH_BILLING_PATIENTS,
  OVERRIDE_ELIGIBILITY,
  GET_PAYMENT_METHODS_GROUP,
  ENROLL_TWS_BHI_PATIENT,
  UPDATE_BHI_PATIENT_TWS,
  GET_SINGLE_BULK_FILE,
  setBulkFile,
  PATCH_FILE_NOTES,
  DOWNLOAD_FILE,
  REUPLOAD_FILE,
  setAllBillingPatients,
  CREATE_NEW_DEFAULT_SCHEDULE,
  setDefaultScheduleAssessments,
  GET_DEFAULT_SCHEDULE_ASSESSMENTS,
  DELETE_DEFAULT_SCHEDULE_ASSESSMENT,
  UPDATE_SELF_HARM_JOURNAL_FLAG,
  FETCH_FIRE_FLAG_PATIENTS,
  setFireFlagPatients,
  UPDATE_PATIENT_LAST_VIEWED,
  setAssessmentResultsOld,
  FETCH_ASSESSMENT_RESULTS_OLD,
} from "./actions";
import { createNotification } from "../app/actions";
import { Mixpanel } from "../../services/mixpanel/Mixpanel";
import { push } from "connected-react-router";

function getSinglePatientData(id) {
  const resp = api.get(`/api/patient/${id}/`);
  return resp;
}

function getSinglePatientBHIData(id) {
  const resp = api.post(`/api/bhi-patient/get-patient-bhi-data/`, {
    patient_id: id,
  });
  return resp;
}

function getAssessmentResultsCall(id, start_date, end_date) {
  const resp = api.post(`/api/patient-assessment/assessment-results/`, {
    patient_id: id,
    start_date,
    end_date,
  });
  return resp;
}

function getAssessmentResultsCallRecent(id) {
  const resp = api.post(`/api/patient-assessment/assessment-results-recent/`, {
    patient_id: id,
  });
  return resp;
}

function* getSinglePatient({ payload: { id, success } }) {
  try {
    const patientId = split(id, "?", 2)[1];
    const singlePatient = yield call(getSinglePatientData, patientId);
    yield put(setSinglePatient(singlePatient.data));

    if (singlePatient.data.bhi_enabled) {
      const singlePatientBHI = yield call(getSinglePatientBHIData, patientId);
      yield put(setSinglePatientBHI(singlePatientBHI.data));
      yield call(success);
    } else {
      yield put(setSinglePatientBHI(null));
      yield call(success);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

function resendInviteCall(id) {
  const resp = api.post(`/api/patient/reinvite/`, { invited_patient_id: id });
  return resp;
}
function* resendInvite({ payload: { resendSuccess, id } }) {
  try {
    const { data } = yield call(resendInviteCall, id);
    if (data) yield call(resendSuccess);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function getPatients(
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  assessed
) {
  const resp = api.get(
    `${"/api/patient/?page_size="}${pageSize}&search=${searchTerm}&ordering=${orderBy}&flag=${filterBy}&is_new=${isNew}&page=${page}&bhi_eligible=${BHIEli}&bhi_info__id__isnull=${BHIenabled}&assessed=${assessed}&clinic_id=${
      window.localStorage.getItem("selectedDropdownClinic")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
        : ""
    }&clinician_id=${
      window.localStorage.getItem("selectedDropdownClinician")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
        : " "
    }`
  );
  return resp;
}
function getInvitedPatients(pageSize, searchTerm, orderBy, filterBy, page) {
  const resp = api.get(
    `${"/api/patient/invite/?page_size="}${pageSize}&search=${searchTerm}&ordering=${orderBy}&status=${filterBy}&page=${page}&status=INVITED&clinic_id=${
      window.localStorage.getItem("selectedDropdownClinic")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
        : ""
    }&clinician_id=${
      window.localStorage.getItem("selectedDropdownClinician")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
        : " "
    }`
  );
  return resp;
}

function* findDataByType(results, count, next, previous, type) {
  if (type === "All") {
    yield put(setPatients(results, count, next, previous));
  } else if (type === "Red") {
    yield put(setRedPatients(results, count));
  } else if (type === "Yellow") {
    yield put(setYellowPatients(results, count));
  } else if (type === "Invited") {
    yield put(setInvitedPatients(results, count, next, previous));
  } else if (type === "HomeData") {
    yield put(setPatientsData(results, count, next, previous));
  } else if (type === "Chat") {
    yield put(setChatPatients(results, count, next, previous));
  } else if (type === "AllBilling") {
    yield put(setAllBillingPatients(results, count, next, previous));
  }
}

function* patients({
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
  },
}) {
  try {
    const {
      data: { results, count, next, previous },
    } = yield call(
      getPatients,
      pageSize,
      searchTerm,
      orderBy,
      filterBy,
      isNew,
      page,
      BHIEli,
      BHIenabled,
      assessed
    );
    yield call(findDataByType, results, count, next, previous, type);
    yield call(success);
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.data.status === "Required field not found.") {
      yield put(push("/dashboard/selectClinicPin"));

      return;
    }
  }
}

function* invitedPatients({
  payload: { success, pageSize, searchTerm, orderBy, filterBy, page },
}) {
  try {
    const {
      data: { results, count, next, previous },
    } = yield call(
      getInvitedPatients,
      pageSize,
      searchTerm,
      orderBy,
      filterBy,
      page
    );
    yield call(findDataByType, results, count, next, previous, "Invited");
    yield call(success);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function sendInviteCall(
  firstName,
  lastName,
  number,
  email,
  NAD,
  insurance,
  physician
) {
  const resp = api.post(
    "/api/patient/create-invite/",
    NAD
      ? {
          first_name: firstName,
          last_name: lastName,
          email,
          cell_number: number,
          next_appointment: moment(NAD).format("YYYY-MM-DD"),
          insurance,
          physician,
          clinic: window.localStorage.getItem("selectedDropdownClinic")
            ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
            : "",
          clinician: window.localStorage.getItem("selectedDropdownClinician")
            ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
            : " ",
        }
      : {
          first_name: firstName,
          last_name: lastName,
          email,
          cell_number: number,
          insurance,
          clinic: window.localStorage.getItem("selectedDropdownClinic")
            ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
            : "",
          clinician: window.localStorage.getItem("selectedDropdownClinician")
            ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
            : " ",
        }
  );
  return resp;
}

function* sendSingleInvite({
  payload: { form, inviteError, NAD, clinic, clinician, user },
}) {
  try {
    const {
      value: { firstName, lastName, number, email, insurance, physician },
    } = form;
    const { data } = yield call(
      sendInviteCall,
      firstName,
      lastName,
      number,
      email,
      NAD,
      insurance,
      physician,
      clinic,
      clinician
    );
    if (data.status === "INVITED") {
      yield put(createNotification("Invite Sent Successfully", true));
      Mixpanel.track("Patient Invited", {
        Name: `${firstName} ${lastName}`,
        Clinic: user.clinic.name,
        User: `${user.first_name} ${user.last_name}`,
        "User Type": user.role,
      });
    }
  } catch (e) {
    yield call(inviteError, e);
  }
}

function sendInviteSingleBulkCall(
  first_name,
  last_name,
  cell_number,
  email,
  insurance,
  NAD,
  clinic,
  clinician
) {
  const resp = api.post("/api/patient/create-invite/", {
    first_name,
    last_name,
    email,
    cell_number,
    insurance,
    next_appointment: moment(NAD).format("YYYY-MM-DD"),
    clinic,
    clinician,
  });
  return resp;
}

function patchBulkRow(
  first_name,
  last_name,
  cell_number,
  email,
  insurance,
  id,
  fileID,
  NAD
) {
  const resp = api.patch(`/api/clinic/patient/rows-bulk/${id}/`, {
    first_name,
    last_name,
    email,
    cell_number,
    insurance,
    upload_file: fileID,
    mark_as_done: true,
    next_appointment: moment(NAD).format("YYYY-MM-DD"),
  });
  return resp;
}

function* sendSingleBulkInvite({
  payload: {
    form,
    inviteError,
    id,
    fileID,
    inviteSuccess,
    NAD,
    clinic,
    clinician,
  },
}) {
  try {
    const { first_name, last_name, cell_number, email, insurance } = form;
    const { data } = yield call(
      sendInviteSingleBulkCall,
      first_name,
      last_name,
      cell_number,
      email,
      insurance,
      NAD,
      clinic,
      clinician
    );
    if (data) {
      Mixpanel.track("Patient Invited", {
        Name: `${first_name} ${last_name}`,
        clinic,
        clinician,
      });

      // eslint-disable-next-line no-unused-vars
      const patchResponse = yield call(
        patchBulkRow,
        first_name,
        last_name,
        cell_number,
        email,
        insurance,
        id,
        fileID,
        NAD
      );
      if (patchResponse.data) yield call(inviteSuccess);
    }
  } catch (e) {
    yield call(inviteError, e);
  }
}

function singleInviteCall(id) {
  const resp = api.get(`/api/patient/invite/${id}/`);
  return resp;
}

function* fetchSingleInvite({ payload: { id, onSuccess } }) {
  try {
    const { data } = yield call(singleInviteCall, id);
    yield put(setSingleInvite(data));

    yield call(onSuccess, data);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function singleAssessmentCall(id) {
  const resp = api.get(`/api/assessment-schedule/${id}/`);

  return resp;
}

function getAssessmentTypesCall() {
  const resp = api.get("/api/assessment/?is_schedulable=1&is_caregiver=0");
  return resp;
}

function* fetchSingleAssessment({ payload: { id, onSuccessEdit } }) {
  try {
    const { data } = yield call(singleAssessmentCall, id);
    yield put(setSingleAssessment(data));
    const assessementTypes = yield call(getAssessmentTypesCall);
    yield put(setAssessmentTypes(assessementTypes.data));
    yield call(onSuccessEdit, data);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function editInviteCall(form, clinic, id, NAD, clinician) {
  const resp = api.patch(
    `/api/patient/invite/${id}/`,
    NAD
      ? {
          first_name: form.first_name,
          last_name: form.last_name,
          insurance: form.insurance,
          email: form.email,
          next_appointment: moment(NAD).format("YYYY-MM-DD"),
          cell_number: form.cell_number,
          clinic,
          clinician,
        }
      : {
          first_name: form.first_name,
          last_name: form.last_name,
          insurance: form.insurance,
          email: form.email,
          next_appointment: null,

          cell_number: form.cell_number,
          clinic,
          clinician,
        }
  );
  return resp;
}

function* editSingleInvite({ payload: { form, invite, NAD } }) {
  try {
    const { data } = yield call(
      editInviteCall,
      form.value,
      invite.clinic,
      invite.id,
      NAD,
      invite.clinician
    );
    if (data) yield put(createNotification("Invite Edited Successfully", true));
    const {
      data: { results, count, next, previous },
    } = yield call(getInvitedPatients, "10", "", "", "", 1);
    yield call(findDataByType, results, count, next, previous, "Invited");
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function enrollCall(diagnosis, consentInfo, patient) {
  const resp = api.post(`/api/bhi-patient/`, {
    diagnosis,
    bhi_manager: "Check Shared With Field",
    consent_information: consentInfo,
    patient,
    enrollment_date: moment().format("YYYY-MM-DD"),
  });
  return resp;
}

function updatePatientSharedWith(bhManager, patient) {
  const resp = api.patch(`/api/patient/${patient}/`, {
    shared_with_clinical_staff: bhManager,
  });
  return resp;
}

function* enrollPatient({
  payload: { diagnosis, bhManager, consentInfo, patient, user, onSuccess },
}) {
  try {
    const { data } = yield call(enrollCall, diagnosis, consentInfo, patient);
    if (data) {
      yield put(createNotification("Patient Enrolled Successfully", true));
      const singlePatientBHI = yield call(getSinglePatientBHIData, patient);
      yield put(setSinglePatientBHI(singlePatientBHI.data));
      const patientInfo = yield call(
        updatePatientSharedWith,
        bhManager,
        patient
      );
      yield put(setSinglePatient(patientInfo.data));
      yield call(onSuccess, patientInfo);
      Mixpanel.track("Patient Enrolled", {
        Name: `${patientInfo.data.first_name} ${patientInfo.data.last_name}`,
        "Patient ID": patient,
        Clinic: user.clinic.name,
        User: `${user.first_name} ${user.last_name}`,
        "User Type": user.role,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong in Enrolling", false));
  }
}

function updateBHIPatientCall(diagnosis, id, patient) {
  const resp = api.patch(`/api/bhi-patient/${id}/`, {
    diagnosis,
    bhi_manager: "Check Shared With Field",
    patient,
  });
  return resp;
}

function* updatePatient({
  payload: { diagnosis, bhi_manager, id, patient, setShow },
}) {
  try {
    const { data } = yield call(updateBHIPatientCall, diagnosis, id, patient);
    if (data) {
      const singlePatientBHI = yield call(getSinglePatientBHIData, patient);
      yield put(setSinglePatientBHI(singlePatientBHI.data));
      const patientInfo = yield call(
        updatePatientSharedWith,
        bhi_manager,
        patient
      );
      yield put(setSinglePatient(patientInfo.data));
      yield put(createNotification("Patient Updated Successfully", true));
      yield call(setShow, false);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function getAssessmentsCall(patientId) {
  const resp = api.get(`/api/assessment-schedule/?patient=${patientId}`);
  return resp;
}

function* getAssessments({ payload: { onSuccess, patientId } }) {
  try {
    const { data } = yield call(getAssessmentsCall, patientId);
    yield put(setAssessmentsSchedule(data));
    yield call(onSuccess);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* getAssessmentTypes({ payload: { onSuccessNew } }) {
  try {
    const { data } = yield call(getAssessmentTypesCall);
    yield put(setAssessmentTypes(data));
    yield call(onSuccessNew);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function newAssessmentCall(value, patient) {
  const resp = api.post(`/api/assessment-schedule/`, {
    start_date: moment(value.start_date).format("YYYY-MM-DD"),
    gap_days: value.gap_days,
    end_date: moment(value.end_date).format("YYYY-MM-DD"),
    patient,
    assessment: value.assessment,
  });
  return resp;
}

function* newAssessment({ payload: { value, patient, onSuccess } }) {
  try {
    const { data } = yield call(newAssessmentCall, value, patient);
    if (data) {
      yield put(createNotification("Assessment Scheduled Successfully", true));
      yield call(onSuccess);
    }
  } catch (e) {
    yield put(createNotification("Assessment Already Scheduled", false));
  }
}

function postPinCall(type, identifier, patient) {
  const resp = api.post(`/api/post/`, { type, identifier, patient });
  return resp;
}

function* postPin({ payload: { type, identifier, patient, setInternalPin } }) {
  try {
    const { data } = yield call(postPinCall, type, identifier, patient);
    if (data) {
      yield call(setInternalPin, data);
      yield put(createNotification("Pin Successful", true));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function postUnpinCall(id) {
  const resp = api.delete(`/api/post/${id}/`);
  return resp;
}

function* postUnpin({ payload: { id } }) {
  try {
    yield call(postUnpinCall, id);
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function editAssessmentCall(value, scheduleID) {
  const resp = api.patch(`/api/assessment-schedule/${scheduleID}/`, {
    gap_days: value.gap_days,
    end_date: moment(value.end_date).format("YYYY-MM-DD"),
    patient: value.patient,
    assessment: value.assessment,
  });
  return resp;
}

function* editAssessment({ payload: { value, scheduleID } }) {
  try {
    const { data } = yield call(editAssessmentCall, value, scheduleID);
    if (data)
      yield put(createNotification("Schedule Edited Successfully", true));
    const resp = yield call(getAssessmentsCall, value.patient);
    yield put(setAssessmentsSchedule(resp.data));
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function fetchPinnedContentCall(patient) {
  const resp = api.get(`/api/post/?patient=${patient}`);
  return resp;
}

function* fetchPinnedContent({ payload: { patient, fetchMyPosts } }) {
  try {
    const { data } = yield call(fetchPinnedContentCall, patient);
    yield put(setPinnedContent(data));
    yield call(fetchMyPosts);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function resetFlagCall(id, flag) {
  let resp;
  if (flag === "RED")
    resp = api.patch(`/api/patient/${id}/`, {
      flag,
      red_flag_updated: new Date(),
    });
  else resp = api.patch(`/api/patient/${id}/`, { flag });

  return resp;
}

function* resetFlag({ payload: { id, flag } }) {
  try {
    const { data } = yield call(resetFlagCall, id, flag);
    if (data) {
      yield put(createNotification("Flag Reset Successfully", true));
      const singlePatient = yield call(getSinglePatientData, id);
      yield put(setSinglePatient(singlePatient.data));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function deleteScheduleCall(id) {
  const resp = api.delete(`/api/assessment-schedule/${id}/`);
  return resp;
}

function* deleteSchedule({ payload: { id, patientId } }) {
  try {
    const response = yield call(deleteScheduleCall, id);
    if (response) {
      yield put(createNotification("Deleted Successfully", true));
      const { data } = yield call(getAssessmentsCall, patientId);
      yield put(setAssessmentsSchedule(data));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function* getAssessmentResults({
  payload: { id, onSuccess, start_date, end_date },
}) {
  try {
    const { data } = yield call(
      getAssessmentResultsCall,
      id,
      moment(start_date).format("YYYY-MM-DD"),
      moment(end_date).format("YYYY-MM-DD")
    );
    if (data) {
      yield put(setAssessmentResults(data));
      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* getAssessmentResultsOld({
  payload: { id, onSuccess, start_date, end_date },
}) {
  try {
    const { data } = yield call(
      getAssessmentResultsCall,
      id,
      moment(start_date).format("YYYY-MM-DD"),
      moment(end_date).format("YYYY-MM-DD")
    );
    if (data) {
      yield put(setAssessmentResultsOld(data));
      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* getAssessmentResultsRecent({ payload: { id, onSuccess } }) {
  try {
    const { data } = yield call(getAssessmentResultsCallRecent, id);
    if (data) {
      yield put(setAssessmentResultsRecent(data));
      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function bulkUploadCall(report, clinic, clinician) {
  // eslint-disable-next-line no-undef
  const FD = new FormData();
  FD.append("file", report);
  FD.append("clinic", clinic);
  FD.append("clinician", clinician);
  const resp = api.post(`/api/clinic/patient/multi-invite/`, FD);
  return resp;
}

function* bulkUpload({ payload: { report, clinic, onSuccess, clinician } }) {
  try {
    const { data } = yield call(bulkUploadCall, report, clinic, clinician);
    if (data) {
      yield call(onSuccess);
      yield put(createNotification("Bulk Upload Successful", true));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(
      createNotification("Error, please check file and fields formats", false)
    );
  }
}

function getBulkSagaCall(fileID) {
  const resp = api.get(
    `/api/clinic/patient/rows-bulk/?upload_file=${fileID}&page_size=999`
  );
  return resp;
}

function* getBulkSaga({ payload: { success, fileID } }) {
  try {
    const { data } = yield call(getBulkSagaCall, fileID);
    if (data) {
      yield put(setBulkInvites(data));
      yield call(success);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function bulkFilesCall() {
  const resp = api.get(`/api/clinic/patient/multi-invite/`);
  return resp;
}

function* bulkFiles({ payload: { success } }) {
  try {
    const { data } = yield call(bulkFilesCall);
    if (data) {
      yield put(setBulkFiles(data));
      yield call(success);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function singleBulkFileCall(id) {
  const resp = api.get(`/api/clinic/patient/multi-invite/` + id);
  return resp;
}

function* singleBulkFile({ payload: { id, onSuccess } }) {
  try {
    const { data } = yield call(singleBulkFileCall, id);
    if (data) {
      yield put(setBulkFile(data));
      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function completeFileCall(fileID) {
  const FD = new FormData();
  FD.append("mark_as_done", true);
  const resp = api.patch(`/api/clinic/patient/multi-invite/${fileID}/`, FD);
  return resp;
}

function* completeFile({ payload: { fileID } }) {
  try {
    const { data } = yield call(completeFileCall, fileID);
    if (data) {
      const file = yield call(singleBulkFileCall, fileID);
      yield put(setBulkFile(file.data));
      yield put(createNotification("File Marked Complete", true));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function processReuploadFileCall(fileID, file) {
  const FD = new FormData();
  FD.append("file", file);
  const resp = api.patch(`/api/clinic/patient/multi-invite/${fileID}/`, FD);
  return resp;
}

function* processReuploadFile({ payload: { fileID, file, success } }) {
  try {
    const { data } = yield call(processReuploadFileCall, fileID, file);
    if (data) {
      yield call(success, data);
      const newfile = yield call(singleBulkFileCall, fileID);
      if (newfile.data) {
        yield put(setBulkFile(newfile.data));
      }
      yield put(createNotification("File Marked Complete", true));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function processDownloadFileCall(fileID) {
  const resp = api.post(
    `/api/clinic/patient/multi-invite-custom/download-file/`,
    {
      clinic_patient_multi_invite_id: fileID,
    }
  );
  return resp;
}

function* processDownloadFile({ payload: { fileID, success } }) {
  try {
    const { data } = yield call(processDownloadFileCall, fileID);
    if (data) {
      yield call(success, data);
    }
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function bhiPatientsSagaCall() {
  const resp = api.get(`/api/bhi-patient/`);
  return resp;
}

function* bhiPatientsSaga() {
  try {
    const { data } = yield call(bhiPatientsSagaCall);
    if (data) {
      yield put(setBHIPatients(data));
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
function getBHINotesSagaCall(patient) {
  const resp = api.get(
    `/api/diagnosis-data-patient/?patient=${patient}&ordering=-created`
  );
  return resp;
}

function* getBHINotesSaga({ payload: { patient, onSuccess } }) {
  try {
    const { data } = yield call(getBHINotesSagaCall, patient);
    if (data) {
      yield put(setBHINotes(data));

      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
function getStaffSagaCall() {
  const resp = api.get(`/me/get_staff/`);
  return resp;
}

function* fetchReportData({
  payload: { search, onSuccess, startDate, endDate },
}) {
  try {
    const patientId = split(search, "?", 2)[1];
    const singlePatient = yield call(getSinglePatientData, patientId);
    yield put(setSinglePatient(singlePatient.data));
    const singlePatientBHI = yield call(getSinglePatientBHIData, patientId);
    yield put(setSinglePatientBHI(singlePatientBHI.data));
    const { data } = yield call(
      getAssessmentResultsCall,
      patientId,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );
    yield put(setAssessmentResults(data));
    const assessementTypes = yield call(getAssessmentTypesCall);
    yield put(setAssessmentTypes(assessementTypes.data));
    const notes = yield call(getBHINotesSagaCall, patientId);
    yield put(setBHINotes(notes.data));
    const staff = yield call(getStaffSagaCall);
    yield put(setStaff(staff.data));
    const schedules = yield call(getAssessmentsCall, patientId);
    yield put(setAssessmentsSchedule(schedules.data));

    yield call(onSuccess);
  } catch (error) {
    Sentry.captureException(error);
  }
}

function* getStaffSaga({ payload: { onSuccess } }) {
  try {
    const { data } = yield call(getStaffSagaCall);
    if (data) {
      yield put(setStaff(data));

      yield call(onSuccess);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function createNoteCall(diagnosis, notes, patient, madeBy) {
  const resp = api.post(`/api/diagnosis-data-patient/`, {
    diagnosis,
    notes,
    patient,
    added_by: madeBy,
  });
  return resp;
}

function* createNote({ payload: { diagnosis, notes, patient, madeBy } }) {
  try {
    yield call(createNoteCall, diagnosis, notes, patient, madeBy);
    const { data } = yield call(getBHINotesSagaCall, patient);
    if (data) {
      yield put(setBHINotes(data));
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function fetchMessageHistoryCall(peerid) {
  const resp = api.get(`/api/chat-history/?to=${peerid}&page_size=9999999`);
  return resp;
}

function* fetchMessageHistory({ payload: { peerid, onSuccess } }) {
  try {
    const { data } = yield call(fetchMessageHistoryCall, peerid);

    if (data) {
      yield put(setMessageHistory(data));
      const staff = yield call(getStaffSagaCall);
      const patientsdata = yield call(
        getPatients,
        "99999999",
        "",
        "",
        "",
        "",
        "1",
        "",
        "",
        ""
      );
      yield call(onSuccess, data, staff.data, patientsdata.data.results);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function saveMessageCall(message_type, message, read_status, to, sender) {
  const resp = api.post(`/api/chat-history/`, {
    message_type,
    message,
    read_status,
    to,
    sender,
  });
  return resp;
}

function* saveMessage({
  payload: { message_type, message, read_status, to, sender, callBack },
}) {
  try {
    yield call(saveMessageCall, message_type, message, read_status, to, sender);
    yield call(callBack);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function fetchAllMessageHistoryCall() {
  const resp = api.get(`/api/chat-history/?page_size=9999999`);
  return resp;
}

function* fetchAllMessageHistory({ payload: { onSuccess } }) {
  try {
    const { data } = yield call(fetchAllMessageHistoryCall);

    if (data) {
      yield put(setAllMessageHistory(data));
      yield call(onSuccess, data);
    }
  } catch (e) {
    console.log(e);
  }
}

function changeMessageStatusCall(patientID, currentStatus) {
  const resp = api.patch(`/api/patient/${patientID}/`, {
    rose_chat: !currentStatus,
  });

  return resp;
}

function* changeMessageStatus({ payload: { patientID, currentStatus } }) {
  try {
    const { data } = yield call(
      changeMessageStatusCall,
      patientID,
      currentStatus
    );

    if (data) {
      const singlePatient = yield call(getSinglePatientData, patientID);
      yield put(setSinglePatient(singlePatient.data));
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function getChatPatients(
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  assessed
) {
  const resp = api.get(
    `${"/api/patient/?page_size="}${pageSize}&search=${searchTerm}&ordering=${orderBy}&flag=${filterBy}&is_new=${isNew}&page=${page}&bhi_eligible=${BHIEli}&bhi_info__id__isnull=${BHIenabled}&assessed=${assessed}&rose_chat=1&clinic_id=${
      window.localStorage.getItem("selectedDropdownClinic")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
        : ""
    }&clinician_id=${
      window.localStorage.getItem("selectedDropdownClinician")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
        : " "
    }`
  );
  return resp;
}

function* chatPatients({
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
  },
}) {
  try {
    const {
      data: { results, count, next, previous },
    } = yield call(
      getChatPatients,
      pageSize,
      searchTerm,
      orderBy,
      filterBy,
      isNew,
      page,
      BHIEli,
      BHIenabled,
      assessed
    );
    yield call(findDataByType, results, count, next, previous, type);
    yield call(success);
  } catch (e) {
    Sentry.captureException(e);
    if (e.response.data.status === "Required field not found.") {
      yield put(push("/dashboard/selectClinicPin"));

      return;
    }
  }
}
function* chatPatientsReturn({
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    assessed,
  },
}) {
  try {
    const {
      data: { results },
    } = yield call(
      getChatPatients,
      pageSize,
      searchTerm,
      orderBy,
      filterBy,
      isNew,
      page,
      BHIEli,
      BHIenabled,
      assessed
    );

    const messages = yield call(fetchAllMessageHistoryCall);

    yield call(success, results, messages.data.results);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function markReadCall(patientID) {
  const resp = api.post(`/api/patient-chat-history/mark-read/`, {
    patient_id: patientID,
  });

  return resp;
}

function* markRead({ payload: { patientID, callBack } }) {
  try {
    yield call(markReadCall, patientID);
    yield call(callBack);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function fetchCheckoutCall() {
  const resp = api.post(`/api/stripe-setup/setup-intent/`);

  return resp;
}

function* fetchCheckout({ payload: { callBack } }) {
  try {
    const data = yield call(fetchCheckoutCall);
    yield call(callBack, data.data);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function fetchBillingCustomerCall() {
  const resp = api.post(`/api/stripe-setup/customer/`);

  return resp;
}
function fetchPaymentMethodsCall() {
  const resp = api.post(`/api/stripe-setup/card/`);

  return resp;
}

function* fetchPaymentMethods({ payload: { callBack } }) {
  try {
    const data = yield call(fetchPaymentMethodsCall);
    const customer = yield call(fetchBillingCustomerCall);

    yield call(callBack, data.data, customer.data);
  } catch (e) {
    yield call(callBack, null);
  }
}

// function fetchBillingCustomerCallForGroup(groupID) {
//   const resp = api.post(`/api/stripe-setup/customer/`, {clinic_group_id:groupID});

//   return resp;
// }
function fetchPaymentMethodsCallForGroup(groupID) {
  const resp = api.post(`/api/stripe-setup/card/`, {
    clinic_group_id: groupID,
  });

  return resp;
}

function* fetchPaymentMethodsForGroup({ payload: { groupID, callBack } }) {
  try {
    const data = yield call(fetchPaymentMethodsCallForGroup, groupID);
    // const customer = yield call(fetchBillingCustomerCallForGroup, groupID);

    yield call(callBack, data.data);
  } catch (e) {
    yield call(callBack, null);
  }
}

function fetchUnreadMessagesCall() {
  const resp = api.get("/api/patient-chat-history/get-unread-messages-count/");

  return resp;
}

function* fetchUnreadMessages({ payload: { callBack } }) {
  try {
    const data = yield call(fetchUnreadMessagesCall);
    yield call(callBack, data.data);
  } catch (e) {
    yield call(callBack, null);
  }
}

function getBillingPatients(
  pageSize,
  searchTerm,
  orderBy,
  filterBy,
  isNew,
  page,
  BHIEli,
  BHIenabled,
  assessed,
  lastEnrollment
) {
  const resp = api.get(
    `${"/api/patient/?page_size="}${pageSize}&search=${searchTerm}&ordering=${orderBy}&flag=${filterBy}&is_new=${isNew}&page=${page}&bhi_eligible=${BHIEli}&bhi_info__id__isnull=${BHIenabled}&assessed=${assessed}&bhi_enrollment_date=${lastEnrollment}`
  );
  return resp;
}

function* billingPatients({
  payload: {
    success,
    pageSize,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    page,
    BHIEli,
    BHIenabled,
    type,
    assessed,
    lastEnrollment,
  },
}) {
  try {
    const {
      data: { results, count, next, previous },
    } = yield call(
      getBillingPatients,
      pageSize,
      searchTerm,
      orderBy,
      filterBy,
      isNew,
      page,
      BHIEli,
      BHIenabled,
      assessed,
      lastEnrollment
    );
    yield call(findDataByType, results, count, next, previous, type);
    yield call(success);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function overrideEligibilityCall(patient) {
  const resp = api.patch(`/api/patient/${patient}/`, { bhi_eligible: true });

  return resp;
}

function* overrideEligibilitySaga({ payload: { patient } }) {
  try {
    const { data } = yield call(overrideEligibilityCall, patient);
    if (data) {
      yield put(createNotification("Eligbility Overriden Successfully", true));
      const singlePatient = yield call(getSinglePatientData, patient);
      yield put(setSinglePatient(singlePatient.data));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function updatePatientSharedWithTWS(bhManager, patient) {
  const resp = api.patch(`/api/patient/${patient}/`, {
    shared_with_tws_concierge: bhManager,
  });
  return resp;
}

function* enrollTWSPatient({
  payload: { diagnosis, bhManager, consentInfo, patient, user, onSuccess },
}) {
  try {
    const { data } = yield call(enrollCall, diagnosis, consentInfo, patient);
    if (data) {
      yield put(createNotification("Patient Enrolled Successfully", true));
      const singlePatientBHI = yield call(getSinglePatientBHIData, patient);
      yield put(setSinglePatientBHI(singlePatientBHI.data));
      const patientInfo = yield call(
        updatePatientSharedWithTWS,
        bhManager,
        patient
      );
      yield put(setSinglePatient(patientInfo.data));
      yield call(onSuccess, patientInfo);
      Mixpanel.track("Patient Enrolled", {
        Name: `${patientInfo.data.first_name} ${patientInfo.data.last_name}`,
        "Patient ID": patient,
        Clinic: user.clinic.name,
        User: `${user.first_name} ${user.last_name}`,
        "User Type": user.role,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong in Enrolling", false));
  }
}
function* updatePatientTWS({
  payload: { diagnosis, bhi_manager, id, patient, setShow },
}) {
  try {
    const { data } = yield call(updateBHIPatientCall, diagnosis, id, patient);
    if (data) {
      const singlePatientBHI = yield call(getSinglePatientBHIData, patient);
      yield put(setSinglePatientBHI(singlePatientBHI.data));
      const patientInfo = yield call(
        updatePatientSharedWithTWS,
        bhi_manager,
        patient
      );
      yield put(setSinglePatient(patientInfo.data));
      yield put(createNotification("Patient Updated Successfully", true));
      yield call(setShow, false);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(createNotification("Something Went Wrong", false));
  }
}

function changeFileNotesCall(fileID, notes) {
  const FD = new FormData();
  FD.append("notes", notes);
  const resp = api.patch(`/api/clinic/patient/multi-invite/${fileID}/`, FD);

  return resp;
}

function* changeFileNotes({ payload: { fileID, notes, callBack } }) {
  const { data } = yield call(changeFileNotesCall, fileID, notes);

  if (data) yield call(callBack);
}

function getDefaultScheduleAssessmentsSagaCall(pinID) {
  const resp = api.get(`/api/schedulable-assessment/?clinic_pin=${pinID}`);

  return resp;
}

function* getDefaultScheduleAssessmentsSaga({ payload: { pinID } }) {
  const { data } = yield call(getDefaultScheduleAssessmentsSagaCall, pinID);

  if (data) yield put(setDefaultScheduleAssessments(data));
}

function createNewDefaultScheduleSagaCall(pinID, assessmentID, gapDays) {
  const resp = api.post(
    "/api/patient-assessment/create-schedulable-assessment/",
    {
      assessment: assessmentID,

      clinic_pin: pinID,
      gap_days: gapDays,
    }
  );

  return resp;
}

function* createNewDefaultScheduleSaga({
  payload: { pinID, assessmentID, gapDays, onSuccess },
}) {
  try {
    const { data } = yield call(
      createNewDefaultScheduleSagaCall,
      pinID,
      assessmentID,
      gapDays
    );

    if (data) yield call(onSuccess);
  } catch (e) {
    if (e.response.status === 500)
      yield put(createNotification("Assessment already scheduled", false));

    if (e.response.status === 501)
      yield put(
        createNotification(
          "Assessment from this group already scheduled",
          false
        )
      );
  }
}

function deleteDefaultScheduleSagaCall(id) {
  const resp = api.delete(`/api/schedulable-assessment/${id}`);

  return resp;
}

function* deleteDefaultScheduleSaga({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(deleteDefaultScheduleSagaCall, id);

    if (response.status === 204) yield call(onSuccess);
  } catch (e) {
    yield put(createNotification("Something went wrong", false));
  }
}

function updateSelfHarmJournalFlagSagaCall(id) {
  const resp = api.post(`/api/patient-journal/update-self-harm-journal-flag/`, {
    journal_id: id,
  });

  return resp;
}

function* updateSelfHarmJournalFlagSaga({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(updateSelfHarmJournalFlagSagaCall, id);

    if (response.status === 200) yield call(onSuccess);
  } catch (e) {
    yield put(createNotification("Something went wrong", false));
  }
}

function getFireFlagPatientsCall(page, page_size, search) {
  if (search !== "") {
    const resp = api.post(`/api/patient-custom/get-fire-flag-patients/`, {
      page: page,
      clinic_id: window.localStorage.getItem("selectedDropdownClinic")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
        : "",
      clinician_id: window.localStorage.getItem("selectedDropdownClinician")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
        : " ",
      page_size,
      search,
    });

    return resp;
  } else {
    const resp = api.post(`/api/patient-custom/get-fire-flag-patients/`, {
      page: page,
      clinic_id: window.localStorage.getItem("selectedDropdownClinic")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinic"))
        : "",
      clinician_id: window.localStorage.getItem("selectedDropdownClinician")
        ? parseInt(window.localStorage.getItem("selectedDropdownClinician"))
        : " ",
      page_size,
    });

    return resp;
  }
}

function* getFireFlagPatients({
  payload: { page, onSuccess, page_size, search },
}) {
  try {
    const { data } = yield call(
      getFireFlagPatientsCall,
      page,
      page_size,
      search
    );

    if (data) {
      yield put(setFireFlagPatients(data));
      yield call(onSuccess);
    }
  } catch (e) {
    console.log(e);
  }
}

function updateLastViewedSagaCall(id) {
  const resp = api.patch(`/api/patient/${id}/`, {
    last_view: moment().format("YYYY-MM-DDThh:mm:ss.000Z"),
  });

  return resp;
}

function* updateLastViewedSaga({ payload: { id } }) {
  try {
    yield call(updateLastViewedSagaCall, id);
  } catch (e) {
    console.log(e);
  }
}

function* patientsSaga() {
  yield takeEvery(FETCH_PATIENTS, patients);
  yield takeEvery(FETCH_INVITED_PATIENTS, invitedPatients);
  yield takeEvery(FETCH_SINGLE_PATIENT, getSinglePatient);
  yield takeEvery(SEND_INVITE, sendSingleInvite);
  yield takeEvery(RESEND_INVITE, resendInvite);
  yield takeEvery(GET_SINGLE_INVITE, fetchSingleInvite);
  yield takeEvery(EDIT_SINGLE_INVITE, editSingleInvite);
  yield takeEvery(ENROLL_BHI_PATIENT, enrollPatient);
  yield takeEvery(UPDATE_BHI_PATIENT, updatePatient);
  yield takeEvery(FETCH_ASSESSMENTS_SCHEDULE, getAssessments);
  yield takeEvery(GET_SINGLE_ASSESSMENT, fetchSingleAssessment);
  yield takeEvery(GET_ASSESSMENTS, getAssessmentTypes);
  yield takeEvery(SET_NEW_ASSESSMENT, newAssessment);
  yield takeEvery(EDIT_SINGLE_ASSESSMENT, editAssessment);
  yield takeEvery(PIN_POST, postPin);
  yield takeEvery(GET_PINNED_CONTENT, fetchPinnedContent);
  yield takeEvery(UNPIN_POST, postUnpin);
  yield takeEvery(RESET_FLAG, resetFlag);
  yield takeEvery(DELETE_SCHEDULE, deleteSchedule);
  yield takeEvery(FETCH_ASSESSMENT_RESULTS, getAssessmentResults);
  yield takeEvery(FETCH_ASSESSMENT_RESULTS_OLD, getAssessmentResultsOld);

  yield takeEvery(FETCH_ASSESSMENT_RESULTS_RECENT, getAssessmentResultsRecent);
  yield takeEvery(BULK_UPLOAD_FILE, bulkUpload);
  yield takeEvery(GET_BULK_INVITES, getBulkSaga);
  yield takeEvery(SEND_SINGLE_BULK, sendSingleBulkInvite);
  yield takeEvery(GET_BULK_FILES, bulkFiles);
  yield takeEvery(GET_SINGLE_BULK_FILE, singleBulkFile);
  yield takeEvery(MARK_FILE_COMPLETE, completeFile);
  yield takeEvery(FETCH_BHI_PATIENTS, bhiPatientsSaga);
  yield takeEvery(FETCH_ALL_REPORT_DATA, fetchReportData);
  yield takeEvery(GET_STAFF, getStaffSaga);
  yield takeEvery(SAVE_BHI_NOTE, createNote);
  yield takeEvery(GET_BHI_NOTES, getBHINotesSaga);
  yield takeEvery(GET_MESSAGE_HISTORY, fetchMessageHistory);
  yield takeEvery(SAVE_SINGLE_MESSAGE, saveMessage);
  yield takeEvery(GET_ALL_MESSAGE_HISTORY, fetchAllMessageHistory);
  yield takeEvery(TOGGLE_MESSAGE_STATUS, changeMessageStatus);
  yield takeEvery(FETCH_CHAT_PATIENTS, chatPatients);
  yield takeEvery(MARK_MESSAGES_READ, markRead);
  yield takeEvery(GET_CHECKOUT_SESSION, fetchCheckout);
  yield takeEvery(GET_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeEvery(RETURN_CHAT_PATIENTS, chatPatientsReturn);
  yield takeEvery(GET_UNREAD_MESSAGES_COUNT, fetchUnreadMessages);
  yield takeEvery(FETCH_BILLING_PATIENTS, billingPatients);
  yield takeEvery(OVERRIDE_ELIGIBILITY, overrideEligibilitySaga);
  yield takeEvery(GET_PAYMENT_METHODS_GROUP, fetchPaymentMethodsForGroup);
  yield takeEvery(ENROLL_TWS_BHI_PATIENT, enrollTWSPatient);
  yield takeEvery(UPDATE_BHI_PATIENT_TWS, updatePatientTWS);
  yield takeEvery(PATCH_FILE_NOTES, changeFileNotes);
  yield takeEvery(DOWNLOAD_FILE, processDownloadFile);
  yield takeEvery(REUPLOAD_FILE, processReuploadFile);
  yield takeEvery(CREATE_NEW_DEFAULT_SCHEDULE, createNewDefaultScheduleSaga);
  yield takeEvery(
    GET_DEFAULT_SCHEDULE_ASSESSMENTS,
    getDefaultScheduleAssessmentsSaga
  );
  yield takeEvery(
    DELETE_DEFAULT_SCHEDULE_ASSESSMENT,
    deleteDefaultScheduleSaga
  );
  yield takeEvery(UPDATE_SELF_HARM_JOURNAL_FLAG, updateSelfHarmJournalFlagSaga);
  yield takeEvery(FETCH_FIRE_FLAG_PATIENTS, getFireFlagPatients);
  yield takeEvery(UPDATE_PATIENT_LAST_VIEWED, updateLastViewedSaga);
}

export default patientsSaga;
