import React, { useState } from "react";
import { Box, Button, Text } from "grommet";
import { View } from "grommet-icons";
import moment from "moment";
import ViewAnswersModalGeneric from "./ViewAnswersModalGeneric";
import { isMobileHook, iTabletHook } from "./Responsive";

const LatestAssessmentBox = ({ tests, name, assessmentTypes }) => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [phqShow, setPhqShow] = useState();

  let tags = [];

  const thisAssessment = assessmentTypes.find(
    (element) => element.name === name
  );

  const maxScore =
    thisAssessment.answer_group.answers[
      thisAssessment.answer_group.answers.length - 1
    ].score * thisAssessment.questionnaire.length;

  if (thisAssessment.tag_group)
    // eslint-disable-next-line prefer-destructuring
    tags = thisAssessment.tag_group.tags;

  const getTag = (score) => {
    if (tags.length === 0) return "No Tag Group";

    let returnTag = "";

    tags.forEach((element) => {
      if (score >= element.low_bound && score <= element.high_bound)
        returnTag = element.label;
    });

    return returnTag;
  };

  return (
    <>
      {phqShow && (
        <ViewAnswersModalGeneric
          display={phqShow}
          setDisplayOut={(value) => setPhqShow(value)}
          Answers={tests[tests.length - 1]}
          name={name}
          tag={getTag(tests[tests.length - 1].score)}
        />
      )}

      <Box
        pad="medium"
        className="large-card latest-assessment"
        margin={{ top: "small" }}
        justify="center"
        gap="medium"
        wrapProp={isMobileorTablet ? "wrap-reverse" : "true"}
      >
        <Box direction="row" justify="between" wrapProp="true">
          <Text color="#395E9D" style={{ maxWidth: "50%" }} weight="bold">
            Most Recent {name} Assessment
          </Text>

          <Box direction="row">
            <Text
              size="small"
              color="#696974"
              weight="bold"
              margin={{ bottom: "small" }}
            >
              Date Taken:
            </Text>
            <Text size="small" color="#696974">
              &nbsp;
              {moment(tests[tests.length - 1].created).format("MM/DD/YYYY")}
            </Text>
          </Box>
        </Box>

        <Box justify="between" direction="row" wrapProp="true">
          <Box align="center" direction="row" wrapProp="true">
            <Box direction="row">
              <Text weight="bold" size="28px">
                {tests[tests.length - 1].score}
              </Text>
              <Text size="18px">/{maxScore}</Text>
            </Box>
            <Box
              className="result-tag"
              pad={{ horizontal: "small", vertical: "xxsmall" }}
              margin={{ left: "small" }}
            >
              <Text size="xsmall" style={{ textTransform: "uppercase" }}>
                {getTag(tests[tests.length - 1].score)}
              </Text>
            </Box>
          </Box>

          <Box margin={{ top: "xsmall" }}>
            <Button
              className="btn-primary"
              color="#2BC2F7"
              size="small"
              label="View Answers"
              onClick={() => setPhqShow(!phqShow)}
              icon={<View color="white" size="14px" />}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LatestAssessmentBox;
