import initialState from "../initialState";
import {
  SET_ALL_CLINICIANS,
  SET_ALL_CLINICS,
  SET_ALL_COORDINATORS,
  SET_CLASS_ENROLLMENT_LIST,
  SET_CLINIC,
  SET_CLINIC_GROUP,
  SET_CLINIC_GROUPS,
  SET_CLINIC_GROUP_CLINICS,
  SET_CLINIC_GROUP_INVOICES,
  SET_CLINIC_META,
  SET_PATIENT_DATA_SALES,
  SET_REVENUE_DATA_SALES,
  SET_TEST_CLINIC_GROUPS,
} from "./actions";

export default function salesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CLINIC_GROUPS: {
      const { data } = payload;
      return { ...state, clinic_groups: data };
    }
    case SET_TEST_CLINIC_GROUPS: {
      const { data } = payload;
      return { ...state, clinic_groups_test: data };
    }
    case SET_CLINIC_GROUP: {
      const { data } = payload;
      return { ...state, single_group: data };
    }
    case SET_CLINIC_GROUP_CLINICS: {
      const { data } = payload;
      return { ...state, single_group_clinics: data };
    }
    case SET_CLINIC: {
      const { data } = payload;
      return { ...state, single_clinic: data };
    }
    case SET_CLINIC_META: {
      const { data } = payload;
      return { ...state, clinic_metadatas: data };
    }
    case SET_CLINIC_GROUP_INVOICES: {
      const { data } = payload;
      return { ...state, group_invoices: data };
    }
    case SET_ALL_CLINICS: {
      const { data } = payload;
      return { ...state, all_clinics: data };
    }
    case SET_PATIENT_DATA_SALES: {
      const { data } = payload;
      return { ...state, sales_patients: data };
    }
    case SET_REVENUE_DATA_SALES: {
      const { data } = payload;
      return { ...state, sales_revenue: data };
    }
    case SET_CLASS_ENROLLMENT_LIST: {
      const { data } = payload;
      return { ...state, class_enrollments: data };
    }
    case SET_ALL_CLINICIANS: {
      const { data } = payload;
      return { ...state, all_clinicians: data };
    }
    case SET_ALL_COORDINATORS: {
      const { data } = payload;
      return { ...state, all_coordinators: data };
    }
    default:
      return state;
  }
}
