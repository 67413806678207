/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";

import {
  Box,
  Text,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
  Select,
  CheckBox,
} from "grommet";
import { Down, Next, Previous, Search } from "grommet-icons";
import { ceil } from "lodash";

// import MailBox from "../../../assets/icons/feather_mail.svg";
import Loading from "../../../common/InPageLoader";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import {
  setPatients,
  fetchBHIPatients,
  fetchBillingPatients,
  fetchPatients,
} from "../../../../store/patients/actions";

const Billing = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [sortValue, setSortValue] = useState();
  const [orderBy, setOrderBy] = useState("");
  const staff = useSelector((state) => state.patients.staff);
  const [filterValue, setFilterValue] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    ).toISOString()
  );

  const getManagedBy = (id) => {
    const thisStaff = staff.find((item) => item.id === id);

    if (thisStaff) return `${thisStaff.first_name} ${thisStaff.last_name}`;

    return "";
  };

  const options = [];
  let count = 0;

  for (let i = 0; i < 12; i += 1) {
    if (i < new Date().getMonth()) {
      const thisDay = new Date(new Date().getFullYear(), i, 1).toISOString();
      options.push({
        value: moment(thisDay).format("MMMM YYYY"),
        key: thisDay,
      });
      count += 1;
    }
  }

  options.sort((a, b) => new Date(b.value) - new Date(a.value));

  let monthIndicator = 11;

  if (count < 6) {
    for (count; count < 6; count += 1) {
      const thisDay = new Date(
        new Date().getFullYear() - 1,
        monthIndicator,
        1
      ).toISOString();
      options.push({
        value: moment(thisDay).format("MMMM YYYY"),
        key: thisDay,
      });
      monthIndicator -= 1;
    }
  }

  const [showValue, setShowValue] = useState("10");
  const [page, setPage] = useState(1);
  const [showOnly, setShowOnly] = useState(false);

  const allpatients =
    useSelector((state) => state.patients.all_billing_patients) || null;

  const patients = useSelector((state) => state.patients.patients) || null;
  const bhiPatients =
    useSelector((state) => state.patients.bhiPatients) || null;
  let totalEntries = allpatients ? allpatients.count : "0";
  const next = allpatients ? allpatients.next : "0";
  const previous = allpatients ? allpatients.previous : "0";

  const totalEntriesShowOnly = useSelector((state) => state.patients.count);
  const nextShowOnly = useSelector((state) => state.patients.next);
  const previousShowOnly = useSelector((state) => state.patients.previous);

  const getEnrollmentDate = (id) => {
    const found = bhiPatients.find((element) => {
      if (element.patient === id) return element;
      return false;
    });
    return found.enrollment_date;
  };

  const handleShowOnlyChange = () => {
    setPage(1);
    setSearchTerm("");
    setOrderBy("");
    setSortValue(null);
    setShowOnly(!showOnly);
  };
  const getReportStatus = (id) => {
    const found = patients.find((element) => element.id === id);

    if (found) return true;

    return false;
  };

  const [searchTerm, setSearchTerm] = useState("");
  const success = () => {
    setLoading(false);
  };

  const fetchPatientsData = () => {
    dispatch(
      fetchPatients(
        success,
        "9999999",
        searchTerm,
        orderBy,
        "",
        "",
        1,
        "",
        "",
        "AllBilling",
        ""
      )
    );
    dispatch(
      fetchBillingPatients(
        success,
        showOnly ? showValue : "999999",
        showOnly ? searchTerm : "",
        showOnly ? orderBy : "",
        "",
        "",
        showOnly ? page : 1,
        "",
        "false",
        "All",
        moment(filterValue).format("YYYY-MM-DD"),
        moment(filterValue).add(1, "month").format("YYYY-MM-DD")
      )
    );
    dispatch(fetchBHIPatients());
  };

  useEffect(() => {
    setPatients(null);
    setLoading(true);
    fetchPatientsData();
  }, [showValue, searchTerm, orderBy, page, filterValue]);

  const paginatedResults = [];

  let counter = 1;
  let totalCount = 0;

  let toPage = 0;

  if (allpatients && allpatients.patients) {
    allpatients.patients.forEach((patient) => {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      if (
        new Date(patient.created) <
        new Date(moment(filterValue).add(1, "month").format("YYYY-MM-DD"))
      ) {
        paginatedResults[toPage].push(patient);
        counter += 1;
        totalCount += 1;
      }

      if (counter > showValue) {
        toPage += 1;
        counter = 1;
      }
    });
    totalEntries = totalCount;
  }

  const totalPages = showOnly
    ? ceil(totalEntriesShowOnly / showValue)
    : paginatedResults.length;
  const pages = [];

  for (let i = 0; i < totalPages; i += 1) {
    pages[i] = i + 1;
  }

  if (patients && bhiPatients)
    return (
      <>
        <Box width="100%">
          <Box direction="row" margin={{ vertical: "small" }} align="center">
            <Text margin={{ vertical: "small" }} weight="bold" color="#395E9D">
              Total billable patients due for
            </Text>
            <Select
              options={options}
              margin={{ left: "xsmall" }}
              labelKey="value"
              valueKey={{ key: "key", reduce: true }}
              value={filterValue}
              style={{ color: "#395E9D", width: "150px" }}
              onChange={({ option }) => {
                setPage(1);
                setFilterValue(option.key);
              }}
              dropHeight="small"
              dropProps={{ style: { borderRadius: "10px" } }}
            />
          </Box>
          <Box
            className="large-card"
            justify="center"
            style={{ overflowX: "auto" }}
            gap="small"
            pad="small"
          >
            <Box
              direction={isMobileorTablet ? "column" : "row"}
              width="100%"
              align="center"
              justify="between"
              margin={{ top: "small" }}
            >
              <Box
                round="small"
                align="center"
                justify="between"
                direction="row"
                height="38px"
                style={{ border: "1px solid #E0E0E0" }}
                width={isMobileorTablet ? "100%" : "320px"}
                className="whiteBack"
                margin={isMobileorTablet ? { bottom: "small" } : null}
              >
                <TextInput
                  name="search"
                  plain
                  type="text"
                  className="searchInput"
                  placeholder="Search"
                  value={searchTerm}
                  onChangeCapture={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(1);
                  }}
                  required
                />

                <Box margin={{ right: "small" }}>
                  <Search color="#E0E0E0" />
                </Box>
              </Box>
              <CheckBox
                checked={showOnly}
                className="noOutline"
                toggle
                onChange={() => handleShowOnlyChange()}
                label={
                  <Text size="small" color="brand">
                    Show Only Ready Reports for Selected Month
                  </Text>
                }
              />
              <Box
                height={isMobileorTablet ? null : "38px"}
                align="center"
                width={isMobileorTablet ? "100%" : null}
                direction={isMobileorTablet ? "column" : "row"}
              >
                <Box
                  className="whiteBack"
                  style={{ border: "1px solid #E0E0E0" }}
                  round="small"
                  width={isMobileorTablet ? "100%" : null}
                  margin={
                    isMobileorTablet
                      ? { bottom: "small" }
                      : { horizontal: "small" }
                  }
                >
                  <Select
                    options={["Name", "Created"]}
                    plain
                    clear
                    size="small"
                    dropHeight="small"
                    dropProps={{
                      style: { borderRadius: "10px" },
                    }}
                    value={sortValue}
                    onChange={({ option }) => {
                      setSortValue(option);
                      setPage(1);
                      if (option === "Name") setOrderBy("first_name");
                      else if (option === "Created") setOrderBy("-created");
                      else setOrderBy("");
                    }}
                    placeholder="Sort By:"
                    style={{ color: "black" }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              direction="row"
              width="100%"
              justify="between"
              align="center"
              pad={{ horizontal: "small" }}
            >
              <Box direction="row" align="center" gap="small">
                {showOnly ? (
                  <Text size="small" color="#395E9D">
                    Showing{" "}
                    {totalEntriesShowOnly === 0
                      ? 0
                      : showValue * page - (showValue - 1)}{" "}
                    to{" "}
                    {nextShowOnly === null
                      ? totalEntriesShowOnly
                      : showValue * page}{" "}
                    of {totalEntriesShowOnly} entries
                  </Text>
                ) : (
                  <Text size="small" color="#395E9D">
                    Showing{" "}
                    {totalEntries === 0
                      ? 0
                      : showValue * page - (showValue - 1)}{" "}
                    to {next === null ? totalEntries : showValue * page} of{" "}
                    {totalEntries} entries
                  </Text>
                )}
                <Box width="60px" round="small" background="#395E9D">
                  <Select
                    options={["5", "10", "20", "30", "50", "100"]}
                    plain
                    value={showValue}
                    style={{ color: "white" }}
                    size="small"
                    onChange={({ option }) => {
                      setShowValue(option);
                      setPage(1);
                    }}
                    dropHeight="small"
                    icon={<Down color="white" size="small" />}
                    dropProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "#395E9D",
                        boxShadow: "2px 6px 21px -2px rgba(0,0,0,0.75)",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                direction="row"
                align="center"
                style={{ border: "1px solid #E0E0E0" }}
                pad="xsmall"
                round="small"
              >
                {showOnly ? (
                  <Button
                    size="small"
                    color="#666666"
                    onClick={() =>
                      previousShowOnly === null ? null : setPage(page - 1)
                    }
                    disabled={previousShowOnly === null}
                  >
                    <Previous color="#395E9D" size="small" />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    color="#666666"
                    onClick={() =>
                      previous === null ? null : setPage(page - 1)
                    }
                    disabled={previous === null}
                  >
                    <Previous color="#395E9D" size="small" />
                  </Button>
                )}
                {pages.map((vals) => (
                  <Text
                    className={vals === page ? "activePage" : "inactivePage"}
                    textAlign="center"
                    size="small"
                    margin="xsmall"
                    color="#82D4E7"
                    onClick={() => setPage(vals)}
                  >
                    {vals}
                  </Text>
                ))}
                {showOnly ? (
                  <Button
                    size="small"
                    color="#666666"
                    onClick={() =>
                      nextShowOnly === null ? null : setPage(page + 1)
                    }
                    disabled={nextShowOnly === null}
                  >
                    <Next color="#395E9D" size="small" />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    color="#666666"
                    onClick={() => (next === null ? null : setPage(page + 1))}
                    disabled={next === null}
                  >
                    <Next color="#395E9D" size="small" />
                  </Button>
                )}
              </Box>
            </Box>

            {loading ? (
              <Box>
                <Loading />
              </Box>
            ) : (
              <>
                <Table alignSelf="center">
                  <TableHeader>
                    <TableRow>
                      <TableCell scope="col">
                        <Text size="small" weight="bold">
                          PATIENT NAME
                        </Text>
                      </TableCell>
                      {isMobileorTablet ? null : (
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            PRIMARY PROVIDER
                          </Text>
                        </TableCell>
                      )}
                      <TableCell scope="col" align="center">
                        <Text size="small" weight="bold">
                          CREATED
                        </Text>
                      </TableCell>
                      <TableCell scope="col" align="center">
                        <Text size="small" weight="bold">
                          REPORT STATUS
                        </Text>
                      </TableCell>
                      <TableCell scope="col" align="center">
                        <Text size="small" weight="bold">
                          BHI ENROLLED
                        </Text>
                      </TableCell>
                      {/* <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            MESSAGE
                          </Text>
                        </TableCell> */}
                      <TableCell scope="col" align="center" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {patients &&
                      allpatients &&
                      !showOnly &&
                      paginatedResults &&
                      paginatedResults[page - 1] &&
                      paginatedResults[page - 1].map((patient) => {
                        if (patient)
                          return (
                            <TableRow key={patient.id}>
                              <TableCell scope="row">
                                <Text size="small" weight="bold">
                                  {patient.first_name} {patient.last_name}
                                </Text>
                              </TableCell>

                              <TableCell>
                                <Text size="small">
                                  {getManagedBy(patient.clinician)}
                                </Text>
                              </TableCell>
                              <TableCell>
                                <Text size="small">
                                  {moment(patient.created).format("MM/DD/YYYY")}
                                </Text>
                              </TableCell>

                              <TableCell align="center">
                                {getReportStatus(patient.id) ? (
                                  <Box className="reportReady">
                                    <Text size="xsmall">REPORT READY</Text>
                                  </Box>
                                ) : (
                                  <Box className="reportMissing">
                                    <Text size="xsmall">NOT READY</Text>
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Text size="xsmall" weight="bold">
                                  {patient.bhi_enabled
                                    ? moment(
                                        getEnrollmentDate(patient.id)
                                      ).format("MM/DD/YYYY")
                                    : "Patient Not Enrolled"}
                                </Text>
                              </TableCell>
                              <TableCell>
                                {getReportStatus(patient.id) ? (
                                  <Box
                                    direction="row"
                                    justify="center"
                                    align="center"
                                  >
                                    <Button
                                      // eslint-disable-next-line no-nested-ternary
                                      className="btn-primary-dark-mobile"
                                      color="transparent"
                                      margin={{ horizontal: "small" }}
                                      size="small"
                                      label="View"
                                      onClick={() => {
                                        dispatch(
                                          push(
                                            `/dashboard/patientReport/?${patient.id}?${filterValue}`
                                          )
                                        );
                                      }}
                                    />
                                    <Button
                                      // eslint-disable-next-line no-nested-ternary
                                      className="btn-current"
                                      color="transparent"
                                      margin={{ horizontal: "small" }}
                                      size="small"
                                      label="Download"
                                      // eslint-disable-next-line no-undef
                                      onClick={() => {
                                        window.open(
                                          `/dashboard/patientReport/?${patient.id}?${filterValue}?download`
                                        );
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    direction="row"
                                    justify="center"
                                    align="center"
                                  >
                                    <Button
                                      // eslint-disable-next-line no-nested-ternary
                                      className="btn-primary-dark-mobile"
                                      color="transparent"
                                      margin={{ horizontal: "small" }}
                                      size="small"
                                      label="View"
                                      disabled
                                    />
                                    <Button
                                      // eslint-disable-next-line no-nested-ternary
                                      className="btn-current"
                                      color="transparent"
                                      margin={{ horizontal: "small" }}
                                      size="small"
                                      label="Download"
                                      disabled
                                      // eslint-disable-next-line no-undef
                                    />
                                  </Box>
                                )}
                              </TableCell>
                            </TableRow>
                          );

                        return null;
                      })}
                    {patients &&
                      allpatients &&
                      showOnly &&
                      patients.map((patient) => (
                        <TableRow key={patient.id}>
                          <TableCell scope="row">
                            <Text size="small" weight="bold">
                              {patient.first_name} {patient.last_name}
                            </Text>
                          </TableCell>

                          <TableCell>
                            <Text size="small">
                              {getManagedBy(patient.clinician)}
                            </Text>
                          </TableCell>

                          <TableCell align="center">
                            {getReportStatus(patient.id) ? (
                              <Box className="reportReady">
                                <Text size="xsmall">REPORT READY</Text>
                              </Box>
                            ) : (
                              <Box className="reportMissing">
                                <Text size="xsmall">NOT READY</Text>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Text size="xsmall" weight="bold">
                              {patient.bhi_enabled
                                ? moment(getEnrollmentDate(patient.id)).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Patient Not Enrolled"}
                            </Text>
                          </TableCell>
                          {/* <TableCell>
                              <Box justify="center" align="center">
                                <Image src={MailBox} alt="message" />
                              </Box>
                            </TableCell> */}
                          <TableCell>
                            <Box
                              direction="row"
                              justify="center"
                              align="center"
                            >
                              <Button
                                // eslint-disable-next-line no-nested-ternary
                                className="btn-primary-dark-mobile"
                                color="transparent"
                                margin={{ horizontal: "small" }}
                                size="small"
                                label="View"
                                onClick={() => {
                                  dispatch(
                                    push(
                                      `/dashboard/patientReport/?${patient.id}?${filterValue}`
                                    )
                                  );
                                }}
                              />
                              <Button
                                // eslint-disable-next-line no-nested-ternary
                                className="btn-current"
                                color="transparent"
                                margin={{ horizontal: "small" }}
                                size="small"
                                label="Download"
                                // eslint-disable-next-line no-undef
                                onClick={() => {
                                  window.open(
                                    `/dashboard/patientReport/?${patient.id}?${filterValue}?download`
                                  );
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Box>
        </Box>
      </>
    );

  return <Loading />;
};

export default Billing;
