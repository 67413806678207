/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";
import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
  Select,
  Menu,
} from "grommet";
import { Alert, Ascending, Descending, FormDown, Search } from "grommet-icons";
import { ceil, split } from "lodash";
import RedAlert from "../../../assets/icons/red-alert-flag.svg";
import YellowAlert from "../../../assets/icons/yellow-alert-flag.svg";
import GreenAlert from "../../../assets/icons/green-alert-flag.svg";

// import MailBox from "../../../assets/icons/feather_mail.svg";
import Loading from "../../common/InPageLoader";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import {
  // fetchFireFlagPatients,
  fetchPatients,
  setPatients,
} from "../../../store/patients/actions";
import InviteModal from "../../common/InviteModal";
import BulkInviteModal from "../../common/BulkInviteModal";
import FireFlagsModal from "../../common/FireFlagsModal";

const RegisteredPatients = () => {
  const user = useSelector((state) => state.app.user.user);
  const [fireFlagsToShow, setFireFlagsToShow] = useState([]);
  const [fireFlagModal, setFireFlagModal] = useState(false);
  const handleFireFlagModal = (patient) => {
    setFireFlagsToShow(patient);
    setFireFlagModal(true);
  };

  const { search } = useSelector((state) => state.router.location);
  let preset = "";
  if (search) {
    preset = split(search, "?", 2)[1];
  }

  const getTaggedFilter = () => {
    if (preset === "RED") return "Red Alert Patients";

    if (preset === "YELLOW") return "Yellow Alert Patients";

    if (preset === "NEW") return "New Patients";

    if (preset === "BHI") return "BHI Eligible";

    return "";
  };

  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState(getTaggedFilter());
  const [sortValue, setSortValue] = useState("Last Active");
  const [orderBy, setOrderBy] = useState("last_active");
  const [orderByDesc, setOrderByDesc] = useState(true);

  const [filterBy, setFilterBy] = useState(
    preset === "RED" || preset === "YELLOW" ? preset : ""
  );
  const [isNew, setIsNew] = useState(preset === "NEW" ? "true" : "");
  const [BHIEli, setBHIEli] = useState(preset === "BHI" ? "true" : "");
  const [BHIenabled, setBHIenabled] = useState(preset === "BHI" ? "true" : "");

  const [showValue, setShowValue] = useState("10");
  const [page, setPage] = useState(1);

  const patients =
    useSelector(
      (state) =>
        // filterBy !== "RED"
        state.patients.patients
      // : state.patients.fireFlagPatients
    ) || null;
  const totalEntries = useSelector(
    (state) =>
      // filterBy !== "RED" ?
      state.patients.count
    //  : state.patients.fireFlagCount
  );
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const success = () => {
    setLoading(false);
  };

  const totalPages = ceil(totalEntries / showValue);
  const pages = [];

  for (let i = 0; i < totalPages; i += 1) {
    pages[i] = i + 1;
  }
  const fetchPatientsData = () => {
    dispatch(
      // filterBy !== "RED"
      fetchPatients(
        success,
        showValue,
        searchTerm,
        orderByDesc ? `-${orderBy}` : orderBy,
        filterBy,
        isNew,
        page,
        BHIEli,
        BHIenabled,
        "All",
        ""
      )
      // :

      // fetchFireFlagPatients(page, success, showValue, searchTerm)
    );
  };

  useEffect(() => {
    setPatients(null);
    setLoading(true);
    fetchPatientsData();
  }, [
    showValue,
    searchTerm,
    orderBy,
    filterBy,
    isNew,
    BHIEli,
    page,
    orderByDesc,
  ]);

  useEffect(() => {
    setPatients(null);
    setLoading(true);
    fetchPatientsData();
  }, []);

  const getFlag = (flag) => {
    if (flag === "RED") return RedAlert;

    if (flag === "YELLOW") return YellowAlert;

    return GreenAlert;
  };

  const getTitle = () => {
    if (!filterBy && !isNew && !BHIEli) return "All Patients";

    if (isNew === "true") return "New Patients";

    if (filterBy === "RED") return "Red Alert Patients";

    if (filterBy === "YELLOW") return "Yellow Alert Patients";

    if (BHIEli === "true") return "BHI Eligible Patients";

    return "All Patients";
  };

  const getEligibilityButton = (elibility, last_view) => {
    if (
      elibility &&
      last_view &&
      moment(last_view).date() === moment().date()
    ) {
      if (isMobileorTablet) return "btn-primary-mobile-last-view";

      return "btn-primary-last-view";
    }

    if (elibility) {
      if (isMobileorTablet) return "btn-primary-mobile";

      return "btn-primary";
    }

    if (isMobileorTablet) return "btn-primary-dark-mobile";

    return "btn-primary-dark";
  };

  const getEligibility = (elibility) => {
    if (elibility) return "Enroll";

    return "View";
  };

  return (
    <>
      {fireFlagModal && (
        <FireFlagsModal
          fireFlagsToShow={fireFlagsToShow}
          display={fireFlagModal}
          setDisplayOut={(value) => setFireFlagModal(value)}
        />
      )}
      {show && (
        <InviteModal display={show} setDisplayOut={(value) => setShow(value)} />
      )}
      {showBulk && (
        <BulkInviteModal
          display={showBulk}
          setDisplayOut={(value) => setShowBulk(value)}
        />
      )}

      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box
            direction="row"
            width={isMobileorTablet ? "100%" : null}
            justify={isMobileorTablet ? "between" : null}
            align="center"
          >
            <Heading level={3} color="#1B3664" alignSelf="center">
              {getTitle()}
            </Heading>
            <Box direction="row" align="center" justify="center" margin="small">
              <Text color="#c4c4c4">Show:</Text>
              <Box width="80px">
                <Select
                  options={["5", "10", "20", "30"]}
                  plain
                  // disabled={filterBy==="RED"}
                  value={showValue}
                  style={{ color: "black" }}
                  size="medium"
                  onChange={({ option }) => {
                    setShowValue(option);
                    setPage(1);
                  }}
                  dropHeight="small"
                  dropProps={{ style: { borderRadius: "10px" } }}
                />
              </Box>
            </Box>
          </Box>
          {user.role === "CLINIC_ADMIN" ? (
            <Menu
              className="menu"
              size="small"
              icon={<FormDown color="white" />}
              color="#395e9d"
              style={{ marginTop: isMobileorTablet ? "16px" : null }}
              label="+ Invite Patients"
              dropAlign={{ top: "bottom" }}
              dropProps={{ className: "dropMenu" }}
              justifyContent="center"
              items={[
                {
                  label: "Add a Patient",
                  onClick: () => {
                    setShow(!show);
                  },
                },
                // {
                //   label: "Invite Multiple Patients",
                //   onClick: () => {
                //     setShowBulk(!showBulk);
                //   },
                // },
                {
                  label: "View Invited Patients",
                  onClick: () => {
                    dispatch(push(`/dashboard/invitedPatients`));
                  },
                },
              ]}
            />
          ) : (
            ""
          )}
        </Box>
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align="center"
          justify="between"
          margin={{ top: "small" }}
        >
          <Box
            round="small"
            align="center"
            justify="between"
            direction="row"
            height="38px"
            width={isMobileorTablet ? "100%" : "320px"}
            className="whiteBack"
            margin={isMobileorTablet ? { bottom: "small" } : null}
          >
            <TextInput
              name="search"
              plain
              type="text"
              className="searchInput"
              placeholder="Search"
              value={searchTerm}
              onChangeCapture={(e) => {
                setSearchTerm(e.target.value);
                setPage(1);
              }}
              required
            />

            <Box margin={{ right: "small" }}>
              <Search color="#395E9D" />
            </Box>
          </Box>
          <Box
            height={isMobileorTablet ? null : "38px"}
            align="center"
            width={isMobileorTablet ? "100%" : null}
            direction={isMobileorTablet ? "column" : "row"}
          >
            <Box
              round="small"
              className="whiteBack"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={[
                  "Red Alert Patients",
                  "Yellow Alert Patients",
                  "New Patients",
                  "BHI Eligible",
                ]}
                plain
                size="small"
                value={filterValue}
                placeholder="Filter:"
                style={{ color: "black" }}
                clear
                onChange={({ option }) => {
                  setFilterValue(option);
                  if (option === "Red Alert Patients") {
                    setFilterBy("RED");
                    setPage(1);
                    setShowValue("10");
                    setSortValue("Flag");
                    setOrderBy("flag");
                    setIsNew("");
                    setBHIEli("");
                    setBHIenabled("");
                  } else if (option === "Yellow Alert Patients") {
                    setFilterBy("YELLOW");
                    setPage(1);
                    setIsNew("");
                    setBHIEli("");
                    setSortValue("Flag");
                    setOrderBy("flag");
                    setBHIenabled("");
                  } else if (option === "New Patients") {
                    setFilterBy("");
                    setIsNew("true");
                    setPage(1);
                    setBHIEli("");
                    setBHIenabled("");
                  } else if (option === "BHI Eligible") {
                    setFilterBy("");
                    setBHIEli("true");
                    setIsNew("");
                    setPage(1);
                    setBHIenabled("true");
                  } else {
                    setFilterBy("");
                    setIsNew("");
                    setPage(1);
                    setBHIEli("");
                    setBHIenabled("");
                  }
                }}
                dropHeight="small"
                dropProps={{ style: { borderRadius: "10px" } }}
              />
            </Box>
            <Box
              className="whiteBack"
              round="small"
              direction="row"
              align="center"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={["First Name", "Last Name", "Flag", "Last Active"]}
                plain
                clear
                size="small"
                dropHeight="small"
                dropProps={{
                  style: { borderRadius: "10px" },
                }}
                value={sortValue}
                onChange={({ option }) => {
                  setSortValue(option);
                  setPage(1);
                  if (option === "First Name") setOrderBy("first_name");
                  else if (option === "Last Name") setOrderBy("last_name");
                  else if (option === "Flag") setOrderBy("flag");
                  else if (option === "Last Active") setOrderBy("last_active");
                  else setOrderBy("");
                }}
                disabled={filterBy === "RED"}
                placeholder="Sort By:"
                style={{ color: "black" }}
              />
              {orderBy ? (
                <Box
                  pad="small"
                  onClick={() => setOrderByDesc(!orderByDesc)}
                  className="noOutline"
                >
                  {orderByDesc ? <Descending /> : <Ascending />}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>

        <Box
          width="100%"
          justify="start"
          align="center"
          margin={{ top: "small" }}
        >
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="evenly"
            gap="medium"
            align="center"
          >
            <Box
              className="large-card"
              align="center"
              justify="center"
              style={{ overflowX: "auto" }}
              gap="small"
              pad="small"
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Table alignSelf="center">
                    <TableHeader>
                      <TableRow>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            NAME
                          </Text>
                        </TableCell>

                        {isMobileorTablet ? null : (
                          <TableCell scope="col">
                            <Text size="small" weight="bold">
                              STATUS
                            </Text>
                          </TableCell>
                        )}
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            FLAG
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            LAST ACTIVE
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            PHYSICIAN
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            CELL NUMBER
                          </Text>
                        </TableCell>
                        {/* <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            MESSAGE
                          </Text>
                        </TableCell> */}
                        <TableCell scope="col" align="center" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {patients &&
                        patients.map((patient) => (
                          <TableRow key={patient.id}>
                            <TableCell scope="row">
                              <Box direction="row" gap="5px" align="center">
                                <Text
                                  weight="bold"
                                  size="small"
                                  color={
                                    patient.fire_flag.length > 0 ? "red" : null
                                  }
                                >
                                  {patient.last_name}, {patient.first_name}
                                </Text>
                                {patient.fire_flag.length > 0 ? (
                                  <Box
                                    className="noOutline"
                                    onClick={() => handleFireFlagModal(patient)}
                                    style={{ cursor: "pointer" }}
                                    pad="xxsmall"
                                    background="#FABFBF"
                                    gap="3px"
                                    justify="center"
                                    direction="row"
                                    align="center"
                                    round="4px"
                                  >
                                    <Alert size="10px" color="#EB5757" />
                                    <Text
                                      size="8px"
                                      weight="bold"
                                      color="#EB5757"
                                    >
                                      URGENT RISK
                                    </Text>
                                  </Box>
                                ) : null}
                              </Box>
                            </TableCell>

                            {isMobileorTablet ? null : (
                              <TableCell>
                                {patient.is_new ? (
                                  <Text
                                    size="xsmall"
                                    weight="bold"
                                    style={{
                                      marginLeft: "10%",
                                      backgroundColor: "#74C9C1",
                                      color: "white",
                                      padding: "3%",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    New
                                  </Text>
                                ) : null}
                              </TableCell>
                            )}
                            <TableCell>
                              <Image width="15px" src={getFlag(patient.flag)} />
                            </TableCell>
                            <TableCell align="center">
                              <Text size="xsmall" weight="bold">
                                {patient.last_active
                                  ? moment(patient.last_active).format(
                                      "MM/DD/YYYY h:mm:ss a"
                                    )
                                  : null}
                              </Text>
                            </TableCell>
                            <TableCell align="center">
                              <Text size="small" weight="bold">
                                {patient ? patient.physician : ""}
                              </Text>
                            </TableCell>
                            <TableCell align="center">
                              <Text size="small" weight="bold">
                                {patient ? patient.cell_number : ""}
                              </Text>
                            </TableCell>

                            {/* <TableCell>
                              <Box justify="center" align="center">
                                <Image src={MailBox} alt="message" />
                              </Box>
                            </TableCell> */}
                            <TableCell>
                              <Box
                                direction="row"
                                justify="center"
                                align="center"
                              >
                                <Button
                                  // eslint-disable-next-line no-nested-ternary
                                  className={
                                    patient.bhi_enabled
                                      ? isMobileorTablet
                                        ? "btn-primary-dark-mobile"
                                        : "btn-primary-dark"
                                      : getEligibilityButton(
                                          patient.bhi_eligible,
                                          patient.last_view
                                        )
                                  }
                                  style={{
                                    width: "50%",
                                    opacity: "0.9",
                                    backgroundColor:
                                      patient.last_view &&
                                      moment(patient.last_view).date() ===
                                        moment().date()
                                        ? "#74C9C1"
                                        : "#395e9d",
                                  }}
                                  color="transparent"
                                  margin={{ horizontal: "small" }}
                                  size="small"
                                  label={
                                    patient.bhi_enabled
                                      ? "View"
                                      : getEligibility(patient.bhi_eligible)
                                  }
                                  onClick={() =>
                                    dispatch(
                                      push(`/dashboard/patient/?${patient.id}`)
                                    )
                                  }
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Box width="100%">
                    <Box height="1px" background="#395E9D" />

                    <Box
                      direction="row"
                      width="100%"
                      justify="between"
                      align="center"
                      pad={{ horizontal: "small" }}
                    >
                      <Box>
                        <Text size="small" color="#395E9D">
                          Showing{" "}
                          {totalEntries === 0
                            ? 0
                            : showValue * page - (showValue - 1)}{" "}
                          to{" "}
                          {page === pages.length
                            ? totalEntries
                            : showValue * page}{" "}
                          of {totalEntries} entries
                        </Text>
                      </Box>
                      <Box direction="row" align="center">
                        <Button
                          size="small"
                          color="#666666"
                          onClick={() => setPage(page - 1)}
                          disabled={page === 1}
                        >
                          <Text size="small">Previous</Text>
                        </Button>
                        {pages.map((vals) => (
                          <Text
                            key={vals}
                            className={
                              vals === page ? "activePage" : "inactivePage"
                            }
                            textAlign="center"
                            size="small"
                            margin="xsmall"
                            color="#82D4E7"
                            onClick={() => setPage(vals)}
                          >
                            {vals}
                          </Text>
                        ))}
                        {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
                        <Button
                          size="small"
                          color="#666666"
                          onClick={() => setPage(page + 1)}
                          disabled={page === pages.length}
                        >
                          <Text size="small">Next</Text>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RegisteredPatients;
