import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const SleepScale = ({ sleep }) => {
  let xAxisData = ["No Data Found"];
  const x2AxisData = [];

  const SeriesData = [];
  const SortedSeries = [];
  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const getOption = () => {
    sleep.forEach((element, index) => {
      xAxisData[index] = element.created;
    });
    const union = [...new Set([...xAxisData, ...x2AxisData])];
    union.sort();

    if (union[0] !== "No Data Found") {
      xAxisData = union;
      xAxisData.forEach((item, index) => {
        xAxisData[index] = moment(item).format("MMM DD");
      });
      xAxisData = [...new Set(xAxisData)];
    }

    sleep.forEach((element, index) => {
      SeriesData[index] = [element.created, element.score];
    });

    SeriesData.sort();
    SeriesData.forEach((element, index) => {
      SortedSeries[index] = [moment(element[0]).format("MMM DD"), element[1]];
    });

    return {
      legend: {
        show: true,
        top: 270,
        data: ["Sleep"],
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },
          magicType: {
            type: ["bar"],
            title: {
              bar: "Show Bar Graph",
            },
          },
          restore: {
            title: "Restore",
          },
          saveAsImage: {
            title: "Save As Image",
            name: `Sleep Check-In ${moment(new Date()).format(
              "MM DD YYYY"
            )} ${firstName} ${lastName}`,
          },
        },
      },

      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${moment(params.data[0]).format("MMM DD")}  <br/> ${
            params.seriesName
          }:  ${params.data[1]}`;
        },
      },

      grid: {
        left: "18%",
      },

      xAxis: {
        offset: 5,
        type: "category",
        data: xAxisData,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: [
        {
          axisLabel: {
            color: (value) => {
              if (value === "Low Risk") return "#4F4F4F";
              if (value === "Medium Risk") return "#EDB219";

              return "#EB7571";
            },
          },
          type: "category",
          name: "",
          data: ["Low Risk", "", "Medium Risk", "", "High Risk"],

          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: "value",
          show: false,
          name: "scores",
          max: 5,
          min: 0,
        },
      ],

      // yAxis: {
      //   type: "value",
      //   max: 4.0,
      //   min: 0,
      //   name: "Assessment Risk Level",
      //   nameLocation: "middle",
      //   nameTextStyle: {
      //     padding: [0, 0, 10, 0],
      //     color: "#999999",
      //   },
      //   axisLabel: {
      //     color: "#999999",
      //   },

      //   axisLine: {
      //     show: false,
      //   },
      //   axisTick: {
      //     show: false,
      //   },
      // },
      series: [
        {
          name: "Sleep",

          color: "#82D4E7",
          data: SortedSeries,
          type: "line",
          symbolSize: 15,
          symbol: "circle",
          lineStyle: {
            width: 5,
          },
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          Sleep Check-in
        </Text>
        <CustomToolTip
          outText="i"
          content="The sleep question is on a 5-point scale"
          subText='from "low risk" (great sleep), to "high risk" (awful sleep)'
        />
      </Box>
      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default SleepScale;
