import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  DateInput,
  Form,
  Select,
} from "grommet";
import { Close, Down } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { editSingleAssessment } from "../../store/patients/actions";

const EditAssessmentModal = ({ display, setDisplayOut }) => {
  const [dropValue, setDropValue] = useState();
  // const [ageRange, setAgeRange] = useState();
  const assessments = useSelector((state) => state.patients.assessment_types);
  const options = [];

  const dispatch = useDispatch();

  const schedule =
    useSelector((state) => state.patients.single_assessment) || null;
  useEffect(() => {
    assessments.forEach((data, index) => {
      options[index] = { value: data.name, key: data.id };
    });
  });

  const [value, setValue] = React.useState(schedule);

  const editSubmit = () => {
    dispatch(editSingleAssessment(value, schedule.id));
    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite"
          position="center"
        >
          <Form
            value={value}
            onSubmit={editSubmit}
            onChange={(nextValue) => setValue(nextValue)}
          >
            <Box gap="medium">
              <Box direction="row" justify="between">
                <Text size="medium" color="#395E9D" weight="bold">
                  Edit Assessment
                </Text>
                <Box>
                  <Close
                    onClick={() => setDisplayOut(false)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                {/* <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Choose Age Range
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      options={["12-17","18-25","26-40","40+"]}
                      dropProps={{
                      style: { borderRadius: "10px" },
                      }}
                      size="small"
                      value={ageRange}
                      onChange={({option})=>setAgeRange(option)}
                      dropHeight="small"
                    />
                  </Box>
                </Box> */}
                <Box width="100%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Choose Assessment Type
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      disabled
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={options}
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      dropHeight="small"
                      size="small"
                      name="assessment"
                    />
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Start Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      size="small"
                      plain
                      name="start_date"
                      format="mm/dd/yyyy"
                      required
                      disabled
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,
                        bounds: [
                          moment(new Date()).format("YYYY-MM-DD"),
                          "2030-12-13",
                        ],

                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                      className="semiInput"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    End Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      size="small"
                      plain
                      name="end_date"
                      format="mm/dd/yyyy"
                      required
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,
                        fill: true,
                        bounds: [
                          moment(new Date()).format("YYYY-MM-DD"),
                          "2030-12-13",
                        ],

                        animate: false,
                        className: "padCal",
                      }}
                      className="semiInput"
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  How Often
                </Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                  direction="row"
                  align="center"
                  justify="between"
                >
                  <Box width="60%">
                    <TextInput
                      size="small"
                      name="gap_days"
                      plain
                      type="numbers"
                      className="semiInput"
                      required
                    />
                  </Box>
                  <Box width="35%" className="inTextSelect" margin="xsmall">
                    <Select
                      size="small"
                      plain
                      options={["Days"]}
                      placeholder="Days/Weeks/Months"
                      icon={<Down size="small" color="#395E9D" />}
                      dropHeight="small"
                      value={dropValue}
                      defaultValue="Days"
                      onChange={({ option }) => {
                        setDropValue(option);
                      }}
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                justify="center"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default EditAssessmentModal;
