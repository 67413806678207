/* eslint-disable */
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Integrations } from "@sentry/tracing";

import { Provider } from "react-redux";
import styled from "styled-components";
import { Grommet } from "grommet";
import { createBrowserHistory } from "history";

import configureStore from "./store";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import initialState from "./store/initialState";
import theme from "./theme";
import * as Sentry from "@sentry/browser";

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-color: #f3f7fe;
`;

export const history = createBrowserHistory();

const store = configureStore(initialState, history);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <Grommet theme={theme}>
      <FlexWrapper>
        <App />
      </FlexWrapper>
    </Grommet>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
