/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import React from "react";
import { Box, Text } from "grommet";

import { useSelector } from "react-redux";
import moment from "moment";
// import { push } from "connected-react-router";
// import moment from "moment";

const ProfileBoxBilling = () => {
  const patient = useSelector((state) => state.patients.patient) || null;
  const patientBHI = useSelector((state) => state.patients.patient_bhi) || null;
  const results = useSelector((state) => state.patients.assessment_results);
  const staff = useSelector((state) => state.patients.staff);

  const getManagedBy = (id) => {
    const thisStaff = staff.find((item) => item.id === id);

    return `${thisStaff.first_name} ${thisStaff.last_name}`;
  };

  const getEligibility = () => {
    if (results) {
      if (patient.bhi_eligible)
        return (
          <Box background="green" width="35%" pad="xsmall" round="xsmall">
            <Text size="small" color="white">
              Eligible
            </Text>
          </Box>
        );
    }
    return (
      <Box background="red" width="35%" pad="xsmall" round="xsmall">
        <Text size="small" color="white">
          Ineligible
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Box
        className="large-card-billing"
        align="start"
        width="100%"
        pad="medium"
        gap="small"
      >
        <Box gap="large" justify="start" width="100%" direction="row">
          <Box>
            <Text
              color="#2BC2F7"
              weight="bold"
              size="small"
              margin={{ right: "xsmall" }}
            >
              Review Date:
            </Text>
            <Text size="small">
              {patient
                ? moment(new Date()).format("MMMM DD, YYYY (hh:mm a)")
                : " "}
            </Text>
          </Box>

          <Box>
            <Text
              color="#2BC2F7"
              weight="bold"
              size="small"
              margin={{ right: "xsmall" }}
            >
              Treating Provider:
            </Text>
            <Text size="small">
              {patientBHI ? getManagedBy(patient.clinician) : " "}
            </Text>
          </Box>
        </Box>
        <Text size="small" weight="bold" color="#333333">
          Patient Details
        </Text>
        <Box gap="small" justify="start" width="100%" direction="row">
          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              Patient Name:
            </Text>
            <Text size="small">
              {patient ? `${patient.first_name} ${patient.last_name}` : " "}
            </Text>
          </Box>

          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              Email:
            </Text>
            <Text size="small">{patient ? patient.email : " "}</Text>
          </Box>
          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              DOB:
            </Text>
            <Text size="small">{patient ? patient.date_of_birth : null}</Text>
          </Box>
          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              Rose Enrollment Date:
            </Text>
            <Text size="small">
              {patient
                ? moment(patient.date_joined).format("MM/DD/YYYY")
                : null}
            </Text>
          </Box>
        </Box>
        <Box gap="small" justify="start" width="100%" direction="row">
          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              Gender:
            </Text>
            <Text size="small">{patient ? patient.gender : null}</Text>
          </Box>
          <Box width="25%">
            <Text
              color="#1B3664"
              size="small"
              margin={{ right: "xsmall", bottom: "xsmall" }}
            >
              BHI Status:
            </Text>
            <Text size="small">
              {patientBHI ? (
                <Box
                  background="#74C9C1"
                  width="35%"
                  pad="xsmall"
                  round="xsmall"
                >
                  <Text size="small" color="white">
                    Enrolled
                  </Text>
                </Box>
              ) : (
                getEligibility()
              )}
            </Text>
          </Box>
          <Box width="25%">
            <Text color="#1B3664" size="small" margin={{ right: "xsmall" }}>
              BH Diagnosis:
            </Text>
            <Text color="#2BC2F7" size="small">
              {patientBHI ? patientBHI.diagnosis : "Patient not Enrolled"}
            </Text>
          </Box>
          <Box width="25%" />
        </Box>
      </Box>
    </>
  );
};
export default ProfileBoxBilling;
