import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import GoBackTItle from "../../../../common/GoBackTItle";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatients } from "../../../../../store/patients/actions";
import InPageLoader from "../../../../common/InPageLoader";

const SingleTableRow = ({ row, color }) => {
  const patients = useSelector((state) => state.patients.patients);

  const getPatientCountbyID = (id) => {
    let count = 0;

    patients.forEach((patient) => {
      if (patient.clinician === id) {
        count++;
      }
    });
    return count;
  };

  return (
    <TableRow key={row.id} style={{ backgroundColor: "white" }}>
      <TableCell scope="row">
        <Text size="small">
          {row.first_name} {row.last_name}
        </Text>
      </TableCell>
      <TableCell scope="row">
        <Text size="small">{row.role}</Text>
      </TableCell>
      <TableCell scope="row">
        <Text size="small">{row.username}</Text>
      </TableCell>
      <TableCell scope="row">
        <Text size="small">
          {row.role === "CLINIC_ADMIN" ? getPatientCountbyID(row.id) : "N/A"}
        </Text>
      </TableCell>
    </TableRow>
  );
};

const MeasureCareMembers = ({ data }) => {
  const patients = useSelector((state) => state.patients.patients);
  const dispatch = useDispatch();
  const success = () => {
    return true;
  };

  useEffect(() => {
    if (!patients)
      dispatch(
        fetchPatients(success, "999", "", "", "", "", 1, "", "", "All", "")
      );
  }, []);

  if (!patients) return <InPageLoader />;

  return (
    <Box pad="medium">
      <Text color="#395E9D" weight="bold">
        <GoBackTItle
          heading="Team Members"
          link="/dashboard/acoexecutiveHome"
        />

        <Box
          style={{ overflowY: "auto", maxHeight: "300px" }}
          margin={{ top: "small" }}
          className="justDropShadow"
        >
          <Table className="blueTable">
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    NAME
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    ROLE
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    USERNAME
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <Text size="small" weight="bold">
                    NO. OF PATIENTS
                  </Text>
                </TableCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {data.map((x) => (
                <SingleTableRow row={x} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Text>
    </Box>
  );
};
export default MeasureCareMembers;
