import { Box } from "grommet";
import React from "react";
import PatientListsComponent from "../../../common/PatientListComponent";

const PatientList = () => {
  return (
    <>
      <Box width="100%" pad="medium">
        <PatientListsComponent />
      </Box>
    </>
  );
};

export default PatientList;
