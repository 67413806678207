/* eslint-disable camelcase */
export const GET_THIS_CARE_GIVER = "caregiver/GET_THIS_CARE_GIVER";
export const SET_THIS_CARE_GIVER = "caregiver/SET_THIS_CARE_GIVER";
export const GET_PENDING_ASSESSMENTS = "caregiver/GET_PENDING_ASSESSMENTS";
export const SET_PENDING_ASSESSMENTS = "caregiver/SET_PENDING_ASSESSMENTS";
export const SUBMIT_ASSESSMENT = "caregiver/SUBMIT_ASSESSMENT";
export const GET_CLINIC_ADMIN_BY_ID = "caregiver/GET_CLINIC_ADMIN_BY_ID";
export const SET_CLINIC_ADMIN_BY_ID = "caregiver/SET_CLINIC_ADMIN_BY_ID";

export const getCareGiver = (id) => ({
  type: GET_THIS_CARE_GIVER,
  payload: { id },
});

export const setCareGiver = (data) => ({
  type: SET_THIS_CARE_GIVER,
  payload: { data },
});

export const getPendingAssessments = () => ({
  type: GET_PENDING_ASSESSMENTS,
  payload: {},
});

export const setPendingAssessments = (data) => ({
  type: SET_PENDING_ASSESSMENTS,
  payload: { data },
});

export const submitAssessment = (answers, callback) => ({
  type: SUBMIT_ASSESSMENT,
  payload: { answers, callback },
});

export const getClinicAdminById = (id) => ({
  type: GET_CLINIC_ADMIN_BY_ID,
  payload: { id },
});

export const setClinicAdmin = (data) => ({
  type: SET_CLINIC_ADMIN_BY_ID,
  payload: { data },
});
