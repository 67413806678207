import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, Text, Box, TextInput } from "grommet";
import { useDispatch } from "react-redux";
import { FormCheckmark, FormNextLink } from "grommet-icons";
import CardSection from "./CardSection";
import { createNotification } from "../../../../store/app/actions";

export default function CardSetupForm({
  intent,
  setMainOut,
  setSuccessShow,
  changeIndex,
}) {
  const [nameOnFile, setNameOnFile] = useState("");
  const [emailOnFile, setEmailOnFile] = useState("");

  const dispatch = useDispatch();
  const stripe = useStripe();

  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardSetup(intent, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: nameOnFile,
          email: emailOnFile,
        },
      },
      expand: ["payment_method"],
    });

    if (result.error) {
      dispatch(createNotification(result.error.message, false));
    } else {
      setMainOut(false);
      setSuccessShow(true);
      changeIndex(0);
    }
  };

  const handleSkip = () => {
    setMainOut(false);
    setSuccessShow(true);
    changeIndex(0);
  };

  return (
    <>
      <Box gap="small">
        <Box width="100%">
          <Text size="xsmall" weight="bold" color="#4F4F4F">
            Name on Card
          </Text>
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            margin={{ top: "6px" }}
            className="customInput"
          >
            <TextInput
              plain
              value={nameOnFile}
              onChange={(e) => setNameOnFile(e.target.value)}
              className="loginInput"
              dropHeight="small"
              size="small"
              name="name"
            />
          </Box>
        </Box>
        <Box width="100%">
          <Text size="xsmall" weight="bold" color="#4F4F4F">
            Card Details
          </Text>
          <Box margin={{ top: "6px" }}>
            <CardSection />
          </Box>
        </Box>
        <Box width="100%">
          <Text size="xsmall" weight="bold" color="#4F4F4F">
            Billing Email Address
          </Text>
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            margin={{ top: "6px" }}
            className="customInput"
          >
            <TextInput
              plain
              value={emailOnFile}
              onChange={(e) => setEmailOnFile(e.target.value)}
              className="loginInput"
              dropHeight="small"
              size="small"
              name="name"
            />
          </Box>
        </Box>
        <Box direction="row" justify="end" gap="small">
          <Button
            fill={false}
            alignSelf="end"
            style={{
              width: "30%",
              padding: "5px 5px",
              backgroundColor: "#8dc779",
              color: "white",
              borderRadius: "8px",
            }}
            plain
            icon={<FormNextLink color="white" />}
            disabled={!stripe}
            label="Skip"
            onClick={() => handleSkip()}
            size="small"
          />
          <Button
            fill={false}
            alignSelf="end"
            style={{
              width: "30%",
              padding: "5px 5px",
              backgroundColor: "#395E9D",
              color: "white",
              borderRadius: "8px",
            }}
            plain
            icon={<FormCheckmark color="white" />}
            disabled={!stripe}
            label="Save"
            onClick={() => handleSubmit()}
            size="small"
          />
        </Box>
      </Box>
    </>
  );
}
