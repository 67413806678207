import React from "react";
import { Box, Text } from "grommet";
import { useSelector } from "react-redux";
import ClinicSelectorAdmin from "../../common/ClinicSelectorAdmin";
import ClinicSelectorStaffClinician from "../../common/ClinicSelectorStaffClinician";
import ClinicSelectorStaffClinic from "../../common/ClinicSelectorStaffClinic";
import { isMobileHook, iTabletHook } from "./../../common/Responsive";

const NoClinicPin = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const user = useSelector((state) => state.app.user.user);

  return (
    <Box
      pad="medium"
      gap="small"
      className="large-card"
      alignSelf="center"
      justify="center"
      width={isMobileorTablet ? "90%" : "60%"}
      // margin="5%"
      margin={isMobileorTablet ? { top: "large" } : "auto"}
    >
      <Text color="#395E9D" weight="bold">
        Get Started
      </Text>
      <Text size="small" weight="bold">
        {user.role === "CLINIC_ADMIN"
          ? "Select a clinic to get started"
          : "Select a clinic and provider account to get started"}
      </Text>
      {user.role === "CLINIC_ADMIN" ? (
        <ClinicSelectorAdmin />
      ) : (
        <ClinicSelectorStaffClinic />
      )}
      {user.role === "CLINICAL_STAFF" ? <ClinicSelectorStaffClinician /> : null}
    </Box>
  );
};
export default NoClinicPin;
