import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getAllClinics, getGroups } from "../../store/sales/actions";
import { useHistory } from "react-router";
import {
  getRevenueByMonth,
  getRevenueByMonthCollected,
  getRevenueByMonthOutstanding,
  getTotalRevenuebyMonthCollected,
  getTotalRevenuebyMonthOutstanding,
} from "../../util/globalFunctions";
import { toInteger } from "lodash";

const AllRevenue = ({ invoices, months }) => {
  const [loading, setLoading] = useState(false);
  const groups = useSelector((state) => state.sales.clinic_groups);
  const dispatch = useDispatch();
  const { pathname } = useSelector((state) => state.router.location);
  const allClinics = useSelector((state) => state.sales.all_clinics);

  const paginatedResults = [];

  let counter = 1;

  const pageSie = pathname === "/dashboard/revenue-reports" ? "5" : "9999";

  let toPage = 0;

  if (groups)
    for (let i = 0; i < groups.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      paginatedResults[toPage].push(groups[i]);
      counter += 1;

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }

  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
    }
    if (!allClinics) dispatch(getAllClinics(success, ""));
  }, []);

  function getFiltered() {
    const toPlay = allClinics.filter((clinic) =>
      groups.find((x) => x.id === clinic.clinic_group)
    );

    return toPlay;
  }

  const history = useHistory();

  const processInvoicedTotals = () => {
    let total = 0;
    months.forEach((month) => {
      total =
        total + toInteger(getRevenueByMonth(invoices, [month], getFiltered()));
    });
    return total;
  };

  const processCollectedTotals = () => {
    let total = 0;
    months.forEach((month) => {
      total =
        total + getTotalRevenuebyMonthCollected(invoices, month, getFiltered());
    });
    return total;
  };

  const processOutstandingTotals = () => {
    let total = 0;
    months.forEach((month) => {
      total =
        total +
        getTotalRevenuebyMonthOutstanding(invoices, month, getFiltered());
    });
    return total;
  };

  if (loading || !groups || !allClinics)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Box width="100%" justify="between" direction="row" align="between">
        <Text color="#1D3763" weight="bold">
          Revenue by Month
        </Text>
        {pathname === "/dashboard/revenue-reports" ? (
          <Box
            className="noOutline"
            onClick={() => history.push("/dashboard/revenue-detail")}
          >
            <Text
              size="small"
              style={{ textDecoration: "underline" }}
              color="#395E9D"
            >
              View All Groups
            </Text>
          </Box>
        ) : (
          <Box />
        )}
      </Box>
      <Box className="large-card" style={{ overflowX: "auto" }} pad="small">
        <Table className="blue_header">
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Month
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Invoiced
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Collected
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Outstanding
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {months.map((month) => (
              <TableRow>
                <TableCell scope="col" align="center">
                  <Text size="small" weight="bold">
                    {month}
                  </Text>
                </TableCell>
                <TableCell align="center">
                  <Text size="small">
                    ${getRevenueByMonth(invoices, [month], getFiltered())}
                  </Text>
                </TableCell>
                <TableCell align="center">
                  <Text size="small">
                    $
                    {getRevenueByMonthCollected(
                      invoices,
                      [month],
                      getFiltered()
                    )}
                  </Text>
                </TableCell>
                <TableCell align="center">
                  <Text size="small">
                    $
                    {getRevenueByMonthOutstanding(
                      invoices,
                      [month],
                      getFiltered()
                    )}
                  </Text>
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="center">
                <Text weight="bold" size="small">
                  Total
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text size="small" weight="bold">
                  ${processInvoicedTotals()}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text size="small" weight="bold">
                  ${processCollectedTotals()}
                </Text>
              </TableCell>
              <TableCell align="center">
                <Text size="small" weight="bold">
                  ${processOutstandingTotals()}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default AllRevenue;
