import React from "react";
import { Box, Text, Layer, Button } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { deleteAssessmentSchedule } from "../../store/patients/actions";

const DeleteAssessmentModal = ({ display, setDisplayOut, deleteID }) => {
  const { search } = useSelector((state) => state.router.location);
  const patientId = split(search, "?", 2)[1];

  const dispatch = useDispatch();

  const resetFlag = () => {
    dispatch(deleteAssessmentSchedule(deleteID, patientId));
    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to delete the assessment?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => resetFlag()}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setDisplayOut(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default DeleteAssessmentModal;
