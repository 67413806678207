/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  // Collapsible,
  Layer,
} from "grommet";
// import { Up, Down } from "grommet-icons";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/light-logo.png";
import Home from "../../../assets/icons/home.svg";
import Patients from "../../../assets/icons/patients.svg";
// import Sessions from "../../../assets/icons/sessions.svg";
import Messages from "../../../assets/icons/messages.svg";
// import Timesheet from "../../../assets/icons/timesheet.svg";
import Settings from "../../../assets/icons/settings.svg";
import Help from "../../../assets/icons/help.svg";
// import FAQ from "../../../assets/icons/faq.svg";
// import Resources from "../../../assets/icons/resources.svg";
import Logout from "../../../assets/logout.png";

import { logout } from "../../../store/app/actions";
import { getUnreadMessagesCount } from "../../../store/patients/actions";
import ClinicSelector from "../../common/ClinicSelector";
const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.global.colors.light};
  width: 100%;
  text-decoration: none !important;
  border-left: 5px solid transparent !important;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  position: relative;

  &:not(.active) * {
    cursor: pointer;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    bottom: 8px;
    width: 5px;
    background-color: ${(props) => props.theme.global.colors.brandBlue};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transform: translateX(-12px);
    transition: transform 150ms ease-in-out;
  }

  &:hover {
    /* background-color: #26406C; */
    width: 100%;
    color: ${(props) => props.theme.global.colors.light};
    text-decoration: none !important;
    cursor: default;
    transform: translateX(2px);

    &:before {
      transform: translateX(-7px);
    }
  }

  &.active {
    width: 100%;
    background-color: ${(props) => props.theme.global.colors.active};
    color: ${(props) => props.theme.global.colors.sideNav};
    text-decoration: none !important;
    font-weight: bold !important;
  }
`;

const Sidebar = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [open, setOpen] = useState(false);
  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const clinic = useSelector((state) => state.app.user.user.clinic);

  const user = useSelector((state) => state.app.user.user);
  const [unreadMessages, setUnreadMessages] = useState();

  const success = (results) => {
    const thisClinic = clinicPins.find(
      (x) =>
        x.pin === Number(window.localStorage.getItem("selectedDropdownPin"))
    );

    setUnreadMessages(results[thisClinic.pin].toString());
  };

  if (
    user.role === "TWS_COORDINATOR" &&
    !(
      window.localStorage.getItem("selectedDropdownPin") ||
      window.localStorage.getItem("selectedDropdownClinic") ||
      window.localStorage.getItem("selectedDropdownClinician")
    )
  ) {
    window.localStorage.setItem("selectedDropdownClinic", user.clinic.id);
    window.localStorage.setItem("selectedDropdownClinician", user.id);
    window.localStorage.setItem("selectedDropdownPin", clinic.pin);
  }

  useEffect(() => {
    const messagesInterval = setInterval(() => {
      if (
        window.localStorage.getItem("selectedDropdownPin") &&
        user.role !== "TWS_COORDINATOR"
      )
        dispatch(getUnreadMessagesCount(success));
    }, 2000);

    return () => {
      clearInterval(messagesInterval);
    };
  }, []);

  return (
    <Box
      width="270px"
      height="100vh"
      style={{
        position: "fixed",
        zIndex: 15,
      }}
      background="brand"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box
        width="100%"
        height="20%"
        align="center"
        justify="center"
        style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}
      >
        <Box>
          <Image src={Logo} alt="logo" />
          <Text margin={{ top: "12px" }} alignSelf="end">
            HEALTH
          </Text>
        </Box>
      </Box>

      <Box width="100%" height="15%" align="center" justify="center">
        {user.role === "CLINIC_ADMIN" || user.role === "CLINICAL_STAFF" ? (
          <>
            <Text
              margin={{ top: "4px", bottom: "10px" }}
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                fontFamily: "Courier New",
                lineHeight: "28px",
                color: "#74C9C1",
              }}
            >
              {window.localStorage.getItem("selectedDropdownPin")}
            </Text>
            <ClinicSelector />
          </>
        ) : null}
        <Text
          margin={{ top: "4px" }}
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            fontFamily: "Courier New",
            lineHeight: "28px",
            color: "#74C9C1",
          }}
        >
          {clinic &&
          (user.role === "TWS_COORDINATOR" ||
            user.role === "TWS_CONCIERGE" ||
            user.role === "TWS_CASE_MANAGER")
            ? `${clinic.name}`
            : ""}
        </Text>
      </Box>
      <Box
        width="100%"
        height="65%"
        style={{ overflow: "hidden" }}
        justify="between"
        margin={{ top: "small" }}
      >
        <Box>
          <StyledLink exact to="/dashboard/home">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Home} alt="home" />
              <Text size="16px" style={{ paddingLeft: "32px" }}>
                Home
              </Text>
            </Box>
          </StyledLink>
          <StyledLink exact to="/dashboard/patients">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Patients} alt="patients" />
              <Text size="16px" style={{ paddingLeft: "32px" }}>
                All Patients
              </Text>
            </Box>
          </StyledLink>

          <StyledLink exact to="/dashboard/resources">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Help} alt="help" />
              <Text size="16px" style={{ paddingLeft: "32px" }}>
                Resources
              </Text>
            </Box>
          </StyledLink>
          {/* <StyledLink exact to="/dashboard/sessions">
            <Box
              pad={{ vertical:  open ? "12px" :"16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Sessions} alt="sessions" />
              <Text size="16px" style={{ paddingLeft: "32px" }}>
                Sessions
              </Text>
            </Box>
          </StyledLink> */}

          {user.clinic.rose_chat && user.role !== "TWS_COORDINATOR" ? (
            <StyledLink exact to="/dashboard/messages">
              <Box
                pad={{ vertical: "16px", horizontal: "medium" }}
                direction="row"
                align="center"
              >
                <Image src={Messages} alt="messages" />
                <Box style={{ fontSize: "16px", paddingLeft: "32px" }}>
                  {unreadMessages && unreadMessages > 0 ? (
                    <Box direction="row" gap="small" align="center">
                      <Text style={{ fontSize: "16px" }}>Chat</Text>
                      <Box className="unreadBadge">{unreadMessages}</Box>
                    </Box>
                  ) : (
                    "Chat"
                  )}
                </Box>
              </Box>
            </StyledLink>
          ) : null}
          <StyledLink exact to="/dashboard/settings">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Settings} alt="settings" />
              <Text size="16px" style={{ paddingLeft: "32px" }}>
                Settings
              </Text>
            </Box>
          </StyledLink>

          {/* <Box
            pad={{ vertical: "medium", horizontal: "medium" }}
            direction="row"
            width="100%"
            align="center"
            justify="between"
            onClick={() => setOpen(!open)}
            style={{ boxShadow: "none" }}
          >
            <Box direction="row" pad={{ left: "5px" }}>
              <Image src={Help} alt="help" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Help
              </Text>
            </Box>
            {open ? <Up size="18px" /> : <Down size="18px" />}
          </Box>
          <Collapsible open={open}>
            <StyledLink exact to="/dashboard/faq">
              <Box
                gap="xsmall"
                pad={{ vertical:  open ? "12px" :"16px", horizontal: "medium" }}
                direction="row"
                align="center"
              >
                <Image src={FAQ} alt="FAQs" />
                <Text size="small" style={{ paddingLeft: "32px" }}>
                  FAQs
                </Text>
              </Box>
            </StyledLink>
            <StyledLink exact to="/dashboard/resources">
              <Box
                gap="xsmall"
                pad={{ vertical:  open ? "12px" :"16px", horizontal: "medium" }}
                direction="row"
                align="center"
              >
                <Image src={Resources} alt="resources" />
                <Text size="small" style={{ paddingLeft: "32px" }}>
                  Resources
                </Text>
              </Box>
            </StyledLink>
          </Collapsible> */}
        </Box>
        <Box width="100%" margin={{ bottom: "medium", top: "xlarge" }}>
          <Box
            className="logoutButton"
            direction="row"
            align="center"
            justify="center"
          >
            <Image src={Logout} alt="resources" height="18px" width="22px" />
            <Button
              size="small"
              onClick={() => {
                setShow(true);
              }}
              plain
              color="secondaryRed"
              label="Logout"
              style={{
                padding: "0 20px",
                textAlign: "center",
                fontSize: "medium",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Sidebar;
