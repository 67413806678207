import { Accordion, AccordionPanel, Box, Tab, Tabs, Text } from "grommet";
import React, { useState } from "react";

const FAQs = () => {
  const [cuurentIndex, setCurrentIndex] = useState(0);

  const onActive = (index) => {
    setCurrentIndex(index);
  };
  return (
    <Box>
      <Box className="large-card" pad="medium">
        <Text color="#395E9D" weight="bold" size="large">
          Frequently Asked Questions
        </Text>
        <Tabs
          activeIndex={cuurentIndex}
          onActive={onActive}
          style={{ width: "100%" }}
        >
          <Tab
            label="Provider FAQs"
            className={cuurentIndex === 0 ? "tab3-active" : "tab3"}
          >
            <Accordion gap="small">
              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Registration/Onboarding
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What is Rose? Is Rose right for me?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          The Rose App is a patient-facing application that
                          allows patients to take assigned assessments, log
                          their moods, journal, interact with their provider and
                          access relevant content suggested by Rose and assigned
                          by their provider. Using a provider-facing, web-based
                          dashboard, Rose synthesizes the patient entered data
                          into a summary chart for providers. Rose allows you to
                          get key insights on your patients in between visits,
                          allowing you to maximize the time spent in session.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How can Rose help my practice?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose allows you to connect with patients in-between
                          visits. The time you and your patients use on the
                          platform can be eligible for BHI (Behavioral Health
                          Integration) and/or RPM (Remote Patient Monitoring)
                          reimbursements. This time can be billed to insurance
                          companies and may generate additional revenue for your
                          practice.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How does Rose’s semantic analysis work and how does
                          that help me in my practice?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          In addition to the daily mood tracking and
                          standardized assessments, Rose helps bridge the time
                          between patient visits by allowing patients to journal
                          in-app. Rose analyzes these journal entries through
                          Natural Language Processing, or NPS, and displays the
                          entry sentiment on your provider dashboard for your
                          review. This data allows you to review your patient’s
                          progress before each session informing you of key
                          items to cover in your in-person or telemedicine
                          visits.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What should I expect when I sign up?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Once you sign up for Rose you will receive a welcome
                          email with a link for you to create a username and
                          password. Once you log into your account for the first
                          time, you will be guided through a 5 minute tutorial
                          of Rose, highlighting the main features of the
                          platform. Next, you will begin inviting patients using
                          your unique provider code. You can invite patients by
                          emailing them directly from the platform and can track
                          when they have downloaded and created an account.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Can I speak to another clinician about their
                          experience with Rose?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Yes, please contact help@rosehealth.com for more
                          information.
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>
              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Patient experience
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How do my patients sign-up?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Invite patients through the provider dashboard. Login
                          to Rose using your username and password. On the right
                          hand side, click on &#34;invite.&#34; Add in your
                          patient’s name, mobile phone number and/or email, you
                          can add in patients in bulk using the template
                          provided. Rose will send your patient a link to
                          download the app. Once they have downloaded the app,
                          they will enter your unique 4-digit code to connect to
                          you.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What should my patient expect?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Upon downloading the app and creating an account,
                          patients will be prompted to take 3 assessments, the
                          PHQ-8 for depression, GAD-7 for anxiety, and a sleep
                          assessment along with a brief introduction to Rose.
                          The patient and provider should have a discussion of
                          expectations of using the Rose App. We recommend the
                          patient regularly log their mood and journal at least
                          a few times each week. Even better, we encourage all
                          patients to use the Rose app every day to get the most
                          benefit.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How does Rose help my patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose helps patients by providing a user-friendly
                          platform to guide their mental health care journey.
                          Rose encourages self-care techniques and reflection of
                          their progress. Rose also strengthens the rapport
                          between you and your patients because the more Rose
                          learns about your patients, the better you will have
                          an accurate clinical picture to provide a higher level
                          of quality care.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Do I have to teach my patients how to use the Rose App
                          or does Rose provide an overview to my patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose will guide new patient users on how to use the
                          app and is designed to be intuitive and accessible.
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>
              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Privacy
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How is the privacy of my patients and my practice
                          protected within Rose?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose is HIPAA-compliant both on the provider dashboard
                          and patient app. All information is saved safely and
                          securely in our AWS cloud services.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Will patients know when providers view their journals
                          or messages?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          No, patients will not know whether or not their
                          provider has reviewed their Rose profile, including
                          journals, messages, and assessments.
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>

              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Billing
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How do I pay my subscription?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Subscriptions are billed monthly via a credit card you
                          put on file. You can contact us for additional
                          information or help entering your credit card
                          information.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          I have questions about my subscription, who can I talk
                          to?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Please reach out to help@rosehealth.com for more
                          information.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What is BHI?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          BHI (Behavioral Health Integration) is a type of care
                          management service. In recent years, CMS updated the
                          Medicare Physician Fee Schedule (MPFS) policies to
                          improve payment for care management services. Working
                          with the CPT Editorial Panel and other clinicians, CMS
                          expanded the suite of codes describing care management
                          services. New codes describe services that involve
                          direct patient contact (that is in-person,
                          face-to-face services) or that do not involve direct
                          patient contact; that represent a single encounter, a
                          monthly service, or both; that are timed services;
                          that address specific conditions; and that represent
                          the work of the billing practitioner, auxiliary
                          personnel (specifically, clinical staff), or both.
                          Learn more{" "}
                          <a href="https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/Downloads/BehavioralHealthIntegration.pdf">
                            Here
                          </a>
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How can I bill for BHI?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          CPT code 99484 is used to bill monthly services
                          delivered using BHI models. Behavioral health care
                          management needs to be at least 20 minutes of clinical
                          staff (physican or other qualified medical
                          professional) time.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          When is a patient eligible for BHI?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          When they score a 5 or higher on the PHQ8 or GAD7 on
                          the Rose platform.
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>
              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Provider-facing dashboard
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Where do I find my unique PIN code?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Log into Rose by entering your username and password.
                          In the upper left corner, your 4-digit verification
                          code, or pin, will appear in red.{" "}
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Can I print out a general report on each patient? Can
                          I print out an overview of all my patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Login into your Rose Provider account. Click on
                          Reports next to the patient you would like to view.
                          Click on the three bar menu in the top right corner of
                          the chart you would like to print. You can save as a
                          PNG file which will allow you to print from your
                          computer.{" "}
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Can I change the frequency & when my patients are
                          scheduled to take the assessments?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Yes, click on each patient’s clinical profile on the
                          Rose dashboard. From the dashboard, click on
                          assessments and edit the next date the assessment will
                          be administered.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Can Rose integrate with my EMR?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          You can import all the Rose reports as PDF into your
                          EMR.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How is the content for Rose curated? What insights
                          does it provide for patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Our content is created and curated through a team of
                          mental health specialists as well as key partnerships
                          with external organizations.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What is the Rose Paths module and how does it benefit
                          my patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose Paths are organized content modules that walk
                          your patient through curated content. This allows for
                          an organized review of recommended content based on
                          symptoms (e.g., anxiety, depression, trauma)
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>
              <AccordionPanel
                style={{ border: "1px solid #395E9D", borderRadius: "4px" }}
                label={
                  <Text size="small" weight="bold">
                    Technical Troubleshooting
                  </Text>
                }
                pad="small"
              >
                <Box margin={{ top: "xsmall" }}>
                  <Accordion>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How do I change my password?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          To change your password, you can login and click on
                          the ‘Settings’ tab. From ‘Settings’ you will be able
                          change your email address and password on the far
                          right of the screen. Clicking ‘Change’ will apply the
                          changes.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What is the turn-around time if I bring an issue to
                          the Rose team?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          We do our best to get back to you as soon as possible
                          and you can expect to hear from us within 24 hours via
                          phone or email.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          I’d like to report a problem with the app and/or
                          clinical dashboard. Who do I contact?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Please contact Rose for any assistance with the
                          platform by emailing us at help@rosehealth.com or
                          calling (833) 400-7673.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          Can Rose integrate with my EMR?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          You can import all the Rose reports as PDF into your
                          EMR.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          How is the content for Rose curated? What insights
                          does it provide for patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Our content is created and curated through a team of
                          mental health specialists as well as key partnerships
                          with external organizations.
                        </Text>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel
                      style={{ borderBottom: "0.5px solid #1B366450" }}
                      label={
                        <Text size="small" weight="bold">
                          What is the Rose Paths module and how does it benefit
                          my patients?
                        </Text>
                      }
                      pad="xsmall"
                    >
                      <Box pad="small">
                        <Text size="small">
                          Rose Paths are organized content modules that walk
                          your patient through curated content. This allows for
                          an organized review of recommended content based on
                          symptoms (e.g., anxiety, depression, trauma)
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
              </AccordionPanel>
            </Accordion>
          </Tab>
          <Tab
            label="Patient FAQs"
            className={cuurentIndex === 1 ? "tab3-active" : "tab3"}
          >
            <Box margin={{ top: "xsmall" }}>
              <Accordion>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      What is Rose?
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      Rose is a mental health application allowing users to log
                      their moods, journal, contact their provider and read
                      relevant content assigned by their provider. Rose allows
                      patients to update their providers on their daily progress
                      to allow in-person sessions to focus on growth, rather
                      than catching up on day-to-day happenings that can be
                      logged in the app and reviewed before sessions.
                    </Text>
                  </Box>
                </AccordionPanel>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      How does Rose help me?
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      Rose helps patients by providing a user-friendly platform
                      to take part in their mental health care journey by means
                      of encouraging self-care techniques and reflection of
                      their progress. Rose also strengthens the rapport between
                      you and your provider because the more Rose learns about
                      you the better your provider will have an authentic
                      clinical picture to provide a higher level of quality care
                      focused mental growth{" "}
                    </Text>
                  </Box>
                </AccordionPanel>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      What is the PIN number?
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      A unique Pin number is given by providers to their
                      patients in order to connect your mobile app account to
                      their provider dashboard.{" "}
                    </Text>
                  </Box>
                </AccordionPanel>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      What are the key features that I can use within the Rose
                      App?
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      The key features of Rose are: -Mood tracking & Journaling
                      to increase self-awareness of you mental health & to
                      communicate to your provider directly -Insights from their
                      recent mood assessments -Access to relevant articles,
                      video and audio clips -Ability to track talking points for
                      upcoming sessions -Access to National Help hotlines to
                      speak to someone immediately.{" "}
                    </Text>
                  </Box>
                </AccordionPanel>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      What does my provider have access to? (ex. Can they see my
                      talking points?)
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      Your provider can see your talking points and can use
                      these to prepare for your next session. Additionally, they
                      will get summary data on your mood, assessments, and
                      common themes from your journal entries.{" "}
                    </Text>
                  </Box>
                </AccordionPanel>
                <AccordionPanel
                  style={{ borderBottom: "0.5px solid #1B366450" }}
                  label={
                    <Text size="small" weight="bold">
                      How is my privacy protected?
                    </Text>
                  }
                  pad="xsmall"
                >
                  <Box pad="small">
                    <Text size="small">
                      Rose is HIPAA-compliant both on the provider dashboard and
                      patient app. All information is saved safely and securely
                      in our AWS cloud services.{" "}
                    </Text>
                  </Box>
                </AccordionPanel>
              </Accordion>
            </Box>
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
};

export default FAQs;
