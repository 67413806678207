import React from "react";
import { Box, Heading, Image, Text } from "grommet";
import { Helmet } from "react-helmet";
import { LinkPrevious } from "grommet-icons";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import Logo from "../../../assets/login-back-full.png";
import { logout } from "../../../store/app/actions";
import { useSelector } from "react-redux";

const PageNotFound = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user.user);
  return (
    <>
      <Box height="100%" width="100%" align="center" justify="center">
        <Helmet>
          <title>404 - Page Not Found</title>
        </Helmet>

        <Box width="100%" justify="center" align="center">
          <Image src={Logo} style={{ maxHeight: "600px", opacity: "40%" }} />

          <Box
            pad="small"
            align="center"
            width="medium"
            style={{ position: "absolute" }}
          >
            <Heading
              color="brand"
              textAlign="center"
              style={{ fontSize: "4rem", fontWeight: "bold" }}
              level={1}
            >
              404
            </Heading>
            <Heading textAlign="center" level={2}>
              Wrong Place
            </Heading>
            <Heading textAlign="center" level={3}>
              Not sure how you got here but this place doesn&apos;t exist
              <br />
            </Heading>
            <Box
              direction="row"
              className="noOutline"
              onClick={() => {
                dispatch(push(`/`));
              }}
              gap="small"
              margin={{ top: "small" }}
              background="brand"
              align="center"
              justify="center"
              round="small"
              width="100%"
              style={{ cursor: "pointer" }}
              pad="small"
            >
              <LinkPrevious color="white" />
              <Text color="white">Back to Rose</Text>
            </Box>
            {user && (
              <Box
                direction="row"
                className="noOutline"
                onClick={() => {
                  dispatch(logout());
                }}
                gap="small"
                margin={{ top: "small" }}
                background="brand"
                align="center"
                justify="center"
                round="small"
                width="100%"
                style={{ cursor: "pointer" }}
                pad="small"
              >
                <Text color="white">Logout</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default PageNotFound;
