import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  Form,
  MaskedInput,
  Select,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { Close } from "grommet-icons";
import DatePicker from "react-datepicker";
import { getStaff, sendInvite } from "../../store/patients/actions";
import { createNotification } from "../../store/app/actions";
import InPageLoader from "./InPageLoader";

const InviteModalStaff = ({ display, setDisplayOut }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [NAD, setNAD] = useState();
  const clinic = useSelector((state) => state.app.user.user.clinic.id);
  const user = useSelector((state) => state.app.user.user);

  const [loading, setLoading] = useState(false);

  const [clinician, setClinician] = useState();
  const staff = useSelector((state) => state.patients.staff);

  const options = [];

  const success = () => {
    setLoading(false);
  };

  if (staff)
    staff.forEach((data) => {
      if (data.role === "CLINIC_ADMIN")
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
    });

  useEffect(() => {
    if (!staff) {
      setLoading(true);
      dispatch(getStaff(success));
    }
  });

  const inviteError = (e) => {
    if (e) {
      const customError = e.response.data;
      const val = Object.values(customError);
      dispatch(createNotification(val[0], false));
    }
  };

  const invitePatient = (form) => {
    if (!value.email && !value.number) {
      dispatch(createNotification("Please enter either phone or email", false));
      return;
    }

    dispatch(sendInvite(form, inviteError, NAD, clinic, clinician, user));
    setDisplayOut(false);
  };

  if (loading)
    return (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-invite"
        position="center"
      >
        <InPageLoader />
      </Layer>
    );

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite"
          position="center"
        >
          <Text>{loading}</Text>
          <Form
            onSubmit={invitePatient}
            value={value}
            onChange={(nextValue) => setValue(nextValue)}
          >
            <Box gap="medium">
              <Box direction="row" justify="between">
                <Text size="medium" color="#395E9D" weight="bold">
                  Invite New Patient
                </Text>
                <Box>
                  <Close
                    onClick={() => setDisplayOut(false)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    First Name
                    <Text size="xsmall" margin={{ left: "1px" }} color="red">
                      *
                    </Text>
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="firstName"
                      plain
                      type="text"
                      className="semiInput"
                      required
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Last Name
                    <Text size="xsmall" margin={{ left: "1px" }} color="red">
                      *
                    </Text>
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="lastName"
                      plain
                      type="text"
                      className="semiInput"
                      required
                    />
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Phone Number
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <MaskedInput
                      size="small"
                      mask={[
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: "XXX",
                        },
                        { fixed: " " },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: "XXX",
                        },
                        { fixed: " " },
                        {
                          length: 4,
                          regexp: /^[0-9]{1,4}$/,
                          placeholder: "XXXX",
                        },
                      ]}
                      name="number"
                      plain
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Email Address
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="email"
                      plain
                      placeholder="user@example.com"
                      type="email"
                      className="semiInput"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Next Appointment Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInputDatePick"
                  >
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      isClearable
                      showYearDropdown
                      selected={NAD}
                      minDate={new Date()}
                      popperClassName="modalPopper"
                      className="semiInput"
                      onChange={(element) => setNAD(element)}
                    />
                  </Box>
                </Box>

                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Insurance
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <MaskedInput
                      size="small"
                      mask={[
                        {
                          length: 14,
                          regexp: /^[a-zA-Z0-9]{1,14}$/,
                          placeholder: "14 Digit Insurance Code",
                        },
                      ]}
                      name="insurance"
                      plain
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  Choose Clinician
                </Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                >
                  <Select
                    plain
                    value={clinician}
                    name="clinician"
                    labelKey="value"
                    valueKey={{ key: "key", reduce: true }}
                    options={options}
                    dropProps={{
                      style: { borderRadius: "10px" },
                    }}
                    onChange={({ option }) => setClinician(option.key)}
                    dropHeight="small"
                    size="small"
                    required
                  />
                </Box>
              </Box>
              <Box
                justify="center"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  disabled={!clinician}
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default InviteModalStaff;
