import { Box, Text, Select } from "grommet";
import { AddCircle, CircleInformation, FormTrash } from "grommet-icons";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "../../store/app/actions";
import {
  createNewDefaultSchedule,
  deleteDefaultScheduleAssessment,
  getAssessments,
  getDefaultScheduleAssessments,
} from "../../store/patients/actions";
import InPageLoader from "./InPageLoader";
import ViewAssessmentDetailsModal from "./ViewAssessmentDetailsModal";
import { push } from "connected-react-router";

const ChangeDefaultAssessments = () => {
  const options = [];
  const [loading, setLoading] = useState();
  const [selectedAssessment, setSelectedAssessment] = useState();
  const [selectedGapDays, setSelectedGapDays] = useState();
  const [viewAssessmentDetails, setViewAssessmentDetails] = useState(false);
  const [viewAssessmentDetailsBottom, setViewAssessmentDetailsBottom] =
    useState(false);

  const [assessmentToView, setAssessmentToView] = useState();

  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const thisClinicDefaults = useSelector(
    (state) => state.patients.default_assessments
  );

  const assessments = useSelector((state) => state.patients.assessment_types);

  const dispatch = useDispatch();
  const onSuccess = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!window.localStorage.getItem("selectedDropdownPin"))
      dispatch(push("/dashboard/selectClinicPin"));
    else {
      setLoading(true);
      const thisClinicPinId = clinicPins.find(
        (x) =>
          x.pin.toString() ===
          window.localStorage.getItem("selectedDropdownPin")
      ).id;
      dispatch(getAssessments(onSuccess));
      dispatch(getDefaultScheduleAssessments(thisClinicPinId));
    }
  }, []);

  useEffect(() => {
    if (assessments) {
      assessments.forEach((data, index) => {
        options[index] = { value: data.name, key: data.id };
      });
      options.sort((a, b) => a.value.localeCompare(b.value));
    }
  });

  const onSuccessSave = () => {
    const thisClinicPinId = clinicPins.find(
      (x) =>
        x.pin.toString() === window.localStorage.getItem("selectedDropdownPin")
    ).id;
    dispatch(createNotification("Assessment Added", true));

    dispatch(getDefaultScheduleAssessments(thisClinicPinId));
  };

  const saveNewSchedule = () => {
    const thisClinicPinId = clinicPins.find(
      (x) =>
        x.pin.toString() === window.localStorage.getItem("selectedDropdownPin")
    ).id;

    if (!thisClinicPinId || !selectedAssessment || !selectedGapDays) {
      dispatch(createNotification("Invalid Selection!", false));
      return;
    }

    dispatch(
      createNewDefaultSchedule(
        thisClinicPinId,
        selectedAssessment,
        selectedGapDays,
        onSuccessSave
      )
    );
    setSelectedAssessment({});
    setSelectedGapDays(null);
  };

  const onSuccessDelete = () => {
    const thisClinicPinId = clinicPins.find(
      (x) =>
        x.pin.toString() === window.localStorage.getItem("selectedDropdownPin")
    ).id;
    dispatch(createNotification("Assessment Deleted", true));

    dispatch(getDefaultScheduleAssessments(thisClinicPinId));
  };

  const deleteDefaultSchedule = (idToDelete) => {
    dispatch(deleteDefaultScheduleAssessment(idToDelete, onSuccessDelete));
  };

  if (loading || !thisClinicDefaults) return <InPageLoader />;

  return (
    <>
      {viewAssessmentDetails && (
        <ViewAssessmentDetailsModal
          assessments={assessments}
          toShow={assessmentToView}
          display={viewAssessmentDetails}
          setDisplayOut={setViewAssessmentDetails}
        />
      )}
      {viewAssessmentDetailsBottom && (
        <ViewAssessmentDetailsModal
          assessments={assessments}
          toShow={selectedAssessment}
          display={viewAssessmentDetailsBottom}
          setDisplayOut={setViewAssessmentDetailsBottom}
        />
      )}
      <Box className="large-card" pad="medium">
        <Text
          color="#395E9D"
          weight="bold"
          size="large"
          margin={{ bottom: "medium" }}
        >
          Default Assessments
        </Text>
        <Text size="small">
          Set up the assessments that will be scheduled for all of your new Rose
          patients by default. You can then customize these to individual
          patients on the patient's profile
        </Text>
        {thisClinicDefaults.map((x, index) => (
          <Box gap="small" margin="xsmall" direction="row" align="center">
            <Box
              pad="xsmall"
              width="5%"
              justify="center"
              align="center"
              round="10px"
            >
              <Text size="small">{index + 1}</Text>
            </Box>
            <Box
              pad="xsmall"
              width="50%"
              direction="row"
              gap="small"
              align="center"
              style={{ border: "2px solid #C4C4C480" }}
              round="10px"
            >
              <Text size="small">Assessment: </Text>
              <Box className="gFlag">
                <Text size="small">{x.assessment.name}</Text>{" "}
              </Box>
              <CircleInformation
                size="small"
                color="#395E9D"
                onClick={() => {
                  setAssessmentToView(x.assessment.id);
                  setViewAssessmentDetails(true);
                }}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Box
              pad="xsmall"
              width="20%"
              direction="row"
              gap="small"
              align="center"
              style={{ border: "2px solid #C4C4C480" }}
              round="10px"
            >
              <Text size="small">Every </Text>
              <Box className="iFlag">
                <Text size="small">{x.gap_days}</Text>
              </Box>
              <Text size="small">Days </Text>
            </Box>
            <Box
              width="20%"
              onClick={() => deleteDefaultSchedule(x.id)}
              className="noOutline"
              pad="xsmall"
            >
              <FormTrash color="#FA8581" />
            </Box>
          </Box>
        ))}

        <Box height="1px" margin="small" background="#C4C4C4" />
        <Text color="#395E9D" weight="bold" margin={{ bottom: "medium" }}>
          Add Additional Assessments
        </Text>
        <Box direction="row" align="center" gap="small" width="100%">
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            margin={{ top: "6px" }}
            className="customInput"
          >
            <Select
              plain
              name="assessment"
              labelKey="value"
              placeholder="Select Assessment"
              value={selectedAssessment}
              onChange={(v) => {
                setSelectedAssessment(v.option.key);
                setAssessmentToView(v.option.key);
              }}
              valueKey={{ key: "key", reduce: true }}
              options={options}
              dropProps={{
                style: { borderRadius: "10px" },
              }}
              required
              dropHeight="small"
              size="small"
            />
          </Box>

          <Text size="small">Every</Text>

          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            margin={{ top: "6px" }}
            className="customInput"
          >
            <Select
              plain
              name="days"
              placeholder="Select Gap Days"
              value={selectedGapDays}
              onChange={(v) => setSelectedGapDays(v.option)}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
              dropProps={{
                style: { borderRadius: "10px" },
              }}
              required
              dropHeight="small"
              size="small"
            />
          </Box>
          <Text size="small">Days</Text>

          <Box
            className="noOutline"
            onClick={() => saveNewSchedule()}
            height="100%"
            align="center"
            justify="center"
          >
            <AddCircle color="#395E9D" />
          </Box>
        </Box>
        {selectedAssessment && selectedAssessment > 0 ? (
          <Box
            className="noOutline"
            margin={{ vertical: "xsmall" }}
            onClick={() => setViewAssessmentDetailsBottom(true)}
          >
            <Text size="small" style={{ textDecoration: "underline" }}>
              View Assessment Details
            </Text>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ChangeDefaultAssessments;
