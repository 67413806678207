import React, { useState } from "react";
import { Box, Text, Layer, Button, TextArea } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { resetFlagAction, saveBHINote } from "../../store/patients/actions";

const ResetFlagModal = ({ display, setDisplayOut, flag }) => {
  const { search } = useSelector((state) => state.router.location);
  const patientId = split(search, "?", 2)[1];
  const [notes, setNotes] = useState("");
  const user = useSelector((state) => state.app.user.user);

  const dispatch = useDispatch();

  const resetFlag = () => {
    dispatch(resetFlagAction(patientId, flag));

    dispatch(
      saveBHINote(
        `Flag Change to ${flag}`.toLowerCase(),
        notes,
        patientId,
        user.id
      )
    );

    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card-flag"
          position="center"
        >
          <Box align="center" gap="small">
            <Text size="small" textAlign="center">
              Are you sure you want to set the flag to{" "}
              <Text size="small" style={{ textTransform: "lowercase" }}>
                {flag != null ? flag : "empty"}
              </Text>
              ?
            </Text>

            <Box width="100%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Comments:
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>

            <Box direction="row" width="100%" justify="evenly">
              <Button
                className="btn-yes-small"
                color="transparent"
                label="Yes"
                disabled={notes === ""}
                onClick={() => resetFlag()}
              />

              <Button
                className="btn-no-small"
                color="transparent"
                label="No"
                disabled={notes === ""}
                onClick={() => setDisplayOut(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default ResetFlagModal;
