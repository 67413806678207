import React, { useState, useEffect } from "react";
import { Layer } from "grommet";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { getCheckoutSessions } from "../../store/patients/actions";
import CardSetupForm from "../pages/dashboard/Billing/CardSetupForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentMethod = ({ display, setDisplayOut, showPaymentMethods }) => {
  const [si, setSI] = useState(null);
  const dispatch = useDispatch();
  const callBack = (data) => {
    setSI(data);
  };

  useEffect(() => {
    if (!si) dispatch(getCheckoutSessions(callBack));
  }, [si]);

  return (
    display && (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-card-payment"
        position="center"
      >
        {si ? (
          <Elements stripe={stripePromise}>
            <CardSetupForm
              showPaymentMethods={showPaymentMethods}
              setDisplayOut={setDisplayOut}
              intent={si}
            />
          </Elements>
        ) : null}
      </Layer>
    )
  );
};
export default PaymentMethod;
