/* eslint-disable camelcase */
import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const DailyCheckIns = ({ results }) => {
  const a_data = [];
  const nm_data = [];
  const d_data = [];
  const pm_data = [];
  const nl_data = [];
  const yAxis = [];
  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const sortedResults = results.sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );

  const xAxis = ["No Data Found"];

  const getOption = () => {
    results.forEach((element, index) => {
      xAxis[index] = moment(element.created).format("MMM DD");
    });

    if (xAxis[0] === "Invalid date") xAxis[0] = "No Data Found";

    sortedResults.forEach((element, index) => {
      if (element.nlp_results.length > 0) {
        if (element.nlp_results[0].predicted_label === "positive_mood") {
          pm_data[index] = 25;
          yAxis[index] = 200;
        } else if (element.nlp_results[0].predicted_label === "depression") {
          d_data[index] = 25;
          yAxis[index] = 100;
        } else if (element.nlp_results[0].predicted_label === "anxiety") {
          a_data[index] = 25;
          yAxis[index] = 50;
        } else if (element.nlp_results[0].predicted_label === "negative_mood") {
          nm_data[index] = 25;
          yAxis[index] = 150;
        }
      } else {
        nl_data[index] = 25;
        yAxis[index] = 0;
      }
    });

    return {
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },

          saveAsImage: {
            title: "Save As Image",
            name: `Journal Analysis ${moment(new Date()).format(
              "MM DD YYYY"
            )} ${firstName} ${lastName}`,
          },
        },
      },

      legend: {
        data: [
          "Depression",
          "Anxiety",
          "Negative Mood",
          "Positive Mood",
          "No Label",
        ],
        top: 40,
        type: "scroll",
      },
      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          if (params.seriesName === "hidden") return null;
          return `${moment(params.name).format("MMM DD")} <br /> ${
            params.seriesName
          }`;
        },
      },
      xAxis: {
        type: "category",
        splitLine: { show: false },
        data: xAxis,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        max: 250,
        type: "value",
        show: false,
      },
      barMaxWidth: 35,
      barMinWidth: 5,
      series: [
        {
          name: "hidden",
          type: "bar",
          stack: "primary",
          cursor: "default",

          itemStyle: {
            barBorderColor: "rgba(0,0,0,0)",
            color: "rgba(0,0,0,0)",
          },
          emphasis: {
            itemStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
          data: yAxis,
        },
        {
          name: "Depression",
          type: "bar",
          itemStyle: {
            barBorderRadius: 4,
          },
          color: "#FA8581",
          stack: "primary",
          data: d_data,
        },
        {
          name: "Anxiety",
          type: "bar",
          itemStyle: {
            barBorderRadius: 4,
          },

          color: "#B0B4FF",
          stack: "primary",
          data: a_data,
        },
        {
          name: "Negative Mood",
          type: "bar",
          itemStyle: {
            barBorderRadius: 4,
          },

          color: "#F8C998",
          stack: "primary",
          data: nm_data,
        },
        {
          name: "Positive Mood",
          type: "bar",
          itemStyle: {
            barBorderRadius: 4,
          },

          color: "#74C9C1",
          stack: "primary",
          data: pm_data,
        },
        {
          name: "No Label",
          type: "bar",
          itemStyle: {
            barBorderRadius: 4,
          },
          color: "#d3d3d3",
          stack: "primary",
          data: nl_data,
        },
      ],
    };
  };
  return (
    <Box className="large-card" pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          Journal Analysis
        </Text>
        <CustomToolTip
          outText="i"
          content="Journal analysis labels as predicted by our Natural Language Processing (NLP) algorithm."
          subText="A patient can have multiple journal entries on the same day showing different semantic tones."
        />
      </Box>

      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default DailyCheckIns;
