import React, { useState } from "react";
import { Box, Text, Layer, Button, Select } from "grommet";
import { Close } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { editSingleAssessment } from "../../store/patients/actions";

const ExtendAssessmentModal = ({ display, setDisplayOut }) => {
  const [dropValue, setDropValue] = useState(1);

  const options = [
    { value: "1 month", key: 1 },
    { value: "3 months", key: 3 },
    { value: "6 months", key: 6 },
    { value: "12 months", key: 12 },
  ];

  const dispatch = useDispatch();

  const schedule =
    useSelector((state) => state.patients.single_assessment) || null;

  const getNewDate = () => {
    const newDate = new Date();

    const calcDate = newDate.setMonth(newDate.getMonth() + dropValue);

    return calcDate;
  };
  const editSubmit = () => {
    dispatch(
      editSingleAssessment(
        {
          gap_days: schedule.gap_days,
          end_date: moment(getNewDate()).format("YYYY-MM-DD"),
          patient: schedule.patient,
          assessment: schedule.assessment,
        },
        schedule.id
      )
    );
    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite"
          position="center"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="#395E9D" weight="bold">
                Extend Assessment
              </Text>
              <Box>
                <Close
                  onClick={() => setDisplayOut(false)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>

            <Box gap="small">
              <Text size="small">
                Assessment {schedule.assessment_name} was scheduled on{" "}
                {schedule.start_date} and ended on {schedule.end_date}.
              </Text>
              <Text size="small">Would you like to extend the assessment?</Text>
              <Box direction="row" align="center" gap="small">
                <Text size="small">Extend to</Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                >
                  <Select
                    plain
                    labelKey="value"
                    valueKey={{ key: "key", reduce: true }}
                    options={options}
                    dropProps={{
                      style: { borderRadius: "10px" },
                    }}
                    dropHeight="small"
                    size="small"
                    name="assessment"
                    value={dropValue}
                    onChange={({ option }) => {
                      setDropValue(option.key);
                    }}
                  />
                </Box>
                <Text size="small">from now</Text>
              </Box>
              <Box direction="row" align="center" gap="small">
                <Text size="small">New End Date:</Text>
                <Box className="iFlag">
                  <Text size="small">
                    {moment(getNewDate()).format("MM-DD-YYYY")}
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box
              justify="center"
              margin={{ top: "medium" }}
              gap="medium"
              direction="row"
            >
              <Button
                className="btn-inverse"
                color="#c4c4c410"
                size="small"
                label="Cancel"
                onClick={() => setDisplayOut(false)}
              />
              <Button
                className="btn"
                color="#395e9d"
                size="small"
                onClick={() => editSubmit()}
                label="Submit"
                type="submit"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default ExtendAssessmentModal;
