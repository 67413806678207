import React from "react";
import { Box, Image, Text } from "grommet";
import Clipboard from "../../assets/icons/Clipboard.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPendingAssessments } from "../../store/caregiver/actions";
import TakeAssessment from "./TakeAssessment";
import InPageLoaderNew from "./InPageLoaderNew";
import NextAssessments from "./NextAssessments";

const AnxietyScreenAssessment = () => {
  const dispatch = useDispatch();

  const pendingAssessments = useSelector(
    (state) => state.caregiver.pending_assessments
  );

  useEffect(() => {
    dispatch(getPendingAssessments());
  }, []);

  if (!pendingAssessments) return <InPageLoaderNew />;

  return (
    <Box pad="medium" className="large-card">
      <Box align="center" justify="center" gap="small">
        <Image src={Clipboard} width="100px" />
        <Text color="#395E9D" weight="bold">
          Screen for Child Anxiety Related Disorders
        </Text>

        <Box gap="small">
          {pendingAssessments.length > 0 &&
            pendingAssessments.map((assessment) => (
              <TakeAssessment assessment={assessment} />
            ))}
        </Box>
        <Box gap="small">
          {pendingAssessments.length === 0 && <NextAssessments />}
        </Box>
      </Box>
    </Box>
  );
};
export default AnxietyScreenAssessment;
