import React, { useState } from "react";
import { Box, Button, Text, Layer, Image, Stack, Markdown } from "grommet";
import { Close, Pin } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import moment from "moment";
import { pinPost, unpinPost } from "../../store/patients/actions";
import LightLogo from "../../assets/roselogo.svg";
import { isMobileHook, iTabletHook } from "./Responsive";

const SinglePostListItem = ({ post, pinned }) => {
  const [show, setShow] = React.useState(false);
  const { search } = useSelector((state) => state.router.location);
  const patient = split(search, "?", 2)[1];
  const dispatch = useDispatch();
  const [internalPin, setInternalPin] = useState(pinned);

  const setPin = () => {
    dispatch(pinPost("Article", post.id, patient, setInternalPin));
  };

  const setUnpin = (obj) => {
    dispatch(unpinPost(obj.id));
    setInternalPin(null);
  };
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  let imageURL;

  if (post.Picture) {
    imageURL = post.Picture.url;
  }

  return (
    <>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-post"
          position="center"
        >
          <Box justify="between" direction="column">
            <Stack anchor="top-right">
              {post.Picture ? (
                <Box
                  width="1200vw"
                  height={isMobileorTablet ? "250px" : "350px"}
                  justify="center"
                  style={{ overflow: "scroll" }}
                >
                  <Image src={imageURL} opacity="strong" fit="cover" />
                </Box>
              ) : null}
              <Box pad="medium">
                <Close
                  size="medium"
                  color="brand"
                  onClick={() => setShow(false)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Stack>

            <Box gap="small">
              <Text
                color="#2B508F"
                size="xlarge"
                weight="bold"
                margin={{ horizontal: "medium", top: "small" }}
              >
                {post.Title}
              </Text>

              {post.Introduction ? (
                <Markdown
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F3F7FB",
                    padding: "20px",
                    marginRight: "5%",
                    marginLeft: "5%",
                    color: "#395E9D",
                    fontStyle: "italic",
                    height: "100%",
                  }}
                  components={{
                    p: {
                      component: "Paragraph",
                      props: { maxWidth: "100%" },
                    },
                  }}
                >
                  {post.Introduction}
                </Markdown>
              ) : null}

              <Markdown
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 40px",
                  color: "#395E9D",
                  gap: "10px",
                }}
                components={{
                  p: {
                    props: {
                      style: {
                        color: "#4f4f4f",
                        margin: "8px 0",
                        maxWidth: "100%",
                      },
                    },
                  },
                  img: {
                    props: { style: { width: "100px" } },
                  },
                  h4: {
                    props: { style: { maxWidth: "100%" } },
                  },
                  h2: {
                    props: { style: { maxWidth: "100%" } },
                  },
                  h3: {
                    props: { style: { maxWidth: "100%" } },
                  },
                }}
              >
                {post.Body}
              </Markdown>
              {post.Conclusion ? (
                <Markdown
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F3F7FB",
                    padding: "20px",
                    marginRight: "5%",
                    marginLeft: "5%",
                    marginBottom: "5%",
                    color: "#395E9D",
                    fontStyle: "italic",
                    height: "100%",
                  }}
                  components={{
                    p: {
                      component: "Paragraph",
                      props: { maxWidth: "100%" },
                    },
                  }}
                >
                  {post.Conclusion}
                </Markdown>
              ) : null}
              <Text
                color="#395E9D"
                weight="bold"
                textAlign="end"
                margin={{ horizontal: "medium" }}
              >
                Source:&nbsp;
                <Text color="#4F4F4F">{post.Source}</Text>
              </Text>

              <Box
                justify="center"
                align="center"
                style={{ minHeight: "110px", backgroundColor: "#0C243A" }}
              >
                <Image width="100px" src={LightLogo} />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
      <Box
        width={isMobileorTablet ? "100%" : "30%"}
        className="deck-card"
        margin={{ top: "small" }}
        pad="medium"
        gap="small"
        justify="between"
      >
        <Text alignSelf="start" weight="bold" color="#395E9D" size="small">
          {post.Title}
        </Text>

        {internalPin ? (
          <Box direction="row" align="center" gap="small">
            <Box background="#C7EFFF50" round pad="small">
              <Pin size="small" color="#2BC2F7" />
            </Box>

            <Text color="#828282" size="small">
              {" "}
              Pinned on:{" "}
              {moment(internalPin.created).format("MM/DD/YY hh:mm a")}
            </Text>
          </Box>
        ) : (
          ""
        )}

        <Box direction="row" gap="small" justify="end" align="center">
          <Button
            color="transparent"
            className="btn-pins"
            label="View"
            size="small"
            onClick={() => setShow(true)}
          />
          <Button
            color="transparent"
            size="small"
            className="btn-pins-dark"
            label={internalPin ? "Unpin" : "Pin"}
            onClick={() => (internalPin ? setUnpin(internalPin) : setPin())}
          />
        </Box>
      </Box>
    </>
  );
};
export default SinglePostListItem;
