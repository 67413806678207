import React from "react";
import { Tab } from "grommet";
import styled from "styled-components";

const CustomTab = ({ title, className, icon }) => {
  const StyledTab = styled(Tab)`
    position: relative;

    * {
      border-bottom: 0;
    }

    &[aria-expanded="true"]:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: #50b5ff;

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  `;

  return <StyledTab title={title} className={className} icon={icon} />;
};

export default CustomTab;
