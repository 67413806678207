import { split } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatients, getStaff } from "../../../../store/patients/actions";

import InPageLoader from "../../../common/InPageLoader";
import MeasureCareMembers from "./Components/MeasureCareMembers";
import MeasurePatients from "./Components/MeasurePatients";

const ACOExecutiveMeasures = () => {
  const { search } = useSelector((state) => state.router.location);
  const identifier = split(search, "?", 2)[1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onGenericSuccess = () => {
    setLoading(false);
  };

  const getWhatToSelect = (state, identifier) => {
    if (identifier === "careMembers") return state.patients.staff;

    if (identifier === "totalPatients" || identifier === "activePatients")
      return state.patients.patients;
  };

  const data = useSelector((state) => getWhatToSelect(state, identifier));

  useEffect(() => {
    setLoading(true);
    if (identifier === "careMembers") dispatch(getStaff(onGenericSuccess));
    if (identifier === "totalPatients" || identifier === "activePatients")
      dispatch(
        fetchPatients(
          onGenericSuccess,
          "99999",
          "",
          "",
          "",
          "",
          "1",
          "",
          "",
          "All",
          ""
        )
      );
  }, []);

  if (loading || !data) return <InPageLoader />;

  return (
    <>
      {identifier === "careMembers" ? <MeasureCareMembers data={data} /> : null}

      {identifier === "totalPatients" ? (
        <MeasurePatients
          data={data}
          countActive={false}
          heading="Patients on Rose"
        />
      ) : null}

      {identifier === "activePatients" ? (
        <>
          <MeasurePatients
            data={data}
            countActive={true}
            heading="Active Patients"
          />
        </>
      ) : null}
    </>
  );
};
export default ACOExecutiveMeasures;
