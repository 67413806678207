import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import InPageLoader from "./InPageLoader";
import { getGroups } from "../../store/sales/actions";
import { useHistory } from "react-router";
import {
  getSignUpbyMonthandGroupID,
  getTotalMonthlySignup,
} from "../../util/globalFunctions";

const AllGroupsUtilization = ({ patients, months }) => {
  const [loading, setLoading] = useState(false);
  const groups = useSelector((state) => state.sales.clinic_groups);
  const dispatch = useDispatch();
  const { pathname } = useSelector((state) => state.router.location);

  const paginatedResults = [];

  let counter = 1;

  const pageSie = pathname === "/dashboard/utilization-reports" ? "5" : "9999";

  let toPage = 0;
  let page = 0;

  if (groups)
    for (let i = 0; i < groups.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      paginatedResults[toPage].push(groups[i]);
      counter += 1;

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }

  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
    }
  }, []);

  const history = useHistory();

  if (loading || !groups)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Box width="100%" justify="between" direction="row">
        <Text color="#1D3763" weight="bold">
          Healthcare Group - Users added per month
        </Text>
        {pathname === "/dashboard/utilization-reports" ? (
          <Box
            className="noOutline"
            onClick={() => history.push("/dashboard/utilization-detail")}
          >
            <Text
              size="small"
              style={{ textDecoration: "underline" }}
              color="#395E9D"
            >
              View All Groups
            </Text>
          </Box>
        ) : (
          <Box />
        )}
      </Box>
      <Box className="large-card" style={{ overflowX: "auto" }} pad="small">
        <Table className="blue_header">
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  NAME
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  CREATED
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  TYPE
                </Text>
              </TableCell>
              {months.map((month, index) => (
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    {month}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {groups.length > 0 &&
              paginatedResults[page] &&
              paginatedResults[page].map((group) =>
                group.is_test_account ? null : (
                  <TableRow key={group.id}>
                    <TableCell scope="row" align="center">
                      <Text size="small">{group.name}</Text>
                    </TableCell>

                    <TableCell align="center">
                      <Text size="small">
                        {moment(group.created).format("MM/DD/YYYY")}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {group.type ? group.type : "Not Specified"}
                      </Text>
                    </TableCell>
                    {months.map((month) => (
                      <TableCell align="center">
                        <Text size="small">
                          {getSignUpbyMonthandGroupID(
                            patients,
                            group.id,
                            month
                          )}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            <TableRow>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">
                <Text weight="bold" size="small">
                  Total
                </Text>
              </TableCell>
              {months.map((month) => (
                <TableCell align="center">
                  <Text size="small" weight="bold">
                    {getTotalMonthlySignup(patients, groups, month)}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default AllGroupsUtilization;
