import { Box, Select } from "grommet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getStaff } from "../../store/patients/actions";
import { push } from "connected-react-router";

const ClinicSelectorStaffClinician = () => {
  const dispatch = useDispatch();
  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const staff = useSelector((state) => state.patients.staff);

  const getStaffName = (id) => {
    var thisStaff;

    if (staff) thisStaff = staff.find((item) => item.id === id);

    if (thisStaff) return thisStaff.first_name + " " + thisStaff.last_name;
    else return id;
  };

  const assossciations = [];
  const [currentSelected, setCurrentSelected] = useState();
  const currentClinic = useSelector((state) => state.app.dropdownClinic);

  const onSuccess = () => true;

  useEffect(() => {
    if (!staff) dispatch(getStaff(onSuccess));
  }, []);

  if (!clinicPins && !staff) return <InPageLoader />;

  if (clinicPins)
    clinicPins.forEach((x) => {
      if (x.clinic.id === currentClinic)
        assossciations.push({
          key: `${x.clinic.id} | ${x.clinic_admin}`,
          value: `${getStaffName(x.clinic_admin)}`,
          clinician: x.clinic_admin,
          pin: x.pin,
        });
    });

  const handleChange = (option) => {
    if (option) {
      window.localStorage.setItem("selectedDropdownClinic", option.key);
      window.localStorage.setItem(
        "selectedDropdownClinician",
        option.clinician
      );
      window.localStorage.setItem("selectedDropdownPin", option.pin);

      setCurrentSelected(option.key);
    }

    dispatch(push("/dashboard/home"));
  };

  return (
    <Box
      background="white"
      style={{ border: "1px solid grey", borderRadius: "10px" }}
    >
      <Select
        plain
        value={currentSelected}
        name="clinic"
        labelKey="value"
        multiple={false}
        valueKey={{ key: "key", reduce: true }}
        options={assossciations}
        dropProps={{
          style: {
            borderRadius: "10px",
            marginTop: "5px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        placeholder="Select Provider"
        onChange={({ option }) => handleChange(option)}
        dropHeight="small"
        size="small"
      />
    </Box>
  );
};

export default ClinicSelectorStaffClinician;
