/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import {
  put,
  take,
  takeEvery,
  call,
  fork,
  spawn,
  delay,
  race,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import api from "../../services/api/ApiService";
import {
  INITIALIZE,
  LOGOUT_USER,
  initialized,
  setUser,
  createNotification,
  logout as logoutAction,
  FETCH_USER,
  SET_LOGGED_USER,
  LOGIN_MANUAL,
  RESET_PASSWORD,
  VERIFY_TOKEN,
  SET_NEW_PASSWORD,
  CONFIRM_PASSWORD_SETTINGS,
  UPDATE_PROFILE,
} from "./actions";
import { Mixpanel } from "../../services/mixpanel/Mixpanel";

function getRefreshToken() {
  return String(window.localStorage.getItem("authRefreshToken"));
}

function setRefreshToken(token) {
  window.localStorage.setItem("authRefreshToken", token);
}

function removeRefreshToken() {
  window.localStorage.removeItem("authRefreshToken");
}

function removeLocalPinSettings() {
  window.localStorage.removeItem("selectedDropdownClinic");
  window.localStorage.removeItem("selectedDropdownClinician");
  window.localStorage.removeItem("selectedDropdownPin");
}

function refreshToken(token) {
  const resp = api.post("/auth/token/refresh/", { refresh: String(token) });
  return resp;
}

function setApiAuth(token) {
  api.defaults.headers.common.Authorization = `JWT ${token}`;
}

function unsetAuth() {
  api.defaults.headers.common.Authorization = null;
}

function retriveMe() {
  const resp = api.get("/me/");
  return resp;
}

function* getUser() {
  const { data } = yield call(retriveMe);
  yield put(setUser(data));
}

function* logout() {
  yield call(removeRefreshToken);
  yield call(unsetAuth);
  yield call(removeLocalPinSettings);
  yield put(setUser(null));

  yield put(push("/login"));
  yield put(createNotification("Logout Sucessful", true));
}

function* refreshLoop(refresher) {
  let userSignedOut;
  while (!userSignedOut) {
    const { expired } = yield race({
      expired: delay(86300000), // Wait 1438 min before refreshing
      signout: take(LOGOUT_USER),
    });

    // token expired first
    if (expired) {
      try {
        const {
          data: { access },
        } = yield call(refreshToken, refresher);
        yield call(setApiAuth, access);
        yield call(getUser);
      } catch (e) {
        userSignedOut = true;
        yield put(logoutAction());
      }
    }
    // user signed out before token expiration
    else {
      userSignedOut = true;
    }
  }
}

export function* setupAuth(access, refresh) {
  setApiAuth(access);
  setRefreshToken(refresh);
  yield spawn(refreshLoop, refresh);
}

function* initializeApp() {
  const token = yield call(getRefreshToken);
  if (token && token !== "null" && token !== "undefined") {
    try {
      const {
        data: { access },
      } = yield call(refreshToken, token);
      if (access) {
        yield call(setApiAuth, access);
        yield call(getUser);
        yield fork(refreshLoop, token);
      } else {
        yield call(removeRefreshToken);
      }
    } catch (e) {
      yield call(unsetAuth);
      yield call(removeRefreshToken);
    }
  }

  yield put(initialized(true));
}

function* setLoggedUser(user, access, refresh) {
  yield call(setupAuth, access, refresh);
  yield put(setUser(user));
  yield call(getUser);
}

function getExistingUserData(username, password) {
  const resp = api.post("/auth/token/", { username, password });
  return resp;
}

function* loginManual({ payload: { form, loginError } }) {
  try {
    const {
      value: { username, password },
    } = form;
    const { data } = yield call(getExistingUserData, username, password);
    const {
      token: { access, refresh },
      user,
    } = data;
    yield call(setLoggedUser, user, access, refresh);
    Mixpanel.people.set({
      $first_name: user.first_name,
      $last_name: user.last_name,
    });
  } catch (e) {
    yield call(loginError);
  }
}

function resetPasswordCall(email) {
  const resp = api.post("/auth/password-reset/", { email });
  return resp;
}

function* resetPassword({ payload: { form, loginError, onSuccess } }) {
  try {
    const {
      value: { email },
    } = form;
    const { data } = yield call(resetPasswordCall, email);
    if (data.status === "OK") {
      yield put(createNotification("Reset Password Email Sent", true));
      yield call(onSuccess);
    }
  } catch (e) {
    yield put(
      createNotification(
        "There is no active user associated with this e-mail address",
        false
      )
    );
    yield call(loginError);
  }
}

function tokenCall(token) {
  const resp = api.post("/auth/password-reset/validate_token/", { token });
  return resp;
}

function* validateToken({ payload: { token, onSucess, onError } }) {
  try {
    const { data } = yield call(tokenCall, token);
    if (data.status === "OK") yield call(onSucess);
  } catch (e) {
    yield call(onError);
  }
}

function setPasswordCall(token, password) {
  const resp = api.post("/auth/password-reset/confirm/", { token, password });
  return resp;
}

function* newPasswordSet({ payload: { token, newpassword } }) {
  try {
    const { data } = yield call(setPasswordCall, token, newpassword);
    if (data.status === "OK") {
      yield put(push("/login"));
      yield put(createNotification("Password Reset Successfully", true));
    }
  } catch (e) {
    const customError = e.response.data;
    const val = Object.values(customError);
    yield put(createNotification(val[0][0], false));
  }
}

function newPasswordSetProfile(newpassword, currentpassword) {
  const resp = api.post("/me/change_password/", {
    old_password: currentpassword,
    new_password: newpassword,
  });
  return resp;
}

function* confirmCurrentPassword({ payload: { form, loginError, onSuccess } }) {
  try {
    const {
      value: { newpassword, currentpassword },
    } = form;
    const data = yield call(
      newPasswordSetProfile,
      newpassword,
      currentpassword
    );
    if (data) {
      yield call(onSuccess);
    }
  } catch (e) {
    yield call(loginError, e);
  }
}
function updateProfileCall(
  id,
  first_name,
  last_name,
  cell_number,
  address_line,
  address_city,
  address_state
) {
  const resp = api.patch(`/api/clinic-admin/${id}/`, {
    first_name,
    last_name,
    cell_number,
    address_line,
    address_city,
    address_state,
  });
  return resp;
}

function* updateProfileFields({ payload: { form, loginError, onSuccess } }) {
  try {
    const {
      value: {
        id,
        first_name,
        last_name,
        cell_number,
        address_line,
        address_city,
        address_state,
      },
    } = form;
    const data = yield call(
      updateProfileCall,
      id,
      first_name,
      last_name,
      cell_number,
      address_line,
      address_city,
      address_state
    );
    if (data) {
      yield call(getUser);
      yield call(onSuccess);
    }
  } catch (e) {
    yield call(loginError, e);
  }
}

function* appSaga() {
  yield takeEvery(INITIALIZE, initializeApp);
  yield takeEvery(LOGOUT_USER, logout);
  yield takeEvery(FETCH_USER, getUser);
  yield takeEvery(SET_LOGGED_USER, setLoggedUser);
  yield takeEvery(LOGIN_MANUAL, loginManual);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(VERIFY_TOKEN, validateToken);
  yield takeEvery(SET_NEW_PASSWORD, newPasswordSet);
  yield takeEvery(CONFIRM_PASSWORD_SETTINGS, confirmCurrentPassword);
  yield takeEvery(UPDATE_PROFILE, updateProfileFields);
}

export default appSaga;
