import { Box, Select } from "grommet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getStaff } from "../../store/patients/actions";
import { setClinicDropdownID } from "../../store/app/actions";

const ClinicSelectorStaffClinic = () => {
  const dispatch = useDispatch();
  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const staff = useSelector((state) => state.patients.staff);

  const assossciations = [];
  const [currentSelected, setCurrentSelected] = useState();

  const onSuccess = () => true;

  useEffect(() => {
    if (!staff) dispatch(getStaff(onSuccess));
  }, []);

  if (!clinicPins && !staff) return <InPageLoader />;

  if (clinicPins)
    clinicPins.forEach((x) => {
      if (!assossciations.find((el) => el.clinic === x.clinic.id))
        assossciations.push({
          key: `${x.clinic.id} | ${x.clinic_admin}`,
          clinic: x.clinic.id,
          value: `${x.clinic.name}`,
        });
    });

  const handleChange = (option) => {
    if (option) {
      dispatch(setClinicDropdownID(option.clinic));

      setCurrentSelected(option.key);
    }
  };

  return (
    <Box
      background="white"
      style={{ border: "1px solid grey", borderRadius: "10px" }}
    >
      <Select
        plain
        value={currentSelected}
        name="clinic"
        labelKey="value"
        multiple={false}
        valueKey={{ key: "key", reduce: true }}
        options={assossciations}
        dropProps={{
          style: {
            borderRadius: "10px",
            marginTop: "5px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        placeholder="Select Clinic"
        onChange={({ option }) => handleChange(option)}
        dropHeight="small"
        size="small"
      />
    </Box>
  );
};

export default ClinicSelectorStaffClinic;
