import { Box } from "grommet";
import React from "react";
import ClinicianListsComponent from "../../../common/ClinicianListComponent";

const ClinicianList = () => {
  return (
    <>
      <Box width="100%" pad="medium">
        <ClinicianListsComponent />
      </Box>
    </>
  );
};

export default ClinicianList;
