import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextArea,
  Button,
  // DateInput,
} from "grommet";

import { LinkPrevious } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "./InPageLoader";
import { getBHINotes, saveBHINote } from "../../store/patients/actions";

const NotesHistoryModal = ({ display, setDisplayOut }) => {
  const patient = useSelector((state) => state.patients.patient);
  const user = useSelector((state) => state.app.user.user);
  const staff = useSelector((state) => state.patients.staff);
  const notes = useSelector((state) => state.patients.bhi_notes);
  const [newNote, setNewNote] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const onSuccess = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getBHINotes(patient.id, onSuccess));
  }, []);

  const saveNote = () => {
    dispatch(saveBHINote("Self Added Note", newNote, patient.id, user.id));
    setNewNote("");
  };

  if (loading) {
    return (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-update"
        position="right"
      >
        <Box width="100%" height="100%">
          <Loader />
        </Box>
      </Layer>
    );
  }

  const getDiagnosis = (element) => {
    if (element.diagnosis === "Fireflag Detection")
      return (
        <Box
          className="pFlag"
          align="center"
          style={{ fontSize: "xsmall" }}
          // pad="xxsmall"
        >
          Fireflag
        </Box>
      );
    if (element.diagnosis === "flag change to yellow")
      return (
        <Box direction="row" gap="xsmall" align="center">
          Flag Change:
          <Box
            className="yFlag"
            align="center"
            style={{ fontSize: "xsmall" }}
            pad="xxsmall"
          >
            YELLOW
          </Box>
        </Box>
      );

    if (element.diagnosis === "flag change to red")
      return (
        <Box direction="row" gap="xsmall" align="center">
          Flag Change:
          <Box
            className="rFlag"
            align="center"
            style={{ fontSize: "xsmall" }}
            pad="xxsmall"
          >
            RED
          </Box>
        </Box>
      );
    if (element.diagnosis === "flag change to null")
      return (
        <Box direction="row" gap="xsmall" align="center">
          Flag Change:
          <Box
            className="iFlag"
            align="center"
            style={{ fontSize: "xsmall" }}
            pad="xxsmall"
          >
            Removed
          </Box>
        </Box>
      );

    if (element.diagnosis === "Self Added Note")
      return (
        <Box direction="row" className="iFlag" gap="xsmall" align="center">
          Clinical Note
        </Box>
      );

    if (element.diagnosis === "eligibility overriden")
      return (
        <Box direction="row" className="iFlag" gap="xsmall" align="center">
          Eligibility Overriden
        </Box>
      );

    if (element.diagnosis === null) return null;

    return `Diagnosis: ${element.diagnosis}`;
  };

  const getManagedBy = (id) => {
    const thisStaff = staff.find((item) => item.id === id);

    if (thisStaff) return `${thisStaff.first_name} ${thisStaff.last_name}`;

    return "";
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-update"
          position="right"
        >
          <Box gap="medium">
            <Box direction="row" gap="small" align="center">
              <Box className="noOutline" onClick={() => setDisplayOut(false)}>
                <LinkPrevious color="brand" />
              </Box>
            </Box>
            <Box gap="small" style={{ minHeight: "200px" }}>
              <Text size="medium" color="brand" weight="bold">
                Add a note
              </Text>
              <Text size="xsmall" style={{ textDecoration: "italic" }}>
                Notes (if applicable)
              </Text>

              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="note"
                  plain
                  fill
                  type="text"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
              <Box align="end">
                <Button
                  className="btn"
                  color="#395e9d"
                  disabled={newNote === ""}
                  onClick={() => saveNote()}
                  size="small"
                  label="Add note"
                />
              </Box>
            </Box>
            <Text size="medium" color="brand" weight="bold">
              Notes History
            </Text>
            <Box style={{ overflowY: "auto", flexShrink: "0" }}>
              {notes &&
                notes.map((element) => (
                  <Box
                    border="bottom"
                    style={{ minHeight: "100px" }}
                    gap="xsmall"
                    pad="small"
                  >
                    <Box direction="row" justify="between">
                      <Box direction="row" gap="xsmall">
                        <Text color="#CCD3D7" size="xsmall">
                          Posted On:
                        </Text>
                        <Text color="#2BC2F7" weight="bold" size="xsmall">
                          {moment(element.created).format("MM/DD/YY hh:mm a")}
                        </Text>
                      </Box>
                      <Box direction="row" gap="xsmall">
                        <Text color="#CCD3D7" size="xsmall">
                          Updated By:
                        </Text>
                        <Text color="#74C9C1" weight="bold" size="xsmall">
                          {getManagedBy(element.added_by)}
                        </Text>
                      </Box>
                    </Box>
                    <Box direction="row">
                      <Text color="#1B3664" size="xsmall">
                        {getDiagnosis(element)}
                      </Text>
                    </Box>
                    <Text color="#1B3664" weight="bold" size="small">
                      {element.notes}
                    </Text>
                  </Box>
                ))}
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default NotesHistoryModal;
