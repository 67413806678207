import React from "react";
import { Text, Layer, Image, Button, Box } from "grommet";
import { useHistory } from "react-router";
import BigTick from "../../assets/icons/bigTick.svg";

const ClinicSuccess = ({ display, setDisplayOut, clinicID }) => {
  const history = useHistory();
  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card-flag"
          position="center"
        >
          <Box gap="small" align="center">
            <Image src={BigTick} />
            <Text size="large" color="#395E9D">
              Clinic Created Successfully
            </Text>

            <Button
              className="btn"
              color="#395e9d"
              size="small"
              label="Visit Clinic"
              onClick={() => history.push(`/dashboard/clinic/?${clinicID}`)}
            />

            <Box className="noOutline" onClick={() => setDisplayOut(false)}>
              <Text size="small">Return to Dashboard</Text>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default ClinicSuccess;
