import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const BarChart = ({ PHQ8, GAD7, PHQ9 }) => {
  let xAxisData = ["No Data Found"];
  const PHQAxisData = [];
  const GADAxisData = [];
  const PHQ9AxisData = [];

  const PHQSeriesData = [];
  const GAD7SeriesData = [];
  const PHQ9SeriesData = [];

  const SortedPHQ = [];
  const SortedGAD = [];
  const SortedPHQ9 = [];

  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const getOption = () => {
    PHQ8.forEach((element, index) => {
      PHQAxisData[index] = element.created;
    });
    GAD7.forEach((element, index) => {
      GADAxisData[index] = element.created;
    });
    PHQ9.forEach((element, index) => {
      PHQ9AxisData[index] = element.created;
    });
    const union = [
      ...new Set([...PHQAxisData, ...GADAxisData, ...PHQ9AxisData]),
    ];
    union.sort();
    if (union.length !== 0) {
      xAxisData = union;
      xAxisData.forEach((item, index) => {
        xAxisData[index] = moment(item).format("MMM DD");
      });
      xAxisData = [...new Set(xAxisData)];
    }

    PHQ8.forEach((element, index) => {
      PHQSeriesData[index] = [element.created, element.score];
    });
    GAD7.forEach((element, index) => {
      GAD7SeriesData[index] = [element.created, element.score];
    });

    PHQ9.forEach((element, index) => {
      PHQ9SeriesData[index] = [element.created, element.score];
    });

    PHQSeriesData.sort();
    PHQSeriesData.forEach((element, index) => {
      SortedPHQ[index] = [moment(element[0]).format("MMM DD"), element[1]];
    });

    GAD7SeriesData.sort();
    GAD7SeriesData.forEach((element, index) => {
      SortedGAD[index] = [moment(element[0]).format("MMM DD"), element[1]];
    });

    PHQ9SeriesData.sort();
    PHQ9SeriesData.forEach((element, index) => {
      SortedPHQ9[index] = [moment(element[0]).format("MMM DD"), element[1]];
    });

    return {
      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${moment(params.data[0]).format("MMM DD")}  <br/> ${
            params.seriesName
          }:  ${params.data[1]}`;
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },
          magicType: {
            type: ["bar"],
            title: {
              bar: "Show Bar Graph",
            },
          },
          restore: {
            title: "Restore",
          },
          saveAsImage: {
            title: "Save As Image",
            name: `PHQ8 and GAD7 ${moment(new Date()).format(
              "MM DD YYYY"
            )} ${firstName} ${lastName}`,
          },
        },
      },

      legend: {
        show: true,
        top: 270,
        data: ["GAD-7", "PHQ-8", "PHQ-9"],
      },

      xAxis: [
        {
          offset: 5,
          type: "category",
          data: xAxisData,
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: "category",
          name: "",
          data: ["None", "Mild", "Moderate", "Severe"],
          axisLabel: {
            color: (value) => {
              if (value === "None") return "#4F4F4F";
              if (value === "Mild") return "#395E9D";
              if (value === "Moderate") return "#EDB219";

              return "#EB7571";
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: "value",
          show: false,
          name: "scores",
          max: 28,
          min: 0,
        },
      ],
      series: [
        {
          name: "PHQ-9",
          type: "line",
          color: "red",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedPHQ9,
        },
        {
          name: "PHQ-8",
          type: "line",
          color: "#FDA004",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedPHQ,
        },
        {
          name: "GAD-7",
          type: "line",
          color: "#395E9D",
          symbolSize: 10,
          symbol: "circle",
          yAxisIndex: 1,
          data: SortedGAD,
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Box direction="row" align="center">
          <Text weight="bold" color="#395E9D">
            Assessment Results
          </Text>
        </Box>
        <CustomToolTip
          outText="i"
          content="The default clinical assessments in Rose are PHQ-8 (for depression) and GAD-7 (for anxiety)."
          subText="This chart shows the patient's most recent assessment results completed via the mobile app."
        />
      </Box>

      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default BarChart;
