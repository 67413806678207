import React from "react";
import { Box } from "grommet";
import ProfileBoxCareGiver from "../../../common/ProfileBoxCareGiver";
import AnxietyScreenAssessment from "../../../common/AnxietyScreenAssessment";
import { useEffect } from "react";
import { getCareGiver } from "../../../../store/caregiver/actions";
import { useDispatch, useSelector } from "react-redux";
import InPageLoaderNew from "../../../common/InPageLoaderNew";
import RecentAssessmentsBox from "../../../common/RecentAssessmentsBox";

const CareGiverHome = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user.user);
  const caregiver = useSelector((state) => state.caregiver.user);

  useEffect(() => {
    dispatch(getCareGiver(user.id));
  }, []);

  if (!user || !caregiver) return <InPageLoaderNew />;

  return (
    <Box pad="medium" gap="small">
      <ProfileBoxCareGiver />
      <AnxietyScreenAssessment />
      <RecentAssessmentsBox />
    </Box>
  );
};
export default CareGiverHome;
