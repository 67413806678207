import React, { useState } from "react";
import { Box, Button, Text, Layer } from "grommet";
import { Close, Pin } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import AudioPlayer from "react-h5-audio-player";
import moment from "moment";
import { pinPost, unpinPost } from "../../store/patients/actions";
import "react-h5-audio-player/lib/styles.css";
import { isMobileHook, iTabletHook } from "./Responsive";

const SingleAudioListItem = ({ post, pinned }) => {
  const [show, setShow] = React.useState(false);
  let imageURL;
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const patient = split(search, "?", 2)[1];
  const [internalPin, setInternalPin] = useState(pinned);
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  if (post.Audio) {
    imageURL = post.Audio.url;
  }
  const setPin = () => {
    dispatch(pinPost("Audio", post.id, patient, setInternalPin));
  };

  const setUnpin = (obj) => {
    dispatch(unpinPost(obj.id));
    setInternalPin(null);
  };

  return (
    <>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-post-audio"
          position="center"
        >
          <Box pad="medium" gap="small" align="start">
            <Box alignSelf="end">
              <Close
                size="medium"
                color="brand"
                onClick={() => setShow(false)}
                style={{ cursor: "pointer" }}
              />
            </Box>

            <Text size="xxlarge" textAlign="center" weight="bold">
              {post.Title}
            </Text>

            {post.Audio ? (
              <Box pad={{ horizontal: "xsmall" }} width="100%">
                <AudioPlayer
                  autoPlay
                  showFilledVolume
                  showFilledProgress
                  showDownloadProgress
                  src={imageURL}
                />
              </Box>
            ) : null}

            {post.Description}
          </Box>
        </Layer>
      )}
      <Box
        className="deck-card"
        width={isMobileorTablet ? "100%" : "30%"}
        margin={{ top: "small" }}
        pad="medium"
        gap="small"
        justify="between"
      >
        <Text alignSelf="start" weight="bold" color="#395E9D" size="small">
          {post.Title}
        </Text>
        {internalPin ? (
          <Box direction="row" align="center" gap="small">
            <Box background="#C7EFFF50" round pad="small">
              <Pin size="small" color="#2BC2F7" />
            </Box>

            <Text color="#828282" size="small">
              {" "}
              Pinned on:{" "}
              {moment(internalPin.created).format("MM/DD/YY hh:mm a")}
            </Text>
          </Box>
        ) : (
          ""
        )}

        <Box direction="row" gap="small" justify="end" align="center">
          <Button
            color="transparent"
            className="btn-pins"
            label="View"
            size="small"
            onClick={() => setShow(true)}
          />
          <Button
            color="transparent"
            size="small"
            className="btn-pins-dark"
            label={internalPin ? "Unpin" : "Pin"}
            onClick={() => (internalPin ? setUnpin(internalPin) : setPin())}
          />
        </Box>
      </Box>
    </>
  );
};
export default SingleAudioListItem;
