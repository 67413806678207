import { Box, Heading } from "grommet";
import React from "react";
import AllClinicsList from "../../../common/AllClinicsList";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import SalesCreateMenu from "../../../common/SalesCreateMenu";

const AllClinics = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;

  return (
    <>
      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align={isMobileorTablet ? "start" : "center"}
          justify="between"
        >
          <Heading level={3} color="#1B3664">
            All Clinics
          </Heading>
          <SalesCreateMenu />
        </Box>
        <AllClinicsList />
      </Box>
    </>
  );
};

export default AllClinics;
