import { Avatar, Box, Heading, Image, Text } from "grommet";
import { Edit, FormPrevious } from "grommet-icons";
import { split } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getClinic } from "../../../../store/sales/actions";
import ClinicNotes from "../../../common/ClinicNotes";
import EditModalClinic from "../../../common/EditModalClinic";
import InPageLoader from "../../../common/InPageLoader";
import InvoicingDetails from "../../../common/InvoicingDetails";

const SingleClinic = () => {
  const { search } = useSelector((state) => state.router.location);
  const clinicID = split(search, "?", 2)[1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const thisClinic = useSelector((state) => state.sales.single_clinic);
  const [showEdit, setShowEdit] = useState(false);

  const onSuccess = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getClinic(clinicID, onSuccess));
  }, []);

  const getAvatar = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (thisClinic.hasOwnProperty("profilePicture")) {
      return <Image src={thisClinic.profilePicture} fit="cover" />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="4xl">
          {thisClinic ? thisClinic.name[0] : ""}
        </Text>
      </Box>
    );
  };

  if (loading || !thisClinic) return <InPageLoader />;

  return (
    <>
      <EditModalClinic
        display={showEdit}
        setDisplayOut={(value) => setShowEdit(value)}
      />

      <Box width="100%" pad="medium" gap="small">
        <Box width="100%" gap="medium">
          <Box>
            <Box direction="row" justify="between">
              <Heading level={3} color="#1B3664">
                {thisClinic.name}
              </Heading>
              <Box
                direction="row"
                className="noOutline"
                onClick={() =>
                  history.push(
                    `/dashboard/healthgroup/?${thisClinic.clinic_group}`
                  )
                }
              >
                {" "}
                <FormPrevious color="#395E9D" />{" "}
                <Text color="#395E9D">Back to Clinic Healthcare Group</Text>
              </Box>
            </Box>
            <Box className="large-card" pad="medium" direction="row">
              <Edit onClick={() => setShowEdit(true)} />

              <Box width="25%" align="center">
                <Avatar
                  size="xlarge"
                  background="#f1f1f5"
                  style={{ border: "3px solid #28539e" }}
                >
                  {getAvatar()}
                </Avatar>
              </Box>
              <Box width="25%" align="start" gap="medium">
                <Box>
                  <Text size="small" color="#395E9D">
                    NAME
                  </Text>
                  <Text size="small">{thisClinic.name}</Text>
                </Box>
                <Box>
                  <Text size="small" color="#395E9D">
                    LOCATION
                  </Text>
                  <Text size="small">{thisClinic.street_address_1}</Text>
                </Box>
              </Box>
              <Box width="25%" align="start" gap="medium">
                <Box>
                  <Text size="small" color="#395E9D">
                    PRIMARY CONTACT
                  </Text>
                  <Text size="small">{thisClinic.emergency_contact}</Text>
                </Box>
                <Box>
                  <Text size="small" color="#395E9D">
                    PRIMARY CONTACT EMAIL
                  </Text>
                  <Text size="small">{thisClinic.email}</Text>
                </Box>
              </Box>
              <Box width="25%" align="start" gap="medium">
                <Box>
                  <Text size="small" color="#395E9D">
                    ROSE SALES PERSON
                  </Text>
                  <Text size="small">{thisClinic.rose_sales_team_member}</Text>
                </Box>
                <Box>
                  <Text size="small" color="#395E9D">
                    CREATED ON
                  </Text>
                  <Text size="small">
                    {moment(thisClinic.created).format("MM/DD/YYYY")}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box direction="row" gap="small">
          <Box width="50%">
            <InvoicingDetails />
          </Box>
          <Box width="50%">
            <ClinicNotes />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SingleClinic;
