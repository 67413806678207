import React from "react";
import { Box, Text, Layer } from "grommet";
import { Close } from "grommet-icons";

const ViewAssessmentDetailsModal = ({
  assessments,
  toShow,
  display,
  setDisplayOut,
}) => {
  const thisAssessment = assessments.find((element) => element.id === toShow);

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-details"
          position="center"
        >
          <Box gap="small">
            <Box direction="row" justify="between" gap="small">
              <Text size="medium" color="#395E9D" weight="bold">
                {thisAssessment.name}
              </Text>
              <Box>
                <Close
                  onClick={() => setDisplayOut(false)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box direction="row" gap="small" width="100%">
              <Box width="50%" style={{ overflowY: "auto" }}>
                <Text className="bFlag">Questions</Text>
                <Box pad="small" gap="small">
                  {thisAssessment.questionnaire.map((element, index) => (
                    <Text size="small" key={element.id}>
                      {index + 1}
                      {": "}
                      {element.text}
                    </Text>
                  ))}
                </Box>
              </Box>
              <Box width="50%">
                <Box style={{ overflowY: "auto" }}>
                  <Text className="bFlag">Answers</Text>
                  <Box pad="small" gap="small">
                    {thisAssessment.answer_group.answers.map(
                      (element, index) => (
                        <Text size="small" key={element.id}>
                          {index + 1}
                          {": "}
                          {element.text}
                          {" - Score: "}
                          {element.score}
                        </Text>
                      )
                    )}
                  </Box>
                </Box>
                <Box style={{ overflowY: "auto" }} />
                <Text className="bFlag">Tags</Text>
                <Box pad="small" gap="small">
                  {thisAssessment.tag_group.tags.map((element) => (
                    <Box direction="row" gap="small" key={element.id}>
                      <Box
                        className="result-tag"
                        pad={{ horizontal: "small", vertical: "xxsmall" }}
                        margin={{ left: "small" }}
                        justify="center"
                      >
                        <Text
                          size="xsmall"
                          style={{ textTransform: "uppercase" }}
                        >
                          {element.label}
                          {":"}
                        </Text>
                      </Box>
                      <Text size="small">
                        {element.low_bound}
                        {" - "}
                        {element.high_bound}
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default ViewAssessmentDetailsModal;
