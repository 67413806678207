import React, { useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  Form,
  MaskedInput,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { Close } from "grommet-icons";
import DatePicker from "react-datepicker";
import { editInvite } from "../../store/patients/actions";
import { createNotification } from "../../store/app/actions";

const EditModal = ({ display, setDisplayOut }) => {
  const invite = useSelector((state) => state.patients.invite) || null;
  const dispatch = useDispatch();

  function setInviteValues(invitePatient) {
    const inviteDetails = {
      first_name: invitePatient.first_name ? invitePatient.first_name : "",
      last_name: invitePatient.last_name ? invitePatient.last_name : "",
      cell_number: invitePatient.cell_number ? invitePatient.cell_number : "",
      email: invitePatient.email ? invitePatient.email : "",
      insurance: invitePatient.insurance ? invitePatient.insurance : "",
      next_appointment: invitePatient.next_appointment
        ? invitePatient.next_appointment
        : "",
    };
    return inviteDetails;
  }
  const [value, setValue] = React.useState(
    invite ? setInviteValues(invite) : ""
  );
  const [NAD, setNAD] = useState(
    value.next_appointment ? new Date(value.next_appointment) : null
  );

  const editSubmit = (form) => {
    if (!value.email && !value.cell_number) {
      dispatch(createNotification("Please enter either phone or email", false));
      return;
    }

    dispatch(editInvite(form, invite, NAD));
    setDisplayOut(false);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite"
          position="center"
        >
          <Form
            onSubmit={editSubmit}
            value={value}
            onChange={(nextValue) => setValue(nextValue)}
          >
            <Box gap="medium">
              <Box direction="row" justify="between">
                <Text size="medium" color="#395E9D" weight="bold">
                  Edit Invite
                </Text>
                <Box>
                  <Close
                    onClick={() => setDisplayOut(false)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    First Name
                    <Text size="xsmall" margin={{ left: "1px" }} color="red">
                      *
                    </Text>
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="first_name"
                      plain
                      placeholder={invite ? invite.first_name : ""}
                      type="text"
                      className="semiInput"
                      required
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Last Name
                    <Text size="xsmall" margin={{ left: "1px" }} color="red">
                      *
                    </Text>
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="last_name"
                      plain
                      placeholder={invite ? invite.last_name : ""}
                      type="text"
                      className="semiInput"
                      required
                    />
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Phone Number
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <MaskedInput
                      size="small"
                      mask={[
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: "XXX",
                        },
                        { fixed: " " },
                        {
                          length: 3,
                          regexp: /^[0-9]{1,3}$/,
                          placeholder: "XXX",
                        },
                        { fixed: " " },
                        {
                          length: 4,
                          regexp: /^[0-9]{1,4}$/,
                          placeholder: "XXXX",
                        },
                      ]}
                      name="cell_number"
                      plain
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Email Address
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <TextInput
                      size="small"
                      name="email"
                      plain
                      type="email"
                      placeholder={invite ? invite.email : ""}
                      className="semiInput"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Insurance
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <MaskedInput
                      size="small"
                      mask={[
                        {
                          length: 14,
                          regexp: /^[a-zA-Z0-9]{1,14}$/,
                          placeholder: "14 Digit Insurance Code",
                        },
                      ]}
                      name="insurance"
                      plain
                    />
                  </Box>
                </Box>

                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Next Appointment Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInputDatePick"
                  >
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      selected={NAD}
                      minDate={new Date()}
                      isClearable
                      popperClassName="modalPopper"
                      className="semiInput"
                      onChange={(element) => setNAD(element)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                justify="center"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default EditModal;
