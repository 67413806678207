import React from "react";
import { Box, Button, Text, Layer, TableRow, TableCell } from "grommet";
import { Close, FormView } from "grommet-icons";
import moment from "moment";
import { isMobileHook, iTabletHook } from "./Responsive";

const SingleJournalListItem = ({ post }) => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [show, setShow] = React.useState(false);
  const getClassName = () => {
    if (post.nlp_results.length > 0) {
      if (post.nlp_results[0].predicted_label === "positive_mood")
        return "pmFlag";

      if (post.nlp_results[0].predicted_label === "depression")
        return "depFlag";

      if (post.nlp_results[0].predicted_label === "negative_mood")
        return "nmFlag";

      if (post.nlp_results[0].predicted_label === "anxiety")
        return "anxietyFlag";

      return null;
    }

    return "iFlag";
  };
  return (
    <>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-entry"
          position="center"
        >
          <Box
            gap="small"
            justify="between"
            margin={{ bottom: "small" }}
            direction="row"
          >
            <Text size="large">Journal Entry</Text>
            <Close
              size="medium"
              color="brand"
              onClick={() => setShow(false)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box>
            <Box
              className={getClassName()}
              width="25%"
              margin={{ bottom: "small" }}
            >
              <Text size="11px" style={{ textTransform: "uppercase" }}>
                {post.nlp_results.length > 0
                  ? post.nlp_results[0].predicted_label.replace(/_/g, " ")
                  : "No Label"}
              </Text>
            </Box>
            <Text size="xsmall">
              Created:&nbsp;
              {post
                ? moment(post.created).format("Do MMM YYYY h:mm:ss a")
                : null}
            </Text>

            <Box style={{ overflowY: "auto" }}>
              <Text margin={{ top: "small" }}>
                Entry:&nbsp;
                {post ? post.entry : null}
              </Text>
            </Box>
          </Box>
        </Layer>
      )}
      <TableRow>
        <TableCell align="center">
          <Text size="xsmall">
            {post ? moment(post.created).format("MMM DD") : null}
          </Text>
        </TableCell>
        <TableCell align="center">
          <Box
            className={getClassName()}
            pad={{ horizontal: "small", vertical: "xxsmall" }}
          >
            <Text size="11px" style={{ textTransform: "uppercase" }}>
              {post.nlp_results.length > 0
                ? post.nlp_results[0].predicted_label.replace(/_/g, " ")
                : "No Label"}
            </Text>
          </Box>
        </TableCell>
        <TableCell width={isMobileorTablet ? "small" : "medium"}>
          <Text
            size="small"
            style={{
              overflowX: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {post ? post.entry : null}
          </Text>
        </TableCell>
        <TableCell>
          <Box direction="row" justify="center" align="center">
            <Button
              className={isMobileorTablet ? "btn-mobile" : "btn-primary"}
              color="#2BC2F7"
              margin={{ horizontal: "small" }}
              size="small"
              label={isMobileorTablet ? null : "View"}
              onClick={() => setShow(!show)}
              icon={<FormView color="white" />}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
export default SingleJournalListItem;
