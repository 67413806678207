/* eslint-disable no-restricted-syntax */
import React from "react";
import { Box } from "grommet";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { split } from "lodash";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import Sidebar from "./Sidebar";
import BillingSidebar from "./Billing/BillingSidebar";
import MobileMenuBilling from "./Billing/MobileMenuBilling";

import MobileMenu from "./MobileMenu";
import AllSteps from "./DashboardSteps";
import EmptySideBar from "./EmptySideBar";
import MobileMenuBulk from "./MobileMenuBulk";
import SalesSidebar from "./SalesPanel/SalesSidebar";

const Dashboard = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const role = useSelector((state) => state.app.user.user.role);
  const { pathname } = useSelector((state) => state.router.location);
  let step = {};

  step = AllSteps.find((data) => {
    if (data.step.path === pathname) {
      return true;
    }
    if (split(pathname, "?")[0] === split(data.step.path, "?")[0]) {
      return true;
    }

    return false;
  });

  if (step === undefined || step === null || !step) {
    return <Redirect to="/page-not-found?" />;
  }

  if (!step.role.find((element) => element === role)) {
    return <Redirect to="/page-not-found" />;
  }

  const { Component } = step.step;

  const getSideBar = () => {
    if (
      role === "CLINIC_ADMIN" ||
      role === "CLINICAL_STAFF" ||
      role === "TWS_COORDINATOR" ||
      role === "TWS_CONCIERGE" ||
      role === "TWS_CASE_MANAGER"
    )
      return <Sidebar />;

    if (role === "INVOICING_ADMIN") return <BillingSidebar />;

    if (role === "SALES_MANAGER") return <SalesSidebar />;

    return <EmptySideBar />;
  };

  const getMobileMenu = () => {
    if (role === "CLINIC_ADMIN" || role === "CLINICAL_STAFF")
      return <MobileMenu />;

    if (role === "INVOICING_ADMIN") return <MobileMenuBilling />;

    return <MobileMenuBulk />;
  };

  return (
    <>
      {isMobileorTablet ? getMobileMenu() : null}
      <Box
        direction="row"
        width="100%"
        height="100%"
        style={{ minHeight: "100vh" }}
      >
        {isMobileorTablet ? null : getSideBar()}
        <Box width="100%" margin={isMobileorTablet ? null : { left: "270px" }}>
          <Component />
        </Box>
      </Box>
    </>
  );
};
export default Dashboard;
