import React, { useState } from "react";
import { Box, Button, Form, TextInput, Text, Image } from "grommet";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { MailOption } from "grommet-icons";
import { push } from "connected-react-router";
import { isMobileHook } from "../../common/Responsive";
import { resetPass } from "../../../store/app/actions";
import logo from "../../../assets/Vectorlock.svg";

const SoftButton = styled(Button)`
  border-radius: 10px;
  background-color: #1c3664;
  border: none;
`;

const LoadingSoftButton = ({ label, submitting }) => (
  <Box height="xxsmall">
    <SoftButton
      fill
      primary
      type="submit"
      disabled={submitting}
      label={
        submitting ? (
          <Loader type="TailSpin" height={30} color="grey" />
        ) : (
          <Text color="#FFFFFF">{label}</Text>
        )
      }
    />
  </Box>
);

const ResetForm = () => {
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const isMobile = isMobileHook();

  const dispatch = useDispatch();

  const loginError = () => {
    setSubmitting(false);
  };

  const onSuccess = () => {
    setSubmitting(false);
  };

  const resetPassSubmit = (form) => {
    setSubmitting(true);
    dispatch(resetPass(form, loginError, onSuccess));
  };

  const onChangeForm = () => {
    if (errors.detail) setErrors({});
  };

  return (
    <Box
      className="wrap-login"
      width={isMobile ? "320px" : "400px"}
      round="10px"
      style={{ position: "absolute" }}
    >
      <Box alignSelf="center" justify="center" margin={{ top: "small" }}>
        <Image src={logo} />
      </Box>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="large"
        weight="bold"
        color="#1B3664"
      >
        Reset Password
      </Text>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="small"
        weight="normal"
        color="black"
      >
        Enter your email to reset your password
      </Text>

      <Box
        align="center"
        justify="center"
        direction="row"
        margin={{ top: "xxsmall", bottom: "xxsmall" }}
      />
      <Form
        onChangeCapture={() => onChangeForm()}
        onSubmit={resetPassSubmit}
        errors={errors}
      >
        <Text size="xsmall" weight="bold" color="#1B3664">
          Email Address
        </Text>
        <Box
          border={{ side: "all", color: "#C4C4C4" }}
          round="small"
          margin={{ top: "6px" }}
        >
          <TextInput
            size="small"
            name="email"
            plain
            type="email"
            placeholder="Email Address"
            className="loginInput"
            icon={<MailOption color="#183B56" />}
            reverse
            required
          />
        </Box>
        <Box margin={{ top: "xsmall", bottom: "xsmall" }} />

        <Box align="center" justify="center" direction="row" margin={{}}>
          {errors.detail ? (
            <Text
              margin={{ top: "xxxsmall", bottom: "xsmall" }}
              size="small"
              color="red"
            >
              {errors.detail}
            </Text>
          ) : null}
        </Box>

        <LoadingSoftButton submitting={submitting} label="Reset Password" />
      </Form>

      <Text
        onClick={() => dispatch(push("/login"))}
        margin="medium"
        style={{ cursor: "pointer" }}
        size="small"
        weight="bold"
        color="#1C3664"
        textAlign="center"
      >
        Back to homepage
      </Text>
    </Box>
  );
};

export default ResetForm;
