import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  // Text,
} from "grommet";
import { useSelector, useDispatch } from "react-redux";
// import { push } from "connected-react-router";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import InviteModal from "../../../common/InviteModal";
import BulkInviteModal from "../../../common/BulkInviteModal";
import Billing from "./Billing";
import Loader from "../../../common/InPageLoader";
import { fetchPatients, getStaff } from "../../../../store/patients/actions";

const BillingHome = () => {
  const user = useSelector((state) => state.app.user.user);
  // const patients = useSelector((state) => state.patients.patients);
  const patientsdata = useSelector((state) => state.patients.patientsdata);
  const staff = useSelector((state) => state.patients.staff);

  let patients = [];

  if (patientsdata)
    // eslint-disable-next-line prefer-destructuring
    patients = patientsdata.patients;

  const dispatch = useDispatch();
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);
  const [loading, setLoading] = useState(false);

  const success = () => {
    setLoading(false);
  };
  const onStaffSuccess = () => true;

  const fetchPatientsData = () => {
    dispatch(getStaff(onStaffSuccess));
    dispatch(
      fetchPatients(success, "999", "", "", "", "", 1, "", "", "HomeData", "")
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchPatientsData();
  }, []);

  const getCountOfPrimaryProviders = () => {
    let count = 0;
    staff.forEach((element) => {
      if (element.role === "CLINIC_ADMIN") count += 1;
    });
    return count;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {show && (
        <InviteModal display={show} setDisplayOut={(value) => setShow(value)} />
      )}
      {showBulk && (
        <BulkInviteModal
          display={showBulk}
          setDisplayOut={(value) => setShowBulk(value)}
        />
      )}

      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Heading level={3} color="#1B3664">
            Hi
            <span style={{ marginLeft: "8px" }}>
              {user ? `${user.first_name} ${user.last_name}` : ""},
            </span>
          </Heading>
        </Box>
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align="center"
          gap="medium"
          margin={{ top: "32px" }}
        >
          <Box className={isMobileorTablet ? "card-mobile" : "card"}>
            <Heading level={6} color="#395E9D">
              All Primary Providers
            </Heading>

            <Heading level={3}>
              {staff ? getCountOfPrimaryProviders() : null}
            </Heading>
            {/* <Text size="small">View Providers</Text> */}
          </Box>
          <Box className={isMobileorTablet ? "card-mobile" : "card"}>
            <Heading level={6} color="#395E9D">
              Total Clinical staff on Rose
            </Heading>
            <Heading level={3}>{staff ? staff.length : null}</Heading>
            {/* <Text size="small">View Staff</Text> */}
          </Box>
          <Box className={isMobileorTablet ? "card-mobile" : "card"}>
            <Heading level={6} color="#395E9D">
              Total number of patients on Rose
            </Heading>
            <Heading level={3}>{patients ? patients.length : null}</Heading>
            {/* <Text size="small">View Patients</Text> */}
          </Box>
        </Box>
        <Billing />
      </Box>
    </>
  );
};

export default BillingHome;
