import React from "react";
import { Box } from "grommet";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ManualLogin from "./ManualLogin";
import LeftMessageCard from "./LeftMessageCard";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import theme from "../../../theme";
// import InfoBanner from "./InfoBanner";
// import Background from '../../../assets/login-back.svg'
// import Background from "../../../assets/logo.svg";

function Login() {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const user = useSelector((state) => state.app.user);

  if (user) {
    if (user.user) {
      if (
        user.user.role === "CLINIC_ADMIN" ||
        user.user.role === "CLINICAL_STAFF"
      )
        return <Redirect to="/dashboard/home" />;

      if (user.user.role === "CUSTOMER_SUCCESS")
        return <Redirect to="/dashboard/bulkUploads" />;

      if (user.user.role === "INVOICING_ADMIN")
        return <Redirect to="/dashboard/billingHome" />;

      if (user.user.role === "SALES_MANAGER")
        return <Redirect to="/dashboard/salesHome" />;

      if (user.user.role === "CAREGIVER")
        return <Redirect to="/dashboard/caregiverHome" />;

      if (
        user.user.role === "TWS_COORDINATOR" ||
        user.user.role === "TWS_CONCIERGE" ||
        user.user.role === "TWS_CASE_MANAGER"
      )
        return <Redirect to="/dashboard/home" />;

      if (user.user.role === "ACO_EXECUTIVE")
        return <Redirect to="/dashboard/acoexecutiveHome" />;
    }
  }

  return (
    <Box
      justify="center"
      align="center"
      background="brand"
      width="100%"
      height="100%"
    >
      <Box
        direction="row"
        align="center"
        justify="center"
        height="100%"
        className={isMobileorTablet ? "wrapper-mobile" : "wrapper"}
      >
        {isMobileorTablet ? null : (
          <Box
            align="center"
            justify="center"
            width="100%"
            height="100%"
            style={{ position: "relative" }}
          >
            <LeftMessageCard />
          </Box>
        )}
        <Box
          width={isMobileorTablet ? "100%" : "67%"}
          height="100%"
          background={theme.global.colors.light}
          align="center"
          justify="center"
          gap="large"
        >
          {/* <InfoBanner /> */}
          <ManualLogin />
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
