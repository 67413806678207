import React, { useEffect, useState } from "react";
import { Text } from "grommet";
import { useDispatch } from "react-redux";
import { getGroupInvoicesforPending } from "../../store/sales/actions";

const InvoicesPendingAction = ({ groupID }) => {
  const [loading, setLoading] = useState(false);
  const [countOfPending, setCountofPending] = useState(0);

  const dispatch = useDispatch();

  const onSuccess = ({ data }) => {
    let temp = 0;

    if (data)
      data.forEach((x) => {
        if (x.status === "open" || x.status === "draft") temp = temp + 1;
      });

    setCountofPending(temp);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getGroupInvoicesforPending(groupID, onSuccess));
  }, []);

  if (loading) return null;

  return (
    <Text size="small" color={countOfPending === 0 ? "green" : "red"}>
      {" "}
      {countOfPending ? countOfPending : "0"}
    </Text>
  );
};

export default InvoicesPendingAction;
