import React, { useState, useEffect } from "react";
import * as $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Redirect } from "react-router";
import Conversations from "./Conversations";
import { RTMInstance as rtm } from "./rtm-client";
import {
  getMessageHistory,
  markMessagesAsRead,
} from "../../../../store/patients/actions";
import RemoteLoginError from "../../../common/RemoteLoginError";

const { RtmTokenBuilder, RtmRole } = require("agora-access-token");
// import NoMessageIcon from '../../../../assets/no-messages.svg';

const Messages = ({ setDisplayOut, display }) => {
  const id = useSelector((state) => state.app.user.user.id);
  const user = useSelector((state) => state.app.user.user);
  const patient = useSelector((state) => state.patients.patient);

  if (!user.clinic.rose_chat) return <Redirect to="/page-not-found" />;

  const [lastMessage, setLastMessage] = useState();
  const [conversations, setConversations] = useState({});
  const [remoteShow, setRemoteShow] = useState(false);

  const uid = id.toString();
  const role = RtmRole.Rtm_User;

  const expirationTimeInSeconds = 36000;

  const currentTimestamp = Math.floor(Date.now() / 1000);

  const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds;

  const token = RtmTokenBuilder.buildToken(
    process.env.REACT_APP_AGORA_ID,
    process.env.REACT_APP_AGORA_CERTIFICATE,
    uid,
    role,
    privilegeExpiredTs
  );

  const dispatch = useDispatch();

  const updateScroll = () => {
    const element = document.getElementById("log");

    if (element) element.scrollTop = element.scrollHeight;
  };

  const addConversation = (message) => {
    setConversations({ ...conversations, [patient.id]: message });
  };

  const getSenderName = (senderID, staff, patients) => {
    if (staff.find((item) => item.id === senderID))
      return `${staff.find((item) => item.id === senderID).first_name} ${
        staff.find((item) => item.id === senderID).last_name
      }`;

    if (patients.find((item) => item.id === senderID))
      return `${patients.find((item) => item.id === senderID).first_name} ${
        patients.find((item) => item.id === senderID).last_name
      }`;

    return senderID;
  };

  const onSuccess = (data, staff, patients) => {
    if (data.count > 0) {
      data.results.reverse();
      data.results.forEach((element) => {
        if (element.message_type === "text") {
          if (element.sender === id)
            $("#log").append(
              `<div class="messageWrapperSend"><div class="timeStampMessageSend">${moment(
                element.created
              ).format("MM/DD/YY hh:mm a")}</div><div class="sentMessage" >${
                element.message
              }</div></div>`
            );
          else
            $("#log").append(
              `<div class="messageWrapperRecieve"><div class="recievedMessage" ><div class="messageSender">${getSenderName(
                element.sender,
                staff,
                patients
              )}</div>${
                element.message
              }</div><div class="timeStampMessageRecieve">${moment(
                element.created
              ).format("MM/DD/YY hh:mm a")}</div></div>`
            );
        }

        if (element.message_type === "image") {
          if (element.sender === id)
            $("#log").append(
              `<div class="messageWrapperSend"><div class="timeStampMessageSend">${moment(
                element.created
              ).format(
                "MM/DD/YY hh:mm a"
              )}</div><div class="sentMessageImage" ><img width="200px" src= '${
                element.message
              }'/></div></div>`
            );
          else
            $("#log").append(
              `<div class="messageWrapperRecieve"><div class="recievedMessageImage" ><div class="messageSender">${getSenderName(
                element.sender,
                staff,
                patients
              )}</div><img width="200px" src= '${
                element.message
              }'/></div><div class="timeStampMessageRecieve">${moment(
                element.created
              ).format("MM/DD/YY hh:mm a")}</div></div>`
            );
        }
      });
      updateScroll();
    }
  };

  const newSuccess = () => true;

  useEffect(() => {
    dispatch(getMessageHistory(patient.id, onSuccess));

    dispatch(markMessagesAsRead(patient.id, newSuccess));

    rtm.on("ChannelMessage", async (data) => {
      const msgObj = {
        message: data.args[0],
        from: data.args[1],
        timestamp: data.args[2].serverReceivedTs,
      };
      setLastMessage(msgObj);
      if (display) dispatch(markMessagesAsRead(msgObj.from, newSuccess));
    });

    rtm.on("ConnectionStateChanged", async (data, data2) => {
      if (data === "ABORTED" && data2 === "REMOTE_LOGIN") {
        rtm.logined = false;
        setRemoteShow(true);
      }
    });
  }, []);

  useEffect(() => {
    if (lastMessage) {
      addConversation(lastMessage);
    }
  }, [lastMessage]);

  if (!rtm.logined) {
    try {
      rtm.init("a27a98bc78bb4456aeec6f22e8f0156c");
      window.rtm = rtm;
      rtm
        .login(uid, token)
        .then(() => {
          rtm.updateLogined(true);
          rtm.joinChannel(patient.id.toString());
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      {remoteShow && <RemoteLoginError display={remoteShow} />}

      <Conversations
        uid={uid}
        peerID={patient.id}
        conversation={[conversations[patient.id]]}
        setConversations={setConversations}
        setDisplayOut={setDisplayOut}
        peerName={`${patient.first_name} ${patient.last_name}`}
      />
    </>
  );
};

export default Messages;
