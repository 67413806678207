import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import CustomToolTip from "./CustomToolTip";
import moment from "moment";

const LineGraphCategories = ({
  data,
  yAxisMax,
  nameToPut,
  yAxisLabel,
  heading,
  horizontalDatas,
  colors,
}) => {
  let xAxisData = horizontalDatas;

  const SeriesData = [];
  const getOption = () => {
    data.forEach((element, index) => {
      SeriesData[index] = element;
    });

    return {
      legend: {
        show: true,
        top: 270,
        itemStyle: {
          color: "transparent",
        },
        textStyle: {
          color: "#1B3664",
        },
        data: [nameToPut],
      },
      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${params.name}  <br/> ${params.seriesName}:  ${params.value}`;
        },
      },

      xAxis: {
        offset: 5,
        type: "category",
        data: xAxisData,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        max: yAxisMax,
        min: 0,
        name: yAxisLabel,
        nameLocation: "middle",
        nameTextStyle: {
          padding: [0, 0, 25, 0],
          color: "#999999",
        },
        axisLabel: {
          color: "#999999",
        },

        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },

          restore: {
            title: "Restore",
          },
          saveAsImage: {
            title: "Save As Image",
            name: nameToPut + ` ${moment(new Date()).format("MM DD YYYY")}`,
          },
        },
      },
      series: [
        {
          name: nameToPut,
          color: "#395E9D",
          data: SeriesData,
          type: "line",
          symbolSize: 15,
          symbol: "circle",
          lineStyle: {
            width: 5,
          },
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          {heading}
        </Text>
        <CustomToolTip
          outText="i"
          content="Single-item questions to assess the patient's daily mental well-being."
          subText="The 5-point scale ranges from 0 (great data) to 4 (awful data)."
        />
      </Box>
      <ReactEcharts option={getOption()} />
    </Box>
  );
};
export default LineGraphCategories;
