export const INITIALIZE = "app/INITIALIZE";
export const INITIALIZED = "app/INITIALIZED";
export const FETCH_USER = "app/FETCH_USER";
export const SET_USER = "app/SET_USER";
export const SIGNUP_USER = "app/SIGNUP_USER";
export const LOGOUT_USER = "app/LOGOUT_USER";
export const SET_ERROR = "app/ERROR";
export const CREATE_NOTIFICATION = "app/CREATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "app/REMOVE_NOTIFICATION";
export const SET_REDIRECT_TO = "app/SET_REDIRECT_TO";
export const SET_LOGGED_USER = "app/SET_LOGGED_USER";
export const LOGIN_MANUAL = "app/LOGIN_MANUAL";
export const RESET_PASSWORD = "app/RESET_PASSWORD";
export const VERIFY_TOKEN = "app/VERIFY_TOKEN";
export const SET_NEW_PASSWORD = "app/SET_NEW_PASSWORD";
export const CONFIRM_PASSWORD_SETTINGS = "app/CONFIRM_PASSWORD_SETTINGS";
export const UPDATE_PROFILE = "app/UPDATE_PROFILE";
export const SET_CLINIC_DROPDOWN_ID = "app/SET_CLINIC_DROPDOWN_ID";

export const initialize = () => ({
  type: INITIALIZE,
  payload: {},
});

export const initialized = (isInitialized) => ({
  type: INITIALIZED,
  payload: {
    isInitialized,
  },
});

export const resetPass = (form, loginError, onSuccess) => ({
  type: RESET_PASSWORD,
  payload: { form, loginError, onSuccess },
});

export const verifyToken = (token, onSucess, onError) => ({
  type: VERIFY_TOKEN,
  payload: { token, onSucess, onError },
});

export const setNewPassword = (token, newpassword) => ({
  type: SET_NEW_PASSWORD,
  payload: { token, newpassword },
});

export const fetchUser = () => ({
  type: FETCH_USER,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

export const signup = (location, form) => ({
  type: SIGNUP_USER,
  payload: { location, ...form },
});

export const logout = () => ({
  type: LOGOUT_USER,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: { error },
});

export const createNotification = (text, good, timeout = 6000) => ({
  type: CREATE_NOTIFICATION,
  payload: { text, good, open: true, timeout },
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
});

export const setRedirectTo = (to) => ({
  type: SET_REDIRECT_TO,
  payload: { to },
});

export const setLoggedUser = (user, access, refresh) => ({
  type: SET_LOGGED_USER,
  payload: { user, access, refresh },
});

export const loginManual = (form, loginError) => ({
  type: LOGIN_MANUAL,
  payload: { form, loginError },
});

export const confirmPasswordSettings = (form, loginError, onSuccess) => ({
  type: CONFIRM_PASSWORD_SETTINGS,
  payload: { form, loginError, onSuccess },
});

export const updateProfile = (form, loginError, onSuccess) => ({
  type: UPDATE_PROFILE,
  payload: { form, loginError, onSuccess },
});

export const setClinicDropdownID = (id) => ({
  type: SET_CLINIC_DROPDOWN_ID,
  payload: { id },
});
