import React from "react";
import { Text, Layer, Button, Box } from "grommet";
import UseAnimations from "react-useanimations";
import alertCircle from "react-useanimations/lib/alertCircle";
import { Close, Refresh } from "grommet-icons";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const RemoteLoginError = ({ display }) => {
  const dispatch = useDispatch();

  return (
    <>
      {display && (
        <Layer plain className="popup-card-flag" position="center">
          <Box gap="small" justify="center" align="center">
            <Box direction="row" gap="small" align="center">
              <UseAnimations
                animation={alertCircle}
                size={110}
                strokeColor="#e86661"
              />
              <Box>
                <Text color="brand" weight="bold">
                  Dual Login Detected
                </Text>

                <Text size="xsmall" color="black">
                  Someone else is already logged in using this account's
                  credentials. Please sign out of the other account and try
                  again.
                </Text>
              </Box>
            </Box>
            <Box direction="row" gap="small">
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#395E9D",
                  borderRadius: "8px",
                }}
                onClick={() => document.location.reload(true)}
                label="Reload"
                icon={<Refresh color="white" />}
                color="transparent"
              />
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#e86661",
                  borderRadius: "8px",
                }}
                onClick={() => dispatch(push(`/dashboard/home`))}
                label="Cancel"
                icon={<Close color="white" />}
                color="transparent"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default RemoteLoginError;
