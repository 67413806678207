import { Box, Text } from "grommet";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentResultsRecent } from "../../store/patients/actions";
import InPageLoaderNew from "./InPageLoaderNew";

const RecentAssessmentsBox = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user.user);
  const results = useSelector(
    (state) => state.patients.assessment_results_recent
  );

  useEffect(() => {
    dispatch(
      fetchAssessmentResultsRecent(user.id, () => {
        return true;
      })
    );
  }, []);

  if (!results) return <InPageLoaderNew />;
  return (
    <Box pad="medium" className="large-card" gap="small">
      <Text weight="bold" color="#395E9D">
        Assessments Taken Recently:
      </Text>
      <Box direction="row" style={{ flexWrap: "wrap" }} gap="medium">
        {Object.entries(results).map((result) => {
          if (result[1].length > 0) {
            return (
              <Box
                margin={{ vertical: "1%", horizontal: null }}
                direction="row"
                gap="small"
                background="#395E9D10"
                style={{ border: "1px solid #395E9D50" }}
                className="contain_width"
                pad="xsmall"
                round="4px"
              >
                <Text size="small">
                  <b>Assessment:</b> {result[0]}
                </Text>
                <Text size="small">
                  <b>Taken:</b> {moment(result[1].created).format("MM/DD/YYYY")}
                </Text>
              </Box>
            );
          } else return null;
        })}
      </Box>
    </Box>
  );
};

export default RecentAssessmentsBox;
