/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  // Collapsible,
  Layer,
} from "grommet";
// import { Up, Down } from "grommet-icons";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Logo from "../../../../assets/light-logo.png";
import Home from "../../../../assets/icons/home.svg";
// import Invoice from "../../../../assets/icons/Invoice.svg";

// import Sessions from "../../../assets/icons/sessions.svg";
// import Messages from "../../../assets/icons/messages.svg";
// import Timesheet from "../../../assets/icons/timesheet.svg";
// import Help from "../../../assets/icons/help.svg";
// import FAQ from "../../../assets/icons/faq.svg";
// import Resources from "../../../assets/icons/resources.svg";
import Logout from "../../../../assets/logout.png";
import Settings from "../../../../assets/icons/settings.svg";

import { logout } from "../../../../store/app/actions";

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.global.colors.light};
  width: 100%;
  text-decoration: none !important;
  border-left: 5px solid transparent !important;
  cursor: default;
  transition: 0.5s;

  &:hover {
    border-radius: 0px 10px 10px 0px !important;
    border-left: 5px solid #2bc2f7 !important;
    width: 100%;
    color: ${(props) => props.theme.global.colors.light};
    text-decoration: none !important;
    transition: 0.5s;
    cursor: default;
  }
  &.active {
    width: 100%;
    background-color: ${(props) => props.theme.global.colors.active};
    color: ${(props) => props.theme.global.colors.sideNav};
    text-decoration: none !important;
    font-weight: bold !important;
  }
`;

const BillingSidebar = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [open, setOpen] = useState(false);

  return (
    <Box
      width="270px"
      height="100vh"
      style={{
        position: "fixed",
        zIndex: 15,
      }}
      background="brand"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box
        width="100%"
        height="35%"
        align="center"
        justify="center"
        style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}
      >
        <Box>
          <Image src={Logo} alt="logo" />
          <Text margin={{ top: "12px" }} alignSelf="end">
            HEALTH
          </Text>
        </Box>
      </Box>

      <Box width="100%" height="5%" align="center" justify="start" />
      <Box
        width="100%"
        height="100%"
        style={{ overflow: "auto" }}
        justify="between"
      >
        <Box>
          <StyledLink exact to="/dashboard/billingHome">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image width="20px" src={Home} alt="home" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Home
              </Text>
            </Box>
          </StyledLink>
          <StyledLink exact to="/dashboard/settings">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image width="20px" src={Settings} alt="home" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Settings
              </Text>
            </Box>
          </StyledLink>
          {/* <StyledLink exact to="/dashboard/invoicing">
            <Box
              pad={{ vertical:"16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image width="20px" src={Invoice} alt="invoicing" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Invoicing
              </Text>
            </Box>
          </StyledLink> */}
          {/* <StyledLink exact to="/dashboard/billing">
            <Box
              pad={{ vertical: "16px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image src={Billing} alt="billing" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Billing
              </Text>
            </Box>
          </StyledLink> */}
        </Box>
        <Box width="100%" margin={{ bottom: "medium", top: "xlarge" }}>
          <Box
            className="logoutButton"
            direction="row"
            align="center"
            justify="center"
          >
            <Image src={Logout} alt="resources" height="18px" width="22px" />
            <Button
              size="small"
              onClick={() => {
                setShow(true);
              }}
              plain
              color="secondaryRed"
              label="Logout"
              style={{
                padding: "0 20px",
                textAlign: "center",
                fontSize: "small",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default BillingSidebar;
