import React, { useState } from "react";
import { Box, Text, DateInput, Button } from "grommet";
import { View } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import Loader from "react-loader-spinner";
import { fetchAssessmentResults } from "../../store/patients/actions";

const ToFromRow = ({ setStartDate, setEndDate, start, end }) => {
  const [internalStart, setInternalStart] = useState(start);
  const [internalEnd, setInternalEnd] = useState(end);
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);

  const patientId = split(search, "?", 2)[1];
  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    setLoading(false);
  };

  const processNewDates = () => {
    setLoading(true);
    dispatch(
      fetchAssessmentResults(patientId, onSuccess, internalStart, internalEnd)
    );
    setStartDate(internalStart);
    setEndDate(internalEnd);
  };

  return (
    <Box direction="row" pad="small" align="center" justify="end" gap="xsmall">
      {loading ? <Loader type="TailSpin" height={30} color="#395e9d" /> : null}
      <Text weight="bold" color="#4F4F4F80">
        From
      </Text>
      <DateInput
        format="mm/dd/yyyy"
        size="small"
        plain
        required
        name="start_date"
        calendarProps={{
          size: "medium",
          daysOfWeek: true,
          fill: true,
          animate: false,
          className: "padCal",
        }}
        value={internalStart}
        onChange={({ value }) => {
          setInternalStart(value);
        }}
        className="myDate"
        icon={<View size="xsmall" style={{ visibility: "hidden" }} />}
      />
      <Text weight="bold" color="#4F4F4F80">
        To
      </Text>
      <DateInput
        format="mm/dd/yyyy"
        size="small"
        plain
        required
        name="end_date"
        calendarProps={{
          size: "medium",
          daysOfWeek: true,
          fill: true,
          animate: false,
          className: "padCal",
        }}
        value={internalEnd}
        onChange={({ value }) => {
          setInternalEnd(value);
        }}
        className="myDate"
        icon={<View size="xsmall" style={{ visibility: "hidden" }} />}
      />
      <Button
        color="transparent"
        className="btn-trans"
        plain
        label="Apply"
        onClick={() => processNewDates()}
      />
    </Box>
  );
};
export default ToFromRow;
