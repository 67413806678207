import React from "react";
import useMobileDetect from "use-mobile-detect-hook";
import { Box, Image, Text } from "grommet";
import logo from "../../assets/rosesvglogo.svg";
import appstore from "../../assets/badge_appstore.png";
import playstore from "../../assets/badge_playstore.png";
import Loader from "react-loader-spinner";

const AppLinker = () => {
  let detectMobile = useMobileDetect();

  if (detectMobile.isMobile() && detectMobile.isAndroid()) {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.askrose.android"
    );
  }

  if (detectMobile.isMobile() && detectMobile.isIos()) {
    window.location.replace(
      "https://apps.apple.com/us/app/rose-smarter-mental-health/id1454630070"
    );
  }

  return (
    <Box gap="large" width="100%" height="100%" align="center" justify="center">
      <Image src={logo} width="200px" margin={{ bottom: "small" }} />

      {/* <Text color="brand">Welcome to Rose</Text> */}
      <Box align="center">
        <Loader type="Oval" color="black" height="30px" />

        <Text>We are redirecting you</Text>
      </Box>
      <Box align="center" gap="small">
        <Text size="small" textAlign="center">
          In case the automatic redirect does not work, please use the links
          below
        </Text>
        <Box direction="row" gap="small">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://apps.apple.com/us/app/rose-smarter-mental-health/id1454630070"
          >
            <Image src={appstore} width="100px" />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.askrose.android"
          >
            <Image src={playstore} width="100px" />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default AppLinker;
