import { push } from "connected-react-router";
import { Box, Heading } from "grommet";
import { FormPrevious } from "grommet-icons";
import React from "react";
import { useDispatch } from "react-redux";

export default ({ heading, link }) => {
  const dispatch = useDispatch();
  return (
    <Box
      width="100%"
      direction="row"
      gap="small"
      className="noOutline"
      onClick={() => dispatch(push(link))}
      align="center"
    >
      <FormPrevious color="brand" />
      <Heading level={3} color="brand">
        {heading}
      </Heading>
    </Box>
  );
};
