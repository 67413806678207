import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  DateInput,
  Form,
  Select,
} from "grommet";

import { Close, Down } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import moment from "moment";
import {
  fetchAssessmentsSchedule,
  setNewAssessment,
} from "../../store/patients/actions";
import ViewAssessmentDetailsModal from "./ViewAssessmentDetailsModal";

const NewAssessmentModal = ({ display, setDisplayOut }) => {
  const { search } = useSelector((state) => state.router.location);
  const patient = split(search, "?", 2)[1];
  const assessments = useSelector((state) => state.patients.assessment_types);
  const options = [];
  const dispatch = useDispatch();

  useEffect(() => {
    assessments.forEach((data, index) => {
      options[index] = { value: data.name, key: data.id };
    });
    options.sort((a, b) => a.value.localeCompare(b.value));
  });

  const [value, setValue] = useState({ selector: "Days" });
  const [viewAssessmentDetails, setViewAssessmentDetails] = useState(false);

  const onSuccessFetch = () => {
    setDisplayOut(false);
  };
  const onSuccess = () => {
    dispatch(fetchAssessmentsSchedule(onSuccessFetch, patient));
  };

  const submitNew = () => {
    dispatch(setNewAssessment(value, patient, onSuccess));
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-invite"
          position="center"
        >
          <Form
            value={value}
            onChange={(nextValue) => setValue(nextValue)}
            onSubmit={submitNew}
          >
            <Box gap="medium">
              <Box direction="row" justify="between">
                <Text size="medium" color="#395E9D" weight="bold">
                  New Assessment
                </Text>
                <Box>
                  <Close
                    onClick={() => setDisplayOut(false)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                {/* <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Choose Age Range
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      name="age"
                      options={["12-17","18-25","26-40","40+"]}
                      dropProps={{
                      style: { borderRadius: "10px" },
                      }}
                      size="small"
                      dropHeight="small"
                      required
                    />
                  </Box>
                </Box> */}
                <Box width="100%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Choose Assessment Type
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      name="assessment"
                      defaultValue=""
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={options}
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      required
                      dropHeight="small"
                      size="small"
                    />
                  </Box>
                  {/* eslint-disable-next-line no-prototype-builtins */}
                  {value.hasOwnProperty("assessment") ? (
                    <Box
                      className="noOutline"
                      margin={{ vertical: "xsmall" }}
                      onClick={() => setViewAssessmentDetails(true)}
                    >
                      <Text
                        size="small"
                        style={{ textDecoration: "underline" }}
                      >
                        View Assessment Questions
                      </Text>
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Start Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      size="small"
                      plain
                      required
                      name="start_date"
                      format="mm/dd/yyyy"
                      className="semiInput"
                      calendarProps={{
                        size: "medium",
                        bounds: [
                          moment(new Date()).format("YYYY-MM-DD"),
                          "2030-12-13",
                        ],
                        daysOfWeek: true,
                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    End Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      size="small"
                      plain
                      required
                      name="end_date"
                      format="mm/dd/yyyy"
                      className="semiInput"
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,
                        bounds: [
                          moment(new Date()).format("YYYY-MM-DD"),
                          "2030-12-13",
                        ],

                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  How Often
                </Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  margin={{ top: "6px" }}
                  className="customInput"
                  direction="row"
                  align="center"
                  justify="between"
                >
                  <Box width="60%">
                    <TextInput
                      size="small"
                      name="gap_days"
                      plain
                      type="numbers"
                      className="semiInput"
                      required
                    />
                  </Box>
                  <Box width="35%" className="inTextSelect" margin="xsmall">
                    <Select
                      size="small"
                      plain
                      required
                      name="selector"
                      options={["Days"]}
                      placeholder="Days"
                      defaultValue="Days"
                      icon={<Down size="small" color="#395E9D" />}
                      dropHeight="small"
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                justify="center"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  // eslint-disable-next-line no-prototype-builtins
                  disabled={!value.hasOwnProperty("assessment")}
                  label="Submit"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}

      {viewAssessmentDetails && (
        <ViewAssessmentDetailsModal
          assessments={assessments}
          toShow={value.assessment}
          display={viewAssessmentDetails}
          setDisplayOut={setViewAssessmentDetails}
        />
      )}
    </>
  );
};
export default NewAssessmentModal;
