import React, { useState } from "react";
import { Text, Layer, Image, Box, Button } from "grommet";
import { FormView } from "grommet-icons";
import { useSelector } from "react-redux";
import Question from "../../assets/icons/questionMark.svg";

const SecurityQuestionModal = ({ display, setDisplayOut }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const patient = useSelector((state) => state.patients.patient);

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card-payment"
          position="center"
        >
          <Box gap="small" align="center">
            <Image src={Question} width="54px" />
            <Text style={{ textAlign: "center" }} color="#395E9D">
              Confirm Patient’s Security Question. <br />
              Only proceed if the caller can answer the security question
            </Text>
            <Box
              background="#EFF2FE"
              style={{ color: "#395E9D" }}
              round="10px"
              width="100%"
              pad="small"
            >
              <Box pad="small">
                <Text size="small" style={{ textDecoration: "underline" }}>
                  Security Question
                </Text>
                <Text>
                  {patient.security_question
                    ? patient.security_question
                    : "No Question Found"}
                </Text>
              </Box>
              <Box height="2px" width="100%" background="#C4C4C450" />
              <Box pad="small">
                <Text size="small" style={{ textDecoration: "underline" }}>
                  Answer
                </Text>
                <Box width="100%" justify="between" direction="row">
                  {showAnswer ? (
                    <Text>
                      {patient.security_answer
                        ? patient.security_answer
                        : "No Answer Found"}
                    </Text>
                  ) : (
                    <Text>************</Text>
                  )}
                  <Box
                    onClick={() => setShowAnswer(!showAnswer)}
                    className="noOutline"
                    direction="row"
                    align="center"
                    gap="xsmall"
                  >
                    <FormView color="#395E9D" />
                    <Text style={{ textDecoration: "underline" }} size="xsmall">
                      {showAnswer ? "Hide" : "Reveal"} Answer
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              alignSelf="end"
              size="small"
              color="#395E9D"
              label="Close"
              className="btn-primary-dark"
              pad="small"
              onClick={() => setDisplayOut(false)}
            />
          </Box>
        </Layer>
      )}
    </>
  );
};
export default SecurityQuestionModal;
