import initialState from "../initialState";
import {
  SET_PATIENTS,
  SET_RED_PATIENTS,
  SET_YELLOW_PATIENTS,
  SET_SINGLE_PATIENT,
  SET_INVITED_PATIENTS,
  SET_SINGLE_INVITE,
  SET_SINGLE_PATIENT_BHI,
  SET_ASSESSMENTS_SCHEDULE,
  SET_SINGLE_ASSESSEMENT,
  SET_ASSESSMENT_TYPES,
  SET_PINNED_CONTENT,
  SET_ASSESSMENT_RESULTS,
  SET_ASSESSMENT_RESULTS_OLD,
  SET_BULK_INVITES,
  SET_BULK_FILES,
  SET_BHI_PATIENTS,
  SET_PATIENTS_DATA,
  SET_STAFF,
  SET_BHI_NOTES,
  SET_ASSESSMENT_RESULTS_RECENT,
  SET_MESSAGE_HISTORY,
  SET_ALL_MESSAGE_HISTORY,
  SET_CHAT_PATIENTS,
  SET_BULK_FILE,
  SET_ALL_BILLING_PATIENTS,
  SET_DEFAULT_SCHEDULE_ASSESSMENTS,
  SET_FIRE_FLAG_PATIENTS,
} from "./actions";

export default function patientsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_PATIENTS: {
      const { patients, count, next, previous } = payload;
      return { ...state, patients, count, next, previous };
    }
    case SET_ALL_BILLING_PATIENTS: {
      const { patients, count, next, previous } = payload;
      return {
        ...state,
        all_billing_patients: { patients, count, next, previous },
      };
    }
    case SET_CHAT_PATIENTS: {
      const { patients, count, next, previous } = payload;
      return { ...state, chat_patients: { patients, count, next, previous } };
    }
    case SET_PATIENTS_DATA: {
      const { patients, count, next, previous } = payload;
      return { ...state, patientsdata: { patients, count, next, previous } };
    }
    case SET_SINGLE_PATIENT: {
      const { patients } = payload;
      return { ...state, patient: patients };
    }
    case SET_SINGLE_PATIENT_BHI: {
      const { patients } = payload;
      return { ...state, patient_bhi: patients };
    }
    case SET_RED_PATIENTS: {
      const { patients, count } = payload;
      return { ...state, redPatients: [patients, count] };
    }
    case SET_YELLOW_PATIENTS: {
      const { patients, count } = payload;
      return { ...state, yellowPatients: [patients, count] };
    }
    case SET_INVITED_PATIENTS: {
      const { patients, count, next, previous } = payload;
      return { ...state, patients, count, next, previous };
    }
    case SET_SINGLE_INVITE: {
      const { data } = payload;
      return { ...state, invite: data };
    }
    case SET_ASSESSMENTS_SCHEDULE: {
      const { data } = payload;
      return { ...state, assessments_schedule: data };
    }
    case SET_SINGLE_ASSESSEMENT: {
      const { data } = payload;
      return { ...state, single_assessment: data };
    }
    case SET_ASSESSMENT_TYPES: {
      const { data } = payload;
      return { ...state, assessment_types: data };
    }
    case SET_PINNED_CONTENT: {
      const { data } = payload;
      return { ...state, pinned_content: data };
    }
    case SET_ASSESSMENT_RESULTS: {
      const { data } = payload;
      return { ...state, assessment_results: data };
    }
    case SET_ASSESSMENT_RESULTS_OLD: {
      const { data } = payload;
      return { ...state, assessment_results_old: data };
    }
    case SET_ASSESSMENT_RESULTS_RECENT: {
      const { data } = payload;
      return { ...state, assessment_results_recent: data };
    }
    case SET_BULK_INVITES: {
      const { data } = payload;
      return { ...state, invites: data };
    }
    case SET_BULK_FILES: {
      const { data } = payload;
      return { ...state, files: data };
    }
    case SET_BULK_FILE: {
      const { data } = payload;
      return { ...state, single_file: data };
    }
    case SET_BHI_PATIENTS: {
      const { data } = payload;
      return { ...state, bhiPatients: data };
    }
    case SET_STAFF: {
      const { data } = payload;
      return { ...state, staff: data };
    }
    case SET_BHI_NOTES: {
      const { data } = payload;
      return { ...state, bhi_notes: data };
    }
    case SET_MESSAGE_HISTORY: {
      const { data } = payload;
      return { ...state, current_messages: data };
    }
    case SET_ALL_MESSAGE_HISTORY: {
      const { data } = payload;
      return { ...state, all_messages: data };
    }
    case SET_DEFAULT_SCHEDULE_ASSESSMENTS: {
      const { data } = payload;
      return { ...state, default_assessments: data };
    }
    case SET_FIRE_FLAG_PATIENTS: {
      const { data } = payload;
      return {
        ...state,
        fireFlagPatients: data.results,
        fireFlagCount: data.count,
      };
    }
    default:
      return state;
  }
}
