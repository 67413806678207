import { Box } from "grommet";
import React from "react";
import { isMobileHook, iTabletHook } from "./Responsive";

export const RowCardContainer = ({
  notMakeResponsive,
  children,
  alignIn,
  justifyIn,
  style,
}) => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;

  return (
    <Box
      direction={
        isMobileorTablet ? (notMakeResponsive ? "row" : "column") : "row"
      }
      gap="small"
      width="100%"
      align={alignIn ? alignIn : null}
      justify={justifyIn ? justifyIn : null}
      style={style}
    >
      {children}
    </Box>
  );
};
