/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";

import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextInput,
  Select,
  Menu,
} from "grommet";
import { FormDown, Search } from "grommet-icons";
import { split } from "lodash";
import RedAlert from "../../../assets/icons/red-alert-flag.svg";
import YellowAlert from "../../../assets/icons/yellow-alert-flag.svg";
import GreenAlert from "../../../assets/icons/green-alert-flag.svg";

// import MailBox from "../../../assets/icons/feather_mail.svg";
import Loading from "../../common/InPageLoader";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import { fetchPatients, setPatients } from "../../../store/patients/actions";
import InviteModal from "../../common/InviteModal";
import BulkInviteModal from "../../common/BulkInviteModal";

const InactivePatients = () => {
  const user = useSelector((state) => state.app.user.user);

  const { search } = useSelector((state) => state.router.location);
  let preset = "";
  if (search) {
    preset = split(search, "?", 2)[1];
  }

  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState();
  const [sortValue, setSortValue] = useState();
  const [orderBy, setOrderBy] = useState("");
  const [filterBy, setFilterBy] = useState(
    preset === "RED" || preset === "YELLOW" ? preset : ""
  );
  const [isNew, setIsNew] = useState("");

  const [page, setPage] = useState(1);

  const patients = useSelector((state) => state.patients.patients) || null;
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const success = () => {
    setLoading(false);
  };

  const fetchPatientsData = () => {
    dispatch(
      fetchPatients(
        success,
        "999",
        searchTerm,
        orderBy,
        filterBy,
        isNew,
        page,
        "",
        "",
        "All",
        ""
      )
    );
  };

  useEffect(() => {
    setPatients(null);
    setLoading(true);
    fetchPatientsData();
  }, [searchTerm, orderBy, filterBy, isNew, page]);
  const getEligibilityButton = (elibility) => {
    if (elibility) {
      if (isMobileorTablet) return "btn-primary-mobile";

      return "btn-primary";
    }

    if (isMobileorTablet) return "btn-primary-dark-mobile";

    return "btn-primary-dark";
  };
  const getEligibility = (elibility) => {
    if (elibility) return "Enroll for BHI";

    return "View";
  };

  const getFlag = (flag) => {
    if (flag === "RED") return RedAlert;

    if (flag === "YELLOW") return YellowAlert;

    return GreenAlert;
  };
  return (
    <>
      {show && (
        <InviteModal display={show} setDisplayOut={(value) => setShow(value)} />
      )}
      {showBulk && (
        <BulkInviteModal
          display={showBulk}
          setDisplayOut={(value) => setShowBulk(value)}
        />
      )}

      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box
            direction="row"
            width={isMobileorTablet ? "100%" : null}
            justify={isMobileorTablet ? "between" : null}
            align="center"
          >
            <Heading level={3} color="#1B3664" alignSelf="center">
              Inactive Patients
            </Heading>
          </Box>
          {user.role === "CLINIC_ADMIN" ? (
            <Menu
              className="menu"
              size="small"
              icon={<FormDown color="white" />}
              color="#395e9d"
              style={{ marginTop: isMobileorTablet ? "16px" : null }}
              label="+ Invite Patients"
              dropAlign={{ top: "bottom" }}
              dropProps={{ className: "dropMenu" }}
              justifyContent="center"
              items={[
                {
                  label: "Add a Patient",
                  onClick: () => {
                    setShow(!show);
                  },
                },
                {
                  label: "Invite Multiple Patients",
                  onClick: () => {
                    setShowBulk(!showBulk);
                  },
                },
                {
                  label: "View Invited Patients",
                  onClick: () => {
                    dispatch(push(`/dashboard/invitedPatients`));
                  },
                },
              ]}
            />
          ) : (
            ""
          )}
        </Box>
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align="center"
          justify="between"
          margin={{ top: "small" }}
        >
          <Box
            round="small"
            align="center"
            justify="between"
            direction="row"
            height="38px"
            width={isMobileorTablet ? "100%" : "320px"}
            className="whiteBack"
            margin={isMobileorTablet ? { bottom: "small" } : null}
          >
            <TextInput
              name="search"
              plain
              type="text"
              className="searchInput"
              placeholder="Search"
              value={searchTerm}
              onChangeCapture={(e) => {
                setSearchTerm(e.target.value);
                setPage(1);
              }}
              required
            />

            <Box margin={{ right: "small" }}>
              <Search color="#395E9D" />
            </Box>
          </Box>
          <Box
            height={isMobileorTablet ? null : "38px"}
            align="center"
            width={isMobileorTablet ? "100%" : null}
            direction={isMobileorTablet ? "column" : "row"}
          >
            <Box
              round="small"
              className="whiteBack"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={[
                  "Red Alert Patients",
                  "Yellow Alert Patients",
                  "New Patients",
                ]}
                plain
                size="small"
                value={filterValue}
                placeholder="Filter:"
                style={{ color: "black" }}
                clear
                onChange={({ option }) => {
                  setFilterValue(option);
                  if (option === "Red Alert Patients") {
                    setFilterBy("RED");
                    setPage(1);
                  } else if (option === "Yellow Alert Patients") {
                    setFilterBy("YELLOW");
                    setPage(1);
                  } else if (option === "New Patients") {
                    setFilterBy("");
                    setIsNew("true");
                    setPage(1);
                  } else {
                    setFilterBy("");
                    setIsNew("");
                    setPage(1);
                  }
                }}
                dropHeight="small"
                dropProps={{ style: { borderRadius: "10px" } }}
              />
            </Box>
            <Box
              className="whiteBack"
              round="small"
              width={isMobileorTablet ? "100%" : null}
              margin={
                isMobileorTablet
                  ? { bottom: "small" }
                  : { horizontal: "medium" }
              }
            >
              <Select
                options={["First Name", "Last Name", "Flag", "Last Active"]}
                plain
                clear
                size="small"
                dropHeight="small"
                dropProps={{
                  style: { borderRadius: "10px" },
                }}
                value={sortValue}
                onChange={({ option }) => {
                  setSortValue(option);
                  setPage(1);
                  if (option === "First Name") setOrderBy("first_name");
                  else if (option === "Last Name") setOrderBy("last_name");
                  else if (option === "Flag") setOrderBy("flag");
                  else if (option === "Last Active") setOrderBy("last_active");
                  else setOrderBy("");
                }}
                placeholder="Sort By:"
                style={{ color: "black" }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          width="100%"
          justify="start"
          align="center"
          margin={{ top: "small" }}
        >
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="evenly"
            gap="medium"
            align="center"
          >
            <Box
              className="large-card"
              align="center"
              justify="center"
              style={{ overflowX: "auto" }}
              gap="small"
              pad="small"
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  <Table alignSelf="center">
                    <TableHeader>
                      <TableRow>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            FIRST NAME
                          </Text>
                        </TableCell>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            LAST NAME
                          </Text>
                        </TableCell>
                        <TableCell scope="col">
                          <Text size="small" weight="bold">
                            FLAG
                          </Text>
                        </TableCell>
                        <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            LAST ACTIVE
                          </Text>
                        </TableCell>
                        {/* <TableCell scope="col" align="center">
                          <Text size="small" weight="bold">
                            MESSAGE
                          </Text>
                        </TableCell> */}
                        <TableCell scope="col" align="center" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {patients &&
                        patients.map((patient) => {
                          const d = new Date();
                          d.setDate(d.getDate() - 30);
                          const lastActive = new Date(patient.last_active);
                          if (lastActive < d)
                            return (
                              <TableRow key={patient.id}>
                                <TableCell scope="row">
                                  <Text size="small" weight="bold">
                                    {patient.first_name}
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text size="small" weight="bold">
                                    {patient.last_name}
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Image
                                    width="15px"
                                    src={getFlag(patient.flag)}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Text size="xsmall" weight="bold">
                                    {patient.last_active
                                      ? moment(patient.last_active).format(
                                          "MM/DD/YYYY h:mm:ss a"
                                        )
                                      : null}
                                  </Text>
                                </TableCell>
                                {/* <TableCell>
                                <Box justify="center" align="center">
                                  <Image src={MailBox} alt="message" />
                                </Box>
                              </TableCell> */}
                                <TableCell>
                                  <Box
                                    direction="row"
                                    justify="center"
                                    align="center"
                                  >
                                    <Button
                                      // eslint-disable-next-line no-nested-ternary
                                      className={
                                        patient.bhi_enabled
                                          ? isMobileorTablet
                                            ? "btn-primary-dark-mobile"
                                            : "btn-primary-dark"
                                          : getEligibilityButton(
                                              patient.bhi_eligible
                                            )
                                      }
                                      color="transparent"
                                      margin={{ horizontal: "small" }}
                                      size="small"
                                      label={
                                        patient.bhi_enabled
                                          ? "View"
                                          : getEligibility(patient.bhi_eligible)
                                      }
                                      onClick={() =>
                                        dispatch(
                                          push(
                                            `/dashboard/patient/?${patient.id}`
                                          )
                                        )
                                      }
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );

                          return null;
                        })}
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InactivePatients;
