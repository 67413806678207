import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Image,
} from "grommet";
import { useSelector, useDispatch } from "react-redux";
import { split } from "lodash";
import moment from "moment";
import { Edit, FormTrash } from "grommet-icons";
import arrowUp from "react-useanimations/lib/arrowUpCircle";
import UseAnimations from "react-useanimations";
import ProfileBoxCommon from "../../common/ProfileBoxCommon";
import NewAssessmentModal from "../../common/NewAssessmentModal";
import Loading from "../../common/Loading";
import {
  fetchAssessmentsSchedule,
  getSingleAssessment,
  getAssessments,
} from "../../../store/patients/actions";
import EditAssessmentModal from "../../common/EditAssessmentModal";
import NoAssess from "../../../assets/NoAssess.png";
import DeleteAssessmentModal from "../../common/DeleteAssessmentModal";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import ViewAssessmentDetailsModal from "../../common/ViewAssessmentDetailsModal";
import CustomToolTip from "../../common/CustomToolTip";
import { Mixpanel } from "../../../services/mixpanel/Mixpanel";
import ExtendAssessmentModal from "../../common/ExtendAssessmentModal";

const ScheduleAssessments = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [viewAssessmentDetails, setViewAssessmentDetails] = useState(false);
  const user = useSelector((state) => state.app.user);

  const [show, setShow] = useState();
  const [editShow, setEditShow] = useState();
  const [extendShow, setExtendShow] = useState();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [toViewAssessment, setToViewAssessment] = useState();

  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const patientId = split(search, "?", 2)[1];

  const onSuccessEdit = () => {
    setEditShow(!editShow);
  };

  const setToEdit = (id) => {
    dispatch(getSingleAssessment(id, onSuccessEdit));
  };

  const onSuccessExtend = () => {
    setExtendShow(!extendShow);
  };

  const setToExtend = (id) => {
    dispatch(getSingleAssessment(id, onSuccessExtend));
  };

  const onSuccessNew = () => {
    setShow(!editShow);
  };

  const setToNew = () => {
    dispatch(getAssessments(onSuccessNew, patientId));
  };

  const assessments = useSelector((state) => state.patients.assessment_types);

  const schedules =
    useSelector((state) => state.patients.assessments_schedule) || [];

  const [loading, setLoading] = useState();

  const onSuccess = () => {
    setLoading(false);
  };

  const onSuccessNothing = () => true;

  useEffect(() => {
    setLoading(true);

    dispatch(getAssessments(onSuccessNothing));

    dispatch(fetchAssessmentsSchedule(onSuccess, patientId));
    Mixpanel.time_event("Time Spent on Patient Dashboard");

    return () => {
      Mixpanel.track("Time Spent on Patient Dashboard", {
        Patient: split(search, "?", 2)[1],
        User: `${user.user.first_name} ${user.user.last_name}`,
        Clinic: user.user.clinic.name,
        "User Type": user.user.role,
      });
    };
  }, []);

  if (loading) return <Loading />;

  const getSchedule = (gapDays) => `Every ${gapDays} Days`;

  const deleteSchedule = (idToDelete) => {
    setDeleteID(idToDelete);
    setDeleteShow(true);
  };

  const checkDate = (endDate) => {
    const dateTocheck = new Date(endDate);
    const today = new Date();

    return dateTocheck > today;
  };

  return (
    <>
      {show && (
        <NewAssessmentModal
          display={show}
          setDisplayOut={(value) => setShow(value)}
        />
      )}
      {editShow && (
        <EditAssessmentModal
          display={editShow}
          setDisplayOut={(value) => setEditShow(value)}
        />
      )}
      {extendShow && (
        <ExtendAssessmentModal
          display={extendShow}
          setDisplayOut={(value) => setExtendShow(value)}
        />
      )}
      {deleteShow && (
        <DeleteAssessmentModal
          deleteID={deleteID}
          display={deleteShow}
          setDisplayOut={(value) => setDeleteShow(value)}
        />
      )}
      {viewAssessmentDetails && assessments && (
        <ViewAssessmentDetailsModal
          assessments={assessments}
          toShow={toViewAssessment}
          display={viewAssessmentDetails}
          setDisplayOut={setViewAssessmentDetails}
        />
      )}

      <Box width="100%">
        <ProfileBoxCommon />
        <Box pad="medium" gap={isMobileorTablet ? "small" : null}>
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            align={isMobileorTablet ? "start" : "center"}
            justify={isMobileorTablet ? null : "between"}
          >
            <Text weight="bold" size="large" color="#395E9D">
              Schedule Assessments
            </Text>
            <Button
              size="small"
              margin={isMobileorTablet ? { vertical: "small" } : null}
              onClick={() => setToNew()}
              className="btn-primary-teal"
              color="transparent"
              label="+ Schedule New Assessment"
            />
          </Box>
          <Box
            className="large-card"
            height="100%"
            margin={{ top: "20px" }}
            style={{ overflowX: "auto" }}
            gap="small"
            pad="small"
          >
            {schedules.length === 0 ? (
              <Box alignSelf="center" align="center" justify="center">
                <Image margin={{ top: "small" }} src={NoAssess} />
                <Text>No Scheduled Assessments</Text>
              </Box>
            ) : (
              <Table alignSelf="center">
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col" align="center">
                      <Text size="small" weight="bold">
                        ASSESSMENT
                      </Text>
                    </TableCell>
                    <TableCell scope="col" align="center">
                      <Text size="small" weight="bold">
                        SCHEDULE
                      </Text>
                    </TableCell>
                    {isMobileorTablet ? null : (
                      <TableCell scope="col" align="center">
                        <Text size="small" weight="bold">
                          DATE STARTED
                        </Text>
                      </TableCell>
                    )}

                    <TableCell scope="col" align="center">
                      <Text size="small" weight="bold">
                        NEXT
                      </Text>
                    </TableCell>
                    <TableCell scope="col" align="center">
                      <Text size="small" weight="bold">
                        STATUS
                      </Text>
                    </TableCell>
                    <TableCell scope="col" align="center" />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {schedules &&
                    schedules.map((schedule) => (
                      <TableRow key={schedule.id}>
                        <TableCell align="center">
                          <Box
                            className="noOutline"
                            onClick={() => {
                              setToViewAssessment(schedule.assessment);
                              setViewAssessmentDetails(true);
                            }}
                          >
                            <Text size="small" weight="bold">
                              {schedule.assessment
                                ? schedule.assessment_name
                                : null}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Text size="small" weight="bold">
                            {schedule.gap_days
                              ? getSchedule(schedule.gap_days)
                              : null}
                          </Text>
                        </TableCell>
                        {isMobileorTablet ? null : (
                          <TableCell align="center">
                            <Text size="small" weight="bold">
                              {schedule.start_date
                                ? moment(schedule.start_date).format(
                                    "MMM Do, YYYY"
                                  )
                                : null}
                            </Text>
                          </TableCell>
                        )}
                        <TableCell align="center">
                          <Text size="small" weight="bold">
                            {schedule.next_date &&
                            new Date(schedule.next_date) >= new Date() ? (
                              moment(schedule.next_date).format("MMM Do, YYYY")
                            ) : (
                              <Box
                                direction="row"
                                align="center"
                                justify="center"
                                gap="small"
                              >
                                {moment(schedule.next_date).format(
                                  "MMM Do, YYYY"
                                )}
                                <CustomToolTip
                                  outText="i"
                                  content={`This patient was scheduled for an assessment on ${moment(
                                    schedule.next_date
                                  ).format(
                                    "MMM Do, YYYY"
                                  )}, which they did not attempt`}
                                  subText="This assessment will be prompted to them on next login"
                                  classIn="TooltipDanger"
                                />
                              </Box>
                            )}
                          </Text>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            className={
                              checkDate(schedule.end_date)
                                ? "activeClass"
                                : "inActiveClass"
                            }
                            direction="row"
                            gap="xsmall"
                            align="center"
                            justify="center"
                          >
                            <Text size="xsmall" weight="bold">
                              {checkDate(schedule.end_date)
                                ? "Active"
                                : `Ended`}
                            </Text>

                            {checkDate(schedule.end_date) ? null : (
                              <Box
                                className="noOutline"
                                onClick={() => setToExtend(schedule.id)}
                              >
                                <UseAnimations
                                  animation={arrowUp}
                                  size={20}
                                  strokeColor="#eb5757"
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box direction="row" justify="center">
                            <Button
                              className="btn-primary-2D9CDB"
                              color="transparent"
                              margin={{ horizontal: "small" }}
                              size="small"
                              onClick={() => {
                                setToEdit(schedule.id);
                              }}
                              icon={<Edit color="white" size="small" />}
                              label="Edit"
                            />
                            <Button
                              plain
                              icon={<FormTrash color="#EB5757" />}
                              onClick={() => {
                                deleteSchedule(schedule.id);
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ScheduleAssessments;
