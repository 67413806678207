/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Text, Image, Button, Collapsible, Layer } from "grommet";
import {
  Menu,
  Close,
  // CircleInformation, Catalog,
  Logout,
} from "grommet-icons";
import { withRouter } from "react-router-dom";
// import styled from "styled-components";
import { useDispatch } from "react-redux";
// import Messages from "../../../assets/icons/messages.svg";

import Logo from "../../../assets/light-logo.png";

import { logout } from "../../../store/app/actions";

// const StyledLink = styled(NavLink)`
//   color: ${(props) => props.theme.global.colors.light};
//   width: 100%;
//   text-decoration: none !important;
//   cursor: context-menu;
//   transition: 0.5s;

//   &:hover {
//     color: ${(props) => props.theme.global.colors.light};
//     text-decoration: none !important;
//     cursor: context-menu;
//   }
//   &.active {
//     width: 100%;
//     background-color: ${(props) => props.theme.global.colors.active};
//     color: ${(props) => props.theme.global.colors.sideNav};
//     text-decoration: none !important;
//   }
// `;

const MobileMenuBulk = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <Box width="100%" background="brand" align="center" justify="center">
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
        >
          <Box align="center" className="popup-card-mobile">
            <Text size="medium" textAlign="center">
              {" "}
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box justify="center" margin="small" pad={{ top: "small" }}>
        <Image src={Logo} alt="logo" />
      </Box>

      <Box
        pad="medium"
        direction="row"
        width="100%"
        align="center"
        justify="between"
        onClick={() => setOpen(!open)}
        className="hamburger-row"
      >
        <Box direction="row">
          {open ? <Close /> : <Menu />}
          <Text size="small" style={{ paddingLeft: "32px" }}>
            Menu
          </Text>
        </Box>
      </Box>
      <Box className="collapsible-menu">
        <Collapsible open={open}>
          <Box
            direction="row"
            pad="medium"
            width="100%"
            align="center"
            height="small"
            margin={{ left: "xsmall" }}
          >
            <Logout color="secondaryRed" />
            <Button
              margin={{ left: "medium" }}
              onClick={() => {
                setShow(true);
              }}
              style={{
                fontSize: "small",
                fontWeight: "bold",
              }}
              plain
              color="secondaryRed"
              label="Logout"
            />
          </Box>
        </Collapsible>
      </Box>
    </Box>
  );
});

export default MobileMenuBulk;
