import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  // Text,
} from "grommet";
// import { push } from "connected-react-router";
import Loader from "../../../common/InPageLoader";
import Methods from "./Methods";
// import ServiceFees from "../../../common/ServiceFees";
// import InvoiceHistory from "../../../common/InvoiceHistory";

const Invoicing = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box width="100%" pad="medium" gap="small">
        <Box className="large-card" pad="medium" gap="small">
          <Text color="#395E9D" weight="bold">
            Invoicing
          </Text>
          <Text size="small">
            This dashboard shows your monthly invoices from Rose. If you have
            questions about the fees on this page, review Exhibit A of your
            Master Service Agreement, or contact your Rose sales representative
          </Text>
        </Box>

        {/* <Box direction="row" gap="small">
          <ServiceFees /> */}
        <Methods />
        {/* </Box>
        <InvoiceHistory /> */}
      </Box>
    </>
  );
};

export default Invoicing;
