/* eslint-disable import/prefer-default-export */
const OnetoNine = [
  {
    value: "F0150 Vascular dementia without behavioral disturbance ",
    label: "F0150 Vascular dementia without behavioral disturbance ",
  },
  {
    value: "F0151 Vascular dementia with behavioral disturbance ",
    label: "F0151 Vascular dementia with behavioral disturbance ",
  },
  {
    value:
      "F0280 Dementia in other diseases classified elsewhere without behavioral disturbance ",
    label:
      "F0280 Dementia in other diseases classified elsewhere without behavioral disturbance ",
  },
  {
    value:
      "F0281 Dementia in other diseases classified elsewhere with behavioral disturbance ",
    label:
      "F0281 Dementia in other diseases classified elsewhere with behavioral disturbance ",
  },
  {
    value: "F0390 Unspecified dementia without behavioral disturbance ",
    label: "F0390 Unspecified dementia without behavioral disturbance ",
  },
  {
    value: "F0391 Unspecified dementia with behavioral disturbance ",
    label: "F0391 Unspecified dementia with behavioral disturbance ",
  },
  {
    value: "F04 Amnestic disorder due to known physiological condition ",
    label: "F04 Amnestic disorder due to known physiological condition ",
  },
  {
    value: "F05 Delirium due to known physiological condition ",
    label: "F05 Delirium due to known physiological condition ",
  },
  {
    value:
      "F060 Psychotic disorder with hallucinations due to known physiological condition ",
    label:
      "F060 Psychotic disorder with hallucinations due to known physiological condition ",
  },
  {
    value: "F061 Catatonic disorder due to known physiological condition ",
    label: "F061 Catatonic disorder due to known physiological condition ",
  },
  {
    value:
      "F062 Psychotic disorder with delusions due to known physiological condition ",
    label:
      "F062 Psychotic disorder with delusions due to known physiological condition ",
  },
  {
    value:
      "F0630 Mood disorder due to known physiological condition, unspecified ",
    label:
      "F0630 Mood disorder due to known physiological condition, unspecified ",
  },
  {
    value:
      "F0631 Mood disorder due to known physiological condition with depressive features ",
    label:
      "F0631 Mood disorder due to known physiological condition with depressive features ",
  },
  {
    value:
      "F0632 Mood disorder due to known physiological condition with major depressive-like episode F0633 Mood disorder due to known physiological condition with manic features ",
    label:
      "F0632 Mood disorder due to known physiological condition with major depressive-like episode F0633 Mood disorder due to known physiological condition with manic features ",
  },
  {
    value:
      "F0634 Mood disorder due to known physiological condition with mixed features ",
    label:
      "F0634 Mood disorder due to known physiological condition with mixed features ",
  },
  {
    value: "F064 Anxiety disorder due to known physiological condition ",
    label: "F064 Anxiety disorder due to known physiological condition ",
  },
  {
    value:
      "F068 Other specified mental disorders due to known physiological condition ",
    label:
      "F068 Other specified mental disorders due to known physiological condition ",
  },
  {
    value: "F070 Personality change due to known physiological condition ",
    label: "F070 Personality change due to known physiological condition ",
  },
  {
    value: "F0781 Postconcussional syndrome ",
    label: "F0781 Postconcussional syndrome ",
  },
  {
    value:
      "F0789 Other personality and behavioral disorders due to known physiological condition ",
    label:
      "F0789 Other personality and behavioral disorders due to known physiological condition ",
  },
  {
    value:
      "F079 Unspecified personality and behavioral disorder due to known physiological condition ",
    label:
      "F079 Unspecified personality and behavioral disorder due to known physiological condition ",
  },
  {
    value:
      "F09 Unspecified mental disorder due to known physiological condition ",
    label:
      "F09 Unspecified mental disorder due to known physiological condition ",
  },
];

const TentoNineteen = [
  {
    value: "F1010 Alcohol abuse, uncomplicated ",
    label: "F1010 Alcohol abuse, uncomplicated ",
  },
  {
    value: "F10120 Alcohol abuse with intoxication, uncomplicated ",
    label: "F10120 Alcohol abuse with intoxication, uncomplicated ",
  },
  {
    value: "F10121 Alcohol abuse with intoxication delirium ",
    label: "F10121 Alcohol abuse with intoxication delirium ",
  },
  {
    value: "F10129 Alcohol abuse with intoxication, unspecified ",
    label: "F10129 Alcohol abuse with intoxication, unspecified ",
  },
  {
    value: "F1014 Alcohol abuse with alcohol-induced mood disorder ",
    label: "F1014 Alcohol abuse with alcohol-induced mood disorder ",
  },
  {
    value:
      "F10150 Alcohol abuse with alcohol-induced psychotic disorder with delusions ",
    label:
      "F10150 Alcohol abuse with alcohol-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F10151 Alcohol abuse with alcohol-induced psychotic disorder with hallucinations ",
    label:
      "F10151 Alcohol abuse with alcohol-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F10159 Alcohol abuse with alcohol-induced psychotic disorder, unspecified ",
    label:
      "F10159 Alcohol abuse with alcohol-induced psychotic disorder, unspecified ",
  },
  {
    value: "F10180 Alcohol abuse with alcohol-induced anxiety disorder ",
    label: "F10180 Alcohol abuse with alcohol-induced anxiety disorder ",
  },
  {
    value: "F10181 Alcohol abuse with alcohol-induced sexual dysfunction ",
    label: "F10181 Alcohol abuse with alcohol-induced sexual dysfunction ",
  },
  {
    value: "F10182 Alcohol abuse with alcohol-induced sleep disorder ",
    label: "F10182 Alcohol abuse with alcohol-induced sleep disorder ",
  },
  {
    value: "F10188 Alcohol abuse with other alcohol-induced disorder ",
    label: "F10188 Alcohol abuse with other alcohol-induced disorder ",
  },
  {
    value: "F1019 Alcohol abuse with unspecified alcohol-induced disorder ",
    label: "F1019 Alcohol abuse with unspecified alcohol-induced disorder ",
  },
  {
    value: "F1020 Alcohol dependence, uncomplicated ",
    label: "F1020 Alcohol dependence, uncomplicated ",
  },
  {
    value: "F1021 Alcohol dependence, in remission ",
    label: "F1021 Alcohol dependence, in remission ",
  },
  {
    value: "F10220 Alcohol dependence with intoxication, uncomplicated ",
    label: "F10220 Alcohol dependence with intoxication, uncomplicated ",
  },
  {
    value: "F10221 Alcohol dependence with intoxication delirium ",
    label: "F10221 Alcohol dependence with intoxication delirium ",
  },
  {
    value: "F10229 Alcohol dependence with intoxication, unspecified ",
    label: "F10229 Alcohol dependence with intoxication, unspecified ",
  },
  {
    value: "F10230 Alcohol dependence with withdrawal, uncomplicated ",
    label: "F10230 Alcohol dependence with withdrawal, uncomplicated ",
  },
  {
    value: "F10231 Alcohol dependence with withdrawal delirium ",
    label: "F10231 Alcohol dependence with withdrawal delirium ",
  },
  {
    value:
      "F10232 Alcohol dependence with withdrawal with perceptual disturbance ",
    label:
      "F10232 Alcohol dependence with withdrawal with perceptual disturbance ",
  },
  {
    value: "F10239 Alcohol dependence with withdrawal, unspecified ",
    label: "F10239 Alcohol dependence with withdrawal, unspecified ",
  },
  {
    value: "F1024 Alcohol dependence with alcohol-induced mood disorder ",
    label: "F1024 Alcohol dependence with alcohol-induced mood disorder ",
  },
  {
    value:
      "F10250 Alcohol dependence with alcohol-induced psychotic disorder with delusions ",
    label:
      "F10250 Alcohol dependence with alcohol-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F10251 Alcohol dependence with alcohol-induced psychotic disorder with hallucinations ",
    label:
      "F10251 Alcohol dependence with alcohol-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F10259 Alcohol dependence with alcohol-induced psychotic disorder, unspecified ",
    label:
      "F10259 Alcohol dependence with alcohol-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1026 Alcohol dependence with alcohol-induced persisting amnestic disorder ",
    label:
      "F1026 Alcohol dependence with alcohol-induced persisting amnestic disorder ",
  },
  {
    value: "F1027 Alcohol dependence with alcohol-induced persisting dementia ",
    label: "F1027 Alcohol dependence with alcohol-induced persisting dementia ",
  },
  {
    value: "F10280 Alcohol dependence with alcohol-induced anxiety disorder ",
    label: "F10280 Alcohol dependence with alcohol-induced anxiety disorder ",
  },
  {
    value: "F10281 Alcohol dependence with alcohol-induced sexual dysfunction ",
    label: "F10281 Alcohol dependence with alcohol-induced sexual dysfunction ",
  },
  {
    value: "F10282 Alcohol dependence with alcohol-induced sleep disorder ",
    label: "F10282 Alcohol dependence with alcohol-induced sleep disorder ",
  },
  {
    value: "F10288 Alcohol dependence with other alcohol-induced disorder ",
    label: "F10288 Alcohol dependence with other alcohol-induced disorder ",
  },
  {
    value:
      "F1029 Alcohol dependence with unspecified alcohol-induced disorder ",
    label:
      "F1029 Alcohol dependence with unspecified alcohol-induced disorder ",
  },
  {
    value: "F10920 Alcohol use, unspecified with intoxication, uncomplicated ",
    label: "F10920 Alcohol use, unspecified with intoxication, uncomplicated ",
  },
  {
    value: "F10921 Alcohol use, unspecified with intoxication delirium ",
    label: "F10921 Alcohol use, unspecified with intoxication delirium ",
  },
  {
    value: "F10929 Alcohol use, unspecified with intoxication, unspecified ",
    label: "F10929 Alcohol use, unspecified with intoxication, unspecified ",
  },
  {
    value: "F1094 Alcohol use, unspecified with alcohol-induced mood disorder ",
    label: "F1094 Alcohol use, unspecified with alcohol-induced mood disorder ",
  },
  {
    value:
      "F10950 Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions ",
    label:
      "F10950 Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F10951 Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations F10959 Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified ",
    label:
      "F10951 Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations F10959 Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1096 Alcohol use, unspecified with alcohol-induced persisting amnestic disorder ",
    label:
      "F1096 Alcohol use, unspecified with alcohol-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1097 Alcohol use, unspecified with alcohol-induced persisting dementia ",
    label:
      "F1097 Alcohol use, unspecified with alcohol-induced persisting dementia ",
  },
  {
    value:
      "F10980 Alcohol use, unspecified with alcohol-induced anxiety disorder ",
    label:
      "F10980 Alcohol use, unspecified with alcohol-induced anxiety disorder ",
  },
  {
    value:
      "F10981 Alcohol use, unspecified with alcohol-induced sexual dysfunction ",
    label:
      "F10981 Alcohol use, unspecified with alcohol-induced sexual dysfunction ",
  },
  {
    value:
      "F10982 Alcohol use, unspecified with alcohol-induced sleep disorder ",
    label:
      "F10982 Alcohol use, unspecified with alcohol-induced sleep disorder ",
  },
  {
    value:
      "F10988 Alcohol use, unspecified with other alcohol-induced disorder ",
    label:
      "F10988 Alcohol use, unspecified with other alcohol-induced disorder ",
  },
  {
    value:
      "F1099 Alcohol use, unspecified with unspecified alcohol-induced disorder ",
    label:
      "F1099 Alcohol use, unspecified with unspecified alcohol-induced disorder ",
  },
  {
    value: "F1110 Opioid abuse, uncomplicated ",
    label: "F1110 Opioid abuse, uncomplicated ",
  },
  {
    value: "F11120 Opioid abuse with intoxication, uncomplicated ",
    label: "F11120 Opioid abuse with intoxication, uncomplicated ",
  },
  {
    value: "F11121 Opioid abuse with intoxication delirium ",
    label: "F11121 Opioid abuse with intoxication delirium ",
  },
  {
    value: "F11122 Opioid abuse with intoxication with perceptual disturbance ",
    label: "F11122 Opioid abuse with intoxication with perceptual disturbance ",
  },
  {
    value: "F11129 Opioid abuse with intoxication, unspecified ",
    label: "F11129 Opioid abuse with intoxication, unspecified ",
  },
  {
    value: "F1114 Opioid abuse with opioid-induced mood disorder ",
    label: "F1114 Opioid abuse with opioid-induced mood disorder ",
  },
  {
    value:
      "F11150 Opioid abuse with opioid-induced psychotic disorder with delusions ",
    label:
      "F11150 Opioid abuse with opioid-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F11151 Opioid abuse with opioid-induced psychotic disorder with hallucinations ",
    label:
      "F11151 Opioid abuse with opioid-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F11159 Opioid abuse with opioid-induced psychotic disorder, unspecified ",
    label:
      "F11159 Opioid abuse with opioid-induced psychotic disorder, unspecified ",
  },
  {
    value: "F11181 Opioid abuse with opioid-induced sexual dysfunction ",
    label: "F11181 Opioid abuse with opioid-induced sexual dysfunction ",
  },
  {
    value: "F11182 Opioid abuse with opioid-induced sleep disorder ",
    label: "F11182 Opioid abuse with opioid-induced sleep disorder ",
  },
  {
    value: "F11188 Opioid abuse with other opioid-induced disorder ",
    label: "F11188 Opioid abuse with other opioid-induced disorder ",
  },
  {
    value: "F1119 Opioid abuse with unspecified opioid-induced disorder ",
    label: "F1119 Opioid abuse with unspecified opioid-induced disorder ",
  },
  {
    value: "F1120 Opioid dependence, uncomplicated ",
    label: "F1120 Opioid dependence, uncomplicated ",
  },
  {
    value: "F1121 Opioid dependence, in remission ",
    label: "F1121 Opioid dependence, in remission ",
  },
  {
    value: "F11220 Opioid dependence with intoxication, uncomplicated ",
    label: "F11220 Opioid dependence with intoxication, uncomplicated ",
  },
  {
    value: "F11221 Opioid dependence with intoxication delirium ",
    label: "F11221 Opioid dependence with intoxication delirium ",
  },
  {
    value:
      "F11222 Opioid dependence with intoxication with perceptual disturbance ",
    label:
      "F11222 Opioid dependence with intoxication with perceptual disturbance ",
  },
  {
    value: "F11229 Opioid dependence with intoxication, unspecified ",
    label: "F11229 Opioid dependence with intoxication, unspecified ",
  },
  {
    value: "F1123 Opioid dependence with withdrawal ",
    label: "F1123 Opioid dependence with withdrawal ",
  },
  {
    value: "F1124 Opioid dependence with opioid-induced mood disorder ",
    label: "F1124 Opioid dependence with opioid-induced mood disorder ",
  },
  {
    value:
      "F11250 Opioid dependence with opioid-induced psychotic disorder with delusions ",
    label:
      "F11250 Opioid dependence with opioid-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F11251 Opioid dependence with opioid-induced psychotic disorder with hallucinations ",
    label:
      "F11251 Opioid dependence with opioid-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F11259 Opioid dependence with opioid-induced psychotic disorder, unspecified ",
    label:
      "F11259 Opioid dependence with opioid-induced psychotic disorder, unspecified ",
  },
  {
    value: "F11281 Opioid dependence with opioid-induced sexual dysfunction ",
    label: "F11281 Opioid dependence with opioid-induced sexual dysfunction ",
  },
  {
    value: "F11282 Opioid dependence with opioid-induced sleep disorder ",
    label: "F11282 Opioid dependence with opioid-induced sleep disorder ",
  },
  {
    value: "F11288 Opioid dependence with other opioid-induced disorder ",
    label: "F11288 Opioid dependence with other opioid-induced disorder ",
  },
  {
    value: "F1129 Opioid dependence with unspecified opioid-induced disorder ",
    label: "F1129 Opioid dependence with unspecified opioid-induced disorder ",
  },
  {
    value: "F1190 Opioid use, unspecified, uncomplicated ",
    label: "F1190 Opioid use, unspecified, uncomplicated ",
  },
  {
    value: "F11920 Opioid use, unspecified with intoxication, uncomplicated ",
    label: "F11920 Opioid use, unspecified with intoxication, uncomplicated ",
  },
  {
    value: "F11921 Opioid use, unspecified with intoxication delirium ",
    label: "F11921 Opioid use, unspecified with intoxication delirium ",
  },
  {
    value:
      "F11922 Opioid use, unspecified with intoxication with perceptual disturbance ",
    label:
      "F11922 Opioid use, unspecified with intoxication with perceptual disturbance ",
  },
  {
    value: "F11929 Opioid use, unspecified with intoxication, unspecified ",
    label: "F11929 Opioid use, unspecified with intoxication, unspecified ",
  },
  {
    value: "F1193 Opioid use, unspecified with withdrawal ",
    label: "F1193 Opioid use, unspecified with withdrawal ",
  },
  {
    value: "F1194 Opioid use, unspecified with opioid-induced mood disorder ",
    label: "F1194 Opioid use, unspecified with opioid-induced mood disorder ",
  },
  {
    value:
      "F11950 Opioid use, unspecified with opioid-induced psychotic disorder with delusions ",
    label:
      "F11950 Opioid use, unspecified with opioid-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F11951 Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations F11959 Opioid use, unspecified with opioid-induced psychotic disorder, unspecified ",
    label:
      "F11951 Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations F11959 Opioid use, unspecified with opioid-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F11981 Opioid use, unspecified with opioid-induced sexual dysfunction",
    label:
      "F11981 Opioid use, unspecified with opioid-induced sexual dysfunction",
  },
  {
    value: "F11982 Opioid use, unspecified with opioid-induced sleep disorder ",
    label: "F11982 Opioid use, unspecified with opioid-induced sleep disorder ",
  },
  {
    value: "F11988 Opioid use, unspecified with other opioid-induced disorder ",
    label: "F11988 Opioid use, unspecified with other opioid-induced disorder ",
  },
  {
    value:
      "F1199 Opioid use, unspecified with unspecified opioid-induced disorder ",
    label:
      "F1199 Opioid use, unspecified with unspecified opioid-induced disorder ",
  },
  {
    value: "F1210 Cannabis abuse, uncomplicated ",
    label: "F1210 Cannabis abuse, uncomplicated ",
  },
  {
    value: "F12120 Cannabis abuse with intoxication, uncomplicated ",
    label: "F12120 Cannabis abuse with intoxication, uncomplicated ",
  },
  {
    value: "F12121 Cannabis abuse with intoxication delirium ",
    label: "F12121 Cannabis abuse with intoxication delirium ",
  },
  {
    value:
      "F12122 Cannabis abuse with intoxication with perceptual disturbance ",
    label:
      "F12122 Cannabis abuse with intoxication with perceptual disturbance ",
  },
  {
    value: "F12129 Cannabis abuse with intoxication, unspecified ",
    label: "F12129 Cannabis abuse with intoxication, unspecified ",
  },
  {
    value: "F12150 Cannabis abuse with psychotic disorder with delusions ",
    label: "F12150 Cannabis abuse with psychotic disorder with delusions ",
  },
  {
    value: "F12151 Cannabis abuse with psychotic disorder with hallucinations ",
    label: "F12151 Cannabis abuse with psychotic disorder with hallucinations ",
  },
  {
    value: "F12159 Cannabis abuse with psychotic disorder, unspecified ",
    label: "F12159 Cannabis abuse with psychotic disorder, unspecified ",
  },
  {
    value: "F12180 Cannabis abuse with cannabis-induced anxiety disorder ",
    label: "F12180 Cannabis abuse with cannabis-induced anxiety disorder ",
  },
  {
    value: "F12188 Cannabis abuse with other cannabis-induced disorder ",
    label: "F12188 Cannabis abuse with other cannabis-induced disorder ",
  },
  {
    value: "F1219 Cannabis abuse with unspecified cannabis-induced disorder ",
    label: "F1219 Cannabis abuse with unspecified cannabis-induced disorder ",
  },
  {
    value: "F1220 Cannabis dependence, uncomplicated ",
    label: "F1220 Cannabis dependence, uncomplicated ",
  },
  {
    value: "F1221 Cannabis dependence, in remission ",
    label: "F1221 Cannabis dependence, in remission ",
  },
  {
    value: "F12220 Cannabis dependence with intoxication, uncomplicated ",
    label: "F12220 Cannabis dependence with intoxication, uncomplicated ",
  },
  {
    value: "F12221 Cannabis dependence with intoxication delirium ",
    label: "F12221 Cannabis dependence with intoxication delirium ",
  },
  {
    value:
      "F12222 Cannabis dependence with intoxication with perceptual disturbance ",
    label:
      "F12222 Cannabis dependence with intoxication with perceptual disturbance ",
  },
  {
    value: "F12229 Cannabis dependence with intoxication, unspecified ",
    label: "F12229 Cannabis dependence with intoxication, unspecified ",
  },
  {
    value: "F12250 Cannabis dependence with psychotic disorder with delusions ",
    label: "F12250 Cannabis dependence with psychotic disorder with delusions ",
  },
  {
    value:
      "F12251 Cannabis dependence with psychotic disorder with hallucinations ",
    label:
      "F12251 Cannabis dependence with psychotic disorder with hallucinations ",
  },
  {
    value: "F12259 Cannabis dependence with psychotic disorder, unspecified ",
    label: "F12259 Cannabis dependence with psychotic disorder, unspecified ",
  },
  {
    value: "F12280 Cannabis dependence with cannabis-induced anxiety disorder ",
    label: "F12280 Cannabis dependence with cannabis-induced anxiety disorder ",
  },
  {
    value: "F12288 Cannabis dependence with other cannabis-induced disorder ",
    label: "F12288 Cannabis dependence with other cannabis-induced disorder ",
  },
  {
    value:
      "F1229 Cannabis dependence with unspecified cannabis-induced disorder ",
    label:
      "F1229 Cannabis dependence with unspecified cannabis-induced disorder ",
  },
  {
    value: "F1290 Cannabis use, unspecified, uncomplicated ",
    label: "F1290 Cannabis use, unspecified, uncomplicated ",
  },
  {
    value: "F12920 Cannabis use, unspecified with intoxication, uncomplicated ",
    label: "F12920 Cannabis use, unspecified with intoxication, uncomplicated ",
  },
  {
    value: "F12921 Cannabis use, unspecified with intoxication delirium ",
    label: "F12921 Cannabis use, unspecified with intoxication delirium ",
  },
  {
    value:
      "F12922 Cannabis use, unspecified with intoxication with perceptual disturbance ",
    label:
      "F12922 Cannabis use, unspecified with intoxication with perceptual disturbance ",
  },
  {
    value: "F12929 Cannabis use, unspecified with intoxication, unspecified ",
    label: "F12929 Cannabis use, unspecified with intoxication, unspecified ",
  },
  {
    value:
      "F12950 Cannabis use, unspecified with psychotic disorder with delusions ",
    label:
      "F12950 Cannabis use, unspecified with psychotic disorder with delusions ",
  },
  {
    value:
      "F12951 Cannabis use, unspecified with psychotic disorder with hallucinations ",
    label:
      "F12951 Cannabis use, unspecified with psychotic disorder with hallucinations ",
  },
  {
    value:
      "F12959 Cannabis use, unspecified with psychotic disorder, unspecified ",
    label:
      "F12959 Cannabis use, unspecified with psychotic disorder, unspecified ",
  },
  {
    value: "F12980 Cannabis use, unspecified with anxiety disorder ",
    label: "F12980 Cannabis use, unspecified with anxiety disorder ",
  },
  {
    value:
      "F12988 Cannabis use, unspecified with other cannabis-induced disorder ",
    label:
      "F12988 Cannabis use, unspecified with other cannabis-induced disorder ",
  },
  {
    value:
      "F1299 Cannabis use, unspecified with unspecified cannabis-induced disorder ",
    label:
      "F1299 Cannabis use, unspecified with unspecified cannabis-induced disorder ",
  },
  {
    value: "F1310 Sedative, hypnotic or anxiolytic abuse, uncomplicated ",
    label: "F1310 Sedative, hypnotic or anxiolytic abuse, uncomplicated ",
  },
  {
    value:
      "F13120 Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated ",
    label:
      "F13120 Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated ",
  },
  {
    value:
      "F13121 Sedative, hypnotic or anxiolytic abuse with intoxication delirium ",
    label:
      "F13121 Sedative, hypnotic or anxiolytic abuse with intoxication delirium ",
  },
  {
    value:
      "F13129 Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified ",
    label:
      "F13129 Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified ",
  },
  {
    value:
      "F1314 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder ",
    label:
      "F1314 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder ",
  },
  {
    value:
      "F13150 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
    label:
      "F13150 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F13151 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
    label:
      "F13151 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F13159 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
    label:
      "F13159 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F13180 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
    label:
      "F13180 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder",
  },
  {
    value:
      "F13181 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
    label:
      "F13181 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
  },
  {
    value:
      "F13182 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder ",
    label:
      "F13182 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder ",
  },
  {
    value:
      "F13188 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder ",
    label:
      "F13188 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder ",
  },
  {
    value:
      "F1319 Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder ",
    label:
      "F1319 Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder ",
  },
  {
    value: "F1320 Sedative, hypnotic or anxiolytic dependence, uncomplicated ",
    label: "F1320 Sedative, hypnotic or anxiolytic dependence, uncomplicated ",
  },
  {
    value: "F1321 Sedative, hypnotic or anxiolytic dependence, in remission ",
    label: "F1321 Sedative, hypnotic or anxiolytic dependence, in remission ",
  },
  {
    value:
      "F13220 Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated ",
    label:
      "F13220 Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated ",
  },
  {
    value:
      "F13221 Sedative, hypnotic or anxiolytic dependence with intoxication delirium ",
    label:
      "F13221 Sedative, hypnotic or anxiolytic dependence with intoxication delirium ",
  },
  {
    value:
      "F13229 Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified ",
    label:
      "F13229 Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified ",
  },
  {
    value:
      "F13230 Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated ",
    label:
      "F13230 Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated ",
  },
  {
    value:
      "F13231 Sedative, hypnotic or anxiolytic dependence with withdrawal delirium ",
    label:
      "F13231 Sedative, hypnotic or anxiolytic dependence with withdrawal delirium ",
  },
  {
    value:
      "F13232 Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance ",
    label:
      "F13232 Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance ",
  },
  {
    value:
      "F13239 Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified ",
    label:
      "F13239 Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified ",
  },
  {
    value:
      "F1324 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder ",
    label:
      "F1324 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder ",
  },
  {
    value:
      "F13250 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
    label:
      "F13250 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F13251 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
    label:
      "F13251 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F13259 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
    label:
      "F13259 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1326 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder ",
    label:
      "F1326 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1327 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia ",
    label:
      "F1327 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia ",
  },
  {
    value:
      "F13280 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder ",
    label:
      "F13280 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder ",
  },
  {
    value:
      "F13281 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
    label:
      "F13281 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
  },
  {
    value:
      "F13282 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder ",
    label:
      "F13282 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder ",
  },
  {
    value:
      "F13288 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder ",
    label:
      "F13288 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder ",
  },
  {
    value:
      "F1329 Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolyticinduced disorder ",
    label:
      "F1329 Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolyticinduced disorder ",
  },
  {
    value:
      "F1390 Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated ",
    label:
      "F1390 Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated ",
  },
  {
    value:
      "F13920 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated F13921 Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium ",
    label:
      "F13920 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated F13921 Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium ",
  },
  {
    value:
      "F13929 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified ",
    label:
      "F13929 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified ",
  },
  {
    value:
      "F13930 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated F13931 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium ",
    label:
      "F13930 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated F13931 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium ",
  },
  {
    value:
      "F13932 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances ",
    label:
      "F13932 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances ",
  },
  {
    value:
      "F13939 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified ",
    label:
      "F13939 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified ",
  },
  {
    value:
      "F1394 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder ",
    label:
      "F1394 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder ",
  },
  {
    value:
      "F13950 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
    label:
      "F13950 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F13951 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
    label:
      "F13951 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F13959 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
    label:
      "F13959 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1396 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder ",
    label:
      "F1396 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1397 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia ",
    label:
      "F1397 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia ",
  },
  {
    value:
      "F13980 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder ",
    label:
      "F13980 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder ",
  },
  {
    value:
      "F13981 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
    label:
      "F13981 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction ",
  },
  {
    value:
      "F13982 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder ",
    label:
      "F13982 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder ",
  },
  {
    value:
      "F13988 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolyticinduced disorder ",
    label:
      "F13988 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolyticinduced disorder ",
  },
  {
    value:
      "F1399 Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder ",
    label:
      "F1399 Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder ",
  },
  {
    value: "F1410 Cocaine abuse, uncomplicated ",
    label: "F1410 Cocaine abuse, uncomplicated ",
  },
  {
    value: "F14120 Cocaine abuse with intoxication, uncomplicated ",
    label: "F14120 Cocaine abuse with intoxication, uncomplicated ",
  },
  {
    value: "F14121 Cocaine abuse with intoxication with delirium ",
    label: "F14121 Cocaine abuse with intoxication with delirium ",
  },
  {
    value:
      "F14122 Cocaine abuse with intoxication with perceptual disturbance ",
    label:
      "F14122 Cocaine abuse with intoxication with perceptual disturbance ",
  },
  {
    value: "F14129 Cocaine abuse with intoxication, unspecified ",
    label: "F14129 Cocaine abuse with intoxication, unspecified ",
  },
  {
    value: "F1414 Cocaine abuse with cocaine-induced mood disorder ",
    label: "F1414 Cocaine abuse with cocaine-induced mood disorder ",
  },
  {
    value:
      "F14150 Cocaine abuse with cocaine-induced psychotic disorder with delusions ",
    label:
      "F14150 Cocaine abuse with cocaine-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F14151 Cocaine abuse with cocaine-induced psychotic disorder with hallucinations ",
    label:
      "F14151 Cocaine abuse with cocaine-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F14159 Cocaine abuse with cocaine-induced psychotic disorder, unspecified ",
    label:
      "F14159 Cocaine abuse with cocaine-induced psychotic disorder, unspecified ",
  },
  {
    value: "F14180 Cocaine abuse with cocaine-induced anxiety disorder ",
    label: "F14180 Cocaine abuse with cocaine-induced anxiety disorder ",
  },
  {
    value: "F14181 Cocaine abuse with cocaine-induced sexual dysfunction ",
    label: "F14181 Cocaine abuse with cocaine-induced sexual dysfunction ",
  },
  {
    value: "F14182 Cocaine abuse with cocaine-induced sleep disorder ",
    label: "F14182 Cocaine abuse with cocaine-induced sleep disorder ",
  },
  {
    value: "F14188 Cocaine abuse with other cocaine-induced disorder ",
    label: "F14188 Cocaine abuse with other cocaine-induced disorder ",
  },
  {
    value: "F1419 Cocaine abuse with unspecified cocaine-induced disorder ",
    label: "F1419 Cocaine abuse with unspecified cocaine-induced disorder ",
  },
  {
    value: "F1420 Cocaine dependence, uncomplicated ",
    label: "F1420 Cocaine dependence, uncomplicated ",
  },
  {
    value: "F1421 Cocaine dependence, in remission ",
    label: "F1421 Cocaine dependence, in remission ",
  },
  {
    value: "F14220 Cocaine dependence with intoxication, uncomplicated ",
    label: "F14220 Cocaine dependence with intoxication, uncomplicated ",
  },
  {
    value: "F14221 Cocaine dependence with intoxication delirium ",
    label: "F14221 Cocaine dependence with intoxication delirium ",
  },
  {
    value:
      "F14222 Cocaine dependence with intoxication with perceptual disturbance ",
    label:
      "F14222 Cocaine dependence with intoxication with perceptual disturbance ",
  },
  {
    value: "F14229 Cocaine dependence with intoxication, unspecified ",
    label: "F14229 Cocaine dependence with intoxication, unspecified ",
  },
  {
    value: "F1423 Cocaine dependence with withdrawal ",
    label: "F1423 Cocaine dependence with withdrawal ",
  },
  {
    value: "F1424 Cocaine dependence with cocaine-induced mood disorder ",
    label: "F1424 Cocaine dependence with cocaine-induced mood disorder ",
  },
  {
    value:
      "F14250 Cocaine dependence with cocaine-induced psychotic disorder with delusions ",
    label:
      "F14250 Cocaine dependence with cocaine-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F14251 Cocaine dependence with cocaine-induced psychotic disorder with hallucinations F14259 Cocaine dependence with cocaine-induced psychotic disorder, unspecified ",
    label:
      "F14251 Cocaine dependence with cocaine-induced psychotic disorder with hallucinations F14259 Cocaine dependence with cocaine-induced psychotic disorder, unspecified ",
  },
  {
    value: "F14280 Cocaine dependence with cocaine-induced anxiety disorder ",
    label: "F14280 Cocaine dependence with cocaine-induced anxiety disorder ",
  },
  {
    value: "F14281 Cocaine dependence with cocaine-induced sexual dysfunction ",
    label: "F14281 Cocaine dependence with cocaine-induced sexual dysfunction ",
  },
  {
    value: "F14282 Cocaine dependence with cocaine-induced sleep disorder ",
    label: "F14282 Cocaine dependence with cocaine-induced sleep disorder ",
  },
  {
    value: "F14288 Cocaine dependence with other cocaine-induced disorder ",
    label: "F14288 Cocaine dependence with other cocaine-induced disorder ",
  },
  {
    value:
      "F1429 Cocaine dependence with unspecified cocaine-induced disorder ",
    label:
      "F1429 Cocaine dependence with unspecified cocaine-induced disorder ",
  },
  {
    value: "F1490 Cocaine use, unspecified, uncomplicated ",
    label: "F1490 Cocaine use, unspecified, uncomplicated ",
  },
  {
    value: "F14920 Cocaine use, unspecified with intoxication, uncomplicated ",
    label: "F14920 Cocaine use, unspecified with intoxication, uncomplicated ",
  },
  {
    value: "F14921 Cocaine use, unspecified with intoxication delirium ",
    label: "F14921 Cocaine use, unspecified with intoxication delirium ",
  },
  {
    value:
      "F14922 Cocaine use, unspecified with intoxication with perceptual disturbance ",
    label:
      "F14922 Cocaine use, unspecified with intoxication with perceptual disturbance ",
  },
  {
    value: "F14929 Cocaine use, unspecified with intoxication, unspecified ",
    label: "F14929 Cocaine use, unspecified with intoxication, unspecified ",
  },
  {
    value: "F1494 Cocaine use, unspecified with cocaine-induced mood disorder ",
    label: "F1494 Cocaine use, unspecified with cocaine-induced mood disorder ",
  },
  {
    value:
      "F14950 Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions F14951 Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations F14959 Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified ",
    label:
      "F14950 Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions F14951 Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations F14959 Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F14980 Cocaine use, unspecified with cocaine-induced anxiety disorder ",
    label:
      "F14980 Cocaine use, unspecified with cocaine-induced anxiety disorder ",
  },
  {
    value:
      "F14981 Cocaine use, unspecified with cocaine-induced sexual dysfunction ",
    label:
      "F14981 Cocaine use, unspecified with cocaine-induced sexual dysfunction ",
  },
  {
    value:
      "F14982 Cocaine use, unspecified with cocaine-induced sleep disorder ",
    label:
      "F14982 Cocaine use, unspecified with cocaine-induced sleep disorder ",
  },
  {
    value:
      "F14988 Cocaine use, unspecified with other cocaine-induced disorder ",
    label:
      "F14988 Cocaine use, unspecified with other cocaine-induced disorder ",
  },
  {
    value:
      "F1499 Cocaine use, unspecified with unspecified cocaine-induced disorder ",
    label:
      "F1499 Cocaine use, unspecified with unspecified cocaine-induced disorder ",
  },
  {
    value: "F1510 Other stimulant abuse, uncomplicated ",
    label: "F1510 Other stimulant abuse, uncomplicated ",
  },
  {
    value: "F15120 Other stimulant abuse with intoxication, uncomplicated ",
    label: "F15120 Other stimulant abuse with intoxication, uncomplicated ",
  },
  {
    value: "F15121 Other stimulant abuse with intoxication delirium ",
    label: "F15121 Other stimulant abuse with intoxication delirium ",
  },
  {
    value:
      "F15122 Other stimulant abuse with intoxication with perceptual disturbance ",
    label:
      "F15122 Other stimulant abuse with intoxication with perceptual disturbance ",
  },
  {
    value: "F15129 Other stimulant abuse with intoxication, unspecified ",
    label: "F15129 Other stimulant abuse with intoxication, unspecified ",
  },
  {
    value: "F1514 Other stimulant abuse with stimulant-induced mood disorder ",
    label: "F1514 Other stimulant abuse with stimulant-induced mood disorder ",
  },
  {
    value:
      "F15150 Other stimulant abuse with stimulant-induced psychotic disorder with delusions ",
    label:
      "F15150 Other stimulant abuse with stimulant-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F15151 Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations F15159 Other stimulant abuse with stimulant-induced psychotic disorder, unspecified ",
    label:
      "F15151 Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations F15159 Other stimulant abuse with stimulant-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F15180 Other stimulant abuse with stimulant-induced anxiety disorder ",
    label:
      "F15180 Other stimulant abuse with stimulant-induced anxiety disorder ",
  },
  {
    value:
      "F15181 Other stimulant abuse with stimulant-induced sexual dysfunction ",
    label:
      "F15181 Other stimulant abuse with stimulant-induced sexual dysfunction ",
  },
  {
    value:
      "F15182 Other stimulant abuse with stimulant-induced sleep disorder ",
    label:
      "F15182 Other stimulant abuse with stimulant-induced sleep disorder ",
  },
  {
    value:
      "F15188 Other stimulant abuse with other stimulant-induced disorder ",
    label:
      "F15188 Other stimulant abuse with other stimulant-induced disorder ",
  },
  {
    value:
      "F1519 Other stimulant abuse with unspecified stimulant-induced disorder ",
    label:
      "F1519 Other stimulant abuse with unspecified stimulant-induced disorder ",
  },
  {
    value: "F1520 Other stimulant dependence, uncomplicated ",
    label: "F1520 Other stimulant dependence, uncomplicated ",
  },
  {
    value: "F1521 Other stimulant dependence, in remission ",
    label: "F1521 Other stimulant dependence, in remission ",
  },
  {
    value:
      "F15220 Other stimulant dependence with intoxication, uncomplicated ",
    label:
      "F15220 Other stimulant dependence with intoxication, uncomplicated ",
  },
  {
    value: "F15221 Other stimulant dependence with intoxication delirium ",
    label: "F15221 Other stimulant dependence with intoxication delirium ",
  },
  {
    value:
      "F15222 Other stimulant dependence with intoxication with perceptual disturbance ",
    label:
      "F15222 Other stimulant dependence with intoxication with perceptual disturbance ",
  },
  {
    value: "F15229 Other stimulant dependence with intoxication, unspecified ",
    label: "F15229 Other stimulant dependence with intoxication, unspecified ",
  },
  {
    value: "F1523 Other stimulant dependence with withdrawal ",
    label: "F1523 Other stimulant dependence with withdrawal ",
  },
  {
    value:
      "F1524 Other stimulant dependence with stimulant-induced mood disorder ",
    label:
      "F1524 Other stimulant dependence with stimulant-induced mood disorder ",
  },
  {
    value:
      "F15250 Other stimulant dependence with stimulant-induced psychotic disorder with delusions F15251 Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations ",
    label:
      "F15250 Other stimulant dependence with stimulant-induced psychotic disorder with delusions F15251 Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F15259 Other stimulant dependence with stimulant-induced psychotic disorder, unspecified F15280 Other stimulant dependence with stimulant-induced anxiety disorder ",
    label:
      "F15259 Other stimulant dependence with stimulant-induced psychotic disorder, unspecified F15280 Other stimulant dependence with stimulant-induced anxiety disorder ",
  },
  {
    value:
      "F15281 Other stimulant dependence with stimulant-induced sexual dysfunction ",
    label:
      "F15281 Other stimulant dependence with stimulant-induced sexual dysfunction ",
  },
  {
    value:
      "F15282 Other stimulant dependence with stimulant-induced sleep disorder ",
    label:
      "F15282 Other stimulant dependence with stimulant-induced sleep disorder ",
  },
  {
    value:
      "F15288 Other stimulant dependence with other stimulant-induced disorder ",
    label:
      "F15288 Other stimulant dependence with other stimulant-induced disorder ",
  },
  {
    value:
      "F1529 Other stimulant dependence with unspecified stimulant-induced disorder ",
    label:
      "F1529 Other stimulant dependence with unspecified stimulant-induced disorder ",
  },
  {
    value: "F1590 Other stimulant use, unspecified, uncomplicated ",
    label: "F1590 Other stimulant use, unspecified, uncomplicated ",
  },
  {
    value:
      "F15920 Other stimulant use, unspecified with intoxication, uncomplicated ",
    label:
      "F15920 Other stimulant use, unspecified with intoxication, uncomplicated ",
  },
  {
    value:
      "F15921 Other stimulant use, unspecified with intoxication delirium ",
    label:
      "F15921 Other stimulant use, unspecified with intoxication delirium ",
  },
  {
    value:
      "F15922 Other stimulant use, unspecified with intoxication with perceptual disturbance ",
    label:
      "F15922 Other stimulant use, unspecified with intoxication with perceptual disturbance ",
  },
  {
    value:
      "F15929 Other stimulant use, unspecified with intoxication, unspecified ",
    label:
      "F15929 Other stimulant use, unspecified with intoxication, unspecified ",
  },
  {
    value: "F1593 Other stimulant use, unspecified with withdrawal ",
    label: "F1593 Other stimulant use, unspecified with withdrawal ",
  },
  {
    value:
      "F1594 Other stimulant use, unspecified with stimulant-induced mood disorder ",
    label:
      "F1594 Other stimulant use, unspecified with stimulant-induced mood disorder ",
  },
  {
    value:
      "F15950 Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions ",
    label:
      "F15950 Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F15951 Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations ",
    label:
      "F15951 Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F15959 Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified F15980 Other stimulant use, unspecified with stimulant-induced anxiety disorder ",
    label:
      "F15959 Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified F15980 Other stimulant use, unspecified with stimulant-induced anxiety disorder ",
  },
  {
    value:
      "F15981 Other stimulant use, unspecified with stimulant-induced sexual dysfunction ",
    label:
      "F15981 Other stimulant use, unspecified with stimulant-induced sexual dysfunction ",
  },
  {
    value:
      "F15982 Other stimulant use, unspecified with stimulant-induced sleep disorder ",
    label:
      "F15982 Other stimulant use, unspecified with stimulant-induced sleep disorder ",
  },
  {
    value:
      "F15988 Other stimulant use, unspecified with other stimulant-induced disorder ",
    label:
      "F15988 Other stimulant use, unspecified with other stimulant-induced disorder ",
  },
  {
    value:
      "F1599 Other stimulant use, unspecified with unspecified stimulant-induced disorder ",
    label:
      "F1599 Other stimulant use, unspecified with unspecified stimulant-induced disorder ",
  },
  {
    value: "F1610 Hallucinogen abuse, uncomplicated ",
    label: "F1610 Hallucinogen abuse, uncomplicated ",
  },
  {
    value: "F16120 Hallucinogen abuse with intoxication, uncomplicated ",
    label: "F16120 Hallucinogen abuse with intoxication, uncomplicated ",
  },
  {
    value: "F16121 Hallucinogen abuse with intoxication with delirium ",
    label: "F16121 Hallucinogen abuse with intoxication with delirium ",
  },
  {
    value:
      "F16122 Hallucinogen abuse with intoxication with perceptual disturbance ",
    label:
      "F16122 Hallucinogen abuse with intoxication with perceptual disturbance ",
  },
  {
    value: "F16129 Hallucinogen abuse with intoxication, unspecified ",
    label: "F16129 Hallucinogen abuse with intoxication, unspecified ",
  },
  {
    value: "F1614 Hallucinogen abuse with hallucinogen-induced mood disorder ",
    label: "F1614 Hallucinogen abuse with hallucinogen-induced mood disorder ",
  },
  {
    value:
      "F16150 Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions F16151 Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations F16159 Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified ",
    label:
      "F16150 Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions F16151 Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations F16159 Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F16180 Hallucinogen abuse with hallucinogen-induced anxiety disorder ",
    label:
      "F16180 Hallucinogen abuse with hallucinogen-induced anxiety disorder ",
  },
  {
    value:
      "F16183 Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks) F16188 Hallucinogen abuse with other hallucinogen-induced disorder ",
    label:
      "F16183 Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks) F16188 Hallucinogen abuse with other hallucinogen-induced disorder ",
  },
  {
    value:
      "F1619 Hallucinogen abuse with unspecified hallucinogen-induced disorder ",
    label:
      "F1619 Hallucinogen abuse with unspecified hallucinogen-induced disorder ",
  },
  {
    value: "F1620 Hallucinogen dependence, uncomplicated ",
    label: "F1620 Hallucinogen dependence, uncomplicated ",
  },
  {
    value: "F1621 Hallucinogen dependence, in remission ",
    label: "F1621 Hallucinogen dependence, in remission ",
  },
  {
    value: "F16220 Hallucinogen dependence with intoxication, uncomplicated ",
    label: "F16220 Hallucinogen dependence with intoxication, uncomplicated ",
  },
  {
    value: "F16221 Hallucinogen dependence with intoxication with delirium ",
    label: "F16221 Hallucinogen dependence with intoxication with delirium ",
  },
  {
    value: "F16229 Hallucinogen dependence with intoxication, unspecified ",
    label: "F16229 Hallucinogen dependence with intoxication, unspecified ",
  },
  {
    value:
      "F1624 Hallucinogen dependence with hallucinogen-induced mood disorder ",
    label:
      "F1624 Hallucinogen dependence with hallucinogen-induced mood disorder ",
  },
  {
    value:
      "F16250 Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions F16251 Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations ",
    label:
      "F16250 Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions F16251 Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F16259 Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified F16280 Hallucinogen dependence with hallucinogen-induced anxiety disorder ",
    label:
      "F16259 Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified F16280 Hallucinogen dependence with hallucinogen-induced anxiety disorder ",
  },
  {
    value:
      "F16283 Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks) F16288 Hallucinogen dependence with other hallucinogen-induced disorder ",
    label:
      "F16283 Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks) F16288 Hallucinogen dependence with other hallucinogen-induced disorder ",
  },
  {
    value:
      "F1629 Hallucinogen dependence with unspecified hallucinogen-induced disorder ",
    label:
      "F1629 Hallucinogen dependence with unspecified hallucinogen-induced disorder ",
  },
  {
    value: "F1690 Hallucinogen use, unspecified, uncomplicated ",
    label: "F1690 Hallucinogen use, unspecified, uncomplicated ",
  },
  {
    value:
      "F16920 Hallucinogen use, unspecified with intoxication, uncomplicated ",
    label:
      "F16920 Hallucinogen use, unspecified with intoxication, uncomplicated ",
  },
  {
    value:
      "F16921 Hallucinogen use, unspecified with intoxication with delirium ",
    label:
      "F16921 Hallucinogen use, unspecified with intoxication with delirium ",
  },
  {
    value:
      "F16929 Hallucinogen use, unspecified with intoxication, unspecified ",
    label:
      "F16929 Hallucinogen use, unspecified with intoxication, unspecified ",
  },
  {
    value:
      "F1694 Hallucinogen use, unspecified with hallucinogen-induced mood disorder ",
    label:
      "F1694 Hallucinogen use, unspecified with hallucinogen-induced mood disorder ",
  },
  {
    value:
      "F16950 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions ",
    label:
      "F16950 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F16951 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations ",
    label:
      "F16951 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F16959 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified ",
    label:
      "F16959 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F16980 Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder ",
    label:
      "F16980 Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder ",
  },
  {
    value:
      "F16983 Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks) ",
    label:
      "F16983 Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks) ",
  },
  {
    value:
      "F16988 Hallucinogen use, unspecified with other hallucinogen-induced disorder ",
    label:
      "F16988 Hallucinogen use, unspecified with other hallucinogen-induced disorder ",
  },
  {
    value:
      "F1699 Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder ",
    label:
      "F1699 Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder ",
  },
  {
    value: "F17200 Nicotine dependence, unspecified, uncomplicated ",
    label: "F17200 Nicotine dependence, unspecified, uncomplicated ",
  },
  {
    value: "F17201 Nicotine dependence, unspecified, in remission ",
    label: "F17201 Nicotine dependence, unspecified, in remission ",
  },
  {
    value: "F17203 Nicotine dependence unspecified, with withdrawal ",
    label: "F17203 Nicotine dependence unspecified, with withdrawal ",
  },
  {
    value:
      "F17208 Nicotine dependence, unspecified, with other nicotine-induced disorders ",
    label:
      "F17208 Nicotine dependence, unspecified, with other nicotine-induced disorders ",
  },
  {
    value:
      "F17209 Nicotine dependence, unspecified, with unspecified nicotine-induced disorders ",
    label:
      "F17209 Nicotine dependence, unspecified, with unspecified nicotine-induced disorders ",
  },
  {
    value: "F17210 Nicotine dependence, cigarettes, uncomplicated ",
    label: "F17210 Nicotine dependence, cigarettes, uncomplicated ",
  },
  {
    value: "F17211 Nicotine dependence, cigarettes, in remission ",
    label: "F17211 Nicotine dependence, cigarettes, in remission ",
  },
  {
    value: "F17213 Nicotine dependence, cigarettes, with withdrawal ",
    label: "F17213 Nicotine dependence, cigarettes, with withdrawal ",
  },
  {
    value:
      "F17218 Nicotine dependence, cigarettes, with other nicotine-induced disorders ",
    label:
      "F17218 Nicotine dependence, cigarettes, with other nicotine-induced disorders ",
  },
  {
    value:
      "F17219 Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders ",
    label:
      "F17219 Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders ",
  },
  {
    value: "F17220 Nicotine dependence, chewing tobacco, uncomplicated ",
    label: "F17220 Nicotine dependence, chewing tobacco, uncomplicated ",
  },
  {
    value: "F17221 Nicotine dependence, chewing tobacco, in remission ",
    label: "F17221 Nicotine dependence, chewing tobacco, in remission ",
  },
  {
    value: "F17223 Nicotine dependence, chewing tobacco, with withdrawal ",
    label: "F17223 Nicotine dependence, chewing tobacco, with withdrawal ",
  },
  {
    value:
      "F17228 Nicotine dependence, chewing tobacco, with other nicotine-induced disorders ",
    label:
      "F17228 Nicotine dependence, chewing tobacco, with other nicotine-induced disorders ",
  },
  {
    value:
      "F17229 Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders F17290 Nicotine dependence, other tobacco product, uncomplicated ",
    label:
      "F17229 Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders F17290 Nicotine dependence, other tobacco product, uncomplicated ",
  },
  {
    value: "F17291 Nicotine dependence, other tobacco product, in remission ",
    label: "F17291 Nicotine dependence, other tobacco product, in remission ",
  },
  {
    value:
      "F17293 Nicotine dependence, other tobacco product, with withdrawal ",
    label:
      "F17293 Nicotine dependence, other tobacco product, with withdrawal ",
  },
  {
    value:
      "F17298 Nicotine dependence, other tobacco product, with other nicotine-induced disorders F17299 Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders ",
    label:
      "F17298 Nicotine dependence, other tobacco product, with other nicotine-induced disorders F17299 Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders ",
  },
  {
    value: "F1810 Inhalant abuse, uncomplicated ",
    label: "F1810 Inhalant abuse, uncomplicated ",
  },
  {
    value: "F18120 Inhalant abuse with intoxication, uncomplicated ",
    label: "F18120 Inhalant abuse with intoxication, uncomplicated ",
  },
  {
    value: "F18121 Inhalant abuse with intoxication delirium ",
    label: "F18121 Inhalant abuse with intoxication delirium ",
  },
  {
    value: "F18129 Inhalant abuse with intoxication, unspecified ",
    label: "F18129 Inhalant abuse with intoxication, unspecified ",
  },
  {
    value: "F1814 Inhalant abuse with inhalant-induced mood disorder ",
    label: "F1814 Inhalant abuse with inhalant-induced mood disorder ",
  },
  {
    value:
      "F18150 Inhalant abuse with inhalant-induced psychotic disorder with delusions ",
    label:
      "F18150 Inhalant abuse with inhalant-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F18151 Inhalant abuse with inhalant-induced psychotic disorder with hallucinations ",
    label:
      "F18151 Inhalant abuse with inhalant-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F18159 Inhalant abuse with inhalant-induced psychotic disorder, unspecified ",
    label:
      "F18159 Inhalant abuse with inhalant-induced psychotic disorder, unspecified ",
  },
  {
    value: "F1817 Inhalant abuse with inhalant-induced dementia ",
    label: "F1817 Inhalant abuse with inhalant-induced dementia ",
  },
  {
    value: "F18180 Inhalant abuse with inhalant-induced anxiety disorder ",
    label: "F18180 Inhalant abuse with inhalant-induced anxiety disorder ",
  },
  {
    value: "F18188 Inhalant abuse with other inhalant-induced disorder ",
    label: "F18188 Inhalant abuse with other inhalant-induced disorder ",
  },
  {
    value: "F1819 Inhalant abuse with unspecified inhalant-induced disorder ",
    label: "F1819 Inhalant abuse with unspecified inhalant-induced disorder ",
  },
  {
    value: "F1820 Inhalant dependence, uncomplicated ",
    label: "F1820 Inhalant dependence, uncomplicated ",
  },
  {
    value: "F1821 Inhalant dependence, in remission ",
    label: "F1821 Inhalant dependence, in remission ",
  },
  {
    value: "F18220 Inhalant dependence with intoxication, uncomplicated ",
    label: "F18220 Inhalant dependence with intoxication, uncomplicated ",
  },
  {
    value: "F18221 Inhalant dependence with intoxication delirium ",
    label: "F18221 Inhalant dependence with intoxication delirium ",
  },
  {
    value: "F18229 Inhalant dependence with intoxication, unspecified ",
    label: "F18229 Inhalant dependence with intoxication, unspecified ",
  },
  {
    value: "F1824 Inhalant dependence with inhalant-induced mood disorder ",
    label: "F1824 Inhalant dependence with inhalant-induced mood disorder ",
  },
  {
    value:
      "F18250 Inhalant dependence with inhalant-induced psychotic disorder with delusions ",
    label:
      "F18250 Inhalant dependence with inhalant-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F18251 Inhalant dependence with inhalant-induced psychotic disorder with hallucinations F18259 Inhalant dependence with inhalant-induced psychotic disorder, unspecified ",
    label:
      "F18251 Inhalant dependence with inhalant-induced psychotic disorder with hallucinations F18259 Inhalant dependence with inhalant-induced psychotic disorder, unspecified ",
  },
  {
    value: "F1827 Inhalant dependence with inhalant-induced dementia ",
    label: "F1827 Inhalant dependence with inhalant-induced dementia ",
  },
  {
    value: "F18280 Inhalant dependence with inhalant-induced anxiety disorder ",
    label: "F18280 Inhalant dependence with inhalant-induced anxiety disorder ",
  },
  {
    value: "F18288 Inhalant dependence with other inhalant-induced disorder ",
    label: "F18288 Inhalant dependence with other inhalant-induced disorder ",
  },
  {
    value:
      "F1829 Inhalant dependence with unspecified inhalant-induced disorder ",
    label:
      "F1829 Inhalant dependence with unspecified inhalant-induced disorder ",
  },
  {
    value: "F1890 Inhalant use, unspecified, uncomplicated ",
    label: "F1890 Inhalant use, unspecified, uncomplicated ",
  },
  {
    value: "F18920 Inhalant use, unspecified with intoxication, uncomplicated ",
    label: "F18920 Inhalant use, unspecified with intoxication, uncomplicated ",
  },
  {
    value: "F18921 Inhalant use, unspecified with intoxication with delirium ",
    label: "F18921 Inhalant use, unspecified with intoxication with delirium ",
  },
  {
    value: "F18929 Inhalant use, unspecified with intoxication, unspecified ",
    label: "F18929 Inhalant use, unspecified with intoxication, unspecified ",
  },
  {
    value:
      "F1894 Inhalant use, unspecified with inhalant-induced mood disorder ",
    label:
      "F1894 Inhalant use, unspecified with inhalant-induced mood disorder ",
  },
  {
    value:
      "F18950 Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions F18951 Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations F18959 Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified ",
    label:
      "F18950 Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions F18951 Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations F18959 Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1897 Inhalant use, unspecified with inhalant-induced persisting dementia ",
    label:
      "F1897 Inhalant use, unspecified with inhalant-induced persisting dementia ",
  },
  {
    value:
      "F18980 Inhalant use, unspecified with inhalant-induced anxiety disorder ",
    label:
      "F18980 Inhalant use, unspecified with inhalant-induced anxiety disorder ",
  },
  {
    value:
      "F18988 Inhalant use, unspecified with other inhalant-induced disorder ",
    label:
      "F18988 Inhalant use, unspecified with other inhalant-induced disorder ",
  },
  {
    value:
      "F1899 Inhalant use, unspecified with unspecified inhalant-induced disorder ",
    label:
      "F1899 Inhalant use, unspecified with unspecified inhalant-induced disorder ",
  },
  {
    value: "F1910 Other psychoactive substance abuse, uncomplicated ",
    label: "F1910 Other psychoactive substance abuse, uncomplicated ",
  },
  {
    value:
      "F19120 Other psychoactive substance abuse with intoxication, uncomplicated ",
    label:
      "F19120 Other psychoactive substance abuse with intoxication, uncomplicated ",
  },
  {
    value:
      "F19121 Other psychoactive substance abuse with intoxication delirium ",
    label:
      "F19121 Other psychoactive substance abuse with intoxication delirium ",
  },
  {
    value:
      "F19122 Other psychoactive substance abuse with intoxication with perceptual disturbances F19129 Other psychoactive substance abuse with intoxication, unspecified ",
    label:
      "F19122 Other psychoactive substance abuse with intoxication with perceptual disturbances F19129 Other psychoactive substance abuse with intoxication, unspecified ",
  },
  {
    value:
      "F1914 Other psychoactive substance abuse with psychoactive substance-induced mood disorder ",
    label:
      "F1914 Other psychoactive substance abuse with psychoactive substance-induced mood disorder ",
  },
  {
    value:
      "F19150 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions ",
    label:
      "F19150 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F19151 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations ",
    label:
      "F19151 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F19159 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified ",
    label:
      "F19159 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1916 Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder ",
    label:
      "F1916 Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1917 Other psychoactive substance abuse with psychoactive substance-induced persisting dementia ",
    label:
      "F1917 Other psychoactive substance abuse with psychoactive substance-induced persisting dementia ",
  },
  {
    value:
      "F19180 Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder ",
    label:
      "F19180 Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder ",
  },
  {
    value:
      "F19181 Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction ",
    label:
      "F19181 Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction ",
  },
  {
    value:
      "F19182 Other psychoactive substance abuse with psychoactive substance-induced sleep disorder ",
    label:
      "F19182 Other psychoactive substance abuse with psychoactive substance-induced sleep disorder ",
  },
  {
    value:
      "F19188 Other psychoactive substance abuse with other psychoactive substance-induced disorder ",
    label:
      "F19188 Other psychoactive substance abuse with other psychoactive substance-induced disorder ",
  },
  {
    value:
      "F1919 Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder ",
    label:
      "F1919 Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder ",
  },
  {
    value: "F1920 Other psychoactive substance dependence, uncomplicated ",
    label: "F1920 Other psychoactive substance dependence, uncomplicated ",
  },
  {
    value: "F1921 Other psychoactive substance dependence, in remission ",
    label: "F1921 Other psychoactive substance dependence, in remission ",
  },
  {
    value:
      "F19220 Other psychoactive substance dependence with intoxication, uncomplicated ",
    label:
      "F19220 Other psychoactive substance dependence with intoxication, uncomplicated ",
  },
  {
    value:
      "F19221 Other psychoactive substance dependence with intoxication delirium ",
    label:
      "F19221 Other psychoactive substance dependence with intoxication delirium ",
  },
  {
    value:
      "F19222 Other psychoactive substance dependence with intoxication with perceptual disturbance ",
    label:
      "F19222 Other psychoactive substance dependence with intoxication with perceptual disturbance ",
  },
  {
    value:
      "F19229 Other psychoactive substance dependence with intoxication, unspecified ",
    label:
      "F19229 Other psychoactive substance dependence with intoxication, unspecified ",
  },
  {
    value:
      "F19230 Other psychoactive substance dependence with withdrawal, uncomplicated ",
    label:
      "F19230 Other psychoactive substance dependence with withdrawal, uncomplicated ",
  },
  {
    value:
      "F19231 Other psychoactive substance dependence with withdrawal delirium ",
    label:
      "F19231 Other psychoactive substance dependence with withdrawal delirium ",
  },
  {
    value:
      "F19232 Other psychoactive substance dependence with withdrawal with perceptual disturbance F19239 Other psychoactive substance dependence with withdrawal, unspecified ",
    label:
      "F19232 Other psychoactive substance dependence with withdrawal with perceptual disturbance F19239 Other psychoactive substance dependence with withdrawal, unspecified ",
  },
  {
    value:
      "F1924 Other psychoactive substance dependence with psychoactive substance-induced mood disorder ",
    label:
      "F1924 Other psychoactive substance dependence with psychoactive substance-induced mood disorder ",
  },
  {
    value:
      "F19250 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions ",
    label:
      "F19250 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F19251 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations ",
    label:
      "F19251 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F19259 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified ",
    label:
      "F19259 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1926 Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder ",
    label:
      "F1926 Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1927 Other psychoactive substance dependence with psychoactive substance-induced persisting dementia ",
    label:
      "F1927 Other psychoactive substance dependence with psychoactive substance-induced persisting dementia ",
  },
  {
    value:
      "F19280 Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder ",
    label:
      "F19280 Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder ",
  },
  {
    value:
      "F19281 Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction ",
    label:
      "F19281 Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction ",
  },
  {
    value:
      "F19282 Other psychoactive substance dependence with psychoactive substance-induced sleep disorder ",
    label:
      "F19282 Other psychoactive substance dependence with psychoactive substance-induced sleep disorder ",
  },
  {
    value:
      "F19288 Other psychoactive substance dependence with other psychoactive substance-induced disorder ",
    label:
      "F19288 Other psychoactive substance dependence with other psychoactive substance-induced disorder ",
  },
  {
    value:
      "F1929 Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder ",
    label:
      "F1929 Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder ",
  },
  {
    value:
      "F1990 Other psychoactive substance use, unspecified, uncomplicated ",
    label:
      "F1990 Other psychoactive substance use, unspecified, uncomplicated ",
  },
  {
    value:
      "F19920 Other psychoactive substance use, unspecified with intoxication, uncomplicated F19921 Other psychoactive substance use, unspecified with intoxication with delirium ",
    label:
      "F19920 Other psychoactive substance use, unspecified with intoxication, uncomplicated F19921 Other psychoactive substance use, unspecified with intoxication with delirium ",
  },
  {
    value:
      "F19922 Other psychoactive substance use, unspecified with intoxication with perceptual disturbance ",
    label:
      "F19922 Other psychoactive substance use, unspecified with intoxication with perceptual disturbance ",
  },
  {
    value:
      "F19929 Other psychoactive substance use, unspecified with intoxication, unspecified ",
    label:
      "F19929 Other psychoactive substance use, unspecified with intoxication, unspecified ",
  },
  {
    value:
      "F19930 Other psychoactive substance use, unspecified with withdrawal, uncomplicated ",
    label:
      "F19930 Other psychoactive substance use, unspecified with withdrawal, uncomplicated ",
  },
  {
    value:
      "F19931 Other psychoactive substance use, unspecified with withdrawal delirium ",
    label:
      "F19931 Other psychoactive substance use, unspecified with withdrawal delirium ",
  },
  {
    value:
      "F19932 Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance ",
    label:
      "F19932 Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance ",
  },
  {
    value:
      "F19939 Other psychoactive substance use, unspecified with withdrawal, unspecified ",
    label:
      "F19939 Other psychoactive substance use, unspecified with withdrawal, unspecified ",
  },
  {
    value:
      "F1994 Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder ",
    label:
      "F1994 Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder ",
  },
  {
    value:
      "F19950 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions ",
    label:
      "F19950 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions ",
  },
  {
    value:
      "F19951 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations ",
    label:
      "F19951 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations ",
  },
  {
    value:
      "F19959 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified ",
    label:
      "F19959 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified ",
  },
  {
    value:
      "F1996 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder ",
    label:
      "F1996 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder ",
  },
  {
    value:
      "F1997 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia ",
    label:
      "F1997 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia ",
  },
  {
    value:
      "F19980 Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder ",
    label:
      "F19980 Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder ",
  },
  {
    value:
      "F19981 Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction ",
    label:
      "F19981 Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction ",
  },
  {
    value:
      "F19982 Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder ",
    label:
      "F19982 Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder ",
  },
  {
    value:
      "F19988 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder ",
    label:
      "F19988 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder ",
  },
  {
    value:
      "F1999 Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder ",
    label:
      "F1999 Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder ",
  },
];

const TwentytoTwentyNine = [
  {
    value: "F200 Paranoid schizophrenia ",
    label: "F200 Paranoid schizophrenia ",
  },
  {
    value: "F201 Disorganized schizophrenia ",
    label: "F201 Disorganized schizophrenia ",
  },
  {
    value: "F202 Catatonic schizophrenia ",
    label: "F202 Catatonic schizophrenia ",
  },
  {
    value: "F203 Undifferentiated schizophrenia ",
    label: "F203 Undifferentiated schizophrenia ",
  },
  {
    value: "F205 Residual schizophrenia ",
    label: "F205 Residual schizophrenia ",
  },
  {
    value: "F2081 Schizophreniform disorder ",
    label: "F2081 Schizophreniform disorder ",
  },
  { value: "F2089 Other schizophrenia ", label: "F2089 Other schizophrenia " },
  {
    value: "F209 Schizophrenia, unspecified ",
    label: "F209 Schizophrenia, unspecified ",
  },
  { value: "F21 Schizotypal disorder ", label: "F21 Schizotypal disorder " },
  { value: "F22 Delusional disorders ", label: "F22 Delusional disorders " },
  {
    value: "F23 Brief psychotic disorder ",
    label: "F23 Brief psychotic disorder ",
  },
  {
    value: "F24 Shared psychotic disorder ",
    label: "F24 Shared psychotic disorder ",
  },
  {
    value: "F250 Schizoaffective disorder, bipolar type ",
    label: "F250 Schizoaffective disorder, bipolar type ",
  },
  {
    value: "F251 Schizoaffective disorder, depressive type ",
    label: "F251 Schizoaffective disorder, depressive type ",
  },
  {
    value: "F258 Other schizoaffective disorders ",
    label: "F258 Other schizoaffective disorders ",
  },
  {
    value: "F259 Schizoaffective disorder, unspecified ",
    label: "F259 Schizoaffective disorder, unspecified ",
  },
  {
    value:
      "F28 Other psychotic disorder not due to a substance or known physiological condition ",
    label:
      "F28 Other psychotic disorder not due to a substance or known physiological condition ",
  },
  {
    value:
      "F29 Unspecified psychosis not due to a substance or known physiological condition ",
    label:
      "F29 Unspecified psychosis not due to a substance or known physiological condition ",
  },
];

const ThirtytoThirtyNine = [
  {
    value: "F3010 Manic episode without psychotic symptoms, unspecified ",
    label: "F3010 Manic episode without psychotic symptoms, unspecified ",
  },
  {
    value: "F3011 Manic episode without psychotic symptoms, mild ",
    label: "F3011 Manic episode without psychotic symptoms, mild ",
  },
  {
    value: "F3012 Manic episode without psychotic symptoms, moderate ",
    label: "F3012 Manic episode without psychotic symptoms, moderate ",
  },
  {
    value: "F3013 Manic episode, severe, without psychotic symptoms ",
    label: "F3013 Manic episode, severe, without psychotic symptoms ",
  },
  {
    value: "F302 Manic episode, severe with psychotic symptoms ",
    label: "F302 Manic episode, severe with psychotic symptoms ",
  },
  {
    value: "F303 Manic episode in partial remission ",
    label: "F303 Manic episode in partial remission ",
  },
  {
    value: "F304 Manic episode in full remission ",
    label: "F304 Manic episode in full remission ",
  },
  { value: "F308 Other manic episodes ", label: "F308 Other manic episodes " },
  {
    value: "F309 Manic episode, unspecified ",
    label: "F309 Manic episode, unspecified ",
  },
  {
    value: "F310 Bipolar disorder, current episode hypomanic ",
    label: "F310 Bipolar disorder, current episode hypomanic ",
  },
  {
    value:
      "F3110 Bipolar disorder, current episode manic without psychotic features, unspecified ",
    label:
      "F3110 Bipolar disorder, current episode manic without psychotic features, unspecified ",
  },
  {
    value:
      "F3111 Bipolar disorder, current episode manic without psychotic features, mild ",
    label:
      "F3111 Bipolar disorder, current episode manic without psychotic features, mild ",
  },
  {
    value:
      "F3112 Bipolar disorder, current episode manic without psychotic features, moderate ",
    label:
      "F3112 Bipolar disorder, current episode manic without psychotic features, moderate ",
  },
  {
    value:
      "F3113 Bipolar disorder, current episode manic without psychotic features, severe ",
    label:
      "F3113 Bipolar disorder, current episode manic without psychotic features, severe ",
  },
  {
    value:
      "F312 Bipolar disorder, current episode manic severe with psychotic features ",
    label:
      "F312 Bipolar disorder, current episode manic severe with psychotic features ",
  },
  {
    value:
      "F3130 Bipolar disorder, current episode depressed, mild or moderate severity, unspecified F3131 Bipolar disorder, current episode depressed, mild ",
    label:
      "F3130 Bipolar disorder, current episode depressed, mild or moderate severity, unspecified F3131 Bipolar disorder, current episode depressed, mild ",
  },
  {
    value: "F3132 Bipolar disorder, current episode depressed, moderate ",
    label: "F3132 Bipolar disorder, current episode depressed, moderate ",
  },
  {
    value:
      "F314 Bipolar disorder, current episode depressed, severe, without psychotic features ",
    label:
      "F314 Bipolar disorder, current episode depressed, severe, without psychotic features ",
  },
  {
    value:
      "F315 Bipolar disorder, current episode depressed, severe, with psychotic features ",
    label:
      "F315 Bipolar disorder, current episode depressed, severe, with psychotic features ",
  },
  {
    value: "F3160 Bipolar disorder, current episode mixed, unspecified ",
    label: "F3160 Bipolar disorder, current episode mixed, unspecified ",
  },
  {
    value: "F3161 Bipolar disorder, current episode mixed, mild ",
    label: "F3161 Bipolar disorder, current episode mixed, mild ",
  },
  {
    value: "F3162 Bipolar disorder, current episode mixed, moderate ",
    label: "F3162 Bipolar disorder, current episode mixed, moderate ",
  },
  {
    value:
      "F3163 Bipolar disorder, current episode mixed, severe, without psychotic features ",
    label:
      "F3163 Bipolar disorder, current episode mixed, severe, without psychotic features ",
  },
  {
    value:
      "F3164 Bipolar disorder, current episode mixed, severe, with psychotic features ",
    label:
      "F3164 Bipolar disorder, current episode mixed, severe, with psychotic features ",
  },
  {
    value:
      "F3170 Bipolar disorder, currently in remission, most recent episode unspecified ",
    label:
      "F3170 Bipolar disorder, currently in remission, most recent episode unspecified ",
  },
  {
    value:
      "F3171 Bipolar disorder, in partial remission, most recent episode hypomanic ",
    label:
      "F3171 Bipolar disorder, in partial remission, most recent episode hypomanic ",
  },
  {
    value:
      "F3172 Bipolar disorder, in full remission, most recent episode hypomanic ",
    label:
      "F3172 Bipolar disorder, in full remission, most recent episode hypomanic ",
  },
  {
    value:
      "F3173 Bipolar disorder, in partial remission, most recent episode manic ",
    label:
      "F3173 Bipolar disorder, in partial remission, most recent episode manic ",
  },
  {
    value:
      "F3174 Bipolar disorder, in full remission, most recent episode manic ",
    label:
      "F3174 Bipolar disorder, in full remission, most recent episode manic ",
  },
  {
    value:
      "F3175 Bipolar disorder, in partial remission, most recent episode depressed ",
    label:
      "F3175 Bipolar disorder, in partial remission, most recent episode depressed ",
  },
  {
    value:
      "F3176 Bipolar disorder, in full remission, most recent episode depressed ",
    label:
      "F3176 Bipolar disorder, in full remission, most recent episode depressed ",
  },
  {
    value:
      "F3177 Bipolar disorder, in partial remission, most recent episode mixed ",
    label:
      "F3177 Bipolar disorder, in partial remission, most recent episode mixed ",
  },
  {
    value:
      "F3178 Bipolar disorder, in full remission, most recent episode mixed ",
    label:
      "F3178 Bipolar disorder, in full remission, most recent episode mixed ",
  },
  { value: "F3181 Bipolar II disorder ", label: "F3181 Bipolar II disorder " },
  {
    value: "F3189 Other bipolar disorder ",
    label: "F3189 Other bipolar disorder ",
  },
  {
    value: "F319 Bipolar disorder, unspecified ",
    label: "F319 Bipolar disorder, unspecified ",
  },
  {
    value: "F320 Major depressive disorder, single episode, mild ",
    label: "F320 Major depressive disorder, single episode, mild ",
  },
  {
    value: "F321 Major depressive disorder, single episode, moderate ",
    label: "F321 Major depressive disorder, single episode, moderate ",
  },
  {
    value:
      "F322 Major depressive disorder, single episode, severe without psychotic features ",
    label:
      "F322 Major depressive disorder, single episode, severe without psychotic features ",
  },
  {
    value:
      "F323 Major depressive disorder, single episode, severe with psychotic features ",
    label:
      "F323 Major depressive disorder, single episode, severe with psychotic features ",
  },
  {
    value:
      "F324 Major depressive disorder, single episode, in partial remission ",
    label:
      "F324 Major depressive disorder, single episode, in partial remission ",
  },
  {
    value: "F325 Major depressive disorder, single episode, in full remission ",
    label: "F325 Major depressive disorder, single episode, in full remission ",
  },
  {
    value: "F3281 Premenstrual dysphoric disorder ",
    label: "F3281 Premenstrual dysphoric disorder ",
  },
  {
    value: "F3289 Other specified depressive episodes ",
    label: "F3289 Other specified depressive episodes ",
  },
  {
    value: "F329 Major depressive disorder, single episode, unspecified ",
    label: "F329 Major depressive disorder, single episode, unspecified ",
  },
  {
    value: "F330 Major depressive disorder, recurrent, mild ",
    label: "F330 Major depressive disorder, recurrent, mild ",
  },
  {
    value: "F331 Major depressive disorder, recurrent, moderate ",
    label: "F331 Major depressive disorder, recurrent, moderate ",
  },
  {
    value:
      "F332 Major depressive disorder, recurrent severe without psychotic features ",
    label:
      "F332 Major depressive disorder, recurrent severe without psychotic features ",
  },
  {
    value:
      "F333 Major depressive disorder, recurrent, severe with psychotic symptoms ",
    label:
      "F333 Major depressive disorder, recurrent, severe with psychotic symptoms ",
  },
  {
    value:
      "F3340 Major depressive disorder, recurrent, in remission, unspecified ",
    label:
      "F3340 Major depressive disorder, recurrent, in remission, unspecified ",
  },
  {
    value: "F3341 Major depressive disorder, recurrent, in partial remission ",
    label: "F3341 Major depressive disorder, recurrent, in partial remission ",
  },
  {
    value: "F3342 Major depressive disorder, recurrent, in full remission ",
    label: "F3342 Major depressive disorder, recurrent, in full remission ",
  },
  {
    value: "F338 Other recurrent depressive disorders ",
    label: "F338 Other recurrent depressive disorders ",
  },
  {
    value: "F339 Major depressive disorder, recurrent, unspecified ",
    label: "F339 Major depressive disorder, recurrent, unspecified ",
  },
  { value: "F340 Cyclothymic disorder ", label: "F340 Cyclothymic disorder " },
  { value: "F341 Dysthymic disorder ", label: "F341 Dysthymic disorder " },
  {
    value: "F3481 Disruptive mood dysregulation disorder ",
    label: "F3481 Disruptive mood dysregulation disorder ",
  },
  {
    value: "F3489 Other specified persistent mood disorders ",
    label: "F3489 Other specified persistent mood disorders ",
  },
  {
    value: "F349 Persistent mood [affective] disorder, unspecified ",
    label: "F349 Persistent mood [affective] disorder, unspecified ",
  },
  {
    value: "F39 Unspecified mood [affective] disorder ",
    label: "F39 Unspecified mood [affective] disorder ",
  },
];
const FortytoFortyNine = [
  {
    value: "F4000 Agoraphobia, unspecified ",
    label: "F4000 Agoraphobia, unspecified ",
  },
  {
    value: "F4001 Agoraphobia with panic disorder ",
    label: "F4001 Agoraphobia with panic disorder ",
  },
  {
    value: "F4002 Agoraphobia without panic disorder ",
    label: "F4002 Agoraphobia without panic disorder ",
  },
  {
    value: "F4010 Social phobia, unspecified ",
    label: "F4010 Social phobia, unspecified ",
  },
  {
    value: "F4011 Social phobia, generalized ",
    label: "F4011 Social phobia, generalized ",
  },
  { value: "F40210 Arachnophobia ", label: "F40210 Arachnophobia " },
  {
    value: "F40218 Other animal type phobia ",
    label: "F40218 Other animal type phobia ",
  },
  {
    value: "F40220 Fear of thunderstorms ",
    label: "F40220 Fear of thunderstorms ",
  },
  {
    value: "F40228 Other natural environment type phobia ",
    label: "F40228 Other natural environment type phobia ",
  },
  { value: "F40230 Fear of blood ", label: "F40230 Fear of blood " },
  {
    value: "F40231 Fear of injections and transfusions ",
    label: "F40231 Fear of injections and transfusions ",
  },
  {
    value: "F40232 Fear of other medical care ",
    label: "F40232 Fear of other medical care ",
  },
  { value: "F40233 Fear of injury ", label: "F40233 Fear of injury " },
  { value: "F40240 Claustrophobia ", label: "F40240 Claustrophobia " },
  { value: "F40241 Acrophobia ", label: "F40241 Acrophobia " },
  { value: "F40242 Fear of bridges ", label: "F40242 Fear of bridges " },
  { value: "F40243 Fear of flying ", label: "F40243 Fear of flying " },
  {
    value: "F40248 Other situational type phobia ",
    label: "F40248 Other situational type phobia ",
  },
  { value: "F40290 Androphobia ", label: "F40290 Androphobia " },
  { value: "F40291 Gynephobia ", label: "F40291 Gynephobia " },
  {
    value: "F40298 Other specified phobia ",
    label: "F40298 Other specified phobia ",
  },
  {
    value: "F408 Other phobic anxiety disorders ",
    label: "F408 Other phobic anxiety disorders ",
  },
  {
    value: "F409 Phobic anxiety disorder, unspecified ",
    label: "F409 Phobic anxiety disorder, unspecified ",
  },
  {
    value:
      "F410 Panic disorder [episodic paroxysmal anxiety] without agoraphobia ",
    label:
      "F410 Panic disorder [episodic paroxysmal anxiety] without agoraphobia ",
  },
  {
    value: "F411 Generalized anxiety disorder ",
    label: "F411 Generalized anxiety disorder ",
  },
  {
    value: "F413 Other mixed anxiety disorders ",
    label: "F413 Other mixed anxiety disorders ",
  },
  {
    value: "F418 Other specified anxiety disorders ",
    label: "F418 Other specified anxiety disorders ",
  },
  {
    value: "F419 Anxiety disorder, unspecified ",
    label: "F419 Anxiety disorder, unspecified ",
  },
  {
    value: "F422 Mixed obsessional thoughts and acts ",
    label: "F422 Mixed obsessional thoughts and acts ",
  },
  { value: "F423 Hoarding disorder ", label: "F423 Hoarding disorder " },
  {
    value: "F424 Excoriation (skin-picking) disorder ",
    label: "F424 Excoriation (skin-picking) disorder ",
  },
  {
    value: "F428 Other obsessive-compulsive disorder ",
    label: "F428 Other obsessive-compulsive disorder ",
  },
  {
    value: "F429 Obsessive-compulsive disorder, unspecified ",
    label: "F429 Obsessive-compulsive disorder, unspecified ",
  },
  {
    value: "F430 Acute stress reaction ",
    label: "F430 Acute stress reaction ",
  },
  {
    value: "F4310 Post-traumatic stress disorder, unspecified ",
    label: "F4310 Post-traumatic stress disorder, unspecified ",
  },
  {
    value: "F4311 Post-traumatic stress disorder, acute ",
    label: "F4311 Post-traumatic stress disorder, acute ",
  },
  {
    value: "F4312 Post-traumatic stress disorder, chronic ",
    label: "F4312 Post-traumatic stress disorder, chronic ",
  },
  {
    value: "F4320 Adjustment disorder, unspecified ",
    label: "F4320 Adjustment disorder, unspecified ",
  },
  {
    value: "F4321 Adjustment disorder with depressed mood ",
    label: "F4321 Adjustment disorder with depressed mood ",
  },
  {
    value: "F4322 Adjustment disorder with anxiety ",
    label: "F4322 Adjustment disorder with anxiety ",
  },
  {
    value: "F4323 Adjustment disorder with mixed anxiety and depressed mood ",
    label: "F4323 Adjustment disorder with mixed anxiety and depressed mood ",
  },
  {
    value: "F4324 Adjustment disorder with disturbance of conduct ",
    label: "F4324 Adjustment disorder with disturbance of conduct ",
  },
  {
    value:
      "F4325 Adjustment disorder with mixed disturbance of emotions and conduct ",
    label:
      "F4325 Adjustment disorder with mixed disturbance of emotions and conduct ",
  },
  {
    value: "F4329 Adjustment disorder with other symptoms ",
    label: "F4329 Adjustment disorder with other symptoms ",
  },
  {
    value: "F438 Other reactions to severe stress ",
    label: "F438 Other reactions to severe stress ",
  },
  {
    value: "F439 Reaction to severe stress, unspecified ",
    label: "F439 Reaction to severe stress, unspecified ",
  },
  { value: "F440 Dissociative amnesia ", label: "F440 Dissociative amnesia " },
  { value: "F441 Dissociative fugue ", label: "F441 Dissociative fugue " },
  { value: "F442 Dissociative stupor ", label: "F442 Dissociative stupor " },
  {
    value: "F444 Conversion disorder with motor symptom or deficit ",
    label: "F444 Conversion disorder with motor symptom or deficit ",
  },
  {
    value: "F445 Conversion disorder with seizures or convulsions ",
    label: "F445 Conversion disorder with seizures or convulsions ",
  },
  {
    value: "F446 Conversion disorder with sensory symptom or deficit ",
    label: "F446 Conversion disorder with sensory symptom or deficit ",
  },
  {
    value: "F447 Conversion disorder with mixed symptom presentation ",
    label: "F447 Conversion disorder with mixed symptom presentation ",
  },
  {
    value: "F4481 Dissociative identity disorder ",
    label: "F4481 Dissociative identity disorder ",
  },
  {
    value: "F4489 Other dissociative and conversion disorders ",
    label: "F4489 Other dissociative and conversion disorders ",
  },
  {
    value: "F449 Dissociative and conversion disorder, unspecified ",
    label: "F449 Dissociative and conversion disorder, unspecified ",
  },
  {
    value: "F450 Somatization disorder ",
    label: "F450 Somatization disorder ",
  },
  {
    value: "F451 Undifferentiated somatoform disorder ",
    label: "F451 Undifferentiated somatoform disorder ",
  },
  {
    value: "F4520 Hypochondriacal disorder, unspecified ",
    label: "F4520 Hypochondriacal disorder, unspecified ",
  },
  { value: "F4521 Hypochondriasis ", label: "F4521 Hypochondriasis " },
  {
    value: "F4522 Body dysmorphic disorder ",
    label: "F4522 Body dysmorphic disorder ",
  },
  {
    value: "F4529 Other hypochondriacal disorders ",
    label: "F4529 Other hypochondriacal disorders ",
  },
  {
    value: "F4541 Pain disorder exclusively related to psychological factors ",
    label: "F4541 Pain disorder exclusively related to psychological factors ",
  },
  {
    value: "F4542 Pain disorder with related psychological factors ",
    label: "F4542 Pain disorder with related psychological factors ",
  },
  {
    value: "F458 Other somatoform disorders ",
    label: "F458 Other somatoform disorders ",
  },
  {
    value: "F459 Somatoform disorder, unspecified ",
    label: "F459 Somatoform disorder, unspecified ",
  },
  {
    value: "F481 Depersonalization-derealization syndrome ",
    label: "F481 Depersonalization-derealization syndrome ",
  },
  { value: "F482 Pseudobulbar affect ", label: "F482 Pseudobulbar affect " },
  {
    value: "F488 Other specified nonpsychotic mental disorders ",
    label: "F488 Other specified nonpsychotic mental disorders ",
  },
  {
    value: "F489 Nonpsychotic mental disorder, unspecified ",
    label: "F489 Nonpsychotic mental disorder, unspecified ",
  },
];

const FiftytoFityNine = [
  {
    value: "F5000 Anorexia nervosa, unspecified ",
    label: "F5000 Anorexia nervosa, unspecified ",
  },
  {
    value: "F5001 Anorexia nervosa, restricting type ",
    label: "F5001 Anorexia nervosa, restricting type ",
  },
  {
    value: "F5002 Anorexia nervosa, binge eating/purging type ",
    label: "F5002 Anorexia nervosa, binge eating/purging type ",
  },
  { value: "F502 Bulimia nervosa ", label: "F502 Bulimia nervosa " },
  {
    value: "F5081 Binge eating disorder ",
    label: "F5081 Binge eating disorder ",
  },
  {
    value: "F5089 Other specified eating disorder ",
    label: "F5089 Other specified eating disorder ",
  },
  {
    value: "F509 Eating disorder, unspecified ",
    label: "F509 Eating disorder, unspecified ",
  },
  { value: "F5101 Primary insomnia ", label: "F5101 Primary insomnia " },
  { value: "F5102 Adjustment insomnia ", label: "F5102 Adjustment insomnia " },
  {
    value: "F5103 Paradoxical insomnia ",
    label: "F5103 Paradoxical insomnia ",
  },
  {
    value: "F5104 Psychophysiologic insomnia ",
    label: "F5104 Psychophysiologic insomnia ",
  },
  {
    value: "F5105 Insomnia due to other mental disorder ",
    label: "F5105 Insomnia due to other mental disorder ",
  },
  {
    value:
      "F5109 Other insomnia not due to a substance or known physiological condition ",
    label:
      "F5109 Other insomnia not due to a substance or known physiological condition ",
  },
  { value: "F5111 Primary hypersomnia ", label: "F5111 Primary hypersomnia " },
  {
    value: "F5112 Insufficient sleep syndrome ",
    label: "F5112 Insufficient sleep syndrome ",
  },
  {
    value: "F5113 Hypersomnia due to other mental disorder ",
    label: "F5113 Hypersomnia due to other mental disorder ",
  },
  {
    value:
      "F5119 Other hypersomnia not due to a substance or known physiological condition ",
    label:
      "F5119 Other hypersomnia not due to a substance or known physiological condition ",
  },
  {
    value: "F513 Sleepwalking [somnambulism] ",
    label: "F513 Sleepwalking [somnambulism] ",
  },
  {
    value: "F514 Sleep terrors [night terrors] ",
    label: "F514 Sleep terrors [night terrors] ",
  },
  { value: "F515 Nightmare disorder ", label: "F515 Nightmare disorder " },
  {
    value:
      "F518 Other sleep disorders not due to a substance or known physiological condition ",
    label:
      "F518 Other sleep disorders not due to a substance or known physiological condition ",
  },
  {
    value:
      "F519 Sleep disorder not due to a substance or known physiological condition, unspecified ",
    label:
      "F519 Sleep disorder not due to a substance or known physiological condition, unspecified ",
  },
  {
    value: "F520 Hypoactive sexual desire disorder ",
    label: "F520 Hypoactive sexual desire disorder ",
  },
  {
    value: "F521 Sexual aversion disorder ",
    label: "F521 Sexual aversion disorder ",
  },
  {
    value: "F5221 Male erectile disorder ",
    label: "F5221 Male erectile disorder ",
  },
  {
    value: "F5222 Female sexual arousal disorder ",
    label: "F5222 Female sexual arousal disorder ",
  },
  {
    value: "F5231 Female orgasmic disorder ",
    label: "F5231 Female orgasmic disorder ",
  },
  {
    value: "F5232 Male orgasmic disorder ",
    label: "F5232 Male orgasmic disorder ",
  },
  {
    value: "F524 Premature ejaculation ",
    label: "F524 Premature ejaculation ",
  },
  {
    value:
      "F525 Vaginismus not due to a substance or known physiological condition ",
    label:
      "F525 Vaginismus not due to a substance or known physiological condition ",
  },
  {
    value:
      "F526 Dyspareunia not due to a substance or known physiological condition ",
    label:
      "F526 Dyspareunia not due to a substance or known physiological condition ",
  },
  {
    value:
      "F528 Other sexual dysfunction not due to a substance or known physiological condition ",
    label:
      "F528 Other sexual dysfunction not due to a substance or known physiological condition ",
  },
  {
    value:
      "F529 Unspecified sexual dysfunction not due to a substance or known physiological condition F53 Puerperal psychosis ",
    label:
      "F529 Unspecified sexual dysfunction not due to a substance or known physiological condition F53 Puerperal psychosis ",
  },
  {
    value:
      "F54 Psychological and behavioral factors associated with disorders or diseases classified elsewhere ",
    label:
      "F54 Psychological and behavioral factors associated with disorders or diseases classified elsewhere ",
  },
  { value: "F550 Abuse of antacids ", label: "F550 Abuse of antacids " },
  {
    value: "F551 Abuse of herbal or folk remedies ",
    label: "F551 Abuse of herbal or folk remedies ",
  },
  { value: "F552 Abuse of laxatives ", label: "F552 Abuse of laxatives " },
  {
    value: "F553 Abuse of steroids or hormones ",
    label: "F553 Abuse of steroids or hormones ",
  },
  { value: "F554 Abuse of vitamins ", label: "F554 Abuse of vitamins " },
  {
    value: "F558 Abuse of other non-psychoactive substances ",
    label: "F558 Abuse of other non-psychoactive substances ",
  },
  {
    value:
      "F59 Unspecified behavioral syndromes associated with physiological disturbances and physical factors ",
    label:
      "F59 Unspecified behavioral syndromes associated with physiological disturbances and physical factors ",
  },
];

const SixtytoSixtyNine = [
  {
    value: "F600 Paranoid personality disorder ",
    label: "F600 Paranoid personality disorder ",
  },
  {
    value: "F601 Schizoid personality disorder ",
    label: "F601 Schizoid personality disorder ",
  },
  {
    value: "F602 Antisocial personality disorder ",
    label: "F602 Antisocial personality disorder ",
  },
  {
    value: "F603 Borderline personality disorder ",
    label: "F603 Borderline personality disorder ",
  },
  {
    value: "F604 Histrionic personality disorder ",
    label: "F604 Histrionic personality disorder ",
  },
  {
    value: "F605 Obsessive-compulsive personality disorder ",
    label: "F605 Obsessive-compulsive personality disorder ",
  },
  {
    value: "F606 Avoidant personality disorder ",
    label: "F606 Avoidant personality disorder ",
  },
  {
    value: "F607 Dependent personality disorder ",
    label: "F607 Dependent personality disorder ",
  },
  {
    value: "F6081 Narcissistic personality disorder ",
    label: "F6081 Narcissistic personality disorder ",
  },
  {
    value: "F6089 Other specific personality disorders ",
    label: "F6089 Other specific personality disorders ",
  },
  {
    value: "F609 Personality disorder, unspecified ",
    label: "F609 Personality disorder, unspecified ",
  },
  {
    value: "F630 Pathological gambling ",
    label: "F630 Pathological gambling ",
  },
  { value: "F631 Pyromania ", label: "F631 Pyromania " },
  { value: "F632 Kleptomania ", label: "F632 Kleptomania " },
  { value: "F633 Trichotillomania ", label: "F633 Trichotillomania " },
  {
    value: "F6381 Intermittent explosive disorder ",
    label: "F6381 Intermittent explosive disorder ",
  },
  {
    value: "F6389 Other impulse disorders",
    label: "F6389 Other impulse disorders",
  },
  {
    value: "F639 Impulse disorder, unspecified ",
    label: "F639 Impulse disorder, unspecified ",
  },
  { value: "F640 Transsexualism ", label: "F640 Transsexualism " },
  {
    value: "F641 Dual role transvestism ",
    label: "F641 Dual role transvestism ",
  },
  {
    value: "F642 Gender identity disorder of childhood ",
    label: "F642 Gender identity disorder of childhood ",
  },
  {
    value: "F648 Other gender identity disorders ",
    label: "F648 Other gender identity disorders ",
  },
  {
    value: "F649 Gender identity disorder, unspecified ",
    label: "F649 Gender identity disorder, unspecified ",
  },
  { value: "F650 Fetishism ", label: "F650 Fetishism " },
  {
    value: "F651 Transvestic fetishism ",
    label: "F651 Transvestic fetishism ",
  },
  { value: "F652 Exhibitionism ", label: "F652 Exhibitionism " },
  { value: "F653 Voyeurism ", label: "F653 Voyeurism " },
  { value: "F654 Pedophilia ", label: "F654 Pedophilia " },
  {
    value: "F6550 Sadomasochism, unspecified ",
    label: "F6550 Sadomasochism, unspecified ",
  },
  { value: "F6551 Sexual masochism ", label: "F6551 Sexual masochism " },
  { value: "F6552 Sexual sadism F", label: "F6552 Sexual sadism F" },
  { value: "6581 Frotteurism ", label: "6581 Frotteurism " },
  { value: "F6589 Other paraphilias ", label: "F6589 Other paraphilias " },
  {
    value: "F659 Paraphilia, unspecified ",
    label: "F659 Paraphilia, unspecified ",
  },
  {
    value: "F66 Other sexual disorders ",
    label: "F66 Other sexual disorders ",
  },
  {
    value: "F6810 Factitious disorder, unspecified ",
    label: "F6810 Factitious disorder, unspecified ",
  },
  {
    value:
      "F6811 Factitious disorder with predominantly psychological signs and symptoms ",
    label:
      "F6811 Factitious disorder with predominantly psychological signs and symptoms ",
  },
  {
    value:
      "F6812 Factitious disorder with predominantly physical signs and symptoms ",
    label:
      "F6812 Factitious disorder with predominantly physical signs and symptoms ",
  },
  {
    value:
      "F6813 Factitious disorder with combined psychological and physical signs and symptoms ",
    label:
      "F6813 Factitious disorder with combined psychological and physical signs and symptoms ",
  },
  {
    value: "F688 Other specified disorders of adult personality and behavior ",
    label: "F688 Other specified disorders of adult personality and behavior ",
  },
  {
    value: "F69 Unspecified disorder of adult personality and behavior ",
    label: "F69 Unspecified disorder of adult personality and behavior ",
  },
];

const SeventytoSeventyNine = [
  {
    value: "F70 Mild intellectual disabilities ",
    label: "F70 Mild intellectual disabilities ",
  },
  {
    value: "F71 Moderate intellectual disabilities ",
    label: "F71 Moderate intellectual disabilities ",
  },
  {
    value: "F72 Severe intellectual disabilities ",
    label: "F72 Severe intellectual disabilities ",
  },
  {
    value: "F73 Profound intellectual disabilities ",
    label: "F73 Profound intellectual disabilities ",
  },
  {
    value: "F78 Other intellectual disabilities ",
    label: "F78 Other intellectual disabilities ",
  },
  {
    value: "F79 Unspecified intellectual disabilities ",
    label: "F79 Unspecified intellectual disabilities ",
  },
];

const EightytoEightyNine = [
  {
    value: "F800 Phonological disorder ",
    label: "F800 Phonological disorder ",
  },
  {
    value: "F801 Expressive language disorder ",
    label: "F801 Expressive language disorder ",
  },
  {
    value: "F802 Mixed receptive-expressive language disorder ",
    label: "F802 Mixed receptive-expressive language disorder ",
  },
  {
    value: "F804 Speech and language development delay due to hearing loss ",
    label: "F804 Speech and language development delay due to hearing loss ",
  },
  {
    value: "F8081 Childhood onset fluency disorder ",
    label: "F8081 Childhood onset fluency disorder ",
  },
  {
    value: "F8082 Social pragmatic communication disorder ",
    label: "F8082 Social pragmatic communication disorder ",
  },
  {
    value: "F8089 Other developmental disorders of speech and language ",
    label: "F8089 Other developmental disorders of speech and language ",
  },
  {
    value: "F809 Developmental disorder of speech and language, unspecified ",
    label: "F809 Developmental disorder of speech and language, unspecified ",
  },
  {
    value: "F810 Specific reading disorder ",
    label: "F810 Specific reading disorder ",
  },
  { value: "F812 Mathematics disorder ", label: "F812 Mathematics disorder " },
  {
    value: "F8181 Disorder of written expression ",
    label: "F8181 Disorder of written expression ",
  },
  {
    value: "F8189 Other developmental disorders of scholastic skills ",
    label: "F8189 Other developmental disorders of scholastic skills ",
  },
  {
    value: "F819 Developmental disorder of scholastic skills, unspecified ",
    label: "F819 Developmental disorder of scholastic skills, unspecified ",
  },
  {
    value: "F82 Specific developmental disorder of motor function ",
    label: "F82 Specific developmental disorder of motor function ",
  },
  { value: "F840 Autistic disorder ", label: "F840 Autistic disorder " },
  { value: "F842 Rett's syndrome ", label: "F842 Rett's syndrome " },
  {
    value: "F843 Other childhood disintegrative disorder ",
    label: "F843 Other childhood disintegrative disorder ",
  },
  { value: "F845 Asperger's syndrome ", label: "F845 Asperger's syndrome " },
  {
    value: "F848 Other pervasive developmental disorders ",
    label: "F848 Other pervasive developmental disorders ",
  },
  {
    value: "F849 Pervasive developmental disorder, unspecified ",
    label: "F849 Pervasive developmental disorder, unspecified ",
  },
  {
    value: "F88 Other disorders of psychological development ",
    label: "F88 Other disorders of psychological development ",
  },
  {
    value: "F89 Unspecified disorder of psychological development ",
    label: "F89 Unspecified disorder of psychological development ",
  },
];

const NinetytoNinetyEight = [
  {
    value:
      "F900 Attention-deficit hyperactivity disorder, predominantly inattentive type ",
    label:
      "F900 Attention-deficit hyperactivity disorder, predominantly inattentive type ",
  },
  {
    value:
      "F901 Attention-deficit hyperactivity disorder, predominantly hyperactive type ",
    label:
      "F901 Attention-deficit hyperactivity disorder, predominantly hyperactive type ",
  },
  {
    value: "F902 Attention-deficit hyperactivity disorder, combined type ",
    label: "F902 Attention-deficit hyperactivity disorder, combined type ",
  },
  {
    value: "F908 Attention-deficit hyperactivity disorder, other type ",
    label: "F908 Attention-deficit hyperactivity disorder, other type ",
  },
  {
    value: "F909 Attention-deficit hyperactivity disorder, unspecified type ",
    label: "F909 Attention-deficit hyperactivity disorder, unspecified type ",
  },
  {
    value: "F910 Conduct disorder confined to family context ",
    label: "F910 Conduct disorder confined to family context ",
  },
  {
    value: "F911 Conduct disorder, childhood-onset type ",
    label: "F911 Conduct disorder, childhood-onset type ",
  },
  {
    value: "F912 Conduct disorder, adolescent-onset type ",
    label: "F912 Conduct disorder, adolescent-onset type ",
  },
  {
    value: "F913 Oppositional defiant disorder ",
    label: "F913 Oppositional defiant disorder ",
  },
  {
    value: "F918 Other conduct disorders ",
    label: "F918 Other conduct disorders ",
  },
  {
    value: "F919 Conduct disorder, unspecified ",
    label: "F919 Conduct disorder, unspecified ",
  },
  {
    value: "F930 Separation anxiety disorder of childhood ",
    label: "F930 Separation anxiety disorder of childhood ",
  },
  {
    value: "F938 Other childhood emotional disorders ",
    label: "F938 Other childhood emotional disorders ",
  },
  {
    value: "F939 Childhood emotional disorder, unspecified ",
    label: "F939 Childhood emotional disorder, unspecified ",
  },
  { value: "F940 Selective mutism ", label: "F940 Selective mutism " },
  {
    value: "F941 Reactive attachment disorder of childhood ",
    label: "F941 Reactive attachment disorder of childhood ",
  },
  {
    value: "F942 Disinhibited attachment disorder of childhood ",
    label: "F942 Disinhibited attachment disorder of childhood ",
  },
  {
    value: "F948 Other childhood disorders of social functioning ",
    label: "F948 Other childhood disorders of social functioning ",
  },
  {
    value: "F949 Childhood disorder of social functioning, unspecified ",
    label: "F949 Childhood disorder of social functioning, unspecified ",
  },
  {
    value: "F950 Transient tic disorder ",
    label: "F950 Transient tic disorder ",
  },
  {
    value: "F951 Chronic motor or vocal tic disorder ",
    label: "F951 Chronic motor or vocal tic disorder ",
  },
  {
    value: "F952 Tourette's disorder F958 Other tic disorders ",
    label: "F952 Tourette's disorder F958 Other tic disorders ",
  },
  {
    value: "F959 Tic disorder, unspecified ",
    label: "F959 Tic disorder, unspecified ",
  },
  {
    value:
      "F980 Enuresis not due to a substance or known physiological condition ",
    label:
      "F980 Enuresis not due to a substance or known physiological condition ",
  },
  {
    value:
      "F981 Encopresis not due to a substance or known physiological condition ",
    label:
      "F981 Encopresis not due to a substance or known physiological condition ",
  },
  {
    value: "F9821 Rumination disorder of infancy ",
    label: "F9821 Rumination disorder of infancy ",
  },
  {
    value: "F9829 Other feeding disorders of infancy and early childhood ",
    label: "F9829 Other feeding disorders of infancy and early childhood ",
  },
  {
    value: "F983 Pica of infancy and childhood ",
    label: "F983 Pica of infancy and childhood ",
  },
  {
    value: "F984 Stereotyped movement disorders ",
    label: "F984 Stereotyped movement disorders ",
  },
  {
    value: "F985 Adult onset fluency disorder ",
    label: "F985 Adult onset fluency disorder ",
  },
  {
    value:
      "F988 Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence ",
    label:
      "F988 Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence ",
  },
  {
    value:
      "F989 Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence ",
    label:
      "F989 Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence ",
  },
];

const ninetyNine = [
  {
    value: "F99 Mental disorder, not otherwise specified",
    label: "F99 Mental disorder, not otherwise specified",
  },
];

export const groupedOptions = [
  {
    label: "Mental disorders due to known physiological conditions (F01-F09)",
    options: OnetoNine,
  },
  {
    label:
      "Mental and behavioral disorders due to psychoactive substance use (F10-F19)",
    options: TentoNineteen,
  },

  {
    label:
      "Schizophrenia, schizotypal, delusional, and other non-mood psychotic disorders (F20-F29)",
    options: TwentytoTwentyNine,
  },

  {
    label: "Mood [affective] disorders (F30-F39)",
    options: ThirtytoThirtyNine,
  },

  {
    label:
      "Anxiety, dissociative, stress-related, somatoform and other nonpsychotic mental disorders (F40-F48)",
    options: FortytoFortyNine,
  },

  {
    label:
      "Behavioral syndromes associated with physiological disturbances and physical factors (F50-F59)",
    options: FiftytoFityNine,
  },

  {
    label: "Disorders of adult personality and behavior (F60-F69)",
    options: SixtytoSixtyNine,
  },

  {
    label: "Intellectual disabilities (F70-F79)",
    options: SeventytoSeventyNine,
  },

  {
    label: "Pervasive and specific developmental disorders (F80-F89)",
    options: EightytoEightyNine,
  },

  {
    label:
      "Behavioral and emotional disorders with onset usually occurring in childhood and adolescence (F90-F98)",
    options: NinetytoNinetyEight,
  },

  { label: "Unspecified mental disorder (F99)", options: ninetyNine },
];
