import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

const envCheck =
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "local";

const actions = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (envCheck) mixpanel.track(name, props);
  },
  time_event: (name) => {
    if (envCheck) mixpanel.time_event(name);
  },
  people: {
    set: (props) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

// eslint-disable-next-line import/prefer-default-export
export const Mixpanel = actions;
