import { Box, Layer, Text } from "grommet";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingAssessments,
  submitAssessment,
} from "../../store/caregiver/actions";
import { fetchAssessmentResultsRecent } from "../../store/patients/actions";

const TakeAssessment = ({ assessment }) => {
  const [show, setShow] = React.useState(false);
  const [done, setDone] = React.useState(0);
  const [answerChange, setAnswerChange] = React.useState(false);
  const [incompleteError, setIncompleteError] = React.useState(false);
  const user = useSelector((state) => state.app.user.user);

  const dispatch = useDispatch();
  const [answers, setAnswers] = React.useState([]);

  const onSuccess = () => {
    setAnswers([]);
    setShow(false);
    setDone(0);
    setAnswerChange(false);
    setIncompleteError(false);
    dispatch(getPendingAssessments());
    dispatch(
      fetchAssessmentResultsRecent(user.id, () => {
        return true;
      })
    );
  };

  const captureQuestionAnswer = (index, question, answer) => {
    if (!answers[index]) {
      setDone(done + 1);
    } else {
      setAnswerChange(!answerChange);
    }

    answers[index] = { question, answer };
  };

  const onSubmit = () => {
    if (done !== assessment.questionnaire.length) {
      setIncompleteError(true);
      return;
    }

    const answerObject = {
      assessment: assessment.id,
      question_answer_list: answers,
    };

    dispatch(submitAssessment(answerObject, onSuccess));
  };

  const getBackgroundColor = (score) => {
    if (assessment.answer_group.answers.length === 2) {
      if (score === 0) {
        return "#6FCF9750";
      }

      if (score === 1) {
        return "#FA858150";
      }

      return "#6FCF9750";
    }
    if (assessment.answer_group.answers.length === 3) {
      if (score === 0) {
        return "#6FCF9750";
      }

      if (score === 1) {
        return "#F8C99850";
      }

      if (score === 2) {
        return "#FA858150";
      }

      return "#6FCF9750";
    }

    if (assessment.answer_group.answers.length === 4) {
      if (score === 0) {
        return "#6FCF9750";
      }

      if (score === 1) {
        return "#E5EA6840";
      }

      if (score === 2) {
        return "#F8C99850";
      }
      if (score === 3) {
        return "#FA858150";
      }

      return "#6FCF9750";
    }

    return "#6FCF9750";
  };

  const getBorderColor = (score) => {
    if (assessment.answer_group.answers.length === 2) {
      if (score === 0) {
        return "#6FCF97";
      }

      if (score === 1) {
        return "#FA8581";
      }

      return "#6FCF97";
    }
    if (assessment.answer_group.answers.length === 3) {
      if (score === 0) {
        return "#6FCF97";
      }

      if (score === 1) {
        return "#F8C998";
      }

      if (score === 2) {
        return "#FA8581";
      }

      return "#6FCF97";
    }

    if (assessment.answer_group.answers.length === 4) {
      if (score === 0) {
        return "#6FCF97";
      }

      if (score === 1) {
        return "#E5EA68";
      }

      if (score === 2) {
        return "#F8C998";
      }
      if (score === 3) {
        return "#FA8581";
      }

      return "#6FCF97";
    }

    return "#6FCF97";
  };

  return (
    <>
      <Box
        round="8px"
        style={{ cursor: "pointer", textAlign: "center" }}
        pad="small"
        className="noOutline"
        background="#395E9D"
        onClick={() => setShow(true)}
      >
        <Text color="white" size="small">
          Start {assessment.name} Assessment
        </Text>
      </Box>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-assessment"
          position="center"
        >
          <Box width="100%" height="100%" gap="medium">
            <Text color="#395E9D" weight="bold">
              {assessment.name}
            </Text>
            <Text color="#4F4F4F" size="small">
              {assessment.banner}
            </Text>
            <Box style={{ flexShrink: "0" }}>
              {assessment.answer_group.answers.map((answer) => (
                <Box direction="row" gap="small" key={answer.id}>
                  <Text size="small" weight="bold" color="#000000">
                    {answer.score}
                  </Text>
                  <Text size="small" color="#4F4F4F">
                    {answer.text}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box direction="row" align="center" justify="between" gap="small">
              <Box width="100%" background="#80808070" height="4px">
                <Box
                  width={`${(done / assessment.questionnaire.length) * 100}%`}
                  height="4px"
                  background="#395E9D"
                  className="widthIncrease"
                />
              </Box>
              <Text>
                {done}/{assessment.questionnaire.length}
              </Text>
            </Box>
            <Box style={{ overflowY: "scroll" }} gap="small">
              {assessment.questionnaire.map((question, index) => (
                <Box gap="small" style={{ flexShrink: "0" }} key={question.id}>
                  <Box direction="row" gap="small" style={{ flexShrink: "0" }}>
                    <Box
                      pad="small"
                      align="center"
                      width="20px"
                      height="20px"
                      round="full"
                      background="#E0E0E080"
                      justify="center"
                    >
                      <Text size="small" color="#333333">
                        {index + 1}
                      </Text>
                    </Box>
                    <Text
                      size="small"
                      color={
                        incompleteError && !answers[index] ? "red" : "#333333"
                      }
                    >
                      {question.text}
                      {incompleteError && !answers[index] ? (
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *required
                        </span>
                      ) : null}
                    </Text>
                  </Box>
                  <Box style={{ flexShrink: "0" }} gap="small">
                    {assessment.answer_group.answers.map((answer) => (
                      <Box
                        key={answer.id}
                        pad="xsmall"
                        round="4px"
                        onClick={() =>
                          captureQuestionAnswer(index, question.id, answer.id)
                        }
                        className="contain_width"
                        background={
                          answers[index] && answers[index].answer === answer.id
                            ? getBackgroundColor(answer.score)
                            : "#395E9D10"
                        }
                        direction="row"
                        gap="small"
                        align="center"
                        justify="start"
                        style={{
                          marginLeft: "25px",
                          cursor: "pointer",
                          border:
                            answers[index] &&
                            answers[index].answer === answer.id
                              ? `1px solid ${getBorderColor(answer.score)}`
                              : `1px solid #395E9D10`,
                        }}
                      >
                        <Box background="#E0E0E066" pad="xsmall">
                          <Text size="small" color="#000000">
                            {answer.score}
                          </Text>
                        </Box>
                        <Text color="#4F4F4F" size="small">
                          {answer.text}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>

            <Box style={{ flexShrink: "0" }} align="center" justify="center">
              {incompleteError && done !== assessment.questionnaire.length ? (
                <Text color="red" size="small">
                  Some questions are unanswered, please review the questions
                  marked red
                </Text>
              ) : null}

              <Box
                className="noOutline"
                margin="small"
                onClick={() => onSubmit()}
                alignSelf="center"
                justify="center"
                align="center"
                style={{ cursor: "pointer" }}
                round="small"
                pad={{ vertical: "small", horizontal: "large" }}
                background="#6FCF97"
              >
                <Text size="small" color="white" weight="bold">
                  Submit
                </Text>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default TakeAssessment;
