/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Text,
  Button,
  Image,
  Menu,
  // CheckBox,
} from "grommet";
import {
  // MailOption,
  Pin,
  // Plan,
  Clipboard,
  Flag,
  LinkPrevious,
} from "grommet-icons";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
// import moment from "moment";
import Loader from "./InPageLoader";
import { isMobileHook, iTabletHook } from "./Responsive";
import { fetchSinglePatient } from "../../store/patients/actions";
import ResetFlagModal from "./ResetFlagModal";

const ProfileBoxCommon = () => {
  const { search } = useSelector((state) => state.router.location);
  const patient = useSelector((state) => state.patients.patient) || null;
  const patientBHI = useSelector((state) => state.patients.patient_bhi) || null;
  const [resetFlagShow, setResetFlagShow] = useState(false);
  const results = useSelector((state) => state.patients.assessment_results);
  const [flagToSet, setFlagtoSet] = useState();
  const user = (state) => state.app.user.user;
  const [loading, setLoading] = useState();
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const dispatch = useDispatch();
  const isMobileorTablet = isMobile || isTablet;
  // const [messageStatus, setMessageStatus] = useState(0);
  const getAvatar = () => {
    if (patient.hasOwnProperty("profilePicture")) {
      return <Image src={patient.profilePicture} fit="cover" />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="4xl">
          {patient.first_name[0] + patient.last_name[0]}
        </Text>
      </Box>
    );
  };

  const getEligibility = () => {
    if (results) {
      if (patient.bhi_eligible)
        return (
          <Text size="small" color="green">
            Eligible
          </Text>
        );
    }

    return (
      <Text size="small" color="red">
        Ineligible
      </Text>
    );
  };

  const onSuccess = () => {
    setLoading(false);
  };

  const getFlag = (flag) => {
    if (flag === "RED") {
      return "rFlag";
    }
    if (flag === "YELLOW") {
      return "yFlag";
    }
    return "gFlag";
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchSinglePatient(search, onSuccess));
  }, []);

  if (loading) {
    return (
      <Box
        className="large-card-sharp"
        // margin={{ top: "small", horizontal: "small" }}
      >
        <Loader />
      </Box>
    );
  }
  return (
    <>
      {resetFlagShow && (
        <ResetFlagModal
          display={resetFlagShow}
          flag={flagToSet}
          setDisplayOut={(value) => setResetFlagShow(value)}
        />
      )}

      <Box
        className="large-card-sharp"
        // margin={{ top: "small", horizontal: "small" }}
      >
        <Box>
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            align="start"
            justify="center"
            margin={{ top: "large", bottom: "xsmall" }}
            gap="large"
          >
            <Box
              width={isMobileorTablet ? "100%" : "60%"}
              justify="center"
              align="center"
              gap="xsmall"
            >
              <Avatar
                size="xlarge"
                background="#f1f1f5"
                style={{ border: "3px solid #28539e" }}
              >
                {patient ? getAvatar() : null}
              </Avatar>
              <Box
                className={patient ? getFlag(patient.flag) : "gFlag"}
                pad={{ horizontal: "small", vertical: "xxsmall" }}
              >
                {patient ? (
                  <Text size="10px">
                    {patient.flag ? `${patient.flag} FLAG` : "GREEN FLAG"}
                  </Text>
                ) : null}
              </Box>
            </Box>
            <Box
              justify="center"
              width="100%"
              margin={isMobileorTablet ? { left: "large" } : null}
            >
              <Box align="start" gap="small">
                <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>
                    First Name:
                  </Text>
                  <Text size="small" color="#A9A9A9">
                    {patient ? patient.first_name : " "}
                  </Text>
                </Box>
                <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>
                    Last Name:
                  </Text>
                  <Text size="small" color="#A9A9A9">
                    {patient ? patient.last_name : " "}
                  </Text>
                </Box>
                {/* <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>Date of Birth (Age):</Text>
                  <Text
                    size="small"
                    color="#A9A9A9"
                  >
                    {patient ? `${patient.date_of_birth} (${moment().diff(moment(patient.date_of_birth), "years")})` : " "}
                  </Text>
                </Box> */}
                <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>
                    Email:
                  </Text>
                  <Text size="small" color="#A9A9A9">
                    {patient ? patient.email : " "}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              justify="center"
              width="100%"
              margin={
                isMobileorTablet ? { left: "large", bottom: "large" } : null
              }
            >
              <Box align="start" gap="small">
                <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>
                    BH Diagnosis:
                  </Text>
                  <Text size="small" color="#A9A9A9">
                    {patientBHI ? patientBHI.diagnosis : "Patient not Enrolled"}
                  </Text>
                </Box>
                {user.role === "TWS_COORDINATOR" ||
                user.role === "TWS_CONCIERGE" ||
                user.role === "TWS_CASE_MANAGER" ? null : (
                  <Box direction="row">
                    <Text size="small" margin={{ right: "xsmall" }}>
                      BHI Status:
                    </Text>
                    <Text size="small" color="#74C9C1">
                      {patientBHI ? "Enrolled" : getEligibility()}
                    </Text>
                  </Box>
                )}
                {/* <Box direction="row">
                  <Box>
                    <Text size="small">Messaging Status</Text>
                  </Box>
                  <Box margin={{ left: "small" }} direction="row">
                    <CheckBox
                      onChange={() => setMessageStatus(!messageStatus)}
                      toggle
                      className="noHover"
                    />
                    <Text
                      size="small"
                      color="#395E9D"
                      margin={{ left: "xsmall" }}
                    >
                      {messageStatus ? "ENABLED" : "DISABLED"}
                    </Text>
                  </Box>
                </Box> */}
                {/* <Box direction="row">
                  <Text size="small" margin={{ right: "xsmall" }}>Managed By:</Text>
                  <Text
                    size="small"
                    color="#A9A9A9"
                  >
                    {patientBHI ? patientBHI.bhi_manager : "Patient not Enrolled"}
                  </Text>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            justify="center"
            margin={
              isMobileorTablet
                ? { horizontal: "medium" }
                : { top: "medium", left: "large" }
            }
            gap="small"
            wrapProp="true"
          >
            {/* <Button
              size="small"
              label="Inbox"
              className="btn-profile-light"
              color="#F1F1F5"
              icon={<MailOption size="small" color="#28539E" />}
            /> */}

            <Button
              size="small"
              label="Pin Content"
              className={
                isMobileorTablet
                  ? "btn-profile-light-mobile"
                  : "btn-profile-light"
              }
              color="#F1F1F5"
              onClick={() =>
                dispatch(push(`/dashboard/pinContent/?${patient.id}`))
              }
              icon={<Pin size="small" color="#28539E" />}
            />
            {/* <Button
              size="small"
              label="Schedule Appointments"
              className="btn-profile-light"
              color="#F1F1F5"
              icon={<Plan size="small" color="#28539E" />}
            /> */}
            <Button
              size="small"
              label="Assessments"
              onClick={() =>
                dispatch(push(`/dashboard/scheduleAssessments/?${patient.id}`))
              }
              className={
                isMobileorTablet
                  ? "btn-profile-light-mobile"
                  : "btn-profile-light"
              }
              color="#F1F1F5"
              icon={<Clipboard size="small" color="#28539E" />}
            />
            <Menu
              size="small"
              label={
                <Box direction="row" align="center" gap="small">
                  <Flag size="small" color="#28539E" />
                  Set Flag
                </Box>
              }
              className={
                isMobileorTablet
                  ? "btn-profile-light-mobile"
                  : "btn-profile-light"
              }
              color="#F1F1F5"
              justifyContent="center"
              dropAlign={{ top: "bottom" }}
              dropProps={{ className: "dropMenu" }}
              items={[
                {
                  label: "Remove Flag",
                  onClick: () => {
                    setResetFlagShow(!resetFlagShow);
                    setFlagtoSet(null);
                  },
                },
                {
                  label: "Set Red Flag",
                  onClick: () => {
                    setResetFlagShow(!resetFlagShow);
                    setFlagtoSet("RED");
                  },
                },
                {
                  label: "Set Yellow Flag",
                  onClick: () => {
                    setResetFlagShow(!resetFlagShow);
                    setFlagtoSet("YELLOW");
                  },
                },
              ]}
              icon={false}
            />
            <Button
              size="small"
              label="Patient Dashboard"
              className="btn-profile-dark"
              color="#395E9D"
              onClick={() =>
                dispatch(push(`/dashboard/patient/?${patient.id}`))
              }
              icon={<LinkPrevious size="small" color="white" />}
            />
          </Box>
        </Box>
        <Box margin={{ top: "medium" }} />
      </Box>
    </>
  );
};
export default ProfileBoxCommon;
