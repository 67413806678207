import { Box, Button, Layer, Text } from "grommet";
import { EmptyCircle } from "grommet-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { unpinPost } from "../../store/patients/actions";
import SingleAudioListItem from "./SingleAudioListItem";
import SinglePostListItem from "./SinglePostListItem";
import SingleTikTokListItem from "./SingleTikTokListItem";
import SingleVideoListItem from "./SingleVideoListItem";
import SingleViewMedicaListItem from "./SingleViewMedicaListItem";

const RenderPinnedContent = ({
  posts,
  videos,
  viewmedica,
  audios,
  tikToks,
  pinnedContent,
}) => {
  const dispatch = useDispatch();
  const [unpinnedall, setunpinnedall] = useState(false);
  const [display, setDisplayOut] = useState(false);

  const processUnpinAll = () => {
    setDisplayOut(true);
  };

  const onYes = () => {
    setunpinnedall(true);
    pinnedContent.forEach((x) => {
      dispatch(unpinPost(x.id));
    });
    setDisplayOut(false);
  };

  const getIfPinned = (postID, type) => {
    const find = pinnedContent.find(
      (data) => data.identifier === postID && data.type === type
    );
    if (find) return find;

    return false;
  };

  const getItemfromLists = (type, id) => {
    if (type === "Article") {
      return posts.find((post) => post.id === id);
    }
    if (type === "Video") {
      return videos.find((video) => video.id === id);
    }
    if (type === "ViewMedica") {
      return viewmedica.find((viewmedica) => viewmedica.id === id);
    }
    if (type === "Audio") {
      return audios.find((audio) => audio.id === id);
    }
    if (type === "TikTok") {
      return tikToks.find((tikTok) => tikTok.id === id);
    }
  };

  const renderBoxBasedOnType = (type, pinnedContent) => {
    if (type === "Article")
      if (getItemfromLists(type, pinnedContent.identifier))
        return (
          <SinglePostListItem
            post={getItemfromLists(type, pinnedContent.identifier)}
            pinned={getIfPinned(pinnedContent.identifier, type)}
          />
        );
      else return null;

    if (type === "Video")
      if (getItemfromLists(type, pinnedContent.identifier))
        return (
          <SingleVideoListItem
            post={getItemfromLists(type, pinnedContent.identifier)}
            pinned={getIfPinned(pinnedContent.identifier, type)}
          />
        );
      else return null;

    if (type === "ViewMedica")
      if (getItemfromLists(type, pinnedContent.identifier))
        return (
          <SingleViewMedicaListItem
            post={getItemfromLists(type, pinnedContent.identifier)}
            pinned={getIfPinned(pinnedContent.identifier, type)}
          />
        );
      else return null;

    if (type === "Audio")
      if (getItemfromLists(type, pinnedContent.identifier))
        return (
          <SingleAudioListItem
            post={getItemfromLists(type, pinnedContent.identifier)}
            pinned={getIfPinned(pinnedContent.identifier, type)}
          />
        );
      else return null;

    if (type === "TikTok")
      if (getItemfromLists(type, pinnedContent.identifier))
        return (
          <SingleTikTokListItem
            post={getItemfromLists(type, pinnedContent.identifier)}
            pinned={getIfPinned(pinnedContent.identifier, type)}
          />
        );
      else return null;
  };

  return (
    <Box>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to unpin all content?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => onYes()}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setDisplayOut(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}
      {pinnedContent.length > 0 && !unpinnedall && (
        <Box pad="small" align="end">
          <Box
            onClick={processUnpinAll}
            className="noOutline"
            background="#FA8581"
            round="8px"
            pad="small"
          >
            <Text size="small" color="#fff">
              UNPIN ALL
            </Text>
          </Box>
        </Box>
      )}
      <Box gap="medium" wrap direction="row">
        {!unpinnedall && pinnedContent.length > 0 ? (
          pinnedContent.map((x) => renderBoxBasedOnType(x.type, x))
        ) : (
          <Box
            justify="center"
            pad="large"
            width="100%"
            height="100%"
            align="center"
          >
            <Box direction="row" gap="small" align="center">
              {" "}
              <EmptyCircle></EmptyCircle>
              <Text size="small">No content pinned</Text>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RenderPinnedContent;
