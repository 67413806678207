import { Box, Layer, Select, Text } from "grommet";
import { Clipboard, Schedule } from "grommet-icons";
import { split } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentResultsOld } from "../../store/patients/actions";
import BoxOldAssessment from "./BoxOldAssessment";
import InPageLoader from "./InPageLoader";

const ViewPastAssessments = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [dataFor, setDataFor] = React.useState(3);
  const [dateFrom, setDateFrom] = React.useState(
    moment().subtract(3, "months")
  );
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const oldResults = useSelector(
    (state) => state.patients.assessment_results_old
  );
  const assessmentTypes = useSelector(
    (state) => state.patients.assessment_types
  );
  const schedulableAssessments = [];

  const checkIfSchedulable = (assessment) => {
    const thisAssessment = assessmentTypes.find(
      (element) => String(element.id) === String(assessment)
    );
    if (thisAssessment) return true;

    return false;
  };

  if (assessmentTypes)
    assessmentTypes.forEach((x, index) => {
      schedulableAssessments[index] = x.name;
    });
  const patientId = split(search, "?", 2)[1];
  const handleOptionChange = (key) => {
    setDataFor(key);
    setDateFrom(moment().subtract(key, "months"));
  };

  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchAssessmentResultsOld(patientId, onSuccess, dateFrom, new Date())
    );
  }, [dateFrom]);

  let resultArray;

  let actualResults = [];

  if (oldResults) {
    resultArray = Object.entries(oldResults);

    resultArray.forEach((x) => {
      x[1].forEach((y) => {
        actualResults.push(y);
      });
    });

    actualResults.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });
  }
  const gettotalAssessmentCount = () => {
    let total = 0;
    resultArray.forEach((x) => {
      total += x[1].length;
    });

    return total;
  };

  return (
    <>
      {showModal && (
        <Layer
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
          style={{
            height: "80%",
            width: "60%",
            borderRadius: "10px",
            marginLeft: "140px",
          }}
        >
          <Box pad="small" gap="small">
            <Box
              direction="row"
              justify="between"
              align="center"
              style={{ flexShrink: "0" }}
              pad="small"
            >
              <Text size="medium" color="#395E9D" weight="bold">
                View Past Assessments
              </Text>
              <Box background="#a1ddec50" round="10px">
                <Select
                  value={dataFor}
                  labelKey="value"
                  multiple={false}
                  valueKey={{ key: "key", reduce: true }}
                  options={[
                    { value: "Last Three Months", key: 3 },
                    { value: "Last Six Months", key: 6 },
                    { value: "Last Year", key: 12 },
                    { value: "Last Two Years", key: 24 },
                  ]}
                  dropProps={{
                    style: {
                      borderRadius: "10px",
                      marginTop: "5px",
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  style={{ color: "#2bc2f7" }}
                  icon={<Schedule color="#2bc2f7" />}
                  onChange={({ option }) => handleOptionChange(option.key)}
                  dropHeight="small"
                  size="small"
                  plain
                />
              </Box>
            </Box>
            {loading ? (
              <InPageLoader />
            ) : (
              <Box style={{ overflowY: "auto" }}>
                <Box
                  pad="small"
                  // className="
                  style={{ flexShrink: "0" }}
                  direction="row"
                  border="bottom"
                >
                  <Text
                    color="#395E9D"
                    size="small"
                    style={{ width: "25%" }}
                    weight="bold"
                  >
                    Assessment
                  </Text>

                  <Text
                    color="#395E9D"
                    size="small"
                    style={{ width: "20%" }}
                    weight="bold"
                  >
                    Date Taken
                  </Text>
                  {/* <Box align="center"> */}
                  <Text
                    style={{ width: "15%" }}
                    color="#395E9D"
                    size="small"
                    weight="bold"
                  >
                    Score
                  </Text>
                  {/* </Box> */}
                  <Box style={{ width: "35%" }} align="center">
                    <Text color="#395E9D" size="small" weight="bold">
                      Tag
                    </Text>
                  </Box>
                  <Box style={{ width: "10%" }} align="center">
                    <Text color="#395E9D" size="small" weight="bold">
                      View
                    </Text>
                  </Box>
                </Box>
                {actualResults.map((test, index) =>
                  test.assessment && checkIfSchedulable(test.assessment) ? (
                    <Box gap="small" style={{ flexShrink: "0" }}>
                      <BoxOldAssessment
                        tests={[test]}
                        key={index}
                        name={index}
                        assessmentTypes={assessmentTypes}
                      />
                    </Box>
                  ) : null
                )}
                {gettotalAssessmentCount() === 0 ? (
                  <Box align="center" height="100%" pad="large">
                    <Text size="small" color>
                      No assessments found
                    </Text>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
        </Layer>
      )}

      <Box
        onClick={() => setShowModal(true)}
        className="noOutline"
        background="#385E9C"
        pad="small"
        round="8px"
        direction="row"
        gap="small"
        align="center"
        style={{ cursor: "pointer" }}
      >
        <Clipboard color="#fff" />
        <Text size="small">View All Assessments</Text>
      </Box>
    </>
  );
};

export default ViewPastAssessments;
