export default {
  app: {
    isInitialized: false,
    user: null,
    notification: { open: false },
    redirectTo: "",
  },
  patients: {},
  sales: {},
  aco: { assessment_improvement: [] },
};
