import React from "react";
import { Box, Tip, Text } from "grommet";

const CustomToolTip = ({ content, outText, classIn, subText }) => (
  <Box className={classIn || "Tooltip"} justify="center">
    <Tip
      content={
        <>
          <Text size="small">{content}</Text>
          <Text size="xsmall">{subText}</Text>
        </>
      }
    >
      {outText}
    </Tip>
  </Box>
);

export default CustomToolTip;
