import React, { useEffect } from "react";
import {
  Box,
  Text,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";
import InPageLoader from "./InPageLoader";
import { split } from "lodash";
import { fetchAssessmentsSchedule } from "../../store/patients/actions";

const SingleTableRow = ({ row, color }) => {
  return (
    <TableRow key={row.id}>
      <TableCell scope="row">
        <Text size="small">{moment(row.created).format("MM/DD/YYYY")}</Text>
      </TableCell>
      <TableCell scope="row">
        <Text color={color} size="small">
          {row.score === 1 ? "Completed" : "Overdue"}
        </Text>
      </TableCell>
    </TableRow>
  );
};

const SingleTableRowPending = ({ date, status, color }) => {
  return (
    <TableRow>
      <TableCell scope="row">
        <Text size="small">{moment(date).format("MM/DD/YYYY")}</Text>
      </TableCell>
      <TableCell scope="row">
        <Text color={color} size="small">
          {status}
        </Text>
      </TableCell>
    </TableRow>
  );
};

const YOQChart = ({ data, heading }) => {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const patientId = split(search, "?", 2)[1];

  const onSuccess = () => true;

  const schedules = useSelector((state) => state.patients.assessments_schedule);
  useEffect(() => {
    dispatch(fetchAssessmentsSchedule(onSuccess, patientId));
  });

  if (!schedules) return <InPageLoader />;

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          {heading}
        </Text>
        <CustomToolTip
          outText="i"
          content="Single-item questions to assess the patient's daily mental well-being."
          subText="Data shown for the selected date range."
        />
      </Box>
      <Box
        style={{ overflowY: "auto", maxHeight: "300px" }}
        margin={{ top: "small" }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                <Text size="small" weight="bold">
                  DATE
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom">
                <Text size="small" weight="bold">
                  STATUS
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((x) => (
              <SingleTableRow row={x} color="#74C9C1" />
            ))}
            {schedules.map((x) => {
              if (x.assessment_name === "YOQ-1")
                return (
                  <SingleTableRowPending
                    date={x.next_date}
                    status="Scheduled"
                    color="#000000"
                  />
                );
              else return null;
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default YOQChart;
