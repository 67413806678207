/* eslint-disable no-undef */
import React, { useState } from "react";
import { Box, Text, Image, Button, Layer } from "grommet";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Logo from "../../../../assets/light-logo.png";
import Home from "../../../../assets/icons/home.svg";
import People from "../../../../assets/icons/userspeople.svg";
import Logout from "../../../../assets/logout.png";
import {
  Analytics,
  CaretDownFill,
  Clipboard,
  Currency,
  DocumentTime,
  DocumentUser,
  Navigate,
  Stripe,
  UserNew,
  UserPolice,
} from "grommet-icons";
import { logout } from "../../../../store/app/actions";
import MixpanelIcon from "../../../../assets/icons/mixpanel.svg";
const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.global.colors.light};
  width: 100%;
  text-decoration: none !important;
  border-left: 5px solid transparent !important;
  cursor: default;
  transition: 0.5s;

  &:hover {
    border-radius: 0px 10px 10px 0px !important;
    border-left: 5px solid #2bc2f7 !important;
    width: 100%;
    color: ${(props) => props.theme.global.colors.light};
    text-decoration: none !important;
    transition: 0.5s;
    cursor: default;
  }
  &.active {
    width: 100%;
    background-color: ${(props) => props.theme.global.colors.active};
    color: ${(props) => props.theme.global.colors.sideNav};
    text-decoration: none !important;
    font-weight: bold !important;
  }
`;

const SalesSidebar = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      width="270px"
      height="100vh"
      style={{
        position: "fixed",
        zIndex: 15,
      }}
      background="brand"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box
        width="100%"
        height="30%"
        align="center"
        justify="center"
        style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}
      >
        <Box>
          <Image src={Logo} alt="logo" />
          <Text weight="bold" margin={{ top: "12px" }} alignSelf="end">
            HEALTH
          </Text>
        </Box>
        <Text size="small" weight="bold">
          Operations Dashboard
        </Text>
      </Box>
      {/* <Box width="100%" height="5%" align="center" justify="start" /> */}
      <Box
        width="100%"
        height="100%"
        style={{ overflow: "auto" }}
        justify="between"
      >
        <Box gap="small" margin={{ top: "small" }}>
          <StyledLink exact to="/dashboard/salesHome">
            <Box
              pad={{ vertical: "12px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image width="20px" src={Home} alt="home" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Home
              </Text>
            </Box>
          </StyledLink>
          <StyledLink exact to="/dashboard/allClinics">
            <Box
              pad={{ vertical: "12px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Image width="20px" src={People} alt="clinics" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                All Clinics
              </Text>
            </Box>
          </StyledLink>
          <Box>
            <Box
              pad={{ vertical: "12px", horizontal: "medium" }}
              direction="row"
              align="center"
              margin={{ left: "5px" }}
              className="noOutline"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Clipboard size="20px" />

              <Text size="small" style={{ paddingLeft: "32px" }}>
                Reports
              </Text>
              <CaretDownFill style={{ marginLeft: "10px" }} />
            </Box>
            {isOpen ? (
              <Box>
                <StyledLink exact to="/dashboard/utilization-reports">
                  <Box
                    pad={{ vertical: "12px", horizontal: "medium" }}
                    direction="row"
                    align="center"
                    margin={{ left: "20px" }}
                  >
                    <Analytics size="20px" />
                    <Text size="small" style={{ paddingLeft: "32px" }}>
                      Utilization
                    </Text>
                  </Box>
                </StyledLink>

                <StyledLink exact to="/dashboard/revenue-reports">
                  <Box
                    pad={{ vertical: "12px", horizontal: "medium" }}
                    direction="row"
                    align="center"
                    margin={{ left: "20px" }}
                  >
                    <Currency size="20px" />
                    <Text size="small" style={{ paddingLeft: "32px" }}>
                      Revenue
                    </Text>
                  </Box>
                </StyledLink>

                <StyledLink exact to="/dashboard/overdue-invoices">
                  <Box
                    pad={{ vertical: "12px", horizontal: "medium" }}
                    direction="row"
                    align="center"
                    margin={{ left: "20px" }}
                  >
                    <DocumentTime size="20px" />
                    <Text size="small" style={{ paddingLeft: "32px" }}>
                      Overdue Invoices
                    </Text>
                  </Box>
                </StyledLink>
                <StyledLink exact to="/dashboard/patient-list">
                  <Box
                    pad={{ vertical: "12px", horizontal: "medium" }}
                    direction="row"
                    align="center"
                    margin={{ left: "20px" }}
                  >
                    <UserNew size="20px" />
                    <Text size="small" style={{ paddingLeft: "32px" }}>
                      Patient List
                    </Text>
                  </Box>
                </StyledLink>
                <StyledLink exact to="/dashboard/clinician-list">
                  <Box
                    pad={{ vertical: "12px", horizontal: "medium" }}
                    direction="row"
                    align="center"
                    margin={{ left: "20px" }}
                  >
                    <UserPolice size="20px" />
                    <Text size="small" style={{ paddingLeft: "32px" }}>
                      Clinician List
                    </Text>
                  </Box>
                </StyledLink>
              </Box>
            ) : null}
          </Box>
          <StyledLink exact to="/dashboard/test-accounts">
            <Box
              pad={{ vertical: "12px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <DocumentUser size="20px" alt="clinics" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Test Accounts
              </Text>
            </Box>
          </StyledLink>
          <StyledLink exact to="/dashboard/class-enrollments">
            <Box
              pad={{ vertical: "12px", horizontal: "medium" }}
              direction="row"
              align="center"
            >
              <Navigate size="20px" alt="classes" />
              <Text size="small" style={{ paddingLeft: "32px" }}>
                Class Enrollments
              </Text>
            </Box>
          </StyledLink>
        </Box>

        {!isOpen && (
          <Box
            width="100%"
            margin={{ bottom: "medium", top: "xlarge" }}
            justify="center"
            align="center"
          >
            <Box background="white" round="4px" margin={{ bottom: "small" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "none" }}
                href="https://mixpanel.com/project/2298452/"
              >
                <Box pad="small" gap="small" direction="row" align="center">
                  <Image src={MixpanelIcon} width="20px" alt="clinics" />
                  <Text size="small" color="brand">
                    Go to Mixpanel
                  </Text>
                </Box>
              </a>
            </Box>
            <Box background="white" round="4px" margin={{ bottom: "small" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "none" }}
                href="https://dashboard.stripe.com/dashboard"
              >
                <Box pad="small" gap="small" direction="row" align="center">
                  <Stripe
                    width="20px"
                    color="brand"
                    src={People}
                    alt="clinics"
                  />
                  <Text size="small" color="brand">
                    Go to Stripe
                  </Text>
                </Box>
              </a>
            </Box>
            <Box
              className="logoutButton"
              direction="row"
              align="center"
              justify="center"
            >
              <Image src={Logout} alt="resources" height="18px" width="22px" />
              <Button
                size="small"
                onClick={() => {
                  setShow(true);
                }}
                plain
                color="secondaryRed"
                label="Logout"
                style={{
                  padding: "0 20px",
                  textAlign: "center",
                  fontSize: "small",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default SalesSidebar;
