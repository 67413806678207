import React, { useState } from "react";
import { Box, Text } from "grommet";
import { View } from "grommet-icons";
import moment from "moment";
import ViewAnswersModalGeneric from "./ViewAnswersModalGeneric";

const BoxOldAssessment = ({ tests, name, assessmentTypes }) => {
  const [phqShow, setPhqShow] = useState();

  let tags = [];

  const thisAssessment = assessmentTypes.find(
    (element) => String(element.id) === String(tests[0].assessment)
  );

  const maxScore =
    thisAssessment.answer_group.answers[
      thisAssessment.answer_group.answers.length - 1
    ].score * thisAssessment.questionnaire.length;

  if (thisAssessment.tag_group)
    // eslint-disable-next-line prefer-destructuring
    tags = thisAssessment.tag_group.tags;

  const getTag = (score) => {
    if (tags.length === 0) return "No Tag Group";

    let returnTag = "";

    tags.forEach((element) => {
      if (score >= element.low_bound && score <= element.high_bound)
        returnTag = element.label;
    });

    return returnTag;
  };

  return (
    <>
      {phqShow && (
        <ViewAnswersModalGeneric
          display={phqShow}
          setDisplayOut={(value) => setPhqShow(value)}
          Answers={tests[tests.length - 1]}
          name={thisAssessment.name}
          tag={getTag(tests[tests.length - 1].score)}
        />
      )}

      <Box
        pad="small"
        // className="
        style={{ flexShrink: "0" }}
        direction="row"
        border="bottom"
      >
        <Text size="small" style={{ width: "25%" }}>
          {thisAssessment.name} Assessment
        </Text>

        <Box direction="row" style={{ width: "20%" }}>
          <Text size="small">
            {moment(tests[tests.length - 1].created).format("MM/DD/YYYY")}
          </Text>
        </Box>

        {/* <Box direction="row"  align="center"> */}
        <Text weight="bold" style={{ width: "15%" }} size="small">
          &nbsp;&nbsp;{tests[tests.length - 1].score}/{maxScore}
        </Text>
        {/* </Box> */}
        <Box
          className="result-tag"
          pad={{ horizontal: "small", vertical: "xxsmall" }}
          margin={{ left: "small" }}
          align="center"
          style={{ width: "35%" }}
        >
          <Text size="xsmall" style={{ textTransform: "uppercase" }}>
            {getTag(tests[tests.length - 1].score)}
          </Text>
        </Box>
        <View
          style={{ width: "10%" }}
          onClick={() => setPhqShow(!phqShow)}
          className="noOutline"
          color="#2BC2F7"
          size="14px"
        />
      </Box>
    </>
  );
};
export default BoxOldAssessment;
