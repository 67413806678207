import React, { useState } from "react";
import { Box, Menu } from "grommet";
import { Add, FormDown, Home, Organization } from "grommet-icons";
import CreateClinicGroup from "./CreateClinicGroup";
import CreateClinic from "./CreateClinic";

const SalesCreateMenu = () => {
  const [groupShow, setGroupShow] = useState(false);
  const [clinicShow, setClinicShow] = useState(false);

  return (
    <>
      <CreateClinicGroup
        display={groupShow}
        setDisplayOut={(value) => setGroupShow(value)}
      />
      <CreateClinic
        display={clinicShow}
        setDisplayOut={(value) => setClinicShow(value)}
        groupID={null}
      />

      <Menu
        size="small"
        label={
          <Box direction="row" align="center" gap="small">
            <Add size="small" color="white" />
            Create New
          </Box>
        }
        className="btn-profile-dark"
        color="#F1F1F5"
        justifyContent="center"
        dropAlign={{ top: "bottom" }}
        dropProps={{ className: "dropMenu" }}
        items={[
          {
            label: "New Healthcare Group",
            onClick: () => {
              setGroupShow(true);
            },
            icon: (
              <Box pad="xsmall">
                <Organization size="small" color="#395E9D" />
              </Box>
            ),
          },
          {
            label: "New Clinic",
            onClick: () => {
              setClinicShow(true);
            },
            icon: (
              <Box pad="xsmall">
                <Home size="small" color="#395E9D" />
              </Box>
            ),
          },
          // {
          //   label: "New Provider",
          //   onClick: () => {},
          //   icon: (
          //     <Box pad="xsmall">
          //       <UserAdd size="small" color="#395E9D" />
          //     </Box>
          //   ),
          // },
        ]}
        icon={<FormDown color="white" size="small" />}
      />
    </>
  );
};
export default SalesCreateMenu;
