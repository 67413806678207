import { Box, Image, Text } from "grommet";
import React from "react";
import Illus from "../../assets/rafiki.svg";
import Message from "../../assets/profileM.svg";
import Call from "../../assets/profileC.svg";

const QuickHelp = () => (
  <Box className="large-card" align="center" justify="center" pad="medium">
    <Box direction="row" gap="medium" align="center">
      <Box background="#395E9D10" pad="small" round="full">
        <Image src={Illus} width="100px" height="100px" />
      </Box>
      <Box gap="small">
        <Text weight="bold">Contact Us</Text>
        <Box direction="row" align="center" gap="small">
          <Image src={Message} width="30px" />{" "}
          <a className="customLink" href="mailto:help@rosehealth.com">
            help@rosehealth.com
          </a>
        </Box>
        <Box direction="row" align="center" gap="small">
          <Image src={Call} width="30px" />{" "}
          <a className="customLink" href="tel:8334007673">
            833-400-7673
          </a>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default QuickHelp;
