import { Box, Text } from "grommet";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentsSchedule } from "../../store/patients/actions";
import InPageLoaderNew from "./InPageLoaderNew";
import AddToCalendar from "react-add-to-calendar";

import "react-add-to-calendar/dist/react-add-to-calendar.css";

const NextAssessments = () => {
  const schedules = useSelector((state) => state.patients.assessments_schedule);
  const user = useSelector((state) => state.app.user.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAssessmentsSchedule(() => {
        return true;
      }, user.id)
    );
  });

  if (!schedules) return <InPageLoaderNew />;
  return (
    <Box align="center" justify="center" gap="small">
      <Text color="#395E9D">
        You have successfully completed your assessments!
      </Text>
      <Text color="#395E9D">Your next assessment is:</Text>
      {schedules.map((schedule) => {
        if (
          new Date(schedule.next_date) > new Date().setHours(0, 0, 0, 0) &&
          new Date(schedule.end_date) > new Date().setHours(0, 0, 0, 0)
        )
          return (
            <Box
              direction="row"
              gap="small"
              background="#395E9D10"
              round="4px"
              pad="small"
              align="center"
              justify="center"
            >
              <Text size="small" color="#395E9D">
                {schedule.assessment_name}
              </Text>
              <Text color="#395E9D" size="small">
                -
              </Text>
              <Text color="#395E9D" size="small">
                {moment(schedule.next_date).format("MM/DD/YYYY")}
              </Text>
              <AddToCalendar
                event={{
                  title: `${schedule.assessment_name} Assessment`,
                  description: `Rose Health ${schedule.assessment_name} Assessment`,
                  location: "Rose Dashboard",
                  startTime: `${schedule.next_date}`,
                  endTime: `${schedule.next_date}`,
                }}
                buttonTemplate={{ "calendar-plus-o": "left" }}
                listItems={[
                  { outlookcom: "Outlook.com" },
                  { apple: "Apple Calendar" },
                  { yahoo: "Yahoo" },
                  { google: "Google" },
                ]}
              />
            </Box>
          );
        else return null;
      })}
    </Box>
  );
};

export default NextAssessments;
