import { Box, Text } from "grommet";
import React from "react";

const TutorialVideos = () => (
  <Box className="large-card" pad="medium">
    <Text color="#395E9D" weight="bold" size="large">
      Tutorial Videos
    </Text>
  </Box>
);

export default TutorialVideos;
