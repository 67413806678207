import React from "react";
import { Box, Image } from "grommet";
import Loader from "react-loader-spinner";
import Logo from "../../assets/dark-logo.png";

const Loading = () => (
  <Box align="center" justify="center" height="100%">
    <Image src={Logo} />
    <Loader type="ThreeDots" color="#395E9D" height="100" width="100" />
  </Box>
);

export default Loading;
