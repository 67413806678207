import React from "react";
import { Box, Select, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import InfoCard from "../../../common/InfoCard";
import { RowCardContainer } from "../../../common/RowCardContainer";
import HistogramSales from "../../../common/HistogramSales";
import { useEffect } from "react";
import {
  getAllClinics,
  getGroups,
  getRevenueDataSales,
} from "../../../../store/sales/actions";
import InPageLoader from "../../../common/InPageLoader";
import {
  getColorsArrayOfLength,
  getLastxMonths,
  getRevenueByMonth,
  getRevenueByMonthCollected,
  getRevenueByMonthOutstanding,
  getTotalRevenuefromArray,
  getTotalRevenuefromArrayforCurrentMonth,
} from "../../../../util/globalFunctions";
import AllGroupsRevenue from "../../../common/AllGroupRevenue";
import { useState } from "react";
import { FormNext, Schedule } from "grommet-icons";
import { useHistory } from "react-router";

const RevenueReports = () => {
  const user = useSelector((state) => state.app.user.user);
  const allClinics = useSelector((state) => state.sales.all_clinics);
  const salesRevenue = useSelector((state) => state.sales.sales_revenue);
  const groups = useSelector((state) => state.sales.clinic_groups);

  function getFiltered() {
    const toPlay = allClinics.filter((clinic) =>
      groups.find((x) => x.id === clinic.clinic_group)
    );

    return toPlay;
  }

  const [dataFor, setDataFor] = useState(3);

  const dispatch = useDispatch();

  const success = () => {
    return true;
  };

  const history = useHistory();
  useEffect(() => {
    if (!allClinics) dispatch(getAllClinics(success, ""));
    if (!salesRevenue) dispatch(getRevenueDataSales(success));
    if (!groups) dispatch(getGroups(success, ""));
  }, []);

  if (!allClinics || !salesRevenue || !groups) return <InPageLoader />;

  return (
    <Box pad="medium" gap="medium">
      <Text color="#395E9D" weight="bold">
        Welcome, {user.first_name} {user.last_name}
      </Text>
      <RowCardContainer>
        <InfoCard
          title="Total Revenue YTD (Billed/Collected)"
          subTitle=""
          stat={`$${Number(
            getTotalRevenuefromArray(salesRevenue, getFiltered())
          ).toLocaleString("en-US")}`}
        />
        <InfoCard
          title="Revenue This Month"
          subTitle=""
          stat={`$${Number(
            getTotalRevenuefromArrayforCurrentMonth(salesRevenue, getFiltered())
          ).toLocaleString("en-US")}`}
        />
      </RowCardContainer>
      <Box className="selectReports" margin="small" alignSelf="end">
        <Select
          value={dataFor}
          labelKey="value"
          multiple={false}
          valueKey={{ key: "key", reduce: true }}
          options={[
            { value: "Last Three Months", key: 3 },
            { value: "Last Six Months", key: 6 },
            { value: "Last Year", key: 12 },
          ]}
          dropProps={{
            style: {
              borderRadius: "10px",
              marginTop: "5px",
              backgroundColor: "white",
              color: "black",
            },
          }}
          style={{ color: "#395E9D" }}
          icon={<Schedule color="#ADB5BD" />}
          onChange={({ option }) => setDataFor(option.key)}
          dropHeight="small"
          size="small"
          plain
        />
      </Box>
      <Box className="selectReports" alignSelf="end">
        <Box
          pad="small"
          direction="row"
          align="center"
          gap="small"
          className="noOutline"
          onClick={() => history.push("/dashboard/revenue-breakdown")}
        >
          <Text style={{ color: "#395E9D" }} size="small" weight="bold">
            View Detailed Report
          </Text>
          <FormNext color="#395E9D" />
        </Box>
      </Box>
      <RowCardContainer>
        <HistogramSales
          data={getRevenueByMonth(
            salesRevenue,
            getLastxMonths(dataFor),
            getFiltered()
          )}
          colors={getColorsArrayOfLength(getLastxMonths(dataFor), "#2BC2F7")}
          yAxisMax={Math.max(
            ...getRevenueByMonth(
              salesRevenue,
              getLastxMonths(dataFor),
              getFiltered()
            )
          )}
          // nameToPut="SCARED-1"
          // yAxisLabel="SCARED Score"
          horizontalDatas={getLastxMonths(dataFor)}
          heading="Revenue Per Month (Invoiced)"
          nameToPut="Revenue ($)"
          itemStyleColor="#2BC2F7"
          barColor="#395E9D"
        />
      </RowCardContainer>
      <RowCardContainer>
        <HistogramSales
          data={getRevenueByMonthCollected(
            salesRevenue,
            getLastxMonths(dataFor),
            getFiltered()
          )}
          colors={getColorsArrayOfLength(getLastxMonths(dataFor), "#2BC2F7")}
          yAxisMax={Math.max(
            ...getRevenueByMonthCollected(
              salesRevenue,
              getLastxMonths(dataFor),
              getFiltered()
            )
          )}
          // nameToPut="SCARED-1"
          // yAxisLabel="SCARED Score"
          horizontalDatas={getLastxMonths(dataFor)}
          heading="Revenue Per Month (Collected)"
          nameToPut="Revenue ($)"
          itemStyleColor="#2BC2F7"
          barColor="#395E9D"
        />
        <HistogramSales
          data={getRevenueByMonthOutstanding(
            salesRevenue,
            getLastxMonths(dataFor),
            getFiltered()
          )}
          colors={getColorsArrayOfLength(getLastxMonths(dataFor), "#2BC2F7")}
          yAxisMax={Math.max(
            ...getRevenueByMonthOutstanding(
              salesRevenue,
              getLastxMonths(dataFor),
              getFiltered()
            )
          )}
          // nameToPut="SCARED-1"
          // yAxisLabel="SCARED Score"
          horizontalDatas={getLastxMonths(dataFor)}
          heading="Revenue Per Month (Outstanding)"
          nameToPut="Revenue ($)"
          itemStyleColor="#2BC2F7"
          barColor="#395E9D"
        />
      </RowCardContainer>
      <AllGroupsRevenue
        invoices={salesRevenue}
        months={getLastxMonths(dataFor)}
      />
    </Box>
  );
};
export default RevenueReports;
