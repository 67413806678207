import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckBox,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";
import InPageLoader from "./InPageLoader";
import { getAllClinics, getGroups } from "../../store/sales/actions";
import { Document, Search } from "grommet-icons";
import InvoicesPendingAction from "./InvoicesPendingAction";
import InvoicesPendingActionRow from "./InvoicesPendingActionRow";
import { cloneDeep } from "lodash";

const AllGroups = () => {
  const [loading, setLoading] = useState(false);
  const groups = useSelector((state) => state.sales.clinic_groups);
  const clinics = useSelector((state) => state.sales.all_clinics);
  const [exportMode, setExportMode] = useState(false);

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sortParam, setSortParam] = useState("name");

  const [showOnly, setShowOnly] = useState(false);

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  const handleShowOnlyChange = () => {
    setShowOnly(!showOnly);
  };

  const paginatedResults = [];

  let counter = 1;

  const pageSie = exportMode ? 9999999999 : 10;

  let toPage = 0;

  const [searchTerm, setSearchTerm] = useState("");

  if (groups) {
    const groupsSortCopy = cloneDeep(groups);
    if (sortParam === "Name")
      groupsSortCopy.sort((a, b) => a.name.localeCompare(b.name));

    if (sortParam === "Created")
      groupsSortCopy.sort((a, b) => new Date(a.created) - new Date(b.created));

    if (sortParam === "Type")
      groupsSortCopy.sort((a, b) =>
        a.type && b.type
          ? a.type.localeCompare(b.type)
          : a.type
          ? 1
          : b.type
          ? -1
          : 0
      );

    if (sortParam === "Sales Rep")
      groupsSortCopy.sort((a, b) =>
        a.rose_sales_team_member && b.rose_sales_team_member
          ? a.rose_sales_team_member.localeCompare(b.rose_sales_team_member)
          : a.rose_sales_team_member
          ? 1
          : b.rose_sales_team_member
          ? -1
          : 0
      );

    for (let i = 0; i < groupsSortCopy.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      paginatedResults[toPage].push(groupsSortCopy[i]);
      counter += 1;

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }
  }
  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
      dispatch(getAllClinics(success, ""));
    }
  }, [sortParam]);

  useEffect(() => {
    dispatch(getGroups(success, searchTerm));
  }, [searchTerm]);

  const getNoLocations = (groupID) => {
    let count = 0;
    if (clinics)
      clinics.forEach((x) => {
        if (x.clinic_group === groupID) {
          count++;
        }
      });

    return count;
  };

  if (loading || !groups || !clinics)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Text color="#1D3763" weight="bold">
        All Healthcare Groups
      </Text>
      <Box direction="row" align="start" width="100%" justify="between">
        <Box
          round="small"
          align="center"
          justify="between"
          direction="row"
          height="38px"
          width={"320px"}
          className="whiteBack"
        >
          <TextInput
            name="search"
            plain
            type="text"
            className="searchInput"
            placeholder="Search"
            value={searchTerm}
            onChangeCapture={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            required
          />

          <Box margin={{ right: "small" }}>
            <Search color="#395E9D" />
          </Box>
        </Box>

        <Box gap="small" align="end">
          <Box justify="center" align="center">
            <CheckBox
              checked={showOnly}
              className="noOutline"
              toggle
              onChange={() => handleShowOnlyChange()}
              label={
                <Text size="small" color="brand">
                  Show Only Groups with Pending Action
                </Text>
              }
            />
          </Box>
          <Box direction="row" gap="small" align="start" justify="start">
            <CheckBox
              size="small"
              toggle
              checked={exportMode}
              onClick={() => setExportMode(!exportMode)}
            ></CheckBox>
            <Text size="small">Export Mode</Text>
          </Box>
          <Box direction="row-reverse" gap="small">
            <Box
              onClick={() => htmlToCSV("data.csv")}
              pad="small"
              style={{ maxHeight: "42px" }}
              alignSelf="end"
              align="center"
              className="selectReports"
              direction="row"
              gap="small"
            >
              <Text weight="bold" size="small" color="#395E9D">
                Download as CSV
              </Text>
              <Document color="#ADB5BD" />
            </Box>
            <Box
              border={{ side: "all", color: "#C4C4C4" }}
              round="xsmall"
              background="#FBFBFB"
              className="customInput"
            >
              <Select
                plain
                options={["Name", "Created", "Type", "Sales Rep"]}
                placeholder="Sort by"
                dropProps={{
                  style: { borderRadius: "10px" },
                }}
                dropHeight="small"
                size="small"
                name="assessment"
                value={sortParam}
                onChange={({ option }) => {
                  setSortParam(option);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="large-card" pad="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  NAME
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  CREATED
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  TYPE
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  SALES REP
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  LOCATIONS
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  INVOICES PENDING ACTION
                </Text>
              </TableCell>
              <TableCell scope="col" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {groups.length > 0 &&
              paginatedResults[page] &&
              !showOnly &&
              paginatedResults[page].map((group) => (
                <TableRow key={group.id}>
                  <TableCell scope="row" align="center">
                    <Text size="small">{group.name}</Text>
                  </TableCell>

                  <TableCell align="center">
                    <Text size="small">
                      {moment(group.created).format("MM/DD/YYYY")}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text size="small">
                      {group.type ? group.type : "Not Specified"}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text size="small">
                      {group.rose_sales_team_member
                        ? group.rose_sales_team_member
                        : "Not Specified"}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text Text size="small">
                      {getNoLocations(group.id)}
                    </Text>
                  </TableCell>
                  <TableCell align="center">
                    <InvoicesPendingAction groupID={group.id} />
                  </TableCell>
                  <TableCell>
                    <Box direction="row" justify="center" align="center">
                      <Button
                        className="btn-primary"
                        color="#2BC2F7"
                        onClick={() =>
                          dispatch(push(`/dashboard/healthgroup/?${group.id}`))
                        }
                        margin={{ horizontal: "small" }}
                        size="small"
                        label="View"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            {groups.length > 0 &&
              showOnly &&
              groups.map((group) => (
                <InvoicesPendingActionRow
                  group={group}
                  groupID={group.id}
                  getNoLocations={(id) => getNoLocations(id)}
                />
              ))}
          </TableBody>
        </Table>
        {!showOnly ? (
          <Box width="100%">
            <Box height="1px" background="#395E9D" />
            <Box
              direction="row"
              width="100%"
              justify="between"
              align="center"
              pad={{ horizontal: "small" }}
            >
              <Box>
                <Text size="small" color="#395E9D">
                  Showing{" "}
                  {groups.length === 0
                    ? 0
                    : pageSie * (page + 1) - (pageSie - 1)}{" "}
                  to {groups[page + 1] ? pageSie * (page + 1) : groups.length}{" "}
                  of {groups.length} entries
                </Text>
              </Box>
              <Box direction="row" align="center">
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page - 1] ? setPage(page - 1) : null
                  }
                  disabled={!paginatedResults[page - 1]}
                >
                  <Text size="small">
                    {groups.length === 0 ? null : "Previous"}
                  </Text>
                </Button>
                {pages.map((vals) => (
                  <Text
                    key={vals}
                    className={vals === page ? "activePage" : "inactivePage"}
                    textAlign="center"
                    size="small"
                    margin="xsmall"
                    color="#82D4E7"
                    onClick={() => setPage(vals)}
                  >
                    {vals + 1}
                  </Text>
                ))}
                {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page + 1] ? setPage(page + 1) : null
                  }
                  disabled={!paginatedResults[page + 1]}
                >
                  <Text size="small">
                    {groups.length === 0 ? null : "Next"}
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
export default AllGroups;
