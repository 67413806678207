import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Menu,
} from "grommet";
import { Alert, FormDown } from "grommet-icons";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import NavLink from "../../common/NavLink";
import RedAlert from "../../../assets/icons/red-alert-flag.svg";
import YellowAlert from "../../../assets/icons/yellow-alert-flag.svg";
import { isMobileHook, iTabletHook } from "../../common/Responsive";
import CustomToolTip from "../../common/CustomToolTip";
import InviteModal from "../../common/InviteModal";
import BulkInviteModal from "../../common/BulkInviteModal";

import Loader from "../../common/InPageLoader";
import {
  fetchFireFlagPatients,
  fetchPatients,
  setPatients,
  setRedPatients,
  setYellowPatients,
} from "../../../store/patients/actions";
import InviteModalStaff from "../../common/InviteModalStaff";
import BulkInviteModalStaff from "../../common/BulkInviteModalStaff";
import FireFlagsModal from "../../common/FireFlagsModal";

const Home = () => {
  const user = useSelector((state) => state.app.user.user);
  const redPatients = useSelector((state) => state.patients.redPatients) || [];
  // const redFirePatients =
  // useSelector((state) => state.patients.fireFlagPatients) || [];

  const yellowPatients =
    useSelector((state) => state.patients.yellowPatients) || [];
  const patients = useSelector((state) => state.patients.patients) || [];

  const dispatch = useDispatch();
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const [show, setShow] = useState(false);
  const [showBulk, setShowBulk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffShow, setStaffShow] = useState(false);
  const [showBulkStaff, setShowBulkStaff] = useState(false);
  const [fireFlagsToShow, setFireFlagsToShow] = useState([]);
  const [fireFlagModal, setFireFlagModal] = useState(false);

  const success = () => {
    setLoading(false);
  };

  const handleFireFlagModal = (patient) => {
    setFireFlagsToShow(patient);
    setFireFlagModal(true);
  };

  const fetchRedPatients = () => {
    setRedPatients([]);
    dispatch(
      fetchPatients(success, "999999", "", "", "RED", "", 1, "", "", "Red", "")
    );
    dispatch(fetchFireFlagPatients(1, success));
  };

  const fetchYellowPatients = () => {
    setYellowPatients([]);
    dispatch(
      fetchPatients(success, "5", "", "", "YELLOW", "", 1, "", "", "Yellow", "")
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchRedPatients();
    fetchYellowPatients();
    dispatch(
      fetchPatients(success, "999", "", "", "", "", 1, "", "", "All", "")
    );

    return () => {
      dispatch(setPatients([]));
    };
  }, []);

  const getCountOfInactive = () => {
    let count = 0;
    const d = new Date();
    d.setDate(d.getDate() - 30);

    patients.forEach((element) => {
      const lastActive = new Date(element.last_active);
      if (lastActive < d) count += 1;
    });
    return count;
  };

  const getCountOfBHI = () => {
    let count = 0;
    patients.forEach((element) => {
      if (element.bhi_eligible === true && element.bhi_enabled === false)
        count += 1;
    });
    return count;
  };

  const getCountOfNew = () => {
    let count = 0;
    patients.forEach((element) => {
      if (element.is_new === true) count += 1;
    });
    return count;
  };

  if (loading) {
    return <Loader />;
  }

  if (redPatients && redPatients[0]) {
    redPatients[0].sort((a, b) => b.fire_flag.length - a.fire_flag.length);
  }

  return (
    <>
      {fireFlagModal && (
        <FireFlagsModal
          fireFlagsToShow={fireFlagsToShow}
          display={fireFlagModal}
          setDisplayOut={(value) => setFireFlagModal(value)}
        />
      )}
      {show && (
        <InviteModal display={show} setDisplayOut={(value) => setShow(value)} />
      )}
      {showBulk && (
        <BulkInviteModal
          display={showBulk}
          setDisplayOut={(value) => setShowBulk(value)}
        />
      )}
      {staffShow && (
        <InviteModalStaff
          display={staffShow}
          setDisplayOut={(value) => setStaffShow(value)}
        />
      )}
      {showBulkStaff && (
        <BulkInviteModalStaff
          display={showBulkStaff}
          setDisplayOut={(value) => setShowBulkStaff(value)}
        />
      )}

      <Box width="100%" pad="medium" gap="small">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align={isMobileorTablet ? "start" : "center"}
        >
          <Box>
            <Heading level={3} color="#1B3664">
              Hi
              <span style={{ marginLeft: "8px" }}>
                {`${user.first_name} ${user.last_name}` || ""},
              </span>
            </Heading>
            <Text size="small">See how your patients are doing ...</Text>
          </Box>
          {user.role === "CLINIC_ADMIN" ? (
            <Menu
              className="menu"
              size="small"
              icon={<FormDown color="white" />}
              color="#395e9d"
              style={{ marginTop: isMobileorTablet ? "16px" : null }}
              label="+ Invite Patients"
              dropAlign={{ top: "bottom" }}
              dropProps={{ className: "dropMenu" }}
              justifyContent="center"
              items={[
                {
                  label: "Add a Patient",
                  onClick: () => {
                    setShow(!show);
                  },
                },
                {
                  label: "Invite Multiple Patients",
                  onClick: () => {
                    setShowBulk(!showBulk);
                  },
                },
                {
                  label: "View Invited Patients",
                  onClick: () => {
                    dispatch(push(`/dashboard/invitedPatients`));
                  },
                },
              ]}
            />
          ) : user.role === "CLINICAL_STAFF" ? (
            <Menu
              className="menu"
              size="small"
              icon={<FormDown color="white" />}
              color="#395e9d"
              style={{ marginTop: isMobileorTablet ? "16px" : null }}
              label="+ Invite Patients"
              dropAlign={{ top: "bottom" }}
              dropProps={{ className: "dropMenu" }}
              justifyContent="center"
              items={[
                {
                  label: "Add a Patient",
                  onClick: () => {
                    setStaffShow(!staffShow);
                  },
                },
                {
                  label: "Invite Multiple Patients",
                  onClick: () => {
                    setShowBulkStaff(!showBulkStaff);
                  },
                },
              ]}
            />
          ) : null}
        </Box>
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          justify="between"
          align="center"
          gap="medium"
          margin={{ top: "32px" }}
        >
          <Box
            onClick={() => {
              dispatch(push(`/dashboard/patients`));
            }}
            className={isMobileorTablet ? "card-mobile" : "card card-home"}
          >
            <Heading level={6}>All Patients</Heading>
            <Heading level={3}>{patients ? patients.length : null}</Heading>
            <Text size="small" color="dark-4">
              View All Patients
            </Text>
          </Box>
          <Box
            onClick={() => {
              dispatch(push(`/dashboard/inactivePatients`));
            }}
            className={isMobileorTablet ? "card-mobile" : "card card-home"}
          >
            <Heading level={6}>Inactive Patients</Heading>
            <Heading level={3}>
              {patients ? getCountOfInactive() : null}
            </Heading>
            <Text size="small" color="dark-4">
              View Inactive Patients
            </Text>
          </Box>
          <Box
            onClick={() => {
              dispatch(push(`/dashboard/patients?BHI`));
            }}
            className={isMobileorTablet ? "card-mobile" : "card card-home"}
          >
            <Box width="100%" justify="between" direction="row">
              <Heading level={6}>BHI Eligible Patients</Heading>
              <CustomToolTip
                content="BHI-eligible patients are those who meet the requirements for Behavioral Health Integration services,"
                subText="based on their clinical assessment results administered via the Rose app."
                outText="i"
                classIn="Tooltip-grey"
              />
            </Box>
            <Heading level={3}>{patients ? getCountOfBHI() : null}</Heading>
            <Text size="small" color="dark-4">
              View Patients to Enroll
            </Text>
          </Box>
          <Box
            onClick={() => {
              dispatch(push(`/dashboard/patients?NEW`));
            }}
            className={isMobileorTablet ? "card-mobile" : "card card-home"}
          >
            <Heading level={6} color="#409E55">
              New Patients
            </Heading>
            <Heading level={3}>{patients ? getCountOfNew() : null}</Heading>
            <Text size="small" color="dark-4">
              View New Patients
            </Text>
          </Box>
        </Box>
        <Box
          width="100%"
          justify="start"
          align="center"
          margin={{ top: "40px" }}
        >
          <Box direction="row" width="100%" justify="between">
            <Text size="medium" weight="bold" alignSelf="center">
              FLAGGED
            </Text>
            <Box
              className="headingline"
              width={isMobileorTablet ? "35%" : "92%"}
              alignSelf="center"
            />
          </Box>
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="evenly"
            gap="medium"
            align="center"
            margin={{ top: "32px" }}
          >
            <Box
              className="large-card-red"
              justify="between"
              align="center"
              gap="small"
            >
              <Box width="100%">
                <Box direction="row" align="start" width="100%">
                  <Image
                    src={RedAlert}
                    alt="red-flag"
                    margin={{ right: "xsmall", top: "xxsmall" }}
                  />
                  <Heading level={4}>
                    Red Alert Patients (
                    {redPatients.length > 0 ? redPatients[1] : 0})
                  </Heading>
                </Box>
                <Table>
                  <TableHeader>
                    <TableRow style={{ backgroundColor: "#FAFAFB" }}>
                      <TableCell scope="col" border="bottom">
                        <Text size="small" weight="bold">
                          NAME
                        </Text>
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        <Text size="small" weight="bold">
                          LAST LOGIN
                        </Text>
                      </TableCell>
                      <TableCell scope="col" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {redPatients.length > 0 &&
                      redPatients[0].map((patient, index) =>
                        index < 5 ? (
                          <TableRow key={patient.id}>
                            <TableCell scope="row">
                              <Box direction="row" gap="5px" align="center">
                                <Text
                                  weight="bold"
                                  // style={{ whiteSpace: "nowrap" }}
                                  size="small"
                                  color={
                                    patient.fire_flag.length > 0 ? "red" : null
                                  }
                                >
                                  {patient.last_name}, {patient.first_name}
                                </Text>
                                {patient.fire_flag.length > 0 ? (
                                  <Box
                                    className="noOutline"
                                    onClick={() => handleFireFlagModal(patient)}
                                    style={{ cursor: "pointer" }}
                                    pad="xxsmall"
                                    background="#FABFBF"
                                    gap="3px"
                                    justify="center"
                                    direction="row"
                                    align="center"
                                    round="4px"
                                  >
                                    <Alert size="10px" color="#EB5757" />
                                    <Text
                                      size="8px"
                                      weight="bold"
                                      color="#EB5757"
                                    >
                                      URGENT RISK
                                    </Text>
                                  </Box>
                                ) : null}
                              </Box>
                            </TableCell>

                            <TableCell align="start">
                              <Text
                                size="small"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {patient.last_active
                                  ? moment(patient.last_active).format(
                                      "M/D/YY h:mm:ss a"
                                    )
                                  : null}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Box
                                direction="row"
                                justify="center"
                                align="center"
                              >
                                <Button
                                  className="btn-primary"
                                  color="#2BC2F7"
                                  onClick={() =>
                                    dispatch(
                                      push(`/dashboard/patient/?${patient.id}`)
                                    )
                                  }
                                  margin={{ horizontal: "small" }}
                                  size="small"
                                  label="View"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : null
                      )}
                  </TableBody>
                </Table>
              </Box>
              <NavLink to="/dashboard/patients?RED">
                <Text weight="bold" size="small">
                  VIEW ALL RED PATIENTS
                </Text>
              </NavLink>
            </Box>
            <Box
              className="large-card-yellow"
              align="center"
              justify="between"
              gap="small"
              margin={isMobileorTablet ? { top: "32px" } : null}
            >
              <Box width="100%">
                <Box direction="row" align="start" width="100%">
                  <Image
                    src={YellowAlert}
                    alt="red-flag"
                    margin={{ right: "xsmall", top: "xxsmall" }}
                  />
                  <Heading level={4}>
                    Yellow Alert Patients (
                    {yellowPatients.length > 0 ? yellowPatients[1] : 0})
                  </Heading>
                </Box>
                <Table>
                  <TableHeader>
                    <TableRow style={{ backgroundColor: "#FAFAFB" }}>
                      <TableCell scope="col" border="bottom">
                        <Text size="small" weight="bold">
                          NAME
                        </Text>
                      </TableCell>
                      <TableCell scope="col" border="bottom">
                        <Text size="small" weight="bold">
                          LAST LOGIN
                        </Text>
                      </TableCell>
                      <TableCell scope="col" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {yellowPatients.length > 0 &&
                      yellowPatients[0].map((patient) => (
                        <TableRow key={patient.id}>
                          <TableCell scope="row">
                            <Box direction="row" gap="5px" align="center">
                              <Text
                                weight="bold"
                                size="small"
                                color={
                                  patient.fire_flag.length > 0 ? "red" : null
                                }
                              >
                                {patient.last_name}, {patient.first_name}
                              </Text>
                              {patient.fire_flag.length > 0 ? (
                                <Box
                                  className="noOutline"
                                  onClick={() => handleFireFlagModal(patient)}
                                  style={{ cursor: "pointer" }}
                                  pad="xxsmall"
                                  background="#FABFBF"
                                  gap="3px"
                                  justify="center"
                                  direction="row"
                                  align="center"
                                  round="4px"
                                >
                                  <Alert size="10px" color="#EB5757" />
                                  <Text
                                    size="8px"
                                    weight="bold"
                                    color="#EB5757"
                                  >
                                    URGENT RISK
                                  </Text>
                                </Box>
                              ) : null}
                            </Box>
                          </TableCell>

                          <TableCell align="start">
                            <Text size="small" weight="normal">
                              {patient.last_active
                                ? moment(patient.last_active).format(
                                    "M/D/YY h:mm:ss a"
                                  )
                                : null}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Box
                              direction="row"
                              justify="center"
                              align="center"
                            >
                              <Button
                                className="btn-primary"
                                color="#2BC2F7"
                                onClick={() => {
                                  dispatch(
                                    push(`/dashboard/patient/?${patient.id}`)
                                  );
                                }}
                                margin={{ horizontal: "small" }}
                                size="small"
                                label="View"
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
              <NavLink to="/dashboard/patients?YELLOW">
                <Text size="small" weight="bold">
                  VIEW ALL YELLOW PATIENTS
                </Text>
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
