import { Box, Heading } from "grommet";
import React from "react";
import { useSelector } from "react-redux";
import AlertsBox from "../../../common/AlertsBox";
import AllGroups from "../../../common/AllGroups";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import SalesCreateMenu from "../../../common/SalesCreateMenu";

const SalesHome = () => {
  const user = useSelector((state) => state.app.user.user);

  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;

  return (
    <>
      <Box width="100%" pad="medium">
        <Box
          direction={isMobileorTablet ? "column" : "row"}
          width="100%"
          align={isMobileorTablet ? "start" : "center"}
          justify="between"
        >
          <Heading level={3} color="#1B3664">
            Hi
            <span style={{ marginLeft: "8px" }}>
              {user ? `${user.first_name} ${user.last_name}` : ""},
            </span>
          </Heading>
          <SalesCreateMenu />
        </Box>
        <AlertsBox />
        <AllGroups />
      </Box>
    </>
  );
};

export default SalesHome;
