import React, { useEffect, useState } from "react";
import { Box, Button, TableCell, TableRow, Text } from "grommet";
import { useDispatch } from "react-redux";
import { getGroupInvoicesforPending } from "../../store/sales/actions";
import moment from "moment";
import { push } from "connected-react-router";
import ReactLoaderSpinner from "react-loader-spinner";

const InvoicesPendingActionRow = ({ group, groupID, getNoLocations }) => {
  const [loading, setLoading] = useState(false);
  const [countOfPending, setCountofPending] = useState(0);

  const dispatch = useDispatch();

  const onSuccess = ({ data }) => {
    let temp = 0;

    if (data)
      data.forEach((x) => {
        if (x.status === "open" || x.status === "draft") temp = temp + 1;
      });

    setCountofPending(temp);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getGroupInvoicesforPending(groupID, onSuccess));
  }, []);

  if (loading)
    return (
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>
          <Box align="center" justify="center" width="100%" pad="xsmall">
            <ReactLoaderSpinner type="ThreeDots" color="#395E9D" height="30" />
          </Box>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    );
  if (countOfPending === 0) return null;
  return (
    <TableRow key={group.id}>
      <TableCell scope="row" align="center">
        <Text size="small">{group.name}</Text>
      </TableCell>

      <TableCell align="center">
        <Text size="small">{moment(group.created).format("MM/DD/YYYY")}</Text>
      </TableCell>
      <TableCell align="center">
        <Text size="small">{group.type ? group.type : "Not Specified"}</Text>
      </TableCell>
      <TableCell align="center">
        <Text size="small">
          {group.rose_sales_team_member
            ? group.rose_sales_team_member
            : "Not Specified"}
        </Text>
      </TableCell>
      <TableCell align="center">
        <Text size="small">{getNoLocations(group.id)}</Text>
      </TableCell>
      <TableCell align="center">
        <Text color={countOfPending === 0 ? "green" : "red"} size="small">
          {countOfPending}
        </Text>
      </TableCell>
      <TableCell>
        <Box direction="row" justify="center" align="center">
          <Button
            className="btn-primary"
            color="#2BC2F7"
            onClick={() =>
              dispatch(push(`/dashboard/healthgroup/?${group.id}`))
            }
            margin={{ horizontal: "small" }}
            size="small"
            label="View"
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default InvoicesPendingActionRow;
