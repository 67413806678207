import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  // DateInput,
  TextArea,
} from "grommet";

import { Close } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "./InPageLoader";
import {
  enrollTWSBHIPatient,
  getStaff,
  saveBHINote,
} from "../../store/patients/actions";

const EnrollModalTWS = ({ display, setDisplayOut }) => {
  const patient = useSelector((state) => state.patients.patient) || null;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [notes, setNotes] = useState("");

  const staff = useSelector((state) => state.patients.staff);
  const user = useSelector((state) => state.app.user.user);
  const [showValue2, setShowValue2] = useState();

  const options = [];

  const onSuccess2 = (singlePatient) => {
    dispatch(saveBHINote(null, notes, singlePatient.data.id, user.id));
  };

  const enrollPatient = () => {
    let filtered = [];
    if (showValue2)
      filtered = showValue2.filter((value) => value !== patient.clinician);

    dispatch(
      enrollTWSBHIPatient(
        "TWS Patient",
        filtered,
        "Agreed",
        patient.id,
        user,
        onSuccess2
      )
    );
    setDisplayOut(false);
  };

  const onSuccess = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getStaff(onSuccess));
  }, []);

  if (staff) {
    staff.forEach((data) => {
      if (data.role === "TWS_CONCIERGE")
        options.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
    });
  }

  const handleDropDownSelect = (nextValue) => {
    const newArray = [];
    nextValue.forEach((element, index) => {
      newArray[index] = element.key;
    });
    setShowValue2(newArray);
  };

  const handleDropDownRemove = (nextValue, removedItem) => {
    const newArray = showValue2.filter((value) => value !== removedItem.key);
    setShowValue2(newArray);
  };

  const getSelectedValues = () => {
    const newArray = [];
    staff.forEach((data) => {
      if (data.id === patient.clinician) {
        newArray.push({
          value: `${data.first_name} ${data.last_name}`,
          key: data.id,
        });
      }
    });
    return newArray;
  };

  if (loading) {
    return (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-update"
        position="right"
      >
        <Box width="100%" height="100%">
          <Loader />
        </Box>
      </Layer>
    );
  }

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-update"
          position="right"
        >
          <Box gap="medium">
            <Box direction="row" justify="between">
              <Text size="medium" color="brand" weight="bold">
                Enroll Patient
              </Text>
              <Box>
                <Close onClick={() => setDisplayOut(false)} />
              </Box>
            </Box>
            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Name
              </Text>

              <TextInput
                plain
                type="text"
                size="small"
                disabled
                className="semiInput"
                value={`${patient.first_name} ${patient.last_name}`}
              />
            </Box>
            {/* <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Date of Birth
              </Text>
             
              <DateInput
                size="small"
                plain
                format="mm/dd/yyyy"
                value={patient.date_of_birth?  patient.date_of_birth : null}
                className="semiInput"
                disabled
              />
            </Box> */}
            <Box width="100%">
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Care Manager
              </Text>
              <Box
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
                className="customInput"
              >
                <Multiselect
                  options={options}
                  displayValue="value"
                  showCheckbox
                  closeOnSelect={false}
                  selectedValues={getSelectedValues()}
                  placeholder="Select..."
                  hidePlaceholder
                  avoidHighlightFirstOption
                  showArrow
                  onSelect={handleDropDownSelect} // Function will trigger on select event
                  onRemove={handleDropDownRemove} // Function will trigger on remove event
                  disablePreSelectedValues
                />
              </Box>
            </Box>

            <Box>
              <Text size="xsmall" weight="bold" color="#4F4F4F">
                Clinical Notes:
              </Text>
              <Box
                border={{ side: "all", color: "#C4C4C4" }}
                round="xsmall"
                background="#FBFBFB"
                margin={{ top: "6px" }}
              >
                <TextArea
                  size="medium"
                  name="notes"
                  plain
                  fill
                  type="text"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="semiInput"
                  resize={false}
                  required
                />
              </Box>
            </Box>

            <Box align="center">
              <Button
                className="btn-fullwidth"
                color="#395e9d"
                size="small"
                disabled={showValue2 === "" || notes === ""}
                label="Submit"
                onClick={() => enrollPatient()}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default EnrollModalTWS;
