/* eslint-disable no-prototype-builtins */
import React from "react";
import {
  Box,
  Text,
  Layer,
  TextInput,
  Button,
  Form,
  Select,
  DateInput,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import {
  createClinicMetadata,
  editClinicMetaData,
  getMetadatas,
} from "../../store/sales/actions";

const EditModalMetaData = ({ display, setDisplayOut }) => {
  const dispatch = useDispatch();

  const { search } = useSelector((state) => state.router.location);
  const clinicID = split(search, "?", 2)[1];

  const metaDatas = useSelector((state) => state.sales.clinic_metadatas);

  let thismetadata;

  if (metaDatas)
    thismetadata = metaDatas.find((x) => x.clinic.toString() === clinicID);

  const checkValueForFees = (value) => {
    if (value === 0 && value !== null) {
      return "0";
    }
    if (value > 0 && value !== null) {
      return value.toString();
    }
    if (value === null) {
      return "";
    }
  };

  function setMetaDataValues(metadataOne) {
    const obj = {
      setup_fees: checkValueForFees(metadataOne.setup_fees),
      number_of_providers_to_charge_setup_fees:
        metadataOne.number_of_providers_to_charge_setup_fees
          ? metadataOne.number_of_providers_to_charge_setup_fees
          : "",
      monthly_fees: metadataOne.monthly_fees
        ? metadataOne.monthly_fees.toString()
        : "",
      number_of_providers_to_charge_monthly_fees:
        metadataOne.number_of_providers_to_charge_monthly_fees
          ? metadataOne.number_of_providers_to_charge_monthly_fees
          : "",
      number_of_months_to_be_paid: metadataOne.number_of_months_to_be_paid
        ? metadataOne.number_of_months_to_be_paid
        : "",
      onboarding_date: metadataOne.onboarding_date
        ? metadataOne.onboarding_date
        : "",
      contract_effective_date: metadataOne.contract_effective_date
        ? metadataOne.contract_effective_date
        : "",
      monthly_billing_effective_date: metadataOne.monthly_billing_effective_date
        ? metadataOne.monthly_billing_effective_date
        : "",
      trial_period: metadataOne.trial_period ? metadataOne.trial_period : "",
      contract_termination_date: metadataOne.contract_termination_date
        ? metadataOne.contract_termination_date
        : "",
    };
    return obj;
  }
  const [valueInvoice, setValueInvoice] = React.useState(
    thismetadata ? setMetaDataValues(thismetadata) : ""
  );

  const onSuccessFinal = () => {
    setDisplayOut(false);
  };

  const onSuccess = () => {
    dispatch(getMetadatas(onSuccessFinal));
  };

  const invoiceSumbit = (form) => {
    if (thismetadata)
      dispatch(editClinicMetaData(thismetadata.id, form, onSuccess, clinicID));
    else dispatch(createClinicMetadata(clinicID, form, onSuccess));
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-sales"
          position="center"
        >
          <Form
            value={valueInvoice}
            onSubmit={invoiceSumbit}
            onChange={(nextValue) => setValueInvoice(nextValue)}
          >
            <Box width="100%" gap="medium">
              <Box direction="row" gap="medium">
                <Box width="25%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    One-time setup fee
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      name="setup_fees"
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={[
                        { value: "$0", key: "0" },
                        { value: "$1", key: "1" },
                        { value: "$250", key: "250" },
                        { value: "$300", key: "300" },
                        { value: "$400", key: "400" },
                        { value: "$500", key: "500" },
                        { value: "$600", key: "600" },
                        { value: "$700", key: "700" },
                        { value: "$800", key: "800" },
                        { value: "$900", key: "900" },
                        { value: "$1000", key: "1000" },
                        { value: "$1100", key: "1100" },
                        { value: "$1200", key: "1200" },
                        { value: "$1300", key: "1300" },
                        { value: "$1400", key: "1400" },
                        { value: "$1500", key: "1500" },
                        { value: "$1600", key: "1600" },
                        { value: "$1700", key: "1700" },
                        { value: "$1800", key: "1800" },
                        { value: "$1900", key: "1900" },
                        { value: "$2000", key: "2000" },
                        { value: "$2100", key: "2100" },
                        { value: "$2200", key: "2200" },
                        { value: "$2300", key: "2300" },
                        { value: "$2400", key: "2400" },
                        { value: "$2500", key: "2500" },
                      ]}
                      className="loginInput"
                      dropHeight="small"
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      size="small"
                      required
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Number of Providers
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      type="number"
                      required
                      className="loginInput"
                      size="small"
                      name="number_of_providers_to_charge_setup_fees"
                    />
                  </Box>
                </Box>
                <Box width="25%" justify="center">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Total Setup Fees
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#D7F4D2"
                    className="customInput"
                    justify="center"
                    align="center"
                    margin={{ top: "6px" }}
                  >
                    <Text plain className="loginInput" size="small">
                      $
                      {valueInvoice &&
                      valueInvoice.hasOwnProperty("setup_fees") &&
                      valueInvoice.hasOwnProperty(
                        "number_of_providers_to_charge_setup_fees"
                      )
                        ? valueInvoice.setup_fees *
                          valueInvoice.number_of_providers_to_charge_setup_fees
                        : null}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="25%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Monthly subscription fees
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      required
                      name="monthly_fees"
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={[
                        { value: "$0", key: "0" },
                        { value: "$40", key: "40" },
                        { value: "$100", key: "100" },
                        { value: "$250", key: "250" },
                        { value: "$300", key: "300" },
                        { value: "$400", key: "400" },
                        { value: "$500", key: "500" },
                        { value: "$600", key: "600" },
                        { value: "$700", key: "700" },
                        { value: "$800", key: "800" },
                        { value: "$900", key: "900" },
                        { value: "$1000", key: "1000" },
                        { value: "$1100", key: "1100" },
                        { value: "$1200", key: "1200" },
                        { value: "$1300", key: "1300" },
                        { value: "$1400", key: "1400" },
                        { value: "$1500", key: "1500" },
                        { value: "$1600", key: "1600" },
                        { value: "$1700", key: "1700" },
                        { value: "$1800", key: "1800" },
                        { value: "$1900", key: "1900" },
                        { value: "$2000", key: "2000" },
                        { value: "$2100", key: "2100" },
                        { value: "$2200", key: "2200" },
                        { value: "$2300", key: "2300" },
                        { value: "$2400", key: "2400" },
                        { value: "$2500", key: "2500" },
                      ]}
                      className="loginInput"
                      dropHeight="small"
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      size="small"
                    />
                  </Box>
                </Box>
                <Box width="50%" direction="row" gap="small">
                  <Box width="50%">
                    <Text size="xsmall" weight="bold" color="#4F4F4F">
                      Number of Providers
                    </Text>
                    <Box
                      border={{ side: "all", color: "#C4C4C4" }}
                      round="xsmall"
                      background="#FBFBFB"
                      className="customInput"
                      margin={{ top: "6px" }}
                    >
                      <TextInput
                        plain
                        type="number"
                        className="loginInput"
                        size="small"
                        required
                        name="number_of_providers_to_charge_monthly_fees"
                      />
                    </Box>
                  </Box>
                  <Box width="50%">
                    <Text size="xsmall" weight="bold" color="#4F4F4F">
                      Number of Months To be Paid
                    </Text>
                    <Box
                      border={{ side: "all", color: "#C4C4C4" }}
                      round="xsmall"
                      background="#FBFBFB"
                      className="customInput"
                      margin={{ top: "6px" }}
                    >
                      <TextInput
                        plain
                        className="loginInput"
                        size="small"
                        required
                        type="number"
                        name="number_of_months_to_be_paid"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box width="25%" justify="center">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Charged every{" "}
                    {valueInvoice &&
                    valueInvoice.hasOwnProperty("number_of_months_to_be_paid")
                      ? valueInvoice.number_of_months_to_be_paid
                      : "?"}{" "}
                    month(s)
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#D7F4D2"
                    className="customInput"
                    justify="center"
                    align="center"
                    margin={{ top: "6px" }}
                  >
                    <Text plain className="loginInput" size="small">
                      $
                      {valueInvoice &&
                      valueInvoice.hasOwnProperty("monthly_fees") &&
                      valueInvoice.hasOwnProperty(
                        "number_of_providers_to_charge_monthly_fees"
                      ) &&
                      valueInvoice.hasOwnProperty("number_of_months_to_be_paid")
                        ? valueInvoice.monthly_fees *
                          valueInvoice.number_of_providers_to_charge_monthly_fees *
                          valueInvoice.number_of_months_to_be_paid
                        : null}
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Contract Effective Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      format="mm/dd/yyyy"
                      className="loginInput"
                      size="small"
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,

                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                      name="contract_effective_date"
                      required
                    />
                  </Box>
                </Box>

                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Onboarding Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      format="mm/dd/yyyy"
                      className="loginInput"
                      size="small"
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,

                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                      name="onboarding_date"
                      required
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Trial Days
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <Select
                      plain
                      name="trial_period"
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={[
                        { value: "No Trial", key: "NO_TRIAL" },
                        { value: "45 Days", key: "45_DAYS" },
                        { value: "60 Days", key: "60_DAYS" },
                      ]}
                      className="loginInput"
                      dropHeight="small"
                      required
                      onChange={(e) => {
                        if (valueInvoice && valueInvoice.onboarding_date) {
                          if (e.value === "NO_TRIAL")
                            setValueInvoice({
                              ...valueInvoice,
                              trial_period: e.value,
                              monthly_billing_effective_date: new Date(
                                valueInvoice.onboarding_date
                              ).toISOString(),
                            });

                          if (e.value === "45_DAYS") {
                            const dateToSet = new Date(
                              valueInvoice.onboarding_date
                            );
                            dateToSet.setDate(dateToSet.getDate() + 45);

                            setValueInvoice({
                              ...valueInvoice,
                              trial_period: e.value,
                              monthly_billing_effective_date:
                                dateToSet.toISOString(),
                            });
                          }

                          if (e.value === "60_DAYS") {
                            const dateToSet = new Date(
                              valueInvoice.onboarding_date
                            );
                            dateToSet.setDate(dateToSet.getDate() + 60);

                            setValueInvoice({
                              ...valueInvoice,
                              trial_period: e.value,
                              monthly_billing_effective_date:
                                dateToSet.toISOString(),
                            });
                          }
                        }
                      }}
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      size="small"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Monthly Billing Effective Date
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <DateInput
                      format="mm/dd/yyyy"
                      className="loginInput"
                      size="small"
                      calendarProps={{
                        size: "medium",
                        daysOfWeek: true,

                        fill: true,
                        animate: false,
                        className: "padCal",
                      }}
                      required
                      name="monthly_billing_effective_date"
                    />
                  </Box>
                </Box>
              </Box>
              <Box width="50%">
                <Text size="xsmall" weight="bold" color="#4F4F4F">
                  Contract Termination Date
                </Text>
                <Box
                  border={{ side: "all", color: "#C4C4C4" }}
                  round="xsmall"
                  background="#FBFBFB"
                  className="customInput"
                  margin={{ top: "6px" }}
                >
                  <DateInput
                    format="mm/dd/yyyy"
                    className="loginInput"
                    size="small"
                    calendarProps={{
                      size: "medium",
                      daysOfWeek: true,

                      fill: true,
                      animate: false,
                      className: "padCal",
                    }}
                    name="contract_termination_date"
                    required
                  />
                </Box>
              </Box>

              <Box
                justify="end"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  label="Submit"
                  disabled={
                    !valueInvoice.hasOwnProperty("setup_fees") ||
                    !valueInvoice.hasOwnProperty("monthly_fees") ||
                    !valueInvoice.hasOwnProperty("trial_period")
                  }
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default EditModalMetaData;
