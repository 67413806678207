import React from "react";
import { Box } from "grommet";
import Loader from "react-loader-spinner";

const InPageLoaderWhite = () => (
  <Box
    align="center"
    justify="center"
    height="100%"
    style={{ position: "absolute" }}
  >
    <Loader type="ThreeDots" color="white" height="100" width="100" />
  </Box>
);

export default InPageLoaderWhite;
