import { deepFreeze } from "grommet/utils";

const customTheme = deepFreeze({
  layer: {
    extend: {
      overflow: "auto",
      pointerEvents: "auto",
    },
  },
  button: {
    extend: {
      outline: "none !important",
    },
  },
  global: {
    colors: {
      active: "#142B52",
      brand: "#1D3763",
      brandBlue: "#2BC2F7",
      sideNav: "#2bc2f7",
      light: "#ffffff",
      secondaryRed: "#FA8581",
    },
  },
  video: {
    scrubber: {
      color: "brand",
    },
  },

  select: {
    options: {
      text: {
        size: "small",
      },
    },
    clear: {
      text: {
        size: "small",
      },
    },
  },

  table: {
    body: {
      align: "start",
    },
    header: {
      align: "start",
    },
  },

  tip: {
    content: {
      round: "small",
      background: "#1D3763",
    },
  },
  accordion: {
    panel: {
      border: {
        size: "small",
        side: "bottom",
        color: "white",
      },
    },
    border: {
      color: "white",
    },
  },
  checkBox: {
    size: "18px",

    toggle: {
      background: "#395E9D",
      color: {
        light: "white",
      },
      size: "35px",
    },
  },
  tab: {
    color: "#50B5FF",
    extend: {
      fontSize: "2px",
    },
    hover: {
      color: "brand",
    },
    border: {
      size: "medium",
      radius: "4px",
      color: "transparent",
      active: {
        color: "#50B5FF",
      },
      hover: {
        color: "transparent",
      },
    },
    active: {
      color: "#50B5FF",
    },
  },
});

export default customTheme;
