/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { Box, Text, Layer, TextInput, Button, Form, Select } from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { editClinic, getClinic, getGroups } from "../../store/sales/actions";

const EditModalClinic = ({ display, setDisplayOut }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.sales.clinic_groups);

  const options = [];

  const thisClinic = useSelector((state) => state.sales.single_clinic);

  const loadSuccess = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getGroups(loadSuccess, ""));
  }, []);

  function setClinicValues(clinic) {
    const obj = {
      clinic_group: clinic.clinic_group ? clinic.clinic_group : "",
      name: clinic.name ? clinic.name : "",
      type: clinic.type ? clinic.type : "",
      street_address_1: clinic.street_address_1 ? clinic.street_address_1 : "",
      street_address_2: clinic.street_address_2 ? clinic.street_address_2 : "",
      city: clinic.city ? clinic.city : "",
      state: clinic.state ? clinic.state : "",
      country: clinic.country ? clinic.country : "",
      zip_code: clinic.zip_code ? clinic.zip_code : "",
      emergency_contact: clinic.emergency_contact
        ? clinic.emergency_contact
        : "",
      email: clinic.email ? clinic.email : "",
      contact_number: clinic.contact_number ? clinic.contact_number : "",
      rose_sales_team_member: clinic.rose_sales_team_member
        ? clinic.rose_sales_team_member
        : "",
    };
    return obj;
  }
  const [value, setValue] = React.useState(
    thisClinic ? setClinicValues(thisClinic) : ""
  );

  const onSuccessFinal = () => {
    setDisplayOut(false);
  };

  const onSuccess = () => {
    dispatch(getClinic(thisClinic.id, onSuccessFinal));
  };

  const clinicSubmit = (form) => {
    dispatch(editClinic(thisClinic.id, form, onSuccess));
  };

  if (loading) return null;

  if (groups)
    groups.forEach((data) => {
      options.push({
        value: data.name,
        key: data.id,
      });
    });

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-sales"
          position="center"
        >
          <Form
            value={value}
            onSubmit={clinicSubmit}
            onChange={(nextValue) => setValue(nextValue)}
          >
            <Box width="100%" gap="medium">
              <Box direction="row" gap="medium">
                <Box width="100%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Healthcare Group
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    margin={{ top: "6px" }}
                    className="customInput"
                  >
                    <Select
                      plain
                      required
                      name="clinic_group"
                      labelKey="value"
                      valueKey={{ key: "key", reduce: true }}
                      options={options}
                      className="loginInput"
                      dropHeight="small"
                      dropProps={{
                        style: { borderRadius: "10px" },
                      }}
                      size="small"
                    />
                  </Box>
                </Box>
              </Box>

              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Clinic Name
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="name"
                      required
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Rose Sales Team Member
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="rose_sales_team_member"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Primary Contact
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="emergency_contact"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Primary Contact Email
                  </Text>
                  <Box
                    round="xsmall"
                    justify="center"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <Box
                      border={{ side: "all", color: "#C4C4C4" }}
                      round="xsmall"
                      background="#FBFBFB"
                      className="customInput"
                      margin={{ top: "6px" }}
                    >
                      <TextInput
                        plain
                        className="loginInput"
                        size="small"
                        type="email"
                        required
                        name="email"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Street Address 1
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="street_address_1"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Street Address 2
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      name="street_address_2"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    City
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="city"
                    />
                  </Box>
                </Box>
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    State
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="state"
                    />
                  </Box>
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Zip Code
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="zip_code"
                    />
                  </Box>
                </Box>

                <Box width="50%">
                  <Text size="xsmall" weight="bold" color="#4F4F4F">
                    Country
                  </Text>
                  <Box
                    border={{ side: "all", color: "#C4C4C4" }}
                    round="xsmall"
                    background="#FBFBFB"
                    className="customInput"
                    margin={{ top: "6px" }}
                  >
                    <TextInput
                      plain
                      className="loginInput"
                      size="small"
                      required
                      name="country"
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                justify="end"
                margin={{ top: "medium" }}
                gap="medium"
                direction="row"
              >
                <Button
                  className="btn-inverse"
                  color="#c4c4c410"
                  size="small"
                  label="Cancel"
                  onClick={() => setDisplayOut(false)}
                />
                <Button
                  className="btn"
                  color="#395e9d"
                  size="small"
                  disabled={value && !value.hasOwnProperty("clinic_group")}
                  label="Next"
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        </Layer>
      )}
    </>
  );
};
export default EditModalClinic;
