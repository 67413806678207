/* eslint-disable no-console */
/* eslint-disable camelcase */
import { call, put, takeEvery } from "redux-saga/effects";

import {
  clearAssessmentImprovement,
  GET_ASSESSMENT_COUNT,
  GET_ASSESSMENT_IMPROVEMENT,
  GET_DEPRESSION_REMISSION,
  GET_FLAG_COUNT,
  GET_LAST_ACTIVE_COUNT,
  GET_MESSAGES_COUNT,
  PROCESS_ASSESSMENT_IMPROVEMENT,
  setAssesmentCount,
  setAssessmentImprovement,
  setDepressionRemission,
  setFlagCount,
  setLastActiveCount,
  setMessageCount,
} from "./actions";
import api from "../../services/api/ApiService";

function getMessageCountSagaCall(start_date, end_date, clinicID) {
  const resp = api.post(
    "/api/aco-executive-stats/get-messages-count-per-day/",
    {
      start_date,
      end_date,
      clinic_id: clinicID,
    }
  );

  return resp;
}

function* getMessageCountSaga({
  payload: { start_date, end_date, clinicID, callback },
}) {
  const { data } = yield call(
    getMessageCountSagaCall,
    start_date,
    end_date,
    clinicID
  );
  yield put(setMessageCount(data));
  yield call(callback, data);
}

function getAssessmentCountSagaCall(start_date, end_date, clinicID) {
  const resp = api.post(
    "/api/aco-executive-stats/get-assessment-type-count-per-day/",
    {
      start_date,
      end_date,
      clinic_id: clinicID,
    }
  );

  return resp;
}

function* getAssessmentCountSaga({
  payload: { start_date, end_date, clinicID, callback },
}) {
  const { data } = yield call(
    getAssessmentCountSagaCall,
    start_date,
    end_date,
    clinicID
  );
  yield put(setAssesmentCount(data));
  yield call(callback, data);
}

function getFlagCountSagaCall(start_date, end_date, clinicID) {
  const resp = api.post(
    "/api/aco-executive-stats/get-patient-flag-type-count-per-day/",
    {
      start_date,
      end_date,
      clinic_id: clinicID,
    }
  );

  return resp;
}

function* getFlagCountSaga({
  payload: { start_date, end_date, clinicID, callback },
}) {
  const { data } = yield call(
    getFlagCountSagaCall,
    start_date,
    end_date,
    clinicID
  );
  yield put(setFlagCount(data));
  yield call(callback, data);
}

function getLastActiveCountSagaCall(start_date, end_date, clinicID) {
  const resp = api.post(
    "/api/aco-executive-stats/get-last-active-patients-count-per-day/",
    {
      start_date,
      end_date,
      clinic_id: clinicID,
    }
  );

  return resp;
}

function* getLastActiveCountSaga({
  payload: { start_date, end_date, clinicID, callback },
}) {
  const { data } = yield call(
    getLastActiveCountSagaCall,
    start_date,
    end_date,
    clinicID
  );
  yield put(setLastActiveCount(data));
  yield call(callback, data);
}
function getDepressionRemissionSagaCall(startDate, endDate, clinicID) {
  const resp = api.post("/api/aco-executive-stats/get-depression-remission/", {
    start_date: startDate,
    end_date: endDate,
    clinic_id: clinicID,
  });

  return resp;
}

function* getDepressionRemissionSaga({
  payload: { startDate, endDate, clinicID, callback },
}) {
  try {
    const { data } = yield call(
      getDepressionRemissionSagaCall,
      startDate,
      endDate,
      clinicID
    );
    yield put(setDepressionRemission(data));
    yield call(callback, data);
  } catch (e) {
    console.log(e);
  }
}

function getAssessmentImprovementSagaCall(
  assessmentID,
  startDate,
  endDate,
  clinicID
) {
  const resp = api.post(
    "/api/aco-executive-stats/get-improvement-for-assessment/",
    {
      assessment_id: assessmentID,
      start_date: startDate,
      end_date: endDate,
      clinic_id: clinicID,
    }
  );

  return resp;
}

function* getAssessmentImprovementSaga({
  payload: {
    assessmentID,
    assessmentName,
    startDate,
    endDate,
    clinicID,
    callback,
  },
}) {
  try {
    const { data } = yield call(
      getAssessmentImprovementSagaCall,
      assessmentID,
      startDate,
      endDate,
      clinicID
    );
    data.assessment = assessmentName;
    console.log(data);

    yield put(setAssessmentImprovement(data));
    yield call(callback, data);
  } catch (e) {
    console.log(e);
  }
}

function* processAssessmentImprovementSaga({ payload: { callback } }) {
  try {
    yield put(clearAssessmentImprovement());
    yield call(callback);
  } catch (e) {
    console.log(e);
  }
}

function* acoSagas() {
  yield takeEvery(GET_MESSAGES_COUNT, getMessageCountSaga);
  yield takeEvery(GET_ASSESSMENT_COUNT, getAssessmentCountSaga);
  yield takeEvery(GET_FLAG_COUNT, getFlagCountSaga);
  yield takeEvery(GET_LAST_ACTIVE_COUNT, getLastActiveCountSaga);
  yield takeEvery(GET_DEPRESSION_REMISSION, getDepressionRemissionSaga);
  yield takeEvery(GET_ASSESSMENT_IMPROVEMENT, getAssessmentImprovementSaga);
  yield takeEvery(
    PROCESS_ASSESSMENT_IMPROVEMENT,
    processAssessmentImprovementSaga
  );
}

export default acoSagas;
