import React, { useState, useEffect } from "react";
import {
  Box,
  CheckBox,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import {
  getAllCliniciansandCoordinators,
  getAllClinics,
  getGroups,
  getPatientsDataSales,
  getTestGroups,
} from "../../store/sales/actions";
import { Document, Unsorted } from "grommet-icons";
import moment from "moment/moment";
import { cloneDeep } from "lodash";

const PatientListsComponent = () => {
  const [loading, setLoading] = useState(false);
  const [last30, setlast30] = useState(false);
  const [removeDemo, setRemoveDemo] = useState(false);

  const patients = useSelector((state) => state.sales.sales_patients);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.sales.clinic_groups);
  const testGroups = useSelector((state) => state.sales.clinic_groups_test);
  const clinics = useSelector((state) => state.sales.all_clinics);
  const clinicians = useSelector((state) => state.sales.all_clinicians);
  const coordinators = useSelector((state) => state.sales.all_coordinators);
  const [sortParam, setSortParam] = useState();
  const [reverse, setReverse] = useState(false);

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }
  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!patients) {
      setLoading(true);
      dispatch(getPatientsDataSales(success));
    }
    if (!groups) {
      dispatch(getGroups(success, ""));
    }

    if (!testGroups) {
      dispatch(getTestGroups(success, ""));
    }

    if (!clinics) {
      dispatch(getAllClinics(success, ""));
    }

    if (!clinicians || !coordinators)
      dispatch(getAllCliniciansandCoordinators());
  }, []);

  const getGroupName = (id) => {
    let thisGroup;
    if (groups) {
      thisGroup = groups.find((x) => x.id === id);
      if (thisGroup) return thisGroup.name;
    }

    if (testGroups) {
      thisGroup = testGroups.find((x) => x.id === id);
      if (thisGroup) return thisGroup.name;
    }

    return id;
  };

  const getClinicianName = (id) => {
    let thisClinician;
    if (clinicians) {
      thisClinician = clinicians.find((x) => x.id === id);
      if (thisClinician)
        return thisClinician.first_name + " " + thisClinician.last_name;
    }

    if (coordinators) {
      thisClinician = coordinators.find((x) => x.id === id);
      if (thisClinician)
        return thisClinician.first_name + " " + thisClinician.last_name;
    }

    return id;
  };

  const getClinicName = (id) => {
    let thisClinic;
    if (clinics && id) {
      thisClinic = clinics.find((x) => x.id === id);
      if (thisClinic) return thisClinic.name;
    }

    return id;
  };

  let patientSorted;
  if (patients) patientSorted = cloneDeep(patients.results);

  if (patientSorted) {
    if (sortParam === "Name")
      patientSorted.sort((a, b) => a.first_name.localeCompare(b.first_name));

    if (sortParam === "Created")
      patientSorted.sort((a, b) => new Date(a.created) - new Date(b.created));

    if (sortParam === "Email")
      patientSorted.sort((a, b) => a.email.localeCompare(b.email));

    if (sortParam === "Group")
      patientSorted.sort((a, b) =>
        a.clinic_group_id && b.clinic_group_id
          ? getGroupName(a.clinic_group_id).localeCompare(
              getGroupName(b.clinic_group_id)
            )
          : -1
      );

    if (sortParam === "Clinic")
      patientSorted.sort((a, b) =>
        a.clinic && b.clinic
          ? getClinicName(b.clinic).localeCompare(getClinicName(a.clinic))
          : -1
      );

    if (sortParam === "Clinician/Coordinator")
      patientSorted.sort((a, b) =>
        (a.clinician || a.tws_coordinator) && (b.clinician || b.tws_coordinator)
          ? getClinicianName(
              b.clinician ? b.clinician : b.tws_coordinator
            ).localeCompare(
              getClinicianName(a.clinician ? a.clinician : a.tws_coordinator)
            )
          : -1
      );

    if (reverse) patientSorted.reverse();
  }

  const checkLast30Active = (patient) => {
    if (!last30) return true;
    else {
      if (moment().diff(moment(patient.last_active), "days") > 30) return false;
      else return true;
    }
  };

  const checkRemoveDemo = (patient) => {
    let thisGroup;

    if (!removeDemo) return true;

    if (testGroups)
      thisGroup = testGroups.find((x) => x.id === patient.clinic_group_id);

    if (thisGroup) return false;
    else return true;
  };

  if (
    loading ||
    !patients ||
    !groups ||
    !testGroups ||
    !clinics ||
    !clinicians ||
    !coordinators
  )
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Text color="#1D3763" weight="bold">
        Patient List
      </Text>
      <Box
        direction="row"
        // background="Red"
        justify="end"
        align="center"
        gap="medium"
      >
        <Box direction="row" gap="small" align="center">
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            className="customInput"
          >
            <Select
              plain
              clear
              options={[
                "Name",
                "Created",
                "Email",
                "Group",
                "Clinic",
                "Clinician/Coordinator",
              ]}
              placeholder="Sort by"
              dropProps={{
                style: { borderRadius: "10px" },
              }}
              dropHeight="small"
              size="small"
              name="sort"
              value={sortParam}
              onChange={({ option }) => {
                setSortParam(option);
              }}
            />
          </Box>
          {sortParam ? <Unsorted onClick={() => setReverse(!reverse)} /> : null}
        </Box>
        <Box
          onClick={() => htmlToCSV("data.csv")}
          pad="small"
          style={{ maxHeight: "42px" }}
          align="center"
          className="selectReports"
          direction="row"
          gap="small"
        >
          <Text weight="bold" size="small" color="#395E9D">
            Download as CSV
          </Text>
          <Document color="#ADB5BD" />
        </Box>
      </Box>
      <Box direction="row" gap="small" align="start" justify="start">
        <CheckBox
          size="small"
          toggle
          checked={last30}
          onClick={() => setlast30(!last30)}
        ></CheckBox>
        <Text size="small">Patients Active in Last 30 Days</Text>
      </Box>
      <Box direction="row" gap="small" align="start" justify="start">
        <CheckBox
          size="small"
          toggle
          checked={removeDemo}
          onClick={() => setRemoveDemo(!removeDemo)}
        ></CheckBox>
        <Text size="small">Remove Demo Accounts</Text>
      </Box>
      <Box
        className="large-card"
        pad="small"
        style={{ maxWidth: "100%", overflowX: "scroll" }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  First Name
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Last Name
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Created
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Email{" "}
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Group
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Clinic
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  Clinician/Coordinator{" "}
                </Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {patientSorted &&
              patientSorted.length > 0 &&
              patientSorted.map((patient) =>
                checkLast30Active(patient) && checkRemoveDemo(patient) ? (
                  <TableRow key={patient.id}>
                    <TableCell scope="row" align="center">
                      <Text size="small">{patient.first_name}</Text>
                    </TableCell>
                    <TableCell scope="row" align="center">
                      <Text size="small">{patient.last_name}</Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {moment(patient.created).format("MM/DD/YY")}
                      </Text>
                    </TableCell>

                    <TableCell align="center">
                      <Text size="small">{patient.email}</Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {getGroupName(patient.clinic_group_id)}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">{getClinicName(patient.clinic)}</Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {getClinicianName(
                          patient.clinician
                            ? patient.clinician
                            : patient.tws_coordinator
                        )}
                      </Text>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            <TableRow>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>
              <TableCell align="center">&nbsp;</TableCell>

              <TableCell align="center">
                <Text weight="bold" size="small">
                  Total
                </Text>
              </TableCell>
              <TableCell>
                <Text weight="bold" size="small">
                  {patientSorted.length}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default PatientListsComponent;
