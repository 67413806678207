import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getGroups } from "../../store/sales/actions";
// import InvoicesPendingActionRow from "./InvoicesPendingActionRow";
import InvoiceOverdueRow from "./InvoicesOverdueRow";
import { Document } from "grommet-icons";

const OverdueGroups = () => {
  const [loading, setLoading] = useState(false);
  const groups = useSelector((state) => state.sales.clinic_groups);
  const dispatch = useDispatch();

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }
  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (!groups) {
      setLoading(true);
      dispatch(getGroups(success, ""));
    }
  }, []);

  if (loading || !groups)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <Box pad="medium" gap="small">
      <Text color="#1D3763" weight="bold">
        All Healthcare Groups
      </Text>
      <Box
        onClick={() => htmlToCSV("data.csv")}
        pad="small"
        style={{ maxHeight: "42px" }}
        alignSelf="end"
        align="center"
        className="selectReports"
        direction="row"
        gap="small"
      >
        <Text weight="bold" size="small" color="#395E9D">
          Download as CSV
        </Text>
        <Document color="#ADB5BD" />
      </Box>

      <Box className="large-card" pad="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  NAME
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  CREATED
                </Text>
              </TableCell>

              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  SALES REP
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  INVOICES 30 DAYS OVERDUE
                </Text>
              </TableCell>
              <TableCell scope="col" border="bottom" align="center">
                <Text size="small" weight="bold">
                  INVOICES 60 DAYS OVERDUE
                </Text>
              </TableCell>
              <TableCell scope="col" />
            </TableRow>
          </TableHeader>
          <TableBody>
            {groups.length &&
              groups.map((group) => (
                <InvoiceOverdueRow group={group} groupID={group.id} />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default OverdueGroups;
