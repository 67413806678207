import React from "react";
import { Box } from "grommet";
import Loader from "react-loader-spinner";

const InPageLoader = () => (
  <Box align="center" justify="center" height="100%">
    <Loader type="ThreeDots" color="#395E9D" height="100" width="100" />
  </Box>
);

export default InPageLoader;
