import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(NavLink)`
  color: ${(props) => props.theme.global.colors.brand};
  text-decoration: underline;
  font-size:small;
  &:hover {
    color: ${(props) => props.theme.global.colors.brand};
  }
  }
`;

export default StyledLink;
