import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { split } from "lodash";
import InPageLoader from "./InPageLoader";
import { getGroupInvoices } from "../../store/sales/actions";
import noInvoice from "../../assets/icons/noInvoice.svg";
import PDFModal from "./PDFModal";

const InvoicingHistory = () => {
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];
  const [loading, setLoading] = useState(false);
  const [modalPDF, setModalPDF] = useState();
  const [modalPDFOpen, setModalPDFOpen] = useState();

  const invoices = useSelector((state) => state.sales.group_invoices);
  const dispatch = useDispatch();

  const success = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getGroupInvoices(groupID, success));
  }, []);

  const getButtonAction = (invoice) => {
    if (invoice.status === "paid")
      return (
        <a
          href={invoice.invoice_pdf}
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          View PDF
        </a>
      );
    else if (invoice.status === "draft")
      return (
        <Box onClick={() => openPDFModal(invoice.id, invoice)}>Finalize</Box>
      );
    else if (invoice.status === "open")
      return (
        <Box onClick={() => openPDFModal(invoice.id, invoice)}>
          Mark as Paid
        </Box>
      );
    else return null;
  };

  const openPDFModal = (id, pdf) => {
    setModalPDF(pdf);
    setModalPDFOpen(true);
  };

  if (loading || !invoices)
    return (
      <Box pad="medium" gap="small" className="large-card">
        <InPageLoader />
      </Box>
    );

  return (
    <>
      <PDFModal
        display={modalPDFOpen}
        setDisplayOut={(value) => setModalPDFOpen(value)}
        invoice={modalPDF}
        setLoading={(value) => setLoading(value)}
      />
      <Box pad="medium" gap="small" className="large-card">
        <Text color="#1D3763" weight="bold">
          Invoice History
        </Text>
        <Box style={{ maxHeight: "300px", overflowY: "auto" }}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    AMOUNT
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    CREATED
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    PAID ON
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    STATUS
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    ACTION
                  </Text>
                </TableCell>{" "}
              </TableRow>
            </TableHeader>
            {invoices.data.length > 0 ? (
              <TableBody>
                {invoices.data.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell scope="row">
                      <Text size="small">
                        {" "}
                        <Box align="center" className="gFlag">
                          ${invoice.amount_due / 100}
                        </Box>
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {moment(new Date(invoice.created * 1000)).format(
                          "MM/DD/YYYY"
                        )}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">
                        {invoice.status === "paid"
                          ? moment(
                              new Date(
                                invoice.status_transitions.paid_at * 1000
                              )
                            ).format("MM/DD/YYYY")
                          : "-"}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text
                        size="small"
                        style={{ textTransform: "capitalize" }}
                      >
                        {invoice.status}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Box
                        direction="row"
                        justify="center"
                        align="center"
                        style={{
                          fontSize: "small",
                          backgroundColor: "#c4c4c448",
                          borderRadius: "4px",
                          textAlign: "center",
                          textDecoration: "none",
                          color: "black",
                        }}
                        pad="8px 6px"
                        className="noOutline"
                      >
                        {invoice.status ? getButtonAction(invoice) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
          {invoices.data.length === 0 ? (
            <Box height="100%" align="center">
              <Image src={noInvoice} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
export default InvoicingHistory;
