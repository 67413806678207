import { Box, Button, Text } from "grommet";
import { Download } from "grommet-icons";
import React from "react";
import CaseStudy from "../../assets/HelpfulMaterials/Rose Case Study.pdf";
import MarketingROSEHealth from "../../assets/HelpfulMaterials/Marketing ROSE Health.pdf";
import BHIProviderOverview from "../../assets/HelpfulMaterials/BHI Provider Overview.pdf";
import TriFold from "../../assets/HelpfulMaterials/Patient TriFold.pdf";
import Poster from "../../assets/HelpfulMaterials/Rose Patient Poster.pdf";
import TopFeatures from "../../assets/HelpfulMaterials/Top Features.pdf";
import Onboarding from "../../assets/HelpfulMaterials/Patient Onboarding Instructions.pdf";
import Overview from "../../assets/HelpfulMaterials/patient overview.pdf";
import IntroEmail from "../../assets/HelpfulMaterials/Patient Intro Email.docx";

const HelpfulMaterial = () => (
  <Box className="large-card" pad="medium" gap="small">
    <Text color="#395E9D" weight="bold" size="large">
      Helpful Material
    </Text>
    <Text>Provider Resources</Text>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Rose Case Study</Text>
      <a href={CaseStudy} download="Rose Case Study.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Marketing Rose Health</Text>
      <a href={MarketingROSEHealth} download="Marketing ROSE Health.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">BHI Provider Overview</Text>
      <a href={BHIProviderOverview} download="BHI Provider Overview.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Text>Patient Facing Material</Text>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Patient Overview</Text>
      <a href={Overview} download="Patient Overview.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Patient Poster</Text>
      <a href={Poster} download="Patient Poster.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Patient Trifold</Text>
      <a href={TriFold} download="Patient Trifold.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Top Features</Text>
      <a href={TopFeatures} download="Top Features.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Patient Onboarding</Text>
      <a href={Onboarding} download="Patient Onboarding.pdf">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
    <Text>Email Templates</Text>
    <Box
      className="large-card"
      align="center"
      pad="small"
      direction="row"
      justify="between"
    >
      <Text color="#395E9D">Rose Introduction Email for Patients</Text>
      <a href={IntroEmail} download="Patient Intro Email.docx">
        <Button
          label="Download"
          plain
          className="btn-profile-light"
          icon={<Download size="small" color="#395E9D" />}
        />
      </a>
    </Box>
  </Box>
);

export default HelpfulMaterial;
