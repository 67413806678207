/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  // Collapsible,
  Layer,
} from "grommet";
// import { Up, Down } from "grommet-icons";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../../assets/light-logo.png";
// import Sessions from "../../../assets/icons/sessions.svg";
// import Messages from "../../../assets/icons/messages.svg";
// import Timesheet from "../../../assets/icons/timesheet.svg";
// import FAQ from "../../../assets/icons/faq.svg";
// import Resources from "../../../assets/icons/resources.svg";
import Logout from "../../../assets/logout.png";

import { logout } from "../../../store/app/actions";

const EmptySideBar = withRouter(() => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [open, setOpen] = useState(false);

  return (
    <Box
      width="270px"
      height="100vh"
      style={{
        position: "fixed",
        zIndex: 15,
      }}
      justify="between"
      background="brand"
    >
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-card"
          position="center"
        >
          <Box align="center">
            <Text size="medium" textAlign="center">
              Are you sure you want to logout?
            </Text>
            <Box
              direction="row"
              width="100%"
              justify="evenly"
              margin={{ top: "medium" }}
            >
              <Button
                className="btn-yes"
                color="#395e9d"
                label="Yes"
                onClick={() => dispatch(logout())}
              />

              <Button
                className="btn-no"
                color="#fa8581"
                label="No"
                onClick={() => setShow(false)}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box width="100%" height="35%" align="center" justify="center">
        <Box>
          <Image src={Logo} alt="logo" />
          <Text margin={{ top: "12px" }} alignSelf="end">
            HEALTH
          </Text>
        </Box>
      </Box>

      <Box
        className="logoutButton"
        direction="row"
        align="center"
        justify="center"
        margin={{ bottom: "medium" }}
      >
        <Image src={Logout} alt="resources" height="18px" width="22px" />
        <Button
          size="small"
          onClick={() => {
            setShow(true);
          }}
          plain
          color="secondaryRed"
          label="Logout"
          style={{
            padding: "0 20px",
            textAlign: "center",
            fontSize: "small",
          }}
        />
      </Box>
    </Box>
  );
});

export default EmptySideBar;
