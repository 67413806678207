/* eslint-disable camelcase */
export const GET_MESSAGES_COUNT = "aco/GET_MESSAGES_COUNT";
export const SET_MESSAGES_COUNT = "aco/SET_MESSAGES_COUNT";
export const GET_ASSESSMENT_COUNT = "aco/GET_ASSESSMENT_COUNT";
export const SET_ASSESSMENT_COUNT = "aco/SET_ASSESSMENT_COUNT";
export const GET_FLAG_COUNT = "aco/GET_FLAG_COUNT";
export const SET_FLAG_COUNT = "aco/SET_FLAG_COUNT";
export const GET_LAST_ACTIVE_COUNT = "aco/GET_LAST_ACTIVE_COUNT";
export const SET_LAST_ACTIVE_COUNT = "aco/SET_LAST_ACTIVE_COUNT";
export const GET_DEPRESSION_REMISSION = "aco/GET_DEPRESSION_REMISSION";
export const SET_DEPRESSION_REMISSION = "aco/SET_DEPRESSION_REMISSION";
export const GET_ASSESSMENT_IMPROVEMENT = "aco/GET_ASSESSMENT_IMPROVEMENT";
export const SET_ASSESSMENT_IMPROVEMENT = "aco/SET_ASSESSMENT_IMPROVEMENT";
export const CLEAR_ASSESSMENT_IMPROVEMENT = "aco/CLEAR_ASSESSMENT_IMPROVEMENT";
export const PROCESS_ASSESSMENT_IMPROVEMENT =
  "aco/PROCESS_ASSESSMENT_IMPROVEMENT";

export const getMessageCount = (start_date, end_date, clinicID, callback) => ({
  type: GET_MESSAGES_COUNT,
  payload: { start_date, end_date, clinicID, callback },
});

export const setMessageCount = (data) => ({
  type: SET_MESSAGES_COUNT,
  payload: { data },
});

export const getAssesmentCount = (
  start_date,
  end_date,
  clinicID,
  callback
) => ({
  type: GET_ASSESSMENT_COUNT,
  payload: { start_date, end_date, clinicID, callback },
});

export const setAssesmentCount = (data) => ({
  type: SET_ASSESSMENT_COUNT,
  payload: { data },
});

export const getFlagCount = (start_date, end_date, clinicID, callback) => ({
  type: GET_FLAG_COUNT,
  payload: { start_date, end_date, clinicID, callback },
});

export const setFlagCount = (data) => ({
  type: SET_FLAG_COUNT,
  payload: { data },
});

export const getLastActiveCount = (
  start_date,
  end_date,
  clinicID,
  callback
) => ({
  type: GET_LAST_ACTIVE_COUNT,
  payload: { start_date, end_date, clinicID, callback },
});

export const setLastActiveCount = (data) => ({
  type: SET_LAST_ACTIVE_COUNT,
  payload: { data },
});

export const getDepressionRemission = (
  startDate,
  endDate,
  clinicID,
  callback
) => ({
  type: GET_DEPRESSION_REMISSION,
  payload: { startDate, endDate, clinicID, callback },
});

export const setDepressionRemission = (data) => ({
  type: SET_DEPRESSION_REMISSION,
  payload: { data },
});

export const getAssessmentImprovement = (
  assessmentID,
  assessmentName,
  startDate,
  endDate,
  clinicID,
  callback
) => ({
  type: GET_ASSESSMENT_IMPROVEMENT,
  payload: {
    assessmentID,
    assessmentName,
    startDate,
    endDate,
    clinicID,
    callback,
  },
});

export const setAssessmentImprovement = (data) => ({
  type: SET_ASSESSMENT_IMPROVEMENT,
  payload: { data },
});
export const processAssessmentImprovement = (callback) => ({
  type: PROCESS_ASSESSMENT_IMPROVEMENT,
  payload: { callback },
});

export const clearAssessmentImprovement = () => ({
  type: CLEAR_ASSESSMENT_IMPROVEMENT,
  payload: {},
});
