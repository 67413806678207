import React, { useState } from "react";
import { Box, Text, TextArea } from "grommet";

import { useDispatch, useSelector } from "react-redux";
import { FormCheckmark, FormEdit } from "grommet-icons";
import InPageLoader from "./InPageLoader";
import { patchGroupNotes } from "../../store/sales/actions";

const GroupNotes = () => {
  const group = useSelector((state) => state.sales.single_group);

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(group && group.notes ? group.notes : null);

  const success = () => {
    setIsEditing(false);
  };
  const saveNote = () => {
    dispatch(patchGroupNotes(group.id, notes, success));
  };

  if (!group)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <>
      <Box pad="medium" gap="small" className="large-card">
        <Box direction="row" justify="between">
          <Text color="#1D3763" weight="bold">
            Notes
          </Text>
        </Box>
        <TextArea
          style={{ minHeight: "60%" }}
          height="100%"
          disabled={!isEditing}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <Box direction="row" justify="end" align="center" gap="small">
          <Box
            className="noOutline"
            onClick={() => setIsEditing(true)}
            pad="xsmall"
          >
            <FormEdit color="#1D3763" />
          </Box>
          <Box className="noOutline" onClick={() => saveNote()} pad="xsmall">
            <FormCheckmark color="#1D3763" />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default GroupNotes;
