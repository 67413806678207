import ReactEcharts from "echarts-for-react";
import React from "react";
import { Box, Text } from "grommet";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomToolTip from "./CustomToolTip";

const PainScale = ({ results }) => {
  const firstName = useSelector((state) => state.patients.patient.first_name);
  const lastName = useSelector((state) => state.patients.patient.last_name);

  const data = [];
  const xAxis = ["No Data Found"];

  const getOption = () => {
    results.forEach((element, index) => {
      xAxis[index] = element.created;
    });
    xAxis.sort();

    if (xAxis[0] !== "No Data Found")
      xAxis.forEach((item, index) => {
        xAxis[index] = moment(item).format("MMM DD");
      });

    results.forEach((element, index) => {
      data[index] = [element.score, element.score + 1, 0, 11];
    });

    return {
      legend: {
        show: true,
        top: 270,
        data: ["Pain"],
      },
      tooltip: {
        trigger: "item",
        formatter: function format(params) {
          return `${moment(params.name).format("MMM DD")} <br /> Pain: ${
            params.data[1]
          }`;
        },
      },

      toolbox: {
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            title: {
              zoom: "Zoom",
              back: "Zoom Out",
            },
          },
          saveAsImage: {
            title: "Save As Image",
            name: `Pain Check-In ${moment(new Date()).format(
              "MM DD YYYY"
            )} ${firstName} ${lastName}`,
          },
        },
      },

      xAxis: {
        offset: 5,
        type: "category",
        data: xAxis,
        axisLine: { show: false },
        axisTick: { show: false },
      },
      yAxis: {
        max: 10,
        name: "Pain Severity",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [0, 0, 10, 0],
          color: "#999999",
        },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          lineStyle: {
            color: "white",
          },
        },
        axisLabel: {
          color: "#999999",
        },
      },

      series: [
        {
          type: "candlestick",
          name: "Pain",
          barWidth: 15,
          itemStyle: {
            color: "#395E9D",
            color0: "#395E9D",
            borderColor: "#EFF3FE",
            borderColor0: "#EFF3FE",
            borderWidth: 3,
          },
          data,
        },
      ],
    };
  };

  return (
    <Box className="large-card" margin={{ top: "small" }} pad="medium">
      <Box direction="row" justify="between">
        <Text alignSelf="start" weight="bold" color="#395E9D">
          Pain Check-in
        </Text>
        <CustomToolTip
          outText="i"
          content="The numeric pain rating scale is the most common pain scale in medicine."
          subText="A person rates the severity of their pain on a scale of 0 to 10. 0 is an example of someone with no pain, with 10 being the worst pain possible."
        />
      </Box>
      <ReactEcharts option={getOption()} />
      <Box justify="center" align="center" gap="medium" direction="row">
        <Text size="xsmall" color="#838FA1">
          0: No Pain
        </Text>
        <Text size="xsmall" color="#838FA1">
          1-3: Mild
        </Text>
        <Text size="xsmall" color="#838FA1">
          4-6: Moderate
        </Text>
        <Text size="xsmall" color="#838FA1">
          7-9: Severe
        </Text>
        <Text size="xsmall" color="#838FA1">
          10: Worst Possible
        </Text>
      </Box>
    </Box>
  );
};
export default PainScale;
