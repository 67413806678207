import React from "react";
import { Box, Text, Layer } from "grommet";
import { Close } from "grommet-icons";
import moment from "moment";

const ViewAnswersModalGeneric = ({
  display,
  setDisplayOut,
  Answers,
  name,
  tag,
}) => (
  <>
    {display && (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-answers"
        position="center"
      >
        <Box>
          <Box
            justify="between"
            direction="row"
            style={{ flexShrink: "0" }}
            margin={{ bottom: "small" }}
          >
            <Box>
              <Text size="medium">{name} Assessment</Text>
              <Text size="small">
                Test taken:{" "}
                {Answers
                  ? moment(Answers.created).format("MM/DD/YYYY")
                  : "No Assessment Taken"}
              </Text>
              <Text size="small">
                Score: {Answers ? Answers.score : "No Assessment Taken"}
              </Text>
              <Text size="small">Tag: {tag ? tag : "No Assessment Taken"}</Text>
            </Box>
            <Close
              onClick={() => setDisplayOut(false)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{ overflowY: "auto", display: "inline-block" }}>
            {Answers
              ? Answers.answers.map((element, index) => (
                  <Box
                    style={{
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                    }}
                    round="xsmall"
                    margin={{ vertical: "medium", horizontal: "xsmall" }}
                  >
                    <Box
                      background="#395e9d"
                      pad="small"
                      style={{ borderRadius: "6px 6px 0px 0px" }}
                    >
                      <Text size="small">
                        Question {index + 1}:&nbsp;
                        {element.question.text}
                      </Text>
                    </Box>
                    <Box background="white" round="small" pad="small">
                      <Text size="small">
                        Answer Score:&nbsp;
                        {element.answer.score} <br />
                        Answer:&nbsp;
                        {element.answer.text}
                      </Text>
                    </Box>
                  </Box>
                ))
              : "No Assessment Taken"}
          </Box>
        </Box>
      </Layer>
    )}
  </>
);
export default ViewAnswersModalGeneric;
