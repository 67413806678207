/* eslint-disable no-nested-ternary */
import { Avatar, Box, CheckBox, Heading, Image, Text } from "grommet";
import { Edit } from "grommet-icons";
import { split } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getGroup,
  toggleInvoicingStatus,
  toggleTestStatus,
} from "../../../../store/sales/actions";
import EditModalClinicGroup from "../../../common/EditModalClinicGroup";
import GroupClinics from "../../../common/GroupClinics";
import GroupCreateMenu from "../../../common/GroupCreateMenu";
import GroupNotes from "../../../common/GroupNotes";
import InPageLoader from "../../../common/InPageLoader";
import InvoicingHistory from "../../../common/InvoicingHistory";
import { isMobileHook, iTabletHook } from "../../../common/Responsive";
import ContractAttachment from "./ContractAttachment";
import MethodsGroup from "./MethodsGroup";

const SingleHealthcareGroup = () => {
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [showEdit, setShowEdit] = useState(false);

  const thisGroup = useSelector((state) => state.sales.single_group);
  const thisGroupClincs = useSelector(
    (state) => state.sales.single_group_clinics
  );

  const onSuccess = () => {
    setLoading(false);
  };

  const handleAutoInvoicingChange = (groupID, currentVal) => {
    dispatch(toggleInvoicingStatus(groupID, currentVal));
  };
  const handleTestStatusChange = (groupID, currentVal) => {
    dispatch(toggleTestStatus(groupID, currentVal));
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getGroup(groupID, onSuccess));
  }, []);

  const getAvatar = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (thisGroup.hasOwnProperty("profilePicture")) {
      return <Image src={thisGroup.profilePicture} fit="cover" />;
    }
    return (
      <Box align="center" justify="center">
        <Text color="#28539e" style={{ textTransform: "uppercase" }} size="4xl">
          {thisGroup ? thisGroup.name[0] : ""}
        </Text>
      </Box>
    );
  };

  if (loading || !thisGroup) return <InPageLoader />;

  return (
    <>
      <EditModalClinicGroup
        display={showEdit}
        setDisplayOut={(value) => setShowEdit(value)}
      />
      <Box width="100%" pad="medium" gap="small">
        <Box width="100%" gap="medium">
          <Box>
            <Box pad="small" justify="between" direction="row">
              <Heading level={3} color="#1B3664">
                {thisGroup.name}
              </Heading>
              <GroupCreateMenu groupID={groupID} />
            </Box>
            <Box direction="row" gap="small">
              <Box
                className="large-card"
                width="75%"
                pad="small"
                gap="small"
                direction="row"
              >
                <Edit onClick={() => setShowEdit(true)} />
                <Box width="15%" align="center">
                  <Avatar
                    size="xlarge"
                    background="#f1f1f5"
                    style={{ border: "3px solid #28539e" }}
                  >
                    {getAvatar()}
                  </Avatar>
                </Box>
                <Box width="85%" direction="column" gap="small">
                  <Box direction="row" align="start" gap="medium">
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        NAME
                      </Text>
                      <Text size="small">{thisGroup.name}</Text>
                    </Box>
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        AUTO CC PAYMENT
                      </Text>
                      <Text size="small">
                        <Box direction="row">
                          <CheckBox
                            onChange={() => {
                              handleAutoInvoicingChange(
                                thisGroup.id,
                                thisGroup.auto_invoicing
                              );
                            }}
                            toggle
                            checked={
                              thisGroup ? thisGroup.auto_invoicing : false
                            }
                          />
                          <Text size="small" margin={{ left: "xsmall" }}>
                            {thisGroup && thisGroup.auto_invoicing
                              ? "ENABLED"
                              : "DISABLED"}
                          </Text>
                        </Box>
                      </Text>
                    </Box>
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        BILLING CONTACT
                      </Text>
                      <Text size="small">{thisGroup.contact_name}</Text>
                    </Box>
                  </Box>
                  <Box direction="row" align="start" gap="medium">
                    <Box width="33%" style={{ overflowX: "auto" }}>
                      <Text size="small" color="#395E9D">
                        BILLING CONTACT EMAIL
                      </Text>
                      <Text size="small">{thisGroup.email}</Text>
                    </Box>
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        ROSE SALES PERSON
                      </Text>
                      <Text size="small">
                        {thisGroup.rose_sales_team_member}
                      </Text>
                    </Box>
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        BILLING CONTACT NUMBER
                      </Text>
                      <Text size="small">{thisGroup.contact_number}</Text>
                    </Box>
                  </Box>

                  <Box direction="row" align="start" gap="medium">
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        PAYMENT TYPE
                      </Text>
                      <Text size="small">
                        {thisGroup
                          ? thisGroup.payment_type
                            ? thisGroup.payment_type
                            : "Not Specified"
                          : null}
                      </Text>
                    </Box>
                    <Box width="33%">
                      <Text size="small" color="#395E9D">
                        IS TEST ACCOUNT?
                      </Text>
                      <Text size="small">
                        <Box direction="row">
                          <CheckBox
                            onChange={() => {
                              handleTestStatusChange(
                                thisGroup.id,
                                thisGroup.is_test_account,
                                thisGroup.name
                              );
                            }}
                            toggle
                            checked={
                              thisGroup ? thisGroup.is_test_account : false
                            }
                          />
                          <Text size="small" margin={{ left: "xsmall" }}>
                            {thisGroup && thisGroup.is_test_account
                              ? "ENABLED"
                              : "DISABLED"}
                          </Text>
                        </Box>
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <ContractAttachment />
            </Box>
          </Box>
          <Box
            direction={isMobileorTablet ? "column" : "row"}
            width="100%"
            justify="between"
            align="center"
            gap="medium"
          >
            <Box className={isMobileorTablet ? "card-mobile" : "card"}>
              <Heading level={6} color="#395E9D">
                Created On
              </Heading>

              <Heading level={3}>
                {" "}
                {thisGroup
                  ? moment(thisGroup.created).format("MM/DD/YYYY")
                  : null}
              </Heading>
            </Box>
            <Box className={isMobileorTablet ? "card-mobile" : "card"}>
              <Heading level={6} color="#395E9D">
                Clinics
              </Heading>
              <Heading level={3}>
                {thisGroupClincs ? thisGroupClincs.length : null}
              </Heading>
            </Box>

            <MethodsGroup />

            <Box
              className={isMobileorTablet ? "card-mobile" : "card"}
              height="100%"
            >
              <Heading level={6} color="#395E9D">
                Type
              </Heading>
              <Text
                weight="bold"
                style={{ textTransform: "capitalize", wordWrap: "break-word" }}
                size="xsmall"
              >
                {thisGroup
                  ? thisGroup.type
                    ? thisGroup.type
                    : "Not Specified"
                  : null}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box width="100%" gap="medium" direction="row">
          <GroupClinics />
          <InvoicingHistory />
        </Box>
        <GroupNotes />
      </Box>
    </>
  );
};

export default SingleHealthcareGroup;
