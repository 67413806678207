import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CheckBox,
  Layer,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from "grommet";

import InPageLoader from "./InPageLoader";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassEnrollmentList,
  getPatientsDataSales,
} from "../../store/sales/actions";
import { Document, Unsorted } from "grommet-icons";
import { cloneDeep } from "lodash";
import moment from "moment";

const ClassEnrollments = () => {
  const [classes, setClasses] = useState(false);
  const [display, setDisplayOut] = useState(false);
  const [selectedClass, setSelectedClass] = useState();
  const [exportMode, setExportMode] = useState(false);
  const [sortParam, setSortParam] = useState("Date");
  const [reverse, setReverse] = useState(false);

  const baseURL = process.env.REACT_APP_CMS_URL;
  const [page, setPage] = useState(0);
  const paginatedResults = [];

  const success = () => {
    return true;
  };
  const salesPatients = useSelector((state) => state.sales.sales_patients);

  const enrollments = useSelector((state) => state.sales.class_enrollments);

  let counter = 1;

  const pageSie = exportMode ? 9999999999 : 10;

  let toPage = 0;
  if (classes) {
    const classesSort = cloneDeep(classes);

    if (sortParam === "Date")
      classesSort.sort((a, b) => new Date(b.Date) - new Date(a.Date));

    if (sortParam === "Title")
      classesSort.sort((a, b) => a.Title.localeCompare(b.Title));

    if (sortParam === "Duration")
      classesSort.sort((a, b) => a.Duration.localeCompare(b.Duration));

    if (sortParam === "Instructor")
      classesSort.sort((a, b) => a.Instructor.localeCompare(b.Instructor));

    if (reverse) classesSort.reverse();

    for (let i = 0; i < classes.length; i += 1) {
      if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

      paginatedResults[toPage].push(classesSort[i]);
      counter += 1;

      if (counter > pageSie) {
        toPage += 1;
        counter = 1;
      }
    }
  }

  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      dispatch(getClassEnrollmentList());
      const query = `${baseURL}live-classes`;

      const classesResponse = await axios.get(query);
      setClasses(classesResponse.data);
    }
    fetchData();
    if (!salesPatients) dispatch(getPatientsDataSales(success));
  }, []);

  const getFilteredEnrollments = () => {
    return enrollments.filter(
      (enrollment) => enrollment.class_id === selectedClass.id
    );
  };

  const getFilteredEnrollmentsByID = (id) => {
    return enrollments.filter((enrollment) => enrollment.class_id === id);
  };

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  if (!classes || !enrollments || !salesPatients)
    return (
      <Box>
        <InPageLoader />
      </Box>
    );

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-enrollments"
          position="center"
        >
          <Box pad="xsmall" gap="small">
            <Box background="brand" pad="small" round="10px">
              <Text color="white">Enrollments for {selectedClass.Title}</Text>
            </Box>

            <Box background="brand" pad="small" round="10px">
              <Text>Date: {selectedClass.Date}</Text>
            </Box>
            <Box background="brand" pad="small" round="10px">
              <Text>Time: {selectedClass.Time}</Text>
            </Box>

            <Box background="brand" pad="small" round="10px">
              <Text>Instructor: {selectedClass.Instructor}</Text>
            </Box>
            <Box gap="small">
              <Text> Enrollments:</Text>
              {getFilteredEnrollments().map((x, index) => (
                <Text>
                  {index + 1}.{" "}
                  {
                    salesPatients.results.find((y) => y.id === x.user_id)
                      .first_name
                  }{" "}
                  {
                    salesPatients.results.find((y) => y.id === x.user_id)
                      .last_name
                  }{" "}
                  - Patient ID:{" "}
                  {salesPatients.results.find((y) => y.id === x.user_id).id} -
                  Clinic ID:{" "}
                  {salesPatients.results.find((y) => y.id === x.user_id).clinic}{" "}
                  -{" "}
                  {salesPatients.results.find((y) => y.id === x.user_id)
                    .clinician
                    ? "Clinician ID: " +
                      salesPatients.results.find((y) => y.id === x.user_id)
                        .clinician
                    : "TWS Coordinator ID: " +
                      salesPatients.results.find((y) => y.id === x.user_id)
                        .tws_coordinator}
                </Text>
              ))}
            </Box>
          </Box>
        </Layer>
      )}
      <Box pad="medium" gap="small">
        <Text color="#1D3763" weight="bold">
          Class Enrollments
        </Text>

        <Box direction="row" gap="small" align="start" justify="start">
          <CheckBox
            size="small"
            toggle
            checked={exportMode}
            onClick={() => setExportMode(!exportMode)}
          ></CheckBox>
          <Text size="small">Export Mode</Text>
        </Box>
        <Box direction="row" gap="small" align="center">
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="xsmall"
            background="#FBFBFB"
            className="customInput"
          >
            <Select
              plain
              options={["Title", "Date", "Duration", "Instructor"]}
              placeholder="Sort by"
              dropProps={{
                style: { borderRadius: "10px" },
              }}
              dropHeight="small"
              size="small"
              name="sort"
              value={sortParam}
              onChange={({ option }) => {
                setSortParam(option);
              }}
            />
          </Box>
          {sortParam ? <Unsorted onClick={() => setReverse(!reverse)} /> : null}
        </Box>
        <Box
          onClick={() => htmlToCSV("data.csv")}
          pad="small"
          style={{ maxHeight: "42px" }}
          alignSelf="end"
          align="center"
          className="selectReports"
          direction="row"
          gap="small"
        >
          <Text weight="bold" size="small" color="#395E9D">
            Download as CSV
          </Text>
          <Document color="#ADB5BD" />
        </Box>

        <Box className="large-card" pad="small">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Title
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Instructor
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Date
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Time
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Duration
                  </Text>
                </TableCell>
                <TableCell scope="col" border="bottom" align="center">
                  <Text size="small" weight="bold">
                    Enrollments
                  </Text>
                </TableCell>

                <TableCell scope="col" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {classes.length > 0 &&
                paginatedResults[page] &&
                paginatedResults[page].map((classSingle) => (
                  <TableRow key={classSingle.id}>
                    <TableCell scope="row" align="center">
                      <Text size="small">{classSingle.Title}</Text>
                    </TableCell>
                    <TableCell scope="row" align="center">
                      <Text size="small">{classSingle.Instructor}</Text>
                    </TableCell>

                    <TableCell align="center">
                      <Text size="small">
                        {moment(classSingle.Date).format("MM/DD/YYYY")}
                      </Text>
                    </TableCell>
                    <TableCell align="center">
                      <Text size="small">{classSingle.Time} EST</Text>
                    </TableCell>

                    <TableCell align="center">
                      <Box direction="row" align="center" justify="center">
                        <Text size="small">{classSingle.Duration}</Text>
                      </Box>{" "}
                    </TableCell>
                    <TableCell align="center">
                      <Box direction="row" align="center" justify="center">
                        <Text size="small">
                          {getFilteredEnrollmentsByID(classSingle.id).length}
                        </Text>
                      </Box>{" "}
                    </TableCell>
                    <TableCell>
                      <Box direction="row" justify="center" align="center">
                        <Button
                          className="btn-primary"
                          color="#2BC2F7"
                          margin={{ horizontal: "small" }}
                          size="small"
                          label="View"
                          onClick={() => {
                            setSelectedClass(classSingle);
                            setDisplayOut(true);
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box width="100%">
            <Box height="1px" background="#395E9D" />
            <Box
              direction="row"
              width="100%"
              justify="between"
              align="center"
              pad={{ horizontal: "small" }}
            >
              <Box>
                <Text size="small" color="#395E9D">
                  Showing{" "}
                  {classes.length === 0
                    ? 0
                    : pageSie * (page + 1) - (pageSie - 1)}{" "}
                  to {classes[page + 1] ? pageSie * (page + 1) : classes.length}{" "}
                  of {classes.length} entries
                </Text>
              </Box>
              <Box direction="row" align="center">
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page - 1] ? setPage(page - 1) : null
                  }
                  disabled={!paginatedResults[page - 1]}
                >
                  <Text size="small">
                    {classes.length === 0 ? null : "Previous"}
                  </Text>
                </Button>
                {pages.map((vals) => (
                  <Text
                    key={vals}
                    className={vals === page ? "activePage" : "inactivePage"}
                    textAlign="center"
                    size="small"
                    margin="xsmall"
                    color="#82D4E7"
                    onClick={() => setPage(vals)}
                  >
                    {vals + 1}
                  </Text>
                ))}
                {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
                <Button
                  size="small"
                  color="#666666"
                  onClick={() =>
                    paginatedResults[page + 1] ? setPage(page + 1) : null
                  }
                  disabled={!paginatedResults[page + 1]}
                >
                  <Text size="small">
                    {classes.length === 0 ? null : "Next"}
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ClassEnrollments;
