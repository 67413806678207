import React, { useEffect, useState } from "react";
import { Box, Button, Text, Layer } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import { Pin } from "grommet-icons";
import moment from "moment";
import parse from "html-react-parser";
import { pinPost, unpinPost } from "../../store/patients/actions";
import { isMobileHook, iTabletHook } from "./Responsive";
import InPageLoader from "./InPageLoader";

const SingleTikTokListItem = ({ post, pinned }) => {
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.router.location);
  const patient = split(search, "?", 2)[1];
  const [internalPin, setInternalPin] = useState(pinned);
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  const setPin = () => {
    dispatch(pinPost("TikTok", post.id, patient, setInternalPin));
  };

  const setUnpin = (obj) => {
    dispatch(unpinPost(obj.id));
    setInternalPin(null);
  };

  const [showBlock, setShowBlock] = useState(false);

  const loadScript = (url) =>
    new Promise((resolve, reject) => {
      if (document.getElementById("tiktok-script")) {
        resolve("loaded");
      }
      const script = document.createElement("script");
      script.async = true;
      script.src = url;
      script.setAttribute("id", "tiktok-script");

      script.onload = () => {
        // script is loaded successfully, call resolve()
        resolve("loaded");
      };

      script.onerror = () => {
        // script is not loaded, call reject()
        reject("error");
      };

      document.head.appendChild(script);
    });

  useEffect(() => {
    if (show)
      loadScript("https://www.tiktok.com/embed.js").then((status) => {
        if (status === "loaded") {
          setShowBlock(true);
        }
      });
  }, [show]);

  return (
    <>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          plain
          className="popup-tiktok"
          position="center"
        >
          {/* <Markdown
            style={{ display: "flex", flexDirection: "column" }}
            components={{
              p: {
                component: "Paragraph",
                props: { maxWidth: "100%", style: { margin: "8px 0" } },
              },
              iframe: {
                props: { width: "100% !important", height: "100% !important" },
              },
            }}
          > */}

          {showBlock ? parse(post.TikTokEmbed) : <InPageLoader />}
          {/* {post.Embed} */}
          {/* </Markdown> */}
        </Layer>
      )}
      <Box
        className="deck-card"
        width={isMobileorTablet ? "100%" : "30%"}
        margin={{ top: "small" }}
        pad="medium"
        gap="small"
        justify="between"
      >
        <Text alignSelf="start" weight="bold" color="#395E9D" size="small">
          {post.Title}
        </Text>
        {internalPin ? (
          <Box direction="row" align="center" gap="small">
            <Box background="#C7EFFF50" round pad="small">
              <Pin size="small" color="#2BC2F7" />
            </Box>

            <Text color="#828282" size="small">
              {" "}
              Pinned on:{" "}
              {moment(internalPin.created).format("MM/DD/YY hh:mm a")}
            </Text>
          </Box>
        ) : (
          ""
        )}

        <Box direction="row" gap="small" justify="end" align="center">
          <Button
            color="transparent"
            className="btn-pins"
            label="View"
            size="small"
            onClick={() => setShow(true)}
          />
          <Button
            color="transparent"
            size="small"
            className="btn-pins-dark"
            label={internalPin ? "Unpin" : "Pin"}
            onClick={() => (internalPin ? setUnpin(internalPin) : setPin())}
          />
        </Box>
      </Box>
    </>
  );
};
export default SingleTikTokListItem;
