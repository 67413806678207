import React from "react";
import { Box, Collapsible, Text } from "grommet";
import { Down, Up } from "grommet-icons";
import SingleAudioListItem from "./SingleAudioListItem";

const RenderSingleCatAudio = ({ posts, element, color, pinnedContent }) => {
  const getIfPinned = (postID) => {
    const find = pinnedContent.find(
      (data) => data.identifier === postID && data.type === "Audio"
    );
    if (find) return find;

    return false;
  };

  const filterPosts = () => {
    let temp = posts.filter(
      (post) =>
        !post.app_type ||
        (post.app_type.App && post.app_type.App === "Adult") ||
        (post.app_type.App && post.app_type.App === "YouthandAdult")
    );

    return temp;
  };

  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box
        pad={{ bottom: "small", top: "medium", horizontal: "medium" }}
        width="100%"
        align="center"
        direction="row"
        justify="between"
        style={
          open
            ? { boxShadow: "none" }
            : { boxShadow: "none", borderBottom: "1px solid #DDE4EF" }
        }
        onClick={() => setOpen(!open)}
      >
        <Box direction="row" align="center">
          <Box height="10px" width="10px" round="large" background={color} />
          <Text
            size="small"
            color={open ? "#395E9D" : "#4F4F4F"}
            weight="bold"
            style={{ paddingLeft: "10px" }}
          >
            {element}
          </Text>
        </Box>
        {open ? (
          <Up
            color="white"
            style={{
              border: "1px solid white",
              backgroundColor: "#395E9D",
              padding: "2px",
              borderRadius: "100%",
            }}
          />
        ) : (
          <Down
            color="#395E9D"
            style={{
              border: "1px solid #395E9D",
              padding: "2px",
              borderRadius: "100%",
            }}
          />
        )}
      </Box>

      <Collapsible open={open}>
        <Box direction="row" gap="medium" wrap>
          {filterPosts().map((postdata) =>
            // eslint-disable-next-line no-nested-ternary
            postdata.classification ? (
              postdata.classification.Name === element ? (
                <SingleAudioListItem
                  key={postdata.id}
                  post={postdata}
                  pinned={getIfPinned(postdata.id)}
                />
              ) : null
            ) : null
          )}
        </Box>
      </Collapsible>
    </>
  );
};
export default RenderSingleCatAudio;
