import { Box, Heading, Text } from "grommet";
import { useDispatch } from "react-redux";
import React from "react";
import { isMobileHook, iTabletHook } from "../common/Responsive";

import { push } from "connected-react-router";
import CustomToolTip from "./CustomToolTip";

const InfoCard = ({
  link,
  title,
  subTitle,
  stat,
  toolTipContent,
  toolTipSubtext,
}) => {
  const dispatch = useDispatch();
  const isMobile = isMobileHook();
  const isTablet = iTabletHook();
  const isMobileorTablet = isMobile || isTablet;
  return (
    <Box
      onClick={() => {
        dispatch(push(link));
      }}
      className={isMobileorTablet ? "card-mobile" : "card card-home"}
    >
      <Box width="100%" justify="between" direction="row">
        <Heading level={6}>{title}</Heading>
        <CustomToolTip
          content={toolTipContent}
          subText={toolTipSubtext}
          outText="i"
          classIn="Tooltip-grey"
        />
      </Box>
      <Heading level={3}>{stat}</Heading>
      <Text size="small" color="dark-4">
        {subTitle}
      </Text>
    </Box>
  );
};

export default InfoCard;
