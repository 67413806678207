/* eslint-disable import/no-cycle */
import { all, fork } from "redux-saga/effects";
import appSagas from "./app/sagas";
import patientsSagas from "./patients/sagas";
import salesSagas from "./sales/sagas";
import acoSagas from "./aco/sagas";
import careGiverSagas from "./caregiver/sagas";

export default function* rootSaga(services = {}) {
  yield all([
    fork(appSagas, services),
    fork(patientsSagas, services),
    fork(salesSagas, services),
    fork(acoSagas, services),
    fork(careGiverSagas, services),
  ]);
}
