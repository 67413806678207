/* eslint-disable camelcase */
export const CREATE_CLINIC_GROUP = "sales/CREATE_CLINIC_GROUP";
export const GET_CLINIC_GROUPS = "sales/GET_CLINIC_GROUPS";
export const SET_CLINIC_GROUPS = "sales/SET_CLINIC_GROUPS";
export const CREATE_CLINIC = "sales/CREATE_CLINIC";
export const CREATE_CLINIC_METADATA = "sales/CREATE_CLINIC_METADATA";
export const GET_GROUP_SETUP_INTENT = "sales/GET_GROUP_SETUP_INTENT";
export const GET_CLINIC_GROUP = "sales/GET_CLINIC_GROUP";
export const SET_CLINIC_GROUP = "sales/SET_CLINIC_GROUP";
export const GET_CLINIC_GROUP_CLINICS = "sales/GET_CLINIC_GROUP_CLINICS";
export const SET_CLINIC_GROUP_CLINICS = "sales/SET_CLINIC_GROUP_CLINICS";
export const GET_CLINIC = "sales/GET_CLINIC";
export const SET_CLINIC = "sales/SET_CLINIC";
export const GET_CLINIC_META = "sales/GET_CLINIC_META";
export const SET_CLINIC_META = "sales/SET_CLINIC_META";
export const GET_CLINIC_GROUP_INVOICES = "sales/GET_CLINIC_GROUP_INVOICES";
export const GET_CLINIC_GROUP_INVOICES_FOR_PENDING =
  "sales/GET_CLINIC_GROUP_INVOICES_FOR_PENDING";
export const SET_CLINIC_GROUP_INVOICES = "sales/SET_CLINIC_GROUP_INVOICES";
export const GET_ALL_CLINICS = "sales/GET_ALL_CLINICS";
export const SET_ALL_CLINICS = "sales/SET_ALL_CLINICS";
export const EDIT_CLINIC_METADATA = "sales/EDIT_CLINIC_METADATA";
export const TOGGLE_INVOICING_STATUS = "sales/TOGGLE_INVOICING_STATUS";
export const PATCH_CLINIC_NOTES = "sales/PATCH_CLINIC_NOTES";
export const PATCH_GROUP_NOTES = "sales/PATCH_GROUP_NOTES";
export const FINALIZE_INVOICE = "sales/FINALIZE_INVOICE";
export const MARK_INVOICE_PAID_MANUAL = "sales/MARK_INVOICE_PAID_MANUAL";
export const EDIT_GROUP = "sales/EDIT_GROUP";
export const EDIT_CLINIC = "sales/EDIT_CLINIC";
export const DOWNLOAD_CONTRACT = "sales/DOWNLOAD_CONTRACT";
export const ATTACH_CONTRACT = "sales/ATTACH_CONTRACT";
export const REUPLOAD_CONTRACT = "sales/REUPLOAD_CONTRACT";
export const GET_PATIENTS_DATA_SALES = "sales/GET_PATIENTS_DATA_SALES";
export const SET_PATIENT_DATA_SALES = "sales/SET_PATIENT_DATA_SALES";
export const GET_REVENUE_DATA_SALES = "sales/GET_REVENUE_DATA_SALES";
export const SET_REVENUE_DATA_SALES = "sales/SET_REVENUE_DATA_SALES";
export const TOGGLE_TEST_STATUS = "sales/TOGGLE_TEST_STATUS";
export const TOGGLE_TEST_STATUS_FOR_TEST = "sales/TOGGLE_TEST_STATUS_FOR_TEST";
export const GET_TEST_CLINIC_GROUPS = "sales/GET_TEST_CLINIC_GROUPS";
export const SET_TEST_CLINIC_GROUPS = "sales/SET_TEST_CLINIC_GROUPS";
export const GET_CLASS_ENROLLMENT_LIST = "sales/GET_CLASS_ENROLLMENT_LIST";
export const SET_CLASS_ENROLLMENT_LIST = "sales/SET_CLASS_ENROLLMENT_LIST";
export const GET_ALL_CLINICIANS_AND_COORDINATORS =
  "sales/GET_ALL_CLINICIANS_AND_COORDINATORS";
export const SET_ALL_CLINICIANS = "sales/SET_ALL_CLINICIANS";
export const SET_ALL_COORDINATORS = "sales/SET_ALL_COORDINATORS";

export const createClinic = (form, callback) => ({
  type: CREATE_CLINIC,
  payload: { form, callback },
});

export const createClinicMetadata = (clinicID, form, callback) => ({
  type: CREATE_CLINIC_METADATA,
  payload: { clinicID, form, callback },
});

export const createClinicGroup = (form, callback) => ({
  type: CREATE_CLINIC_GROUP,
  payload: { form, callback },
});

export const getGroups = (callback, searchTerm) => ({
  type: GET_CLINIC_GROUPS,
  payload: { callback, searchTerm },
});

export const getTestGroups = (callback, searchTerm) => ({
  type: GET_TEST_CLINIC_GROUPS,
  payload: { callback, searchTerm },
});

export const setGroups = (data) => ({
  type: SET_CLINIC_GROUPS,
  payload: { data },
});

export const setTestGroups = (data) => ({
  type: SET_TEST_CLINIC_GROUPS,
  payload: { data },
});

export const getAllClinics = (callback, searchTerm) => ({
  type: GET_ALL_CLINICS,
  payload: { callback, searchTerm },
});

export const setAllClinics = (data) => ({
  type: SET_ALL_CLINICS,
  payload: { data },
});

export const getSetupIntentforGroup = (clinic_group_id, callback) => ({
  type: GET_GROUP_SETUP_INTENT,
  payload: { clinic_group_id, callback },
});

export const getGroup = (id, callback) => ({
  type: GET_CLINIC_GROUP,
  payload: { id, callback },
});

export const setGroup = (data) => ({
  type: SET_CLINIC_GROUP,
  payload: { data },
});

export const getGroupClinics = (id, callback) => ({
  type: GET_CLINIC_GROUP_CLINICS,
  payload: { id, callback },
});

export const setGroupClincs = (data) => ({
  type: SET_CLINIC_GROUP_CLINICS,
  payload: { data },
});

export const getClinic = (id, callback) => ({
  type: GET_CLINIC,
  payload: { id, callback },
});

export const setClinic = (data) => ({
  type: SET_CLINIC,
  payload: { data },
});

export const getMetadatas = (callback) => ({
  type: GET_CLINIC_META,
  payload: { callback },
});

export const SetMetadatas = (data) => ({
  type: SET_CLINIC_META,
  payload: { data },
});

export const getGroupInvoices = (id, callback) => ({
  type: GET_CLINIC_GROUP_INVOICES,
  payload: { id, callback },
});

export const getGroupInvoicesforPending = (id, callback) => ({
  type: GET_CLINIC_GROUP_INVOICES_FOR_PENDING,
  payload: { id, callback },
});

export const setGroupInvoices = (data) => ({
  type: SET_CLINIC_GROUP_INVOICES,
  payload: { data },
});

export const editClinicMetaData = (metadataid, form, callback, clinicID) => ({
  type: EDIT_CLINIC_METADATA,
  payload: { metadataid, form, callback, clinicID },
});

export const toggleInvoicingStatus = (groupID, currentStatus) => ({
  type: TOGGLE_INVOICING_STATUS,
  payload: { groupID, currentStatus },
});
export const toggleTestStatus = (groupID, currentStatus) => ({
  type: TOGGLE_TEST_STATUS,
  payload: { groupID, currentStatus },
});

export const toggleTestStatusForTest = (groupID, currentStatus) => ({
  type: TOGGLE_TEST_STATUS_FOR_TEST,
  payload: { groupID, currentStatus },
});

export const patchClinicNotes = (clinicID, notes, callBack) => ({
  type: PATCH_CLINIC_NOTES,
  payload: { clinicID, notes, callBack },
});

export const patchGroupNotes = (groupID, notes, callBack) => ({
  type: PATCH_GROUP_NOTES,
  payload: { groupID, notes, callBack },
});

export const finalizeInvoice = (invoiceID, callBack) => ({
  type: FINALIZE_INVOICE,
  payload: { invoiceID, callBack },
});

export const markInvoicePaidManual = (invoiceID, callBack, memo) => ({
  type: MARK_INVOICE_PAID_MANUAL,
  payload: { invoiceID, callBack, memo },
});

export const editGroup = (groupID, form, callback) => ({
  type: EDIT_GROUP,
  payload: { groupID, form, callback },
});

export const editClinic = (clinicID, form, callback) => ({
  type: EDIT_CLINIC,
  payload: { clinicID, form, callback },
});

export const downloadContract = (groupID, callback) => ({
  type: DOWNLOAD_CONTRACT,
  payload: { groupID, callback },
});

export const attachContract = (groupID, file, callback) => ({
  type: ATTACH_CONTRACT,
  payload: { groupID, file, callback },
});

export const reUploadContract = (groupID, file, success) => ({
  type: REUPLOAD_CONTRACT,
  payload: { groupID, file, success },
});

export const getPatientsDataSales = (success) => ({
  type: GET_PATIENTS_DATA_SALES,
  payload: { success },
});

export const setPatientDataSales = (data) => ({
  type: SET_PATIENT_DATA_SALES,
  payload: { data },
});

export const getRevenueDataSales = (success) => ({
  type: GET_REVENUE_DATA_SALES,
  payload: { success },
});

export const setRevenueDataSales = (data) => ({
  type: SET_REVENUE_DATA_SALES,
  payload: { data },
});

export const getClassEnrollmentList = (data) => ({
  type: GET_CLASS_ENROLLMENT_LIST,
  payload: {},
});

export const setClassEnrollmentList = (data) => ({
  type: SET_CLASS_ENROLLMENT_LIST,
  payload: { data },
});

export const getAllCliniciansandCoordinators = () => ({
  type: GET_ALL_CLINICIANS_AND_COORDINATORS,
  payload: {},
});

export const setCliniciansAll = (data) => ({
  type: SET_ALL_CLINICIANS,
  payload: { data },
});

export const setCoordinatorsAll = (data) => ({
  type: SET_ALL_COORDINATORS,
  payload: { data },
});
