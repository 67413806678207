import React, { useState } from "react";
import {
  Box,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Text,
  Image,
  TableBody,
  Button,
} from "grommet";
import SingleJournalListItem from "./SingleJournalListItem";
import NoMessageIcon from "../../assets/no-messages.svg";
import CustomToolTip from "./CustomToolTip";

const JounralEntries = ({ results }) => {
  results.sort((a, b) => new Date(b.created) - new Date(a.created));
  const filteredResults = results.filter(
    (item) => item.entry !== "created on basis of PHQ-9 assessment response"
  );

  const [page, setPage] = useState(0);

  const paginatedResults = [];

  let counter = 1;

  let toPage = 0;

  for (let i = 0; i < filteredResults.length; i += 1) {
    if (!paginatedResults[toPage]) paginatedResults[toPage] = [];

    paginatedResults[toPage].push(filteredResults[i]);
    counter += 1;

    if (counter > 5) {
      toPage += 1;
      counter = 1;
    }
  }

  const pages = [];

  for (let i = 0; i < paginatedResults.length; i += 1) {
    pages[i] = i;
  }

  return (
    <>
      <Box
        className="large-card"
        margin={{ top: "small" }}
        justify="start"
        style={{ overflowY: "auto" }}
        gap="small"
        pad="medium"
      >
        <Box direction="row" justify="between">
          <Text alignSelf="start" weight="bold" color="#395E9D">
            Journal Entries
          </Text>
          <CustomToolTip
            outText="i"
            content="Each journal entry of the patient is tagged with a label indicating its semantic tone, as predicted by our Natural Language Processing (NLP) model."
          />
        </Box>

        {filteredResults.length === 0 ? (
          <Box alignSelf="center" align="center" justify="center">
            <Image margin={{ top: "small" }} width="50px" src={NoMessageIcon} />
            <Text>No Journal Entries</Text>
          </Box>
        ) : (
          <Table className="jBook" alignSelf="center">
            <TableHeader>
              <TableRow>
                <TableCell scope="col" align="center">
                  <Text size="small" weight="bold">
                    DATE
                  </Text>
                </TableCell>
                <TableCell scope="col" align="center">
                  <Text size="small" weight="bold">
                    ISSUE
                  </Text>
                </TableCell>
                <TableCell scope="col">
                  <Text size="small" weight="bold">
                    PREVIEW
                  </Text>
                </TableCell>
                <TableCell scope="col" align="center" />
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedResults[page].map((postdata) => (
                <SingleJournalListItem key={postdata.id} post={postdata} />
              ))}
            </TableBody>
          </Table>
        )}
        <Box width="100%">
          <Box height="1px" background="#395E9D" />
          <Box
            direction="row"
            width="100%"
            justify="between"
            align="center"
            pad={{ horizontal: "small" }}
          >
            <Box>
              <Text size="small" color="#395E9D">
                Showing{" "}
                {filteredResults.length === 0 ? 0 : 5 * (page + 1) - (5 - 1)} to{" "}
                {paginatedResults[page + 1]
                  ? 5 * (page + 1)
                  : filteredResults.length}{" "}
                of {filteredResults.length} entries
              </Text>
            </Box>
            <Box direction="row" align="center">
              <Button
                size="small"
                color="#666666"
                onClick={() =>
                  paginatedResults[page - 1] ? setPage(page - 1) : null
                }
                disabled={!paginatedResults[page - 1]}
              >
                <Text size="small">
                  {filteredResults.length === 0 ? null : "Previous"}
                </Text>
              </Button>
              {pages.map((vals) => (
                <Text
                  key={vals}
                  className={vals === page ? "activePage" : "inactivePage"}
                  textAlign="center"
                  size="small"
                  margin="xsmall"
                  color="#82D4E7"
                  onClick={() => setPage(vals)}
                >
                  {vals + 1}
                </Text>
              ))}
              {/* <Text
                          className="activePage"
                          textAlign="center"
                          size="small"
                          margin="xsmall"
                          color="#82D4E7"
                        >
                          {page}
                        </Text> */}
              <Button
                size="small"
                color="#666666"
                onClick={() =>
                  paginatedResults[page + 1] ? setPage(page + 1) : null
                }
                disabled={!paginatedResults[page + 1]}
              >
                <Text size="small">
                  {filteredResults.length === 0 ? null : "Next"}
                </Text>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default JounralEntries;
