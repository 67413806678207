import initialState from "../initialState";
import {
  CLEAR_ASSESSMENT_IMPROVEMENT,
  SET_ASSESSMENT_COUNT,
  SET_ASSESSMENT_IMPROVEMENT,
  SET_DEPRESSION_REMISSION,
  SET_FLAG_COUNT,
  SET_LAST_ACTIVE_COUNT,
  SET_MESSAGES_COUNT,
} from "./actions";

export default function acoReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MESSAGES_COUNT: {
      const { data } = payload;
      return { ...state, message_count: data };
    }
    case SET_ASSESSMENT_COUNT: {
      const { data } = payload;
      return { ...state, assessment_count: data };
    }
    case SET_FLAG_COUNT: {
      const { data } = payload;
      return { ...state, flag_count: data };
    }
    case SET_LAST_ACTIVE_COUNT: {
      const { data } = payload;
      return { ...state, last_active_count: data };
    }
    case SET_DEPRESSION_REMISSION: {
      const { data } = payload;
      return { ...state, depression_remission_rate: data };
    }
    case SET_ASSESSMENT_IMPROVEMENT: {
      const { data } = payload;
      return {
        ...state,
        assessment_improvement: state.assessment_improvement.concat(data),
      };
    }
    case CLEAR_ASSESSMENT_IMPROVEMENT: {
      return { ...state, assessment_improvement: [] };
    }
    default:
      return state;
  }
}
