import React, { useEffect } from "react";
import { Box } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import ProfileBox from "../../common/ProfileBox";
import { Mixpanel } from "../../../services/mixpanel/Mixpanel";
import { updateLastViewed } from "../../../store/patients/actions";

const SinglePatientView = () => {
  const { search } = useSelector((state) => state.router.location);
  const user = useSelector((state) => state.app.user);
  const dispatch = useDispatch();

  useEffect(() => {
    Mixpanel.time_event("Time Spent on Patient Dashboard");

    dispatch(updateLastViewed(split(search, "?", 2)[1]));

    return () => {
      Mixpanel.track("Time Spent on Patient Dashboard", {
        Patient: split(search, "?", 2)[1],
        User: `${user.user.first_name} ${user.user.last_name}`,
        Clinic: user.user.clinic.name,
        "User Type": user.user.role,
      });
    };
  });

  return (
    <Box width="100%">
      <Box>
        <ProfileBox />
      </Box>
    </Box>
  );
};
export default SinglePatientView;
