import { Box, Select, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getStaff, getUnreadMessagesCount } from "../../store/patients/actions";
import { push } from "connected-react-router";

const ClinicSelector = () => {
  const dispatch = useDispatch();
  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const staff = useSelector((state) => state.patients.staff);
  const [unreadMessages, setUnreadMessages] = useState();

  const success = (results) => {
    setUnreadMessages(results);
  };

  useEffect(() => {
    const messagesInterval = setInterval(() => {
      // if (window.localStorage.getItem("selectedDropdownPin"))
      dispatch(getUnreadMessagesCount(success));
    }, 2000);
    return () => {
      clearInterval(messagesInterval);
    };
  }, []);

  const assossciations = [];

  const selectClinician = parseInt(
    window.localStorage.getItem("selectedDropdownClinician")
  );

  const selectPin = parseInt(
    window.localStorage.getItem("selectedDropdownPin")
  );

  const onSuccess = () => true;

  useEffect(() => {
    if (!staff) dispatch(getStaff(onSuccess));
  }, []);

  const getStaffName = (id) => {
    var thisStaff;

    if (staff) thisStaff = staff.find((item) => item.id === id);

    if (thisStaff) return thisStaff.first_name + " " + thisStaff.last_name;
    else return id;
  };

  const getClinicNameFromPin = (pin) => {
    var thisPinObj;

    if (clinicPins) thisPinObj = clinicPins.find((item) => item.pin === pin);

    if (thisPinObj) return thisPinObj.clinic.name;
    else return pin;
  };

  if (clinicPins)
    clinicPins.forEach((x) => {
      assossciations.push({
        key: `${x.clinic.id} | ${x.clinic_admin}`,
        value: `${x.clinic.name} | ${getStaffName(x.clinic_admin)}`,
        clinician: x.clinic_admin,
        clinicName: x.clinic.name,
        pin: x.pin,
      });
    });

  const handleChange = (option) => {
    if (option) {
      window.localStorage.setItem("selectedDropdownClinic", option.key);
      window.localStorage.setItem(
        "selectedDropdownClinician",
        option.clinician
      );
      window.localStorage.setItem("selectedDropdownPin", option.pin);
    } else {
      window.localStorage.removeItem("selectedDropdownClinic");
      window.localStorage.removeItem("selectedDropdownClinician");
      window.localStorage.removeItem("selectedDropdownPin");
    }

    dispatch(push("/dashboard/home"));
  };
  const renderOption = (option, state) => (
    <Box pad={{ horizontal: "xsmall", top: "xsmall" }}>
      <Box
        pad={{ horizontal: "small" }}
        direction="row"
        justify="between"
        align="center"
      >
        <Box>
          <Text size="xsmall" color="#1B3664">
            {option.clinicName}
          </Text>
          <Text size="small">{getStaffName(option.clinician)}</Text>
        </Box>
        {unreadMessages && unreadMessages[option.pin] > 0 ? (
          <Box background="#FA8581" round="full" width="10px" height="10px" />
        ) : null}
      </Box>
      <Box
        margin={{ top: "xsmall" }}
        height="1px"
        alignSelf="center"
        background="#C7DBFC80"
        width="90%"
      />
    </Box>
  );

  const getIfAnyUnread = (messagesObj) => {
    let totalUnread = 0;
    Object.values(messagesObj).forEach((x) => (totalUnread = totalUnread + x));

    if (totalUnread > 0) return true;
    else return false;
  };

  if (!clinicPins && !staff) return <InPageLoader />;

  return (
    <Box
      background="white"
      className="minContentHeight"
      width="90%"
      style={{ border: "1px solid white", borderRadius: "10px" }}
    >
      <Select
        plain
        value={
          <Box
            direction="row"
            align="center"
            gap="xsmall"
            pad={{ left: "xsmall" }}
          >
            {unreadMessages && getIfAnyUnread(unreadMessages) ? (
              <Box
                background="#FA8581"
                style={{ flexShrink: "0" }}
                round="full"
                width="10px"
                height="10px"
              />
            ) : null}
            <Box pad="xsmall" style={{ minWidth: "200px" }}>
              <Text size="xsmall" color="#1B366450">
                CURRENT CLINIC
              </Text>
              <Text size="xsmall" color="#1B3664">
                {" "}
                {selectPin
                  ? getClinicNameFromPin(selectPin)
                  : "Get Started"}{" "}
                {selectPin ? "|" : null}{" "}
                {selectClinician ? getStaffName(selectClinician) : null}
              </Text>
            </Box>
          </Box>
        }
        name="clinic"
        labelKey="value"
        clear
        multiple={false}
        valueKey={{ key: "key", reduce: true }}
        options={assossciations}
        dropProps={{
          style: {
            borderRadius: "10px",
            marginTop: "5px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        placeholder="Select Clinic"
        onChange={({ option }) => handleChange(option)}
        dropHeight="small"
        size="small"
      >
        {renderOption}
      </Select>
    </Box>
  );
};

export default ClinicSelector;
