import { Box, Select, Text } from "grommet";
import { Document, Schedule } from "grommet-icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueDataSales } from "../../../../store/sales/actions";
import { getLastxMonths } from "../../../../util/globalFunctions";
import AllGroupsRevenue from "../../../common/AllGroupRevenue";
import InPageLoader from "../../../common/InPageLoader";
import { RowCardContainer } from "../../../common/RowCardContainer";

const RevenueDetail = () => {
  const [dataFor, setDataFor] = useState(3);

  const dispatch = useDispatch();
  const salesRevenue = useSelector((state) => state.sales.sales_revenue);
  const success = () => {
    return true;
  };

  useEffect(() => {
    if (!salesRevenue) dispatch(getRevenueDataSales(success));
  }, []);

  function htmlToCSV(filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }
      //eslint-disable-next-line
      row.forEach((x, index) => {
        row[index] = x.replace(/,/g, " ");
      });

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }

  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  if (!salesRevenue) return <InPageLoader />;

  return (
    <>
      <RowCardContainer
        style={{ marginTop: "20px" }}
        alignIn="center"
        justifyIn="end"
      >
        <Box
          onClick={() => htmlToCSV("data.csv")}
          pad="small"
          style={{ maxHeight: "42px" }}
          alignSelf="end"
          align="center"
          className="selectReports"
          direction="row"
          gap="small"
        >
          <Text weight="bold" size="small" color="#395E9D">
            Download as CSV
          </Text>
          <Document color="#ADB5BD" />
        </Box>
        <Box
          className="selectReports"
          alignSelf="end"
          margin={{ right: "small" }}
        >
          <Select
            value={dataFor}
            labelKey="value"
            multiple={false}
            valueKey={{ key: "key", reduce: true }}
            options={[
              { value: "Last Three Months", key: 3 },
              { value: "Last Six Months", key: 6 },
              { value: "Last Year", key: 12 },
            ]}
            dropProps={{
              style: {
                borderRadius: "10px",
                marginTop: "5px",
                backgroundColor: "white",
                color: "black",
              },
            }}
            style={{ color: "#395E9D" }}
            icon={<Schedule color="#ADB5BD" />}
            onChange={({ option }) => setDataFor(option.key)}
            dropHeight="small"
            size="small"
            plain
          />
        </Box>
      </RowCardContainer>
      <AllGroupsRevenue
        invoices={salesRevenue}
        months={getLastxMonths(dataFor)}
      />
      ;
    </>
  );
};
export default RevenueDetail;
