import React, { useState } from "react";
import { Text, Layer, Box } from "grommet";
import { CircleInformation, Clipboard } from "grommet-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBHINote,
  updateSelfHarmJournalFlag,
} from "../../store/patients/actions";

const FireFlagsModal = ({ fireFlagsToShow, display, setDisplayOut }) => {
  const [selectedAction, setSelectedAction] = useState("HIGH RISK");
  const [fireFlagNum, setFireFlagNum] = useState(0);
  const user = useSelector((state) => state.app.user.user);
  const dispatch = useDispatch();
  const onSuccess = () => {
    window.location.reload();
  };

  const processNextAction = () => {
    if (selectedAction === "LOW RISK") {
      dispatch(
        updateSelfHarmJournalFlag(
          fireFlagsToShow.fire_flag[fireFlagNum].id,
          onSuccess
        )
      );
      dispatch(
        saveBHINote(
          "Fireflag Detection",
          `Therapist marked fireflag as low risk - "${fireFlagsToShow.fire_flag[fireFlagNum].journal_fire_flagged}" `,
          fireFlagsToShow.id,
          user.id
        )
      );
    }
    if (selectedAction === "HIGH RISK") {
      dispatch(
        saveBHINote(
          "Fireflag Detection",
          `Therapist marked fireflag as high risk - "${fireFlagsToShow.fire_flag[fireFlagNum].journal_fire_flagged}" `,
          fireFlagsToShow.id,
          user.id
        )
      );
    }

    if (fireFlagNum + 1 === fireFlagsToShow.fire_flag.length)
      setDisplayOut(false);
    else setFireFlagNum(fireFlagNum + 1);
  };

  return (
    <>
      {display && (
        <Layer
          onEsc={() => setDisplayOut(false)}
          onClickOutside={() => setDisplayOut(false)}
          plain
          className="popup-card-fireFlag"
          position="center"
        >
          <Box align="center" justify="center" pad="small" gap="small">
            <Box round="full" pad="small" background="#FEDDDC">
              <Clipboard color="#F89595" />
            </Box>
            <Text size="small" color="#F89595">
              {fireFlagNum + 1}/{fireFlagsToShow.fire_flag.length}
            </Text>

            <Text textAlign="center" size="small">
              {" "}
              <b>
                {fireFlagsToShow.first_name} {fireFlagsToShow.last_name}
              </b>{" "}
              has been identified as a potential{" "}
              <span style={{ color: "#F89595", fontWeight: "bold" }}>
                urgent risk alert
              </span>
              , which means the Rose system has detected{" "}
              <span style={{ color: "#F89595", fontWeight: "bold" }}>
                indications of suicidal ideation
              </span>
            </Text>

            <Box background="#EFF2FE" width="100%" pad="small" round="10px">
              <Text size="small" weight="bold" color="#395E9D">
                This patient was flagged because:
              </Text>
              <Text size="small" color="#395E9D" gap="xsmall">
                {fireFlagsToShow.fire_flag[fireFlagNum].journal_fire_flagged ===
                "Fireflag triggered due to PHQ9"
                  ? "Their answer to Question 9 of the PHQ-9 recently indicated that they were experiencing suicidal ideation"
                  : "Our algorithms detected potential suicide ideation in the following journal entry."}
              </Text>
              {fireFlagsToShow.fire_flag[fireFlagNum].journal_fire_flagged ===
              "Fireflag triggered due to PHQ9" ? null : (
                <Text size="small" color="#395E9D" gap="xsmall">
                  <i>
                    "
                    {
                      fireFlagsToShow.fire_flag[fireFlagNum]
                        .journal_fire_flagged
                    }
                    "
                  </i>
                </Text>
              )}
              {/* <Text
                color="#2BC2F7"
                size="small"
                weight="bold"
                margin={{ top: "small" }}
                style={{ cursor: "pointer" }}
              >
                <u>More information on our detection algorithms</u>
              </Text> */}
            </Box>
            <Box width="100%">
              <Text color="#44444F" size="small">
                Using your clinical judgement, please classify this alert as:
              </Text>
            </Box>
            <Box
              pad="small"
              className="noOutlineWithBorder"
              width="100%"
              round="10px"
              onClick={() => setSelectedAction("LOW RISK")}
              style={{
                border:
                  selectedAction === "LOW RISK"
                    ? "1px solid #FB7F12"
                    : "1px solid #E0E0E0",
              }}
              direction="row"
              align="center"
              gap="medium"
            >
              <Box
                round="full"
                width="10px"
                height="10px"
                margin={{ left: "small" }}
                pad="8px"
                border="1px solid #E0E0E0"
                align="center"
                justify="center"
              >
                <Box
                  background={selectedAction === "LOW RISK" ? "#FB7F12" : null}
                  pad="5px"
                  round="full"
                />
              </Box>
              <Box gap="1px">
                <Box
                  className="maxContent"
                  pad={{ vertical: "4px", horizontal: "small" }}
                  background="#FADBBF"
                  direction="row"
                  gap="xsmall"
                  round="4px"
                  align="center"
                  justify="center"
                >
                  <CircleInformation size="small" color="#FB7F12" />
                  <Text weight="bold" size="xsmall" color="#FB7F12">
                    {" "}
                    LOW RISK
                  </Text>
                </Box>
                <Text size="small">
                  The patient was flagged unnecessarily, or the data is not of
                  immediate concern.
                </Text>
              </Box>
            </Box>
            <Box
              className="noOutlineWithBorder"
              onClick={() => setSelectedAction("HIGH RISK")}
              pad="small"
              width="100%"
              round="10px"
              style={{
                border:
                  selectedAction === "HIGH RISK"
                    ? "1px solid #EB5757"
                    : "1px solid #E0E0E0",
              }}
              direction="row"
              align="center"
              gap="medium"
            >
              <Box
                round="full"
                width="10px"
                height="10px"
                margin={{ left: "small" }}
                pad="8px"
                border="1px solid #E0E0E0"
                align="center"
                justify="center"
              >
                <Box
                  background={selectedAction === "HIGH RISK" ? "#EB5757" : null}
                  pad="5px"
                  round="full"
                />
              </Box>
              <Box gap="1px">
                <Box
                  className="maxContent"
                  pad={{ vertical: "4px", horizontal: "small" }}
                  background="#FABFBF"
                  direction="row"
                  gap="xsmall"
                  round="4px"
                  align="center"
                  justify="center"
                >
                  <CircleInformation size="small" color="#EB5757" />
                  <Text weight="bold" size="xsmall" color="#EB5757">
                    {" "}
                    HIGH RISK
                  </Text>
                </Box>
                <Text size="small">This patient is at risk of harm.</Text>
              </Box>
            </Box>
            {/* {JSON.stringify(fireFlagsToShow.fire_flag)} */}
            <Box width="100%">
              <Box
                className="noOutline"
                onClick={processNextAction}
                alignSelf="end"
                round="10px"
                pad={{ horizontal: "medium", vertical: "small" }}
                background="#395E9D"
              >
                <Text color="#fff" size="small">
                  {" "}
                  {fireFlagNum + 1 === fireFlagsToShow.fire_flag.length
                    ? "Finish"
                    : "Next"}
                </Text>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};
export default FireFlagsModal;
