import React, { useEffect } from "react";
import { FormClose, StatusGood, StatusCritical, Alert } from "grommet-icons";
import { Box, Button, Layer, Text } from "grommet";
import { useSelector } from "react-redux";

const Notification = ({ onClose }) => {
  const { open, text, good, timeout } = useSelector(
    (state) => state.app.notification
  );
  useEffect(() => {
    if (open && timeout > 0) {
      setTimeout(onClose, timeout);
    }
  }, [open]);

  const getBackground = (type) => {
    if (type === "neutral") return "#AE8F13";

    if (type) return "#8DC779";
    else return "#FA8581";
  };

  const getBackgroundBottom = (type) => {
    if (type === "neutral") return "#6A570C";

    if (type) return "#589147";
    else return "#BD5050";
  };
  const getIcon = (type) => {
    if (type === "neutral") return <Alert color="white" />;

    if (type) return <StatusGood color="white" />;
    else return <StatusCritical color="white" />;
  };
  return (
    open && (
      <Layer
        position="top-right"
        modal={false}
        margin={{ vertical: "medium", horizontal: "small" }}
        onEsc={onClose}
        responsive={false}
        plain
      >
        <Box elevation="medium" background={getBackground(good)}>
          <Box
            align="center"
            direction="row"
            gap="small"
            justify="between"
            pad={{ vertical: "small", horizontal: "small" }}
          >
            <Box align="center" direction="row" gap="xsmall">
              {getIcon(good)}
              <Text color="white">{text}</Text>
            </Box>
            <Button
              icon={<FormClose color="white" />}
              onClick={onClose}
              plain
              focusIndicator={false}
            />
          </Box>
          <Box
            background={getBackgroundBottom(good)}
            className="animateNotif"
          />
        </Box>
      </Layer>
    )
  );
};
export default Notification;
