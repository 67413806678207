import React, { useState, useEffect } from "react";
import { Box, Button, Form, TextInput, Text, Image } from "grommet";
import styled from "styled-components";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { FormView, Hide } from "grommet-icons";
import { push } from "connected-react-router";
import { isMobileHook } from "../../common/Responsive";
import {
  createNotification,
  verifyToken,
  setNewPassword,
} from "../../../store/app/actions";
import Loader from "../../common/InPageLoaderWhite";
import logo from "../../../assets/Vectorlock.svg";

const SoftButton = styled(Button)`
  border-radius: 10px;
  background-color: #1c3664;
  border: none;
`;

const LoadingSoftButton = ({ label, submitting, invalidToken, weakPass }) => (
  <Box height="xxsmall">
    <SoftButton
      fill
      primary
      type="submit"
      disabled={submitting || invalidToken || weakPass}
      label={
        submitting ? (
          <Loader type="TailSpin" height={30} color="grey" />
        ) : (
          <Text color="#FFFFFF">{label}</Text>
        )
      }
    />
  </Box>
);

const ProcessResetForm = () => {
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const [value, setValue] = useState("");
  const isMobile = isMobileHook();
  const [showPass, setShowPass] = useState(false);
  const { search } = useSelector((state) => state.router.location);
  const token = search.split("=");
  const [passwordStrength, setPasswordStrength] = useState();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onError = () => {
    setInvalidToken(true);
    setLoading(false);
  };

  const onSucess = () => {
    setLoading(false);
    setTokenVerified(true);
  };

  const checkToken = () => {
    dispatch(verifyToken(token[1], onSucess, onError));
  };

  useEffect(() => {
    if (!tokenVerified) {
      setLoading(true);
      checkToken();
    }
  }, []);

  const resetPassSubmit = (form) => {
    setSubmitting(true);
    const {
      value: { newpassword, confirmpassword },
    } = form;
    if (newpassword === confirmpassword) {
      dispatch(setNewPassword(token[1], newpassword));
      setSubmitting(false);
    } else dispatch(createNotification("Passwords do not match", false));
  };

  const onChangeForm = () => {
    if (errors.detail) setErrors({});
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      className="wrap-login"
      width={isMobile ? "320px" : "400px"}
      round="10px"
      style={{ position: "absolute" }}
    >
      <Box alignSelf="center" justify="center" margin={{ top: "small" }}>
        <Image src={logo} />
      </Box>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="large"
        weight="bold"
        color="#1B3664"
      >
        Reset Password
      </Text>

      <Text
        margin={{ vertical: "xsmall", horizontal: "xxxsmall" }}
        size="small"
        weight="normal"
        color="black"
      >
        Enter your new password
      </Text>
      {invalidToken ? (
        <Text color="red" size="small">
          Expired Token, please try reseting your password again
        </Text>
      ) : null}

      <Box
        align="center"
        justify="center"
        direction="row"
        margin={{ top: "xxsmall", bottom: "xxsmall" }}
      />
      <Form
        onChangeCapture={() => onChangeForm()}
        onSubmit={resetPassSubmit}
        errors={errors}
        onChange={(nextValue) => setValue(nextValue)}
        value={value}
      >
        <Text size="xsmall" weight="bold" color="#1B3664">
          New Password
        </Text>
        <Box>
          <Box
            border={{ side: "all", color: "#C4C4C4" }}
            round="small"
            margin={{ top: "6px" }}
            align="center"
            direction="row"
          >
            <TextInput
              name="newpassword"
              plain
              disabled={invalidToken}
              type={showPass ? "text" : "password"}
              className="passwordInput"
              placeholder="Your Password"
              required
            />
            <Box margin={{ right: "small" }}>
              {showPass ? (
                <FormView
                  color="#92929D"
                  onClick={() => setShowPass(!showPass)}
                />
              ) : (
                <Hide color="#92929D" onClick={() => setShowPass(!showPass)} />
              )}
            </Box>
          </Box>
          <PasswordStrengthBar
            scoreWords={["too weak", "weak", "okay", "good", "strong"]}
            minLength={6}
            shortScoreWord={
              value.hasOwnProperty("newpassword") &&
              value.newpassword.length > 0
                ? "too short"
                : ""
            }
            password={
              value.hasOwnProperty("newpassword") ? value.newpassword : " "
            }
            onChangeScore={(score) => setPasswordStrength(score)}
          />
        </Box>
        <Text size="xsmall" weight="bold" color="#1B3664">
          Confirm Password
        </Text>

        <Box
          border={{ side: "all", color: "#C4C4C4" }}
          round="small"
          margin={{ top: "6px" }}
          align="center"
          direction="row"
        >
          <TextInput
            name="confirmpassword"
            plain
            disabled={invalidToken}
            type={showPass ? "text" : "password"}
            className="passwordInput"
            placeholder="Your Password"
            required
          />
        </Box>

        <Box margin={{ top: "xsmall", bottom: "xsmall" }} />

        <Box align="center" justify="center" direction="row" margin={{}}>
          {errors.detail ? (
            <Text
              margin={{ top: "xxxsmall", bottom: "xsmall" }}
              size="small"
              color="red"
            >
              {errors.detail}
            </Text>
          ) : null}
        </Box>

        <LoadingSoftButton
          invalidToken={invalidToken}
          submitting={submitting}
          label="Reset Password"
          weakPass={passwordStrength === 0}
        />
      </Form>

      <Text
        onClick={() => dispatch(push("/login"))}
        margin="medium"
        style={{ cursor: "pointer" }}
        size="small"
        weight="bold"
        color="#1C3664"
        textAlign="center"
      >
        Back to homepage
      </Text>
    </Box>
  );
};

export default ProcessResetForm;
