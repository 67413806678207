/* eslint-disable no-console */
/* eslint-disable camelcase */
import { call, put, takeEvery } from "redux-saga/effects";
import moment from "moment";
import { createNotification } from "../app/actions";

import {
  CREATE_CLINIC,
  CREATE_CLINIC_GROUP,
  CREATE_CLINIC_METADATA,
  DOWNLOAD_CONTRACT,
  EDIT_CLINIC,
  EDIT_CLINIC_METADATA,
  EDIT_GROUP,
  FINALIZE_INVOICE,
  GET_ALL_CLINICIANS_AND_COORDINATORS,
  GET_ALL_CLINICS,
  GET_CLASS_ENROLLMENT_LIST,
  GET_CLINIC,
  GET_CLINIC_GROUP,
  GET_CLINIC_GROUPS,
  GET_CLINIC_GROUP_CLINICS,
  GET_CLINIC_GROUP_INVOICES,
  GET_CLINIC_GROUP_INVOICES_FOR_PENDING,
  GET_CLINIC_META,
  GET_GROUP_SETUP_INTENT,
  GET_PATIENTS_DATA_SALES,
  GET_REVENUE_DATA_SALES,
  GET_TEST_CLINIC_GROUPS,
  MARK_INVOICE_PAID_MANUAL,
  PATCH_CLINIC_NOTES,
  PATCH_GROUP_NOTES,
  REUPLOAD_CONTRACT,
  setAllClinics,
  setClassEnrollmentList,
  setClinic,
  setCliniciansAll,
  setCoordinatorsAll,
  setGroup,
  setGroupClincs,
  setGroupInvoices,
  setGroups,
  SetMetadatas,
  setPatientDataSales,
  setRevenueDataSales,
  setTestGroups,
  TOGGLE_INVOICING_STATUS,
  TOGGLE_TEST_STATUS,
  TOGGLE_TEST_STATUS_FOR_TEST,
} from "./actions";
import api from "../../services/api/ApiService";

function createClinicGroupSagaCall(form) {
  const {
    value: {
      name,
      type,
      street_address_1,
      street_address_2,
      city,
      state,
      country,
      rose_sales_team_member,
      contact_name,
      contact_number,
      zip_code,
      email,
      payment_type,
    },
  } = form;

  let FD = new FormData();
  FD.append("name", name);
  FD.append("type", type);
  FD.append("street_address_1", street_address_1);
  FD.append("street_address_2", street_address_2);
  FD.append("city", city);
  FD.append("state", state);
  FD.append("country", country);
  FD.append("rose_sales_team_member", rose_sales_team_member);
  FD.append("contact_name", contact_name);
  FD.append("contact_number", contact_number);
  FD.append("zip_code", zip_code);
  FD.append("email", email);
  FD.append("payment_type", payment_type);

  const resp = api.post(`/api/clinic-group/`, FD);

  return resp;
}

function* createClinicGroupSaga({ payload: { form, callback } }) {
  try {
    const { data } = yield call(createClinicGroupSagaCall, form);
    yield call(callback, data);
  } catch (e) {
    console.log(e);
  }
}

function fetchClinicGroupsSagaCall(searchTerm) {
  const resp = api.get(
    `/api/clinic-group/?is_test_account=0&&search=` + searchTerm
  );

  return resp;
}

function* fetchClinicGroupsSaga({ payload: { callback, searchTerm } }) {
  const { data } = yield call(fetchClinicGroupsSagaCall, searchTerm);
  yield put(setGroups(data));
  yield call(callback);
}

function fetchTestClinicGroupsSagaCall(searchTerm) {
  const resp = api.get(
    `/api/clinic-group/?is_test_account=1&&search=` + searchTerm
  );

  return resp;
}

function* fetchTestClinicGroupsSaga({ payload: { callback, searchTerm } }) {
  const { data } = yield call(fetchTestClinicGroupsSagaCall, searchTerm);
  yield put(setTestGroups(data));
  yield call(callback);
}

function createClinicSagaCall(form) {
  const {
    value: {
      name,
      clinic_group,
      street_address_1,
      street_address_2,
      city,
      state,
      country,
      rose_sales_team_member,
      emergency_contact,
      email,
      zip_code,
    },
  } = form;

  const resp = api.post(`/api/clinic/`, {
    name,
    clinic_group,
    street_address_1,
    street_address_2,
    city,
    state,
    country,
    rose_sales_team_member,
    emergency_contact,
    email,
    zip_code,
  });

  return resp;
}

function* createClinicSaga({ payload: { form, callback } }) {
  const { data } = yield call(createClinicSagaCall, form);
  yield call(callback, data);
}

function createClinicMetadataSagaCall(clinicID, form) {
  const {
    value: {
      number_of_providers_to_charge_setup_fees,
      number_of_providers_to_charge_monthly_fees,
      setup_fees,
      monthly_fees,
      onboarding_date,
      contract_effective_date,
      monthly_billing_effective_date,
      number_of_months_to_be_paid,
      trial_period,
      contract_termination_date,
    },
  } = form;

  const resp = api.post(`/api/clinic-invoicing-metadata/`, {
    clinic: clinicID,
    number_of_providers_to_charge_setup_fees,
    number_of_providers_to_charge_monthly_fees,
    setup_fees,
    monthly_fees,
    onboarding_date: moment(onboarding_date).format("YYYY-MM-DD"),
    contract_effective_date: moment(contract_effective_date).format(
      "YYYY-MM-DD"
    ),
    monthly_billing_effective_date: moment(
      monthly_billing_effective_date
    ).format("YYYY-MM-DD"),
    number_of_months_to_be_paid,
    trial_period,
    contract_termination_date: moment(contract_termination_date).format(
      "YYYY-MM-DD"
    ),
  });

  return resp;
}

function* createClinicMetadataSaga({ payload: { clinicID, form, callback } }) {
  const { data } = yield call(createClinicMetadataSagaCall, clinicID, form);
  yield call(callback, data);
}

function fetchGroupSetupIntentSagaCall(clinic_group_id) {
  const resp = api.post(`/api/stripe-setup/setup-intent/`, { clinic_group_id });

  return resp;
}

function* fetchGroupSetupIntentSaga({
  payload: { clinic_group_id, callback },
}) {
  const { data } = yield call(fetchGroupSetupIntentSagaCall, clinic_group_id);
  yield call(callback, data);
}

function fetchClinicGroupSagaCall(id) {
  const resp = api.get(`/api/clinic-group/${id}/`);

  return resp;
}

function* fetchClinicGroupSaga({ payload: { id, callback } }) {
  const { data } = yield call(fetchClinicGroupSagaCall, id);
  yield put(setGroup(data));
  yield call(callback);
}

function fetchClinicGroupClinicsSagaCall(id) {
  const resp = api.post(
    `/api/clinic-group-custom/get-all-clinics-of-a-clinic-group/`,
    { clinic_group_id: id }
  );

  return resp;
}

function* fetchClinicGroupClinicsSaga({ payload: { id, callback } }) {
  const { data } = yield call(fetchClinicGroupClinicsSagaCall, id);
  yield put(setGroupClincs(data));
  yield call(callback);
}

function fetchClinicSagaCall(id) {
  const resp = api.get(`/api/clinic/${id}/`);

  return resp;
}

function* fetchClinicSaga({ payload: { id, callback } }) {
  const { data } = yield call(fetchClinicSagaCall, id);
  yield put(setClinic(data));
  yield call(callback);
}

function fetchClinicMetasSagaCall() {
  const resp = api.get(`/api/clinic-invoicing-metadata/`);

  return resp;
}

function* fetchClinicMetasSaga({ payload: { callback } }) {
  const { data } = yield call(fetchClinicMetasSagaCall);
  yield put(SetMetadatas(data));
  yield call(callback);
}

function fetchClinicGroupInvoicesSagaCall(id) {
  const resp = api.post(
    `/api/clinic-group-custom/get-all-invoices-of-a-clinic-group/`,
    { clinic_group_id: id, limit: 50 }
  );

  return resp;
}

function* fetchClinicGroupInvoicesSaga({ payload: { id, callback } }) {
  try {
    const { data } = yield call(fetchClinicGroupInvoicesSagaCall, id);
    yield put(setGroupInvoices(data));
    yield call(callback);
  } catch (error) {
    yield put(setGroupInvoices({ data: [] }));
    yield call(callback);
  }
}
function* fetchClinicGroupInvoicesSagaforPending({
  payload: { id, callback },
}) {
  try {
    const { data } = yield call(fetchClinicGroupInvoicesSagaCall, id);
    yield call(callback, data);
  } catch (e) {
    yield call(callback, []);
  }
}

function fetchAllClinicsSagaCall(searchTerm) {
  const resp = api.get(`/api/clinic?search=` + searchTerm);

  return resp;
}

function* fetchAllClinicsSaga({ payload: { callback, searchTerm } }) {
  const { data } = yield call(fetchAllClinicsSagaCall, searchTerm);
  yield put(setAllClinics(data));
  yield call(callback);
}

function editClinicMetadataSagaCall(metadataid, form, clinicID) {
  const {
    value: {
      number_of_providers_to_charge_setup_fees,
      number_of_providers_to_charge_monthly_fees,
      setup_fees,
      monthly_fees,
      contract_effective_date,
      monthly_billing_effective_date,
      number_of_months_to_be_paid,
      trial_period,
      contract_termination_date,
      onboarding_date,
    },
  } = form;
  const resp = api.patch(`/api/clinic-invoicing-metadata/${metadataid}/`, {
    clinic: clinicID,
    number_of_providers_to_charge_setup_fees,
    number_of_providers_to_charge_monthly_fees,
    setup_fees,
    monthly_fees,
    contract_effective_date: moment(contract_effective_date).format(
      "YYYY-MM-DD"
    ),
    monthly_billing_effective_date: moment(
      monthly_billing_effective_date
    ).format("YYYY-MM-DD"),
    number_of_months_to_be_paid,
    trial_period,
    contract_termination_date: moment(contract_termination_date).format(
      "YYYY-MM-DD"
    ),
    onboarding_date: moment(onboarding_date).format("YYYY-MM-DD"),
  });

  return resp;
}

function* editClinicMetadataSaga({
  payload: { metadataid, form, callback, clinicID },
}) {
  const { data } = yield call(
    editClinicMetadataSagaCall,
    metadataid,
    form,
    clinicID
  );
  yield put(setAllClinics(data));
  yield call(callback);
}

function changeInvoicingStatusCall(groupID, currentStatus) {
  const FD = new FormData();
  FD.append("auto_invoicing", !currentStatus);

  const resp = api.patch(`/api/clinic-group/${groupID}/`, FD);

  return resp;
}

function* changeInvoicingStatus({ payload: { groupID, currentStatus } }) {
  const { data } = yield call(
    changeInvoicingStatusCall,
    groupID,
    currentStatus
  );

  if (data) {
    const singleGroup = yield call(fetchClinicGroupSagaCall, groupID);
    yield put(setGroup(singleGroup.data));
  }
}

function changeTestStatusCall(groupID, currentStatus) {
  const FD = new FormData();
  FD.append("is_test_account", !currentStatus);

  const resp = api.patch(`/api/clinic-group/${groupID}/`, FD);

  return resp;
}

function* changeTestStatus({ payload: { groupID, currentStatus } }) {
  const { data } = yield call(changeTestStatusCall, groupID, currentStatus);

  if (data) {
    const singleGroup = yield call(fetchClinicGroupSagaCall, groupID);
    yield put(setGroup(singleGroup.data));
  }
}

function* changeTestStatusForTest({ payload: { groupID, currentStatus } }) {
  const { data } = yield call(changeTestStatusCall, groupID, currentStatus);

  if (data) {
    const groups = yield call(fetchTestClinicGroupsSagaCall, "");
    yield put(setTestGroups(groups.data));
  }
}

function changeClinicNotesCall(clinicID, notes) {
  const resp = api.patch(`/api/clinic/${clinicID}/`, {
    notes,
  });

  return resp;
}

function* changeClinicNotes({ payload: { clinicID, notes, callBack } }) {
  const { data } = yield call(changeClinicNotesCall, clinicID, notes);

  if (data) yield call(callBack);
}

function changeGroupNotesCall(groupID, notes) {
  const FD = new FormData();
  FD.append("notes", notes);
  const resp = api.patch(`/api/clinic-group/${groupID}/`, FD);

  return resp;
}

function* changeGroupNotes({ payload: { groupID, notes, callBack } }) {
  const { data } = yield call(changeGroupNotesCall, groupID, notes);

  if (data) yield call(callBack);
}

function finalizeInvoiceSagaCall(invoiceID) {
  const resp = api.post(`/api/invoice/finalize-an-invoice/`, {
    invoice_id: invoiceID,
  });

  return resp;
}

function* finalizeInvoiceSaga({ payload: { invoiceID, callBack } }) {
  const data = yield call(finalizeInvoiceSagaCall, invoiceID);

  if (data.status === 200) yield call(callBack);
}

function markInvoiceasPaidSagaCall(invoiceID, memo) {
  const resp = api.post(`/api/invoice/mark-invoice-as-paid/`, {
    invoice_id: invoiceID,
    memo,
  });

  return resp;
}

function* markInvoiceasPaidSaga({ payload: { invoiceID, callBack, memo } }) {
  try {
    const data = yield call(markInvoiceasPaidSagaCall, invoiceID, memo);
    if (data.status === 200) yield call(callBack);
  } catch (e) {
    yield put(createNotification(Object.values(e.response.data)[0][0], false));
  }
}

function editGroupSagaCall(groupID, form) {
  const {
    value: {
      name,
      type,
      street_address_1,
      street_address_2,
      city,
      state,
      country,
      rose_sales_team_member,
      contact_name,
      contact_number,
      zip_code,
      email,
      payment_type,
    },
  } = form;

  let FD = new FormData();
  FD.append("name", name);
  FD.append("type", type);
  FD.append("street_address_1", street_address_1);
  FD.append("street_address_2", street_address_2);
  FD.append("city", city);
  FD.append("state", state);
  FD.append("country", country);
  FD.append("rose_sales_team_member", rose_sales_team_member);
  FD.append("contact_name", contact_name);
  FD.append("contact_number", contact_number);
  FD.append("zip_code", zip_code);
  FD.append("email", email);
  FD.append("payment_type", payment_type);

  const resp = api.patch(`/api/clinic-group/${groupID}/`, FD);

  return resp;
}

function* editGroupSaga({ payload: { groupID, form, callback } }) {
  try {
    const { data } = yield call(editGroupSagaCall, groupID, form);

    if (data) yield call(callback);
  } catch (e) {
    yield put(createNotification(Object.values(e.response.data)[0][0], false));
  }
}

function editClinicSagaCall(clinicID, form) {
  const {
    value: {
      name,
      clinic_group,
      street_address_1,
      street_address_2,
      city,
      state,
      country,
      rose_sales_team_member,
      emergency_contact,
      email,
      zip_code,
    },
  } = form;

  const resp = api.patch(`/api/clinic/${clinicID}/`, {
    name,
    clinic_group,
    street_address_1,
    street_address_2,
    city,
    state,
    country,
    rose_sales_team_member,
    emergency_contact,
    email,
    zip_code,
  });

  return resp;
}

function* editClinicSaga({ payload: { clinicID, form, callback } }) {
  try {
    const { data } = yield call(editClinicSagaCall, clinicID, form);

    if (data) yield call(callback);
  } catch (e) {
    yield put(createNotification(Object.values(e.response.data)[0][0], false));
  }
}

function processDownloadContractCall(groupID) {
  const resp = api.post(`/api/clinic-group-custom/download-file/`, {
    clinic_group_id: groupID,
  });
  return resp;
}

function* processDownloadContract({ payload: { groupID, callback } }) {
  try {
    const { data } = yield call(processDownloadContractCall, groupID);
    if (data) {
      yield call(callback, data);
    }
  } catch (e) {
    yield put(createNotification("Something Went Wrong", false));
  }
}

function processReuploadContractCall(groupID, file) {
  const FD = new FormData();
  FD.append("contract_file", file);
  const resp = api.patch(`/api/clinic-group/${groupID}/`, FD);
  return resp;
}

function* processReuploadContract({ payload: { groupID, file, success } }) {
  const { data } = yield call(processReuploadContractCall, groupID, file);
  if (data) {
    yield call(success, data);
    const group = yield call(fetchClinicGroupSagaCall, groupID);
    if (group.data) {
      yield put(setGroup(group.data));
    }
  }
}

function getPatientsDataSalesSagaCall() {
  const resp = api.get(`/api/patient/?page_size=999999`);
  return resp;
}

function* getPatientsDataSalesSaga({ payload: { success } }) {
  const { data } = yield call(getPatientsDataSalesSagaCall);
  if (data) {
    yield put(setPatientDataSales(data));
    yield call(success);
  }
}

function getRevenueDataSalesSagaCall() {
  const resp = api.post(`/api/invoice/get-invoices-from-db/`, {
    start_date: "1971-02-14",
    end_date: moment().format("YYYY-MM-DD"),
  });
  return resp;
}

function* getRevenueDataSalesSaga({ payload: { success } }) {
  const { data } = yield call(getRevenueDataSalesSagaCall);
  if (data) {
    yield put(setRevenueDataSales(data));
    yield call(success);
  }
}

function getClassEnrollmentListSagaCall() {
  const resp = api.get(`/api/live-classes-enrollment/`);
  return resp;
}

function* getClassEnrollmentListSaga() {
  const { data } = yield call(getClassEnrollmentListSagaCall);
  if (data) {
    yield put(setClassEnrollmentList(data));
  }
}

function getCoordinatorsSagaCall() {
  const resp = api.get(`/api/tws-coordinator/`);
  return resp;
}

function getCliniciansSagaCall() {
  const resp = api.get(`/api/clinic-admin/`);
  return resp;
}

function* getCliniciansandCoordinatorsSaga() {
  const clinicians = yield call(getCliniciansSagaCall);
  const coordinators = yield call(getCoordinatorsSagaCall);

  if (clinicians) {
    yield put(setCliniciansAll(clinicians.data));
    yield put(setCoordinatorsAll(coordinators.data));
  }
}

function* salesSagas() {
  yield takeEvery(CREATE_CLINIC_GROUP, createClinicGroupSaga);
  yield takeEvery(GET_CLINIC_GROUPS, fetchClinicGroupsSaga);
  yield takeEvery(GET_TEST_CLINIC_GROUPS, fetchTestClinicGroupsSaga);
  yield takeEvery(CREATE_CLINIC, createClinicSaga);
  yield takeEvery(CREATE_CLINIC_METADATA, createClinicMetadataSaga);
  yield takeEvery(GET_GROUP_SETUP_INTENT, fetchGroupSetupIntentSaga);
  yield takeEvery(GET_CLINIC_GROUP, fetchClinicGroupSaga);
  yield takeEvery(GET_CLINIC_GROUP_CLINICS, fetchClinicGroupClinicsSaga);
  yield takeEvery(GET_CLINIC, fetchClinicSaga);
  yield takeEvery(GET_CLINIC_META, fetchClinicMetasSaga);
  yield takeEvery(GET_CLINIC_GROUP_INVOICES, fetchClinicGroupInvoicesSaga);
  yield takeEvery(
    GET_CLINIC_GROUP_INVOICES_FOR_PENDING,
    fetchClinicGroupInvoicesSagaforPending
  );
  yield takeEvery(GET_ALL_CLINICS, fetchAllClinicsSaga);
  yield takeEvery(EDIT_CLINIC_METADATA, editClinicMetadataSaga);
  yield takeEvery(TOGGLE_INVOICING_STATUS, changeInvoicingStatus);
  yield takeEvery(TOGGLE_TEST_STATUS, changeTestStatus);
  yield takeEvery(TOGGLE_TEST_STATUS_FOR_TEST, changeTestStatusForTest);
  yield takeEvery(PATCH_CLINIC_NOTES, changeClinicNotes);
  yield takeEvery(PATCH_GROUP_NOTES, changeGroupNotes);
  yield takeEvery(FINALIZE_INVOICE, finalizeInvoiceSaga);
  yield takeEvery(MARK_INVOICE_PAID_MANUAL, markInvoiceasPaidSaga);
  yield takeEvery(EDIT_GROUP, editGroupSaga);
  yield takeEvery(EDIT_CLINIC, editClinicSaga);
  yield takeEvery(DOWNLOAD_CONTRACT, processDownloadContract);
  yield takeEvery(REUPLOAD_CONTRACT, processReuploadContract);
  yield takeEvery(GET_PATIENTS_DATA_SALES, getPatientsDataSalesSaga);
  yield takeEvery(GET_REVENUE_DATA_SALES, getRevenueDataSalesSaga);
  yield takeEvery(GET_CLASS_ENROLLMENT_LIST, getClassEnrollmentListSaga);
  yield takeEvery(
    GET_ALL_CLINICIANS_AND_COORDINATORS,
    getCliniciansandCoordinatorsSaga
  );
}

export default salesSagas;
