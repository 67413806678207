/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from "react";
import * as $ from "jquery";
import S3FileUpload from "react-s3";
import { Avatar, Box, Form, Text, TextInput } from "grommet";
import { Emoji, Image, Send } from "grommet-icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { push } from "connected-react-router";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import YellowAlert from "../../../../assets/icons/yellow-alert-flag.svg";
import RedAlert from "../../../../assets/icons/red-alert-flag.svg";
import GreenAlert from "../../../../assets/icons/green-alert-flag.svg";
import Notification from "../../../../assets/notif.mp3";
import { serializeFormData } from "./common";
import { RTMInstance as rtm } from "./rtm-client";
import {
  getAllMessagesHistory,
  markMessagesAsRead,
  saveSingleMessage,
} from "../../../../store/patients/actions";

const config = {
  bucketName: "chathistoryimages",
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

const Messages = (props) => {
  const { peerID, peerName, conversation, setConversations, uid } = props;
  const staff = useSelector((state) => state.patients.staff);
  const user = useSelector((state) => state.app.user.user);

  const patients = useSelector((state) =>
    state.patients.chat_patients ? state.patients.chat_patients.patients : []
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();

  const updateScroll = () => {
    const element = document.getElementById("log");

    if (element) element.scrollTop = element.scrollHeight;
  };

  const allSuccess = () => true;
  const callBack = () => {
    dispatch(getAllMessagesHistory(allSuccess));
  };

  const getSenderName = (id) => {
    if (staff.find((item) => item.id.toString() === id))
      return `${staff.find((item) => item.id.toString() === id).first_name} ${
        staff.find((item) => item.id.toString() === id).last_name
      }`;

    if (patients.find((item) => item.id.toString() === id))
      return `${
        patients.find((item) => item.id.toString() === id).first_name
      } ${patients.find((item) => item.id.toString() === id).last_name}`;

    return id;
  };

  if (staff && patients && conversation[0])
    if (conversation[0]) {
      const audio = new Audio(Notification);
      audio.play();
      if (conversation[0].message.messageType === "TEXT") {
        if (peerID) {
          if (
            conversation[0].message.text.startsWith(
              "https://chathistoryimages.s3.amazonaws.com/"
            ) ||
            conversation[0].message.text.startsWith(
              "https://s3.us-east-1.amazonaws.com/chathistoryimages/"
            )
          )
            $("#log").append(
              `<div class="messageWrapperRecieve"><div class="recievedMessageImage" ><div class="messageSender">${getSenderName(
                conversation[0].from
              )}</div><img width="200px" src= '${
                conversation[0].message.text
              }'/></div><div class="timeStampMessageRecieve">${moment().format(
                "MM/DD/YY hh:mm a"
              )}</div></div>`
            );
          else
            $("#log").append(
              `<div class="messageWrapperRecieve"><div class="recievedMessage" ><div class="messageSender">${getSenderName(
                conversation[0].from
              )}</div>${
                conversation[0].message.text
              }</div><div class="timeStampMessageRecieve">${moment().format(
                "MM/DD/YY hh:mm a"
              )}</div></div>`
            );

          if (peerID === conversation[0].from)
            dispatch(markMessagesAsRead(conversation[0].from, callBack));
        }

        setConversations({});
        updateScroll();
        callBack();
      }
    }

  const sendText = () => {
    const params = serializeFormData("loginForm");

    if (!params.peerMessage) return;

    setSending(true);

    rtm
      .sendChannelMessage(params.peerMessage, peerID)
      .then(() => {
        $("#log").append(
          `<div class="messageWrapperSend"><div class="timeStampMessageSend">${moment().format(
            "MM/DD/YY hh:mm a"
          )}</div><div class="sentMessage" >${params.peerMessage}</div></div>`
        );

        dispatch(
          saveSingleMessage(
            "text",
            params.peerMessage,
            true,
            peerID,
            uid,
            callBack
          )
        );
        updateScroll();
        document.getElementById("textMessage").value = "";

        setSending(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let file;

  const sendImage = () => {
    if (!file) return;

    setSending(true);

    S3FileUpload.uploadFile(file, config).then((data) => {
      dispatch(
        saveSingleMessage("image", data.location, true, peerID, uid, callBack)
      );
      rtm.sendChannelMessage(data.location, peerID).then(() => {
        $("#log").append(
          `<div class="messageWrapperSend"><div class="timeStampMessageSend">${moment().format(
            "MM/DD/YY hh:mm a"
          )}</div><div class="sentMessageImage" ><img width="200px" src= '${
            data.location
          }'/></div></div>`
        );

        const params = serializeFormData("loginForm");
        if (params.peerMessage) sendText();

        updateScroll();
        file = null;
        document.getElementById("fileName").innerHTML = "";
        setSending(false);
      });
    });
  };

  const onEmojiClick = (event, emojiObject) => {
    document.getElementById("textMessage").value =
      document.getElementById("textMessage").value + emojiObject.emoji;
    setShowEmojiPicker(false);
    document.getElementById("textMessage").focus();
  };

  const handleFile = (event) => {
    if (event.target.files[0]) {
      document.getElementById("fileName").innerHTML =
        event.target.files[0].name;
      file = event.target.files[0];
      document.getElementById("textMessage").focus();
    }
  };

  const handleKeyPress = (event) => {
    if (sending) return;

    if (event.key === "Enter") {
      event.preventDefault();
      if (file) sendImage();
      else sendText();
    }
  };

  const getParticipantsNames = (peer) => {
    const thisPatient = patients.find((item) => item.id.toString() === peer);

    const clinician = getSenderName(thisPatient.clinician.toString());
    const sharedWith = thisPatient.shared_with_clinical_staff;
    let sharedWithName = "";

    sharedWith.forEach((item) => {
      sharedWithName += `, ${getSenderName(item.toString())} (Clinical Staff)`;
    });
    return `, ${clinician} (Primary Provider)${sharedWithName}`;
  };

  const getParticipantsNamesTWS = (peer) => {
    const thisPatient = patients.find((item) => item.id.toString() === peer);

    const sharedWith = thisPatient.shared_with_tws_concierge;
    let sharedWithName = "";

    sharedWith.forEach((item) => {
      sharedWithName += `, ${getSenderName(item.toString())} (TWS Concierge)`;
    });
    return `${sharedWithName}`;
  };

  const getNameInitials = (peer) => {
    const thisPatient = patients.find((item) => item.id.toString() === peer);

    if (thisPatient)
      return thisPatient.first_name[0] + thisPatient.last_name[0];

    return peer;
  };

  const getPatientFlag = (peer) => {
    const thisPatient = patients.find((item) => item.id.toString() === peer);

    if (thisPatient) {
      if (thisPatient.flag === "RED")
        return (
          <Box
            background="#FA858120"
            round="20px"
            pad={{ horizontal: "small", vertical: "3px" }}
            direction="row"
            gap="small"
          >
            <img alt="redFlag" width="10px" src={RedAlert} />
            <Text weight="bold" color="#FA8581" size="xsmall">
              Red Flag Patient
            </Text>
          </Box>
        );

      if (thisPatient.flag === "YELLOW")
        return (
          <Box
            background="#F2C94C20"
            round="20px"
            pad={{ horizontal: "small", vertical: "3px" }}
            direction="row"
            gap="small"
          >
            <img alt="yellowFlag" width="10px" src={YellowAlert} />
            <Text weight="bold" color="#F2C94C" size="xsmall">
              Yellow Flag Patient
            </Text>
          </Box>
        );
      if (thisPatient.flag === null)
        return (
          <Box
            background="#60ff8420"
            round="20px"
            pad={{ horizontal: "small", vertical: "3px" }}
            direction="row"
            gap="small"
          >
            <img alt="greenFlag" width="10px" src={GreenAlert} />
            <Text weight="bold" color="#19e046" size="xsmall">
              Green Flag Patient
            </Text>
          </Box>
        );
    }

    return peer;
  };

  return (
    <Box
      width="90%"
      justify="between"
      background="#1B366410"
      pad="small"
      round="8px"
      margin="small"
    >
      <Box
        margin={{ bottom: "small" }}
        background="#FFFFFF"
        round="8px"
        direction="row"
        gap="small"
        align="center"
        width="100%"
        justify="start"
        pad="small"
      >
        <Avatar background="#f1f1f5">
          <Box align="center" justify="center">
            {peerName ? (
              <Text
                color="#28539e"
                style={{ textTransform: "uppercase" }}
                width="20px"
                placeholder="reciever"
              >
                {getNameInitials(peerID)}
              </Text>
            ) : (
              <Text
                color="#28539e"
                style={{ textTransform: "uppercase" }}
                width="20px"
                placeholder="reciever"
              >
                {peerID}
              </Text>
            )}
          </Box>
        </Avatar>
        {peerName ? (
          <Box>
            <Box
              className="noOutline"
              onClick={() => dispatch(push(`/dashboard/patient/?${peerID}`))}
              margin={{ bottom: "xxsmall" }}
            >
              <Box direction="row" align="center" gap="xsmall">
                <Text color="#28539e" weight="bold">
                  {peerName}
                </Text>
                {getPatientFlag(peerID)}
              </Box>
            </Box>
            <Text size="xsmall">
              {`${peerName} (Patient)`}
              {user.role === "TWS_CONCIERGE" || user.role === "TWS_CASE_MANAGER"
                ? getParticipantsNamesTWS(peerID)
                : getParticipantsNames(peerID)}
            </Text>
          </Box>
        ) : (
          <Box background="#1B366410" width="30%" height="50%" round="8px" />
        )}
      </Box>

      <Box id="log" gap="small" pad="medium">
        <Box justify="center" height="100%" align="center">
          <Text size="small" textAlign="center">
            Your conversations appears here,
            <br />
            select a chat to get started
          </Text>
        </Box>
      </Box>
      {showEmojiPicker ? (
        <Picker
          onEmojiClick={onEmojiClick}
          disableAutoFocus
          skinTone={SKIN_TONE_NEUTRAL}
          groupNames={{ smileys_people: "PEOPLE" }}
          native
          disableSkinTonePicker
          pickerStyle={{ position: "absolute", marginBottom: 120, bottom: 0 }}
        />
      ) : null}
      {peerID ? (
        <Box pad="small" background="white" round="8px">
          <Form id="loginForm">
            <Box gap="small">
              <Box justify="center" gap="small" align="end">
                <Box
                  background="#1B366410"
                  width="100%"
                  direction="row"
                  align="center"
                  style={{ fontSize: "16px" }}
                  round="8px"
                  pad={{ left: "small" }}
                >
                  <Box direction="row" gap="small" pad={{ right: "small" }}>
                    <label style={{ marginBottom: "0%" }}>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFile(event)}
                      />
                      <Image />
                    </label>
                    <Emoji
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    />
                  </Box>
                  <TextInput
                    onKeyPress={handleKeyPress}
                    placeholder="Type a message"
                    name="peerMessage"
                    id="textMessage"
                  />
                  {sending ? (
                    <Box className="noOutline" width="10%" align="center">
                      <Loader
                        type="ThreeDots"
                        color="#395E9D"
                        height="30"
                        width="30"
                      />
                    </Box>
                  ) : (
                    <Box
                      onClick={() => (file ? sendImage() : sendText())}
                      className="noOutline"
                      width="10%"
                      align="center"
                    >
                      <Send />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                direction="row"
                align="center"
                pad={{ horizontal: "small" }}
                gap="small"
                justify="end"
              >
                <Text
                  size="small"
                  id="fileName"
                  color="green"
                  margin={{ left: "xsmall" }}
                />
              </Box>
            </Box>
          </Form>
        </Box>
      ) : null}
    </Box>
  );
};

export default Messages;
