import { Box, Select } from "grommet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InPageLoader from "./InPageLoader";
import { getStaff } from "../../store/patients/actions";
import { push } from "connected-react-router";

const ClinicSelectorAdmin = () => {
  const dispatch = useDispatch();
  const clinicPins = useSelector((state) => state.app.user.user.clinic_pin);
  const staff = useSelector((state) => state.patients.staff);

  const assossciations = [];
  const [currentSelected, setCurrentSelected] = useState(
    window.localStorage.getItem("selectedDropdownClinic") &&
      window.localStorage.getItem("selectedDropdownClinician")
      ? `${parseInt(
          window.localStorage.getItem("selectedDropdownClinic")
        )} | ${parseInt(
          window.localStorage.getItem("selectedDropdownClinician")
        )}`
      : {}
  );

  const onSuccess = () => true;

  useEffect(() => {
    if (!staff) dispatch(getStaff(onSuccess));
  }, []);

  if (!clinicPins && !staff) return <InPageLoader />;

  if (clinicPins)
    clinicPins.forEach((x) => {
      assossciations.push({
        key: `${x.clinic.id} | ${x.clinic_admin}`,
        value: `${x.clinic.name}`,
        clinician: x.clinic_admin,
        pin: x.pin,
      });
    });

  const handleChange = (option) => {
    if (option) {
      window.localStorage.setItem("selectedDropdownClinic", option.key);
      window.localStorage.setItem(
        "selectedDropdownClinician",
        option.clinician
      );
      window.localStorage.setItem("selectedDropdownPin", option.pin);
      setCurrentSelected(option.key);
    } else {
      window.localStorage.removeItem("selectedDropdownClinic");
      window.localStorage.removeItem("selectedDropdownClinician");
      window.localStorage.removeItem("selectedDropdownPin");

      setCurrentSelected({});
    }

    dispatch(push("/dashboard/home"));
  };

  return (
    <Box
      background="white"
      style={{ border: "1px solid grey", borderRadius: "10px" }}
    >
      <Select
        plain
        value={currentSelected}
        name="clinic"
        labelKey="value"
        clear
        multiple={false}
        valueKey={{ key: "key", reduce: true }}
        options={assossciations}
        dropProps={{
          style: {
            borderRadius: "10px",
            marginTop: "5px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        placeholder="Select Clinic"
        onChange={({ option }) => handleChange(option)}
        dropHeight="small"
        size="small"
      />
    </Box>
  );
};

export default ClinicSelectorAdmin;
