/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { Download, FormPrevious } from "grommet-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { split } from "lodash";
import moment from "moment";
import SmolLoader from "react-loader-spinner";
import ProfileBoxBilling from "../../../common/ProfileBoxBilling";
import BHSummary from "../../../common/BHSummary";
import Loader from "../../../common/InPageLoader";
import { fetchAllReportData } from "../../../../store/patients/actions";
import { useHistory } from "react-router";

const PatientReport = () => {
  const { search } = useSelector((state) => state.router.location);
  const download = split(search, "?")[3];
  const patientID = split(search, "?")[1];
  const month = new Date(split(search, "?")[2]);
  const history = useHistory();

  const [loading, setLoading] = useState();
  const patientBHI = useSelector((state) => state.patients.patient_bhi);
  const patient = useSelector((state) => state.patients.patient);
  const schedules = useSelector((state) => state.patients.assessments_schedule);
  const endDate = new Date(
    month.getFullYear(),
    month.getMonth() + 1,
    0
  ).toISOString();
  const startDate = month.toISOString();

  const [showDownloadBar, setShowDownloadBar] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const checkClosing = async () => {
    setShowDownloadBar(false);
    if (download) {
      await delay(100);
      // eslint-disable-next-line no-undef
      window.close();
    }
  };

  const printDocument = () => {
    setShowDownloadBar(true);
    // eslint-disable-next-line no-undef
    const input = document.getElementById("pdf1");
    const pdf = new jsPDF({
      unit: "px",
      hotfixes: ["px_scaling"],
    });
    const imgWidth = 793;
    const pageHeight = 1115;
    let heightLeft = input.offsetHeight - (30 / 100) * input.offsetHeight;
    let position = 10;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      pdf.addImage(
        imgData,
        "PNG",
        0,
        position,
        793,
        input.offsetHeight - (30 / 100) * input.offsetHeight,
        "patientSummary",
        "SLOW"
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position +=
          heightLeft - (input.offsetHeight - (30 / 100) * input.offsetHeight);
        pdf.addPage();

        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          input.offsetHeight - (30 / 100) * input.offsetHeight,
          "patientSummary",
          "SLOW"
        );
        heightLeft -= pageHeight;
      }

      pdf.save(
        `Report Patient:${patientID} ${moment(new Date()).format(
          "MM/DD/YYYY"
        )}.pdf`
      );
      checkClosing();
    });
  };

  const getScheduledAssessments = () => {
    let newString = "";
    if (schedules)
      schedules.forEach((element, index) => {
        if (index === schedules.length - 1)
          newString += ` and ${element.assessment_name}`;
        else newString += `${element.assessment_name}, `;
      });

    return newString;
  };

  const dispatch = useDispatch();

  const onSuccess = () => {
    setLoading(false);
    if (download) {
      printDocument();
    }
  };

  useEffect(() => {
    setLoading(true);

    dispatch(fetchAllReportData(search, onSuccess, startDate, endDate));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Box id="pdf1">
      <Box
        width="100%"
        pad={{ top: "medium", horizontal: "medium" }}
        gap="medium"
      >
        <Box
          direction="row"
          className="noOutline"
          onClick={() => history.push(`/dashboard/billingHome`)}
        >
          {" "}
          <FormPrevious color="#395E9D" />{" "}
          <Text weight="bold" color="#395E9D">
            Back to Billing Dashboard
          </Text>
        </Box>
        <Box direction="row" justify="between" align="center">
          <Text weight="bold" color="#395E9D">
            Monthly BHI Report -{moment(month).format("MMMM YYYY")}
          </Text>

          <Box direction="row">
            {showDownloadBar ? (
              <SmolLoader type="Rings" height={30} color="#395e9d" />
            ) : (
              <Box height="36px" />
            )}
            <Box
              direction="row"
              onClick={() => printDocument()}
              align="center"
              className="noOutline"
              gap="xsmall"
              pad="xsmall"
              style={{ cursor: "pointer" }}
              round="xsmall"
              background="#395E9D"
              height="30px"
            >
              <Text size="small" color="white">
                Download Report
              </Text>
              <Download size="small" color="white" />
            </Box>
          </Box>
        </Box>
        <ProfileBoxBilling />
        <Text weight="bold" size="small">
          Behavioral Health Summary
        </Text>
        <BHSummary />
      </Box>
      <Box>
        <Box pad={{ top: "medium", horizontal: "medium" }}>
          <Text weight="bold" size="small">
            BHI Program Documentation
          </Text>
        </Box>
        <Box width="100%" pad="medium" gap="medium">
          {patient && patientBHI && schedules ? (
            <Box width="100%" pad="medium" className="large-card-summary">
              <Box style={{ fontSize: "small" }}>
                {" "}
                {`${patient.last_name}, ${patient.first_name}`} was consented
                and enrolled in treatment plan for General Behavioral Health
                Integration (CPT 99484) for {patientBHI.diagnosis}. The Rose
                enrollment was done on{" "}
                {moment(patient.date_joined).format("MM/DD/YYYY")}
                . Consent was also obtained from the patient to consult with
                relevant specialists including a psychiatric consultant if
                needed. As part of this consent, the patient was informed that
                there is beneficiary cost-sharing, including potential
                deductible and coinsurance amounts for both in-person and
                non-face-to-face services that are provided.
                <br /> <br />
                Patient was counseled on symptoms related to their diagnosis in
                the office and instructed to call our office if the symptoms
                worsened.
                <br /> <br />
                Patient to be closely monitored, screened and counseled
                according to the psychological tests results{" "}
                {getScheduledAssessments()}
                . These psychological tests are repeated monthly to reassess
                presence or absence of mental stress that a patient experiences
                to help adjust this treatment plan.
                <br /> <br />
                Care management services for the behavioral health diagnosis
                encompass at least 20 minutes of clinical time, directed by a
                physician or other qualified healthcare professional, to be
                performed per calendar month including initial assessment,
                follow-up monitoring, using applicable validated clinical rating
                scales, behavioral health care planning in relation to
                behavioral/psychiatric health problems, facilitating and
                coordinating treatment such as psychotherapy, pharmacotherapy,
                counseling, and/or psychiatric consultation and continuity of
                care with a designated member of the care team.
                <br /> <br />
                Additionally, the patient was instructed to keep mood, anxiety
                and journal logs using a HIPAA-compliant mobile application. The
                clinical care team can remotely monitor patient wellbeing using
                web-based dashboards and plan care for the patient. The mobile
                app also provides patients with personalized content based on
                their mental health status including behavioral health
                resources, guided meditation, and crisis support. The care team
                helped the patient set up their mobile app account during the
                initial visit and reviewed how to use the mobile app.
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default PatientReport;
