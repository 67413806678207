const Schema = {
  openapi: "3.0.2",
  info: {
    title: "RoseAPI",
    version: "",
  },
  paths: {
    "/api/me/": {
      get: {
        operationId: "retrieveMe",
        description: "Simply returns a single user",
        parameters: [],
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    properties: {
                      id: {
                        type: "integer",
                        readOnly: true,
                      },
                      firstName: {
                        type: "string",
                        maxLength: 30,
                      },
                      lastName: {
                        type: "string",
                        maxLength: 150,
                      },
                      email: {
                        type: "string",
                        format: "email",
                        maxLength: 254,
                      },
                      role: {
                        type: "string",
                      },
                    },
                    required: ["username"],
                  },
                },
              },
            },
            description: "",
          },
        },
      },
    },
    "/auth/refresh/": {
      post: {
        operationId: "refreshToken",
        description:
          "Takes a refresh type JSON web token and returns an access type JSON web\ntoken if the refresh token is valid.",
        parameters: [],
        requestBody: {
          content: {
            "application/x-www-form-urlencoded": {
              schema: {
                properties: {
                  refresh: {
                    type: "string",
                  },
                },
                required: ["refresh"],
              },
            },
            "multipart/form-data": {
              schema: {
                properties: {
                  refresh: {
                    type: "string",
                  },
                },
                required: ["refresh"],
              },
            },
            "application/json": {
              schema: {
                properties: {
                  refresh: {
                    type: "string",
                  },
                },
                required: ["refresh"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  properties: {
                    refresh: {
                      type: "string",
                    },
                  },
                  required: ["refresh"],
                },
              },
            },
            description: "",
          },
        },
      },
    },
    "/auth/login/": {
      post: {
        operationId: "manualLogin",
        description: "",
        parameters: [],
        requestBody: {
          content: {
            "application/x-www-form-urlencoded": {
              schema: {
                properties: {
                  username: {
                    type: "string",
                  },
                  password: {
                    type: "string",
                    writeOnly: true,
                  },
                },
                required: ["username", "password"],
              },
            },
            "multipart/form-data": {
              schema: {
                properties: {
                  username: {
                    type: "string",
                  },
                  password: {
                    type: "string",
                    writeOnly: true,
                  },
                },
                required: ["username", "password"],
              },
            },
            "application/json": {
              schema: {
                properties: {
                  username: {
                    type: "string",
                  },
                  password: {
                    type: "string",
                    writeOnly: true,
                  },
                },
                required: ["username", "password"],
              },
            },
          },
        },
        responses: {
          200: {
            content: {
              "application/json": {
                schema: {
                  properties: {
                    user: {
                      properties: {
                        id: {
                          type: "integer",
                          readOnly: true,
                        },
                        firstName: {
                          type: "string",
                          maxLength: 30,
                        },
                        lastName: {
                          type: "string",
                          maxLength: 150,
                        },
                        email: {
                          type: "string",
                          format: "email",
                          maxLength: 254,
                        },
                        username: {
                          type: "string",
                          nullable: true,
                          maxLength: 25,
                        },
                        role: {
                          type: "string",
                        },
                      },
                      required: ["username"],
                      type: "object",
                      readOnly: true,
                    },
                    token: {
                      properties: {
                        refresh: {
                          type: "string",
                        },
                        access: {
                          type: "string",
                        },
                      },
                      required: ["refresh", "access"],
                      type: "object",
                      readOnly: true,
                    },
                  },
                },
              },
            },
            description: "",
          },
        },
      },
    },
  },
};

export default Schema;
