import moment from "moment";

const getWeekDayIndex = (day) => {
  if (day === "Monday") return 0;
  if (day === "Tuesday") return 1;
  if (day === "Wednesday") return 2;
  if (day === "Thursday") return 3;
  if (day === "Friday") return 4;
  if (day === "Saturday") return 5;
  if (day === "Sunday") return 6;
  else return -1;
};

export const getThisMonthCount = (arrayData) => {
  let count = 0;
  for (let i = 0; i < arrayData.length; i++) {
    if (
      moment(arrayData[i].created).month() === moment().month() &&
      moment(arrayData[i].created).year() === moment().year()
    ) {
      count++;
    }
  }
  return count;
};

export const getWeekDayValues = (arrayData) => {
  //take array of dates and return week-day wise count
  const weekArray = [0, 0, 0, 0, 0, 0, 0];
  const arrayObjects = Object.keys(arrayData);
  const arrayValues = Object.values(arrayData);

  var result = [];
  if (arrayObjects && arrayObjects.length > 0) {
    arrayObjects.forEach((item, index) => {
      result.push({ key: item, value: arrayValues[index] });
    });
  }

  result.forEach((element) => {
    weekArray[getWeekDayIndex(moment(element.key).format("dddd"))] =
      +element.value;
  });

  return weekArray;
};

export const getFlagValues = (arrayData) => {
  //take array of dates and return week-day wise count
  const finalObject = { Red: 0, Yellow: 0, Green: 0 };
  const arrayObjects = Object.keys(arrayData);
  const arrayValues = Object.values(arrayData);

  var result = [];
  if (arrayObjects && arrayObjects.length > 0) {
    arrayObjects.forEach((item, index) => {
      result.push({ key: item, value: arrayValues[index] });
    });
  }

  result.forEach((element) => {
    if (element.value.RED)
      finalObject.Red = finalObject.Red + element.value.RED;

    if (element.value.YELLOW)
      finalObject.Yellow = finalObject.Yellow + element.value.YELLOW;

    if (element.value.null)
      finalObject.Green = finalObject.Green + element.value.null;
  });

  return finalObject;
};

export const getAssessmentValues = (arrayData) => {
  //take array of dates and return week-day wise count
  const finalObject = {};
  const arrayObjects = Object.keys(arrayData);
  const arrayValues = Object.values(arrayData);

  var result = [];
  if (arrayObjects && arrayObjects.length > 0) {
    arrayObjects.forEach((item, index) => {
      result.push({ key: item, value: arrayValues[index] });
    });
  }

  result.forEach((element) => {
    const arrayObjectsInside = Object.keys(element.value);
    const arrayValuesInside = Object.values(element.value);

    var resultInside = [];
    if (arrayObjectsInside && arrayObjectsInside.length > 0) {
      arrayObjectsInside.forEach((item, index) => {
        resultInside.push({ key: item, value: arrayValuesInside[index] });
      });
    }

    resultInside.forEach((element) => {
      if (finalObject[element.key])
        finalObject[element.key] = finalObject[element.key] + element.value;
      else finalObject[element.key] = element.value;
    });
  });

  return finalObject;
};

export const getColorsArrayOfLength = (arr, color) => {
  const final = [];
  arr.forEach(() => {
    final.push(color);
  });

  return final;
};

export const getLastxMonths = (dataFor) => {
  const currentMonth = moment().month();
  let arrayOfMonths = [];
  let year = moment().year();
  for (let i = 0; i < dataFor; i++) {
    if (currentMonth - (Math.abs(i) % 12) + 1 >= 0)
      arrayOfMonths.push(
        `${
          currentMonth - (Math.abs(i) % 12) + 1 === 0
            ? "December"
            : moment(currentMonth - (Math.abs(i) % 12) + 1, "M").format("MMMM")
        } ${year}`
      );
    else {
      arrayOfMonths.push(
        `${
          currentMonth - (Math.abs(i) % 12) + 13 === 0
            ? "December"
            : moment(currentMonth - (Math.abs(i) % 12) + 13, "M").format("MMMM")
        } ${year}`
      );
    }

    if (currentMonth - (i % 12) === 0) {
      year--;
    }
  }
  return arrayOfMonths;
};

export const getSignUpByMonth = (patients, months) => {
  let getSignUpByMonth = [];

  months.forEach((month, index) => {
    getSignUpByMonth[index] = 0;

    patients.forEach((patient) => {
      if (
        moment(patient.created).month() === moment(month).month() &&
        moment(patient.created).year() === moment(month).year()
      ) {
        getSignUpByMonth[index]++;
      }
    });
  });

  return getSignUpByMonth;
};

export const getSignUpbyMonthandGroupID = (patients, groupID, month) => {
  let count = 0;
  patients.forEach((patient) => {
    if (
      moment(patient.created).month() === moment(month).month() &&
      moment(patient.created).year() === moment(month).year() &&
      patient.clinic_group_id === groupID
    ) {
      count++;
    }
  });
  return count;
};

export const getTotalMonthlySignup = (patients, group, month) => {
  let count = 0;
  group.forEach((group) => {
    patients.forEach((patient) => {
      if (
        moment(patient.created).month() === moment(month).month() &&
        moment(patient.created).year() === moment(month).year() &&
        patient.clinic_group_id === group.id
      ) {
        count++;
      }
    });
  });

  return count;
};

export const getTotalRevenuefromArray = (arrayData, actualGroups) => {
  let total = 0;
  arrayData.forEach((element) => {
    //add checks for invoice status
    if (
      (element.status === "PAID" ||
        element.status === "OPEN" ||
        element.status === "DRAFT") &&
      moment(element.created).year() === moment().year() &&
      actualGroups.find((x) => x.clinic_group === element.clinic_group_id)
    )
      total = total + element.total_amount;
  });
  return total;
};

export const getTotalRevenuefromArrayforCurrentMonth = (
  arrayData,
  actualGroups
) => {
  let total = 0;
  arrayData.forEach((element) => {
    //add checks for invoice status
    if (
      (element.status === "PAID" ||
        element.status === "OPEN" ||
        element.status === "DRAFT") &&
      moment(element.created).month() === moment().month() &&
      moment(element.created).year() === moment().year() &&
      actualGroups.find((x) => x.clinic_group === element.clinic_group_id)
    ) {
      total = total + element.total_amount;
    }
  });
  return total;
};

export const getRevenueByMonth = (invoices, months, actualGroups) => {
  let getRevenueByMonth = [];

  months.forEach((month, index) => {
    getRevenueByMonth[index] = 0;

    invoices.forEach((invoice) => {
      if (
        (invoice.status === "PAID" ||
          invoice.status === "OPEN" ||
          invoice.status === "DRAFT") &&
        moment(invoice.created).month() === moment(month).month() &&
        moment(invoice.created).year() === moment(month).year() &&
        actualGroups.find((x) => x.clinic_group === invoice.clinic_group_id)
      ) {
        getRevenueByMonth[index] =
          getRevenueByMonth[index] + invoice.total_amount;
      }
    });
  });

  return getRevenueByMonth;
};

export const getRevenueByMonthCollected = (invoices, months, actualGroups) => {
  let getRevenueByMonth = [];

  months.forEach((month, index) => {
    getRevenueByMonth[index] = 0;

    invoices.forEach((invoice) => {
      if (
        invoice.status === "PAID" &&
        moment(invoice.created).month() === moment(month).month() &&
        moment(invoice.created).year() === moment(month).year() &&
        actualGroups.find((x) => x.clinic_group === invoice.clinic_group_id)
      ) {
        getRevenueByMonth[index] =
          getRevenueByMonth[index] + invoice.total_amount;
      }
    });
  });

  return getRevenueByMonth;
};
export const getRevenueByMonthOutstanding = (
  invoices,
  months,
  actualGroups
) => {
  let getRevenueByMonth = [];

  months.forEach((month, index) => {
    getRevenueByMonth[index] = 0;

    invoices.forEach((invoice) => {
      if (
        (invoice.status === "OPEN" || invoice.status === "DRAFT") &&
        moment(invoice.created).month() === moment(month).month() &&
        moment(invoice.created).year() === moment(month).year() &&
        actualGroups.find((x) => x.clinic_group === invoice.clinic_group_id)
      ) {
        getRevenueByMonth[index] =
          getRevenueByMonth[index] + invoice.total_amount;
      }
    });
  });

  return getRevenueByMonth;
};

export const getRevenuebyMonthandGroupID = (invoices, groupID, month) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      invoice.clinic_group_id === groupID
    ) {
      total = total + invoice.total_amount;
    }
  });
  return total;
};

export const getRevenuebyMonthandGroupIDCollected = (
  invoices,
  groupID,
  month
) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      invoice.status === "PAID" &&
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      invoice.clinic_group_id === groupID
    ) {
      total = total + invoice.total_amount;
    }
  });
  return total;
};

export const getTotalRevenuebyMonth = (invoices, month, groups) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      invoice.status === "PAID" &&
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      groups.find((x) => x.id === invoice.clinic_group_id)
    ) {
      total = total + invoice.total_amount;
    }
  });
  return total;
};

export const getTotalRevenuebyMonthforReport = (invoices, month, groups) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      (invoice.status === "PAID" ||
        invoice.status === "OPEN" ||
        invoice.status === "DRAFT") &&
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      groups.find((x) => x.id === invoice.clinic_group_id)
    ) {
      console.log(invoice);
      total = total + invoice.total_amount;
    }
  });
  return total;
};

export const getTotalRevenuebyMonthCollected = (invoices, month, groups) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      invoice.status === "PAID" &&
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      groups.find((x) => x.id === invoice.clinic_group_id)
    ) {
      total = total + invoice.total_amount;
    }
  });
  return total;
};

export const getTotalRevenuebyMonthOutstanding = (invoices, month, groups) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (
      (invoice.status === "OPEN" || invoice.status === "DRAFT") &&
      moment(invoice.created).month() === moment(month).month() &&
      moment(invoice.created).year() === moment(month).year() &&
      groups.find((x) => x.id === invoice.clinic_group_id)
    ) {
      total = total + invoice.total_amount;
    }
  });
  return total;
};
