import React, { useState, useEffect } from "react";
import { Layer } from "grommet";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { split } from "lodash";
import { getSetupIntentforGroup } from "../../store/sales/actions";
import CardEditForm from "../pages/dashboard/SalesPanel/CardEditForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentMethodGroup = ({ display, setDisplayOut, showPaymentMethods }) => {
  const [si, setSI] = useState(null);
  const { search } = useSelector((state) => state.router.location);
  const groupID = split(search, "?", 2)[1];

  const dispatch = useDispatch();
  const callBack = (data) => {
    setSI(data);
  };

  useEffect(() => {
    if (!si) dispatch(getSetupIntentforGroup(groupID, callBack));
  }, [si]);

  return (
    display && (
      <Layer
        onEsc={() => setDisplayOut(false)}
        onClickOutside={() => setDisplayOut(false)}
        plain
        className="popup-card-payment"
        position="center"
      >
        {si ? (
          <Elements stripe={stripePromise}>
            <CardEditForm
              showPaymentMethods={showPaymentMethods}
              setDisplayOut={setDisplayOut}
              intent={si}
              groupID={groupID}
            />
          </Elements>
        ) : null}
      </Layer>
    )
  );
};
export default PaymentMethodGroup;
