import React from "react";
import { Box, Select, Text } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import InfoCard from "../../../common/InfoCard";
import { RowCardContainer } from "../../../common/RowCardContainer";
import HistogramSales from "../../../common/HistogramSales";
import { useEffect } from "react";
import {
  getAllClinics,
  getGroups,
  getPatientsDataSales,
} from "../../../../store/sales/actions";
import InPageLoader from "../../../common/InPageLoader";
import {
  getColorsArrayOfLength,
  getLastxMonths,
  getSignUpByMonth,
  getThisMonthCount,
} from "../../../../util/globalFunctions";
import AllGroupsUtilization from "../../../common/AllGroupUtilization";
import { useState } from "react";
import { Schedule } from "grommet-icons";

const UtilizationReports = () => {
  const user = useSelector((state) => state.app.user.user);
  const allClinics = useSelector((state) => state.sales.all_clinics);
  const salesPatients = useSelector((state) => state.sales.sales_patients);
  const [dataFor, setDataFor] = useState(3);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.sales.clinic_groups);

  const success = () => {
    return true;
  };

  function getFiltered() {
    const toPlay = allClinics.filter((clinic) =>
      groups.find((x) => x.id === clinic.clinic_group)
    );

    return toPlay;
  }

  function getFilteredPatients() {
    const toPlay = salesPatients.results.filter((patient) =>
      getFiltered().find((x) => x.id === patient.clinic)
    );

    return toPlay;
  }

  useEffect(() => {
    if (!allClinics) dispatch(getAllClinics(success, ""));
    if (!salesPatients) dispatch(getPatientsDataSales(success));
    if (!groups) dispatch(getGroups(success, ""));
  }, []);

  if (!allClinics || !salesPatients || !groups) return <InPageLoader />;

  return (
    <Box pad="medium" gap="medium">
      <Text color="#395E9D" weight="bold">
        Welcome, {user.first_name} {user.last_name}
      </Text>
      <RowCardContainer>
        <InfoCard
          title="Total No. of Clinics"
          subTitle=""
          stat={getFiltered().length}
        />
        <InfoCard
          title="Total No. of Patients"
          subTitle=""
          stat={getFilteredPatients().length}
        />
        <InfoCard
          title="Total No. of New Patients This Month"
          subTitle=""
          stat={getThisMonthCount(getFilteredPatients())}
        />
      </RowCardContainer>
      <Box className="selectReports" margin="small" alignSelf="end">
        <Select
          value={dataFor}
          labelKey="value"
          multiple={false}
          valueKey={{ key: "key", reduce: true }}
          options={[
            { value: "Last Three Months", key: 3 },
            { value: "Last Six Months", key: 6 },
            { value: "Last Year", key: 12 },
          ]}
          dropProps={{
            style: {
              borderRadius: "10px",
              marginTop: "5px",
              backgroundColor: "white",
              color: "black",
            },
          }}
          style={{ color: "#395E9D" }}
          icon={<Schedule color="#ADB5BD" />}
          onChange={({ option }) => setDataFor(option.key)}
          dropHeight="small"
          size="small"
          plain
        />
      </Box>
      <RowCardContainer>
        <HistogramSales
          data={getSignUpByMonth(getFiltered(), getLastxMonths(dataFor))}
          yAxisMax={Math.max(
            ...getSignUpByMonth(getFiltered(), getLastxMonths(dataFor))
          )}
          colors={getColorsArrayOfLength(getLastxMonths(dataFor), "#395E9D")}
          heading="No of Clinics Added"
          horizontalDatas={getLastxMonths(dataFor)}
          barColor="#395E9D"
          nameToPut="Clinics Signed Up"
          itemStyleColor="#395E9D"
        />
        <HistogramSales
          data={getSignUpByMonth(
            getFilteredPatients(),
            getLastxMonths(dataFor)
          )}
          yAxisMax={Math.max(
            ...getSignUpByMonth(getFilteredPatients(), getLastxMonths(dataFor))
          )}
          colors={getColorsArrayOfLength(getLastxMonths(dataFor), "#2BC2F7")}
          heading="No of Patients Added"
          horizontalDatas={getLastxMonths(dataFor)}
          barColor="#395E9D"
          nameToPut="Patients Signed Up"
          itemStyleColor="#2BC2F7"
        />
      </RowCardContainer>
      <AllGroupsUtilization
        patients={getFilteredPatients()}
        months={getLastxMonths(dataFor)}
      />
    </Box>
  );
};
export default UtilizationReports;
