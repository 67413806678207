import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app/reducer";
import patients from "./patients/reducer";
import sales from "./sales/reducer";
import aco from "./aco/reducer";
import caregiver from "./caregiver/reducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    patients,
    sales,
    aco,
    caregiver,
  });

export default rootReducer;
